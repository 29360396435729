import React from "react";
import Icon from "./Icon";

function IconCloud(props) {
  return (
    <Icon {...props}>
      <g fill="none" fillRule="evenodd">
        <g stroke="currentColor" strokeWidth="2">
          <path d="M83.5 21.5H65.472M65.5 1.5v20" strokeLinecap="square" />
          <path d="M1 1v108h83V21.283L65.528 1H1z" />
        </g>
        <g transform="translate(21 69)">
          <rect
            stroke="currentColor"
            strokeWidth="2"
            x="-1"
            y="-1"
            width="45"
            height="22"
            rx="2"
          />
          <path
            d="M10.2 5.403c-1.054 0-1.87.408-2.45 1.225-.578.817-.867 1.955-.867 3.414 0 3.037 1.106 4.555 3.317 4.555.928 0 2.052-.24 3.371-.717V16.3c-1.084.466-2.295.699-3.633.699-1.922 0-3.392-.6-4.41-1.802C4.508 13.996 4 12.27 4 10.023c0-1.416.25-2.656.75-3.72.5-1.066 1.219-1.883 2.156-2.45C7.843 3.283 8.94 3 10.2 3c1.283 0 2.573.32 3.868.96l-.904 2.347a14.713 14.713 0 0 0-1.49-.634 4.33 4.33 0 0 0-1.474-.27zm15.01 7.629c0 1.23-.43 2.198-1.288 2.906C23.064 16.646 21.87 17 20.34 17c-1.41 0-2.658-.273-3.742-.82v-2.682c.892.41 1.646.698 2.264.866a6.514 6.514 0 0 0 1.695.251c.614 0 1.086-.12 1.414-.363.328-.242.493-.602.493-1.08 0-.267-.073-.505-.217-.713a2.457 2.457 0 0 0-.638-.6c-.28-.193-.85-.5-1.712-.923-.808-.391-1.413-.767-1.817-1.127a4.213 4.213 0 0 1-.967-1.257c-.24-.479-.361-1.037-.361-1.677 0-1.205.396-2.152 1.188-2.841C18.731 3.344 19.826 3 21.224 3a7.54 7.54 0 0 1 1.966.251c.624.168 1.276.404 1.957.708l-.904 2.245c-.705-.298-1.288-.506-1.749-.624a5.471 5.471 0 0 0-1.36-.177c-.53 0-.937.127-1.22.382-.283.255-.425.587-.425.997 0 .254.057.476.172.666.114.19.297.372.547.55.25.176.842.495 1.776.954 1.235.609 2.081 1.219 2.54 1.83.457.612.686 1.362.686 2.25zm10.961-9.836H39l-4.492 13.618h-3.055L26.97 3.196h2.83l2.485 8.103c.138.479.281 1.036.43 1.672.147.637.239 1.08.275 1.328.066-.572.292-1.571.678-3l2.503-8.103z"
            fill="currentColor"
          />
        </g>
      </g>
    </Icon>
  );
}

export default IconCloud;
