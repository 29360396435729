import { gql } from "apollo-boost";

const HOMEBEAT_FRAGMENT = gql`
  fragment Homebeat on Homebeat {
    id
    frequency
    lastSent: last_delivered_at
    updatedAt: updated_at
    inactiveAt: inactive_at
    welcomeEmailBody: welcome_email_body
    optInAt: opt_in_at
    lead @type(name: "Lead") {
      name
      email: email_address
    }
    report @type(name: "Report") {
      guid
      propertyType: prop_type
      propertySubType: prop_sub_type
      eventSummary: event_summary @type(name: "ReportEventSummary") {
        viewCount: view_count
        lastViewedAt: last_view_at
        lastRequestedCMAAt: last_cma_request_at
      }
      user @type(name: "User") {
        guid
      }
    }
    subjectProperty: subject_property @type(name: "SubjectProperty") {
      address
      addressLat: geo_lat
      addressLng: geo_lon
      addressParts: address_parts
      beds
      baths
      sqft
      garages
      acres
      lotSize: lot_size
    }
  }
`;

export const GET_HOMEBEAT_FEATURE_STATUS = gql`
  query GetHomebeatFeatureStatus {
    features @rest(type: "Feature", path: "user/feature/homebeat") {
      homebeat @type(name: "Feature") {
        offerHomebeat: offer_feature
        hasHomebeat: has_feature
      }
    }
  }
`;

export const GET_HOMEBEATS = gql`
  ${HOMEBEAT_FRAGMENT}
  query GetHomebeats(
    $page: Int = 1
    $limit: Int = 17
    $search: String = ""
    $sortBy: String = ""
    $sortDir: String = "desc"
    $filter: String = "all"
  ) {
    homebeats(
      page: $page
      limit: $limit
      search: $search
      sortBy: $sortBy
      sortDir: $sortDir
      filter: $filter
    )
      @rest(
        type: "Homebeats"
        path: "homebeats?page={args.page}&limit={args.limit}&q={args.search}&sort_by={args.sortBy}&sort_direction={args.sortDir}&filter={args.filter}"
      ) {
      meta @type(name: "HomebeatsMeta") {
        currentPage
        perPage
        totalPages
        totalResults
      }
      results @type(name: "Homebeat") {
        ...Homebeat
      }
    }
  }
`;

export const GET_HOMEBEAT = gql`
  ${HOMEBEAT_FRAGMENT}
  query GetHomebeat($id: ID!) {
    homebeat(id: $id)
      @rest(
        type: "Homebeat"
        path: "automations/{args.id}/edit"
        method: "GET"
      ) {
      ...Homebeat
    }
  }
`;

export const CREATE_HOMEBEAT = gql`
  ${HOMEBEAT_FRAGMENT}
  mutation CreateHomebeat($input: HomebeatInput!) {
    createHomebeat(input: $input)
      @rest(type: "Homebeat", path: "automations", method: "POST") {
      ...Homebeat
    }
  }
`;

export const UPDATE_HOMEBEAT = gql`
  ${HOMEBEAT_FRAGMENT}
  mutation UpdateHomebeat($input: HombeatInput!) {
    updateHomebeat(input: $input)
      @rest(
        type: "Homebeat"
        path: "automations/{args.input.automation.id}"
        method: "PUT"
      ) {
      ...Homebeat
    }
  }
`;

export const DELETE_HOMEBEAT = gql`
  mutation DeleteHomebeat($id: ID!) {
    deleteHomebeat(id: $id)
      @rest(type: "Hombeat", path: "automations/{args.id}", method: "DELETE") {
      id
    }
  }
`;

export const DELETE_HOMEBEATS = gql`
  mutation DeleteHomebeats($ids: String!) {
    deleteHomebeats(ids: $ids)
      @rest(
        type: "Hombeat"
        path: "automations?ids[]={args.ids}"
        method: "DELETE"
      ) {
      id
    }
  }
`;

export const UPLOAD_HOMEBEAT_CONTACTS = gql`
  ${HOMEBEAT_FRAGMENT}
  mutation UploadHombeatContacts($input: HomebeatContactsInput!) {
    uploadHomebeatContacts(input: $input)
      @rest(type: "Homebeat", path: "homebeats/upload", method: "POST") {
      ...Homebeat
    }
  }
`;

export const GET_NEW_HOMEBEAT_DETAILS = gql`
  query GetNewHomebeatDetails {
    newHomebeatDetails @rest(type: "Homebeat", path: "automations/new") {
      welcomeEmail: welcome_email_body
      defaultWelcomeEmail: default_welcome_email_body
    }
  }
`;

export const RESEND_HOMEBEAT = gql`
  mutation ResendHomebeat($input: ResendHomebeatInput!) {
    resendHomebeat(input: $input)
      @rest(
        type: "Hombeat"
        path: "homebeat/{args.input.id}/resend"
        method: "POST"
      ) {
      id
    }
  }
`;
