import { USER_UPDATE_TESTIMONIAL_CREDENTIALS } from "../../actionTypes/user";
import {
  request as updateTestimonialCredentialsRequest,
  resolved as updateTestimonialCredentialsResolved,
  rejected as updateTestimonialCredentialsRejected
} from "./testimonials/update";

export default function (state = {}, action) {
  switch (action.type) {
    case `${USER_UPDATE_TESTIMONIAL_CREDENTIALS}_REQUEST`:
      return updateTestimonialCredentialsRequest(state, action);
    case `${USER_UPDATE_TESTIMONIAL_CREDENTIALS}_RESOLVED`:
      return updateTestimonialCredentialsResolved(state, action);
    case `${USER_UPDATE_TESTIMONIAL_CREDENTIALS}_REJECTED`:
      return updateTestimonialCredentialsRejected(state, action);

    default:
      return state;
  }
}
