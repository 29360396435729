import React, { useState, useRef } from "react";
import { addContactItem } from "../../api/user";
import { useSession } from "../../Session";
import Label from "../common/Label";
import Input from "../common/Input";
import { Select, Option } from "../common/Select";
import Loading from "../common/Loading";
import { Tip, Grid, Cell, Button } from "./styled/user-contact-items-add";

function UserContactItemAdd() {
  const { currentUser, setContactItems, config } = useSession();
  const [isAdding, setIsAdding] = useState(false);
  const [key, setKey] = useState("");
  const [value, setValue] = useState("");
  const methodRef = useRef();
  const isDisabled = !key || !value || isAdding;

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsAdding(true);
    const contactItem = await addContactItem(currentUser.id, { key, value });
    setIsAdding(false);
    setKey("");
    setValue("");
    setContactItems((contactItems) => [...contactItems, contactItem]);
    methodRef.current.focus();
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Grid>
          <Cell>
            <Label htmlFor="contact-method">Contact Method</Label>
            <Select
              ref={methodRef}
              id="contact-method"
              isFullWidth
              value={key}
              onChange={(e) => setKey(e.target.value)}>
              <Option value="" disabled>
                Select a Method
              </Option>
              {config.contactMethods.map(([label, key]) => (
                <Option key={key} value={key}>
                  {label}
                </Option>
              ))}
            </Select>
          </Cell>
          <Cell>
            <Label htmlFor="contact-value">Contact Value</Label>
            <Input
              id="contact-value"
              isFullWidth
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          </Cell>
        </Grid>
        <Button app="cma" disabled={isDisabled}>
          {isAdding && <Loading>Adding</Loading>}
          {!isAdding && "Add"}
        </Button>
      </form>
      <Tip>
        Tip: Enter a method of contact and click 'Add' to add to your contact
        list.
      </Tip>
    </>
  );
}

export default UserContactItemAdd;
