import React, { useState } from "react";
import { Modal } from "@wrstudios/components";
import { useSession } from "../../Session";
import CardCreate from "../common/CardCreate";
import IconCMA from "../icons/report/IconCMA";
import IconBuyerTour from "../icons/report/IconBuyerTour";
import IconProperty from "../icons/report/IconProperty";
import IconFlyer from "../icons/report/IconFlyer";
import IconDocument from "../icons/report/IconDocument";
import { Grid, Cell } from "./styled/home-create-report";

function HomeCreateReport() {
  const { features } = useSession();
  const [showModal, setShowModal] = useState(false);
  const [isHoveringCMA, setIsHoveringCMA] = useState(false);
  const [isHoveringTour, setIsHoveringTour] = useState(false);
  const [isHoveringProperty, setIsHoveringProperty] = useState(false);
  const [isHoveringFlyer, setIsHoveringFlyer] = useState(false);
  const [isHoveringDocument, setIsHoveringDocument] = useState(false);
  const handleClose = () => setShowModal(false);

  return (
    <>
      <CardCreate onClick={() => setShowModal(true)}>
        Create New Report
      </CardCreate>
      {showModal && (
        <Modal onClose={handleClose}>
          <Modal.Content>
            <Modal.Header>
              Create New Report <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <Grid>
                <Cell>
                  <a
                    href="/cmas/new"
                    onClick={handleClose}
                    onMouseEnter={() => setIsHoveringCMA(true)}
                    onMouseLeave={() => setIsHoveringCMA(false)}>
                    <IconCMA hover={isHoveringCMA} />
                  </a>
                </Cell>
                <Cell>
                  <a
                    href="/tours/new"
                    onClick={handleClose}
                    onMouseEnter={() => setIsHoveringTour(true)}
                    onMouseLeave={() => setIsHoveringTour(false)}>
                    <IconBuyerTour hover={isHoveringTour} />
                  </a>
                </Cell>
                <Cell>
                  <a
                    href="/properties/new"
                    onClick={handleClose}
                    onMouseEnter={() => setIsHoveringProperty(true)}
                    onMouseLeave={() => setIsHoveringProperty(false)}>
                    <IconProperty hover={isHoveringProperty} />
                  </a>
                </Cell>
                {features.hasFlyers && (
                  <Cell>
                    <a
                      href="/flyers/new"
                      onClick={handleClose}
                      onMouseEnter={() => setIsHoveringFlyer(true)}
                      onMouseLeave={() => setIsHoveringFlyer(false)}>
                      <IconFlyer
                        viewBox="0 0 233 303"
                        hover={isHoveringFlyer}
                      />
                    </a>
                  </Cell>
                )}
                {features.hasDocuments && (
                  <Cell>
                    <a
                      href="/documents/new"
                      onMouseEnter={() => setIsHoveringDocument(true)}
                      onMouseLeave={() => setIsHoveringDocument(false)}
                      onClick={handleClose}>
                      <IconDocument hover={isHoveringDocument} />
                    </a>
                  </Cell>
                )}
              </Grid>
            </Modal.Body>
          </Modal.Content>
        </Modal>
      )}
    </>
  );
}

export default HomeCreateReport;
