import React, { useState, useEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
import { getHiddenEmail } from "../../../utils/email";
import Label from "../../common/Label";
import Tip from "../../common/Tip";
import { Option } from "../../common/Select";
import IconHomebeat from "../../icons/report/IconHomebeat";
import { frequencies } from "../utils";
import { editAutomation } from "./api";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalClose,
  ModalBody,
  Banner,
  Icon,
  Address,
  CityStateZip,
  Text,
  Email,
  FormGroup,
  Select
} from "./styled/homebeat-viewer-subscription-modal";

function HomebeatViewerSubscriptionModal() {
  const location = useLocation();
  const { agent, automation, lead, subjectProperty } = useHomebeatViewer();
  const query = new URLSearchParams(location.search);
  const [frequency, setFrequency] = useState(query.get("frequency") || "");
  const [showModal, setShowModal] = useState(!!frequency);
  const [isClosing, setIsClosing] = useState(false);
  const hiddenEmail = getHiddenEmail(lead.emailAddress || "");
  const modalContentRef = useRef();

  useEffect(() => {
    if (!showModal) return;
    editAutomation(automation.id, { frequency, api_key: agent.guid });
  }, [showModal, frequency, automation.id, agent.guid]);

  if (!showModal) return null;

  return (
    <Modal
      focusLock={{ returnFocus: true }}
      onClose={() => {
        setIsClosing(true);
        modalContentRef.current.style.transform = `translateX(110%)`;
      }}>
      <ModalContent
        ref={modalContentRef}
        onTransitionEnd={() => isClosing && setShowModal(false)}>
        <ModalHeader>
          Subscription Options
          <ModalClose />
        </ModalHeader>
        <ModalBody>
          <Banner>
            <Icon>
              <IconHomebeat />
            </Icon>
            <div>
              {subjectProperty.street && (
                <Address>{subjectProperty.street}</Address>
              )}
              {subjectProperty.cityStateZip && (
                <CityStateZip>{subjectProperty.cityStateZip}</CityStateZip>
              )}
            </div>
          </Banner>
          {!!hiddenEmail && (
            <Text>
              Emails are being sent to <Email>{hiddenEmail}</Email>
            </Text>
          )}
          <FormGroup>
            <Label htmlFor="frequency">Email frequency</Label>
            <Select
              id="frequency"
              value={frequency}
              onChange={(e) => setFrequency(e.target.value)}>
              {frequencies.map((frequency) => (
                <Option key={frequency.value} value={frequency.value}>
                  {frequency.label}
                </Option>
              ))}
            </Select>
            <Tip>Your changes are saved automatically.</Tip>
          </FormGroup>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

export default HomebeatViewerSubscriptionModal;
