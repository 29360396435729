import React, { useMemo } from "react";
import { ApolloProvider as AP } from "@apollo/react-hooks";
import { ApolloClient, ApolloLink, InMemoryCache } from "apollo-boost";
import { RestLink } from "apollo-link-rest";
import { useSession } from "./Session";

function useClient() {
  const { config } = useSession();
  return useMemo(() => {
    const authRestLink = new ApolloLink((operation, forward) => {
      operation.setContext(({ headers }) => {
        return {
          headers: {
            ...headers,
            Accept: "application/json",
            "X-CSRF-TOKEN": config.csrfToken
          }
        };
      });
      return forward(operation);
    });
    const restLink = new RestLink({
      uri: config.url.endsWith("/") ? config.url : `${config.url}/`
    });
    const client = new ApolloClient({
      link: ApolloLink.from([authRestLink, restLink]),
      cache: new InMemoryCache()
    });
    return client;
  }, [config.url]);
}

function ApolloProvider({ children }) {
  const client = useClient();
  return <AP client={client}>{children}</AP>;
}

export { ApolloProvider };
