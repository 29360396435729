import styled from "styled-components";
import { Container as BaseCheckbox } from "./checkbox";

export const Container = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: pointer;

  ${BaseCheckbox} {
    margin-right: 1rem;
  }
`;
