import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import IconTemplate from "../../icons/IconTemplate";
import IconErrorOutline from "../../icons/material/alert/IconErrorOutline";
import CustomizeModalTemplate from "../connected/CustomizeModalTemplate";
import CustomizeControlsButton from "./CustomizeControlsButton";
import {
  Container,
  Icon,
  Text,
  Title,
  SubTitle
} from "./styled/customize-controls-button";

function CustomizeControlsTemplate({
  title,
  error,
  isFetching,
  hasError,
  hasModified
}) {
  return (
    <CustomizeControlsButton>
      {({ isModalOpen, openModal, closeModal }) => (
        <Fragment>
          <Container
            disabled={isFetching}
            aria-label="Select a Template"
            onClick={openModal}>
            <Icon hasError={hasError}>
              {!isFetching && !hasError && (
                <IconTemplate width={24} height={24} />
              )}
              {isFetching && <Loading />}
              {!isFetching && hasError && (
                <IconErrorOutline width={24} height={24} />
              )}
            </Icon>
            <Text hasError={hasError}>
              <Title>Template</Title>
              {hasModified && (
                <SubTitle>
                  Unsaved Template<sup>*</sup>
                </SubTitle>
              )}
              {hasError && <SubTitle>{error}</SubTitle>}
              {!hasModified && !hasError && (
                <SubTitle dangerouslySetInnerHTML={{ __html: title }} />
              )}
            </Text>
          </Container>
          {isModalOpen && <CustomizeModalTemplate onClose={closeModal} />}
        </Fragment>
      )}
    </CustomizeControlsButton>
  );
}

CustomizeControlsTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  hasModified: PropTypes.bool.isRequired,
  error: PropTypes.string.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired
};

export default CustomizeControlsTemplate;
