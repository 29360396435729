import styled, { css } from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1rem;

  ${({ theme }) => theme.media.sm`
    align-items: baseline;
    margin: 1.4rem;
  `}

  ${({ theme, isPopup, popupDirection, type }) =>
    isPopup &&
    css`
      align-items: baseline;
      max-width: 35.5rem;
      border-radius: 0.4rem;
      box-shadow: ${theme.styleGuide.shadows.modal};
      border: 0.1rem solid ${getBorderColor({ theme, type })};
      position: fixed;
      bottom: 1.4rem;
      left: ${popupDirection === "left" ? "1.4rem" : "auto"};
      right: ${popupDirection === "right" ? "1.4rem" : "auto"};
      z-index: 10;

      ${({ theme }) => theme.media.sm`
        margin: 0;
      `}
    `}

  svg {
    display: inline-block;
    width: 1.6rem;
    height: 1.6rem;
    margin: -0.2rem 1.2rem 0 0;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }

  a {
    font-weight: 700;
    text-decoration: none;
    margin-left: 1.2rem;

    svg {
      margin: 0 0 0 1.2rem;
    }
  }

  ${getTypeStyle};
`;

export const Message = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 1;
  margin-right: 1.2rem;

  ${({ theme }) => theme.media.sm`
    align-items: baseline;
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-right: 1.4rem;
  `}
`;

export const Badge = styled.div`
  font-size: 1rem;
  font-weight: 700;
  line-height: 1rem;
  letter-spacing: 0.045rem;
  margin-right: 1.2rem;
  padding: 0.4rem 0.8rem;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.styleGuide.colors.white};
  background: ${({ theme }) => theme.styleGuide.colors.purple[500]};
  border-radius: 0.9rem;

  ${({ theme }) => theme.media.sm`
    margin-right: 1.4rem;
  `}
`;

export const Close = styled.button`
  padding: 0;
  background-color: transparent;
  border: 0;
  appearance: none;

  svg {
    width: 2rem;
    height: 2rem;
    margin: 0;
  }
`;

function getTypeStyle({ theme, type }) {
  switch (type) {
    case "update":
      return constructTheme({
        color: theme.styleGuide.colors.blue[900],
        backgroundColor: theme.styleGuide.colors.blue[100]
      });
    case "warning":
      return constructTheme({
        color: theme.styleGuide.colors.yellow[900],
        backgroundColor: theme.styleGuide.colors.yellow[100]
      });
    case "error":
      return constructTheme({
        color: theme.styleGuide.colors.red[900],
        backgroundColor: theme.styleGuide.colors.red[100]
      });
    case "success":
      return constructTheme({
        color: theme.styleGuide.colors.green[900],
        backgroundColor: theme.styleGuide.colors.green[100]
      });
    case "marketing":
      return constructTheme({
        color: theme.styleGuide.colors.white,
        backgroundColor: theme.styleGuide.colors.suite[800]
      });
    default:
      return constructTheme({
        color: theme.styleGuide.colors.gray[900],
        backgroundColor: theme.styleGuide.colors.gray[100]
      });
  }
}

function constructTheme({ color, backgroundColor }) {
  return css`
    color: ${color};
    background-color: ${backgroundColor};

    svg {
      fill: ${color};
    }

    a {
      color: ${color};

      svg {
        fill: currentColor;
      }
    }
  `;
}

function getBorderColor({ theme, type }) {
  switch (type) {
    case "update":
      return theme.styleGuide.colors.blue[200];
    case "warning":
      return theme.styleGuide.colors.yellow[200];
    case "error":
      return theme.styleGuide.colors.red[200];
    case "success":
      return theme.styleGuide.colors.green[200];
    case "marketing":
      return theme.styleGuide.colors.suite[200];
    default:
      return theme.styleGuide.colors.gray[200];
  }
}
