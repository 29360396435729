import { gql } from "apollo-boost";

export const GET_FLYERS = gql`
  query GetFlyers($page: Int = 1, $limit: Int = 17, $search: String = "") {
    flyers(page: $page, limit: $limit, search: $search)
      @rest(
        type: "Flyers"
        path: "flyers?page={args.page}&limit={args.limit}&q={args.search}"
      ) {
      meta @type(name: "FlyersMeta") {
        currentPage
        perPage
        totalPages
        totalResults
      }
      results @type(name: "Flyer") {
        id
        guid
        title
        type
        status
        notes
        thumbnail: thumbnail_url_with_timestamp
        createdWith: created_via
        pdfLink: pdf_permalink
        addressParts: report_address_parts
        updatedAt: updated_at
        eventSummary: event_summary @type(name: "ReportEventSummary") {
          viewCount: view_count
          lastViewedAt: last_view_at
        }
      }
    }
  }
`;

export const DELETE_FLYER = gql`
  mutation DeleteFlyer($id: ID!) {
    deleteFlyer(id: $id)
      @rest(type: "Flyer", path: "flyers/{args.id}", method: "DELETE") {
      id
    }
  }
`;
