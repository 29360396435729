import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, theme: requestState(state.theme) };
}

export function resolved(state, action) {
  const data = action.payload[1];
  const { customize = {} } = data;

  return {
    ...state,
    ...customize,
    refresh: true,
    theme: resolvedState({
      ...state.theme,
      selected: { ...action.payload.theme }
    })
  };
}

export function rejected(state, action) {
  return { ...state, theme: rejectedState(state.theme, action.payload) };
}
