import React, { useState } from "react";
import InvestorDisqualified from "../investor/InvestorDisqualified";
import InvestorGeneric from "../investor/InvestorGeneric";
import InvestorOpendoor from "../investor/InvestorOpendoor";
import { Container } from "./styled/publish-cash-offer";

function PublishCashOffer({
  cashOffer,
  report,
  leads,
  isEligible,
  showCashOfferStep,
  showIntroBack,
  showEstimate,
  hasAcceptedOffers,
  estimate,
  setLocalShowCashOffer
}) {
  const [localLeads, setLeads] = useState(leads || []);
  const hasMoreLeads = localLeads.length - 1 > 0;
  const [lead, setLead] = useState(localLeads[0] || { investor: {} });
  const [previousLead, setPreviousLead] = useState(lead);
  const investorToken = lead.investor.token;
  const isDisqualified =
    investorToken === "disqualified" || isEligible === false;

  return (
    <Container>
      {!isDisqualified && (
        <>
          {investorToken !== "opendoor" && (
            <InvestorGeneric
              report={report}
              lead={lead}
              setLead={setLead}
              showCashOfferStep={!!showCashOfferStep}
              showIntroBack={showIntroBack}
              setLocalShowCashOffer={setLocalShowCashOffer}
            />
          )}
          {investorToken === "opendoor" && (
            <InvestorOpendoor
              cashOffer={cashOffer}
              report={report}
              lead={lead}
              setLead={setLead}
              showCashOfferStep={showCashOfferStep}
              showIntroBack={showIntroBack}
              showEstimate={showEstimate}
              hasAcceptedOffers={hasAcceptedOffers}
              estimate={estimate}
              setLocalShowCashOffer={setLocalShowCashOffer}
            />
          )}
        </>
      )}
      {isDisqualified && (
        <InvestorDisqualified
          report={report}
          lead={lead}
          previousLead={previousLead}
          setLead={setLead}
          setPreviousLead={setPreviousLead}
          hasMoreLeads={hasMoreLeads}
          setLeads={setLeads}
        />
      )}
    </Container>
  );
}

export default PublishCashOffer;
