import { gql } from "apollo-boost";

export const GET_DOCUMENTS = gql`
  query GetDocuments($page: Int = 1, $limit: Int = 17, $search: String = "") {
    documents(page: $page, limit: $limit, search: $search)
      @rest(
        type: "Documents"
        path: "documents?page={args.page}&limit={args.limit}&q={args.search}"
      ) {
      meta @type(name: "DocumentsMeta") {
        currentPage
        perPage
        totalPages
        totalResults
      }
      results @type(name: "Document") {
        id
        guid
        title
        type
        status
        notes
        thumbnail: thumbnail_url_with_timestamp
        createdWith: created_via
        pdfLink: pdf_permalink
        addressParts: report_address_parts
        updatedAt: updated_at
        eventSummary: event_summary @type(name: "ReportEventSummary") {
          viewCount: view_count
          lastViewedAt: last_view_at
        }
      }
    }
  }
`;

export const DELETE_DOCUMENT = gql`
  mutation DeleteDocument($id: ID!) {
    deleteDocument(id: $id)
      @rest(type: "Document", path: "documents/{args.id}", method: "DELETE") {
      id
    }
  }
`;
