import { sort } from "timsort";
import { findPage } from "../../../utils/page";

function getNextPageIndex(pages, page) {
  const pagesWithGreaterIndexes = pages.filter(
    (p) => p.position > page.position
  );

  sort(pagesWithGreaterIndexes, (a, b) => a.position - b.position);

  let index = pages.findIndex((p) => {
    if (pagesWithGreaterIndexes[0]) {
      return p.key === pagesWithGreaterIndexes[0].key;
    }
  });

  if (index === -1) {
    index = page.position;
  }

  return index;
}

const nonDynamicRdcPages = [
  "rdc_listing_toolkit",
  "rdc_listing_promotion",
  "rdc_listings_that_sell_checklist"
];

export function add(state, action) {
  const { key } = action.payload;
  const inactivePages = [...state.inactive.pages];
  const activePages = [...state.active.pages];
  const marketingPages = [...state.powerPacks.pages.marketing];
  const luxPages = [...state.powerPacks.pages.lux];
  const tomPages = [...state.powerPacks.pages.tom];
  let newState = { ...state };
  let { index, page } =
    findPage(key, inactivePages) ||
    findPage(key, marketingPages) ||
    findPage(key, luxPages) ||
    findPage(key, tomPages);

  if (page) {
    page = { ...page };

    const introductionPages = activePages.filter(
      (p) => p.parent === "introduction"
    );

    // If we are inserting a non dynamic page and there's introduction pages
    // in the report, find the last introduction page and insert after that
    // page, else, insert as normal in the analysis section.
    if (nonDynamicRdcPages.includes(page.key) && !!introductionPages.length) {
      const lastPage = introductionPages[introductionPages.length - 1];
      const lastPageIndex = getNextPageIndex(activePages, lastPage);

      if (lastPage) {
        activePages.splice(lastPageIndex, 0, page);
      }
    } else {
      const nextPageIndex = getNextPageIndex(activePages, page);
      activePages.splice(nextPageIndex, 0, page);
    }

    newState.active = { ...state.active, pages: activePages };

    switch (page.inactive_group) {
      case "inactive_marketing_2013":
      case "inactive_marketing_luxe":
        marketingPages.splice(index, 1);
        luxPages.splice(index, 1);
        newState.powerPacks = {
          ...state.powerPacks,
          pages: {
            ...state.powerPacks.pages,
            lux: luxPages,
            marketing: marketingPages
          }
        };
        break;
      case "inactive_marketing_tom_ferry":
        tomPages.splice(index, 1);
        newState.powerPacks = {
          ...state.powerPacks,
          pages: { ...state.powerPacks.pages, tom: tomPages }
        };
        break;
      default:
        if (
          page.parent === "listings" ||
          (page.parent !== "listings" && page.key !== "listings")
        ) {
          inactivePages.splice(index, 1);
        }
        newState.inactive = { ...state.inactive, pages: inactivePages };
        break;
    }
  }

  return { ...newState, template: { ...newState.template, hasModified: true } };
}
