import React from "react";

function IconHomebeat() {
  return (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.50922 0.432874C7.79098 0.189042 8.20902 0.189042 8.49078 0.432874L15.4908 6.49057C15.6554 6.63303 15.75 6.83999 15.75 7.05769V16C15.75 16.4142 15.4142 16.75 15 16.75H1C0.585786 16.75 0.25 16.4142 0.25 16V7.05769C0.25 6.83999 0.344596 6.63303 0.509217 6.49057L7.50922 0.432874ZM1.75 7.4005V15.25H14.25V7.4005L8 1.99184L1.75 7.4005Z"
        fill="#FD5154"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 13L7.565 12.57C6.02 11.0414 5 10.0327 5 8.79836C5 7.78965 5.7245 7 6.65 7C7.172 7 7.673 7.26485 8 7.68174C8.327 7.26485 8.828 7 9.35 7C10.2755 7 11 7.78965 11 8.79836C11 10.0327 9.98 11.0414 8.435 12.57L8 13Z"
        fill="#FD5154"
      />
    </svg>
  );
}

export default IconHomebeat;
