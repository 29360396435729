import React from "react";

function IconCheckmarkOutline() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26">
      <g fill="none" fillRule="evenodd" stroke="#20B575" strokeWidth="1.5">
        <path d="M18 9.5l-7.5 7L8 14" />
        <path d="M24.5 13a11.5 11.5 0 11-23 0 11.5 11.5 0 0123 0h0z" />
      </g>
    </svg>
  );
}

export default IconCheckmarkOutline;
