import React from "react";
import PropTypes from "prop-types";
import IconHouse from "../icons/IconHouse";

function ListingsStatsCountItem({ label, value, color }) {
  return (
    <li className="flex items-start mt-8">
      <span
        className={`flex items-center relative text-xs rounded px-4 py-2 mr-4 listings-stats-counts-marker`}
        style={{ backgroundColor: color, color }}>
        <span className="text-white">
          <IconHouse width="12" height="11" />
        </span>
      </span>

      <span className="capitalize">
        {label}: {value}
      </span>
    </li>
  );
}

ListingsStatsCountItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired,
  color: PropTypes.string.isRequired
};

export default ListingsStatsCountItem;
