import React from "react";

function IconCashOffer() {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 14.5C11.5899 14.5 14.5 11.5899 14.5 8C14.5 4.41015 11.5899 1.5 8 1.5C4.41015 1.5 1.5 4.41015 1.5 8C1.5 11.5899 4.41015 14.5 8 14.5ZM8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16Z"
        fill="#20B575"
      />
      <path
        d="M7.72607 12.7627H8.34375V11.9033C9.86914 11.8013 10.9595 10.9849 10.9595 9.63672C10.9595 8.46045 10.1914 7.86963 8.7251 7.53662L8.34375 7.45605V5.56006C8.94531 5.646 9.34814 5.979 9.42334 6.55908H10.8574C10.8037 5.2915 9.83691 4.45361 8.34375 4.34619V3.4707H7.72607V4.34082C6.24902 4.43213 5.19092 5.26465 5.19092 6.54834C5.19092 7.7085 5.96436 8.33691 7.36621 8.65381L7.72607 8.73975V10.7002C6.99561 10.6196 6.60889 10.2383 6.52295 9.69043H5.0835C5.12109 11.0332 6.2168 11.8228 7.72607 11.9087V12.7627ZM6.69482 6.40869C6.69482 5.979 7.04932 5.62988 7.72607 5.54932V7.31104C7.00098 7.11768 6.69482 6.83838 6.69482 6.40869ZM9.46094 9.80322C9.46094 10.292 9.09033 10.625 8.34375 10.7002V8.87939C9.17627 9.08887 9.46094 9.33057 9.46094 9.80322Z"
        fill="#20B575"
      />
    </svg>
  );
}

export default IconCashOffer;
