import { initialRequestState } from "../../utils/redux";

export function resetDefaultRequest(state, action) {
  const { key } = action.payload;

  return { ...state, [key]: initialRequestState(state[key]) };
}

export function resetSelectedPage(state) {
  return { ...state, customPages: { ...state.customPages, selected: {} } };
}
