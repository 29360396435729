import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { useQueryString } from "../../utils/url";
import { useSession } from "../../Session";
import Wrapper from "../common/Wrapper";
import Search from "../common/Search";
import CardCreate from "../common/CardCreate";
import GettingStarted from "../common/CardGettingStarted";
import { ReportCardSkeleton } from "../common/ReportCard";
import Notifications from "../common/Notifications";
import { GET_FLYERS } from "./queries";
import FlyerCard from "./FlyerCard";
import {
  Container,
  Header,
  Grid,
  Cell,
  Title,
  Paginate
} from "./styled/flyers";

function Flyers() {
  const { config } = useSession();
  const { params } = useQueryString();
  const [search, setSearch] = useState(params.q || "");
  const [currentPage, setCurrentPage] = useState(params.page || 1);
  const [getFlyers, { data, loading }] = useLazyQuery(GET_FLYERS, {
    fetchPolicy: "cache-and-network"
  });
  const meta = get(data, "flyers.meta", {});
  const flyers = get(data, "flyers.results", []);
  const showOnboardingVideo = !loading && meta.totalResults < 5;

  useEffect(() => {
    getFlyers({ variables: { page: Number(currentPage || 1), search } });
  }, [currentPage, search]);

  return (
    <>
      <Notifications />
      <Wrapper>
        <Container>
          <Header>
            <Title>Flyers</Title>
            <Search
              defaultValue={search}
              placeholder="Search Flyers"
              onSearch={(searchTerm) => {
                setSearch(searchTerm);
                setCurrentPage(1);
              }}
              onClear={() => {
                setSearch("");
                setCurrentPage(1);
              }}
            />
          </Header>
          <Grid>
            <Cell>
              <CardCreate as="a" href="/flyers/new">
                Create Flyer
              </CardCreate>
            </Cell>
            {loading &&
              new Array(5).fill().map((_, index) => (
                <Cell key={index}>
                  <ReportCardSkeleton />
                </Cell>
              ))}
            {!loading &&
              flyers.map((flyer) => (
                <Cell key={flyer.id}>
                  <FlyerCard
                    id={flyer.id}
                    guid={flyer.guid}
                    title={flyer.title}
                    type={flyer.type}
                    status={flyer.status}
                    pdfLink={flyer.pdfLink}
                    notes={flyer.notes}
                    thumbnail={flyer.thumbnail}
                    createdWith={flyer.createdWith}
                    address={flyer.addressParts[0]}
                    cityStateZip={flyer.addressParts[1]}
                    viewCount={flyer.eventSummary.viewCount}
                    lastViewedAt={flyer.eventSummary.lastViewedAt}
                    updatedAt={flyer.updatedAt}
                    refetchQuery={GET_FLYERS}
                  />
                </Cell>
              ))}
            {showOnboardingVideo && (
              <Cell>
                <GettingStarted
                  videoTitle="Getting Started with Flyers"
                  videoId={config.flyerKickStartVideoId}>
                  Getting Started with Flyers
                </GettingStarted>
              </Cell>
            )}
          </Grid>
          {!loading && (
            <Paginate
              scrollToTopOnPageChange
              currentPage={meta.currentPage}
              totalPages={meta.totalPages}
              perPage={meta.perPage}
              totalResults={meta.totalResults}
              onPageChange={setCurrentPage}
            />
          )}
        </Container>
      </Wrapper>
    </>
  );
}

export default Flyers;
