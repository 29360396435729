import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useRef
} from "react";
import { ThemeProvider } from "styled-components";
import { useParams } from "react-router-dom";
import { get } from "lodash";
import { transformListing } from "../../../utils/listing";
import { allAreAcreage } from "../../../utils/listings";
import { useSession } from "../../../Session";
import { transformSubjectProperty } from "../../../utils/subject-property";
import { getHomebeat } from "./api";

const HomebeatViewerContext = createContext();

function HomebeatViewerProvider({ children }) {
  const { guid } = useParams();
  const { currentUser } = useSession();
  const [loading, setLoading] = useState(true);
  const [automation, setAutomation] = useState({});
  const [lead, setLead] = useState({});
  const [listings, setListings] = useState([]);
  const [includedListingIds, setIncludedListingIds] = useState([]);
  const [report, setReport] = useState({});
  const [subjectProperty, setSubjectProperty] = useState({});
  const [agent, setAgent] = useState(window.agent || {});
  const [isAcreageReport, setIsAcreageReport] = useState(false);
  const isReportOwnedByCurrentAgent = agent.guid === currentUser.guid;
  const comparablesScrollPosition = useRef(0);
  const landMeasurement = (window.agent || {}).landMeasurement;
  const showAcresInsteadOfLotSize = landMeasurement === "acres";

  useEffect(() => {
    getHomebeat(guid).then((homebeat) => {
      const listings = (homebeat.listings || []).map(transformListing);

      setAutomation(homebeat.automation || {});
      setLead(homebeat.lead || {});
      setListings(listings);
      setIncludedListingIds(listings.map(({ id }) => id));
      setReport(homebeat.report || {});
      setSubjectProperty(
        transformSubjectProperty(homebeat.subjectProperty || {})
      );
      setAgent(homebeat.agent || {});
      setIsAcreageReport(allAreAcreage(listings));
      setLoading(false);
    });
  }, [guid]);

  let primaryColor = get(window.theme, "colors.primary");
  let secondaryColor = get(window.theme, "colors.secondary");

  if (primaryColor && !primaryColor.startsWith("#"))
    primaryColor = `#${primaryColor}`;
  if (secondaryColor && !secondaryColor.startsWith("#"))
    secondaryColor = `#${secondaryColor}`;

  const theme = {
    homebeat: {
      colors: {
        primary: primaryColor,
        secondary: secondaryColor
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <HomebeatViewerContext.Provider
        value={{
          loading,
          agent,
          theme,
          automation,
          setAutomation,
          lead,
          setLead,
          listings,
          setListings,
          includedListingIds,
          setIncludedListingIds,
          report,
          setReport,
          subjectProperty,
          setSubjectProperty,
          showAcresInsteadOfLotSize,
          isAcreageReport,
          isReportOwnedByCurrentAgent,
          comparablesScrollPosition
        }}>
        {children}
      </HomebeatViewerContext.Provider>
    </ThemeProvider>
  );
}

function useHomebeatViewer() {
  const context = useContext(HomebeatViewerContext);
  if (!context) {
    throw new Error(
      "`useHomebeatViewer` must be used within a `<HomebeatViewerProvider />`"
    );
  }
  return context;
}

export { HomebeatViewerProvider, useHomebeatViewer };
