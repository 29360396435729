import React from "react";

function IconBackArrow(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="5 2.5 24 24 "
      {...props}>
      <defs>
        <path id="b-icon-back-arrow" d="M16 21l5.5 5 5.5-5" />
        <filter
          x="-140.1%"
          y="-228.3%"
          width="380.2%"
          height="715.3%"
          filterUnits="objectBoundingBox"
          id="a-icon-back-arrow">
          <feMorphology
            radius="1"
            operator="dilate"
            in="SourceAlpha"
            result="shadowSpreadOuter1"
          />
          <feOffset
            dy="4"
            in="shadowSpreadOuter1"
            result="shadowOffsetOuter1"
          />
          <feMorphology radius="1" in="SourceAlpha" result="shadowInner" />
          <feOffset dy="4" in="shadowInner" result="shadowInner" />
          <feComposite
            in="shadowOffsetOuter1"
            in2="shadowInner"
            operator="out"
            result="shadowOffsetOuter1"
          />
          <feGaussianBlur
            stdDeviation="4"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          />
          <feColorMatrix
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.1 0"
            in="shadowBlurOuter1"
          />
        </filter>
      </defs>
      <g
        transform="rotate(90 23 16)"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round">
        <use
          fill="#000"
          filter="url(#a-icon-back-arrow)"
          xlinkHref="#b-icon-back-arrow"
        />
        <use
          stroke="currentColor"
          strokeWidth="2"
          xlinkHref="#b-icon-back-arrow"
        />
      </g>
    </svg>
  );
}

export default IconBackArrow;
