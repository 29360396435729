import React from "react";
import PropTypes from "prop-types";
import IconClose from "../icons/material/navigation/IconClose";
import {
  Close,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  Wistia
} from "./styled/wistia-modal";

function WistiaModal({ videoId, videoTitle, onClose, ...props }) {
  return (
    <Modal {...props} onClose={onClose}>
      <Close onClick={onClose}>
        <IconClose />
      </Close>
      <ModalContent>
        <ModalHeader>{videoTitle}</ModalHeader>
        <ModalBody>
          <Wistia videoId={videoId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

WistiaModal.defaultProps = {
  onClose: () => {}
};

WistiaModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default WistiaModal;
