import React from "react";

function IconFlyer() {
  return (
    <svg
      width="16"
      height="18"
      viewBox="0 0 16 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.5 1.25C0.5 0.835786 0.835786 0.5 1.25 0.5H12.25C12.4489 0.5 12.6397 0.579018 12.7803 0.71967L15.7803 3.71967C15.921 3.86032 16 4.05109 16 4.25V17.25C16 17.6642 15.6642 18 15.25 18H1.25C0.835786 18 0.5 17.6642 0.5 17.25V1.25ZM2 2V16.5H14.5V4.56066L11.9393 2H2Z"
        fill="#20B575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 10.5C3.25 10.0858 3.58579 9.75 4 9.75H12.5C12.9142 9.75 13.25 10.0858 13.25 10.5C13.25 10.9142 12.9142 11.25 12.5 11.25H4C3.58579 11.25 3.25 10.9142 3.25 10.5Z"
        fill="#20B575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.25 13.5C3.25 13.0858 3.58579 12.75 4 12.75H12.5C12.9142 12.75 13.25 13.0858 13.25 13.5C13.25 13.9142 12.9142 14.25 12.5 14.25H4C3.58579 14.25 3.25 13.9142 3.25 13.5Z"
        fill="#20B575"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.5 4.25C3.5 3.83579 3.83579 3.5 4.25 3.5H7.25C7.66421 3.5 8 3.83579 8 4.25V7.25C8 7.66421 7.66421 8 7.25 8H4.25C3.83579 8 3.5 7.66421 3.5 7.25V4.25ZM5 5V6.5H6.5V5H5Z"
        fill="#20B575"
      />
    </svg>
  );
}

export default IconFlyer;
