import React from "react";
import { Container, Body, Footer } from "./styled/card";

function Card(props) {
  return <Container {...props} />;
}

function CardBody(props) {
  return <Body {...props} />;
}

function CardFooter(props) {
  return <Footer {...props} />;
}

export { Card, CardBody, CardFooter };
