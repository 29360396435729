import styled from "styled-components";
import { Slider as BaseSlider } from "@wrstudios/components";

export const Slider = styled(BaseSlider)`
  width: 100%;
  height: 26rem;
  border-radius: 0.4rem;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    max-width: initial;
    max-height: initial;
    object-fit: cover;
  }
`;
