import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../Icon";

function IconLayoutFourProperties(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#576474"
            d="M46.5 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.62-7.2-8.61 7.2a1.51 1.51 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-3 0v-7.89h-.75a1.5 1.5 0 0 1-1-2.65L35.73 44a1.48 1.48 0 0 1 1.92 0l12.06 10a1.5 1.5 0 0 1-1 2.65H48v7.89a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#c4c8d0"
            d="M52.57 76.32H20.82a5.42 5.42 0 0 1-5.42-5.41V38.23a5.42 5.42 0 0 1 5.42-5.41H53a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.43 5.41zm-31.75-40.5a2.42 2.42 0 0 0-2.42 2.41v32.68a2.42 2.42 0 0 0 2.42 2.41h31.75A2.41 2.41 0 0 0 55 70.91V37.84a2 2 0 0 0-2-2zM48.37 87.82h7.49v3h-7.49zM18.88 87.82h25.4v3h-25.4zM18.88 98.06h36.99v3H18.88zM27.6 108.3h28.26v3H27.6zM18.88 108.3h4.34v3h-4.34zM18.88 126.78h7.49v3h-7.49zM30.46 126.78h25.4v3h-25.4zM18.88 137.02h36.99v3H18.88zM18.88 147.26h28.26v3H18.88zM51.52 147.26h4.34v3h-4.34zM48.37 164.19h7.49v3h-7.49zM18.88 164.19h25.4v3h-25.4zM18.88 174.43h36.99v3H18.88zM27.6 184.67h28.26v3H27.6zM18.88 184.67h4.34v3h-4.34zM18.88 203.15h7.49v3h-7.49zM30.46 203.15h25.4v3h-25.4zM18.88 213.39h36.99v3H18.88zM18.88 223.63h28.26v3H18.88zM51.52 223.63h4.34v3h-4.34z"
          />
          <path
            fill="#576474"
            d="M99.7 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.61-7.2-8.62 7.2a1.45 1.45 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-3 0v-7.89h-.74a1.5 1.5 0 0 1-1-2.65L88.94 44a1.48 1.48 0 0 1 1.92 0l12 10.06a1.5 1.5 0 0 1-1 2.65h-.75v7.89a1.5 1.5 0 0 1-1.41 1.46z"
          />
          <path
            fill="#c4c8d0"
            d="M105.77 76.32H74a5.42 5.42 0 0 1-5.41-5.41V38.23A5.42 5.42 0 0 1 74 32.82h32.15a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.38 5.41zM74 35.82a2.41 2.41 0 0 0-2.41 2.41v32.68A2.41 2.41 0 0 0 74 73.32h31.75a2.42 2.42 0 0 0 2.42-2.41V37.84a2 2 0 0 0-2-2zM72.08 87.82h7.49v3h-7.49zM83.67 87.82h25.4v3h-25.4zM72.08 98.06h36.99v3H72.08zM72.08 108.3h28.26v3H72.08zM104.72 108.3h4.34v3h-4.34zM101.58 126.78h7.49v3h-7.49zM72.08 126.78h25.4v3h-25.4zM72.08 137.02h36.99v3H72.08zM80.8 147.26h28.26v3H80.8zM72.08 147.26h4.34v3h-4.34zM72.08 164.19h7.49v3h-7.49zM83.67 164.19h25.4v3h-25.4zM72.08 174.43h36.99v3H72.08zM72.08 184.67h28.26v3H72.08zM104.72 184.67h4.34v3h-4.34zM101.58 203.15h7.49v3h-7.49zM72.08 203.15h25.4v3h-25.4zM72.08 213.39h36.99v3H72.08zM80.8 223.63h28.26v3H80.8zM72.08 223.63h4.34v3h-4.34z"
          />
          <path
            fill="#576474"
            d="M152.91 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.62-7.2-8.61 7.2a1.49 1.49 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5v-7.89h-.75a1.5 1.5 0 0 1-1-2.65l12-10.06a1.48 1.48 0 0 1 1.92 0L156.12 54a1.5 1.5 0 0 1-1 2.65h-.74v7.89a1.5 1.5 0 0 1-1.47 1.52z"
          />
          <path
            fill="#c4c8d0"
            d="M159 76.32h-31.77a5.42 5.42 0 0 1-5.42-5.41V38.23a5.42 5.42 0 0 1 5.42-5.41h32.14a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.37 5.41zm-31.75-40.5a2.42 2.42 0 0 0-2.42 2.41v32.68a2.42 2.42 0 0 0 2.42 2.41H159a2.41 2.41 0 0 0 2.41-2.41V37.84a2 2 0 0 0-2-2zM154.78 87.82h7.49v3h-7.49zM125.29 87.82h25.4v3h-25.4zM125.29 98.06h36.98v3h-36.98zM134.01 108.3h28.26v3h-28.26zM125.29 108.3h4.34v3h-4.34zM125.29 126.78h7.49v3h-7.49zM136.87 126.78h25.4v3h-25.4zM125.29 137.02h36.98v3h-36.98zM125.29 147.26h28.26v3h-28.26zM157.93 147.26h4.34v3h-4.34zM154.78 164.19h7.49v3h-7.49zM125.29 164.19h25.4v3h-25.4zM125.29 174.43h36.98v3h-36.98zM134.01 184.67h28.26v3h-28.26zM125.29 184.67h4.34v3h-4.34zM125.29 203.15h7.49v3h-7.49zM136.87 203.15h25.4v3h-25.4zM125.29 213.39h36.98v3h-36.98zM125.29 223.63h28.26v3h-28.26zM157.93 223.63h4.34v3h-4.34z"
          />
          <path
            fill="#576474"
            d="M206.11 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.61-7.2-8.62 7.2a1.51 1.51 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-3 0v-7.89h-.74a1.5 1.5 0 0 1-1-2.65L195.35 44a1.48 1.48 0 0 1 1.92 0l12 10.06a1.5 1.5 0 0 1-1 2.65h-.75v7.89a1.5 1.5 0 0 1-1.41 1.46z"
          />
          <path
            fill="#c4c8d0"
            d="M212.18 76.32h-31.75a5.42 5.42 0 0 1-5.43-5.41V38.23a5.42 5.42 0 0 1 5.41-5.41h32.15a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.38 5.41zm-31.75-40.5a2.41 2.41 0 0 0-2.43 2.41v32.68a2.41 2.41 0 0 0 2.41 2.41h31.75a2.42 2.42 0 0 0 2.42-2.41V37.84a2 2 0 0 0-2-2zM207.99 87.82h7.49v3h-7.49zM178.49 87.82h25.4v3h-25.4zM178.49 98.06h36.99v3h-36.99zM187.21 108.3h28.26v3h-28.26zM178.49 108.3h4.34v3h-4.34zM178.49 126.78h7.49v3h-7.49zM190.08 126.78h25.4v3h-25.4zM178.49 137.02h36.99v3h-36.99zM178.49 147.26h28.26v3h-28.26zM211.13 147.26h4.34v3h-4.34zM207.99 164.19h7.49v3h-7.49zM178.49 164.19h25.4v3h-25.4zM178.49 174.43h36.99v3h-36.99zM187.21 184.67h28.26v3h-28.26zM178.49 184.67h4.34v3h-4.34zM48.37 241.25h7.49v3h-7.49zM18.88 241.25h25.4v3h-25.4zM18.88 251.49h36.99v3H18.88zM27.6 261.73h28.26v3H27.6zM18.88 261.73h4.34v3h-4.34zM72.08 241.25h7.49v3h-7.49zM83.67 241.25h25.4v3h-25.4zM72.08 251.49h36.99v3H72.08zM72.08 261.73h28.26v3H72.08zM104.72 261.73h4.34v3h-4.34zM154.78 241.25h7.49v3h-7.49zM125.29 241.25h25.4v3h-25.4zM125.29 251.49h36.98v3h-36.98zM134.01 261.73h28.26v3h-28.26zM125.29 261.73h4.34v3h-4.34zM207.99 241.25h7.49v3h-7.49zM178.49 241.25h25.4v3h-25.4zM178.49 251.49h36.99v3h-36.99zM187.21 261.73h28.26v3h-28.26zM178.49 261.73h4.34v3h-4.34zM178.49 203.15h7.49v3h-7.49zM190.08 203.15h25.4v3h-25.4zM178.49 213.39h36.99v3h-36.99zM178.49 223.63h28.26v3h-28.26zM211.13 223.63h4.34v3h-4.34z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#20b575"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#20b575"
            d="M46.5 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.62-7.2-8.61 7.2a1.51 1.51 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-3 0v-7.89h-.75a1.5 1.5 0 0 1-1-2.65L35.73 44a1.48 1.48 0 0 1 1.92 0l12.06 10a1.5 1.5 0 0 1-1 2.65H48v7.89a1.5 1.5 0 0 1-1.5 1.52z"
          />
          <path
            fill="#20b575"
            d="M52.57 76.32H20.82a5.42 5.42 0 0 1-5.42-5.41V38.23a5.42 5.42 0 0 1 5.42-5.41H53a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.43 5.41zm-31.75-40.5a2.42 2.42 0 0 0-2.42 2.41v32.68a2.42 2.42 0 0 0 2.42 2.41h31.75A2.41 2.41 0 0 0 55 70.91V37.84a2 2 0 0 0-2-2zM48.37 87.82h7.49v3h-7.49zM18.88 87.82h25.4v3h-25.4zM18.88 98.06h36.99v3H18.88zM27.6 108.3h28.26v3H27.6zM18.88 108.3h4.34v3h-4.34zM18.88 126.78h7.49v3h-7.49zM30.46 126.78h25.4v3h-25.4zM18.88 137.02h36.99v3H18.88zM18.88 147.26h28.26v3H18.88zM51.52 147.26h4.34v3h-4.34zM48.37 164.19h7.49v3h-7.49zM18.88 164.19h25.4v3h-25.4zM18.88 174.43h36.99v3H18.88zM27.6 184.67h28.26v3H27.6zM18.88 184.67h4.34v3h-4.34zM18.88 203.15h7.49v3h-7.49zM30.46 203.15h25.4v3h-25.4zM18.88 213.39h36.99v3H18.88zM18.88 223.63h28.26v3H18.88zM51.52 223.63h4.34v3h-4.34zM99.7 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.61-7.2-8.62 7.2a1.45 1.45 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-3 0v-7.89h-.74a1.5 1.5 0 0 1-1-2.65L88.94 44a1.48 1.48 0 0 1 1.92 0l12 10.06a1.5 1.5 0 0 1-1 2.65h-.75v7.89a1.5 1.5 0 0 1-1.41 1.46z"
          />
          <path
            fill="#20b575"
            d="M105.77 76.32H74a5.42 5.42 0 0 1-5.41-5.41V38.23A5.42 5.42 0 0 1 74 32.82h32.15a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.38 5.41zM74 35.82a2.41 2.41 0 0 0-2.41 2.41v32.68A2.41 2.41 0 0 0 74 73.32h31.75a2.42 2.42 0 0 0 2.42-2.41V37.84a2 2 0 0 0-2-2zM72.08 87.82h7.49v3h-7.49zM83.67 87.82h25.4v3h-25.4zM72.08 98.06h36.99v3H72.08zM72.08 108.3h28.26v3H72.08zM104.72 108.3h4.34v3h-4.34zM101.58 126.78h7.49v3h-7.49zM72.08 126.78h25.4v3h-25.4zM72.08 137.02h36.99v3H72.08zM80.8 147.26h28.26v3H80.8zM72.08 147.26h4.34v3h-4.34zM72.08 164.19h7.49v3h-7.49zM83.67 164.19h25.4v3h-25.4zM72.08 174.43h36.99v3H72.08zM72.08 184.67h28.26v3H72.08zM104.72 184.67h4.34v3h-4.34zM101.58 203.15h7.49v3h-7.49zM72.08 203.15h25.4v3h-25.4zM72.08 213.39h36.99v3H72.08zM80.8 223.63h28.26v3H80.8zM72.08 223.63h4.34v3h-4.34zM152.91 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.62-7.2-8.61 7.2a1.49 1.49 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-1.5 1.5 1.5 1.5 0 0 1-1.5-1.5v-7.89h-.75a1.5 1.5 0 0 1-1-2.65l12-10.06a1.48 1.48 0 0 1 1.92 0L156.12 54a1.5 1.5 0 0 1-1 2.65h-.74v7.89a1.5 1.5 0 0 1-1.47 1.52z"
          />
          <path
            fill="#20b575"
            d="M159 76.32h-31.77a5.42 5.42 0 0 1-5.42-5.41V38.23a5.42 5.42 0 0 1 5.42-5.41h32.14a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.37 5.41zm-31.75-40.5a2.42 2.42 0 0 0-2.42 2.41v32.68a2.42 2.42 0 0 0 2.42 2.41H159a2.41 2.41 0 0 0 2.41-2.41V37.84a2 2 0 0 0-2-2zM154.78 87.82h7.49v3h-7.49zM125.29 87.82h25.4v3h-25.4zM125.29 98.06h36.98v3h-36.98zM134.01 108.3h28.26v3h-28.26zM125.29 108.3h4.34v3h-4.34zM125.29 126.78h7.49v3h-7.49zM136.87 126.78h25.4v3h-25.4zM125.29 137.02h36.98v3h-36.98zM125.29 147.26h28.26v3h-28.26zM157.93 147.26h4.34v3h-4.34zM154.78 164.19h7.49v3h-7.49zM125.29 164.19h25.4v3h-25.4zM125.29 174.43h36.98v3h-36.98zM134.01 184.67h28.26v3h-28.26zM125.29 184.67h4.34v3h-4.34zM125.29 203.15h7.49v3h-7.49zM136.87 203.15h25.4v3h-25.4zM125.29 213.39h36.98v3h-36.98zM125.29 223.63h28.26v3h-28.26zM157.93 223.63h4.34v3h-4.34zM206.11 66.06a1.5 1.5 0 0 1-1.5-1.5v-9.39a1.51 1.51 0 0 1 .31-.91l-8.61-7.2-8.62 7.2a1.51 1.51 0 0 1 .31.91v9.39a1.5 1.5 0 0 1-3 0v-7.89h-.74a1.5 1.5 0 0 1-1-2.65L195.35 44a1.48 1.48 0 0 1 1.92 0l12 10.06a1.5 1.5 0 0 1-1 2.65h-.75v7.89a1.5 1.5 0 0 1-1.41 1.46z"
          />
          <path
            fill="#20b575"
            d="M212.18 76.32h-31.75a5.42 5.42 0 0 1-5.43-5.41V38.23a5.42 5.42 0 0 1 5.41-5.41h32.15a5 5 0 0 1 5 5v33.09a5.42 5.42 0 0 1-5.38 5.41zm-31.75-40.5a2.41 2.41 0 0 0-2.43 2.41v32.68a2.41 2.41 0 0 0 2.41 2.41h31.75a2.42 2.42 0 0 0 2.42-2.41V37.84a2 2 0 0 0-2-2zM207.99 87.82h7.49v3h-7.49zM178.49 87.82h25.4v3h-25.4zM178.49 98.06h36.99v3h-36.99zM187.21 108.3h28.26v3h-28.26zM178.49 108.3h4.34v3h-4.34zM178.49 126.78h7.49v3h-7.49zM190.08 126.78h25.4v3h-25.4zM178.49 137.02h36.99v3h-36.99zM178.49 147.26h28.26v3h-28.26zM211.13 147.26h4.34v3h-4.34zM207.99 164.19h7.49v3h-7.49zM178.49 164.19h25.4v3h-25.4zM178.49 174.43h36.99v3h-36.99zM187.21 184.67h28.26v3h-28.26zM178.49 184.67h4.34v3h-4.34zM48.37 241.25h7.49v3h-7.49zM18.88 241.25h25.4v3h-25.4zM18.88 251.49h36.99v3H18.88zM27.6 261.73h28.26v3H27.6zM18.88 261.73h4.34v3h-4.34zM72.08 241.25h7.49v3h-7.49zM83.67 241.25h25.4v3h-25.4zM72.08 251.49h36.99v3H72.08zM72.08 261.73h28.26v3H72.08zM104.72 261.73h4.34v3h-4.34zM154.78 241.25h7.49v3h-7.49zM125.29 241.25h25.4v3h-25.4zM125.29 251.49h36.98v3h-36.98zM134.01 261.73h28.26v3h-28.26zM125.29 261.73h4.34v3h-4.34zM207.99 241.25h7.49v3h-7.49zM178.49 241.25h25.4v3h-25.4zM178.49 251.49h36.99v3h-36.99zM187.21 261.73h28.26v3h-28.26zM178.49 261.73h4.34v3h-4.34zM178.49 203.15h7.49v3h-7.49zM190.08 203.15h25.4v3h-25.4zM178.49 213.39h36.99v3h-36.99zM178.49 223.63h28.26v3h-28.26zM211.13 223.63h4.34v3h-4.34z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutFourProperties.propTypes = {
  hover: PropTypes.bool
};

IconLayoutFourProperties.defaultProps = {
  hover: false
};

export default IconLayoutFourProperties;
