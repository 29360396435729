import React from "react";
import PropTypes from "prop-types";

function ListingAddress({ addresses }) {
  return (
    <h3 className="inline-block font-normal text-base text-grey-darker mr-2">
      {addresses[0]}
      &nbsp;
      {addresses[1] && (
        <span className="text-sm text-grey-dark">{addresses[1]}</span>
      )}
    </h3>
  );
}

ListingAddress.propTypes = {
  addresses: PropTypes.arrayOf(PropTypes.string)
};

export default ListingAddress;
