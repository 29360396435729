import React from "react";
import PropTypes from "prop-types";
import { ListingMapStatic, Markers } from "@wrstudios/components";
import { useSession } from "../../Session";

function MapStatic({
  lat,
  lon,
  width,
  height,
  zoomLevel,
  pitch,
  pin,
  ...props
}) {
  const { config } = useSession();

  return (
    <ListingMapStatic
      latitude={lat}
      longitude={lon}
      zoomLevel={zoomLevel}
      width={width}
      height={height}
      pitch={pitch}
      pin={pin}
      mapboxApiToken={config.mapboxApiToken}
      {...props}
    />
  );
}

MapStatic.defaultProps = {
  zoomLevel: 15,
  pitch: 50,
  pin: <Markers.SubjectPropertyMarker as="div" />
};

MapStatic.propTypess = {
  lat: PropTypes.number.isRequired,
  lon: PropTypes.number.isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  pitch: PropTypes.number,
  pin: PropTypes.element
};

export default MapStatic;
