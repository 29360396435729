import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  const selected = requestState(state.customPages.selected);

  return {
    ...state,
    customPages: requestState({ ...state.customPages, selected, saving: true })
  };
}

export function resolved(state, action) {
  const { page } = action.payload;
  const { key, value } = page;
  const hasInactivePages = state.inactive && state.inactive.pages;
  const hasActivePages = state.active && state.active.pages;
  const hasCustomPages = state.customPages;
  let newState = { ...state };

  if (hasInactivePages) {
    const inactivePages = [...state.inactive.pages].map((inactivePage) => {
      if (inactivePage.key === key) {
        inactivePage.value = value;
      }

      return { ...inactivePage };
    });

    newState = {
      ...newState,
      inactive: resolvedState({
        ...state.inactive,
        pages: inactivePages,
        saving: false
      })
    };
  }

  if (hasActivePages) {
    const activePages = [...state.active.pages].map((activePage) => {
      if (activePage.key === key) {
        activePage.value = value;
      }

      return { ...activePage };
    });

    newState = {
      ...newState,
      active: resolvedState({
        ...state.active,
        pages: activePages,
        saving: false
      })
    };
  }

  if (hasCustomPages) {
    newState = {
      ...newState,
      customPages: resolvedState({
        ...state.customPages,
        selected: resolvedState({
          ...state.customPages.selected,
          value
        }),
        saving: false
      })
    };
  }

  return newState;
}

export function rejected(state, action) {
  const { error } = action.payload;
  const selected = rejectedState(state.customPages.selected, error);

  return {
    ...state,
    customPages: rejectedState(
      { ...state.customPages, selected, saving: false },
      error
    )
  };
}
