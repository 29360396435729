import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Container } from "./styled/wistia";

function Wistia({
  videoId,
  autoplay,
  muted,
  popover,
  popoverAnimateThumbnail,
  playerColor,
  buttonBackground,
  controlsVisibleOnLoad,
  onReady,
  onEnd,
  ...props
}) {
  const video = useRef();

  const handleHook = (event) => {
    if (video.current) {
      video.current[event]("play", onPlay);
      video.current[event]("pause", onPause);
      video.current[event]("end", onEnd);
    }
  };

  const handleReady = (video) => {
    video.current = video;
    handleHook("bind");
    onReady(video);
  };

  useEffect(() => {
    window._wq = window._wq || [];
    window._wq = [...window._wq, { id: videoId, onReady: handleReady }];
    return () => {
      handleHook("unbind");
      window._wq = window._wq.filter((video) => video.id !== videoId);
    };
  }, []);

  const classes = [
    `wistia_embed wistia_async_${videoId}`,
    autoplay ? `autoPlay=${autoplay}` : "",
    `muted=${muted}`,
    `popover=${popover}`,
    `popoverAnimateThumbnail=${popoverAnimateThumbnail}`,
    `playerColor=${playerColor}`,
    `buttonBackground=${buttonBackground}`,
    `controlsVisibleOnLoad=${controlsVisibleOnLoad}`
  ].filter(Boolean);

  return (
    <Container {...props}>
      <Helmet>
        <script src="//fast.wistia.com/assets/external/E-v1.js"></script>
      </Helmet>
      <div className={classes.join(" ")} />
    </Container>
  );
}

Wistia.defaultProps = {
  autoplay: true,
  muted: false,
  popover: false,
  popoverAnimateThumbnail: false,
  playerColor: "#20b575",
  buttonBackground: "#20b575",
  controlsVisibleOnLoad: true,
  onReady: () => {},
  onPlay: () => {},
  onPause: () => {},
  onEnd: () => {}
};

Wistia.propTypes = {
  videoId: PropTypes.string.isRequired,
  autoplay: PropTypes.bool,
  muted: PropTypes.bool,
  popover: PropTypes.bool,
  popoverAnimateThumbnail: PropTypes.bool,
  playerColor: PropTypes.string,
  buttonBackground: PropTypes.string,
  controlsVisibleOnLoad: PropTypes.bool,
  onReady: PropTypes.func,
  onEnd: PropTypes.func
};

export default Wistia;
