import { useState } from "react";

function CustomizeControlsButton({ children }) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  return children({
    isModalOpen,
    openModal: () => setIsModalOpen(true),
    closeModal: () => setIsModalOpen(false)
  });
}

export default CustomizeControlsButton;
