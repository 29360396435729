import PropTypes from "prop-types";
import React, { useState } from "react";
import { connect } from "react-redux";
import { getReportType } from "../../utils/types";
import IconSparkle from "../icons/IconSparkle";
import IconRemoveRedEye from "../icons/material/image/IconRemoveRedEye";
import CustomizeModalPdfPreview from "./CustomizeModalPdfPreview";
import { SubTitle, Title } from "./styled/customize-page-preview";
import { useSession } from "../../Session";

function CustomizePagePreview({
  onOpen,
  onClose,
  isEmpty,
  isActive,
  userId,
  page,
  report,
  url
}) {
  const { features } = useSession();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const onClick = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsModalOpen(true);
    onOpen();
  };

  const getUrl = () => {
    const pageContentsUrl =
      page.id && `/users/${userId}/page_contents/${page.id}.pdf`;
    const pagePDFUrl =
      report.id &&
      `/${getReportType(report.type)}/${
        report.id
      }/preview.pdf?page_key=${encodeURIComponent(page.key)}`;

    if (features.offerRdcToolkit && page.key.startsWith("rdc_")) {
      return `/samples/${page.key}.pdf`;
    }

    return url || pageContentsUrl || pagePDFUrl;
  };

  const getTitles = (title) => {
    const realTitle = title.split("(")[0];
    let realSubtitle = title.match(/\(([^)]+)\)/);
    realSubtitle = realSubtitle !== null ? realSubtitle[0] : "";

    return { title: realTitle.trim(), subtitle: realSubtitle.trim() };
  };

  const { title, subtitle } = getTitles(page.title || "");
  const isRdc = page.key.startsWith("rdc_");

  return (
    <>
      <button
        className="preview-cta"
        type="button"
        title={title}
        data-pendo-id={isRdc ? "rdc-preview-page" : undefined}
        onClick={onClick}>
        <Title isActive={isActive} isEmpty={isEmpty}>
          {title}
          {isActive && page.key.startsWith("rdc_") && <IconSparkle />}
          {subtitle && (
            <SubTitle isActive={isActive}>
              {" "}
              <span>{subtitle}</span>
            </SubTitle>
          )}
        </Title>
        <span className="preview-cta-icon">
          <IconRemoveRedEye />
        </span>
      </button>
      {isModalOpen && (
        <CustomizeModalPdfPreview
          page={page}
          onClose={() => {
            setIsModalOpen(false);
            onClose();
          }}
          url={getUrl()}
          isEmpty={isEmpty}
        />
      )}
    </>
  );
}

CustomizePagePreview.propTypes = {
  userId: PropTypes.number,
  report: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
  page: PropTypes.shape({
    id: PropTypes.number,
    title: PropTypes.string,
    key: PropTypes.string,
    parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  url: PropTypes.string,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  isEmpty: PropTypes.bool,
  isActive: PropTypes.bool
};

CustomizePagePreview.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  isEmpty: false,
  isActive: false
};

const mapStateToProps = (state) => ({
  userId: state.user.id,
  report: state.report
});

export default connect(mapStateToProps)(CustomizePagePreview);
