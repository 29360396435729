import React from "react";

function IconAdjustmentPositive(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21.09 9" {...props}>
      <path d="M19 .75a1.34 1.34 0 0 1 1.37 1.32v4.86A1.34 1.34 0 0 1 19 8.25H2.13A1.35 1.35 0 0 1 .75 6.93V2.07A1.35 1.35 0 0 1 2.13.75H19M19 0H2.13A2.1 2.1 0 0 0 0 2.07v4.86A2.1 2.1 0 0 0 2.13 9H19a2.1 2.1 0 0 0 2.12-2.07V2.07A2.1 2.1 0 0 0 19 0z" />
      <path d="M18.88 4.09l-1.82-1.82-1.83 1.82h1.45v2.62h.75V4.09h1.45zM3.8 2.42L2.21 6.67h.93l.3-.88H5l.3.88h.9L4.61 2.42zm-.13 2.67l.53-1.6.54 1.6zM9.21 2.67a2 2 0 0 0-1-.25H6.9v4.25h1.31a2 2 0 0 0 1-.26 1.69 1.69 0 0 0 .69-.71 2.27 2.27 0 0 0 .25-1.06v-.19a2.27 2.27 0 0 0-.25-1.06 1.84 1.84 0 0 0-.69-.72zm0 2a1.54 1.54 0 0 1-.28 1 .94.94 0 0 1-.74.33h-.42V3.13h.43a1 1 0 0 1 .8.33 1.56 1.56 0 0 1 .27 1zM12.56 2.42v2.94a.72.72 0 0 1-.15.49A.5.5 0 0 1 12 6a.54.54 0 0 1-.42-.15.69.69 0 0 1-.13-.47h-.88a1.24 1.24 0 0 0 .38 1 1.54 1.54 0 0 0 1 .34 1.64 1.64 0 0 0 .74-.17 1.22 1.22 0 0 0 .5-.48 1.43 1.43 0 0 0 .18-.72V2.42z" />
    </svg>
  );
}

export default IconAdjustmentPositive;
