import PropTypes from "prop-types";
import React, { useState } from "react";
import Loading from "./Loading";
import PDF from "./PDF";
import { Container, Error, Overlay } from "./styled/pdf-preview";

function PDFPreview({ url, ...props }) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const errors = {
    404: "Page not found.",
    422: "You must publish at least one report for preview to work.",
    500: "We could not process your request at the moment. Please try again later."
  };
  const statusFromErrorMessage =
    error && error.message
      ? (error.message.match(/\((\d+)\)/) || [])[1]
      : undefined;

  return (
    <Container {...props}>
      {loading && (
        <Overlay>
          <Loading>Loading PDF Preview</Loading>
        </Overlay>
      )}
      {!!error && (
        <Overlay>
          <Error>
            {errors[Number(statusFromErrorMessage)] ||
              "There was a problem loading the pdf."}
          </Error>
        </Overlay>
      )}
      <PDF
        url={url}
        onLoad={() => setLoading(false)}
        onError={(error) => {
          setError(error);
          setLoading(false);
        }}
      />
    </Container>
  );
}

PDFPreview.propTypes = {
  url: PropTypes.string.isRequired
};

export default PDFPreview;
