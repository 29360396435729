import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconStar } from "@wrstudios/icons";
import IconStarFilled from "../../icons/IconStarFilled";
import { Container, Grid, Cell, Star } from "./styled/homebeat-viewer-rating";

function HomebeatViewerRating({ rating, onChange }) {
  const [hoverRating, setHoverRating] = useState(rating);

  const handleChange = (rating) => {
    setHoverRating(rating);
    onChange(rating);
  };

  return (
    <Container onMouseLeave={() => setHoverRating(rating)}>
      <Grid>
        <Cell>
          <Star
            onClick={() => handleChange(1)}
            onMouseEnter={() => setHoverRating(1)}>
            {hoverRating >= 1 ? <IconStarFilled /> : <IconStar />}
            Needs Work
          </Star>
        </Cell>
        <Cell>
          <Star
            onClick={() => handleChange(2)}
            onMouseEnter={() => setHoverRating(2)}>
            {hoverRating >= 2 ? <IconStarFilled /> : <IconStar />}
          </Star>
        </Cell>
        <Cell>
          <Star
            onClick={() => handleChange(3)}
            onMouseEnter={() => setHoverRating(3)}>
            {hoverRating >= 3 ? <IconStarFilled /> : <IconStar />}
            Good
          </Star>
        </Cell>
        <Cell>
          <Star
            onClick={() => handleChange(4)}
            onMouseEnter={() => setHoverRating(4)}>
            {hoverRating >= 4 ? <IconStarFilled /> : <IconStar />}
          </Star>
        </Cell>
        <Cell>
          <Star
            onClick={() => handleChange(5)}
            onMouseEnter={() => setHoverRating(5)}>
            {hoverRating >= 5 ? <IconStarFilled /> : <IconStar />}
            Excellent
          </Star>
        </Cell>
      </Grid>
    </Container>
  );
}

HomebeatViewerRating.defaultProps = {
  onChange: () => {}
};

HomebeatViewerRating.propTypes = {
  rating: PropTypes.number.isRequired,
  onChange: PropTypes.func
};

export default HomebeatViewerRating;
