import React from "react";

export default function IconSwitch() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.53 16.53a.75.75 0 0 0 0-1.06L5.06 13l2.47-2.47a.75.75 0 0 0-1.06-1.06l-3 3a.75.75 0 0 0 0 1.06l3 3a.75.75 0 0 0 1.06 0ZM12.47 3.47a.75.75 0 0 0 0 1.06L14.94 7l-2.47 2.47a.75.75 0 1 0 1.06 1.06l3-3a.75.75 0 0 0 0-1.06l-3-3a.75.75 0 0 0-1.06 0Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25 7c0 .414.336.75.75.75h11a.75.75 0 0 0 0-1.5H5a.75.75 0 0 0-.75.75ZM15.75 13a.75.75 0 0 0-.75-.75H5a.75.75 0 0 0 0 1.5h10a.75.75 0 0 0 .75-.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
