import styled from "styled-components";
import { Row as BaseRow } from "./comparable";

export const Container = styled.div`
  padding: 1.6rem;
  border-bottom: 0.1rem solid
    ${({ theme }) => theme.styleGuide.colors.gray[400]};

  ${BaseRow} {
    align-items: baseline;
    border: 0;
    padding: 0;
  }
`;

export const Street = styled.h2`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin: 0 0 0.1rem;
  padding-right: 0.5rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const CityStateZip = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding-right: 0.5rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Price = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin: 0 0 0.1rem;
  padding: 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  background-color: transparent;
  border: 0;
  white-space: nowrap;
  cursor: ${({ hasClickEvent }) => (hasClickEvent ? "pointer" : "default")};
  appearance: none;

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const PriceLabel = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Status = styled.div`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  margin-bottom: 0.1rem;
  color: ${({ color }) => color};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;
