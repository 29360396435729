import React, { useState, useRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { v4 as uuid } from "uuid";
import { debounce } from "lodash";
import { formatCurrency, formatNumber } from "@wrstudios/utils";
import { IconTrashCan } from "@wrstudios/icons";
import {
  addAdjustment,
  removeAdjustment,
  updateAdjustment
} from "../../actions/listings";
import { formatCurrentInputValue } from "../../utils/string";
import {
  Container,
  Table,
  Header,
  Label,
  Value,
  Controls,
  AddLabel,
  AddValue,
  AddControls,
  AddControl,
  AddInput
} from "./styled/listing-adjustments";

function ListingAdjustments({
  id,
  price,
  adjustments,
  addAdjustment,
  removeAdjustment,
  updateAdjustment
}) {
  const nameRef = useRef();
  const [name, setName] = useState("");
  const [value, setValue] = useState("");
  const totalAdjustments = adjustments.reduce((total, adjustment) => {
    const adjustmentValue = Number(formatNumber(adjustment.value, "0[.]0"));
    if (Number.isNaN(adjustmentValue)) return total;
    return total + adjustmentValue;
  }, 0);

  const handleSubmit = (e) => {
    e.preventDefault();
    addAdjustment(id, {
      id: uuid(),
      name,
      value: formatNumber(value, "0[.]0")
    });
    setName("");
    setValue("");
    nameRef.current.focus();
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Table>
        <thead>
          <tr>
            <Header>Name</Header>
            <Header>Value</Header>
            <Header>&nbsp;</Header>
          </tr>
        </thead>
        <tbody>
          {adjustments.map((adjustment) => (
            <Adjustment
              key={adjustment.id}
              listingId={id}
              id={adjustment.id}
              name={adjustment.name}
              value={adjustment.value}
              removeAdjustment={removeAdjustment}
              updateAdjustment={updateAdjustment}
            />
          ))}
        </tbody>
        <tfoot>
          <tr>
            <InputRow
              ref={nameRef}
              name={name}
              value={value}
              onNameChange={(e) => setName(e.target.value)}
              onValueChange={(e) => setValue(e.target.value)}
            />
            <AddControls>
              {name ? <AddControl>Save</AddControl> : " "}
            </AddControls>
          </tr>
          <tr>
            <Label>Total Adjustments</Label>
            <Value>{formatCurrency(totalAdjustments)}</Value>
            <Controls>&nbsp;</Controls>
          </tr>
          <tr>
            <Label>
              <strong>Adjusted Price</strong>
            </Label>
            <Value>
              <strong>{formatCurrency(price + totalAdjustments)}</strong>
            </Value>
            <Controls>&nbsp;</Controls>
          </tr>
        </tfoot>
      </Table>
    </Container>
  );
}

ListingAdjustments.defaultProps = {
  price: 0
};

ListingAdjustments.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  adjustments: PropTypes.array.isRequired,
  addAdjustment: PropTypes.func.isRequired,
  removeAdjustment: PropTypes.func.isRequired,
  updateAdjustment: PropTypes.func.isRequired
};

function Adjustment({
  listingId,
  id,
  name,
  value,
  removeAdjustment,
  updateAdjustment
}) {
  const [localName, setName] = useState(name || "");
  const [localValue, setValue] = useState(value || "");
  const update = useRef(
    debounce(
      ({ name, value }) =>
        updateAdjustment(listingId, {
          id,
          name,
          value: formatNumber(value, "0[.]0")
        }),
      200
    )
  ).current;

  return (
    <tr key={id}>
      <InputRow
        name={localName}
        value={localValue}
        onNameChange={(e) => {
          setName(e.target.value);
          update({
            name: e.target.value,
            value: formatNumber(localValue, "0[.]0")
          });
        }}
        onValueChange={(e) => {
          setValue(e.target.value);
          update({
            name: localName,
            value: formatNumber(e.target.value, "0[.]0")
          });
        }}
      />
      <Controls>
        <button type="button" onClick={() => removeAdjustment(listingId, id)}>
          <IconTrashCan />
        </button>
      </Controls>
    </tr>
  );
}

Adjustment.propTypes = {
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  removeAdjustment: PropTypes.func.isRequired,
  updateAdjustment: PropTypes.func.isRequired
};

const InputRow = forwardRef(
  ({ name, value, onNameChange, onValueChange }, ref) => (
    <>
      <AddLabel>
        <AddInput
          ref={ref}
          placeholder="Adjustment Name"
          value={name}
          onChange={onNameChange}
        />
      </AddLabel>
      <AddValue>
        <AddInput
          placeholder="$0"
          value={formatCurrentInputValue(value)}
          onChange={onValueChange}
        />
      </AddValue>
    </>
  )
);

export default connect(null, {
  addAdjustment,
  removeAdjustment,
  updateAdjustment
})(ListingAdjustments);
