import styled from "styled-components";

export const Container = styled.div`
  overflow: auto;
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  white-space: nowrap;
`;

export const Heading = styled.th`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  letter-spacing: 0.05rem;
  padding: 1.2rem 1.6rem;
  text-align: left;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  text-transform: uppercase;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Data = styled.td`
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1.2rem 1.6rem;
  text-align: left;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Row = styled.tr`
  opacity: ${({ isGhost }) => (isGhost ? 0.5 : 1)};

  &:hover {
    ${Data} {
      background-color: ${({ theme, isLoading }) =>
        !isLoading && theme.styleGuide.colors.gray[200]};
    }
  }
`;

export const Footer = styled.tfoot`
  ${Data} {
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 2.2rem;

    ${({ theme }) => theme.media.sm`
      font-size: 1.7rem;
      line-height: 2.4rem;
    `}
  }
`;
