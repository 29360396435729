import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { addCustomPage } from "../../api/user";
import { useSession } from "../../Session";
import Input from "../common/Input";
import Flash from "../common/Flash";
import {
  Grid,
  Cell,
  ErrorCell,
  Control
} from "./styled/user-custom-pages-edit";

function UserCustomPagesEditAdd({ onClose }) {
  const inputRef = useRef();
  const { currentUser, customPages, setCustomPages } = useSession();
  const [title, setTitle] = useState("");
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });
  const customPageKeys = customPages.map((page) => page.key);
  const pageExists = customPageKeys.includes(title);
  const isDisabled = !title || pageExists || request.loading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setRequest({ loading: true, success: false, error: null });
      const page = await addCustomPage(currentUser.id, { title, key: title });
      const newPage = {
        id: page.id,
        key: page.key,
        title: page.title || page.key,
        content: "",
        isDefault: false
      };
      setRequest({ loading: false, success: true, error: null });
      setTitle("");
      setCustomPages((customPages) => [...customPages, newPage]);
      onClose(newPage.key);
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error: error.originalResponse.data.error
      });
    }
  };

  useEffect(() => {
    setTimeout(() => inputRef.current.focus());
  }, []);

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Cell>
          <Input
            ref={inputRef}
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </Cell>
        <Cell>
          <Control disabled={isDisabled}>
            {!request.loading && "Add"}
            {request.loading && "Adding"}
          </Control>
        </Cell>
        <Cell>
          <Control type="button" onClick={() => onClose("")}>
            Cancel
          </Control>
        </Cell>
        {request.error && (
          <ErrorCell>
            <Flash variant="error">{request.error}</Flash>
          </ErrorCell>
        )}
        {pageExists && (
          <ErrorCell>
            <Flash>That page already exists.</Flash>
          </ErrorCell>
        )}
      </Grid>
    </form>
  );
}

UserCustomPagesEditAdd.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default UserCustomPagesEditAdd;
