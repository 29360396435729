import styled from "styled-components";
import Color from "color";
import { SubTitle as BaseSubTitle } from "../../styled/customize-controls-button";

export const Container = styled.span`
  display: block;
`;

export const Selected = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-bottom: 0.1rem dashed ${({ theme }) => theme.colors.grayAlt};
  cursor: pointer;
  position: relative;
`;

export const List = styled.span`
  display: block;
  padding: 1rem 0;
  border: 0.1rem solid
    ${({ theme }) => Color(theme.colors.grayLighter).alpha(0.7).string()};
  border-radius: ${({ theme }) => theme.borderRadius.md};
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.shadows.overlay};
  transform: translate(-50%, 100%);
  position: absolute;
  bottom: -1.6rem;
  left: 50%;
  z-index: 20;

  &:before,
  &:after {
    content: "";
    width: 1.2rem;
    height: 1.2rem;
    transform: rotate(45deg) translate(-50%, -0.3rem);
    border-top-left-radius: ${({ theme }) => theme.borderRadius.sm};
    position: absolute;
    top: 0;
    left: 50%;
  }

  &:before {
    background-color: ${({ theme }) => theme.colors.grayLighter};
  }

  &:after {
    background-color: ${({ theme }) => theme.colors.white};
    top: 0.1rem;
  }
`;

export const ListItems = styled.span`
  display: block;
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;
  z-index: 1;
`;

export const Item = styled.button.attrs({ type: "button" })`
  display: block;
  width: 100%;
  font-size: 1.4rem;
  line-height: 2.9rem;
  padding: 0 1.6rem;
  text-align: left;
  color: ${({ theme }) => theme.colors.gray};
  background-color: ${({ theme, isHighlighted }) =>
    isHighlighted ? theme.colors.grayLighterSuper : theme.colors.white};
  cursor: pointer;
`;

export const SubTitle = styled(BaseSubTitle)`
  display: block;
  width: 100%;
  text-align: left;
  position: relative;
  z-index: 1;
`;

export const Icon = styled.span`
  transform: translateY(-50%);
  position: absolute;
  right: 0;
  top: 50%;
`;
