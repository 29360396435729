import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const Modal = styled(BaseModal)`
  align-items: flex-start;
  padding: 0 4.8rem;
  z-index: 505;

  ${({ theme }) => theme.media.md`
    padding: 0;
  `}
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 120rem;
  min-height: 100vh;
  min-height: -webkit-fill-available;
  padding: 0;
  overflow: auto;
  border-radius: 0;
`;
