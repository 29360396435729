import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import DropdownGroup from "./DropdownGroup";

function applyDirection(direction) {
  switch (direction) {
    case "top-right":
      return "dropdown-tr";
    case "top-left":
      return "dropdown-tl";
    case "top-center":
      return "dropdown-tc";
    case "right-top":
      return "dropdown-rt";
    case "left-top":
      return "dropdown-lt";
  }
}

function Dropdown({
  button,
  buttonAriaLabel,
  buttonId,
  className,
  content,
  direction,
  disabled,
  disabledClass,
  groups,
  onInputValueChange
}) {
  return (
    <Downshift onInputValueChange={onInputValueChange || function () {}}>
      {({ isOpen, toggleMenu }) => (
        <div className={`dropdown ${className}`}>
          <button
            id={buttonId}
            className={`inline-block ${disabled ? disabledClass : ""} ${
              isOpen ? "open" : "closed"
            }`}
            onClick={toggleMenu}
            aria-haspopup="true"
            aria-expanded={isOpen}
            disabled={disabled}
            type="button"
            aria-label={`Button: ${
              !isOpen ? "Open" : "Close"
            } ${buttonAriaLabel}`}>
            {button(isOpen)}
          </button>
          {isOpen && (
            <div className={`dropdown-menu ${applyDirection(direction)}`}>
              <div className="relative z-10 bg-white rounded">
                {content && content(isOpen)}
                {groups &&
                  groups.map((items, index) => (
                    <DropdownGroup
                      key={index}
                      items={items}
                      toggleMenu={toggleMenu}
                    />
                  ))}
              </div>
            </div>
          )}
        </div>
      )}
    </Downshift>
  );
}

Dropdown.propTypes = {
  button: PropTypes.func.isRequired,
  buttonAriaLabel: PropTypes.string.isRequired,
  buttonId: PropTypes.string,
  content: PropTypes.func,
  groups: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.object)),
  direction: PropTypes.string,
  onInputValueChange: PropTypes.func,
  disabled: PropTypes.bool
};

Dropdown.defaultProps = {
  direction: "top-right",
  className: "",
  disabled: false
};

export default Dropdown;
