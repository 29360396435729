import React from "react";
import PropTypes from "prop-types";

function Icon({ width, height, viewBox, children, className }) {
  return (
    <svg
      fill="currentColor"
      height={height}
      viewBox={viewBox}
      width={width}
      xmlns="http://www.w3.org/2000/svg"
      className={className}>
      {children}
    </svg>
  );
}

Icon.propTypes = {
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  viewBox: PropTypes.string,
  className: PropTypes.string
};

Icon.defaultProps = {
  width: 18,
  height: 18,
  viewBox: "0 0 24 24",
  className: ""
};

export default Icon;
