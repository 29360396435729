import { flatten } from "lodash";
import { sort } from "timsort";
import { createSelector } from "reselect";

// Data from the store.
const inactivePages = (state) => state.customize.inactive.pages;
const activePages = (state) => state.customize.active.pages;
const powerPackMarketing = (state) =>
  state.customize.powerPacks.pages.marketing;
const powerPackLuxPages = (state) => state.customize.powerPacks.pages.lux;
const powerPackTomPages = (state) => state.customize.powerPacks.pages.tom;
const themes = (state) => state.customize.theme.all;
const templates = (state) => state.customize.template.all;
const customPages = (state) => state.customize.customPages.all;

/**
 * Groups an array of pages together based on if they are a parent or not.
 *
 * Specifically calls out listings chapters
 *
 * @param {Array} pages
 */
const groupPages = (pages) => {
  const groups = pages
    .filter((page) => !page.parent)
    .map((parent) => {
      parent.children = pages.filter((page) => page.parent === parent.key);

      return parent;
    });

  return Object.values(groups);
};

/**
 * Groups inactive pages
 *
 * @param {Array} inactivePages
 */
export const groupInactivePages = createSelector(inactivePages, (pages) => {
  const values = groupPages([...pages]);

  sort(values, (a, b) => a.position - b.position);

  values.forEach((page) => {
    if (page.children.length) {
      sort(page.children, (a, b) => a.position - b.position);
    }
  });

  return values;
});

/**
 * Groups active pages
 *
 * Notes: We want to null out all children unless they are children of listings
 *
 * @param {Array} activePages
 */
export const groupActivePages = createSelector(activePages, (pages) => {
  let newPages = pages.map((page) => {
    const newPage = { ...page };

    if (newPage.parent !== "listings" || newPage.key === "listings_chapter") {
      newPage.parent = null;
    }

    return newPage;
  });

  return groupPages(newPages);
});

/**
 * Groups custom pages (lux, tom, marketting2013)
 *
 * @param {Array} pages
 */
const groupSpecialPagesFactory = (pages) =>
  createSelector(pages, (pages) => {
    const values = groupPages(pages);

    sort(values, (a, b) => a.position - b.position);

    return values;
  });

/**
 * Groups and sorts themes
 *
 * @param {Array} themes
 */
export const groupThemes = createSelector(themes, (themes) => {
  const groups = {};

  // Group themes
  for (const theme in themes) {
    const group = themes[theme].group;

    if (!groups.hasOwnProperty(group)) {
      groups[group] = [];
    }

    groups[group].push(themes[theme]);
  }

  // Sort themes alphabetically
  for (const group in groups) {
    sort(groups[group], (a, b) => {
      if (a.title > b.title) return 1;
      if (a.title < b.title) return -1;
      return 0;
    });
  }

  let sortedGroups = Object.keys(groups).map((key) => ({ [key]: groups[key] }));

  // Sort groups alphabetically
  sort(sortedGroups, (a, b) => {
    const aKey = Object.keys(a)[0];
    const bKey = Object.keys(b)[0];

    if (aKey > bKey) return 1;
    if (aKey < bKey) return -1;
    return 0;
  });

  // Group for the select component
  sortedGroups = sortedGroups.map((item) => {
    const label = Object.keys(item)[0];
    const values = item[label].map(({ title: label, key: value, colors }) => ({
      label,
      value,
      colors
    }));

    return { label, value: values };
  });

  // Rename Custom to Branded and change item groups
  // TODO: We should think about changing the groups in themes since this works off those values.
  sortedGroups = sortedGroups.map((group) => {
    if (group.label === "Custom") group.label = "Branded";
    if (group.label === "Franchise") group.label = "Classic";

    return group;
  });

  // Swap modern and classic around
  const franchiseGroupIndex = sortedGroups.findIndex(
    (group) => group.label === "Classic"
  );
  const modernGroupIndex = sortedGroups.findIndex(
    (group) => group.label === "Modern"
  );

  if (franchiseGroupIndex > -1 && modernGroupIndex > -1) {
    [sortedGroups[modernGroupIndex], sortedGroups[franchiseGroupIndex]] = [
      sortedGroups[franchiseGroupIndex],
      sortedGroups[modernGroupIndex]
    ];
  }

  return sortedGroups;
});

/**
 * Removes templates from their groups
 *
 * @param {Array} templates
 */
export const ungroupTemplates = createSelector(templates, (templates) => {
  return flatten(
    Object.keys(templates)
      .filter((key) => key !== "Create")
      .map((key) => ({ [key]: templates[key] }))
      .map((group) => flatten(Object.values(group)))
  ).map(([label, value, pageCount = 0]) => ({ label, value, pageCount }));
});

/**
 * Groups and sorts templates
 *
 * @param {Array} templates
 */
export const groupTemplates = createSelector(templates, (templates) => {
  let sortedGroups = Object.keys(templates)
    .filter((key) => key !== "Create")
    .map((key) => ({ [key]: templates[key] }));

  // Sort groups alphabetically
  sort(sortedGroups, (a, b) => {
    const aKey = Object.keys(a)[0];
    const bKey = Object.keys(b)[0];

    if (aKey > bKey) return 1;
    if (aKey < bKey) return -1;
    return 0;
  });

  // Group for the select component
  sortedGroups = sortedGroups.map((item) => {
    const label = Object.keys(item)[0];
    const values = item[label].map(([label, value, pageCount = 0]) => ({
      label,
      value,
      pageCount
    }));

    return {
      label,
      value: values
    };
  });

  return sortedGroups;
});

/**
 * Groups and sorts custom pages
 *
 * @param {Array} customPages
 */
export const groupCustomPages = createSelector(customPages, (customPages) => {
  let sortedGroups = Object.keys(customPages).map((key) => ({
    [key]: customPages[key]
  }));

  // Sort groups alphabetically
  sort(sortedGroups, (a, b) => {
    const aKey = Object.keys(a)[0];
    const bKey = Object.keys(b)[0];

    if (aKey > bKey) return 1;
    if (aKey < bKey) return -1;
    return 0;
  });

  // Group for the select component
  sortedGroups = sortedGroups.map((item) => {
    const label = Object.keys(item)[0];
    const values = item[label].map(([label, value]) => ({
      label,
      value
    }));

    return {
      label,
      value: values
    };
  });

  return sortedGroups;
});

/**
 * Selector factory uses.
 */
export const groupMarketingPages = groupSpecialPagesFactory(powerPackMarketing);
export const groupLuxPages = groupSpecialPagesFactory(powerPackLuxPages);
export const groupTomPages = groupSpecialPagesFactory(powerPackTomPages);
