import React from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import {
  Container,
  Content,
  Confetti,
  Icon,
  Text,
  GlobalStyle
} from "./styled/customize-modal-congratulations";

function CustomizeModalTomFerryCongratulations({ onClick }) {
  return (
    <Container>
      <GlobalStyle />
      <Confetti />
      <Content>
        <Icon />
        <Text>
          Your Listing Presentation by Tom Ferry content is ready to be added to
          your report.
        </Text>
        <Button app="cma" onClick={onClick}>
          Back to Report
        </Button>
      </Content>
    </Container>
  );
}

CustomizeModalTomFerryCongratulations.propTypes = {
  onClick: PropTypes.func
};

export default CustomizeModalTomFerryCongratulations;
