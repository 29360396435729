import { pickBy, identity } from "lodash";
import qs from "qs";

const updateSearchQuery = (newParams, options = {}) => {
  const replace = options.replace || false;

  const currentParams = qs.parse(window.location.search, {
    ignoreQueryPrefix: true
  });
  const purgedParams = pickBy({ ...currentParams, ...newParams }, identity);
  const newUrl =
    window.location.origin +
    window.location.pathname +
    (!!Object.values(purgedParams).length
      ? `?${qs.stringify(purgedParams)}`
      : "");
  if (replace === true) {
    window.history.replaceState({ path: newUrl }, "", newUrl);
  } else {
    window.history.pushState({ path: newUrl }, "", newUrl);
  }
};

const appendTimestampParam = () => {
  updateSearchQuery({ ts: Date.now() }, { replace: true });
};

const removeTimestampParam = () => {
  updateSearchQuery({ ts: null }, { replace: true });
};

export function useQueryString() {
  return {
    params: qs.parse(window.location.search, { ignoreQueryPrefix: true }),
    updateSearchQuery,
    appendTimestampParam,
    removeTimestampParam,
    ...qs
  };
}

export function usePath() {
  return {
    path: window.location.pathname
  };
}

export function getCasDomain() {
  switch (window.location.host) {
    case "staging.cloudcma.com":
      return "https://staging.cloudagentsuite.com";
    case "cloudcma.com":
      return "https://cloudagentsuite.com";
    default:
      return "http://localhost:3400";
  }
}

export function getNewCmaUrl() {
  switch (window.location.host) {
    case "staging.cloudcma.com":
      return "https://app.staging.cloudcma.com";
    case "cloudcma.com":
      return "https://app.cloudcma.com";
    default:
      return "http://localhost:3002";
  }
}
