import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import {
  prices,
  activePrices,
  soldPrices,
  sqfts,
  activeSqfts,
  soldSqfts,
  ppSqft,
  activePpsqft,
  soldPpsqft
} from "../../selectors/listings";
import PricingGuideRow from "./PriceGuideRow";

function PricingGuide({
  report,
  subjectProperty,
  prices,
  activePrices,
  soldPrices,
  sqfts,
  activeSqfts,
  soldSqfts,
  ppSqft,
  activePpsqft,
  soldPpsqft
}) {
  return (
    <Fragment>
      <h3 className="text-sbase leading-sxl font-normal text-grey-darker mb-4">
        Price Guide
      </h3>
      <div className="tabley tabley-layout-auto tabley-responsive tabley-slim">
        <div className="tabley-header">
          <div className="tabley-cell">
            <strong>Comps</strong>
          </div>
          <div className="tabley-cell text-right">
            <strong>Low</strong>
          </div>
          <div className="tabley-cell text-right">
            <strong>Median</strong>
          </div>
          <div className="tabley-cell text-right">
            <strong>Average</strong>
          </div>
          <div className="tabley-cell text-right">
            <strong>High</strong>
          </div>
          {report.includes.sqft && (
            <Fragment>
              <div className="tabley-cell text-right">
                <strong className="whitespace-no-wrap">Avg. $/sq ft</strong>
              </div>
              <div className="tabley-cell text-right">
                <strong>
                  Suggested Range
                  <sup>*</sup>
                </strong>
              </div>
            </Fragment>
          )}
        </div>
        <PricingGuideRow
          title="All"
          prices={prices}
          sqfts={sqfts}
          ppSqft={ppSqft}
        />
        <PricingGuideRow
          title="Active"
          titleColor="green"
          prices={activePrices}
          sqfts={activeSqfts}
          ppSqft={activePpsqft}
        />
        <PricingGuideRow
          title="Sold"
          titleColor="red"
          prices={soldPrices}
          sqfts={soldSqfts}
          ppSqft={soldPpsqft}
        />
      </div>
      {report.includes.sqft && (
        <Fragment>
          {!!subjectProperty.sqft && (
            <p className="text-xs text-grey-dark mt-2">
              * Suggested range is calculated as{" "}
              <em>(&plusmn; 3% of $/sq ft) &times; subject property sq ft.</em>
            </p>
          )}
          {!subjectProperty.sqft && (
            <p className="text-xs text-grey-dark mt-2">
              * Suggested range is calculated as{" "}
              <em>&plusmn; 3% of average price.</em>
            </p>
          )}
        </Fragment>
      )}
    </Fragment>
  );
}

PricingGuide.propTypes = {
  report: PropTypes.shape({
    includes: PropTypes.shape({
      sqft: PropTypes.bool
    })
  }),
  subjectProperty: PropTypes.shape({
    sqft: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  }),
  prices: PropTypes.object,
  activePrices: PropTypes.object,
  soldPrices: PropTypes.object,
  sqfts: PropTypes.object,
  activeSqfts: PropTypes.object,
  soldSqfts: PropTypes.object
};

const mapStateToProps = (state) => ({
  report: state.report,
  subjectProperty: state.subjectProperty,
  prices: prices(state),
  activePrices: activePrices(state),
  soldPrices: soldPrices(state),
  sqfts: sqfts(state),
  activeSqfts: activeSqfts(state),
  soldSqfts: soldSqfts(state),
  ppSqft: ppSqft(state),
  activePpsqft: activePpsqft(state),
  soldPpsqft: soldPpsqft(state)
});

export default connect(mapStateToProps)(PricingGuide);
