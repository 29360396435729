import styled, { css } from "styled-components";

export const Container = styled.label`
  display: inline-flex;
  cursor: pointer;
`;

export const Box = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: ${({ size }) => (size === "sm" ? "1.4rem" : "2rem")};
  height: ${({ size }) => (size === "sm" ? "1.4rem" : "2rem")};
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[600]};
  color: ${({ theme }) => theme.styleGuide.colors.white};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};

  svg {
    width: ${({ size }) => (size === "sm" ? "1rem" : "1.2rem")};
    height: ${({ size }) => (size === "sm" ? "1rem" : "1.2rem")};
  }
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  opacity: 0;
  ${getVariant};
`;

function getVariant({ theme, variant, shadowSize }) {
  switch (variant) {
    case "live-primary":
      return css`
        &:checked + ${Box} {
          border-color: ${theme.live.colors.primary};
          background-color: ${theme.live.colors.primary};
        }

        &:checked:focus + ${Box} {
          border-color: ${theme.live.colors.primary};
          background-color: ${theme.live.colors.primary};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.live.colors.primary};
        }

        &:checked:hover + ${Box} {
          border-color: ${theme.live.colors.primary};
          background-color: ${theme.live.colors.primary};
        }

        &:checked:active + ${Box} {
          border-color: ${theme.live.colors.primary};
          background-color: ${theme.live.colors.primary};
        }

        &:not(:checked):focus + ${Box} {
          border-color: ${theme.live.colors.primary};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.live.colors.primary};
        }

        &:not(:checked):hover + ${Box} {
          border-color: ${theme.live.colors.primary};
        }

        &:not(:checked):active + ${Box} {
          color: ${theme.live.colors.primary};
          background-color: ${theme.live.colors.primary};
        }
      `;
    case "live-secondary":
      return css`
        &:checked + ${Box} {
          border-color: ${theme.live.colors.secondary};
          background-color: ${theme.live.colors.secondary};
        }

        &:checked:focus + ${Box} {
          border-color: ${theme.live.colors.secondary};
          background-color: ${theme.live.colors.secondary};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.live.colors.secondary};
        }

        &:checked:hover + ${Box} {
          border-color: ${theme.live.colors.secondary};
          background-color: ${theme.live.colors.secondary};
        }

        &:checked:active + ${Box} {
          border-color: ${theme.live.colors.secondary};
          background-color: ${theme.live.colors.secondary};
        }

        &:not(:checked):focus + ${Box} {
          border-color: ${theme.live.colors.secondary};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.live.colors.secondary};
        }

        &:not(:checked):hover + ${Box} {
          border-color: ${theme.live.colors.secondary};
        }

        &:not(:checked):active + ${Box} {
          color: ${theme.live.colors.secondary};
          background-color: ${theme.live.colors.secondary};
        }
      `;
    case "teal":
    case "green":
    case "yellow":
    case "blue":
    case "purple":
    case "violet":
    case "red":
      return css`
        &:checked + ${Box} {
          border-color: ${theme.styleGuide.colors[variant][500]};
          background-color: ${theme.styleGuide.colors[variant][500]};
        }

        &:checked:focus + ${Box} {
          border-color: ${theme.styleGuide.colors[variant][400]};
          background-color: ${theme.styleGuide.colors[variant][400]};
          box-shadow: 0 0 0 ${shadowSize}rem
            ${theme.styleGuide.colors[variant][500]};
        }

        &:checked:hover + ${Box} {
          border-color: ${theme.styleGuide.colors[variant][400]};
          background-color: ${theme.styleGuide.colors[variant][400]};
        }

        &:checked:active + ${Box} {
          border-color: ${theme.styleGuide.colors[variant][500]};
          background-color: ${theme.styleGuide.colors[variant][500]};
        }

        &:not(:checked):focus + ${Box} {
          border-color: ${theme.styleGuide.colors[variant][600]};
          box-shadow: 0 0 0 ${shadowSize}rem
            ${theme.styleGuide.colors[variant][500]};
        }

        &:not(:checked):hover + ${Box} {
          border-color: ${theme.styleGuide.colors.gray[700]};
        }

        &:not(:checked):active + ${Box} {
          color: ${theme.styleGuide.colors.gray[300]};
          background-color: ${theme.styleGuide.colors.gray[300]};
        }
      `;
    case "secondary":
      return css`
        &:checked + ${Box} {
          color: ${theme.styleGuide.colors.cma[500]};
          border-color: ${theme.styleGuide.colors.cma[500]};
        }

        &:checked:hover + ${Box} {
          color: ${theme.styleGuide.colors.cma[400]};
          border-color: ${theme.styleGuide.colors.cma[400]};
        }

        &:checked:active + ${Box} {
          color: ${theme.styleGuide.colors.cma[500]};
          border-color: ${theme.styleGuide.colors.cma[500]};
        }

        &:checked:focus + ${Box} {
          color: ${theme.styleGuide.colors.cma[400]};
          border-color: ${theme.styleGuide.colors.cma[400]};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.styleGuide.colors.cma[500]};
        }

        &:not(:checked):hover + ${Box} {
          border-color: ${theme.styleGuide.colors.gray[700]};
        }

        &:not(:checked):active + ${Box} {
          color: ${theme.styleGuide.colors.gray[300]};
          background-color: ${theme.styleGuide.colors.gray[300]};
        }

        &:not(:checked):focus + ${Box} {
          border-color: ${theme.styleGuide.colors.cma[600]};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.styleGuide.colors.cma[500]};
        }
      `;
    case "primary":
    default:
      return css`
        &:checked + ${Box} {
          border-color: ${theme.styleGuide.colors.cma[500]};
          background-color: ${theme.styleGuide.colors.cma[500]};
        }

        &:checked:focus + ${Box} {
          border-color: ${theme.styleGuide.colors.cma[400]};
          background-color: ${theme.styleGuide.colors.cma[400]};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.styleGuide.colors.cma[500]};
        }

        &:checked:hover + ${Box} {
          border-color: ${theme.styleGuide.colors.cma[400]};
          background-color: ${theme.styleGuide.colors.cma[400]};
        }

        &:checked:active + ${Box} {
          border-color: ${theme.styleGuide.colors.cma[500]};
          background-color: ${theme.styleGuide.colors.cma[500]};
        }

        &:not(:checked):focus + ${Box} {
          border-color: ${theme.styleGuide.colors.cma[600]};
          box-shadow: 0 0 0 ${shadowSize}rem ${theme.styleGuide.colors.cma[500]};
        }

        &:not(:checked):hover + ${Box} {
          border-color: ${theme.styleGuide.colors.gray[700]};
        }

        &:not(:checked):active + ${Box} {
          color: ${theme.styleGuide.colors.gray[300]};
          background-color: ${theme.styleGuide.colors.gray[300]};
        }
      `;
  }
}
