import { connect } from "react-redux";
import CustomizeControlsTitle from "../CustomizeControlsTitle";

function select({ customize: { title } }) {
  return {
    title
  };
}

const actions = {};

export default connect(select, actions)(CustomizeControlsTitle);
