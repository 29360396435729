import {
  LISTINGS_ADD,
  LISTINGS_ADD_ADJUSTMENT,
  LISTINGS_GET,
  LISTINGS_REMOVE,
  LISTINGS_REMOVE_ADJUSTMENT,
  LISTINGS_REORDER,
  LISTINGS_RESET_LISTING_REQUEST,
  LISTINGS_SORT,
  LISTINGS_TOGGLE,
  LISTINGS_UPDATE,
  LISTINGS_UPDATE_ADJUSTMENT,
  LISTINGS_UPDATE_DESCRIPTION,
  LISTINGS_UPDATE_NOTE
} from "../../actionTypes/listings";
import {
  rejected as addRejected,
  request as addRequest,
  resolved as addResolved
} from "./add";
import {
  rejected as addAdjustmentRejected,
  request as addAdjustmentRequest,
  resolved as addAdjustmentResolved
} from "./adjustments/add";
import {
  rejected as removeAdjustmentRejected,
  request as removeAdjustmentRequest,
  resolved as removeAdjustmentResolved
} from "./adjustments/remove";
import {
  rejected as updateAdjustmentRejected,
  request as updateAdjustmentRequest,
  resolved as updateAdjustmentResolved
} from "./adjustments/update";
import {
  rejected as updateDescriptionRejected,
  request as updateDescriptionRequest,
  resolved as updateDescriptionResolved
} from "./description";
import {
  rejected as getRejected,
  request as getRequest,
  resolved as getResolved
} from "./get";
import {
  rejected as removeRejected,
  request as removeRequest,
  resolved as removeResolved
} from "./remove";
import {
  rejected as reorderRejected,
  request as reorderRequest,
  resolved as reorderResolved
} from "./reorder";
import { resetListingDefaultRequest } from "./reset";
import {
  rejected as sortRejected,
  request as sortRequest,
  resolved as sortResolved
} from "./sort";
import {
  rejected as toggleRejected,
  request as toggleRequest,
  resolved as toggleResolved
} from "./toggle";
import {
  rejected as updateRejected,
  request as updateRequest,
  resolved as updateResolved
} from "./update";

export default function (state = {}, action) {
  switch (action.type) {
    case LISTINGS_RESET_LISTING_REQUEST:
      return resetListingDefaultRequest(state, action);

    case `${LISTINGS_SORT}_REQUEST`:
      return sortRequest(state, action);
    case `${LISTINGS_SORT}_RESOLVED`:
      return sortResolved(state, action);
    case `${LISTINGS_SORT}_REJECTED`:
      return sortRejected(state, action);

    case `${LISTINGS_TOGGLE}_REQUEST`:
      return toggleRequest(state, action);
    case `${LISTINGS_TOGGLE}_RESOLVED`:
      return toggleResolved(state, action);
    case `${LISTINGS_TOGGLE}_REJECTED`:
      return toggleRejected(state, action);

    case `${LISTINGS_REORDER}_REQUEST`:
      return reorderRequest(state, action);
    case `${LISTINGS_REORDER}_RESOLVED`:
      return reorderResolved(state, action);
    case `${LISTINGS_REORDER}_REJECTED`:
      return reorderRejected(state, action);

    case `${LISTINGS_ADD}_REQUEST`:
      return addRequest(state, action);
    case `${LISTINGS_ADD}_RESOLVED`:
      return addResolved(state, action);
    case `${LISTINGS_ADD}_REJECTED`:
      return addRejected(state, action);

    case `${LISTINGS_UPDATE}_REQUEST`:
      return updateRequest(state, action);
    case `${LISTINGS_UPDATE}_RESOLVED`:
      return updateResolved(state, action);
    case `${LISTINGS_UPDATE}_REJECTED`:
      return updateRejected(state, action);

    case `${LISTINGS_REMOVE}_REQUEST`:
      return removeRequest(state, action);
    case `${LISTINGS_REMOVE}_RESOLVED`:
      return removeResolved(state, action);
    case `${LISTINGS_REMOVE}_REJECTED`:
      return removeRejected(state, action);

    case `${LISTINGS_GET}_REQUEST`:
      return getRequest(state, action);
    case `${LISTINGS_GET}_RESOLVED`:
      return getResolved(state, action);
    case `${LISTINGS_GET}_REJECTED`:
      return getRejected(state, action);

    case `${LISTINGS_UPDATE_DESCRIPTION}_REQUEST`:
      return updateDescriptionRequest(state, action);
    case `${LISTINGS_UPDATE_DESCRIPTION}_RESOLVED`:
      return updateDescriptionResolved(state, action);
    case `${LISTINGS_UPDATE_DESCRIPTION}_REJECTED`:
      return updateDescriptionRejected(state, action);

    case `${LISTINGS_ADD_ADJUSTMENT}_REQUEST`:
      return addAdjustmentRequest(state, action);
    case `${LISTINGS_ADD_ADJUSTMENT}_RESOLVED`:
      return addAdjustmentResolved(state, action);
    case `${LISTINGS_ADD_ADJUSTMENT}_REJECTED`:
      return addAdjustmentRejected(state, action);

    case `${LISTINGS_REMOVE_ADJUSTMENT}_REQUEST`:
      return removeAdjustmentRequest(state, action);
    case `${LISTINGS_REMOVE_ADJUSTMENT}_RESOLVED`:
      return removeAdjustmentResolved(state, action);
    case `${LISTINGS_REMOVE_ADJUSTMENT}_REJECTED`:
      return removeAdjustmentRejected(state, action);

    case `${LISTINGS_UPDATE_ADJUSTMENT}_REQUEST`:
      return updateAdjustmentRequest(state, action);
    case `${LISTINGS_UPDATE_ADJUSTMENT}_RESOLVED`:
      return updateAdjustmentResolved(state, action);
    case `${LISTINGS_UPDATE_ADJUSTMENT}_REJECTED`:
      return updateAdjustmentRejected(state, action);

    case LISTINGS_UPDATE_NOTE:
      return {
        ...state,
        all: state.all.map((listing) => {
          if (listing.id === action.payload.listingId) {
            return { ...listing, note: action.payload.note };
          }
          return listing;
        })
      };

    default:
      return state;
  }
}
