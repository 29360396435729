import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: 50rem;
  margin: 3.2rem auto;
`;

export const Title = styled.h1`
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.2rem;
  margin-bottom: 3.2rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const Box = styled.div`
  padding: 2.4rem;
  border-radius: 0.4rem;
  background-color: ${({ theme }) => theme.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayLighter};
`;

export const Header = styled.strong`
  display: block;
  font-size: 1.7rem;
  font-weight: 600;
  line-height: 2.4rem;
  margin-bottom: 2.8rem;
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Cell = styled.div`
  &:first-child {
    min-width: 0;
  }
`;

export const Text = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${({ theme }) => theme.colors.grayDark};
`;
