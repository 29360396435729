import { requestState, resolvedState, rejectedState } from "../../utils/redux";

export function request(state, action) {
  return requestState({ ...state, filter: action.payload.filter });
}

export function resolved(state, action) {
  return resolvedState({ ...state, price: action.payload.price });
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
