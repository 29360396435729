import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const Item = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};

  &:first-child {
    border-top: 0;
  }
`;

export const Title = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  text-align: left;
  cursor: pointer;
  appearance: none;

  &:hover svg {
    visibility: visible;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-left: 0.5rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[700]};
    visibility: hidden;
  }
`;

export const Badge = styled.span`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-left: 0.5rem;
  padding: 0.2rem 0.4rem;
  font-style: italic;
  color: ${({ theme }) => theme.styleGuide.colors.purple[500]};
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[300]};
  border-radius: 0.3rem;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Controls = styled.div`
  display: inline-flex;
  align-items: center;
`;

export const Control = styled.div`
  padding: 0 0.5rem;

  &:last-child {
    padding-right: 0;
  }

  button {
    padding: 0;
    cursor: pointer;
    appearance: none;

    svg {
      width: 1.8rem;
      height: 1.8rem;
      fill: ${({ theme }) => theme.styleGuide.colors.gray[700]};
    }

    &:disabled {
      cursor: not-allowed;

      svg {
        opacity: 0.5;
      }
    }
  }
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  height: 100%;
  max-width: 90rem;
`;
