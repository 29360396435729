import React from "react";
import PropTypes from "prop-types";
import { formatNumber, formatCurrency } from "@wrstudios/utils";
import IconAdjustmentPositive from "../icons/IconAdjustmentPositive";
import IconAdjustmentNegative from "../icons/IconAdjustmentNegative";
import { Price, Status } from "./styled/listing-status";

function ListingStatus({ priceRaw, adjustments, status, color }) {
  const totalAdjustments = adjustments.reduce((total, adjustment) => {
    const adjustmentValue = Number(formatNumber(adjustment.value, "0[.]0"));
    if (Number.isNaN(adjustmentValue)) return total;
    return total + adjustmentValue;
  }, 0);
  const priceWithAdjustments = priceRaw + totalAdjustments;

  return (
    <div>
      {priceWithAdjustments !== priceRaw && (
        <Price>
          {priceRaw < priceWithAdjustments && <IconAdjustmentPositive />}
          {priceRaw > priceWithAdjustments && <IconAdjustmentNegative />}
          {formatCurrency(priceWithAdjustments)}
        </Price>
      )}
      <Status style={{ color }}>{status}</Status>
    </div>
  );
}

ListingStatus.propType = {
  price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  priceRaw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  status: PropTypes.string.isRequired,
  color: PropTypes.string.isRequired
};

export default ListingStatus;
