import React from "react";
import { pluralize } from "@wrstudios/utils";
import Logo from "../../common/Logo";
import Button from "../../common/Button";
import { useHomebeatEditor } from "./HomebeatEditorProvider";
import {
  Container,
  Cell,
  Header,
  Controls,
  Skip
} from "./styled/homebeat-editor-navigation";

function HomebeatsNavigation() {
  const { homebeats, currentIndex, skip, finish } = useHomebeatEditor();

  return (
    <Container id="navigation">
      <Cell>
        <Logo />
      </Cell>
      <Cell>
        <HomebeatsNavigationHeader />
      </Cell>
      <Cell>
        <Controls>
          {homebeats.length > 1 && currentIndex !== homebeats.length - 1 && (
            <Skip onClick={skip}>Skip this person</Skip>
          )}
          <Button app="cma" variant="secondary" onClick={finish}>
            Done
          </Button>
        </Controls>
      </Cell>
    </Container>
  );
}

function HomebeatsNavigationHeader() {
  const { homebeats, currentIndex, isEditing } = useHomebeatEditor();

  return (
    <Header>
      {!isEditing && homebeats.length <= 1 && "New Homebeat"}
      {!isEditing && homebeats.length > 1 && (
        <>
          {currentIndex + 1} of{" "}
          {pluralize("New Homebeats", homebeats.length, true)}
        </>
      )}
      {isEditing && homebeats.length <= 1 && "Edit Homebeat"}
      {isEditing && homebeats.length > 1 && (
        <>
          {currentIndex + 1} of{" "}
          {pluralize("Editable Homebeats", homebeats.length, true)}
        </>
      )}
    </Header>
  );
}

HomebeatsNavigation.Header = HomebeatsNavigationHeader;

export default HomebeatsNavigation;
