/**
 * Strips all non-numeric values from a number
 *
 * @param {String|Number} number
 */
export function stripNumber(number) {
  if (typeof number === "undefined" || number === null) {
    return 0;
  }

  if (typeof number === "object") {
    throw new Error("The number should be either a string or number.");
  }

  if (typeof number === "string") {
    number = number.replace(/[^\d.-]/g, "");
    const isNegative = number.startsWith("-");

    if (isNegative && number.endsWith("-")) {
      number = number.substr(1);
    }

    if (number.endsWith("-")) {
      number = number.substr(0, number.length - 1);
      number = isNegative ? number : `-${number}`;
    }
  }

  let parsedNumber = Number(number);

  if (Number.isNaN(parsedNumber)) {
    parsedNumber = 0;
  }

  return parsedNumber;
}

/**
 * Convert lot size to acres.
 *
 * @param {Number} lotSize
 */
export function lotSizeToAcres(lotSize) {
  if (!lotSize) return 0;
  return lotSize / 43560;
}

/**
 * Convert acres to lot size.
 *
 * @param {Number} acres
 */
export function acresToLotSize(acres) {
  if (!acres) return 0;
  return acres * 43560;
}

/**
 * Turns a string in to a value
 *
 * @param {String} detail
 */
export function parseValue(detail) {
  return Number((detail || "").toString().replace(/,/g, ""));
}
