import { Modal } from "@wrstudios/components";
import React from "react";
import {
  ModalBody,
  ModalContent
} from "./styled/suggested-list-price-edit-modal";
import SuggestedListPriceEdit from "./SuggestedListPriceEdit";

export default function SuggestedListPriceEditModal({
  report,
  subjectProperty,
  setSubjectProperty,
  onClose
}) {
  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <ModalBody>
          <SuggestedListPriceEdit
            report={report}
            subjectProperty={subjectProperty}
            setSubjectProperty={setSubjectProperty}
            onClose={onClose}
          />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}
