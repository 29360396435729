import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IconNoCover from "../icons/IconNoCover";
import { updateCover } from "../../actions/customize";
import { Container } from "./styled/customize-modal-cover-remove";

function CustomizeModalCoverRemove({ reportId, updateCover, onClick }) {
  return (
    <Container
      onClick={() => {
        updateCover(reportId, { title: "No Cover", key: null });
        onClick();
      }}>
      <IconNoCover />
      Don't use a cover
    </Container>
  );
}

CustomizeModalCoverRemove.propTypes = {
  reportId: PropTypes.number.isRequired,
  updateCover: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  reportId: state.report.id
});

const mapDispatchToProps = {
  updateCover
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalCoverRemove);
