import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { IconDropdown } from "@wrstudios/icons";
import { Container, StyledSelect } from "./styled/select";

const Select = forwardRef(
  ({ containerClassName, isFullWidth, ...props }, ref) => (
    <Container className={containerClassName} isFullWidth={isFullWidth}>
      <StyledSelect {...props} ref={ref} />
      <IconDropdown />
    </Container>
  )
);

Select.defaultProps = {
  isFullWidth: true
};

Select.propTypes = {
  containerClassName: PropTypes.string,
  isFullWidth: PropTypes.bool
};

function OptGroup(props) {
  return <optgroup {...props} />;
}

function Option(props) {
  return <option {...props} />;
}

export { Select, OptGroup, Option };
