import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { connect } from "react-redux";
import { updateListingNote } from "../../actions/listings";
import { updateSubjectPropertyNote } from "../../actions/subject-property";
import { updateListingNote as updateListingNoteApi } from "../../api/listing";
import { updateSubjectPropertyNote as updateSubjectPropertyNoteApi } from "../../api/subject-property";
import AgentNote from "../agent-note/AgentNote";
import ListingAdjustments from "../listing/ListingAdjustments";
import NetSheet from "../net-sheet/NetSheet";
import PricingGuide from "../pricing-guide/PricingGuide";
import ListingDetailsDescription from "./ListingDetailsDescription";
import ListingDetailsFeatures from "./ListingDetailsFeatures";
import ListingDetailsGeneralInfo from "./ListingDetailsGeneralInfo";
import ListingWarning from "./ListingWarning";

function ListingDetails({
  report,
  listing,
  updateSubjectPropertyNote,
  updateListingNote
}) {
  const type = report.type && report.type.toLowerCase();
  const isModern = report.is_modern;
  const isFlyer = type === "flyer";

  return (
    <div className="p-6 pt-2">
      {listing.status === "Subject Property" && (
        <Fragment>
          <div className="mb-8">
            <PricingGuide />
          </div>

          <div className="mt-8">
            <NetSheet />
          </div>

          {!isFlyer && (
            <Fragment>
              {!isModern && (
                <div className="mt-8">
                  <ListingWarning>
                    Notes only appear on Live CMAs and PDFs using modern themes.
                    Please select a <strong>Modern Theme</strong> in the{" "}
                    <strong>Customize</strong> step.
                  </ListingWarning>
                </div>
              )}

              <div className="mt-8">
                <label
                  htmlFor="agent-note"
                  className="text-sbase leading-sxl font-normal text-grey-darker mb-4">
                  Notes
                </label>
                <AgentNote
                  id="agent-note"
                  note={listing?.note}
                  placeholder="Notes will appear on the Suggested List Price page"
                  onSubmit={async (note) => {
                    await updateSubjectPropertyNoteApi(
                      report.id,
                      listing.id,
                      note
                    );
                    updateSubjectPropertyNote(note);
                  }}
                />
              </div>
            </Fragment>
          )}
        </Fragment>
      )}

      {listing.status !== "Subject Property" && (
        <Fragment>
          <div className="mb-8">
            <ListingDetailsGeneralInfo listing={listing} />
          </div>

          <div className="mt-8">
            <ListingDetailsDescription
              title={!isFlyer ? "Remarks" : "Description"}
              listing={listing}
              disabled={!isFlyer}
            />
          </div>

          {!isFlyer && (
            <Fragment>
              {!isModern && (
                <div className="mt-8">
                  <ListingWarning>
                    Notes only appear on Live CMAs and PDFs using modern themes.
                    Please select a <strong>Modern Theme</strong> in the{" "}
                    <strong>Customize</strong> step.
                  </ListingWarning>
                </div>
              )}

              <div className="mt-8">
                <label
                  htmlFor="agent-note"
                  className="text-sbase leading-sxl font-normal text-grey-darker mb-4">
                  Notes
                </label>
                <AgentNote
                  id="agent-note"
                  note={listing?.note}
                  placeholder="Notes will appear wherever listing details are shown"
                  onSubmit={async (note) => {
                    await updateListingNoteApi(report.id, listing.id, note);
                    updateListingNote(listing.id, note);
                  }}
                />
              </div>
            </Fragment>
          )}

          {listing.data.features && (
            <div className="mt-8">
              <ListingDetailsFeatures features={listing.data.features} />
            </div>
          )}

          {type === "cma" && (
            <div className="mt-8">
              <ListingAdjustments
                id={listing.id}
                price={listing.price_raw}
                adjustments={listing.adjustments}
              />
            </div>
          )}
        </Fragment>
      )}
    </div>
  );
}

ListingDetails.propTypes = {
  report: PropTypes.shape({
    type: PropTypes.string.isRequired
  }).isRequired,
  listing: PropTypes.shape({
    data: PropTypes.object,
    adjustments: PropTypes.array
  }).isRequired,
  updateSubjectPropertyNote: PropTypes.func.isRequired,
  updateListingNote: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  report: state.report
});

const mapDispatchToProps = {
  updateSubjectPropertyNote,
  updateListingNote
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingDetails);
