import React from "react";

function IconUpload() {
  return (
    <svg viewBox="0 0 34 34" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.1 19.125c.704 0 1.275.57 1.275 1.275v7.225h21.25V20.4a1.275 1.275 0 1 1 2.55 0v8.5c0 .704-.57 1.275-1.275 1.275H5.1c-.704 0-1.275-.57-1.275-1.275v-8.5c0-.704.57-1.275 1.275-1.275Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="m17 3.297 6.002 6.001a1.275 1.275 0 0 1-1.803 1.804l-2.924-2.924v14.687a1.275 1.275 0 1 1-2.55 0V8.178l-2.923 2.924a1.275 1.275 0 0 1-1.804-1.804L17 3.297Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconUpload;
