/**
 * Checks for which transation end even is supported in the browser
 */
function whichTransitionEvent() {
  let t;
  const el = document.createElement("fakeelement");
  const transitions = {
    transition: "transitionend",
    OTransition: "oTransitionEnd",
    MozTransition: "transitionend",
    WebkitTransition: "webkitTransitionEnd"
  };

  for (t in transitions) {
    if (typeof el.style[t] !== "undefined") {
      return transitions[t];
    }
  }
}

/**
 * Triggers when a transition has ended
 *
 * @param {HTMLElement} element
 * @param {Function} callback
 */
export function onTransitionEnd(element, callback) {
  const transitionEvent = whichTransitionEvent();

  transitionEvent && element.addEventListener(transitionEvent, callback, false);
}

/**
 * Removes the transition event from an element
 *
 * @param {HTMLElement} element
 * @param {function} callback
 */
export function removeOnTransitionEnd(element, callback) {
  const transitionEvent = whichTransitionEvent();

  element.removeEventListener(transitionEvent, callback, false);
}
