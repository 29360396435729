import axios from "axios";
import { getCSRFToken } from "./page";

// CSRF Helper for ajax
const CSRF_TOKEN = getCSRFToken();

axios.defaults.headers.common = {
  "X-CSRF-TOKEN": CSRF_TOKEN
};

// Intercept our response to modify the error message
axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const originalError = new Error(error);
    const { response = {} } = error;
    const { data = {} } = response;
    let modifiedError = {
      name: originalError.name,
      message: originalError.message,
      originalError,
      rawError: error,
      originalResponse: response
    };

    if (data && Object.keys(data).length) {
      modifiedError = { ...modifiedError, ...data };
    }

    return Promise.reject(modifiedError);
  }
);

/**
 * API Initial Request State
 *
 * @param {Object} data
 */
export function initialRequestState(data) {
  return {
    ...data,
    request: {
      fetching: false,
      fetched: false,
      error: {},
      errors: {},
      data: {}
    }
  };
}

/**
 * API Request State
 *
 * @param {Object} data
 * @param {Object} requestData
 * @param {Object} requestError
 */
export function requestState(data) {
  return {
    ...data,
    request: {
      fetching: true,
      fetched: false,
      error: {},
      errors: {}
    }
  };
}

/**
 * API Resolved State
 *
 * @param {Object} data
 * @param {Object} requestData
 */
export function resolvedState(data, requestData = {}) {
  return {
    ...data,
    request: { fetching: false, fetched: true, data: requestData }
  };
}

/**
 * API Rejected State
 *
 * @param {Object} data
 * @param {Object} requestError
 * @param {Object} requestErrors
 */
export function rejectedState(
  data,
  requestError = { message: "" },
  requestErrors = {}
) {
  return {
    ...data,
    request: {
      fetching: false,
      fetched: false,
      error: requestError,
      errors: requestErrors
    }
  };
}

/**
 * Handles network request redux object
 *
 * This method will create a {TYPE}_REQUEST redux object
 *
 * @param {String} type The action type constant used in a redux object
 * @param {Object} data
 */
function request(type, data) {
  return {
    type: `${type}_REQUEST`,
    payload: { ...data }
  };
}

/**
 * Handles network resolved redux object
 *
 * This method will create a {TYPE}_RESOLVED redux object
 *
 * @param {String} type The action type constant used in a redux object
 * @param {Object} data
 */
function resolved(type, data) {
  return {
    type: `${type}_RESOLVED`,
    payload: {
      ...data
    }
  };
}

/**
 * Handles network rejection redux object
 *
 * This method will create a {TYPE}_REJECTED redux object
 *
 * @param {String} type The action type constant used in a redux object
 * @param {ErrorEvent} error
 */
function rejected(type, data, error) {
  return {
    type: `${type}_REJECTED`,
    payload: {
      ...data,
      error
    }
  };
}

/**
 * Runs async network operations in serial.
 *
 * TODO: Can we use reduce here with promises instead of for loop?
 *
 * @param {Array} requests
 * @param {Object} state
 */
async function ayncNetworkLoop(requests, state) {
  let data = [];

  for (let i = 0; i < requests.length; i++) {
    const payload = await network(requests[i], state, ...data);

    data = [...data, payload];
  }

  return data;
}

/**
 * Handles api requests
 *
 * @param {string} type The action type constant used in a redux object
 * @param {Object} data
 * @param {Object|Array} networkData (can be a single object or an array of objects)
 */
export function api(type, data, networkData) {
  return async function apiAsync(dispatch, getState) {
    if (!Array.isArray(networkData)) {
      networkData = [networkData];
    }

    let { ensure = false } = networkData[0];

    // Force ensure on more than 1 network request.
    if (networkData.length > 1) {
      ensure = true;
    }

    dispatch(request(type, data));

    // Not ensuring data means the user wont experience lag when performing actions.
    // If we want to ensure data we can set the ensure property to true on the networkData object.
    // Ensuring data will result in a small lag for the user but make sure the data
    // has successfully been sent to the back end and a response has been received.
    if (!ensure) dispatch(resolved(type, data));

    try {
      // const networkRequests = networkData.map(data => network(data, getState()))
      // let payload = await Promise.all(networkRequests)
      let payload = await ayncNetworkLoop(networkData, getState());

      if (payload.length === 1) {
        payload = payload[0];
      }

      if (ensure) dispatch(resolved(type, { ...data, ...payload }));

      return payload;
    } catch (error) {
      if (process.env.NODE_ENV !== "production") {
        console.error(error);
      }

      dispatch(rejected(type, data, error));

      throw new Error(error.originalError);
    }
  };
}

/**
 * Handles the network request
 *
 * @param {Object} networkData
 * @param {Object} state
 * @param {Object} newState
 */
async function network(networkData, state, newState) {
  let {
    url,
    data = {},
    removeCSRF = false,
    removeHeaders = false
  } = networkData;
  const { method = "post", type = "json" } = networkData;

  if (typeof url === "undefined") {
    throw new Error("You must provide a url for the network method.");
  }

  if (typeof data === "function") {
    data = data(state, newState);
  }

  let headers = {
    Accept: "application/json; charset=utf-8",
    "Content-Type": "application/json; charset=utf-8",
    "Access-Control-Allow-Origin": "*"
  };

  if (removeHeaders) {
    headers = {};
  }

  switch (type) {
    // TODO: Convert all the text/html endpoints to json if possible.
    case "text":
      headers = {};
      break;
  }

  const config = {
    method,
    url,
    data,
    headers
  };

  if (method.toLowerCase() === "get") {
    config.params = data;

    delete config.data;
  }

  if (removeCSRF) {
    delete axios.defaults.headers.common["X-CSRF-TOKEN"];
  }

  let { data: serverData } = await axios(config);

  axios.defaults.headers.common = {
    "X-CSRF-TOKEN": CSRF_TOKEN
  };

  return serverData;
}
