import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { getListings, reorder, sort } from "../../actions/listings";
import { activeListings, errorListings } from "../../selectors/listings";
import { addBodyClass, removeBodyClass } from "../../utils/page";
import { getReportType } from "../../utils/types";
import ListingsControls from "./ListingsControls";
import Listing from "./Listing";
import ListingDraggable from "./ListingDraggable";
import ListingNew from "./ListingNew";
import { RequestState } from "./styled/listings";

class Listings extends Component {
  state = {
    isDragDisabled: false,
    fetchTimer: 2000
  };

  componentDidMount() {
    this.loadListings();
  }

  UNSAFE_componentWillReceiveProps() {
    setTimeout(() => this.loadListings(), this.state.fetchTimer);
  }

  render() {
    const { subjectProperty, activeListings } = this.props;
    const disableDrag = activeListings.length <= 1;
    let filter = "";
    let request = { fetching: false, fetched: false, error: {} };
    let hasError = false;

    if (subjectProperty) {
      filter = subjectProperty.filter;
      request = subjectProperty.request;
      hasError = request.error && !!Object.keys(request.error).length;
    }

    const hasFilter = filter === "price" || filter === "price_manually";

    return (
      <Fragment>
        {subjectProperty && (
          <div className="mb-12 sm:my-12 relative">
            <h2 className="font-normal leading-2xl text-center sm:text-left text-grey-darker mb-8">
              Subject Property
            </h2>
            <RequestState>
              {!request.fetching &&
                request.fetched &&
                hasFilter &&
                "All changes saved."}
              {!request.fetching &&
                hasError &&
                hasFilter &&
                request.error.message}
            </RequestState>
            <Listing listing={subjectProperty} />
          </div>
        )}
        <div className="mt-12">
          <h2 className="font-normal leading-2xl text-center sm:text-left text-grey-darker mb-8">
            Listings
          </h2>
          <ListingsControls />
          <DragDropContext
            onDragStart={this.onDragStart}
            onDragEnd={this.onDragEnd}>
            <Droppable droppableId="droppable" isDropDisabled={disableDrag}>
              {(provided) => (
                <div ref={provided.innerRef}>
                  {activeListings.map((listing, index) => (
                    <ListingDraggable
                      key={listing.id}
                      index={index}
                      listing={listing}
                      isDragDisabled={disableDrag}
                    />
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
          <ListingNew />
        </div>
      </Fragment>
    );
  }

  loadListings = () => {
    const { report, listings, activeListings, errorListings, getListings } =
      this.props;

    if (listings.length - errorListings.length > activeListings.length) {
      getListings(report.id, getReportType(report.type));
    }
  };

  onDragStart = () => {
    addBodyClass("cursor-move");
  };

  onDragEnd = (result) => {
    removeBodyClass("cursor-move");

    if (!result.destination) return;

    const { report, reorder, sort } = this.props;

    reorder(report.id, result.source.index, result.destination.index);
    sort(report.id, "", "manually");
  };
}

Listings.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  listing: PropTypes.object,
  reorder: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  report: state.report,
  subjectProperty: state.subjectProperty,
  listings: state.listings.all,
  activeListings: activeListings(state),
  errorListings: errorListings(state)
});

const mapDispatchToProps = {
  getListings,
  reorder,
  sort
};

export default connect(mapStateToProps, mapDispatchToProps)(Listings);
