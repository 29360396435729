import React from "react";

function IconLayout() {
  return (
    <svg
      width="18"
      height="13"
      viewBox="0 0 18 13"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-830.000000, -328.000000)">
        <g transform="translate(820.000000, 307.000000)">
          <g transform="translate(0.000000, 9.000000)">
            <g transform="translate(7.000000, 7.000000)">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M9.5,18 L14.5,18 L14.5,5 L9.5,5 L9.5,18 L9.5,18 Z M3.5,18 L8.5,18 L8.5,5 L3.5,5 L3.5,18 L3.5,18 Z M15.5,5 L15.5,18 L20.5,18 L20.5,5 L15.5,5 L15.5,5 Z"
                fill="#89929D"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconLayout;
