import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import Alert from "../../common/Alert";
import Loading from "../../common/Loading";
import IconStar from "../../icons/material/toggle/IconStar";
import IconErrorOutline from "../../icons/material/alert/IconErrorOutline";
import CustomizeModalTheme from "../CustomizeModalTheme";
import CustomizeModalThemeTryModern from "../CustomizeModalThemeTryModern";
import CustomizeControlsButton from "./CustomizeControlsButton";
import {
  Container,
  Icon,
  Text,
  Title,
  SubTitle,
  Emoji
} from "./styled/customize-controls-button";

class CustomizeControlsTheme extends Component {
  constructor(props) {
    super(props);

    this.storageKey = `${this.props.report.type.toLowerCase()}-try-modern-theme-modal`;

    this.state = {
      isTryModalOpen: this.canShowTryModal(),
      showAlert: false
    };
  }

  render() {
    const {
      theme: {
        selected,
        request: { fetching, error = {} }
      }
    } = this.props;
    const { isTryModalOpen } = this.state;
    const hasError = !!Object.keys(error).length;

    return (
      <CustomizeControlsButton>
        {({ isModalOpen, openModal, closeModal }) => (
          <Fragment>
            <Container
              disabled={fetching}
              aria-label="Select a Theme"
              onClick={openModal}>
              <Icon hasError={hasError}>
                {!fetching && !hasError && <IconStar width={24} height={24} />}
                {fetching && <Loading />}
                {!fetching && hasError && (
                  <IconErrorOutline width={24} height={24} />
                )}
              </Icon>
              <Text hasError={hasError}>
                <Title>
                  Theme{" "}
                  <Emoji>
                    {this.showModernReminder()
                      ? String.fromCharCode(0x2728)
                      : ""}
                  </Emoji>
                </Title>
                <SubTitle>{hasError ? error.message : selected.title}</SubTitle>
              </Text>
            </Container>
            {isModalOpen && <CustomizeModalTheme onClose={closeModal} />}
            {isTryModalOpen && (
              <CustomizeModalThemeTryModern
                onClose={this.handleOnTryModalClose}
              />
            )}
            {hasError && (
              <Alert
                title="Branded theme"
                confirmButton="Ok"
                onConfirm={this.handleOnAlertConfirm}>
                Your account cannot use the <strong>{error.theme.title}</strong>{" "}
                branded theme. Please choose another theme or{" "}
                <strong>
                  <a
                    href="https://cloudagentsuite.com/tools/cma#custom-branded-themes"
                    target="_blank"
                    rel="noreferrer"
                    data-turbolinks="false"
                    className="text-green no-underline">
                    click for information about branded themes
                  </a>
                </strong>
                .
              </Alert>
            )}
          </Fragment>
        )}
      </CustomizeControlsButton>
    );
  }

  showModernReminder = () => {
    return ["franchise", "heritage"].includes(
      this.props.theme.selected.group.toLowerCase()
    );
  };

  canShowTryModal = () => {
    let show = JSON.parse(window.localStorage.getItem(this.storageKey));

    show = show === null ? true : show;

    return this.showModernReminder() && show;
  };

  handleOnAlertConfirm = () => {
    this.props.resetRequestState("theme");
  };

  handleOnTryModalClose = () => {
    this.setState({ isTryModalOpen: false });
    window.localStorage.setItem(this.storageKey, false);
  };
}

CustomizeControlsTheme.propTypes = {
  report: PropTypes.shape({
    type: PropTypes.string
  }),
  theme: PropTypes.shape({
    selected: PropTypes.shape({
      title: PropTypes.string
    }),
    request: PropTypes.shape({
      error: PropTypes.object
    })
  }),
  resetRequestState: PropTypes.func
};

export default CustomizeControlsTheme;
