import PropTypes from "prop-types";
import React from "react";
import { Redirect } from "react-router-dom";
import { getFrequencyFriendlyName } from "../utils";
import HomebeatEditorActions from "./HomebeatEditorActions";
import HomebeatEditorForm from "./HomebeatEditorForm";
import HomebeatEditorNavigation from "./HomebeatEditorNavigation";
import HomebeatEditorPreview from "./HomebeatEditorPreview";
import {
  HomebeatEditorProvider,
  useHomebeatEditor
} from "./HomebeatEditorProvider";
import HomebeatEditorSentModal from "./HomebeatEditorSentModal";
import HomebeatEditorUpdatedModal from "./HomebeatEditorUpdatedModal";
import {
  Container,
  Sidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SidebarOverflow
} from "./styled/homebeat-editor";

function HomebeatEditor() {
  const { showMobilePreview, hasSent, hasUpdated, setHasUpdated, homebeat } =
    useHomebeatEditor();

  if (homebeat.frequency) {
    const homebeatFrequency = getFrequencyFriendlyName(
      homebeat.frequency,
      !!homebeat.inactiveAt
    );

    if (homebeatFrequency === "Unsubscribed") {
      return <Redirect to="/homebeats" />;
    }
  }

  return (
    <>
      <HomebeatEditorNavigation />
      <Container>
        <Sidebar isShowingMobilePreview={showMobilePreview}>
          <SidebarOverflow>
            <SidebarHeader>
              <HomebeatEditorNavigation.Header />
            </SidebarHeader>
            <SidebarContent>
              <HomebeatEditorForm />
            </SidebarContent>
          </SidebarOverflow>
          <SidebarFooter>
            <HomebeatEditorActions />
          </SidebarFooter>
        </Sidebar>
        <HomebeatEditorPreview />
        {hasSent && <HomebeatEditorSentModal />}
        {hasUpdated && (
          <HomebeatEditorUpdatedModal onClose={() => setHasUpdated(false)} />
        )}
      </Container>
    </>
  );
}

function HomebeatEditorWithProvider({
  homebeats,
  isEditing,
  isLoading,
  onFinish
}) {
  return (
    <HomebeatEditorProvider
      homebeats={homebeats}
      isEditing={isEditing}
      isLoading={isLoading}
      onFinish={onFinish}>
      <HomebeatEditor />
    </HomebeatEditorProvider>
  );
}

HomebeatEditorWithProvider.defaultProps = {
  isEditing: false,
  isLoading: false
};

HomebeatEditorWithProvider.propTypes = {
  homebeats: PropTypes.array.isRequired,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFinish: PropTypes.func.isRequired
};

export default HomebeatEditorWithProvider;
