import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../../../common/Loading";
import IconLanguage from "../../../icons/material/action/IconLanguage";
import IconErrorOutline from "../../../icons/material/alert/IconErrorOutline";
import {
  CustomizeControlsLanguageDropdown,
  CustomizeControlsLanguageDropdownItem
} from "./CustomizeControlsLanguageDropdown";
import { Icon, Title } from "../styled/customize-controls-button";
import { Container, Text } from "./styled/customize-controls-language";

class CustomizeControlsLanguage extends Component {
  render() {
    const {
      language: {
        all,
        selected,
        request: { fetching, error = {} }
      }
    } = this.props;
    const hasError = error && !!Object.keys(error).length;

    return (
      <Container>
        <Icon hasError={hasError}>
          {!fetching && !hasError && <IconLanguage width={24} height={24} />}
          {fetching && <Loading />}
          {!fetching && hasError && <IconErrorOutline width={24} height={24} />}
        </Icon>
        <Text disabled={fetching} hasError={hasError}>
          <Title>Language</Title>
          <CustomizeControlsLanguageDropdown
            value={selected}
            disabled={fetching}
            onChange={this.handleOnChange}>
            {all.map(([name, code], index) => (
              <CustomizeControlsLanguageDropdownItem key={index} value={code}>
                {name}
              </CustomizeControlsLanguageDropdownItem>
            ))}
          </CustomizeControlsLanguageDropdown>
        </Text>
      </Container>
    );
  }

  handleOnChange = (e) => {
    this.props.updateLanguage(this.props.report.id, e.target.value);
  };
}

CustomizeControlsLanguage.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number
  }),
  language: PropTypes.shape({
    all: PropTypes.array,
    selected: PropTypes.string,
    request: PropTypes.shape({
      fetching: PropTypes.bool,
      error: PropTypes.object
    })
  }),
  updateLanguage: PropTypes.func
};

export default CustomizeControlsLanguage;
