import React from "react";
import PropTypes from "prop-types";
import { Container, Notes } from "./styled/created-with";

function CreatedWith({ host, notes }) {
  const url = (host || "").replace(/\W+/g, "").toLowerCase();

  return (
    <Container>
      {!!url && host !== "web" && (
        <img
          src={`//www.google.com/s2/favicons?domain=www.${url}.com`}
          alt={`Created with ${host}`}
          aria-hidden
        />
      )}
      <Notes title={notes}>{notes}</Notes>
    </Container>
  );
}

CreatedWith.propTypes = {
  host: PropTypes.string,
  notes: PropTypes.string
};

export default CreatedWith;
