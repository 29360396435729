import React, { Component } from "react";
import PropTypes from "prop-types";
import { debounce } from "lodash";
import { connect } from "react-redux";
import { updateSuggestedListPriceManually } from "../../actions/subject-property";
import { getTextFromHtmlString } from "../../utils/dom";
import { hasClass } from "../../utils/page";
import { Input } from "./styled/listing-price-edit";

class ListingPriceEdit extends Component {
  state = {
    price: getTextFromHtmlString(this.props.price)
  };

  render() {
    const {
      subjectProperty: {
        filter,
        request: { fetching, fetched, error = {} }
      }
    } = this.props;
    const { price } = this.state;
    const hasError = error && !!Object.keys(error).length;
    const isMobile = hasClass("body", ["mobile", "tablet"]);
    const hasFilter = filter === "price" || filter === "price_manually";

    return (
      <div className="my-8 sm:m-0">
        <Input
          value={price == 0 ? "" : price}
          select={true}
          autoFocus={!isMobile && price == 0}
          placeholder="Set price"
          onChange={this.handleOnChange}
        />
        <p className="block sm:hidden text-center text-sm mt-4 -mb-6">
          {!fetching && fetched && hasFilter && "All changes saved."}
          {!fetching && hasError && hasFilter && error.message}
        </p>
      </div>
    );
  }

  handleOnChange = (e) => {
    this.setState({ price: e.target.value }, this.handleServerUpdate);
  };

  handleServerUpdate = debounce(() => {
    const { report, subjectProperty, updateSuggestedListPriceManually } =
      this.props;

    updateSuggestedListPriceManually(
      report.id,
      subjectProperty.id,
      this.state.price
    );
  }, 1000);
}

ListingPriceEdit.getDerivedStateFromProps = function (
  { price, subjectProperty },
  state
) {
  if (subjectProperty.request.fetched && subjectProperty.filter === "price") {
    return { ...state, price: getTextFromHtmlString(price) };
  }

  return state;
};

ListingPriceEdit.propsTypes = {
  report: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  subjectProperty: PropTypes.shape({
    id: PropTypes.number.isRequired,
    request: PropTypes.shape({
      fetching: PropTypes.bool,
      fetched: PropTypes.bool,
      error: PropTypes.shape({
        message: PropTypes.string
      })
    })
  }),
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired
};

const mapStateToProps = (state) => ({
  report: state.report,
  subjectProperty: state.subjectProperty
});

const mapDispatchToProps = {
  updateSuggestedListPriceManually
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingPriceEdit);
