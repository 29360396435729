import { stringify } from "qs";
import api from "../../api/api";
import camelcaseKeys from "camelcase-keys";

export function connectZF(user_id, username, password) {
  return api
    .get("/transactions/connect_zf", {
      params: { user_id, username, password }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function createTransactionZF(context_id, report_id) {
  return api
    .post("/transactions/create_zf", { context_id, report_id, type: "ZipForm" })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function connectTD(user_id, report_id, return_path) {
  window.open(
    `/transactions/connect_td?${stringify({
      user_id,
      report_id,
      return_path
    })}`,
    "_self"
  );
}

export function createTransactionTD(access_token, report_id) {
  return api
    .post("/transactions/create_td", {
      access_token,
      report_id,
      type: "TransactionDesk"
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function disconnectZF() {
  return api
    .post("/transactions/disconnect", {
      key: "zip_form"
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function disconnectTD() {
  return api
    .post("/transactions/disconnect", {
      key: "transaction_desk"
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}
