import { formatNumber } from "@wrstudios/utils";

export function transformSubjectProperty(subjectProperty) {
  return {
    id: getId(subjectProperty),
    sqft: getSqft(subjectProperty),
    beds: getBeds(subjectProperty),
    baths: getBaths(subjectProperty),
    lotSize: getLotSize(subjectProperty),
    area: getArea(subjectProperty),
    garages: getGarages(subjectProperty),
    acres: getAcres(subjectProperty),
    city: getCity(subjectProperty),
    assocFee: getAssocFee(subjectProperty),
    county: getCounty(subjectProperty),

    lotDims: getLotDims(subjectProperty),

    propSubType: getPropSubType(subjectProperty),
    propType: getPropType(subjectProperty),
    saleRent: getSaleRent(subjectProperty),

    schoolDistrict: getSchoolDistrict(subjectProperty),
    schoolElementary: getSchoolElementary(subjectProperty),

    schoolHigh: getSchoolHigh(subjectProperty),
    schoolMiddle: getSchoolMiddle(subjectProperty),
    state: getState(subjectProperty),
    style: getStyle(subjectProperty),
    subDivision: getSubDivision(subjectProperty),

    taxes: getTaxes(subjectProperty),
    yearBuilt: getYearBuilt(subjectProperty),
    zip: getZip(subjectProperty),

    coords: getCoords(subjectProperty),
    address: getAddress(subjectProperty),
    street: getStreet(subjectProperty),
    cityStateZip: getCityStateZip(subjectProperty),
    priceRange: getPriceRange(subjectProperty),
    netSheetItems: getNetSheetItems(subjectProperty),
    netSheetItemsTotal: getNetSheetItemsTotal(subjectProperty),
    photo: getPhoto(subjectProperty),

    sqftType: getSqftType(subjectProperty),
    landMeasurement: getLandMeasurement(subjectProperty),
    note: getNote(subjectProperty)
  };
}
function getAssocFee(subjectProperty) {
  return subjectProperty.assoc_fee || subjectProperty.assocFee || null;
}
function getCity(subjectProperty) {
  return subjectProperty.city || null;
}
function getCounty(subjectProperty) {
  return subjectProperty.county || null;
}
function getLotDims(subjectProperty) {
  return subjectProperty.lot_dims || subjectProperty.lotDims || null;
}
function getPropSubType(subjectProperty) {
  return subjectProperty.prop_sub_type || subjectProperty.propSubType || null;
}
function getPropType(subjectProperty) {
  return subjectProperty.prop_type || subjectProperty.propType || null;
}
function getSaleRent(subjectProperty) {
  return subjectProperty.sale_rent || subjectProperty.saleRent || null;
}
function getSchoolDistrict(subjectProperty) {
  return (
    subjectProperty.school_district || subjectProperty.schoolDistrict || null
  );
}
function getSchoolElementary(subjectProperty) {
  return (
    subjectProperty.school_elementary ||
    subjectProperty.schoolElementary ||
    null
  );
}
function getSchoolHigh(subjectProperty) {
  return subjectProperty.school_high || subjectProperty.schoolHigh || null;
}
function getSchoolMiddle(subjectProperty) {
  return subjectProperty.school_middle || subjectProperty.schoolMiddle || null;
}
function getState(subjectProperty) {
  return subjectProperty.state || null;
}
function getStyle(subjectProperty) {
  return subjectProperty.style || null;
}
function getArea(subjectProperty) {
  return subjectProperty.area || null;
}
function getSubDivision(subjectProperty) {
  return subjectProperty.subdivision || null;
}
function getTaxes(subjectProperty) {
  return subjectProperty.taxes || null;
}

function getId(subjectProperty) {
  return subjectProperty.id;
}

function getLat(subjectProperty) {
  return Number(subjectProperty.geo_lat || subjectProperty.geoLat || 0);
}

function getLon(subjectProperty) {
  return Number(subjectProperty.geo_lon || subjectProperty.geoLon || 0);
}

function getCoords(subjectProperty) {
  return {
    lat: getLat(subjectProperty),
    lon: getLon(subjectProperty)
  };
}

function getZip(subjectProperty) {
  return subjectProperty.zip;
}
function getAddress(subjectProperty) {
  return subjectProperty.address || "";
}

function getStreet(subjectProperty) {
  return subjectProperty.street || "";
}

function getCityStateZip(subjectProperty) {
  return subjectProperty.city_state_zip || subjectProperty.cityStateZip || "";
}

function getPhoto(subjectProperty) {
  return subjectProperty.photo || "";
}

function getBeds(subjectProperty) {
  return Number(formatNumber(subjectProperty.beds || 0, "0.[0]"));
}

function getBaths(subjectProperty) {
  return Number(formatNumber(subjectProperty.baths || 0, "0.[0]"));
}

function getSqft(subjectProperty) {
  return Number(formatNumber(subjectProperty.sqft || 0, "0.[0]"));
}

function getLotSize(subjectProperty) {
  return Number(
    formatNumber(
      subjectProperty.lot_size || subjectProperty.lotSize || 0,
      "0[.]0[0]"
    )
  );
}

function getAcres(subjectProperty) {
  const lotSize = getLotSize(subjectProperty);
  const acres = Number(formatNumber(subjectProperty.acres || 0, "0[.]0[0]"));
  return acres || lotSize / 43560;
}

function getYearBuilt(subjectProperty) {
  return Number(
    formatNumber(
      subjectProperty.year_built || subjectProperty.yearBuilt || 0,
      "0"
    )
  );
}

function getGarages(subjectProperty) {
  return Number(formatNumber(subjectProperty.garages || 0, "0.[0]"));
}

function getPriceRange(subjectProperty) {
  const priceRange =
    subjectProperty.price_range || subjectProperty.priceRange || {};

  return {
    min: priceRange.min || 0,
    max: priceRange.max || 0,
    sep: priceRange.sep || ""
  };
}

function getNetSheetItems(subjectProperty) {
  return subjectProperty.net_sheet_items || subjectProperty.netSheetItems || [];
}

function getNetSheetItemsTotal(subjectProperty) {
  return getNetSheetItems(subjectProperty).reduce(
    (total, netSheetItem) => total + Number(netSheetItem.value),
    0
  );
}

function getSqftType(subjectProperty) {
  return subjectProperty.sqft_type || subjectProperty.sqftType || "";
}

function getLandMeasurement(subjectProperty) {
  return (
    subjectProperty.land_measurement || subjectProperty.landMeasurement || ""
  );
}

function getNote(subjectProperty) {
  return subjectProperty.note || "";
}
