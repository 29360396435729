import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  max-width: ${({ theme }) => theme.sizes.xl}px;
  margin: 0 auto;
  padding: 0 2.4rem;

  @media (max-width: ${({ theme }) => theme.sizes.xl - 1}px) {
    max-width: ${({ theme }) => theme.sizes.lg}px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.lg - 1}px) {
    max-width: ${({ theme }) => theme.sizes.md}px;
  }

  @media (max-width: ${({ theme }) => theme.sizes.md - 1}px) {
    max-width: ${({ theme }) => theme.sizes.sm}px;
  }
`;
