import React from "react";
import PropTypes from "prop-types";
import { AriaMessage } from "@wrstudios/components";
import { Container, Pill } from "./styled/switch";

function Switch({ isOn, color, name, ariaMessage, onChange, ...props }) {
  return (
    <Container
      {...props}
      type="button"
      isOn={isOn}
      color={color}
      className={isOn ? "switch-active" : ""}
      onClick={(e) => onChange(!isOn, e)}>
      {name && <input type="hidden" name={name} value={isOn ? "on" : "off"} />}
      <Pill isOn={isOn} color={color} className="switch-pill" />
      <AriaMessage live="polite">
        {!!ariaMessage ? ariaMessage : <>The switch is {isOn ? "On" : "Off"}</>}
      </AriaMessage>
    </Container>
  );
}

Switch.defaultProps = {
  isOn: false,
  ariaMessage: "",
  onChange: () => {}
};

Switch.propTypes = {
  isOn: PropTypes.bool,
  color: PropTypes.string,
  name: PropTypes.string,
  ariaMessage: PropTypes.string,
  onChange: PropTypes.func
};

export default Switch;
