import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import BaseButton from "../../../common/Button";

export const ModalContent = styled(BaseModal.Content)`
  padding: 6.4rem;

  ${({ theme }) => theme.media.sm`
    padding: 2.4rem;
  `}
`;

export const ModalIcon = styled.div`
  margin-bottom: 2.4rem;
  text-align: center;

  svg {
    width: 4.8rem;
    height: 4.8rem;
    fill: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  }
`;

export const ModalHeader = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`;

export const ModalContact = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  strong {
    font-weight: 700;
  }
`;

export const ModalFinish = styled(BaseButton)`
  border: 0;
`;
