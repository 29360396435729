import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../../utils/redux";

export function request(state) {
  const fakePDF = requestState({
    id: -1,
    image: "",
    url: "",
    page_file_name: "Uploading..."
  });

  return {
    ...state,
    customPages: {
      ...state.customPages,
      pdfs: [...state.customPages.pdfs, fakePDF]
    }
  };
}

export function resolved(state, action) {
  const pdf = { ...action.payload };
  const pdfs = [...state.customPages.pdfs].filter((pdf) => pdf.id !== -1); // Remove fake pdf

  return {
    ...state,
    customPages: resolvedState({ ...state.customPages, pdfs: [...pdfs, pdf] })
  };
}

export function rejected(state, action) {
  const pdfs = [...state.customPages.pdfs].map((pdf) => {
    if (pdf.id === -1) {
      pdf = rejectedState(pdf, action.payload.error);
    }

    return pdf;
  });

  return { ...state, customPages: { ...state.customPages, pdfs } };
}
