import PropTypes from "prop-types";
import React, { useState } from "react";
import IconSparkle from "../icons/IconSparkle";
import IconFolder from "../icons/material/file/IconFolder";
import IconFolderOpen from "../icons/material/file/IconFolderOpen";
import CustomizeInactivePages from "./CustomizeInactivePages";
import CustomizeControlsIllustrations from "./controls/connected/CustomizeControlsIllustrations";
import { Badge } from "./styled/customize-inactive-folder";

function CustomizeInactiveFolder({
  title,
  tag,
  isNew,
  pages,
  open,
  showIllustrationControl
}) {
  const [isOpen, setIsOpen] = useState(open);

  return (
    <>
      <div className="flex items-center my-8">
        <button
          className="flex items-center font-normal text-lg leading-2xl text-grey-darker truncate"
          type="button"
          onClick={() => setIsOpen((isOpen) => !isOpen)}>
          <span className="text-grey mr-4">
            {!isOpen && <IconFolder width={24} height={24} />}
            {isOpen && <IconFolderOpen width={24} height={24} />}
          </span>
          <span className="truncate" title={title}>
            {title}
          </span>
        </button>
        {isNew && (
          <Badge isNew>
            <IconSparkle /> New
          </Badge>
        )}
        {!isNew && tag && <Badge>{tag}</Badge>}
      </div>
      {isOpen && (
        <>
          {showIllustrationControl && (
            <div>
              <CustomizeControlsIllustrations />
            </div>
          )}
          <CustomizeInactivePages pages={pages} />
        </>
      )}
    </>
  );
}

CustomizeInactiveFolder.defaultProps = {
  open: true,
  showIllustrationControl: false
};

CustomizeInactiveFolder.propTypes = {
  title: PropTypes.string.isRequired,
  tag: PropTypes.string,
  isNew: PropTypes.bool,
  showIllustrationControl: PropTypes.bool,
  pages: PropTypes.arrayOf(PropTypes.object).isRequired,
  open: PropTypes.bool,
  offer: PropTypes.bool
};

export default CustomizeInactiveFolder;
