import { IconMoney } from "@wrstudios/icons";
import { formatCurrency } from "@wrstudios/utils";
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  getOpendoorHome,
  sendInvestorEvent,
  submitInvestorOffer
} from "../../api/report";
import Button from "../common/Button";
import Loading from "../common/Loading";
import IconBadge from "../icons/investor/IconBadge";
import IconCheckmarkOutline from "../icons/investor/IconCheckmarkOutline";
import IconHelpOutline from "../icons/material/action/IconHelpOutline";
import IconArrowBack from "../icons/IconArrowBack";
import opendoorPrintable from "./images/opendoor_printable_with_stamp.png";
import InvestorToggle from "./InvestorToggle";
import {
  Actions,
  Badge,
  BadgeCell,
  BadgeGrid,
  BadgeLink,
  BadgeText,
  BadgeTitle,
  BackButton,
  BackButtonIcon,
  ButtonIcon,
  Cell,
  CommissionText,
  CommissionRange,
  DetailsTitle,
  DetailsText,
  Estimate,
  EstimateTitle,
  Grid,
  ImageCell,
  Learn,
  Link,
  Point,
  Points,
  PointsHeader,
  Status,
  StatusTitle,
  Text,
  Title,
  Emphasis,
  Subheader
} from "./styled/investor-opendoor";

const steps = {
  INTRO: 0,
  QUALIFIED: 1,
  SENT: 2
};

const InvestorOpendoorContext = createContext();

function InvestorOpendoor({
  cashOffer,
  report,
  lead,
  setLead,
  showCashOfferStep,
  showEstimate,
  hasAcceptedOffers,
  estimate,
  showIntroBack,
  setLocalShowCashOffer
}) {
  const [localEstimate, setLocalEstimate] = useState({
    low: estimate.low,
    high: estimate.high
  });
  const [home, setHome] = useState({});
  const acceptedOffer = report.investor_offer && report.investor_offer_accepted;
  const hasStartedSellerFlow = acceptedOffer;
  const hasCompletedSellerFlow = hasStartedSellerFlow && !!lead.status; // TODO: This will be changed to `lead.status.created`

  // Only show the thank you step if the offer has been accepted and they've completed opendoor's seller flow.
  const [step, setStep] = useState(
    showEstimate && hasAcceptedOffers
      ? steps.QUALIFIED
      : acceptedOffer && hasCompletedSellerFlow
      ? steps.SENT
      : acceptedOffer && !!lead.status_url
      ? steps.QUALIFIED
      : steps.INTRO
  );

  useEffect(() => {
    sendInvestorEvent(report.id, lead.id, "offer_displayed");
  }, []);

  return (
    <InvestorOpendoorContext.Provider
      value={{
        cashOffer,
        report,
        lead,
        home,
        setHome,
        acceptedOffer,
        hasStartedSellerFlow,
        hasCompletedSellerFlow,
        setLead,
        onQualified: () => setStep(steps.QUALIFIED),
        onDisqualified: () =>
          setLead((lead) => ({
            ...lead,
            investor: { ...lead.investor, token: "disqualified" }
          }))
      }}>
      {step === steps.INTRO && (
        <InvestorOpendoorIntro
          setLocalEstimate={setLocalEstimate}
          showBack={showIntroBack}
          setLocalShowCashOffer={setLocalShowCashOffer}
        />
      )}
      {step === steps.QUALIFIED && (
        <InvestorOpendoorQualified estimate={localEstimate} />
      )}
      {step === steps.SENT && <InvestorOpendoorSent />}
      {step !== steps.QUALIFIED && (
        <InvestorToggle
          show={showCashOfferStep}
          text="Show me when my client’s home is eligible for a cash offer"
          feature="ibuyer_connect"
        />
      )}
      {step === steps.QUALIFIED && (
        <InvestorToggle
          show={showEstimate}
          text="Automatically get cash offer estimates for future eligible CMAs."
          feature="ibuyer_connect_auto_estimate"
        />
      )}
    </InvestorOpendoorContext.Provider>
  );
}

function InvestorOpendoorIntro({
  setLocalEstimate,
  showBack,
  setLocalShowCashOffer
}) {
  const {
    report,
    lead,
    hasStartedSellerFlow,
    hasCompletedSellerFlow,
    setHome,
    onQualified,
    onDisqualified
  } = useContext(InvestorOpendoorContext);
  const [network, setNetwork] = useState({
    isFetching: false,
    hasFetched: false,
    hasError: false
  });

  const handleGetCashOffer = () => {
    sendInvestorEvent(report.id, lead.id, "request_offer");
    setNetwork({ isFetching: true, hasFetched: false, hasError: false });
    getOpendoorHome(report.id)
      .then((home) => {
        if (home.isEligible && home.url) {
          submitInvestorOffer(report.id, lead.id);
          setHome(home);
          setLocalEstimate(home.estimate);
          onQualified();
        } else {
          onDisqualified();
        }
      })
      .catch(onDisqualified);
  };

  return (
    <>
      <Subheader>While we're preparing that:</Subheader>
      <Grid>
        <Cell>
          {!hasStartedSellerFlow && (
            <Title>
              Opendoor has served <Emphasis>100,000+</Emphasis> customers and
              paid agents <Emphasis>$300M+</Emphasis> in commission.
            </Title>
          )}
          {hasStartedSellerFlow && !hasCompletedSellerFlow && (
            <Title>Finish requesting an offer</Title>
          )}
          <PointsHeader>
            More options means more chances for you to close:{" "}
          </PointsHeader>
          <Points>
            <Point>
              <IconCheckmarkOutline />
              Get an estimated offer for your client’s home in minutes. Sent to
              you, the agent.
            </Point>
            <Point>
              <IconCheckmarkOutline />
              Give your client a convenient selling option with no prep work or
              home showings.
            </Point>
            <Point>
              <IconCheckmarkOutline />
              Avoid appraisal issues and buyer financing delays or fall
              throughs.
            </Point>
            <Point>
              <IconCheckmarkOutline />
              Line up your client's close dates so they can avoid paying 2
              mortgages or moving twice.
            </Point>
            <Point>
              <IconCheckmarkOutline />
              <Emphasis>
                Get 1% from Opendoor Brokerage on top of your seller commission.
                That’s more cash for you.
              </Emphasis>
            </Point>
          </Points>
          <Actions>
            {showBack && (
              <BackButton
                app="cma"
                variant="secondary"
                size="large"
                onClick={() => setLocalShowCashOffer(false)}>
                <BackButtonIcon>
                  <IconArrowBack />
                </BackButtonIcon>
                Back
              </BackButton>
            )}
            <Button
              app="cma"
              size="large"
              disabled={
                network.isFetching ||
                network.hasFetched ||
                (network.hasFetched && !home.isEligible)
              }
              onClick={handleGetCashOffer}>
              <>
                {network.isFetching ? (
                  <Loading>Requesting Offer</Loading>
                ) : network.hasFetched && home.isEligible ? (
                  "Offer Requested!"
                ) : (
                  <>
                    <ButtonIcon>
                      <IconMoney />
                    </ButtonIcon>
                    Get Cash Offer
                  </>
                )}
              </>
            </Button>
          </Actions>
        </Cell>
        <ImageCell>
          <img src={opendoorPrintable} alt="Opendoor Printable" />
        </ImageCell>
      </Grid>
      <Badge>
        <BadgeGrid>
          <BadgeCell>
            <IconBadge />
          </BadgeCell>
          <BadgeCell>
            <BadgeTitle>Your listing is safe with Cloud CMA</BadgeTitle>
            <BadgeText>
              We only send your listing info to <strong>Opendoor</strong> if you
              request a cash offer. By selecting{" "}
              <strong>“Get cash offer”</strong>, you agree to the{" "}
              <BadgeLink
                href="//cloudagentsuite.com/investor-connect/tos"
                target="_blank">
                iBuyer Connect Terms of Service
              </BadgeLink>
              .
            </BadgeText>
          </BadgeCell>
        </BadgeGrid>
      </Badge>
    </>
  );
}

function InvestorOpendoorQualified({ estimate }) {
  const { report, lead, home, cashOffer } = useContext(InvestorOpendoorContext);
  const estimateFormatted = [
    estimate.low ? formatCurrency(estimate.low) : null,
    estimate.high ? formatCurrency(estimate.high) : null
  ]
    .filter(Boolean)
    .join(" - ");

  const commissionEstimateFormatted = [
    estimate.low ? formatCurrency(estimate.low * 0.01) : null,
    estimate.high ? formatCurrency(estimate.high * 0.01) : null
  ]
    .filter(Boolean)
    .join("-");

  const handleClick = () => {
    const url = home.url || lead.status_url || cashOffer.url;
    if (url) window.open(url, "_blank");
    window.location.href = "/cmas";
  };

  useEffect(() => {
    sendInvestorEvent(report.id, lead.id, "estimate_displayed");
  }, []);

  return (
    <>
      <EstimateTitle>
        Your client's estimated cash offer from <Emphasis>Opendoor</Emphasis>:
      </EstimateTitle>
      <Estimate>{estimateFormatted || "N/A"}</Estimate>
      <CommissionText>
        Your 1% commission from Opendoor Brokerage:
      </CommissionText>
      <CommissionRange>{commissionEstimateFormatted}</CommissionRange>
      <DetailsTitle>
        Ready to see Opendoor’s best offer? Answer a few questions and send us a
        short video.
      </DetailsTitle>
      <DetailsText>
        If you’re not ready yet, you can request an offer after your listing
        appointment.
      </DetailsText>
      <Actions>
        <Button app="cma" size="large" onClick={handleClick}>
          Let's get started
        </Button>
      </Actions>
    </>
  );
}

function InvestorOpendoorSent() {
  const { lead } = useContext(InvestorOpendoorContext);
  const isDenied = !!lead.status_denied_text;
  const text =
    lead.status_text ||
    "We are currently working on your offer. Please check back later.";
  const deniedText =
    lead.status_denied_text || "We're sorry, this property has been denied.";

  return (
    <>
      <StatusTitle>Cash offer from Opendoor</StatusTitle>
      <Text>
        {isDenied && deniedText && (
          <>
            We wish Opendoor could be one of the options you bring to your
            seller. Unfortunately, they said they're unable to make an offer on
            your client's home at this time as{" "}
            <Status dangerouslySetInnerHTML={{ __html: deniedText }} />.
          </>
        )}
        {!isDenied && <span dangerouslySetInnerHTML={{ __html: text }} />}
      </Text>
      <Actions>
        {!isDenied && lead.status_url && (
          <Button as="a" app="cma" href={lead.status_url} target="_blank">
            View Offer
          </Button>
        )}
        {isDenied && (
          <Button app="cma" variant="secondary" onClick={() => history.back()}>
            Go back
          </Button>
        )}
      </Actions>
      <Learn>
        <IconHelpOutline /> Learn more about{" "}
        <Link href="https://www.cloudagentsuite.com/opendoor" target="_blank">
          Cloud CMA and {lead.investor.name}
        </Link>
      </Learn>
    </>
  );
}

export default InvestorOpendoor;
