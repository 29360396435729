import React from "react";
import PropTypes from "prop-types";
import { Container, Message, Badge, Close } from "./styled/notification";

function Notification({
  message,
  type,
  isPopup,
  popupDirection,
  isCloseable,
  onClose
}) {
  return (
    <Container isPopup={isPopup} popupDirection={popupDirection} type={type}>
      <Message>
        {type === "marketing" && (
          <Badge className="notification-badge">New</Badge>
        )}
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </Message>
      {isCloseable && (
        <Close onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 20 20">
            <path
              fillRule="evenodd"
              d="M2.53 2.47a.75.75 0 011.061 0L10 8.879l6.409-6.41a.75.75 0 011.06 1.061l-6.408 6.41 6.47 6.469a.75.75 0 01-1.061 1.06L10 11l-6.47 6.47a.75.75 0 11-1.06-1.061l6.47-6.47L2.53 3.53a.75.75 0 010-1.06z"
              clipRule="evenodd"
            />
          </svg>
        </Close>
      )}
    </Container>
  );
}

Notification.defaultProps = {
  popupDirection: "right",
  isCloseable: true
};

Notification.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["update", "warning", "error", "success", "marketing"])
    .isRequired,
  isPopup: PropTypes.bool,
  popupDirection: PropTypes.oneOf(["left", "right"]),
  isCloseable: PropTypes.bool,
  onClose: PropTypes.func
};

export default Notification;
