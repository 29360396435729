import styled, { css } from "styled-components";

export const Title = styled.span`
  ${({ isActive, isEmpty, theme }) =>
    isActive &&
    css`
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 600;
      color: ${isEmpty && theme.styleGuide.colors.gray[600]};
    `}

  svg {
    width: 2.2rem;
    height: 2.2rem;
    color: #8b5cf6;
    margin-top: -0.4rem;
    margin-left: 0.4rem;
  }
`;

export const SubTitle = styled.span`
  font-size: ${({ isActive }) => isActive && "80%"};
`;
