import styled from "styled-components";

export const Price = styled.span`
  font-size: 1.3rem;
  line-height: 2rem;
  margin-right: 0.6rem;
  color: ${({ theme }) => theme.colors.gray};

  svg {
    width: 2.4rem;
    margin-right: 0.3rem;
    vertical-align: baseline;
    fill: ${({ theme }) => theme.colors.black};
    transform: translateY(0.1rem);
  }
`;

export const Status = styled.span`
  font-size: 1.1rem;
  line-height: 1.8rem;
`;
