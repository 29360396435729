import React from "react";
import PropTypes from "prop-types";
import CustomizePageAdd from "./CustomizePageAdd";
import CustomizePagePreview from "./CustomizePagePreview";
import CustomizePageEdit from "./CustomizePageEdit";

function CustomizeInactivePage({ page }) {
  return (
    <div className="customize-inactive-page">
      <CustomizePageAdd page={page} />
      <CustomizePagePreview page={page} />
      <CustomizePageEdit page={page} />
    </div>
  );
}

CustomizeInactivePage.propTypes = {
  page: PropTypes.object.isRequired
};

export default CustomizeInactivePage;
