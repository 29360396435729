import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import Label from "../common/Label";
import LinkToggler from "../togglers/LinkToggler";
import { Select, Option } from "../common/Select";

class AdvancedOptions extends Component {
  state = {
    options: this.props.options,
    open: this.props.open,
    lotSize:
      this.props.land_measurement && this.props.land_measurement.enabled
        ? this.props.land_measurement.selected.value
        : null,
    additionalMeasurements: {
      acres:
        this.props.additionalMeasurements &&
        this.props.additionalMeasurements.acres,
      sqft:
        this.props.additionalMeasurements &&
        this.props.additionalMeasurements.sqft
    }
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState(props);
  }

  handleChange = (e) => {
    const { name, value } = e.target;
    const options = this.state.options.map((option) => {
      if (option.name === name) {
        option.value = value;
        option.style = {};
      }

      return option;
    });

    if (
      name === "subject_property[lot_size]" &&
      this.state.lotSize === "acres"
    ) {
      this.setState({ additionalMeasurements: { acres: e.target.value } });
    }

    if (
      name === "subject_property[lot_size]" &&
      this.state.lotSize === "sqft"
    ) {
      this.setState({ additionalMeasurements: { sqft: e.target.value } });
    }

    this.setState({ options, open: true }, () =>
      this.props.onChange(this.state)
    );
  };

  handleSelectChange = (e) => {
    this.setState({ lotSize: e.target.value, open: true });
  };

  render() {
    const { title, tip, onToggle, land_measurement } = this.props;
    const { open, options, additionalMeasurements } = this.state;

    return (
      <LinkToggler
        isOpen={open}
        title={title}
        tip={tip}
        buttonAriaLabel="Advanced Options"
        onToggle={onToggle}>
        {options.map(({ label, name, value, style, key }, index) => (
          <div
            key={index}
            className={`w-1/2 p-4 advanced-option ${
              key === "lot_size" ? "sm:w-1/2" : "sm:w-1/4"
            }`}>
            <Label htmlFor={`option-${index}`}>{label}</Label>
            <div className="flex cma-input-text-select">
              <Input
                name={name}
                id={`option-${index}`}
                style={
                  key === "lot_size" && land_measurement.enabled
                    ? {
                        borderRadius: "0.4rem 0 0 0.4rem",
                        zIndex: "2",
                        maxWidth: "70px",
                        ...style
                      }
                    : style
                }
                value={
                  key === "lot_size" && this.state.lotSize === "acres"
                    ? additionalMeasurements.acres || value
                    : key === "lot_size" && this.state.lotSize === "sqft"
                    ? additionalMeasurements.sqft || value
                    : value
                }
                onChange={this.handleChange}
              />
              {key === "lot_size" && land_measurement.enabled ? (
                <Select
                  defaultValue={
                    land_measurement.selected && land_measurement.selected.value
                  }
                  id="subject-property-land-measurement"
                  name="subject_property[land_measurement]"
                  onChange={this.handleSelectChange}
                  style={{
                    borderRadius: "0 0.4rem 0.4rem 0",
                    backgroundColor: "rgba(243,244,246,0.4)",
                    left: "-1px"
                  }}>
                  {land_measurement.options.map((option) => (
                    <Option key={option.value} value={option.value}>
                      {option.label}
                    </Option>
                  ))}
                </Select>
              ) : (
                ""
              )}
            </div>
          </div>
        ))}
      </LinkToggler>
    );
  }
}

AdvancedOptions.defaultProps = {
  open: false,
  onChange: function () {},
  onToggle: function () {}
};

AdvancedOptions.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tip: PropTypes.string,
  options: PropTypes.array,
  onChange: PropTypes.func,
  onToggle: PropTypes.func,
  land_measurement: PropTypes.object
};

export default AdvancedOptions;
