import styled from "styled-components";

export const Preview = styled.div`
  flex-grow: 1;
  min-height: calc(100vh - 6rem);
  position: relative;

  ${({ theme, isShowingMobilePreview }) => theme.media.md`
    display: ${isShowingMobilePreview ? "block" : "none"};
  `}
`;

export const PreviewText = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  position: absolute;
  top: 0;
  left: 0;
`;

export const PreviewIFrame = styled.iframe`
  display: block;
  width: 100%;
  min-height: calc(100vh - 6rem);
`;

export const PreviewControls = styled.div`
  display: none;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;

  ${({ theme }) => theme.media.md`
    display: block;
  `}
`;

export const Card = styled.div`
  width: 100%;
  max-width: 40rem;
  padding: 3rem;
  text-align: center;
  border-radius: 0.6rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};

  ${({ theme }) => theme.media.sm`
    padding: 2rem;
  `}
`;

export const CardHeader = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  margin-bottom: 2rem;
  color: ${({ theme, hasError }) =>
    hasError
      ? theme.styleGuide.colors.red[600]
      : theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

export const CardText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  &:last-of-type {
    margin-bottom: 0;
  }

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const CardAction = styled.div`
  margin-top: 2rem;
`;
