import styled, { createGlobalStyle } from "styled-components";
import { Comparable as BaseComparable } from "../../../comparable";

export const Comparable = styled(BaseComparable)`
  border-radius: 0;
  cursor: ${({ isSubjectProperty }) =>
    isSubjectProperty ? "default" : "pointer"};

  a {
    color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const ImageContainer = styled.div`
  position: relative;
`;

export const Tag = styled.div`
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0.4rem 0.8rem;
  color: ${({ theme }) => theme.styleGuide.colors.white};
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Header = styled.h2`
  flex-shrink: 0;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  padding: 1.6rem 1.2rem 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
  border-left: 0;
  border-right: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const HeaderEdit = styled.button`
  flex-shrink: 0;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 1.6rem 1.2rem 1.3rem;
  color: ${({ theme }) => theme.homebeat.colors.primary};
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
  border-left: 0;
  border-right: 0;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.9rem;
    fill: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const Overflow = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
`;

// A hack to work around the slow rerendering of highlighting all rows
export const GlobalStyle = createGlobalStyle`
  .highlight-original-list-price .original-list-price,
  .highlight-list-price .list-price,
  .highlight-sold-price .sold-price,
  .highlight-price .price,
  .highlight-adjusted-price .adjusted-price,
  .highlight-price-per-sqft .price-per-sqft,
  .highlight-price-per-acre .price-per-acre,
  .highlight-sold-date .sold-date,
  .highlight-dom .dom,
  .highlight-virtual-tour .virtual-tour,
  .highlight-beds .beds,
  .highlight-baths .baths,
  .highlight-sqft .sqft,
  .highlight-lot-size .lot-size,
  .highlight-garages .garages {
    background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  }
`;
