import styled, { keyframes } from "styled-components";

const teeter = keyframes`
  0% { transform: rotate(10deg); }
  50% { transform: rotate(-10deg); }
  100% { transform: rotate(10deg); }
`;

const slide = keyframes`
  0% { transform: translate(2%); }
  50% { transform: translate(-2%); }
  100% { transform: translate(2%); }
`;

const popIn = keyframes`
  0% { opacity: 0;  transform: scale(0.8); }
  75% { transform: scale(1.15); }
  100% { opacity: 1; transform: scale(1); }
`;

const popOut = keyframes`
  0% { opacity: 1; transform: scale(1); }
  75% { transform: scale(0.85); }
  100% { opacity: 0; transform: scale(0.8); }
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 2.4rem;

  .fill-primary {
    fill: ${({ primaryColor }) => primaryColor};
  }

  .fill-secondary {
    fill: ${({ secondaryColor }) => secondaryColor};
  }

  .stroke-primary {
    stroke: ${({ primaryColor }) => primaryColor};
  }

  .stroke-secondary {
    stroke: ${({ secondaryColor }) => secondaryColor};
  }

  .text-primary {
    color: ${({ primaryColor }) => primaryColor};
  }

  .text-secondary {
    color: ${({ secondaryColor }) => secondaryColor};
  }

  .bg-primary {
    background-color: ${({ primaryColor }) => primaryColor};
  }

  .bg-secondary {
    background-color: ${({ secondaryColor }) => secondaryColor};
  }
`;

export const Content = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 4.8rem 6.4rem;
  border-radius: 1.2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};

  ${({ theme }) => theme.media.sm`
    padding: 3.2rem;
  `}
`;

export const Logo = styled.a`
  margin-bottom: 3.2rem;
  text-align: center;

  img {
    max-width: 18rem;
    max-height: 4.8rem;
  }

  svg {
    width: 18rem;
    height: 3.1rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -3.2rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    margin: -2.4rem;
  `}
`;

export const Cell = styled.div`
  padding: 3.2rem;

  ${({ theme }) => theme.media.sm`
    padding: 2.4rem;
  `}

  &:first-child {
    flex-shrink: 0;

    ${({ theme }) => theme.media.sm`
      padding-bottom: 0;
    `}
  }
`;

export const Illustration = styled.div`
  flex-shrink: 0;

  .animated {
    transform-origin: center;
    animation: ${teeter} 2.5s infinite;
  }

  .shadow {
    transform-origin: center;
    animation: ${slide} 2.5s infinite;
  }

  svg {
    width: 14.8rem;
    height: 8.8rem;
  }
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const IconContainer = styled.div`
  width: 2rem;
  height: 2rem;
  margin-right: 1.2rem;
  position: relative;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const ItemIconLoading = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  flex-shrink: 0;
  animation: ${({ isLoading }) => !isLoading && popOut} 300ms forwards;
  position: absolute;

  svg {
    fill: ${({ theme }) => theme.styleGuide.colors.gray[600]};
  }
`;

export const ItemIcon = styled.div`
  display: ${({ isVisible }) => (isVisible ? "block" : "none")};
  flex-shrink: 0;
  opacity: 0;
  transform: scale(0.8);
  animation: ${({ isLoading }) => !isLoading && popIn} 300ms forwards;
  position: absolute;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const ItemText = styled.strong`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  color: ${({ theme, isVisible, isLoading }) =>
    isLoading
      ? theme.styleGuide.colors.gray[700]
      : isVisible
      ? theme.styleGuide.colors.gray[900]
      : theme.styleGuide.colors.gray[600]};
  white-space: nowrap;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Item = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 1.6rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PoweredBy = styled.a`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 3.2rem;
  text-align: center;
  text-decoration: none;
  color: ${({ theme }) => theme.styleGuide.colors.gray[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
    margin-top: 2.4rem;
  `}

  svg {
    width: 9.6rem;
    height: 1.6rem;
    margin-top: 0.8rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[600]};
  }
`;

export const Error = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 2rem 0 0;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;
