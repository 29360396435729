import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 90rem;
  padding-bottom: 0;
`;

export const Body = styled.div`
  max-height: 65vh;
  margin: 0 -2rem;
  overflow-y: auto;
  overflow-x: hidden;

  ${({ theme }) => theme.media.sm`
    height: calc(100vh - 7rem);
    max-height: 100%;
  `}
`;

export const Remove = styled.div`
  margin-bottom: 2rem;
`;

export const Group = styled.div`
  margin-bottom: 3rem;
  padding: 0 2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;
