import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, font: requestState(state.font) };
}

export function resolved(state, action) {
  return {
    ...state,
    font: resolvedState({ ...state.font, selected: { ...action.payload } })
  };
}

export function rejected(state, action) {
  return { ...state, font: rejectedState(state.font, action.payload.error) };
}
