import React from "react";

function IconExcludeAll() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-931.000000, -809.000000)">
        <g transform="translate(177.000000, 795.000000)">
          <g transform="translate(752.000000, 8.000000)">
            <g>
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M14,10 L2,10 L2,12 L14,12 L14,10 L14,10 Z M14,6 L2,6 L2,8 L14,8 L14,6 L14,6 Z M19.4836914,14.4126236 L18.0694778,12.99841 L15.2410507,15.8268372 L12.4126236,12.99841 L10.99841,14.4126236 L13.8268372,17.2410507 L10.99841,20.0694778 L12.4126236,21.4836914 L15.2410507,18.6552643 L18.0694778,21.4836914 L19.4836914,20.0694778 L16.6552643,17.2410507 L19.4836914,14.4126236 Z M2,16 L10,16 L10,14 L2,14 L2,16 L2,16 Z"
                fill="#576474"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconExcludeAll;
