import { connect } from "react-redux";
import { isModernTheme, hasFeature } from "../../../../utils/report";
import CustomizeControls from "../CustomizeControls";

function select({
  report: { type },
  customize: {
    theme,
    layout,
    template,
    cover,
    font,
    language,
    title,
    description
  }
}) {
  return {
    reportType: type.toLowerCase(),
    theme,
    layout,
    template,
    cover,
    font,
    language,
    title,
    description,
    isModernTheme: isModernTheme(theme.selected),
    hasModernFlyers: hasFeature(theme.selected, "modern_flyers")
  };
}

const actions = {};

export default connect(select, actions)(CustomizeControls);
