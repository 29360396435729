import styled from "styled-components";
import BaseWrapper from "../../common/Wrapper";
import BaseButton from "../../common/Button";

export const Container = styled.div`
  font-family: "Circular";
  margin-top: -3rem;
  padding-top: 6.9rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Wrapper = styled(BaseWrapper)`
  max-width: 94rem;
`;

export const Hero = styled.div`
  margin-bottom: 4.8rem;
  text-align: center;

  ${({ theme }) => theme.media.md`
    margin-bottom: 3rem;
  `}
`;

export const HeroLogo = styled.div`
  margin-bottom: 1.8rem;

  svg {
    width: 15.3rem;
    height: 2.6rem;
    margin: 0 auto;
    fill: ${({ theme }) => theme.colors.purpleDark};
  }
`;

export const HeroTitle = styled.h1`
  font-weight: 500;
  font-size: 5.2rem;
  line-height: 5.8rem;
  margin-bottom: 0.9rem;
  color: ${({ theme }) => theme.colors.purpleDarker};

  ${({ theme }) => theme.media.sm`
    font-size: 4rem;
    line-height: 5rem;
  `}
`;

export const HeroDescription = styled.p`
  font-size: 2rem;
  line-height: 2.8rem;
  margin-bottom: 2.1rem;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Actions = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: -0.2rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    margin: -0.5rem;
  `}
`;

export const Action = styled.div`
  padding: 0.2rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    padding: 0.5rem;
  `}
`;

export const Preview = styled.div`
  margin-top: 4.8rem 0 5.1rem;
`;

export const SectionTitle = styled.h2`
  font-weight: 500;
  font-size: 3.6rem;
  line-height: 4.1rem;
  margin: 0 auto 4.3rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.purpleDarker};

  ${({ theme }) => theme.media.sm`
    font-size: 3rem;
    margin-bottom: 2rem;
  `}
`;

export const SectionSubTitle = styled.h3`
  font-size: 1.6rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.colors.purpleDarker};
`;

export const SectionIcon = styled.div`
  margin-bottom: 1rem;

  svg {
    width: 8.6rem;
    height: 8.6rem;
    margin: 0 auto;
  }
`;

export const SectionDescription = styled.p`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.gray};
`;

export const SectionNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 500;
  line-height: 1;
  width: 4.2rem;
  height: 4.2rem;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.purpleDarker};
  color: ${({ theme }) => theme.colors.white};
`;

export const Grid = styled.div`
  display: flex;
  margin: -1rem;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const Cell = styled.div`
  width: calc(100% / 3);
  padding: 1rem;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const Card = styled.div`
  height: 100%;
  padding: 4rem;
  box-shadow: ${({ theme }) => theme.shadows.raised};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  border: 0.1rem solid ${({ theme }) => theme.colors.grayLighter};
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.lg`
    padding: 3rem 2rem 2rem;
  `}
`;

export const Button = styled(BaseButton)`
  font-weight: 500;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 0.8rem;
  }
`;

export const Working = styled.div`
  margin: 5.1rem 0 7.9rem;
  text-align: center;

  ${({ theme }) => theme.media.sm`
    margin: 4rem 0;
  `}

  ${SectionNumber} {
    margin: -4.2rem auto 0;
    transform: translateY(-2rem);
  }

  ${SectionTitle} {
    margin: 0 auto 6.8rem;

    ${({ theme }) => theme.media.md`
      margin-bottom: 5rem;
    `}
  }

  ${SectionSubTitle} {
    font-size: 2.4rem;
    line-height: 4.1rem;
  }

  ${Card} {
    padding: 4rem 3rem 3rem;
  }

  ${Grid} {
    ${({ theme }) => theme.media.md`
      margin: -2rem;
    `}
  }

  ${Cell} {
    ${({ theme }) => theme.media.md`
      padding: 2rem;
    `}
  }
`;

export const Clients = styled.div`
  margin: 7.9rem 0 8.2rem;

  ${({ theme }) => theme.media.sm`
    margin: 4rem 0;
  `}

  ${SectionTitle} {
    margin-bottom: 6.7rem;

    ${({ theme }) => theme.media.md`
      margin-bottom: 4rem;
    `}
  }
`;

export const Client = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 56rem;
  margin: 0 auto 7.3rem;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}

  ${({ theme }) => theme.media.sm`
    margin: 0 auto 4rem;
  `}

  &:last-child {
    margin-bottom: 0;

    ${({ theme }) => theme.media.sm`
      margin: 0;
    `}
  }

  ${SectionTitle} {
    margin-bottom: 7.1rem;
  }
`;

export const ClientDetails = styled.div`
  max-width: 50%;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
    text-align: center;
  `}
`;

export const ClientIcon = styled.div`
  ${({ theme }) => theme.media.md`
    margin-bottom: 1rem;
    order: -1;
  `}

  svg {
    width: 15.4rem;
    height: 13rem;
  }
`;

export const GettingStarted = styled.div`
  padding: 8rem 0 4.9rem;
  background-color: #f9f9f9;

  ${({ theme }) => theme.media.sm`
    padding: 4rem 0;
  `}

  ${SectionTitle} {
    margin-bottom: 8.1rem;

    ${({ theme }) => theme.media.sm`
      margin-bottom: 5rem;
    `}
  }

  ${SectionNumber} {
    margin-top: -4.2rem;
    transform: translateY(-2rem);

    ${({ theme }) => theme.media.lg`
      transform: translateY(-1rem);
    `}
  }

  ${Grid} {
    ${({ theme }) => theme.media.md`
      margin: -2rem;
    `}
  }

  ${Cell} {
    ${({ theme }) => theme.media.md`
      padding: 2rem;
    `}
  }
`;

export const GettingStartedFooter = styled.div`
  margin-top: 4rem;
  text-align: center;

  ${({ theme }) => theme.media.sm`
    margin-top: 1rem;
  `}
`;

export const GettingStartedSubTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 4.1rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.purpleDarker};
`;

export const GettingStartedList = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1rem 0 0;
  padding: 0;
  list-style: none;
`;

export const GettingStartedListItem = styled.li`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  margin: 1rem;
  color: ${({ theme }) => theme.colors.gray};

  svg {
    width: 1.1rem;
    height: 1.2rem;
    margin-right: 0.5rem;

    g {
      stroke: ${({ theme }) => theme.colors.purpleDarker};
    }
  }
`;
