import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import IconContentCopy from "../../icons/material/content/IconContentCopy";
import IconErrorOutline from "../../icons/material/alert/IconErrorOutline";
import CustomizeModalCover from "../CustomizeModalCover";
import CustomizeControlsButton from "./CustomizeControlsButton";
import {
  Container,
  Icon,
  Text,
  Title,
  SubTitle
} from "./styled/customize-controls-button";

function CustomizeControlsCover({
  cover: {
    selected,
    request: { fetching, error = {} }
  }
}) {
  const hasError = error && !!Object.keys(error).length;

  return (
    <CustomizeControlsButton>
      {({ isModalOpen, openModal, closeModal }) => (
        <Fragment>
          <Container
            disabled={fetching}
            aria-label="Select a Cover"
            onClick={openModal}>
            <Icon hasError={hasError}>
              {!fetching && !hasError && (
                <IconContentCopy width={24} height={24} />
              )}
              {fetching && <Loading />}
              {!fetching && hasError && (
                <IconErrorOutline width={24} height={24} />
              )}
            </Icon>
            <Text hasError={hasError}>
              <Title>Cover</Title>
              <SubTitle>{hasError ? error.message : selected.title}</SubTitle>
            </Text>
          </Container>
          {isModalOpen && <CustomizeModalCover onClose={closeModal} />}
        </Fragment>
      )}
    </CustomizeControlsButton>
  );
}

CustomizeControlsCover.propTypes = {
  cover: PropTypes.shape({
    selected: PropTypes.shape({
      title: PropTypes.string
    }),
    request: PropTypes.object
  })
};

export default CustomizeControlsCover;
