import {
  ORDER_RESET,
  ORDER_NEW,
  ORDER_DISCOUNT
} from "../../actionTypes/order";
import {
  request as orderNewRequest,
  resolved as orderNewResolved,
  rejected as orderNewRejected
} from "./new";
import {
  request as orderDiscountRequest,
  resolved as orderDiscountResolved,
  rejected as orderDiscountRejected
} from "./discount";

const initialState = {
  amount: null,
  request: {
    fetching: false,
    fetched: false,
    error: {},
    errors: {},
    data: {}
  },
  discount: {
    request: {
      fetching: false,
      fetched: false,
      error: {},
      errors: {},
      data: {}
    }
  }
};

export default function (state = initialState, action) {
  switch (action.type) {
    case ORDER_RESET:
      return initialState;

    case `${ORDER_NEW}_REQUEST`:
      return orderNewRequest(state, action);
    case `${ORDER_NEW}_RESOLVED`:
      return orderNewResolved(state, action);
    case `${ORDER_NEW}_REJECTED`:
      return orderNewRejected(state, action);

    case `${ORDER_DISCOUNT}_REQUEST`:
      return orderDiscountRequest(state, action);
    case `${ORDER_DISCOUNT}_RESOLVED`:
      return orderDiscountResolved(state, action);
    case `${ORDER_DISCOUNT}_REJECTED`:
      return orderDiscountRejected(state, action);

    default:
      return state;
  }
}
