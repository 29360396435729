export const SUBJECT_PROPERTY_COPY_NET_SHEET =
  "SUBJECT_PROPERTY_COPY_NET_SHEET";
export const SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM =
  "SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM";
export const SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM =
  "SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM";
export const SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM =
  "SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM";
export const SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM =
  "SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM";
export const SUBJECT_PROPERTY_UPDATE_LIST_PRICE =
  "SUBJECT_PROPERTY_UPDATE_LIST_PRICE";
export const SUBJECT_PROPERTY_REMOVE_PHOTO = "SUBJECT_PROPERTY_REMOVE_PHOTO";
export const SUBJECT_PROPERTY_UPDATE_NOTE = "SUBJECT_PROPERTY_UPDATE_NOTE";
export const SUBJECT_PROPERTY_UPDATE_PRICE_RANGE =
  "SUBJECT_PROPERTY_UPDATE_PRICE_RANGE";
export const SUBJECT_PROPERTY_UPDATE_NET_SHEET_ITEMS =
  "SUBJECT_PROPERTY_UPDATE_NET_SHEET_ITEMS";
