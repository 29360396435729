import { connect } from "react-redux";
import { getReportType } from "../../../utils/types";
import { selectTemplate } from "../../../actions/customize";
import { groupTemplates, ungroupTemplates } from "../../../selectors/customize";
import CustomizeModalTemplate from "../CustomizeModalTemplate";

function select({ report, ...state }) {
  const [builtInTemplates = {}, customTemplates = {}] = groupTemplates(state);

  return {
    reportId: report.id || -1,
    reportType: getReportType(report.type) || "",
    allTemplates: ungroupTemplates(state),
    builtInTemplates: builtInTemplates.value || [],
    customTemplates: customTemplates.value || [],
    template: state.customize.template,
    request: state.customize.template.request,
    isRemoving: state.customize.template.remove || false
  };
}

const actions = {
  selectTemplate
};

export default connect(select, actions)(CustomizeModalTemplate);
