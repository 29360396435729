import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Select from "../select/Select";
import { groupThemes } from "../../selectors/customize";

function CustomizeModalThemeSelect({ selected, themes, onSelect }) {
  return (
    <Select
      id="theme"
      selected={selected}
      autoFocus={true}
      options={themes}
      onSelect={onSelect}
    />
  );
}

CustomizeModalThemeSelect.propTypes = {
  selected: PropTypes.object.isRequired,
  themes: PropTypes.array.isRequired,
  onSelect: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  themes: groupThemes(state)
});

export default connect(mapStateToProps)(CustomizeModalThemeSelect);
