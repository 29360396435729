import {
  formatCurrency,
  formatNumber,
  getDateShort,
  pluralize
} from "@wrstudios/utils";
import PropTypes from "prop-types";
import React, { useState } from "react";
import Helmet from "react-helmet";
import { useTheme } from "styled-components";
import {
  getAverageAdjustedPrice,
  getAverageAdjustedPricePerAcre,
  getAverageAdjustedPricePerSqft,
  getAverageDOM,
  getListingByHighestAdjustedPrice,
  getListingByLowestAdjustedPrice,
  getMappedStatus,
  getMedianAdjustedPrice,
  hasAveragePriceAdjustments
} from "../../../utils/listings";
import Adjustment from "../../common/Adjustment";
import BathsTooltip from "../../common/BathsTooltip";
import { Table } from "../../common/Table";
import Tabs from "../../common/Tabs";
import IllustrationComparables from "../../illustrations/IllustrationComparables";
import HomebeatViewerListingDetailsModal from "./HomebeatViewerListingDetailsModal";
import HomebeatViewerNavigation from "./HomebeatViewerNavigation";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerValuationModal from "./HomebeatViewerValuationModal";
import {
  CityStateZip,
  Container,
  Header,
  HomebeatViewerListingToggle,
  HomebeatViewerListingToggleContainer,
  HomeValuation,
  HomeValuationIllustration,
  HomeValuationText,
  Stat,
  StatLabel,
  Stats,
  StatsCell,
  StatsContainer,
  StatsContainerCell,
  StatsContainerGrid,
  StatsGrid,
  Status,
  StatValue,
  Street,
  Tab,
  TableContainer,
  TableData,
  TableHeader,
  TableHeading,
  TableRow
} from "./styled/homebeat-viewer-stats";

function HomebeatViewerStats() {
  const theme = useTheme();
  const { listings } = useHomebeatViewer();
  const mappedStatus = getMappedStatus(listings);
  const [previewListingId, setPreviewListingId] = useState(null);
  const [activeStatus, setActiveStatus] = useState("");

  return (
    <>
      <Helmet>
        <title>List - Homebeat</title>
      </Helmet>
      <HomebeatViewerNavigation>Your Homebeat</HomebeatViewerNavigation>
      <Container>
        <Header>
          <Tabs>
            <Tab
              isActive={!activeStatus}
              activeColor={theme.homebeat.colors.primary}
              onClick={() => setActiveStatus("")}>
              All
            </Tab>
            {mappedStatus.map((status) => (
              <Tab
                key={status}
                isActive={activeStatus === status}
                activeColor={theme.homebeat.colors.primary}
                onClick={() => setActiveStatus(status)}>
                {status}
              </Tab>
            ))}
          </Tabs>
        </Header>
        <HomebeatViewerStatsGrid activeStatus={activeStatus} />
        <HomebeatViewerStatsTable
          activeStatus={activeStatus}
          setPreviewListingId={setPreviewListingId}
        />
        {previewListingId && (
          <HomebeatViewerListingDetailsModal
            listingId={previewListingId}
            onClose={() => setPreviewListingId(null)}
          />
        )}
      </Container>
    </>
  );
}

function HomebeatViewerStatsGrid({ activeStatus }) {
  const { listings, includedListingIds, isAcreageReport } = useHomebeatViewer();
  const filteredListings = listings
    .filter((listing) => !activeStatus || listing.mappedStatus === activeStatus)
    .filter((listing) => includedListingIds.includes(listing.id));
  const highestPricedListing =
    getListingByHighestAdjustedPrice(filteredListings);
  const lowestPricedListing = getListingByLowestAdjustedPrice(filteredListings);
  const averageAdjustedPrice = getAverageAdjustedPrice(filteredListings);
  const medianPrice = getMedianAdjustedPrice(filteredListings);
  const averagePricePerSqft = getAverageAdjustedPricePerSqft(filteredListings);
  const averagePricePerAcre = getAverageAdjustedPricePerAcre(filteredListings);
  const averageDOM = getAverageDOM(filteredListings);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <StatsContainer>
        <StatsContainerGrid>
          <StatsContainerCell>
            <StatsGrid>
              <StatsCell>
                <Stats>
                  <Stat>
                    <StatLabel title="Low Price">Low Price</StatLabel>
                    <StatValue>
                      {lowestPricedListing && lowestPricedListing.price
                        ? formatCurrency(lowestPricedListing.price)
                        : "-"}
                    </StatValue>
                  </Stat>
                  <Stat>
                    <StatLabel title="High Price">High Price</StatLabel>
                    <StatValue>
                      {highestPricedListing && highestPricedListing.price
                        ? formatCurrency(highestPricedListing.price)
                        : "-"}
                    </StatValue>
                  </Stat>
                  <Stat>
                    <StatLabel title="Average Price">Average Price</StatLabel>
                    <StatValue>
                      {!!averageAdjustedPrice
                        ? formatCurrency(averageAdjustedPrice)
                        : "-"}
                    </StatValue>
                  </Stat>
                </Stats>
              </StatsCell>
              <StatsCell>
                <Stats>
                  <Stat>
                    <StatLabel title="Median Price">Median Price</StatLabel>
                    <StatValue>
                      {!!medianPrice ? formatCurrency(medianPrice) : "-"}
                    </StatValue>
                  </Stat>
                  {isAcreageReport && (
                    <Stat>
                      <StatLabel title="Avg $/acre">Avg $/acre</StatLabel>
                      <StatValue>
                        {averagePricePerAcre
                          ? `${formatCurrency(averagePricePerAcre)} / acre`
                          : "-"}
                      </StatValue>
                    </Stat>
                  )}
                  {!isAcreageReport && (
                    <Stat>
                      <StatLabel title="Avg $/ sq. ft">Avg $/ sq. ft</StatLabel>
                      <StatValue>
                        {!!averagePricePerSqft
                          ? `${formatCurrency(averagePricePerSqft)} / sq. ft`
                          : "-"}
                      </StatValue>
                    </Stat>
                  )}
                  <Stat>
                    <StatLabel title="Avg days on market">
                      Avg days on market
                    </StatLabel>
                    <StatValue>
                      {!!averageDOM
                        ? `${formatNumber(averageDOM, "0,0")} ${pluralize(
                            "Day",
                            averageDOM
                          )}`
                        : "-"}
                    </StatValue>
                  </Stat>
                </Stats>
              </StatsCell>
            </StatsGrid>
          </StatsContainerCell>
          <StatsContainerCell>
            <HomeValuation onClick={() => setShowModal(true)}>
              <HomeValuationIllustration>
                <IllustrationComparables />
              </HomeValuationIllustration>
              <HomeValuationText>
                Get an Expert Home Valuation
              </HomeValuationText>
            </HomeValuation>
          </StatsContainerCell>
        </StatsContainerGrid>
      </StatsContainer>
      {showModal && (
        <HomebeatViewerValuationModal onClose={() => setShowModal(false)} />
      )}
    </>
  );
}

HomebeatViewerStatsGrid.propTypes = {
  activeStatus: PropTypes.string.isRequired
};

function HomebeatViewerStatsTable({ activeStatus, setPreviewListingId }) {
  const theme = useTheme();
  const {
    listings,
    subjectProperty,
    isAcreageReport,
    showAcresInsteadOfLotSize
  } = useHomebeatViewer();
  const hasPriceAdjustments = hasAveragePriceAdjustments(listings);
  const filteredListings = listings.filter(
    (listing) => !activeStatus || listing.mappedStatus === activeStatus
  );
  const primarySubjectPropertyLotSize = showAcresInsteadOfLotSize
    ? subjectProperty.acres
    : subjectProperty.lotSize;

  return (
    <TableContainer>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHeading className="table-heading-list-view">
              <HomebeatViewerListingToggleContainer />
              Address
            </TableHeading>
            <TableHeading className="table-heading-list-view">
              Status
            </TableHeading>
            <TableHeading className="table-heading-list-view">
              Price
            </TableHeading>
            {hasPriceAdjustments && (
              <TableHeading className="table-heading-list-view">
                Adj. Price
              </TableHeading>
            )}
            <TableHeading className="table-heading-list-view">
              Sold Date
            </TableHeading>
            {!isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                $/Sq.Ft
              </TableHeading>
            )}
            {hasPriceAdjustments && !isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                Adj. $/Sq.Ft.
              </TableHeading>
            )}
            {isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                $/Acre
              </TableHeading>
            )}
            {hasPriceAdjustments && isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                Adj. $/Acre
              </TableHeading>
            )}
            <TableHeading className="table-heading-list-view">Dom</TableHeading>
            {!isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                Beds
              </TableHeading>
            )}
            {!isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                Baths
              </TableHeading>
            )}
            {!isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                Sq.Ft.
              </TableHeading>
            )}
            {isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                Acres
              </TableHeading>
            )}
            <TableHeading className="table-heading-list-view">
              Lot Size
            </TableHeading>
            {!isAcreageReport && (
              <TableHeading className="table-heading-list-view">
                Garage
              </TableHeading>
            )}
          </TableRow>
        </TableHeader>
        <tbody>
          <TableRow isSubjectProperty>
            <TableData>
              <HomebeatViewerListingToggleContainer />
              <Street>
                {subjectProperty.street}{" "}
                <CityStateZip>{subjectProperty.cityStateZip}</CityStateZip>
              </Street>
            </TableData>
            <TableData>
              <Status statusColor={theme.styleGuide.colors.purple[500]}>
                Subject
              </Status>
            </TableData>
            <TableData>
              <strong>
                {subjectProperty.priceRange.min
                  ? formatCurrency(subjectProperty.priceRange.min)
                  : "-"}
              </strong>
            </TableData>
            {hasPriceAdjustments && (
              <TableData>
                <strong>
                  {subjectProperty.priceRange.min ? (
                    <Adjustment
                      value={subjectProperty.priceRange.min}
                      adjustedValue={subjectProperty.priceRange.min}
                    />
                  ) : (
                    "-"
                  )}
                </strong>
              </TableData>
            )}
            <TableData>
              <strong>-</strong>
            </TableData>
            <TableData>
              <strong>
                {subjectProperty.priceRange.min && subjectProperty.sqft
                  ? formatCurrency(
                      subjectProperty.priceRange.min / subjectProperty.sqft
                    )
                  : "-"}
              </strong>
            </TableData>
            {hasPriceAdjustments && (
              <TableData>
                <strong>
                  {subjectProperty.priceRange.min && subjectProperty.sqft ? (
                    <Adjustment
                      value={
                        subjectProperty.priceRange.min / subjectProperty.sqft
                      }
                      adjustedValue={
                        subjectProperty.priceRange.min / subjectProperty.sqft
                      }
                    />
                  ) : (
                    "-"
                  )}
                </strong>
              </TableData>
            )}
            <TableData>
              <strong>-</strong>
            </TableData>
            {!isAcreageReport && (
              <TableData>
                <strong>
                  {subjectProperty.beds
                    ? formatNumber(subjectProperty.beds)
                    : "-"}
                </strong>
              </TableData>
            )}
            {!isAcreageReport && (
              <TableData>
                <strong>
                  {subjectProperty.baths
                    ? formatNumber(subjectProperty.baths)
                    : "-"}
                </strong>
              </TableData>
            )}
            {!isAcreageReport && (
              <TableData>
                <strong>
                  {subjectProperty.sqft
                    ? formatNumber(subjectProperty.sqft)
                    : "-"}
                </strong>
              </TableData>
            )}
            {isAcreageReport && (
              <TableData>
                <strong>
                  {subjectProperty.acres
                    ? formatNumber(subjectProperty.acres, "0.[0]0")
                    : "-"}
                </strong>
              </TableData>
            )}
            <TableData>
              <strong>
                {primarySubjectPropertyLotSize
                  ? formatNumber(
                      primarySubjectPropertyLotSize,
                      primarySubjectPropertyLotSize < 1000
                        ? "0[,][.][0]0"
                        : "0,[0]"
                    )
                  : "-"}
              </strong>
            </TableData>
            {!isAcreageReport && (
              <TableData>
                <strong>
                  {subjectProperty.garages
                    ? formatNumber(subjectProperty.garages)
                    : "-"}
                </strong>
              </TableData>
            )}
          </TableRow>
          {filteredListings.map((listing) => {
            const primaryLotSize =
              (showAcresInsteadOfLotSize || isAcreageReport
                ? listing.acres
                : listing.lotSize) || 0;

            return (
              <TableRow
                key={listing.id}
                isGhost={listing.isGhost}
                onClick={() => setPreviewListingId(listing.id)}>
                <TableData>
                  <HomebeatViewerListingToggleContainer>
                    <HomebeatViewerListingToggle
                      listingId={listing.id}
                      label=""
                    />
                  </HomebeatViewerListingToggleContainer>
                  <Street>
                    {listing.street}{" "}
                    <CityStateZip>{listing.cityStateZip}</CityStateZip>
                  </Street>
                </TableData>
                <TableData>
                  <Status statusColor={listing.statusColor}>
                    {listing.status || "No Status"}
                  </Status>
                </TableData>
                <TableData>
                  {listing.price ? formatCurrency(listing.price) : "-"}
                </TableData>
                {hasPriceAdjustments && (
                  <TableData>
                    <Adjustment
                      value={listing.price}
                      adjustedValue={listing.adjustedPrice}
                    />
                  </TableData>
                )}
                <TableData>
                  {listing.soldDate ? getDateShort(listing.soldDate) : "-"}
                </TableData>
                {!isAcreageReport && (
                  <TableData>
                    {listing.pricePerSqft
                      ? formatCurrency(listing.pricePerSqft)
                      : "-"}
                  </TableData>
                )}
                {isAcreageReport && (
                  <TableData>
                    {listing.pricePerAcre
                      ? formatCurrency(listing.pricePerAcre)
                      : "-"}
                  </TableData>
                )}
                {hasPriceAdjustments && !isAcreageReport && (
                  <TableData>
                    <Adjustment
                      value={listing.pricePerSqft}
                      adjustedValue={listing.adjustedPricePerSqft}
                    />
                  </TableData>
                )}
                {hasPriceAdjustments && isAcreageReport && (
                  <TableData>
                    <Adjustment
                      value={listing.pricePerAcre}
                      adjustedValue={listing.adjustedPricePerAcre}
                    />
                  </TableData>
                )}
                <TableData>
                  {listing.dom ? formatNumber(listing.dom) : "-"}
                </TableData>
                {!isAcreageReport && (
                  <TableData>
                    {listing.beds ? formatNumber(listing.beds) : "-"}
                  </TableData>
                )}
                {!isAcreageReport && (
                  <TableData>
                    {listing.baths ? (
                      <BathsTooltip baths={listing.formattedBaths}>
                        {listing.baths}
                      </BathsTooltip>
                    ) : (
                      "-"
                    )}
                  </TableData>
                )}
                {!isAcreageReport && (
                  <TableData>
                    {listing.sqft ? formatNumber(listing.sqft) : "-"}
                  </TableData>
                )}
                {isAcreageReport && (
                  <TableData>
                    {listing.acres
                      ? formatNumber(listing.acres, "0.[0]0")
                      : "-"}
                  </TableData>
                )}
                <TableData>
                  {primaryLotSize
                    ? formatNumber(
                        primaryLotSize,
                        primaryLotSize < 1000 ? "0[,][.][0]0" : "0,[0]"
                      )
                    : "-"}
                </TableData>
                {!isAcreageReport && (
                  <TableData>
                    {listing.garages ? formatNumber(listing.garages) : "-"}
                  </TableData>
                )}
              </TableRow>
            );
          })}
        </tbody>
      </Table>
    </TableContainer>
  );
}

HomebeatViewerStatsTable.propTypes = {
  activeStatus: PropTypes.string.isRequired,
  setPreviewListingId: PropTypes.func.isRequired
};

export default HomebeatViewerStats;
