import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { isUri } from "valid-url";

function ListingDetailsFeatures({ features }) {
  const sortedFeatures = Object.keys(features).sort();

  return (
    <Fragment>
      <h3 className="text-sbase leading-sxl font-normal text-grey-darker mb-4">
        Features
      </h3>
      <ul className="list-reset listing-additional-details-features">
        {sortedFeatures.map((key, index) => {
          const name = key;
          const value = features[key];

          if (!value) return null;

          return (
            <li key={index} className="text-sm leading-xl">
              {isUri(String(value)) && (
                <Fragment>
                  <strong>{name}</strong>:{" "}
                  <a
                    href={value}
                    target="_blank"
                    className="no-underline text-green"
                    rel="noreferrer">
                    View
                  </a>
                </Fragment>
              )}
              {!isUri(String(value)) && (
                <Fragment>
                  <strong>{name}</strong>: {value}
                </Fragment>
              )}
            </li>
          );
        })}
      </ul>
    </Fragment>
  );
}

ListingDetailsFeatures.propTypes = {
  features: PropTypes.object
};

export default ListingDetailsFeatures;
