import React from "react";
import PropTypes from "prop-types";
import IconClose from "../icons/material/navigation/IconClose";
import {
  Close,
  Modal,
  ModalContent,
  ModalHeader,
  ModalBody,
  YouTube
} from "./styled/youtube-modal";

function YouTubeModal({ videoId, videoTitle, onClose, ...props }) {
  return (
    <Modal onClose={onClose}>
      <Close onClick={onClose}>
        <IconClose />
      </Close>
      <ModalContent>
        {videoTitle && <ModalHeader>{videoTitle}</ModalHeader>}
        <ModalBody>
          <YouTube {...props} videoId={videoId} />
        </ModalBody>
      </ModalContent>
    </Modal>
  );
}

YouTubeModal.defaultProps = {
  onClose: () => {}
};

YouTubeModal.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
  onClose: PropTypes.func.isRequired
};

export default YouTubeModal;
