import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import { Tab, TabList, TabPanel } from "../tabs";
import CustomizeModalTemplateCreate from "./connected/CustomizeModalTemplateCreate";
import CustomizeModalTemplateCard from "./connected/CustomizeModalTemplateCard";
import {
  ModalContent,
  Content,
  Tabs,
  Text,
  Templates,
  Grid,
  Cell
} from "./styled/customize-modal-template";

function CustomizeModalTemplate({
  reportId,
  reportType,
  allTemplates,
  builtInTemplates,
  customTemplates,
  selectTemplate,
  isRemoving,
  onClose
}) {
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  const handleTabChange = (selectedTabIndex) => {
    setSelectedTabIndex(selectedTabIndex);
  };

  useEffect(() => {
    return () => {
      if (isRemoving) {
        selectTemplate(reportId, reportType, {
          key: "default",
          title: "Default Template"
        });
      }
    };
  }, []);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Template
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Content>
            <Text>
              Templates are a great way to save your current report's page set
              and order. You can also select from popular built-in templates.
            </Text>
            <Tabs selectedIndex={selectedTabIndex} onSelect={handleTabChange}>
              <TabList>
                <Tab>All</Tab>
                <Tab>Built In</Tab>
                <Tab>Custom</Tab>
              </TabList>
              <TabPanel>
                <Templates>
                  <Grid>
                    <Cell>
                      <CustomizeModalTemplateCreate
                        changeTab={handleTabChange}
                      />
                    </Cell>
                    {allTemplates.map((template) => (
                      <Cell key={template.value}>
                        <CustomizeModalTemplateCard
                          templateId={template.value}
                          onClose={onClose}
                        />
                      </Cell>
                    ))}
                  </Grid>
                </Templates>
              </TabPanel>
              <TabPanel>
                <Templates>
                  <Grid>
                    <Cell>
                      <CustomizeModalTemplateCreate
                        changeTab={handleTabChange}
                      />
                    </Cell>
                    {builtInTemplates.map((template) => (
                      <Cell key={template.value}>
                        <CustomizeModalTemplateCard
                          templateId={template.value}
                          onClose={onClose}
                        />
                      </Cell>
                    ))}
                  </Grid>
                </Templates>
              </TabPanel>
              <TabPanel>
                <Templates>
                  <Grid>
                    <Cell>
                      <CustomizeModalTemplateCreate
                        changeTab={handleTabChange}
                      />
                    </Cell>
                    {customTemplates.map((template) => (
                      <Cell key={template.value}>
                        <CustomizeModalTemplateCard
                          templateId={template.value}
                          onClose={onClose}
                        />
                      </Cell>
                    ))}
                  </Grid>
                </Templates>
              </TabPanel>
            </Tabs>
          </Content>
        </Modal.Body>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalTemplate.propTypes = {
  reportId: PropTypes.number.isRequired,
  reportType: PropTypes.string.isRequired,
  allTemplates: PropTypes.array.isRequired,
  builtInTemplates: PropTypes.array.isRequired,
  customTemplates: PropTypes.array.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  isRemoving: PropTypes.bool.isRequired,
  onClose: PropTypes.func
};

export default CustomizeModalTemplate;
