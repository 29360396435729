export const CUSTOMIZE_RESET_REQUEST = "CUSTOMIZE_RESET_REQUEST";
export const CUSTOMIZE_RESET_SELECTED_PAGE = "CUSTOMIZE_RESET_SELECTED_PAGE";
export const CUSTOMIZE_ADD_ACTIVE_PAGE = "CUSTOMIZE_ADD_ACTIVE_PAGE";
export const CUSTOMIZE_REMOVE_ACTIVE_PAGE = "CUSTOMIZE_REMOVE_ACTIVE_PAGE";
export const CUSTOMIZE_SORT_ACTIVE_PAGES = "CUSTOMIZE_SORT_ACTIVE_PAGES";
export const CUSTOMIZE_SORT_ACTIVE_PAGE_CHILDREN =
  "CUSTOMIZE_SORT_ACTIVE_PAGE_CHILDREN";
export const CUSTOMIZE_UPDATE_COVER = "CUSTOMIZE_UPDATE_COVER";
export const CUSTOMIZE_UPDATE_FONT = "CUSTOMIZE_UPDATE_FONT";
export const CUSTOMIZE_UPDATE_LANGUAGE = "CUSTOMIZE_UPDATE_LANGUAGE";
export const CUSTOMIZE_UPDATE_ILLUSTRATION = "CUSTOMIZE_UPDATE_ILLUSTRATION";
export const CUSTOMIZE_UPDATE_LAYOUT = "CUSTOMIZE_UPDATE_LAYOUT";
export const CUSTOMIZE_UPDATE_THEME = "CUSTOMIZE_UPDATE_THEME";
export const CUSTOMIZE_UPDATE_HEADLINE = "CUSTOMIZE_UPDATE_HEADLINE";
export const CUSTOMIZE_UPDATE_TITLE = "CUSTOMIZE_UPDATE_TITLE";
export const CUSTOMIZE_UPDATE_DESCRIPTION = "CUSTOMIZE_UPDATE_DESCRIPTION";
export const CUSTOMIZE_SELECT_TEMPLATE = "CUSTOMIZE_SELECT_TEMPLATE";
export const CUSTOMIZE_UPDATE_TEMPLATE = "CUSTOMIZE_UPDATE_TEMPLATE";
export const CUSTOMIZE_SAVE_TEMPLATE = "CUSTOMIZE_SAVE_TEMPLATE";
export const CUSTOMIZE_REMOVE_TEMPLATE = "CUSTOMIZE_REMOVE_TEMPLATE";
export const CUSTOMIZE_CLEAR_TEMPLATE = "CUSTOMIZE_CLEAR_TEMPLATE";
export const CUSTOMIZE_ADD_CUSTOM_PAGE = "CUSTOMIZE_ADD_CUSTOM_PAGE";
export const CUSTOMIZE_EDIT_CUSTOM_PAGE = "CUSTOMIZE_EDIT_CUSTOM_PAGE";
export const CUSTOMIZE_RENAME_CUSTOM_PAGE = "CUSTOMIZE_RENAME_CUSTOM_PAGE";
export const CUSTOMIZE_REMOVE_CUSTOM_PAGE = "CUSTOMIZE_REMOVE_CUSTOM_PAGE";
export const CUSTOMIZE_SAVE_CUSTOM_PAGE = "CUSTOMIZE_SAVE_CUSTOM_PAGE";
export const CUSTOMIZE_ADD_CUSTOM_PDF_PAGE = "CUSTOMIZE_ADD_CUSTOM_PDF_PAGE";
export const CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE =
  "CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE";
export const CUSTOMIZE_RESET_PAGES = "CUSTOMIZE_RESET_PAGES";
export const CUSTOMIZE_PAGE_META = "CUSTOMIZE_PAGE_META";
