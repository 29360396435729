import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return {
    ...state,
    template: requestState({ ...state.template, load: true })
  };
}

export function resolved(state, action) {
  const { template } = action.payload;
  const { customize } = action.payload[1];

  return Object.assign({}, state, {
    ...JSON.parse(JSON.stringify(customize)),
    template: resolvedState({
      ...state.template,
      selected: template,
      load: true,
      remove: false,
      hasModified: false
    }),
    load: false
  });
}

export function rejected(state, action) {
  return {
    ...state,
    template: rejectedState(state.template, action.payload.error)
  };
}
