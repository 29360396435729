import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "@wrstudios/utils";
import { Container, Arrow } from "./styled/adjustment";

function Adjustment({ value, adjustedValue, shouldDim }) {
  const isAdjusted = !!value && !!adjustedValue;
  const diff = isAdjusted ? adjustedValue - value : 0;
  const diffPositive = diff > 0;
  const diffNegative = diff < 0;
  const calculatedValue = Math.abs(adjustedValue || value);

  return (
    <Container shouldDim={shouldDim ? !diff : false}>
      {diffPositive && <Arrow isPositive>&uarr; </Arrow>}
      {diffNegative && <Arrow>&darr; </Arrow>}
      {!!value
        ? formatCurrency(
            calculatedValue,
            calculatedValue < 1 ? "$0[.]0[0]0" : undefined
          )
        : "-"}
    </Container>
  );
}

Adjustment.defaultProps = {
  shouldDim: true
};

Adjustment.propTypes = {
  value: PropTypes.number,
  adjustedValue: PropTypes.number,
  shouldDim: PropTypes.bool
};

export default Adjustment;
