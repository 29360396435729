import {
  initialRequestState,
  requestState,
  rejectedState
} from "../../utils/redux";

export function request(state, action) {
  const { reportId } = action.payload;
  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      return requestState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function resolved(state, action) {
  const { reportId, launch_url, investor } = action.payload;
  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      // We reset to inital as we will need to send to the server.
      return initialRequestState({ ...report, launch_url, investor });
    }

    return report;
  });

  return { ...state, all: reports };
}

export function rejected(state, action) {
  const { reportId } = action.payload;
  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      return rejectedState(report, action.payload.error);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function reset(state) {
  const reports = state.all.map((report) => {
    const newReport = { ...report };

    newReport.survey_questions = (newReport.survey_questions || []).map(
      (question) => {
        const newQuestion = { ...question };

        newQuestion.answer = "";

        return newQuestion;
      }
    );

    return initialRequestState(newReport);
  });

  return { ...state, all: reports };
}
