import {
  REPORT_RESET_REQUEST,
  REPORT_GET_STATUS,
  REPORT_EMAIL,
  REPORT_DELETE,
  REPORT_PUBLISH
} from "../../actionTypes/reports";
import {
  INVESTOR_OFFER_REQUEST,
  INVESTOR_OFFER_SUBMIT,
  INVESTOR_QUESTIONNAIRE,
  INVESTOR_QUESTIONNAIRE_RESET
} from "../../actionTypes/investor";
import { reset as resetDefaultRequest } from "./reset";
import {
  request as deleteReportRequest,
  resolved as deleteReportResolved,
  rejected as deleteReportRejected
} from "./delete";
import {
  request as publishReportRequest,
  resolved as publishReportResolved,
  rejected as publishReportRejected
} from "./publish";
import {
  request as getReportStatusRequest,
  resolved as getReportStatusResolved,
  rejected as getReportStatusRejected
} from "./status";
import {
  request as emailReportRequest,
  resolved as emailReportResolved,
  rejected as emailReportRejected
} from "./email";
import {
  request as investorOfferRequestRequest,
  resolved as investorOfferRequestResolved,
  rejected as investorOfferRequestRejected
} from "../investors/request";
import {
  request as investorOfferSubmitRequest,
  resolved as investorOfferSubmitResolved,
  rejected as investorOfferSubmitRejected
} from "../investors/submit";
import {
  request as investorQuestionnaireRequest,
  resolved as investorQuestionnaireResolved,
  rejected as investorQuestionnaireRejected,
  reset as resetQuestionnaire
} from "../investors/questionnaire";

export default function (state = {}, action) {
  switch (action.type) {
    case REPORT_RESET_REQUEST:
      return resetDefaultRequest(state, action);

    case `${REPORT_DELETE}_REQUEST`:
      return deleteReportRequest(state, action);
    case `${REPORT_DELETE}_RESOLVED`:
      return deleteReportResolved(state, action);
    case `${REPORT_DELETE}_REJECTED`:
      return deleteReportRejected(state, action);

    case `${REPORT_PUBLISH}_REQUEST`:
      return publishReportRequest(state, action);
    case `${REPORT_PUBLISH}_RESOLVED`:
      return publishReportResolved(state, action);
    case `${REPORT_PUBLISH}_REJECTED`:
      return publishReportRejected(state, action);

    case `${REPORT_GET_STATUS}_REQUEST`:
      return getReportStatusRequest(state, action);
    case `${REPORT_GET_STATUS}_RESOLVED`:
      return getReportStatusResolved(state, action);
    case `${REPORT_GET_STATUS}_REJECTED`:
      return getReportStatusRejected(state, action);

    case `${REPORT_EMAIL}_REQUEST`:
      return emailReportRequest(state, action);
    case `${REPORT_EMAIL}_RESOLVED`:
      return emailReportResolved(state, action);
    case `${REPORT_EMAIL}_REJECTED`:
      return emailReportRejected(state, action);

    case `${INVESTOR_OFFER_REQUEST}_REQUEST`:
      return investorOfferRequestRequest(state, action);
    case `${INVESTOR_OFFER_REQUEST}_RESOLVED`:
      return investorOfferRequestResolved(state, action);
    case `${INVESTOR_OFFER_REQUEST}_REJECTED`:
      return investorOfferRequestRejected(state, action);

    case `${INVESTOR_OFFER_SUBMIT}_REQUEST`:
      return investorOfferSubmitRequest(state, action);
    case `${INVESTOR_OFFER_SUBMIT}_RESOLVED`:
      return investorOfferSubmitResolved(state, action);
    case `${INVESTOR_OFFER_SUBMIT}_REJECTED`:
      return investorOfferSubmitRejected(state, action);

    case `${INVESTOR_QUESTIONNAIRE}_REQUEST`:
      return investorQuestionnaireRequest(state, action);
    case `${INVESTOR_QUESTIONNAIRE}_RESOLVED`:
      return investorQuestionnaireResolved(state, action);
    case `${INVESTOR_QUESTIONNAIRE}_REJECTED`:
      return investorQuestionnaireRejected(state, action);

    case INVESTOR_QUESTIONNAIRE_RESET:
      return resetQuestionnaire(state, action);

    default:
      return state;
  }
}
