import styled from "styled-components";

export const Header = styled.h3`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const Options = styled.ul`
  margin: 0;
  padding: 2.5rem;
  list-style: none;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  border-radius: 0.6rem;
`;

export const Option = styled.li`
  margin-bottom: 1.2rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  appearance: none;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  text-decoration: none;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    fill: currentColor;
  }
`;
