import styled from "styled-components";

export const Container = styled.ul`
  font-size: 1.3rem;
  line-height: 2rem;
  padding: 0;
  margin: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  overflow: hidden;
  position: relative;

  &:before {
    position: absolute;
    bottom: 0;
    width: 0;
    white-space: nowrap;
    line-height: 1.7rem;
    content: ". . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .";
  }
`;

export const ListItemLabel = styled.span`
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  position: relative;
`;

export const ListItemValue = styled.strong`
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  position: relative;
`;
