import { connect } from "react-redux";
import { resetRequestState } from "../../../../actions/customize";
import CustomizeControlsTheme from "../CustomizeControlsTheme";

function select({ report, customize: { theme } }) {
  return {
    report,
    theme
  };
}

const actions = (dispatch) => ({
  resetRequestState: () => dispatch(resetRequestState("theme"))
});

export default connect(select, actions)(CustomizeControlsTheme);
