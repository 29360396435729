import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext } from "react-beautiful-dnd";
import {
  sortActivePages,
  sortActivePageChildren
} from "../../actions/customize";
import { addBodyClass, removeBodyClass } from "../../utils/page";
import CustomizeActivePagesDroppable from "./CustomizeActivePagesDroppable";

class CustomizeActivePages extends Component {
  static propTypes = {
    activePages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        key: PropTypes.string.isRequired
      })
    ),
    pages: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        key: PropTypes.string.isRequired
      })
    ),
    sortActivePages: PropTypes.func.isRequired,
    sortActivePageChildren: PropTypes.func.isRequired
  };

  state = {
    draggable: true
  };

  constructor(props) {
    super(props);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
    this.enableDrag = this.enableDrag.bind(this);
    this.disableDrag = this.disableDrag.bind(this);
  }

  enableDrag() {
    this.setState({ draggable: true });
  }

  disableDrag() {
    this.setState({ draggable: false });
  }

  onDragStart() {
    addBodyClass("cursor-move");
  }

  onDragEnd(result) {
    removeBodyClass("cursor-move");

    if (!result.destination) return;

    const { pages, sortActivePages, sortActivePageChildren } = this.props;
    const type = result.type;
    const parent = pages.find((page) => page.key === type);
    const children = (parent && parent.children) || [];
    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    !children.length
      ? sortActivePages(startIndex, endIndex)
      : sortActivePageChildren(type, startIndex, endIndex);
  }

  render() {
    const { pages } = this.props;
    const { draggable } = this.state;

    return (
      <DragDropContext
        onDragStart={this.onDragStart}
        onDragEnd={this.onDragEnd}>
        <CustomizeActivePagesDroppable
          pages={pages}
          type="pages"
          draggable={draggable}
          enableDrag={this.enableDrag}
          disableDrag={this.disableDrag}
        />
      </DragDropContext>
    );
  }
}

const mapStateToProps = (state) => ({
  report: state.report
});

const mapDispatchToProps = (dispatch) => ({
  sortActivePages: (startIndex, endIndex) =>
    dispatch(sortActivePages(startIndex, endIndex)),
  sortActivePageChildren: (type, startIndex, endIndex) =>
    dispatch(sortActivePageChildren(type, startIndex, endIndex))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeActivePages);
