import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "@wrstudios/utils";
import ListingPriceEdit from "./ListingPriceEdit";

function ListingPricing({ price, priceRaw, isSubjectProperty }) {
  return (
    <>
      {!isSubjectProperty && (
        <h4
          className={`font-normal text-sbase ${
            priceRaw ? "text-grey-darker" : "text-grey-dark"
          }`}>
          {!priceRaw && <span>No Price</span>}
          {!!priceRaw && (
            <span
              className="inline-flex items-center"
              title={formatCurrency(priceRaw)}>
              {formatCurrency(priceRaw)}
            </span>
          )}
        </h4>
      )}
      {isSubjectProperty && <ListingPriceEdit price={price} />}
    </>
  );
}

ListingPricing.defaultProps = {
  adjustments: []
};

ListingPricing.propTypes = {
  price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  priceRaw: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSubjectProperty: PropTypes.bool.isRequired
};

export default ListingPricing;
