import React from "react";

function IllustrationScale() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="-10 -10 161 95">
      <g>
        <path
          className="shadow"
          d="M.8 76.9c0-3.7 31.2-6.5 69.7-6.2 38.5.3 69.7 3.3 69.6 7-.1 3.7-31.2 6.5-69.7 6.2-19.2-.1-36.7-.9-49.3-2.2-6-.6-10.9-1.3-14.4-2.1C3 78.8.8 77.8.8 76.9z"
          fill="#E1E5E9"
        />
        <g className="animated">
          <path
            className="stroke-primary house"
            d="M120.2 1.2l-17.7 10.5v24.5h14V25.3c0-1.1.9-2 2-2h3.4c1.1 0 2 .9 2 2v10.9h14V11.7L120.2 1.2z"
            fill="none"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinejoin="round"
          />
          <path
            className="stroke-secondary platform"
            d="M1.3 41.4h138.3"
            strokeWidth="2.5"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            className="stroke-primary coin"
            d="M18.7 37c9.7 0 17.5-7.8 17.5-17.5S28.4 2 18.7 2C9.1 2 1.3 9.8 1.2 19.5 1.3 29.2 9.1 37 18.7 37z"
            fill="none"
            strokeWidth="2.5"
            strokeMiterlimit="10"
          />
          <path
            className="fill-primary currency"
            d="M18.9 24.1c-1.6 0-3.3-.9-3.9-2.1l-2.6 1.2c.8 2 3 3.3 5.3 3.6v1.9h2.7v-1.9c3.1-.3 4.7-1.9 4.7-4.4 0-3.5-3.2-4.3-6.1-4.6-1.5-.1-2.9-.4-2.9-1.6 0-1.2 1.4-1.7 2.8-1.7 1.5 0 2.8.8 3.2 1.6l2.4-1.1c-.9-1.9-2.4-2.6-4.2-2.8v-1.6h-2.6V12c-2.4.2-4.7 1.5-4.7 4.1 0 3.5 2.8 4 5.7 4.3 1.7.1 3.3.6 3.3 1.9 0 1.5-1.3 1.8-3.1 1.8z"
          />
        </g>
        <path
          d="M75.9 64.9c-1.6-6.9-4.4-18.6-5.2-22-.1-.2-.4-.2-.4 0-.8 3.4-3.5 15-5.1 22-1.9 8.1-3.5 11.4-3.5 11.4h17.8s-1.7-3.3-3.6-11.4z"
          fill="#fff"
          className="triangle stroke-secondary"
          strokeWidth="2.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  );
}

export default IllustrationScale;
