import styled from "styled-components";

export const Container = styled.div`
  min-height: 100%;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  position: relative;

  .react-pdf__Document {
    background-color: ${({ loading, error }) =>
      !loading && !error && "#525659"};
  }

  .annotationLayer {
    display: none;
  }
`;

export const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
`;

export const Error = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;
