/**
 * Strips html and replaces <br> tags with new lines
 *
 * @param {HTMLElement} html
 */
export function stripHtml(html) {
  const temp = document.createElement("div");
  temp.innerHTML = html.replace(/<br[^>]*>/gi, "\n");
  return temp.textContent;
}

/**
 * Hides the characters of the email except the first and last character
 *
 * @param {String} email
 */
export function getHiddenEmail(email) {
  const [local, domain] = email.split("@");
  const middle = local.slice(1, -1);
  const newLocal = `${local[0] || ""}${"*".repeat(middle.length)}${
    local[local.length - 1] || ""
  }`;
  return [newLocal, domain].filter(Boolean).join("@");
}
