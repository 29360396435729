import { format, parseISO, formatDistance } from "date-fns";
import { formatCurrency } from "@wrstudios/utils";
export {
  formatNumber,
  formatCurrency,
  formatCurrencyShort
} from "@wrstudios/utils";

export function formatDate(date, dateFormat = "yyyy-MM-dd") {
  return format(parseISO(date), dateFormat);
}

export function formatTimeAgo(date) {
  return formatDistance(parseISO(date), new Date());
}

export function formatCurrentInputValue(value) {
  let formattedValue = formatCurrency(value, "$0[,][.]0[0]0");
  if (formattedValue === "$0" || formattedValue === "$") formattedValue = "";
  if (value === "-") formattedValue = "-";
  if (String(value).endsWith(".")) formattedValue = value;
  return formattedValue || "";
}
