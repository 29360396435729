import { IconWarning } from "@wrstudios/icons";
import PropTypes from "prop-types";
import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import { updateHeadline } from "../../actions/customize";
import Loading from "../common/Loading";
import IconErrorOutline from "../icons/material/alert/IconErrorOutline";
import { Input, Warning } from "./styled/customize-page-edit-headline";

class CustomizePageEditHeadline extends Component {
  ref = createRef();

  state = {
    editing: false,
    headline: this.props.headline || "Comparative Market Analysis"
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentDidUpdate(_, prevState) {
    if (this.ref.current && !prevState.editing && this.state.editing) {
      this.ref.current.select();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  onSave = async () => {
    try {
      await this.props.updateHeadline(this.props.reportId, this.state.headline);

      this.props.onSave();

      if (this._isMounted) {
        this.setState({ editing: false });
      }
    } catch (error) {
      // Redux will handle the error dispatch
    }
  };

  onEdit = () => {
    this.setState({ editing: true });
    this.props.onEdit();
  };

  onKeyDown = (e) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      this.onSave();
    }
  };

  render() {
    const { fetching, error = {} } = this.props.page.request;
    const { headline, editing } = this.state;
    const hasError = !!Object.keys(error).length;

    return (
      <>
        <div className="flex items-center">
          {fetching && <Loading />}
          {!editing && (
            <>
              <i className="leading-2xl" title="This is your custom headline.">
                {headline}
              </i>
              <span className="flex items-center ml-auto">
                {this.props.isEmpty && (
                  <Warning>
                    <IconWarning />
                    <span>Empty</span>
                  </Warning>
                )}
                <button
                  className="text-sm leading-2xl text-green ml-auto lg:ml-4"
                  type="button"
                  onClick={this.onEdit}>
                  Edit
                </button>
              </span>
            </>
          )}
          {editing && (
            <>
              <Input
                ref={this.ref}
                value={headline}
                onChange={(e) => this.setState({ headline: e.target.value })}
                onKeyDown={this.onKeyDown}
              />
              <button
                className={`bg-transparent text-sm leading-2xl text-green ml-auto lg:ml-4 ${
                  fetching ? "cursor-default" : ""
                }`}
                type="button"
                disabled={fetching}
                onClick={this.onSave}>
                Save
              </button>
            </>
          )}
        </div>
        {!fetching && hasError && (
          <div className="flex items-center mt-4 text-red text-sm">
            <span className="mr-4">
              <IconErrorOutline />
            </span>
            <p>{error.message}</p>
          </div>
        )}
      </>
    );
  }
}

CustomizePageEditHeadline.defaultProps = {
  isEmpty: false
};

CustomizePageEditHeadline.propTypes = {
  isEmpty: PropTypes.bool,
  reportId: PropTypes.number,
  page: PropTypes.shape({
    key: PropTypes.string,
    parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    request: PropTypes.shape({
      fetching: PropTypes.bool,
      error: PropTypes.shape({
        message: PropTypes.string
      })
    })
  })
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  headline: state.customize.headline.current
});

const mapDispatchToProps = {
  updateHeadline
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizePageEditHeadline);
