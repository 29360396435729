import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { Container as TabList } from "../../tabs/styled/tab-list";
import { Container as TabPanel } from "../../tabs/styled/tab-panel";
import { Tabs as BaseTabs } from "../../tabs";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 60rem;
  padding-bottom: 0;

  ${TabList} {
    margin-bottom: 2rem;
  }

  ${TabPanel} {
    padding: 0 2rem 0 0;
    overflow-x: hidden;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.media.sm`
    height: calc(100vh - 7rem);
  `}
`;

export const Tabs = styled(BaseTabs)`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  margin-right: -2rem;
  overflow: hidden;
`;

export const Text = styled.p`
  line-height: 2.4rem;
  margin-bottom: 2rem;
`;

export const Templates = styled.div`
  height: 36rem;
  flex-grow: 1;
  margin: 0 -2rem;
  padding: 0 2rem 2rem;

  ${({ theme }) => theme.media.sm`
    height: auto;
  `}
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
`;

export const Cell = styled.div`
  width: calc(100% / 3);
  padding: 1rem;

  ${({ theme }) => theme.media.sm`
    width: 50%;
  `}

  ${({ theme }) => theme.media.xs`
    width: 100%;
  `}
`;
