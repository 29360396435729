import { IconCompare, IconMap, IconStats } from "@wrstudios/icons";
import React from "react";
import { Link, useParams } from "react-router-dom";
import IconCloudCMA from "../../icons/logos/horizontal/IconCloudCMA";
import IconCloudLogo from "../../icons/logos/IconCloudLogo";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import {
  CompanyLogo,
  Container,
  Control,
  ControlCell,
  ControlGrid,
  ControlsContainer,
  ControlText,
  Grid,
  Logo,
  LogoContainer,
  LogoIcon,
  MobileLogo,
  MobileTitle,
  NonMobileLogo,
  Title,
  TitleContainer
} from "./styled/homebeat-viewer-navigation";

function HomebeatViewerNavigation({ children, headerRef, mobileHeaderRef }) {
  const { guid } = useParams();
  const { agent } = useHomebeatViewer();

  return (
    <>
      <Container ref={headerRef} className="header">
        <Grid>
          <LogoContainer>
            <Logo>
              <Link to={`/homebeat/${guid}`} className="logo">
                {!agent.companyLogo && (
                  <LogoIcon>
                    <MobileLogo>
                      <IconCloudLogo />
                    </MobileLogo>
                    <NonMobileLogo>
                      <IconCloudCMA />
                    </NonMobileLogo>
                  </LogoIcon>
                )}
                {!!agent.companyLogo && <CompanyLogo src={agent.companyLogo} />}
              </Link>
            </Logo>
          </LogoContainer>
          <TitleContainer>
            <Title className="font-primary header-title">{children}</Title>
          </TitleContainer>
          <ControlsContainer>
            <ControlGrid>
              <ControlCell>
                <Control
                  to={`/homebeat/${guid}`}
                  exact
                  className="header-control">
                  <IconCompare />
                  <ControlText>Compare</ControlText>
                </Control>
              </ControlCell>
              <ControlCell>
                <Control
                  to={`/homebeat/${guid}/map`}
                  className="header-control">
                  <IconMap />
                  <ControlText>Map</ControlText>
                </Control>
              </ControlCell>
              <ControlCell>
                <Control
                  to={`/homebeat/${guid}/stats`}
                  className="header-control">
                  <IconStats />
                  <ControlText>Stats</ControlText>
                </Control>
              </ControlCell>
            </ControlGrid>
          </ControlsContainer>
        </Grid>
      </Container>
      {children && <MobileTitle ref={mobileHeaderRef}>{children}</MobileTitle>}
    </>
  );
}

export default HomebeatViewerNavigation;
