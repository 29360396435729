import axios from "axios";
import camelcaseKeys from "camelcase-keys";
import api from "./api";

export function addContactItem(userId, contact) {
  return api
    .post(`/users/${userId}/contact_items`, { contact_item: contact })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function removeContactItem(userId, contactId) {
  return api
    .delete(`/users/${userId}/contact_items/${contactId}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function reorderContactItems(userId, contacts) {
  const formData = contacts.reduce((formData, contact) => {
    formData.append("item[]", contact.id);
    return formData;
  }, new FormData());
  return api
    .post(`/users/${userId}/contact_items/sort`, formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateUser(userId, details) {
  return api
    .put(`/users/${userId}`, { user: details })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateUserWithFormData(userId, formData) {
  return api
    .put(`/users/${userId}`, formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateUserAvatar(userId, file, dimensions) {
  const formData = new FormData();
  formData.append("user[photo][file]", file);
  formData.append("user[photo][crop][width]", dimensions.width);
  formData.append("user[photo][crop][height]", dimensions.height);
  formData.append("user[photo][crop][x]", dimensions.x);
  formData.append("user[photo][crop][y]", dimensions.y);
  return api
    .put(`/users/${userId}`, formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function removeUserAvatar(userId) {
  return api
    .delete(`/users/${userId}/photo`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function removeUserCompanyLogo(userId) {
  return api
    .delete(`/users/${userId}/logo`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateUserMLS(userId, details) {
  return api
    .post(`/users/${userId}/mls_credential`, { mls_credential: details })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateUserAPI(userId, details) {
  return api
    .put(`/users/${userId}/api`, { api: details })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function addCustomPage(userId, details) {
  return api
    .post(`/users/${userId}/page_contents`, details)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function addCustomPageImage(file) {
  const formData = new FormData();
  formData.append("image[image]", file);
  return api
    .post("/attachments/create", formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function removeCustomPage(userId, customPageId) {
  return api
    .delete(`/users/${userId}/page_contents/${customPageId}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateCustomPage(userId, customPageId, content) {
  return api
    .put(`/users/${userId}/page_contents/${customPageId}`, {
      page_content: content
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function renameCustomPage(userId, customPageId, key) {
  return api
    .put(`/users/${userId}/page_contents/${customPageId}/rename`, { key })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function removeCustomPDFPage(userId, customPageId) {
  return api
    .delete(`/users/${userId}/custom_pages/${customPageId}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function uploadCustomPDF(userId, pdfFile) {
  const formData = new FormData();
  formData.append("custom_page[page]", pdfFile);
  return api
    .post(`/users/${userId}/custom_pages`, formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function optOutOfFeature(feature, optOut) {
  return api
    .put(`/user/feature/${feature}?opt_out=${optOut}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function getMLSInstructions(mlsCode) {
  return api
    .get("/mls_credential/instructions", { params: { code: mlsCode } })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function cancelAccount() {
  return api
    .delete("/account/cancel")
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function cancelOrder(orderId) {
  return api
    .delete(`/orders/${orderId}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateCreditCard(details) {
  return api
    .post("/account/billing", details)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function deleteSystemNotification(id) {
  return api
    .delete(`/system_notifications/${id}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function storeRedirectUrl(scope, url) {
  return axios.post(
    "/graphql",
    {
      query: /* GraphQL */ `
        mutation StoreRedirectUrl($scope: String!, $url: String!) {
          storeRedirectUrl(scope: $scope, url: $url) {
            success
          }
        }
      `,
      variables: { scope, url }
    },
    {
      headers: {
        Authorization: `Bearer ${window.sessionData.currentUser.casJwt}`
      }
    }
  );
}
