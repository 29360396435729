// ! DO NOT UPDATE THIS FILE
// ! THIS FILE IS ONLY USED AS A BACKWARDS COMPATABILITY MECHANISM
// ! IF YOU NEED TO MAKE A CHANGE, CONSIDER USING `@wrstudios/theme` INSTEAD

export const raised =
  "0 0 0.2rem 0 rgba(0, 0, 0, 0.2), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1)";
export const sticky = "0 0.2rem 0.4rem rgba(0, 0, 0, 0.1)";
export const overlay = "0 0.4rem 0.8rem rgba(0, 0, 0, 0.1)";
export const overlayReverse = "0 -0.4rem 0.8rem rgba(0, 0, 0, 0.1)";
export const modal = "0 1.2rem 2.4rem rgba(0, 0, 0, 0.1)";
