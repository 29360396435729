import { requestState, resolvedState, rejectedState } from "../../utils/redux";

export function request(state, action) {
  const { reportId } = action.payload;
  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      return requestState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function resolved(state, action) {
  const { reportId } = action.payload;

  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      report.investor_offer = true;
      report.investor_offer_accepted = true;
      report.investor_offer_show_video = false;

      return resolvedState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function rejected(state, action) {
  const { reportId } = action.payload;
  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      return rejectedState(report, action.payload.error);
    }

    return report;
  });

  return { ...state, all: reports };
}
