import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { copyNetSheet } from "../../actions/subject-property";

class NetSheetOfferCopy extends Component {
  state = {
    close: false
  };

  render() {
    let {
      subjectProperty: {
        request: { fetching, error = {} },
        filter
      }
    } = this.props;
    const hasError = error && !!Object.keys(error).length;

    if (filter !== "net-sheet") {
      fetching = false;
    }

    return !this.state.close ? (
      <div className="mt-8">
        <div className="flex items-center">
          <div className="ml-auto mr-4">
            {fetching && <p className="text-sm">Copying net sheet...</p>}
            {!fetching && (
              <p className="text-sm">
                Would you like to copy your last Net Sheet?
              </p>
            )}
          </div>
          <div className="flex items-center">
            <button
              className="button button-cma button-secondary button-small mr-4"
              type="button"
              disabled={fetching}
              onClick={this.handleOnCancel}>
              No
            </button>
            <button
              className="button button-cma button-small"
              type="button"
              disabled={fetching}
              onClick={this.handleOnConfirm}>
              Yes
            </button>
          </div>
        </div>
        {hasError && filter === "net-sheet" && (
          <div className="mt-4 text-right">
            <p className="text-sm text-red">{error.message}</p>
          </div>
        )}
      </div>
    ) : null;
  }

  handleOnCancel = () => {
    const {
      subjectProperty: { id },
      copyNetSheet
    } = this.props;

    this.setState({ close: true }, () => copyNetSheet(id, false));
  };

  handleOnConfirm = () => {
    const {
      subjectProperty: { id },
      copyNetSheet
    } = this.props;

    copyNetSheet(id, true);
  };
}

NetSheetOfferCopy.propTypes = {
  subjectProperty: PropTypes.shape({
    id: PropTypes.number.isRequired,
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired,
      error: PropTypes.shape({
        message: PropTypes.string
      })
    }).isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  subjectProperty: state.subjectProperty
});

const mapDispatchToProps = {
  copyNetSheet
};

export default connect(mapStateToProps, mapDispatchToProps)(NetSheetOfferCopy);
