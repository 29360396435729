import React from "react";
import parser from "parse-address";
import { Modal } from "@wrstudios/components";
import { IconSend } from "@wrstudios/icons";
import Button from "../../common/Button";
import { useHomebeatEditor } from "./HomebeatEditorProvider";
import {
  ModalContent,
  ModalIcon,
  ModalHeader,
  ModalContact,
  ModalFinish
} from "./styled/homebeat-editor-sent-modal";

function HomebeatEditorSentModal() {
  const { homebeats, currentIndex, name, email, address, skip, finish } =
    useHomebeatEditor();
  const parsedAddress = parser.parseLocation(address) || {
    number: "",
    street: "",
    type: ""
  };

  return (
    <Modal>
      <ModalContent>
        <ModalIcon>
          <IconSend />
        </ModalIcon>
        <ModalHeader>Your Homebeat has sent!</ModalHeader>
        <ModalContact>
          <strong>{name}</strong>
          <div>{email}</div>
          <div>
            {[parsedAddress.number, parsedAddress.street, parsedAddress.type]
              .filter(Boolean)
              .join(" ")}
          </div>
        </ModalContact>
        {homebeats.length > 1 && currentIndex !== homebeats.length - 1 && (
          <Button app="cma" onClick={skip}>
            Go to next person
          </Button>
        )}
        {homebeats.length > 1 && (
          <ModalFinish app="cma" variant="secondary" onClick={finish}>
            I'm finished for now
          </ModalFinish>
        )}
        {homebeats.length <= 1 && (
          <Button app="cma" onClick={finish}>
            Done
          </Button>
        )}
      </ModalContent>
    </Modal>
  );
}

export default HomebeatEditorSentModal;
