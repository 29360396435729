import styled from "styled-components";

export const Section = styled.div`
  margin: 6rem 0 2rem;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.3rem;
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  text-align: center;
`;

export const Text = styled.p`
  font-size: 1.3rem;
  line-height: 2rem;
`;
