import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return requestState(state);
}

export function resolved(state, action) {
  const { startIndex, endIndex } = action.payload;
  const listings = [...state.all];
  const [removed] = listings.splice(startIndex, 1);

  listings.splice(endIndex, 0, removed);
  listings.forEach((listing, index) => (listing.sortPosition = index));

  return resolvedState({ ...state, all: listings });
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
