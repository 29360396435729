import { createStore, compose, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducers";

const composeEnhancers =
  ((process.env.NODE_ENV !== "production" ||
    window.location.search.includes("debug")) &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
  compose;

export function createDynamicStore() {
  let store = {};

  document
    .querySelectorAll("[data-react-element-redux-props]")
    .forEach((element) => {
      const reduxProps = JSON.parse(
        element.dataset.reactElementReduxProps || "{}"
      );

      // Check if the property is already in the store state. If it is,
      // merge into that property, if not, create a new property.
      let pushFullState = true;
      Object.keys(reduxProps).forEach((key) => {
        if (store.hasOwnProperty(key)) {
          store[key] = {
            ...store[key],
            ...reduxProps[key]
          };
          pushFullState = false;
        }
      });

      if (pushFullState) {
        store = { ...store, ...reduxProps };
      }

      return store;
    });

  return createStore(
    rootReducer,
    store,
    composeEnhancers(applyMiddleware(thunk))
  );
}
