import { clamp } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useQueryString } from "../../utils/url";
import { Cell, Children, Container, Next, Prev, View } from "./styled/paginate";

export default function Paginate({
  scrollToTopOnPageChange,
  currentPage,
  totalPages,
  perPage,
  totalResults,
  onPageChange,
  children,
  ...props
}) {
  const { updateSearchQuery } = useQueryString();

  useEffect(() => {
    if (currentPage > totalPages) {
      if (scrollToTopOnPageChange) window.scrollTo({ top: 0, left: 0 });
      const newPage =
        currentPage > totalPages
          ? totalPages
          : currentPage - 1 > 1
          ? currentPage - 1
          : 1;
      updateSearchQuery({ page: newPage > 1 ? newPage : null });
      onPageChange(newPage);
    }
  }, [
    currentPage,
    totalPages,
    scrollToTopOnPageChange,
    updateSearchQuery,
    onPageChange
  ]);

  if (totalPages <= 1) return null;

  const handlePageClick = (newPage) => {
    if (newPage === currentPage) return;
    if (scrollToTopOnPageChange) window.scrollTo({ top: 0, left: 0 });
    updateSearchQuery({ page: newPage !== 1 ? newPage : null });
    onPageChange(newPage);
  };

  return (
    <Container {...props}>
      <Cell>
        <View>
          Showing{" "}
          <strong>
            {(currentPage - 1) * perPage + 1} -{" "}
            {clamp(currentPage * perPage, 1, totalResults)}
          </strong>{" "}
          of <strong>{totalResults}</strong> results
        </View>
      </Cell>
      <Cell>
        <Children>{children}</Children>
      </Cell>
      <Cell>
        <Prev
          disabled={currentPage <= 1}
          onClick={() => handlePageClick(currentPage - 1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M12.53 5.487a.75.75 0 0 1 0 1.06l-3.47 3.47 3.47 3.47a.75.75 0 0 1-1.06 1.06l-4-4a.75.75 0 0 1 0-1.06l4-4a.75.75 0 0 1 1.06 0Z"
            />
          </svg>
        </Prev>
        <Next
          disabled={currentPage >= totalPages}
          onClick={() => handlePageClick(currentPage + 1)}>
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 21">
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.47 14.547a.75.75 0 0 1 0-1.06l3.47-3.47-3.47-3.47a.75.75 0 0 1 1.06-1.06l4 4a.75.75 0 0 1 0 1.06l-4 4a.75.75 0 0 1-1.06 0Z"
            />
          </svg>
        </Next>
      </Cell>
    </Container>
  );
}

Paginate.defaultProps = {
  scrollToTopOnPageChange: true,
  currentPage: 1,
  totalPages: 1,
  perPage: 1,
  totalResults: 1,
  onPageChange: () => {}
};

Paginate.propTypes = {
  scrollToTopOnPageChange: PropTypes.bool,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  perPage: PropTypes.number,
  totalResults: PropTypes.number,
  onPageChange: PropTypes.func
};
