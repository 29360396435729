import { pluralize } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React from "react";
import { useSession } from "../../Session";
import CreatedWith from "../common/CreatedWith";
import Image from "../common/Image";
import ReportCard from "../common/ReportCard";
import IconBuyerTour from "../icons/report/IconBuyerTour";
import { DELETE_TOUR } from "./queries";

function TourCard({
  id,
  guid,
  title,
  type,
  status,
  pdfLink,
  notes,
  thumbnail,
  addresses,
  cities,
  createdWith,
  viewCount,
  lastViewedAt,
  updatedAt,
  refetchQuery
}) {
  const { currentUser, features } = useSession();
  const address = !addresses.length
    ? "No addresses available"
    : pluralize("Properties", addresses.length, true);
  const cityStateZip = !cities.length
    ? "No cities Available"
    : cities.join(", ");

  return (
    <ReportCard
      id={id}
      guid={guid}
      title={title}
      type={type}
      typeLabel="Buyer Tour"
      status={status}
      pdfLink={pdfLink}
      address={address}
      cityStateZip={cityStateZip}
      notes={<CreatedWith host={createdWith} notes={notes} />}
      viewCount={viewCount}
      lastViewedAt={lastViewedAt}
      updatedAt={updatedAt}
      image={
        thumbnail ? <Image src={thumbnail} alt={title} /> : <IconBuyerTour />
      }
      liveUrl={`/tours/${id}`}
      isLiveEnabled={currentUser.liveToursEnabled}
      isSlideshowEnabled={features.hasPresent}
      deleteQuery={DELETE_TOUR}
      refetchQuery={refetchQuery}
    />
  );
}

TourCard.propTypes = {
  id: PropTypes.number.isRequired,
  guid: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
  notes: PropTypes.string,
  thumbnail: PropTypes.string,
  addresses: PropTypes.array.isRequired,
  cities: PropTypes.array.isRequired,
  createdWith: PropTypes.string,
  viewCount: PropTypes.number,
  lastViewedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  refetchQuery: PropTypes.object
};

export default TourCard;
