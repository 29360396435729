import React from "react";
import PropTypes from "prop-types";
import IconCalendar from "../icons/material/action/IconCalendar";
import {
  Container,
  DatePicker as StyledDatePicker
} from "./styled/date-picker";

const config = {
  offset: { enabled: true, offset: "0, 15px" }
};

function DatePicker({ isFullWidth, value, ...props }) {
  return (
    <Container>
      <StyledDatePicker
        {...props}
        isFullWidth={isFullWidth}
        selected={value}
        popperModifiers={config}
      />
      <IconCalendar />
    </Container>
  );
}

DatePicker.defaultProps = {
  isFullWidth: true
};

DatePicker.propTypes = {
  isFullWidth: PropTypes.bool
};

export default DatePicker;
