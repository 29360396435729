import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { useQueryString } from "../../utils/url";
import Wrapper from "../common/Wrapper";
import Search from "../common/Search";
import CardCreate from "../common/CardCreate";
import { ReportCardSkeleton } from "../common/ReportCard";
import Notifications from "../common/Notifications";
import { GET_DOCUMENTS } from "./queries";
import DocumentCard from "./DocumentCard";
import {
  Container,
  Header,
  Grid,
  Cell,
  Title,
  Paginate
} from "./styled/documents";

function Documents() {
  const { params } = useQueryString();
  const [search, setSearch] = useState(params.q || "");
  const [currentPage, setCurrentPage] = useState(params.page || 1);
  const [getDocuments, { data, loading }] = useLazyQuery(GET_DOCUMENTS, {
    fetchPolicy: "cache-and-network"
  });
  const meta = get(data, "documents.meta", {});
  const documents = get(data, "documents.results", []);

  useEffect(() => {
    getDocuments({ variables: { page: Number(currentPage || 1), search } });
  }, [currentPage, search]);

  return (
    <>
      <Notifications />
      <Wrapper>
        <Container>
          <Header>
            <Title>Documents</Title>
            <Search
              defaultValue={search}
              placeholder="Search Documents"
              onSearch={(searchTerm) => {
                setSearch(searchTerm);
                setCurrentPage(1);
              }}
              onClear={() => {
                setSearch("");
                setCurrentPage(1);
              }}
            />
          </Header>
          <Grid>
            <Cell>
              <CardCreate as="a" href="/documents/new">
                Create DocumentCard
              </CardCreate>
            </Cell>
            {loading &&
              new Array(5).fill().map((_, index) => (
                <Cell key={index}>
                  <ReportCardSkeleton />
                </Cell>
              ))}
            {!loading &&
              documents.map((document) => (
                <Cell key={document.id}>
                  <DocumentCard
                    id={document.id}
                    guid={document.guid}
                    title={document.title}
                    type={document.type}
                    status={document.status}
                    pdfLink={document.pdfLink}
                    notes={document.notes}
                    thumbnail={document.thumbnail}
                    createdWith={document.createdWith}
                    address={document.addressParts[0]}
                    cityStateZip={document.addressParts[1]}
                    viewCount={document.eventSummary.viewCount}
                    lastViewedAt={document.eventSummary.lastViewedAt}
                    updatedAt={document.updatedAt}
                    refetchQuery={GET_DOCUMENTS}
                  />
                </Cell>
              ))}
          </Grid>
          {!loading && (
            <Paginate
              scrollToTopOnPageChange
              currentPage={meta.currentPage}
              totalPages={meta.totalPages}
              perPage={meta.perPage}
              totalResults={meta.totalResults}
              onPageChange={setCurrentPage}
            />
          )}
        </Container>
      </Wrapper>
    </>
  );
}

export default Documents;
