import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import OrderForm from "../order/OrderForm";
import IconPowerPack from "../icons/IconPowerPack";
import CustomizeModalPowerPackCongratulations from "./CustomizeModalPowerPackCongratulations";
import {
  CheckoutModalContent,
  CheckoutModalClose
} from "./styled/customize-modal-kit";
import { Title } from "./styled/customize-modal-power-pack-checkout";

function CustomizeModalPowerPack({ sku, amount, onCheckout, onClose }) {
  const [showCongrats, setShowCongrats] = useState(false);
  const [triggerCheckout, setTriggerCheckout] = useState(false);
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      onClose={() => {
        onClose();
        if (showCongrats && triggerCheckout) onCheckout();
      }}>
      <CheckoutModalContent>
        <CheckoutModalClose />
        <Modal.Body>
          <Title>
            <IconPowerPack />
          </Title>
          {!showCongrats && (
            <OrderForm
              sku={sku}
              amount={amount}
              title="Power Pack"
              onCheckout={() => {
                if (isMounted.current) {
                  setShowCongrats(true);
                  setTriggerCheckout(true);
                }
              }}
            />
          )}
          {showCongrats && (
            <CustomizeModalPowerPackCongratulations
              onClick={() => {
                setTriggerCheckout(false);
                onCheckout();
              }}
            />
          )}
        </Modal.Body>
      </CheckoutModalContent>
    </Modal>
  );
}

CustomizeModalPowerPack.propTypes = {
  sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func,
  onClose: PropTypes.func
};

export default CustomizeModalPowerPack;
