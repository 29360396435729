import React from "react";
import PropTypes from "prop-types";
import { getCSRFToken } from "../../utils/page";

const CSRF_TOKEN = getCSRFToken();

function getFormMethod(method) {
  switch (method.toLowerCase()) {
    case "put":
    case "delete":
    case "post":
    case "patch":
      return "post";

    default:
      return "get";
  }
}

function DropdownList({ items, close }) {
  return (
    <ul className="dropdown-list">
      {items.map(
        (
          {
            text,
            url,
            disabled,
            danger,
            form,
            title,
            target = "_self",
            turbolinks = true,
            closeOnClick = true,
            onClick = function () {}
          },
          index
        ) => (
          <li
            key={index}
            className={`dropdown-item ${
              disabled ? "dropdown-item-disabled" : "dropdown-item-enabled"
            } ${danger ? "dropdown-item-danger" : ""}`}>
            {!form && (
              <a
                className={`dropdown-link ${
                  danger ? "dropdown-link-danger" : ""
                }`}
                href={url}
                target={url && target !== "_self" ? target : undefined}
                data-turbolinks={turbolinks}
                onClick={(e) => {
                  if (disabled) return;

                  closeOnClick && close();
                  onClick(e, { text, url });
                }}
                rel={url ? "" : target && target === "_blank" ? "noopener" : ""}
                aria-label={`Link: ${text}`}
                tabIndex={disabled ? -1 : 0}
                disabled={disabled}
                title={title || text}>
                <span className="dropdown-text" aria-hidden>
                  {text}
                </span>
              </a>
            )}
            {form && (
              <form action={form.action} method={getFormMethod(form.method)}>
                <input type="hidden" name="_method" value={form.method} />
                <input
                  type="hidden"
                  name="authenticity_token"
                  value={CSRF_TOKEN}
                />
                {Object.entries(form.data).map(([key, val], index) => (
                  <input type="hidden" name={key} value={val} key={index} />
                ))}
                <button
                  className={`dropdown-link ${
                    danger ? "dropdown-link-danger" : ""
                  }`}
                  disabled={disabled}
                  title={title || text}>
                  <span className="dropdown-text" aria-hidden>
                    {text}
                  </span>
                </button>
              </form>
            )}
          </li>
        )
      )}
    </ul>
  );
}

DropdownList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      url: PropTypes.string,
      danger: PropTypes.bool,
      form: PropTypes.object,
      disabled: PropTypes.bool,
      target: PropTypes.string,
      turbolinks: PropTypes.bool,
      onClick: PropTypes.func
    })
  ).isRequired,
  close: PropTypes.func.isRequired
};

export default DropdownList;
