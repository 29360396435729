import React, { useCallback } from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Papa from "papaparse";
import { parseVCard } from "./utils";
import { useCSV } from "./CSVProvider";

function parseVCards(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const cards = e.target.result
        .split(/BEGIN:VCARD([\s\S]*?)END:VCARD/g)
        .filter((item) => item.trim());
      const parsedCards = cards.map(parseVCard);
      resolve(parsedCards);
    };
    reader.onerror = (err) => {
      reject(err);
    };
    reader.readAsText(file);
  });
}

function CSVDropArea({ onDrop, children, dragChildren, ...props }) {
  const { setColumns, setData } = useCSV();

  const handleDrop = useCallback(
    ([file]) => {
      if (file.name.endsWith(".vcf")) {
        parseVCards(file).then((cards) => {
          onDrop({
            data: cards,
            isCSV: false,
            isVCard: true,
            filename: file.name
          });
        });
      } else {
        Papa.parse(file, {
          header: true,
          complete: ({ data }) => {
            const columns = Object.values(
              data.reduce(
                (columns, row) => ({
                  ...columns,
                  ...Object.keys(row)
                    .map((key) => key.trim())
                    .filter(Boolean)
                }),
                {}
              )
            );
            setColumns(columns);
            setData(data);
            onDrop({ data, isCSV: true, isVCard: false, filename: file.name });
          }
        });
      }
    },
    [setColumns, setData]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: handleDrop
  });

  return (
    <div
      {...props}
      {...getRootProps()}
      style={{
        cursor: props.disabled ? "not-allowed" : "pointer"
      }}>
      <input
        {...getInputProps({ multiple: false, accept: ".csv,.vcf" })}
        disabled={props.disabled}
      />
      {isDragActive ? dragChildren || children : children}
    </div>
  );
}

CSVDropArea.defaultProps = {
  onDrop: () => {},
  disabled: false
};

CSVDropArea.propTypes = {
  onDrop: PropTypes.func,
  disabled: PropTypes.bool
};

export default CSVDropArea;
