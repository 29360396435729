import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, customPages: requestState(state.customPages) };
}

export function resolved(state, action) {
  let selected = action.payload;
  let inactivePages = [];
  let activePages = [];
  const hasInactivePages = state.inactive && state.inactive.pages;
  const hasActivePages = state.active && state.active.pages;

  if (hasInactivePages) {
    inactivePages = [...state.inactive.pages];
  }

  if (hasActivePages) {
    activePages = [...state.active.pages];
  }

  const page = [...inactivePages, ...activePages].find(
    (page) => page.key === selected.key
  );

  selected.value = selected.value || "";

  if (page) {
    selected = { ...page, ...selected };
  }

  return {
    ...state,
    customPages: resolvedState({ ...state.customPages, selected })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    customPages: rejectedState(state.customPages, action.payload.error)
  };
}
