import styled from "styled-components";
import BaseInput from "../../common/Input";

export const Input = styled(BaseInput)`
  padding: 0;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom-style: dashed;
  border-radius: 0;
`;

export const Warning = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  margin: 0 0.2rem 0 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.yellow[700]};

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.styleGuide.colors.yellow[600]};
  }

  ${({ theme }) => theme.media.lg`
    margin: 0 1.2rem;
  `}
`;
