import React, { useState } from "react";
import { updatePreference } from "../../api/unsubscribe";
import Wrapper from "../common/Wrapper";
import Switch from "../common/Switch";
import {
  Container,
  Title,
  Box,
  Header,
  Grid,
  Cell,
  Text
} from "./styled/user-unsubscribe";

function UserUnsubscribe({ feature, title, ...props }) {
  const [optOut, setOptOut] = useState(props.optOut);

  const handleChange = (isOn) => {
    setOptOut(!isOn);
    updatePreference(feature, !isOn);
  };

  return (
    <Wrapper>
      <Container>
        <Title>Your Subscription</Title>
        <Box>
          <Header>Subscription settings</Header>
          <Grid>
            <Cell>
              <Text>{title}</Text>
            </Cell>
            <Cell>
              <Switch isOn={!optOut} onChange={handleChange} />
            </Cell>
          </Grid>
        </Box>
      </Container>
    </Wrapper>
  );
}

export default UserUnsubscribe;
