import React from "react";

function IconAutoPilot(props) {
  return (
    <svg viewBox="0 0 96 77.81" {...props}>
      <defs>
        <style>{".Auto-pilot_svg__cls-2{fill:#c4c8d0}"}</style>
      </defs>
      <g id="Auto-pilot_svg__Layer_1" data-name="Layer 1">
        <path
          d="M82.19 5.2L68 5.18v1.93a1 1 0 0 1-1 1 1 1 0 0 1-1-1V5.18H22v2a1 1 0 0 1-1 1 1 1 0 0 1-1-1v-2H5.84A3.69 3.69 0 0 0 2 8.76v2.47l83.81.1V8.85a3.66 3.66 0 0 0-3.62-3.65z"
          fill="none"
        />
        <path
          className="Auto-pilot_svg__cls-2"
          d="M82.19 3.18L68 3.16V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v2.16l-44-.05V1a1 1 0 0 0-1-1 1 1 0 0 0-1 1v2.1H5.85A5.7 5.7 0 0 0 0 8.75v53.49a5.68 5.68 0 0 0 5.66 5.68l56.5.06a1 1 0 0 0 0-2l-56.5-.08A3.66 3.66 0 0 1 2 62.24v-49l83.81.1v31.42a1 1 0 0 0 1 1 1 1 0 0 0 1-1V8.84a5.67 5.67 0 0 0-5.62-5.66zM2 11.23V8.76a3.69 3.69 0 0 1 3.73-3.65h14.16v2a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-2h44v2a1 1 0 0 0 1 1 1 1 0 0 0 1-1V5.18h14.23a3.66 3.66 0 0 1 3.65 3.65v2.48z"
        />
        <path
          d="M80.88 47.53A15.14 15.14 0 1 0 96 62.69a15.15 15.15 0 0 0-15.12-15.16zM75.52 65a.24.24 0 0 1 0 .27l-1 1a.26.26 0 0 1-.4 0 7.61 7.61 0 0 1-1-3.74 7.49 7.49 0 0 1 7-7.54.44.44 0 0 0 .41-.43v-1.81a.26.26 0 0 1 .43-.18l3.27 3.35-3.27 3.34a.25.25 0 0 1-.43-.17v-1.87a.35.35 0 0 0-.36-.35A5.69 5.69 0 0 0 75.52 65zm6.13 5.34a.42.42 0 0 0-.42.42v1.84a.24.24 0 0 1-.42.17l-3.27-3.35 3.27-3.34a.24.24 0 0 1 .42.17v1.87a.35.35 0 0 0 .36.35 5.62 5.62 0 0 0 5.18-5.66 5.79 5.79 0 0 0-.57-2.49.23.23 0 0 1 0-.27l1-1a.24.24 0 0 1 .39 0 7.61 7.61 0 0 1 1 3.74 7.49 7.49 0 0 1-6.94 7.57z"
          fill="#fd5154"
        />
        <path
          d="M87.25 59l-1 1a.23.23 0 0 0 0 .27 5.79 5.79 0 0 1 .57 2.49 5.62 5.62 0 0 1-5.18 5.66.35.35 0 0 1-.36-.35v-1.82a.24.24 0 0 0-.42-.17l-3.27 3.34 3.27 3.35a.24.24 0 0 0 .42-.17v-1.82a.42.42 0 0 1 .42-.42 7.49 7.49 0 0 0 7-7.55 7.61 7.61 0 0 0-1-3.74.24.24 0 0 0-.45-.07zm-7.12-2.13a.35.35 0 0 1 .36.35v1.87a.25.25 0 0 0 .43.17l3.27-3.34-3.27-3.35a.26.26 0 0 0-.43.18v1.81a.44.44 0 0 1-.41.43 7.49 7.49 0 0 0-7 7.54 7.61 7.61 0 0 0 1 3.74.26.26 0 0 0 .4 0l1-1a.24.24 0 0 0 0-.27 5.69 5.69 0 0 1 4.61-8.15z"
          fill="#fff"
        />
        <rect
          x={12.48}
          y={21.23}
          width={6.93}
          height={6.93}
          rx={1.43}
          fill="#576474"
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={31.77}
          y={21.23}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={50.45}
          y={21.23}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={68.44}
          y={21.23}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={12.48}
          y={35.72}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={31.77}
          y={35.72}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={50.45}
          y={35.72}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={68.44}
          y={35.72}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={12.48}
          y={49.76}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={31.77}
          y={49.76}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
        <rect
          className="Auto-pilot_svg__cls-2"
          x={50.45}
          y={49.76}
          width={6.93}
          height={6.93}
          rx={1.43}
        />
      </g>
    </svg>
  );
}

export default IconAutoPilot;
