import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  margin: 3.3rem 0 0;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    margin: 1.2rem 0 3rem
  `}
`;

export const Text = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-left: 1rem;
  color: ${({ theme }) => theme.colors.grayDark};

  ${({ theme }) => theme.media.sm`
    margin: 0.8rem 0 0;
    text-align: center;
  `}
`;
