import PropTypes from "prop-types";
import React from "react";
import IconCheckmark from "../icons/IconCheckmark";
import { Box, Container, Input } from "./styled/checkbox";

function Checkbox({ variant, size, className, shadowSize, ...props }) {
  return (
    <Container className="checkbox-container">
      <Input
        {...props}
        type="checkbox"
        variant={variant}
        shadowSize={shadowSize}
      />
      <Box className={className} size={size}>
        <IconCheckmark />
      </Box>
    </Container>
  );
}

Checkbox.defaultProps = {
  variant: "primary",
  size: "md",
  shadowSize: 0.2
};

Checkbox.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "teal",
    "green",
    "yellow",
    "blue",
    "purple",
    "violet",
    "red",
    "live-primary",
    "live-secondary"
  ]),
  size: PropTypes.oneOf(["sm", "md"]),
  shadowSize: PropTypes.number
};

export default Checkbox;
