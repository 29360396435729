import { connect } from "react-redux";
import CustomizeControlsTemplate from "../CustomizeControlsTemplate";

function select({ customize }) {
  const error = customize.template.request.error || {};

  return {
    title: customize.template.selected.title || "",
    hasModified: customize.template.hasModified || false,
    isFetching: customize.template.request.fetching,
    hasError: !!Object.values(error).length,
    error: error.message || ""
  };
}

export default connect(select)(CustomizeControlsTemplate);
