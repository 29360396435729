import styled from "styled-components";

export const Container = styled.div`
  padding: 3.4rem;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  &:first-child {
    border-top: none;
  }
`;

export const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 16px;
  row-gap: 12px;

  ${({ theme }) => theme.media.xl`
    grid-template-columns: repeat(1, 1fr);
    column-gap: 16px;
    row-gap: 12px;
  `}
`;

export const Subheader = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

export const CardAction = styled.div`
  display: flex;
  align-items: center;
  background: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: 0.4rem;
  padding: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  cursor: pointer;
  font-size: 1.6rem;
  line-height: 2.2rem;
  font-weight: 600;
`;

export const CardActionIcon = styled.span`
  width: 1.6rem;
  margin-right: 1.6rem;
`;

export const Link = styled.a`
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  text-decoration: none;
`;
