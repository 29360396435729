import { gql } from "apollo-boost";

export const GET_REPORTS = gql`
  query GetReports {
    data @rest(type: "Data", path: "home") {
      reports @type(name: "Report") {
        id
        guid
        title
        type
        status
        notes
        cities: listings_cities
        addresses: listings_addresses
        thumbnail: thumbnail_url_with_timestamp
        createdWith: created_via
        pdfLink: pdf_permalink
        addressParts: report_address_parts
        updatedAt: updated_at
        investorOffer: investor_offer
        eventSummary: event_summary @type(name: "ReportEventSummary") {
          viewCount: view_count
          lastViewedAt: last_view_at
        }
        subjectProperty: subject_property @type(name: "SubjectProperty") {
          beds
          baths
          sqft
          garages
          lotSize: lot_size
        }
        transaction: external_transaction @type(name: "ExternalTransaction") {
          createdAt: created_at
          guid
          id
          reportId: report_id
          updatedAt: updated_at
          url
          type
        }
      }
      news @type(name: "News") {
        type
        image
        title
        link
        readMore: read_more
      }
    }
  }
`;
