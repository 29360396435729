import { IconAlert } from "@wrstudios/icons";
import React from "react";
import Button from "../common/Button";
import emailAlert from "./images/email_alert.png";
import justListedEmailAlert from "./images/just_listed_email_alert.png";
import textAlert from "./images/text_alert.png";
import {
  ButtonIcon,
  Container,
  ContentContainer,
  Header,
  Image,
  ImageContainer,
  ProTip,
  Subheader,
  Text
} from "./styled/publish-alerts";

function PublishAlerts({ report, streams }) {
  const variants = {
    0: {
      heading:
        "Got leads? Set them up with listing alerts that keep you top-of-mind.",
      message:
        "It's easy to keep them engaged with your own modern listing alerts that keep your name in front of them.",
      tip: "Search for listings that correspond closely to what they're interested in, then choose <i>Daily</i> or <i>Weekly</i> email alerts for them to stay engaged.",
      image: emailAlert
    },
    1: {
      heading:
        "Active buyers should be getting real-time text message listing alerts.",
      message:
        "Don't let their dream home slip away sitting in their email inbox. Make sure they are the first to see the home with the industry's fastest alert notifications.",
      tip: "Simply, search for the listings that match their criteria and choose <i>Real-time</i> alerts.",
      image: textAlert
    },
    2: {
      heading: "Never miss a comp that affects this home's selling potential.",
      message:
        "Setting up listing alerts around this home will keep you (and your seller) in the loop of how the market is evolving.",
      tip: "Set your search criteria to match the criteria you chose for this CMA. Then choose <i>Real-time</i> or <i>Daily</i> alerts so you stay on top of the market.",
      image: justListedEmailAlert
    }
  };
  const variantNumber = ["Property", "Tour"].includes(report.type) ? 1 : 2;
  const variant = variants[variantNumber];

  return (
    <Container>
      <ContentContainer>
        <Subheader>While we're preparing that:</Subheader>
        <Header>{variant.heading}</Header>
        <Text>{variant.message}</Text>
        <ProTip>
          <strong>Pro Tip:</strong>{" "}
          <blockquote dangerouslySetInnerHTML={{ __html: variant.tip }} />
        </ProTip>
        <Button
          app="cma"
          size="large"
          rel="noopener noreferrer"
          onClick={() => {
            if (typeof window.ga === "function") {
              window.ga(
                "cloudAgentSuiteTracker.send",
                "event",
                "Cross Product",
                "Login"
              );
            }
            window.open(streams.product_link, "_blank");
          }}>
          <ButtonIcon>
            <IconAlert />
          </ButtonIcon>
          Set Up Alerts
        </Button>
      </ContentContainer>
      <ImageContainer>
        <Image src={variant.image} alt="Text Alerts" />
      </ImageContainer>
    </Container>
  );
}

export default PublishAlerts;
