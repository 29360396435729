import React from "react";

function IconScribble() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="51" height="8">
      <path
        fill="none"
        stroke="#20B575"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.27 1.669C66.432.475 65.458.19 1 6.857l48.998-.851"
      />
    </svg>
  );
}

export default IconScribble;
