import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { default as BaseButton } from "../Button";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 50rem;
`;

export const Actions = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -1rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    margin: -0.5rem;
  `};
`;

export const Action = styled.div`
  padding: 1rem;

  ${({ theme }) => theme.media.sm`
    width: 100%;
    padding: 0.5rem;
  `};
`;

export const Button = styled(BaseButton)`
  ${({ theme }) => theme.media.sm`
    width: 100%;
  `};
`;

export const Icon = styled.span`
  display: inline-flex;
  margin-right: 0.5rem;

  svg {
    width: 1.8rem;
    height: 1.8rem;
  }
`;
