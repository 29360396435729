import React from "react";
import Icon from "../../Icon";

function IconAddCircleOutline(props) {
  return (
    <Icon {...props}>
      <path d="M0 0h24v24H0V0z" fill="none" />
      <path d="M9.93 13.5h4.14L12 7.98zM20 2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zm-4.05 16.5l-1.14-3H9.17l-1.12 3H5.96l5.11-13h1.86l5.11 13h-2.09z" />
    </Icon>
  );
}

export default IconAddCircleOutline;
