import styled from "styled-components";

export const RequestState = styled.span`
  position: absolute;
  top: 1.5rem;
  right: 0;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;
