import React, { useState } from "react";
import { UserAvatar } from "@wrstudios/components";
import { IconClose } from "@wrstudios/icons";
import { getInitials } from "@wrstudios/utils";
import Loading from "../../common/Loading";
import { contactAgent } from "./api";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerButton from "./HomebeatViewerButton";
import {
  Container,
  Button,
  Overlay,
  Agent,
  Grid,
  Cell,
  AgentName,
  AgentLink,
  Content,
  Message,
  Controls,
  TextArea,
  Success,
  Error
} from "./styled/homebeat-viewer-agent";

function HomebeatViewerAgent(props) {
  const { agent, report } = useHomebeatViewer();
  const [showOverlay, setShowOverlay] = useState(false);
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [error, setError] = useState(null);

  const handleToggle = () => {
    setHasSent(false);
    setError(null);
    setMessage("");
    setShowOverlay(!showOverlay);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setHasSent(false);
    setLoading(true);
    setError(null);

    try {
      await contactAgent(report.guid, message);
      setLoading(false);
      setHasSent(true);
      setMessage("");
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  return (
    <Container {...props}>
      {showOverlay && (
        <Overlay onKeyDown={(e) => e.key === "Escape" && setShowOverlay(false)}>
          <Agent>
            <Grid>
              <Cell>
                <UserAvatar
                  size="md"
                  initials={getInitials(agent.name)}
                  avatarUrl={agent.avatarUrl}
                />
              </Cell>
              <Cell>
                <AgentName>{agent.name}</AgentName>
                <div>
                  <AgentLink
                    href={agent.website}
                    target="_blank"
                    rel="noopener noreferrer">
                    {agent.companyName}
                  </AgentLink>
                </div>
                <div>
                  <AgentLink
                    href={`tel:${agent.mobilePhone || agent.officePhone}`}>
                    {agent.mobilePhone || agent.officePhone}
                  </AgentLink>
                </div>
              </Cell>
            </Grid>
          </Agent>
          <Content>
            <Message>{report.description}</Message>
            <form onSubmit={handleSubmit}>
              <TextArea
                as="textarea"
                rows="3"
                autoFocus
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <Controls>
                <HomebeatViewerButton disabled={!message || loading}>
                  {loading ? <Loading>Sending</Loading> : "Send"}
                </HomebeatViewerButton>
              </Controls>
            </form>
            {hasSent && <Success>Your message has been sent!</Success>}
            {error && (
              <Error>
                There was a problem sending your message. Please try again.
              </Error>
            )}
          </Content>
        </Overlay>
      )}
      <Button onClick={handleToggle}>
        {showOverlay && <IconClose />}
        {!showOverlay && (
          <UserAvatar
            size="md"
            initials={getInitials(agent.name)}
            avatarUrl={agent.avatarUrl}
          />
        )}
      </Button>
    </Container>
  );
}

export default HomebeatViewerAgent;
