import styled from "styled-components";
import {
  TableHeading as BaseTableHeading,
  TableRow as BaseTableRow,
  TableData as BaseTableData
} from "../../../common/Table";
import BaseTabs from "../../../common/Tabs";
import BaseHomebeatViewerListingToggle from "../HomebeatViewerListingToggle";

export const Container = styled.div`
  padding: 2.4rem;
`;

export const Header = styled.div`
  margin-bottom: 2.4rem;

  ${({ theme }) => theme.media.md`
    margin-bottom: 1.2rem;
  `}
`;

export const Tab = styled(BaseTabs.Tab)`
  text-transform: capitalize;
`;

export const StatsContainer = styled.div`
  margin-bottom: 2.4rem;

  ${({ theme }) => theme.media.md`
    margin-bottom: 1.2rem;
  `}
`;

export const StatsContainerGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.2rem;

  ${({ theme }) => theme.media.lg`
    flex-direction: column;
  `}

  ${({ theme }) => theme.media.md`
    margin: -0.6rem;
  `}
`;

export const StatsContainerCell = styled.div`
  padding: 1.2rem;

  ${({ theme }) => theme.media.md`
    padding: 0.6rem;
  `}

  &:first-child {
    flex-grow: 1;
  }

  &:last-child {
    ${({ theme }) => theme.media.lg`
      order: -1;
    `}
  }
`;

export const StatsGrid = styled.div`
  display: flex;
  height: 100%;
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border-radius: 0.6rem;

  @media (max-width: 1420px) {
    flex-direction: column;
  }
`;

export const Stats = styled.div`
  display: flex;
  align-items: center;
  height: 100%;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const Stat = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 2.4rem 3.2rem;
  border-left: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};

  @media (max-width: 1420px) {
    padding: 1.2rem;
  }

  ${({ theme }) => theme.media.md`
    border-left: 0;
    border-bottom: 0.1rem solid ${({ theme }) =>
      theme.styleGuide.colors.gray[400]};
  `}

  &:first-child {
    @media (max-width: 1420px) {
      border-left: 0;
    }
  }

  &:last-child {
    border-bottom: 0;
  }
`;

export const StatsCell = styled.div`
  flex-grow: 1;
  min-width: 0;

  &:first-child {
    ${Stat} {
      @media (max-width: 1420px) {
        border-bottom: 0.1rem solid
          ${({ theme }) => theme.styleGuide.colors.gray[400]};
      }

      &:first-child {
        border-left: 0;
      }
    }
  }
`;

export const StatLabel = styled.strong`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const StatValue = styled.div`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  ${({ theme }) => theme.media.sm`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`;

export const HomeValuation = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  padding: 1.6rem 2rem;
  border: 0.1rem solid ${({ theme }) => theme.homebeat.colors.primary};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border-radius: 0.6rem;
  appearance: none;
  cursor: pointer;

  ${({ theme }) => theme.media.md`
    width: 100%;
  `}
`;

export const HomeValuationIllustration = styled.div`
  margin-bottom: 0.6rem;

  svg {
    width: 5.3rem;
    height: 4rem;
  }
`;

export const HomeValuationText = styled.strong`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.homebeat.colors.primary};

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const TableContainer = styled.div`
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
  border-radius: 0.4rem;
  overflow: hidden;
`;

export const TableRow = styled(BaseTableRow)`
  cursor: ${({ isSubjectProperty }) => !isSubjectProperty && "pointer"};
  opacity: ${({ isGhost }) => (isGhost ? 0.5 : 1)};

  strong {
    font-weight: 600;
  }
`;

export const TableHeading = styled(BaseTableHeading)`
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  position: sticky;
  top: 0;
  z-index: 1;

  &:after {
    content: "";
    width: 100%;
    height: 0.1rem;
    background-color: ${({ theme }) => theme.styleGuide.colors.gray[400]};
    position: absolute;
    left: 0;
    bottom: -0.1rem;
  }
`;

export const TableHeader = styled.thead`
  ${TableRow} {
    cursor: default;
  }

  ${TableHeading} {
    &:first-child {
      padding-left: 2.4rem;
    }

    &:last-child {
      padding-right: 2.4rem;
    }
  }
`;

export const TableData = styled(BaseTableData)`
  &:first-child {
    padding-left: 2.4rem;
  }

  &:last-child {
    padding-right: 2.4rem;
  }
`;

export const HomebeatViewerListingToggleContainer = styled.div`
  display: inline-flex;
  width: 3.5rem;
  margin-right: 2.4rem;
  vertical-align: middle;
`;

export const HomebeatViewerListingToggle = styled(
  BaseHomebeatViewerListingToggle
)`
  width: 3.5rem;
  height: 2rem;
  padding: 0.3rem;

  .switch-pill:after {
    width: 1.4rem;
    height: 1.4rem;
  }
`;

export const Street = styled.span`
  font-weight: 600;
`;

export const CityStateZip = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Status = styled.span`
  display: inline-flex;
  align-items: center;
  height: 2rem;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  padding: 0 0.8rem;
  border-radius: 1rem;
  color: ${({ theme, statusColor }) =>
    [
      theme.styleGuide.colors.purple[500].toLowerCase(),
      theme.styleGuide.colors.red[500].toLowerCase()
    ].includes(statusColor.toLowerCase())
      ? theme.getColorSpectrumFromColor(statusColor)[600]
      : theme.getColorSpectrumFromColor(statusColor)[700]};
  background-color: ${({ theme, statusColor }) =>
    theme.getColorSpectrumFromColor(statusColor)[100]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;    
  `}
`;
