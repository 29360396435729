import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, cover: requestState(state.cover) };
}

export function resolved(state, action) {
  return {
    ...state,
    cover: resolvedState({
      ...state.cover,
      selected: { ...action.payload.cover }
    })
  };
}

export function rejected(state, action) {
  return { ...state, cover: rejectedState(state.cover, action.payload.error) };
}
