import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state, action) {
  const { listingId } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing = requestState(listing);
    }

    return listing;
  });

  return { ...state, all: listings };
}

export function resolved(state, action) {
  const { listingId } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing = resolvedState({ ...listing, ...action.payload });
    }

    return listing;
  });

  return { ...state, all: listings };
}

export function rejected(state, action) {
  const { listingId } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing = rejectedState(listing, action.payload.error);
    }

    return listing;
  });

  return { ...state, all: listings };
}
