import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatDate, formatCurrency, formatNumber } from "@wrstudios/utils";
import { getProxiedImageUrl } from "@wrstudios/image-proxy";
import { useSession } from "../../Session";
import { translate } from "../../utils/locale";
import { Slider } from "./styled/listing-details-general-info";

function fields(listing, report) {
  let fields = [
    { name: "Status", key: "status", type: "string" },
    {
      name: `${translate("mls.label", "MLS")} #`,
      key: "mlsnum",
      type: "string"
    },
    { name: "Source", key: "source", type: "string" },
    {
      name: translate("listing.county", "County"),
      key: "county",
      type: "string"
    },
    { name: "Acres", key: "acres", type: "number" },
    { name: "Lot Dim", key: "lotdim", type: "string" },
    { name: "Lot Size", key: "lotsize", type: "string" },
    { name: "Taxes", key: "taxes", type: "string" },
    { name: "Garages", key: "garages", type: "number" },
    { name: "Area", key: "area", type: "string" },
    { name: "Subdivision", key: "subdivision", type: "string" },
    { name: "Style", key: "style", type: "string" },
    { name: "DOM", key: "dom", type: "number" },
    { name: "Full baths", key: "baths_full", type: "number" },
    { name: "3/4 baths", key: "baths_three_quarter", type: "number" },
    { name: "Half baths", key: "baths_half", type: "number" },
    { name: "Quater baths", key: "baths_quarter", type: "number" },
    { name: "List date", key: "date_list", type: "date" },
    { name: " date", key: "date_sold", type: "date", dynamicName: true },
    {
      name: `${listing.status_as_word} date`,
      key: "date_pending",
      type: "date"
    },
    { name: "Expired date", key: "date_expired", type: "date" },
    { name: "Off-market date", key: "date_offmarket", type: "date" },
    { name: "Updated", key: "updated_at", type: "date_name" },
    { name: "List price", key: "price_list", type: "currency" },
    { name: "High list price", key: "price_list_high", type: "currency" },
    { name: "Low list price", key: "price_list_low", type: "currency" },
    { name: "Original List Price", key: "price_list_orig", type: "currency" },
    { name: " price", key: "price_sold", type: "currency", dynamicName: true },
    {
      name: "List/Sqft",
      key: "price_list_per_sqft",
      type: "currency",
      hide: !report.includes.sqft
    },
    {
      name: " / Sqft",
      key: "price_sold_per_sqft",
      type: "currency",
      dynamicName: true,
      hide: !report.includes.sqft
    },
    { name: "Assoc Fee", key: "assoc_fee", type: "number" },
    { name: "School district", key: "school_district", type: "string" },
    { name: "High", key: "school_high", type: "string" },
    { name: "Middle", key: "school_middle", type: "string" },
    { name: "Elementary", key: "school_elementary", type: "string" }
  ];

  // Remove hidden fields
  fields = fields.filter((field) => !field.hide);

  // Remove sold pending fields if sold is present
  if (
    listing.status_as_word &&
    listing.status_as_word.toLowerCase() === "sold"
  ) {
    fields = fields.filter((field) => field.key !== "date_pending");
  }

  return fields;
}

function getListingType(listing) {
  return listing.rental ? "Rent" : "Sold";
}

function castValue(value, type) {
  switch (type) {
    case "number":
      return Number.isNaN(Number(value)) ? value : Number(value);
    case "currency":
      return formatCurrency(value);
    case "date":
      return formatDate({
        dateValue: new Date(value),
        formating: "MM/dd/yyyy"
      });
    case "date_name":
      return formatDate({
        dateValue: new Date(value),
        formating: "MM/dd/yyyy h:mma"
      });
    default:
      return value;
  }
}

function ListingDetailsGeneralInfo({ listing, report }) {
  const { mls } = useSession();
  const listingFields = fields(listing, report);
  const photos =
    !listing.mls_photo_proxy || listing.data.source === "manual"
      ? listing.photos || (listing.data || {}).photos || []
      : (listing.data.photos || []).map((photo) =>
          getProxiedImageUrl({
            url: photo,
            strategy: mls.photoProxy
          })
        );

  return (
    <div className="-m-4 flex flex-wrap">
      <div className="w-full p-4 md:w-1/2">
        <Slider>
          {photos.map((photo, index) => (
            <img
              key={index}
              src={photo}
              alt={`${listing.addresses.join(" ")} ${index + 1}`}
            />
          ))}
        </Slider>
      </div>
      <div className="w-full p-4 md:w-1/2">
        {!!listingFields.length && (
          <ul className="list-reset listing-additional-details-general-info">
            {listingFields.map((field, index) => {
              const dataField = listing[field.key] || listing.data[field.key];
              const dataFieldValue = dataField
                ? castValue(
                    field.key === "lotsize"
                      ? Number(formatNumber(listing[field.key], "0[.][0]0")) ||
                          Number(
                            formatNumber(listing.data[field.key], "0[.][0]0")
                          )
                      : listing[field.key] || listing.data[field.key],
                    field.type
                  )
                : 0;

              return dataField ? (
                <li
                  key={index}
                  className="listing-additional-details-general-info-item">
                  <strong className="capitalize">
                    {field.dynamicName
                      ? `${getListingType(listing)} ${field.name}`
                      : field.name}
                  </strong>
                  :&nbsp;
                  <span
                    title={field.type === "currency" ? dataFieldValue : ""}
                    className="break-words">
                    {dataFieldValue}
                  </span>
                </li>
              ) : null;
            })}
          </ul>
        )}
        {!listingFields.length && <p>No field data available.</p>}
      </div>
    </div>
  );
}

ListingDetailsGeneralInfo.propTypes = {
  listing: PropTypes.shape({
    data: PropTypes.shape({
      photos: PropTypes.arrayOf(PropTypes.string)
    })
  })
};

const mapStateToProps = (state) => ({
  report: state.report
});

export default connect(mapStateToProps)(ListingDetailsGeneralInfo);
