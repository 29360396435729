import styled, { css } from "styled-components";

export const Container = styled.span`
  display: flex;
  justify-content: space-between;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  padding: 0.8rem;
  border-radius: 0.4rem;
  ${getVariant};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  a {
    color: ${({ theme }) => theme.styleGuide.colors.attract[500]};
  }
`;

export const Close = styled.button`
  margin-left: 2rem;
  padding: 0;
  appearance: none;
  cursor: pointer;
  color: currentColor;

  svg {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
  }
`;

function getVariant({ theme, variant }) {
  switch (variant) {
    case "note":
      return css`
        background-color: #fdeecd;
      `;
    case "success":
      return css`
        color: ${theme.styleGuide.colors.cma[500]};
        background-color: ${theme.styleGuide.colors.cma[200]};
      `;
    case "error":
      return css`
        color: ${theme.styleGuide.colors.attract[500]};
        background-color: ${({ theme }) =>
          theme.styleGuide.colors.attract[200]};
      `;
    case "warning":
      return css`
        color: ${theme.styleGuide.colors.red[900]};
        background-color: ${({ theme }) => theme.styleGuide.colors.yellow[100]};
      `;
    default:
      return css`
        color: ${theme.styleGuide.colors.streams[500]};
        background-color: ${({ theme }) =>
          theme.styleGuide.colors.streams[200]};
      `;
  }
}
