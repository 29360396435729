import {
  initialRequestState,
  requestState,
  resolvedState,
  rejectedState
} from "../../utils/redux";

export function request(state, action) {
  const { reportId } = action.payload;
  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      return requestState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function resolved(state, action) {
  const { id, reportId, filter } = action.payload;
  let { survey_questions, investor, launch_url } = action.payload;

  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      survey_questions = survey_questions || [];

      const newReport = {
        ...report,
        investor,
        launch_url,
        survey_questions,
        investorId: id,
        show_questionnaire: !!survey_questions.length
      };

      return filter === "resolve"
        ? resolvedState(newReport)
        : initialRequestState(newReport);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function rejected(state, action) {
  const { reportId } = action.payload;
  const reports = state.all.map((report) => {
    if (report.id === reportId) {
      return rejectedState(report, action.payload.error);
    }

    return report;
  });

  return { ...state, all: reports };
}
