import { connect } from "react-redux";
import CustomizeControlsLayout from "../CustomizeControlsLayout";

function select({ customize: { layout } }) {
  return {
    layout
  };
}

const actions = {};

export default connect(select, actions)(CustomizeControlsLayout);
