import { stripProtocol } from "./http";

/**
 * Generates a base64 url from a file.
 *
 * @param {File} file The image to conver to base64
 */
export function getBase64(file) {
  if (!file.type.includes("image")) {
    throw new Error(
      `getBase64 required an image file. You get file type: [${file.type}]`
    );
  }

  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
}

/**
 * Adds our proxy server to images
 *
 * @param {String} url
 */
export function proxy(url) {
  if (url.startsWith("http://")) {
    return `https://images.cloudmlx.com/${stripProtocol(url)}`;
  }

  return url;
}

/**
 * Loads images in a promise
 *
 * @param {Array} srcs
 */
export function loadImages(srcs) {
  return Promise.all(
    srcs.map((src) => {
      return new Promise((resolve, reject) => {
        const image = new Image();
        const proxySrc = proxy(src);

        image.src = proxySrc;

        image.onload = () => resolve(proxySrc);
        image.onerror = () => reject();
      });
    })
  );
}

/**
 * Loads a single image
 *
 * @param {String} src
 */
export function loadImage(src) {
  return new Promise((resolve, reject) => {
    const image = new Image();
    const proxySrc = proxy(src);

    image.src = proxySrc;

    image.onload = () => resolve(proxySrc);
    image.onerror = () => reject();
  });
}
