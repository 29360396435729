import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import CustomizeModalLayoutItems from "./CustomizeModalLayoutItems";
import { ModalContent } from "./styled/customize-modal-layout";

function CustomizeModalLayout({ onClose }) {
  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Layout
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <CustomizeModalLayoutItems onClick={onClose} />
        </Modal.Body>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalLayout.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default CustomizeModalLayout;
