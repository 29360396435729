import React from "react";
import Helmet from "react-helmet";
import { useParams, useHistory } from "react-router-dom";
import { Comparables } from "../../comparable";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerAgent from "./HomebeatViewerAgent";
import HomebeatViewerNavigation from "./HomebeatViewerNavigation";
import HomebeatViewerListing from "./HomebeatViewerListing";
import HomebeatViewerSubjectProperty from "./HomebeatViewerSubjectProperty";

function HomebeatViewerComparables() {
  const { guid } = useParams();
  const history = useHistory();
  const { listings, comparablesScrollPosition } = useHomebeatViewer();

  return (
    <>
      <Helmet>
        <title>Side by Side - Homebeat</title>
      </Helmet>
      <HomebeatViewerNavigation>Your Homebeat</HomebeatViewerNavigation>
      <Comparables
        hasFooter={false}
        scrollLeft={comparablesScrollPosition.current}
        onScroll={(e) => {
          comparablesScrollPosition.current = e.target.scrollLeft;
        }}>
        <HomebeatViewerSubjectProperty />
        {listings.map((listing) => (
          <HomebeatViewerListing
            key={listing.id}
            listing={listing}
            onClick={(e) => {
              history.push(`/homebeat/${guid}/listing/${listing.id}`, {
                initialTab: e.target.dataset.virtualTour ? "tour" : "map",
                showFullscreenMedia: !!e.target.dataset.virtualTour
              });
            }}
          />
        ))}
      </Comparables>
      <HomebeatViewerAgent />
    </>
  );
}

export default HomebeatViewerComparables;
