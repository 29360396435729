import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { Select as BaseSelect } from "../../../common/Select";

export const Modal = styled(BaseModal)`
  justify-content: flex-end;
  padding: 0;
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 44.2rem;
  height: 100%;
  padding: 0;
  border-radius: 0;
  transition: transform 0.2s;
  position: relative;

  .address-auto-complete-input {
    height: 3.6rem;
    padding: 0.8rem 1.2rem;
    border-width: 0.1rem;

    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.homebeat.colors.primary};
    }
  }

  .address-auto-complete-menu {
    border-width: 0.1rem;
  }

  .is-highlighted {
    color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const ModalHeader = styled(BaseModal.Header)`
  font-weight: 600;
  padding: 3.2rem 3.2rem 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const ModalClose = styled(BaseModal.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const ModalBody = styled(BaseModal.Body)`
  margin: 0;
  padding: 0 3.2rem 3.2rem;
`;

export const Banner = styled.div`
  display: flex;
  align-items: center;
  margin: 0 -3.2rem 2.4rem;
  padding: 1.2rem 2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
  border-left: 0;
  border-right: 0;
`;

export const Icon = styled.div`
  flex-shrink: 0;
  margin-right: 1.7rem;

  svg {
    width: 6.8rem;
    height: 6.8rem;

    .dots {
      fill: ${({ theme }) => theme.styleGuide.colors.red[500]};
    }
  }
`;

export const Address = styled.div`
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const CityStateZip = styled.div`
  font-size: 1.2rem;
  line-height: 1.8rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Email = styled.strong`
  font-weight: 600;
`;

export const FormGroup = styled.div`
  max-width: 22.4rem;
  margin-top: 2rem;
`;

export const Select = styled(BaseSelect)`
  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;
