import React from "react";
import ListingsMap from "../listings/map/ListingsMap";
import Listings from "../listings/Listings";
import ListingsStats from "../listings/ListingsStats";

function Report() {
  return (
    <div className="my-12">
      <ListingsMap />
      <div className="flex flex-wrap -m-6">
        <div className="w-full lg:w-3/4 p-6">
          <Listings />
        </div>
        <ListingsStats />
      </div>
    </div>
  );
}

export default Report;
