import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { activeListings } from "../../selectors/listings";
import ListingsStatsCountItem from "./ListingsStatsCountItem";

function ListingsStatsCount({ listings }) {
  const visibleListings = listings.filter((listings) => !listings.hide);
  const active = visibleListings.filter(
    (listing) => (listing.status_as_letter || "").toLowerCase() === "a"
  );
  const activeLabel = [
    ...new Set(
      active.map((listing) => listing.status_as_word || listing.status)
    )
  ].filter(Boolean);
  const backup = visibleListings.filter(
    (listing) => (listing.status_as_letter || "").toLowerCase() === "b"
  );
  const backupLabel = [
    ...new Set(
      backup.map((listing) => listing.status_as_word || listing.status)
    )
  ].filter(Boolean);
  const pending = visibleListings.filter(
    (listing) => (listing.status_as_letter || "").toLowerCase() === "p"
  );
  const pendingLabel = [
    ...new Set(
      pending.map((listing) => listing.status_as_word || listing.status)
    )
  ].filter(Boolean);
  const sold = visibleListings.filter(
    (listing) => (listing.status_as_letter || "").toLowerCase() === "s"
  );
  const soldLabel = [
    ...new Set(sold.map((listing) => listing.status_as_word || listing.status))
  ].filter(Boolean);
  const expired = visibleListings.filter(
    (listing) => (listing.status_as_letter || "").toLowerCase() === "x"
  );
  const expiredLabel = [
    ...new Set(
      expired.map((listing) => listing.status_as_word || listing.status)
    )
  ].filter(Boolean);
  const cancelled = visibleListings.filter(
    (listing) =>
      (listing.status_as_letter || "").toLowerCase() === "w" ||
      (listing.status_as_letter || "").toLowerCase() === "c" ||
      (listing.status_as_letter || "").toLowerCase() === "h"
  );
  const cancelledLabel = [
    ...new Set(
      cancelled.map((listing) => listing.status_as_word || listing.status)
    )
  ].filter(Boolean);

  return (
    <div className="text-center sm:text-left mt-0 lg:mt-12">
      <h2 className="font-normal text-lg text-grey-darker mb-8">
        Summary of Listings
      </h2>
      <ul className="list-reset inline-flex flex-col mx-auto sm:block">
        {!active.length &&
          !pending.length &&
          !sold.length &&
          !expired.length &&
          !cancelled.length && <li>No listings selected</li>}
        {!!active.length && (
          <ListingsStatsCountItem
            label={activeLabel.join(", ")}
            value={active.length}
            color={`#${active[0].status_as_color}`}
          />
        )}
        {!!backup.length && (
          <ListingsStatsCountItem
            label={backupLabel.join(", ")}
            value={backup.length}
            color={`#${backup[0].status_as_color}`}
          />
        )}
        {!!pending.length && (
          <ListingsStatsCountItem
            label={pendingLabel.join(", ")}
            value={pending.length}
            color={`#${pending[0].status_as_color}`}
          />
        )}
        {!!sold.length && (
          <ListingsStatsCountItem
            label={soldLabel.join(", ")}
            value={sold.length}
            color={`#${sold[0].status_as_color}`}
          />
        )}
        {!!expired.length && (
          <ListingsStatsCountItem
            label={expiredLabel.join(", ")}
            value={expired.length}
            color={`#${expired[0].status_as_color}`}
          />
        )}
        {!!cancelled.length && (
          <ListingsStatsCountItem
            label={cancelledLabel.join(", ")}
            value={cancelled.length}
            color={`#${cancelled[0].status_as_color}`}
          />
        )}
      </ul>
    </div>
  );
}

ListingsStatsCount.propTypes = {
  listings: PropTypes.arrayOf(PropTypes.object)
};

const mapStateToProps = (state) => ({
  listings: activeListings(state)
});

export default connect(mapStateToProps)(ListingsStatsCount);
