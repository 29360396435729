import { useMutation, useQuery } from "@apollo/react-hooks";
import { identity, pickBy } from "lodash";
import PropTypes from "prop-types";
import React, { createContext, useContext, useState } from "react";
import { useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { useSession } from "../../../Session";
import {
  acresToLotSize,
  lotSizeToAcres,
  parseValue
} from "../../../utils/number";
import { useQueryString } from "../../../utils/url";
import {
  CREATE_HOMEBEAT,
  GET_NEW_HOMEBEAT_DETAILS,
  UPDATE_HOMEBEAT
} from "../queries";

const HomebeatEditorContext = createContext();

function HomebeatEditorProvider({
  homebeats,
  currentIndex,
  isEditing,
  isLoading,
  children,
  onSkip,
  onFinish
}) {
  const theme = useTheme();
  const { mls } = useSession();
  const params = useParams();
  const { params: queryParams } = useQueryString();
  const [homebeat, setHomebeat] = useState(
    homebeats[currentIndex] || {
      lead: {},
      subjectProperty: {},
      report: {
        user: {}
      }
    }
  );
  const [name, setName] = useState(
    queryParams.client || homebeat.lead.name || ""
  );
  const [email, setEmail] = useState(
    queryParams.email || homebeat.lead.email || ""
  );
  const [address, setAddress] = useState(
    queryParams.address || homebeat.subjectProperty.address || ""
  );
  const [addressCoords, setAddressCoords] = useState({
    lat: queryParams.geo_lat || homebeat.subjectProperty.addressLat || "",
    lng: queryParams.geo_lon || homebeat.subjectProperty.addressLng || ""
  });
  const [propertyType, setPropertyType] = useState(
    queryParams.prop_type ||
      homebeat.report.propertyType ||
      mls.propertyTypes.default ||
      ""
  );
  const [propertySubType, setPropertySubType] = useState(
    queryParams.prop_sub_type ||
      homebeat.report.propertySubType ||
      mls.propertySubTypes.default ||
      ""
  );
  const [beds, setBeds] = useState(
    parseValue(queryParams.beds || homebeat.subjectProperty.beds) || ""
  );
  const [baths, setBaths] = useState(
    parseValue(queryParams.baths || homebeat.subjectProperty.baths) || ""
  );
  const [sqft, setSqft] = useState(
    parseValue(queryParams.sqft || homebeat.subjectProperty.sqft) || ""
  );
  const [garages, setGarages] = useState(
    parseValue(queryParams.garages || homebeat.subjectProperty.garages) || ""
  );
  const [lotSize, setLotSize] = useState(
    parseValue(queryParams.lot_size || homebeat.subjectProperty.lotSize) || ""
  );
  const [acres, setAcres] = useState(
    parseValue(queryParams.acres || homebeat.subjectProperty.acres) || ""
  );
  const noFrequencies = ["never", "none", ""];
  const [frequency, setFrequency] = useState(
    noFrequencies.includes(queryParams.frequency || homebeat.frequency)
      ? "never"
      : queryParams.frequency || homebeat.frequency || "quarterly"
  );
  const [welcomeEmail, setWelcomeEmail] = useState(
    queryParams.welcome_email || homebeat.welcomeEmailBody || ""
  );
  const [defaultWelcomeEmail, setDefaultWelcomeEmail] = useState(
    queryParams.default_welcome_email || homebeat.welcomeEmailBody || ""
  );
  const variables = {
    input: {
      automation: {
        id: homebeat.id,
        api_key: homebeat.report.user.guid,
        frequency,
        welcome_email_body:
          welcomeEmail === defaultWelcomeEmail
            ? null
            : welcomeEmail.replace(
                `class="keyword-placeholder" style="color: ${theme.styleGuide.colors.purple[500]};"`,
                'class="keyword-placeholder"'
              ),
        report: {
          prop_type: propertyType,
          prop_sub_type: propertySubType
        },
        subject_property: pickBy(
          {
            address,
            geo_lat: addressCoords.lat,
            geo_lon: addressCoords.lng,
            sqft,
            beds,
            baths,
            garages: garages,
            lot_size: lotSize || acresToLotSize(acres),
            acres: acres || lotSizeToAcres(lotSize)
          },
          identity
        ),
        lead: {
          name,
          email_address: email
        }
      }
    }
  };

  const [iFrameKey, setIFrameKey] = useState(Math.random());
  const [updatingError, setUpdatingError] = useState(null);
  const [creatingError, setCreatingError] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [showMobilePreview, setShowMobilePreview] = useState(false);
  const [isNewlyCreated, setIsNewlyCreated] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [hasUpdated, setHasUpdated] = useState(false);
  const [isGeneratingPreview, setIsGeneratingPreview] = useState(false);
  const isEditingOrNewlyCreated =
    (isEditing && !!(params.id || homebeat.id)) ||
    (isEditing && homebeat.frequency !== null) ||
    isNewlyCreated;
  const [createHomebeat, { loading: creatingHomebeat }] = useMutation(
    CREATE_HOMEBEAT,
    {
      variables,
      onCompleted: ({ createHomebeat }) => {
        setHomebeat(createHomebeat);
        setIsNewlyCreated(true);

        if (isGeneratingPreview) {
          setShowPreview(true);
        }

        if (!homebeat.frequency && !!createHomebeat.frequency) {
          setHasSent(true);
        }
      },
      onError: (error) => {
        setCreatingError(error);
        setShowPreview(false);
        setIsGeneratingPreview(false);
      }
    }
  );
  const [updateHomebeat, { loading: updatingHomebeat }] = useMutation(
    UPDATE_HOMEBEAT,
    {
      variables,
      onCompleted: ({ updateHomebeat }) => {
        setHomebeat(updateHomebeat);

        if (isGeneratingPreview) {
          setShowPreview(true);
        }

        if (!homebeat.frequency && !!updateHomebeat.frequency) {
          setHasSent(true);
        } else if (!!updateHomebeat.frequency && !isGeneratingPreview) {
          setHasUpdated(true);
        }
      },
      onError: (error) => {
        setUpdatingError(error);
        setShowPreview(false);
        setIsGeneratingPreview(false);
      }
    }
  );

  const handleGeneratePreview = () => {
    setIFrameKey(Math.random());
    setIsGeneratingPreview(true);
    setShowMobilePreview(true);
    setCreatingError(null);
    setUpdatingError(null);

    if (isEditingOrNewlyCreated) {
      updateHomebeat({
        variables: {
          ...variables,
          input: {
            ...variables.input,
            automation: {
              ...variables.input.automation,
              frequency: null
            }
          }
        }
      });
      return;
    }

    createHomebeat({
      variables: {
        ...variables,
        input: {
          ...variables.input,
          automation: {
            ...variables.input.automation,
            frequency: null
          }
        }
      }
    });
  };

  useQuery(GET_NEW_HOMEBEAT_DETAILS, {
    skip: isEditingOrNewlyCreated,
    fetchPolicy: "no-cache",
    onCompleted: (data) => {
      if (!data) return;
      setWelcomeEmail(
        data.newHomebeatDetails.welcomeEmail.replace(
          /class="keyword-placeholder"/g,
          `class="keyword-placeholder" style="color: ${theme.styleGuide.colors.purple[500]};"`
        )
      );
      setDefaultWelcomeEmail(
        data.newHomebeatDetails.defaultWelcomeEmail.replace(
          /class="keyword-placeholder"/g,
          `class="keyword-placeholder" style="color: ${theme.styleGuide.colors.purple[500]};"`
        )
      );
    }
  });

  const isDisabled =
    creatingHomebeat ||
    updatingHomebeat ||
    hasSent ||
    isGeneratingPreview ||
    !address ||
    !propertyType ||
    !frequency ||
    !name ||
    !email;

  return (
    <HomebeatEditorContext.Provider
      value={{
        homebeats,
        homebeat,
        currentIndex,
        name,
        setName,
        email,
        setEmail,
        address,
        setAddress,
        addressCoords,
        setAddressCoords,
        propertyType,
        setPropertyType,
        propertySubType,
        setPropertySubType,
        beds,
        setBeds,
        baths,
        setBaths,
        sqft,
        setSqft,
        garages,
        setGarages,
        lotSize,
        setLotSize,
        acres,
        setAcres,
        frequency,
        setFrequency,
        welcomeEmail,
        setWelcomeEmail,
        defaultWelcomeEmail,
        setDefaultWelcomeEmail,
        noFrequencies,
        skip: onSkip,
        finish: onFinish,
        generatePreview: handleGeneratePreview,
        iFrameKey,
        setIFrameKey,
        isLoading,
        isDisabled,
        isEditing,
        isGeneratingPreview,
        setIsGeneratingPreview,
        updatingHomebeat,
        creatingHomebeat,
        updateHomebeat,
        createHomebeat,
        creatingError,
        setCreatingError,
        updatingError,
        setUpdatingError,
        showPreview,
        setShowPreview,
        showMobilePreview,
        setShowMobilePreview,
        hasSent,
        setHasSent,
        hasUpdated,
        setHasUpdated,
        isNewlyCreated,
        isEditingOrNewlyCreated
      }}>
      {children}
    </HomebeatEditorContext.Provider>
  );
}

HomebeatEditorProvider.defaultProps = {
  isEditing: false,
  isLoading: false
};

HomebeatEditorProvider.propTypes = {
  homebeats: PropTypes.array.isRequired,
  isEditing: PropTypes.bool,
  isLoading: PropTypes.bool,
  onSkip: PropTypes.func.isRequired,
  onFinish: PropTypes.func.isRequired
};

function useHomebeatEditor() {
  const context = useContext(HomebeatEditorContext);
  if (!context) {
    throw new Error(
      "`useHomebeatEditor` must be used within a `<HomebeatEditorProvider />`"
    );
  }
  return context;
}

function HomebeatEditorProviderContainer({
  homebeats,
  isEditing,
  isLoading,
  children,
  onFinish
}) {
  const [editorKey, setEditorKey] = useState(Math.random());
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleSkip = () => {
    setCurrentIndex(currentIndex + 1);
    setEditorKey(Math.random());
  };

  return (
    <HomebeatEditorProvider
      key={editorKey}
      homebeats={homebeats}
      currentIndex={currentIndex}
      isEditing={isEditing}
      isLoading={isLoading}
      onSkip={handleSkip}
      onFinish={onFinish}>
      {children}
    </HomebeatEditorProvider>
  );
}

export {
  HomebeatEditorProviderContainer as HomebeatEditorProvider,
  useHomebeatEditor
};
