import styled from "styled-components";

export const Container = styled.button`
  padding: 0;
  appearance: none;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;
