import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import Switch from "../../common/Switch";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import {
  Container,
  Label,
  Control
} from "./styled/homebeat-viewer-listing-toggle";

function HomebeatViewerListingToggle({ listingId, label, ...props }) {
  const theme = useTheme();
  const { includedListingIds, setIncludedListingIds } = useHomebeatViewer();
  const isOn = includedListingIds.includes(listingId);

  return (
    <Container>
      {label && <Label>{label}</Label>}
      <Control>
        <Switch
          {...props}
          color={theme.homebeat.colors.primary}
          isOn={isOn}
          onChange={(_, e) => {
            e.stopPropagation();
            setIncludedListingIds((listingIds) => {
              return isOn
                ? listingIds.filter((id) => id !== listingId)
                : [...listingIds, listingId];
            });
          }}
        />
      </Control>
    </Container>
  );
}

HomebeatViewerListingToggle.defaultProps = {
  label: "Include in valuation"
};

HomebeatViewerListingToggle.propTypes = {
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  label: PropTypes.string
};

export default HomebeatViewerListingToggle;
