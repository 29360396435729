import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLayout } from "../../actions/customize";
import {
  Container,
  Badge,
  Title
} from "./styled/customize-modal-layout-item-image";

function CustomizeModalLayoutItemImage({
  reportId,
  layout,
  selected,
  updateLayout,
  onClick
}) {
  const isActive = layout.key === selected.key;
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <Container
        autoFocus={isActive}
        isActive={isActive || isHovering}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          updateLayout(reportId, layout);
          onClick();
        }}>
        {isActive && <Badge>Currently Selected</Badge>}
        <img
          src={`/images/reports/layouts/${layout.key}.jpg`}
          alt={layout.title}
        />
      </Container>
      <Title>{layout.title}</Title>
    </>
  );
}

CustomizeModalLayoutItemImage.propTypes = {
  reportId: PropTypes.number,
  layout: PropTypes.shape({
    key: PropTypes.string
  }),
  selected: PropTypes.shape({
    key: PropTypes.string
  }),
  updateLayout: PropTypes.func,
  onClick: PropTypes.func
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  selected: state.customize.layout.selected
});

const mapDispatchToProps = {
  updateLayout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalLayoutItemImage);
