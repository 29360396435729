import React from "react";
import Button from "../common/Button";
import { useCSV } from "./CSVProvider";

function CSVAction({ onClick, ...props }) {
  const { getMappedData } = useCSV();

  return (
    <Button
      {...props}
      onClick={(e) => {
        onClick({ originalEvent: e, getMappedData });
      }}
    />
  );
}

CSVAction.defaultProps = {
  onClick: () => {}
};

export default CSVAction;
