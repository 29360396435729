import React from "react";
import IconCMA from "../icons/report/IconCMA";
import IconBuyerTour from "../icons/report/IconBuyerTour";
import IconProperty from "../icons/report/IconProperty";
import IconFlyer from "../icons/report/IconFlyer";
import IconHomebeat from "../icons/report/IconHomebeat";

export function getReportDetails(type) {
  switch (type) {
    case "Cma":
      return {
        title: "CMA",
        label: "CMA Report",
        url: "/cmas",
        urlLabel: "Back to CMAs",
        image: <IconCMA />
      };
    case "Tour":
      return {
        title: "Buyer Tour",
        label: "Buyer Tour",
        url: "/tours",
        urlLabel: "Back to Buyer Tours",
        image: <IconBuyerTour />
      };
    case "Property":
      return {
        title: "Property Report",
        label: "Property Report",
        url: "/properties",
        urlLabel: "Back to Property Reports",
        image: <IconProperty />
      };
    case "Flyer":
      return {
        title: "Flyer",
        label: "Flyer",
        url: "/flyers",
        urlLabel: "Back to Flyers",
        image: <IconFlyer />
      };
    case "Homebeat":
      return {
        title: "Homebeat",
        label: "Homebeat",
        url: "/homebeats",
        urlLabel: "Back to Homebeats",
        image: <IconHomebeat />
      };
    default:
      return {
        title: "Report",
        label: "Report",
        url: "/",
        urlLabel: "Go Home",
        image: <IconCMA />
      };
  }
}
