import styled from "styled-components";

export const Container = styled.label`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  margin-bottom: 0.3rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  vertical-align: middle;

  &[for] {
    cursor: pointer;
  }

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    font-weight: 700;
    line-height: 1.7rem;
  `}
`;
