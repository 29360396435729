import React from "react";

function IconSparkle(props) {
  return (
    <svg
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 21"
      {...props}>
      <path
        d="M5.476 9.499c.069 0 .13-.055.137-.178.198-2.195.376-2.488 1.818-2.666.13-.014.164-.075.164-.144 0-.089-.04-.143-.177-.164C5.982 6.17 5.81 5.862 5.613 3.9c-.014-.123-.075-.164-.137-.164-.082 0-.143.04-.157.17-.198 1.963-.37 2.263-1.798 2.441-.137.02-.178.075-.178.164 0 .069.041.13.164.144 1.43.178 1.614.465 1.812 2.666.014.123.075.178.157.178Zm6.262 6.617c.089 0 .164-.048.184-.192.54-4.778 1.033-5.263 4.512-5.803.144-.02.205-.09.205-.178 0-.096-.061-.157-.212-.178-3.472-.526-3.964-1.025-4.539-5.346-.027-.157-.082-.218-.184-.218-.09 0-.164.061-.178.205-.533 4.327-1.066 4.826-4.546 5.36-.15.02-.205.081-.205.177 0 .089.055.157.198.178 3.487.54 4.013 1.018 4.587 5.803.014.137.089.192.178.192Zm-4.197.677c.082 0 .13-.069.143-.185.198-2.194.37-2.488 1.812-2.666.123-.014.17-.069.17-.144 0-.075-.034-.143-.17-.157-1.429-.184-1.614-.458-1.812-2.454-.013-.116-.075-.157-.143-.157-.082 0-.137.034-.15.164-.199 1.975-.377 2.262-1.82 2.447-.115.014-.163.075-.163.157 0 .075.048.13.164.144 1.435.178 1.62.472 1.818 2.666.007.123.069.184.15.184Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconSparkle;
