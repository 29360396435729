import { ATTACHMENTS_UPLOAD } from "../../actionTypes/attachments";
import {
  request as uploadRequest,
  resolved as uploadResolved,
  rejected as uploadRejected
} from "./upload";

const defaultState = {
  request: {
    fetching: false,
    fetched: false,
    data: {},
    error: {},
    errors: {}
  }
};

export default function (state = defaultState, action) {
  switch (action.type) {
    case `${ATTACHMENTS_UPLOAD}_REQUEST`:
      return uploadRequest(state, action);
    case `${ATTACHMENTS_UPLOAD}_RESOLVED`:
      return uploadResolved(state, action);
    case `${ATTACHMENTS_UPLOAD}_REJECTED`:
      return uploadRejected(state, action);

    default:
      return state;
  }
}
