import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { getPageKeys } from "../../utils/page";

function hasKeys(obj) {
  if (!obj) return false;

  return !!Object.keys(obj).length;
}

function CustomizeActiveHiddenInputs({
  pages,
  hasTemplateModified,
  selectedTheme,
  selectedLayout,
  selectedTemplate,
  selectedCover,
  selectedFont,
  selectedLanguage,
  illustrations
}) {
  const hasTheme = Boolean(hasKeys(selectedTheme) && selectedTheme.key);
  const hasLayout = Boolean(hasKeys(selectedLayout) && selectedLayout.key);
  const hasTemplate = Boolean(
    hasKeys(selectedTemplate) && selectedTemplate.key
  );
  const hasCover = Boolean(hasKeys(selectedCover) && selectedCover.key);
  const hasFont = Boolean(hasKeys(selectedFont) && selectedFont.key);
  const hasLanguage = Boolean(selectedLanguage);
  const hasIllustration = hasKeys(illustrations);
  const templateKey = hasTemplateModified
    ? "unsaved"
    : hasTemplate
    ? selectedTemplate.key || ""
    : "";

  return (
    <Fragment>
      <input
        type="hidden"
        name="pages"
        value={JSON.stringify(getPageKeys(pages))}
      />
      {hasTheme && (
        <input type="hidden" name="theme" value={selectedTheme.key || ""} />
      )}
      {hasLayout && (
        <input type="hidden" name="layout" value={selectedLayout.key || ""} />
      )}
      {hasTemplate && (
        <input type="hidden" name="template" value={templateKey} />
      )}
      {hasCover && (
        <input type="hidden" name="cover" value={selectedCover.key || ""} />
      )}
      {hasFont && (
        <input type="hidden" name="font" value={selectedFont.key || ""} />
      )}
      {hasIllustration && (
        <input
          type="hidden"
          name="illustrations"
          value={Number(illustrations.active || 0)}
        />
      )}
      {hasLanguage && (
        <input type="hidden" name="locale" value={selectedLanguage} />
      )}
    </Fragment>
  );
}

CustomizeActiveHiddenInputs.propTypes = {
  hasTemplateModified: PropTypes.bool.isRequired,
  selectedTheme: PropTypes.shape({ key: PropTypes.string }),
  selectedLayout: PropTypes.shape({ key: PropTypes.string }),
  selectedTemplate: PropTypes.shape({
    key: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  selectedCover: PropTypes.shape({ key: PropTypes.string }),
  selectedFont: PropTypes.shape({ key: PropTypes.string }),
  selectedLanguage: PropTypes.string,
  illustrations: PropTypes.shape({ active: PropTypes.bool })
};

export default CustomizeActiveHiddenInputs;
