import styled from "styled-components";
import { default as BaseInput } from "../Input";

export const Container = styled.form`
  position: relative;

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;

export const SearchIcon = styled.div`
  transform: translateY(-50%);
  position: absolute;
  top: 50%;
  left: 0.7rem;
  z-index: 1;
`;

export const Input = styled(BaseInput)`
  min-width: 24.3rem;
  padding: 0.7rem 3.5rem;
`;

export const Clear = styled.button`
  padding: 0;
  appearance: none;
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  right: 0.7rem;
  z-index: 1;

  &:active,
  &:focus {
    outline: none;

    svg {
      fill: ${({ theme }) => theme.styleGuide.colors.cma[500]};
    }
  }
`;
