import { useState, useLayoutEffect } from "react";
import PropTypes from "prop-types";

function Media({ query, children }) {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useLayoutEffect(() => {
    const mql = window.matchMedia(query);
    const handler = (e) => setMatches(e.matches);
    mql.addListener(handler);
    return () => {
      mql.removeListener(handler);
    };
  }, [query]);

  return children(matches);
}

Media.propTypes = {
  query: PropTypes.string.isRequired,
  children: PropTypes.func.isRequired
};

export default Media;
