import React from "react";
import PropTypes from "prop-types";
import { colors } from "@wrstudios/theme";
import { Markers } from "@wrstudios/components";
import { Container } from "./styled/subject-property-pin";

function SubjectPropertyPin({ color, props }) {
  return (
    <Container markerColor={color}>
      <Markers.SubjectPropertyMarker as="div" {...props} />
    </Container>
  );
}

SubjectPropertyPin.defaultProps = {
  color: colors.purple[500]
};

SubjectPropertyPin.propTypes = {
  color: PropTypes.string
};

export default SubjectPropertyPin;
