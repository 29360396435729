import React, { useState } from "react";
import PropTypes from "prop-types";
import { Card } from "./Card";
import WistiaModal from "./WistiaModal";
import { Container, Icon } from "./styled/card-getting-started";

function CardGettingStarted({
  videoId,
  videoTitle,
  children,
  onClick,
  ...props
}) {
  const [isShowingModal, setIsShowingModal] = useState(false);

  const handleClick = (e) => {
    setIsShowingModal(true);
    onClick(e);
  };

  return (
    <>
      <Card {...props}>
        <Container onClick={handleClick}>
          <Icon />
          {children}
        </Container>
      </Card>
      {isShowingModal && (
        <WistiaModal
          videoId={videoId}
          videoTitle={videoTitle || children}
          onClose={() => setIsShowingModal(false)}>
          {children}
        </WistiaModal>
      )}
    </>
  );
}

CardGettingStarted.defaultProps = {
  onClick: () => {}
};

CardGettingStarted.propTypes = {
  videoId: PropTypes.string.isRequired,
  videoTitle: PropTypes.string,
  onClick: PropTypes.func
};

export default CardGettingStarted;
