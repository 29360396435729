import styled from "styled-components";
import { default as BaseTitle } from "../../common/Title";
import { default as BasePaginate } from "../../common/Paginate";

export const Container = styled.div`
  margin: 1.5rem 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 3rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `}
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.5rem;
`;

export const Cell = styled.div`
  width: calc(100% / 3);
  padding: 1.5rem;

  ${({ theme }) => theme.media.lg`
    width: 50%;
  `};

  ${({ theme }) => theme.media.sm`
    width: 100%
  `};
`;

export const Title = styled(BaseTitle)`
  ${({ theme }) => theme.media.sm`
    margin-bottom: 1rem;
  `}
`;

export const Paginate = styled(BasePaginate)`
  margin-top: 3rem;
`;

export const SwitchBack = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 4.2rem auto 0;
  gap: 1.2rem;
  appearance: none;
  cursor: pointer;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #111827;

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }
`;
