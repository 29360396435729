import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import PlatformService from "@lwolf-common/platform-service/build/PlatformService";
import React, { useEffect, useState } from "react";
import { useSession } from "../../Session";
import { getCasDomain, getNewCmaUrl } from "../../utils/url";
import LegacyNavigation from "./LegacyNavigation";
import { Container, Global } from "./styled/navigation";

export default function Navigation() {
  const { currentUser } = useSession();
  const canUseNewNavigation =
    currentUser.lwaJwt && !currentUser.loggedInAs && !currentUser.isAuthSSO;

  return canUseNewNavigation ? <NewNavigation /> : <LegacyNavigation />;
}

function NewNavigation() {
  const { currentUser, features, config } = useSession();
  const { data: appSwitcher } = useAppSwitcher();

  return (
    <Container id="navigation">
      <Global />
      <Navbar dataLwtId="default">
        <ApplicationSwitcher
          applicationName="Cloud CMA"
          appSections={appSwitcher?.appSections || []}
          launchpadUrl={config.lwPlatformUrl}
        />
        <NavbarNavigationBar
          navItems={[
            { label: "CMA", url: "/cmas" },
            { label: "Buyer Tour", url: "/tours" },
            { label: "Property", url: "/properties" },
            features.hasFlyers ? { label: "Flyer", url: "/flyers" } : undefined,
            features.hasDocuments
              ? { label: "Documents", url: "/documents" }
              : undefined,
            features.hasHomebeat || features.offerHomebeat
              ? { label: "Homebeat", url: "/homebeats" }
              : undefined
          ].filter(Boolean)}
          linkWrapper={({ item }) => (
            <a
              href={item.url}
              data-turbolinks={item.url === "/homebeats" ? "false" : "true"}>
              {item.label}
            </a>
          )}
        />
        <NavbarAdditionalItems>
          <NavbarHelpLink
            url="https://lonewolf.my.site.com/s/cloud-cma-resources"
            linkWrapper={({ url, label }) => (
              <a href={url} target="_blank" rel="noreferrer">
                {label}
              </a>
            )}
          />
          <NavbarUserMenu
            id="avatar"
            user={{
              name: currentUser?.name,
              email: currentUser?.email,
              avatarURL: currentUser?.avatarUrl
            }}
            menuItems={[
              { label: "Account Settings", url: "/settings" },
              currentUser.role >= 99990
                ? {
                    label: "Brokers",
                    url: `${getNewCmaUrl()}/admin/brokers?jwt=${
                      currentUser.casJwt
                    }`
                  }
                : features.hasBrokerAccess
                ? {
                    label: "Dashboard",
                    url: `${getNewCmaUrl()}/dashboard?jwt=${currentUser.casJwt}`
                  }
                : (currentUser.isAdmin || currentUser.role >= 9990) &&
                  currentUser.isSmallBroker
                ? {
                    label: "Dashboard",
                    url: `/admin/account/${currentUser.accountId}`
                  }
                : {},
              { divider: true },
              { label: "Custom Pages", url: "/settings/custom-pages" },
              features.hasAPIButton
                ? { label: "Lead Generation", url: "/settings/lead-generation" }
                : {},
              { divider: true },
              {
                label: "Learning Resources",
                url: "https://www.lwolf.com/services/training/cloud-agent-suite"
              },
              {
                label: "Support Center",
                url: "https://lonewolf.my.site.com/s/cloud-cma-resources"
              },
              {
                label: "Launchpad",
                url: `${getCasDomain()}/app`
              },
              { divider: true },
              {
                label: currentUser.loggedInAs
                  ? "Back to your account"
                  : "Sign Out",
                url: config.logoutUrl
              }
            ].filter((item) => !!Object.keys(item).length)}
            linkWrapper={({ item }) => {
              if (item.url === config.logoutUrl || item.label === "Launchpad") {
                return (
                  <a href={item.url} data-turbolinks="false">
                    {item.label}
                  </a>
                );
              }

              const isExternal = [
                "Learning Resources",
                "Support Center"
              ].includes(item.label);

              if (isExternal) {
                return (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.label}
                  </a>
                );
              }

              return <a href={item.url}>{item.label}</a>;
            }}
          />
        </NavbarAdditionalItems>
      </Navbar>
    </Container>
  );
}

function useAppSwitcher() {
  const { currentUser, config } = useSession();
  const [data, setData] = useState();

  const { lwaJwt } = currentUser || {};

  useEffect(() => {
    if (!lwaJwt) return;

    async function initGlobalHeader() {
      try {
        const platformService = await PlatformService.initialize({
          lwDomain: config.lwUrl,
          token: lwaJwt,
          appId: "cc"
        });

        const appSections = await platformService.getAppSections();

        setData({ appSections });
      } catch (error) {
        console.log("error", error);
      }
    }

    initGlobalHeader();
  }, [lwaJwt, config]);

  return { data };
}
