import {
  CUSTOMIZE_ADD_ACTIVE_PAGE,
  CUSTOMIZE_ADD_CUSTOM_PAGE,
  CUSTOMIZE_ADD_CUSTOM_PDF_PAGE,
  CUSTOMIZE_CLEAR_TEMPLATE,
  CUSTOMIZE_EDIT_CUSTOM_PAGE,
  CUSTOMIZE_PAGE_META,
  CUSTOMIZE_REMOVE_ACTIVE_PAGE,
  CUSTOMIZE_REMOVE_CUSTOM_PAGE,
  CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE,
  CUSTOMIZE_REMOVE_TEMPLATE,
  CUSTOMIZE_RENAME_CUSTOM_PAGE,
  CUSTOMIZE_RESET_PAGES,
  CUSTOMIZE_RESET_REQUEST,
  CUSTOMIZE_RESET_SELECTED_PAGE,
  CUSTOMIZE_SAVE_CUSTOM_PAGE,
  CUSTOMIZE_SAVE_TEMPLATE,
  CUSTOMIZE_SELECT_TEMPLATE,
  CUSTOMIZE_SORT_ACTIVE_PAGES,
  CUSTOMIZE_SORT_ACTIVE_PAGE_CHILDREN,
  CUSTOMIZE_UPDATE_COVER,
  CUSTOMIZE_UPDATE_DESCRIPTION,
  CUSTOMIZE_UPDATE_FONT,
  CUSTOMIZE_UPDATE_HEADLINE,
  CUSTOMIZE_UPDATE_ILLUSTRATION,
  CUSTOMIZE_UPDATE_LANGUAGE,
  CUSTOMIZE_UPDATE_LAYOUT,
  CUSTOMIZE_UPDATE_TEMPLATE,
  CUSTOMIZE_UPDATE_THEME,
  CUSTOMIZE_UPDATE_TITLE
} from "../../actionTypes/customize";
import { ORDER_NEW } from "../../actionTypes/order";
import {
  rejected as updateCoverRejected,
  request as updateCoverRequest,
  resolved as updateCoverResolved
} from "./cover/update";
import {
  rejected as addCustomPageRejected,
  request as addCustomPageRequest,
  resolved as addCustomPageResolved
} from "./custom-pages/add";
import {
  rejected as editCustomPageRejected,
  request as editCustomPageRequest,
  resolved as editCustomPageResolved
} from "./custom-pages/edit";
import {
  rejected as addCustomPDFPageRejected,
  request as addCustomPDFPageRequest,
  resolved as addCustomPDFPageResolved
} from "./custom-pages/pdf/add";
import {
  rejected as removeCustomPDFPageRejected,
  request as removeCustomPDFPageRequest,
  resolved as removeCustomPDFPageResolved
} from "./custom-pages/pdf/remove";
import {
  rejected as removeCustomPageRejected,
  request as removeCustomPageRequest,
  resolved as removeCustomPageResolved
} from "./custom-pages/remove";
import {
  rejected as renameCustomPageRejected,
  request as renameCustomPageRequest,
  resolved as renameCustomPageResolved
} from "./custom-pages/rename";
import {
  rejected as saveCustomPageRejected,
  request as saveCustomPageRequest,
  resolved as saveCustomPageResolved
} from "./custom-pages/save";
import {
  rejected as updateDescriptionRejected,
  request as updateDescriptionRequest,
  resolved as updateDescriptionResolved
} from "./description/update";
import {
  rejected as updateFontRejected,
  request as updateFontRequest,
  resolved as updateFontResolved
} from "./font/update";
import {
  rejected as updateHeadlineRejected,
  request as updateHeadlineRequest,
  resolved as updateHeadlineResolved
} from "./headline/update";
import {
  rejected as updateIllustrationRejected,
  request as updateIllustrationRequest,
  resolved as updateIllustrationResolved
} from "./illustrations/update";
import {
  rejected as updateLanguageRejected,
  request as updateLanguageRequest,
  resolved as updateLanguageResolved
} from "./language/update";
import {
  rejected as updateLayoutRejected,
  request as updateLayoutRequest,
  resolved as updateLayoutResolved
} from "./layout/update";
import { resolved as orderResolved } from "./order/new";
import { add as addActivePage } from "./page/add";
import { updateMeta } from "./page/meta";
import { remove as removeActivePage } from "./page/remove";
import {
  rejected as resetPagesRejected,
  request as resetPagesRequest,
  resolved as resetPagesResolved
} from "./page/reset";
import { sortActiveChildren, sortActivePages } from "./page/sort";
import { resetDefaultRequest, resetSelectedPage } from "./reset";
import {
  rejected as clearTemplateRejected,
  request as clearTemplateRequest,
  resolved as clearTemplateResolved
} from "./template/clear";
import {
  rejected as removeTemplateRejected,
  request as removeTemplateRequest,
  resolved as removeTemplateResolved
} from "./template/remove";
import {
  rejected as saveTemplateRejected,
  request as saveTemplateRequest,
  resolved as saveTemplateResolved
} from "./template/save";
import {
  rejected as selectTemplateRejected,
  request as selectTemplateRequest,
  resolved as selectTemplateResolved
} from "./template/select";
import {
  rejected as updateTemplateRejected,
  request as updateTemplateRequest,
  resolved as updateTemplateResolved
} from "./template/update";
import {
  rejected as updateThemeRejected,
  request as updateThemeRequest,
  resolved as updateThemeResolved
} from "./theme/update";
import {
  rejected as updateTitleRejected,
  request as updateTitleRequest,
  resolved as updateTitleResolved
} from "./title/update";

export default function (state = {}, action) {
  switch (action.type) {
    case `${CUSTOMIZE_RESET_PAGES}_REQUEST`:
      return resetPagesRequest(state, action);
    case `${CUSTOMIZE_RESET_PAGES}_RESOLVED`:
      return resetPagesResolved(state, action);
    case `${CUSTOMIZE_RESET_PAGES}_REJECTED`:
      return resetPagesRejected(state, action);

    case CUSTOMIZE_RESET_REQUEST:
      return resetDefaultRequest(state, action);

    case CUSTOMIZE_RESET_SELECTED_PAGE:
      return resetSelectedPage(state, action);

    case CUSTOMIZE_SORT_ACTIVE_PAGES:
      return sortActivePages(state, action);

    case CUSTOMIZE_SORT_ACTIVE_PAGE_CHILDREN:
      return sortActiveChildren(state, action);

    case CUSTOMIZE_ADD_ACTIVE_PAGE:
      return addActivePage(state, action);

    case CUSTOMIZE_REMOVE_ACTIVE_PAGE:
      return removeActivePage(state, action);

    case `${CUSTOMIZE_UPDATE_COVER}_REQUEST`:
      return updateCoverRequest(state, action);
    case `${CUSTOMIZE_UPDATE_COVER}_RESOLVED`:
      return updateCoverResolved(state, action);
    case `${CUSTOMIZE_UPDATE_COVER}_REJECTED`:
      return updateCoverRejected(state, action);

    case `${CUSTOMIZE_UPDATE_FONT}_REQUEST`:
      return updateFontRequest(state, action);
    case `${CUSTOMIZE_UPDATE_FONT}_RESOLVED`:
      return updateFontResolved(state, action);
    case `${CUSTOMIZE_UPDATE_FONT}_REJECTED`:
      return updateFontRejected(state, action);

    case `${CUSTOMIZE_UPDATE_LANGUAGE}_REQUEST`:
      return updateLanguageRequest(state, action);
    case `${CUSTOMIZE_UPDATE_LANGUAGE}_RESOLVED`:
      return updateLanguageResolved(state, action);
    case `${CUSTOMIZE_UPDATE_LANGUAGE}_REJECTED`:
      return updateLanguageRejected(state, action);

    case `${CUSTOMIZE_UPDATE_ILLUSTRATION}_REQUEST`:
      return updateIllustrationRequest(state, action);
    case `${CUSTOMIZE_UPDATE_ILLUSTRATION}_RESOLVED`:
      return updateIllustrationResolved(state, action);
    case `${CUSTOMIZE_UPDATE_ILLUSTRATION}_REJECTED`:
      return updateIllustrationRejected(state, action);

    case `${CUSTOMIZE_UPDATE_LAYOUT}_REQUEST`:
      return updateLayoutRequest(state, action);
    case `${CUSTOMIZE_UPDATE_LAYOUT}_RESOLVED`:
      return updateLayoutResolved(state, action);
    case `${CUSTOMIZE_UPDATE_LAYOUT}_REJECTED`:
      return updateLayoutRejected(state, action);

    case `${CUSTOMIZE_SELECT_TEMPLATE}_REQUEST`:
      return selectTemplateRequest(state, action);
    case `${CUSTOMIZE_SELECT_TEMPLATE}_RESOLVED`:
      return selectTemplateResolved(state, action);
    case `${CUSTOMIZE_SELECT_TEMPLATE}_REJECTED`:
      return selectTemplateRejected(state, action);

    case `${CUSTOMIZE_UPDATE_TEMPLATE}_REQUEST`:
      return updateTemplateRequest(state, action);
    case `${CUSTOMIZE_UPDATE_TEMPLATE}_RESOLVED`:
      return updateTemplateResolved(state, action);
    case `${CUSTOMIZE_UPDATE_TEMPLATE}_REJECTED`:
      return updateTemplateRejected(state, action);

    case `${CUSTOMIZE_SAVE_TEMPLATE}_REQUEST`:
      return saveTemplateRequest(state, action);
    case `${CUSTOMIZE_SAVE_TEMPLATE}_RESOLVED`:
      return saveTemplateResolved(state, action);
    case `${CUSTOMIZE_SAVE_TEMPLATE}_REJECTED`:
      return saveTemplateRejected(state, action);

    case `${CUSTOMIZE_REMOVE_TEMPLATE}_REQUEST`:
      return removeTemplateRequest(state, action);
    case `${CUSTOMIZE_REMOVE_TEMPLATE}_RESOLVED`:
      return removeTemplateResolved(state, action);
    case `${CUSTOMIZE_REMOVE_TEMPLATE}_REJECTED`:
      return removeTemplateRejected(state, action);

    case `${CUSTOMIZE_CLEAR_TEMPLATE}_REQUEST`:
      return clearTemplateRequest(state, action);
    case `${CUSTOMIZE_CLEAR_TEMPLATE}_RESOLVED`:
      return clearTemplateResolved(state, action);
    case `${CUSTOMIZE_CLEAR_TEMPLATE}_REJECTED`:
      return clearTemplateRejected(state, action);

    case `${CUSTOMIZE_UPDATE_THEME}_REQUEST`:
      return updateThemeRequest(state, action);
    case `${CUSTOMIZE_UPDATE_THEME}_RESOLVED`:
      return updateThemeResolved(state, action);
    case `${CUSTOMIZE_UPDATE_THEME}_REJECTED`:
      return updateThemeRejected(state, action);

    case `${CUSTOMIZE_UPDATE_HEADLINE}_REQUEST`:
      return updateHeadlineRequest(state, action);
    case `${CUSTOMIZE_UPDATE_HEADLINE}_RESOLVED`:
      return updateHeadlineResolved(state, action);
    case `${CUSTOMIZE_UPDATE_HEADLINE}_REJECTED`:
      return updateHeadlineRejected(state, action);

    case `${CUSTOMIZE_UPDATE_TITLE}_REQUEST`:
      return updateTitleRequest(state, action);
    case `${CUSTOMIZE_UPDATE_TITLE}_RESOLVED`:
      return updateTitleResolved(state, action);
    case `${CUSTOMIZE_UPDATE_TITLE}_REJECTED`:
      return updateTitleRejected(state, action);

    case `${CUSTOMIZE_UPDATE_DESCRIPTION}_REQUEST`:
      return updateDescriptionRequest(state, action);
    case `${CUSTOMIZE_UPDATE_DESCRIPTION}_RESOLVED`:
      return updateDescriptionResolved(state, action);
    case `${CUSTOMIZE_UPDATE_DESCRIPTION}_REJECTED`:
      return updateDescriptionRejected(state, action);

    case `${CUSTOMIZE_ADD_CUSTOM_PAGE}_REQUEST`:
      return addCustomPageRequest(state, action);
    case `${CUSTOMIZE_ADD_CUSTOM_PAGE}_RESOLVED`:
      return addCustomPageResolved(state, action);
    case `${CUSTOMIZE_ADD_CUSTOM_PAGE}_REJECTED`:
      return addCustomPageRejected(state, action);

    case `${CUSTOMIZE_EDIT_CUSTOM_PAGE}_REQUEST`:
      return editCustomPageRequest(state, action);
    case `${CUSTOMIZE_EDIT_CUSTOM_PAGE}_RESOLVED`:
      return editCustomPageResolved(state, action);
    case `${CUSTOMIZE_EDIT_CUSTOM_PAGE}_REJECTED`:
      return editCustomPageRejected(state, action);

    case `${CUSTOMIZE_RENAME_CUSTOM_PAGE}_REQUEST`:
      return renameCustomPageRequest(state, action);
    case `${CUSTOMIZE_RENAME_CUSTOM_PAGE}_RESOLVED`:
      return renameCustomPageResolved(state, action);
    case `${CUSTOMIZE_RENAME_CUSTOM_PAGE}_REJECTED`:
      return renameCustomPageRejected(state, action);

    case `${CUSTOMIZE_REMOVE_CUSTOM_PAGE}_REQUEST`:
      return removeCustomPageRequest(state, action);
    case `${CUSTOMIZE_REMOVE_CUSTOM_PAGE}_RESOLVED`:
      return removeCustomPageResolved(state, action);
    case `${CUSTOMIZE_REMOVE_CUSTOM_PAGE}_REJECTED`:
      return removeCustomPageRejected(state, action);

    case `${CUSTOMIZE_SAVE_CUSTOM_PAGE}_REQUEST`:
      return saveCustomPageRequest(state, action);
    case `${CUSTOMIZE_SAVE_CUSTOM_PAGE}_RESOLVED`:
      return saveCustomPageResolved(state, action);
    case `${CUSTOMIZE_SAVE_CUSTOM_PAGE}_REJECTED`:
      return saveCustomPageRejected(state, action);

    case `${CUSTOMIZE_ADD_CUSTOM_PDF_PAGE}_REQUEST`:
      return addCustomPDFPageRequest(state, action);
    case `${CUSTOMIZE_ADD_CUSTOM_PDF_PAGE}_RESOLVED`:
      return addCustomPDFPageResolved(state, action);
    case `${CUSTOMIZE_ADD_CUSTOM_PDF_PAGE}_REJECTED`:
      return addCustomPDFPageRejected(state, action);

    case `${CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE}_REQUEST`:
      return removeCustomPDFPageRequest(state, action);
    case `${CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE}_RESOLVED`:
      return removeCustomPDFPageResolved(state, action);
    case `${CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE}_REJECTED`:
      return removeCustomPDFPageRejected(state, action);

    case `${ORDER_NEW}_RESOLVED`:
      return orderResolved(state, action);

    case CUSTOMIZE_PAGE_META:
      return updateMeta(state, action);

    default:
      return state;
  }
}
