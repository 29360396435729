import { connect } from "react-redux";
import CustomizeControlsCover from "../CustomizeControlsCover";

function select({ customize: { cover } }) {
  return {
    cover
  };
}

const actions = {};

export default connect(select, actions)(CustomizeControlsCover);
