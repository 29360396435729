import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 60rem;
  padding-bottom: 0;
`;

export const Body = styled.div`
  margin: 0 -2rem;
  padding: 0 2rem 2rem;
  max-height: 50vh;
  overflow-x: hidden;
  overflow-y: auto;

  ${({ theme }) => theme.media.sm`
    max-height: 100%;
  `}
`;

export const Default = styled.button`
  margin-bottom: 1rem;
  padding: 0;
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  cursor: pointer;
  appearance: none;
`;

export const Font = styled.button`
  margin-bottom: 1rem;
  padding: 2rem;
  text-align: left;
  border-radius: 0.4rem;
  border: 0.2rem solid
    ${({ theme, isActive }) =>
      isActive
        ? theme.styleGuide.colors.cma[500]
        : theme.styleGuide.colors.gray[500]};

  &:last-child {
    margin-bottom: 0;
  }
`;

export const Header = styled.span`
  display: block;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`;

export const Text = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;
