import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return { ...state, discount: requestState(state.discount) };
}

export function resolved(state, action) {
  const { amount } = action.payload;

  return {
    ...state,
    amount: Number(amount),
    discount: resolvedState(state.discount)
  };
}

export function rejected(state, action) {
  return {
    ...state,
    discount: rejectedState(state.discount, action.payload.error)
  };
}
