import styled from "styled-components";

export const Counter = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  color: #6b7280;
`;

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 1.2rem;
`;

export const Error = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};
`;
