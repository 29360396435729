export function getFrequencyFriendlyName(frequency, inactive = false) {
  if (inactive) return "Inactive";

  switch (frequency) {
    case null:
      return "Not Set Up";
    case "pending_opt_in":
      return "Pending";
    case "monthly":
      return "Monthly";
    case "quarterly":
      return "Quarterly";
    case "semi-annually":
      return "Semi-Annually";
    case "annually":
      return "Annually";
    case "never":
      return "Never";
    case "none":
    case "":
      return "Unsubscribed";
    default:
      console.warn(
        `Automation frequency [${frequency}] is not supported for [getFrequencyFriendlyName].`
      );
      return frequency;
  }
}

export function getFrequencyFriendlyNameShort(frequency, inactive = false) {
  if (inactive) return "Inactive";

  switch (frequency) {
    case null:
      return "Not Set Up";
    case "pending_opt_in":
      return "Pending";
    case "monthly":
      return "Monthly";
    case "quarterly":
      return "Quarterly";
    case "semi-annually":
      return "Semi-Annual";
    case "annually":
      return "Annual";
    case "never":
    case "none":
    case "":
      return "Unsubscribed";
    default:
      console.warn(
        `Automation frequency [${frequency}] is not supported for [getFrequencyFriendlyNameShort].`
      );
      return frequency;
  }
}

export function getFrequencyFriendlyLabel(frequency) {
  switch (frequency) {
    case "monthly":
      return "every month";
    case "quarterly":
      return "every quarter";
    case "semi-annually":
      return "every 6 months";
    case "annually":
      return "every year";
    case "never":
    case "none":
    case "":
      return "";
    default:
      console.warn(
        `Automation frequency [${frequency}] is not supported for [getFrequencyFriendlyName].`
      );
      return frequency;
  }
}

export function getFrequencyInMonths(frequency) {
  switch (frequency) {
    case "monthly":
      return 1;
    case "quarterly":
      return 3;
    case "semi-annually":
      return 6;
    case "annually":
      return 12;
    default:
      console.warn(
        `Automation frequency [${frequency}] is not supported for [getFrequencyInMonths].`
      );
      return 1;
  }
}

export function getFrequencyColors(frequency, inactive) {
  if (inactive) return { backgroundColor: "#E5E7EB", color: "#1F2937" };

  switch (frequency) {
    case null:
    case "never":
    case "none":
    case "":
      return { backgroundColor: "#FEE2E2", color: "#7C2D12" };

    case "monthly":
    case "quarterly":
    case "semi-annually":
    case "annually":
      return { backgroundColor: "#E5E7EB", color: "#1F2937" };

    case "pending_opt_in":
      return { backgroundColor: "#FFEDD5", color: "#7C2D12" };

    default:
      console.warn(
        `Automation frequency [${frequency}] is not supported for [getFrequencyColor].`
      );
      return frequency;
  }
}

export function getFrequencyOption(frequencyValue) {
  return frequencies.find((frequency) => {
    if (frequencyValue === "none") frequencyValue = "never";
    return frequency.value === frequencyValue;
  });
}

export const frequencies = [
  { label: "Monthly", value: "monthly" },
  { label: "Quarterly", value: "quarterly" },
  { label: "Semi-Annually", value: "semi-annually" },
  { label: "Annually", value: "annually" },
  { label: "Never", value: "never" }
];
