import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state, action) {
  const { id, dateId } = action.payload;
  const net_sheet_items = [...state.net_sheet_items].map((item) => {
    if (item.id === id || item.id === dateId) {
      item = requestState(action.payload);
    }

    return item;
  });

  return { ...state, net_sheet_items, filter: "net-sheet" };
}

export function resolved(state, action) {
  const { id, dateId } = action.payload;
  const net_sheet_items = [...state.net_sheet_items].map((item) => {
    if (item.id === id || item.id === dateId) {
      item = resolvedState(action.payload);
    }

    return item;
  });

  return { ...state, net_sheet_items, filter: "" };
}

export function rejected(state, action) {
  const { id, dateId } = action.payload;
  const net_sheet_items = [...state.net_sheet_items].map((item) => {
    if (item.id === id || item.id === dateId) {
      item = rejectedState(item, action.payload.error);
    }

    return item;
  });

  return { ...state, net_sheet_items, filter: "" };
}
