import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "@wrstudios/components";
import { updateTitle } from "../../actions/customize";
import Label from "../common/Label";
import { Select, Option } from "../common/Select";
import { ModalContent } from "./styled/customize-modal-title";

function CustomizeModalTitle({ reportId, title, updateTitle, onClose }) {
  let { key: value } = title.selected;

  if (!value) {
    value = "just_listed";
  }

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Title
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <Label htmlFor="title">Choose Title</Label>
          <Select
            id="title"
            value={value}
            onChange={(e) => {
              const newTitle = title.all.find(
                (title) => title.key === e.target.value
              );
              updateTitle(reportId, newTitle);
              onClose();
            }}>
            {title.all.map((title) => (
              <Option
                key={title.key}
                value={title.key}
                data-title={title.title}>
                {title.title}
              </Option>
            ))}
          </Select>
        </Modal.Body>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalTitle.propTypes = {
  reportId: PropTypes.number.isRequired,
  title: PropTypes.shape({
    selected: PropTypes.shape({
      title: PropTypes.string,
      key: PropTypes.string
    })
  }).isRequired,
  updateTitle: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  title: state.customize.title
});

const mapDispatchToProps = {
  updateTitle
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalTitle);
