import styled from "styled-components";
import { default as BaseHomebeatViewerListing } from "../HomebeatViewerListing";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.overlay};
  transform: translateX(-110%);
  transition: transform 0.2s;
  position: fixed;
  top: 6rem;
  bottom: 0;
  left: 0;
  z-index: 100;

  a {
    text-decoration: none;
  }
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.2rem;
`;

export const Close = styled.button`
  width: 4.8rem;
  height: 4.8rem;
  margin: -1.2rem;
  padding: 0;
  background-color: transparent;
  border: 0;
  cursor: pointer;
  appearance: none;

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;

export const HomebeatViewerListing = styled(BaseHomebeatViewerListing)`
  width: 34.6rem;
  max-width: 100%;
  height: 100%;
`;
