import React from "react";
import { Container, Item } from "./styled/fab";

function FAB(props) {
  return <Container {...props} />;
}

function FABItem(props) {
  return <Item {...props} />;
}

FAB.Item = FABItem;

export default FAB;
