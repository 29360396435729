import { sort as timSort } from "timsort";
import { createSelector } from "reselect";
import { uniqBy } from "lodash";
import { average, median } from "../utils/math";
import { stripNumber } from "../utils/number";

// Data from the store.
const listings = (state) => state.listings.all;
const sortOptions = (state) => state.listings.sortOptions;

/**
 * Filter down an array of listings based on type and if it's active
 *
 * @param {Array} listings
 * @param {Array|String} types (active, pending, sold, etc.)
 */
const filteredListings = (listings, types = null) =>
  createSelector(listings, (listings) => {
    let filteredListings = listings.filter((listing) =>
      listing.hasOwnProperty("data")
        ? listing.data.hasOwnProperty("fetched")
          ? listing.data.fetched
          : true
        : false
    );

    if (types !== null) {
      if (!Array.isArray(types)) {
        types = [types];
      }

      filteredListings = filteredListings.filter(
        (listing) =>
          !listing.data.error &&
          listing.status &&
          types.includes(listing.data.mapped_status)
      );
    }

    return filteredListings.filter((listing) =>
      !listing.hide ? listing : false
    );
  });

/**
 * Pulls the low, high, average, and median for a set of data. The data maps
 * to properties on the listings. e.g. price or sqft.
 *
 * @param {Array} listings
 * @param {String} property
 * @param {String} type (active, pending, sold, etc.)
 */
const dataFactory = (listings, property) =>
  createSelector(listings, (listings) => {
    const items = listings
      .map((listing) =>
        stripNumber(listing[property] || listing.data[property])
      )
      .filter(Boolean);
    const low = items.length ? Math.round(Math.min.apply(Math, items)) : 0;
    const high = items.length ? Math.round(Math.max.apply(Math, items)) : 0;
    const avg = average(items);
    const med = median(items);

    return {
      low,
      high,
      average: avg,
      median: med
    };
  });

/**
 * Calculates the price per sqft on a list of listings
 *
 * @param {Array} listings
 */
const ppSqftFactory = (listings) =>
  createSelector(listings, (listings) => {
    let ppsqfts = listings.map((listing) => {
      let ppsqft = listing.price / listing.sqft;

      if (Number.isNaN(ppsqft) || !Number.isFinite(ppsqft)) {
        ppsqft = 0;
      }

      return ppsqft;
    }, 0);

    if (ppsqfts.length) {
      ppsqfts = ppsqfts.reduce((acc, cur) => acc + cur, 0) / ppsqfts.length;
    } else {
      ppsqfts = 0;
    }

    return ppsqfts;
  });

/**
 * Grabs listings that have been fetched with their sort position
 *
 * @param {Array} listings
 */
export const activeListings = createSelector(listings, (listings) => {
  let filteredListings = listings
    .filter((listing) =>
      listing.hasOwnProperty("data")
        ? listing.data.hasOwnProperty("fetched")
          ? listing.data.fetched
          : true
        : false
    )
    .filter((listing) => !(listing.data && listing.data.error))
    .map((listing) => {
      if (!listing.hasOwnProperty("sortPosition")) {
        listing.sortPosition = listing.position;
      }

      return listing;
    });

  timSort(filteredListings, (a, b) => a.sortPosition - b.sortPosition);

  function perimeterPoint(lat, lng, ang, rad) {
    if (!lat || !lng) return [lat, lng];

    const angle = ang * (Math.PI / 180);
    const radius = rad / (6371000 * (Math.PI / 180));
    const scale = Math.cos(Number(lat) * (Math.PI / 180));
    const newLat = Number(lat) + radius * Math.sin(angle);
    const newLng = Number(lng) + (radius * Math.cos(angle)) / scale;

    return [newLat, newLng];
  }

  function declutter(listings) {
    const angleSize = 360 / listings.length;
    const newListings = listings.map((listing, index) => {
      const angle = angleSize * index;
      const [lat, lon] = perimeterPoint(
        listing.geo_lat,
        listing.geo_lon,
        angle,
        1
      );

      return { ...listing, geo_lat: lat, geo_lon: lon };
    });

    return newListings;
  }

  const coordinates = filteredListings.map((listing) => [
    listing.geo_lat,
    listing.geo_lon
  ]);
  const hasDuplicates =
    filteredListings.length >
    uniqBy(coordinates, (item) => JSON.stringify(item)).length;

  if (hasDuplicates) {
    filteredListings = declutter(filteredListings);
  }

  return filteredListings;
});

/**
 * Grabs listings that have been fetched but have errors
 *
 * @param {Array} listings
 */
export const errorListings = createSelector(listings, (listings) => {
  const filteredListings = listings.filter(
    (listing) => listing.data && listing.data.error
  );

  return filteredListings;
});

/**
 * Grabs the sort options of listings
 *
 * @param {Array} sortOptions
 */
export const getSortOptions = createSelector(sortOptions, (sortOptions) => {
  let options = [];

  if (typeof sortOptions !== "undefined") {
    options = [...sortOptions];

    // Inject the manually sort option if not found.
    if (!options.some(([, value]) => value === "manually")) {
      options = [...options, ["Manually", "manually"]];
    }

    timSort(options, (a, b) => {
      if (a[0] > b[0]) return 1;
      if (a[0] < b[0]) return -1;
      return 0;
    });

    options = options.filter(([text, value]) => text.length && value.length);
  }

  return options;
});

/**
 * Selector factory uses.
 */
export const prices = dataFactory(filteredListings(listings), "price");
export const activePrices = dataFactory(
  filteredListings(listings, "active"),
  "price"
);
export const soldPrices = dataFactory(
  filteredListings(listings, ["sold", "closed"]),
  "price"
);

export const sqfts = dataFactory(filteredListings(listings), "sqft");
export const activeSqfts = dataFactory(
  filteredListings(listings, "active"),
  "sqft"
);
export const soldSqfts = dataFactory(
  filteredListings(listings, ["sold", "closed"]),
  "sqft"
);

export const ppSqft = ppSqftFactory(filteredListings(listings));
export const activePpsqft = ppSqftFactory(filteredListings(listings, "active"));
export const soldPpsqft = ppSqftFactory(
  filteredListings(listings, ["sold", "closed"])
);
