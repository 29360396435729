import styled from "styled-components";
import BaseTip from "../../common/Tip";

export const HomebeatWrapper = styled.div`
  min-height: 100vh;
`;

export const Container = styled.div`
  max-width: 60rem;
  margin: 0 auto;
`;

export const NavigationWrapper = styled.div`
  color: #111827;
  width: 100%;
  background: white;
  box-shadow: 0 0 0.2rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 6rem;
  align-items: center;
  padding-left: 3rem;
  padding-right: 9rem;
`;

export const NavigationLinkWrapper = styled.a`
  display: flex;
  flex-direction: row;
  color: #111827;
  gap: 1.4rem;
  text-decoration: none;
  cursor: pointer;
`;

export const NavigationIconWrapper = styled.span`
  width: 0.78rem;
`;

export const NavigationTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 2rem;
  line-height: 2.6rem;
  font-weight: 600;
  flex: 1;
  justify-content: center;
`;

export const Header = styled.div`
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
`;

export const Content = styled.div`
  padding: 3rem;
  text-align: center;
  border-radius: 0.6rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};

  ${({ theme }) => theme.media.sm`
    padding: 2rem;
  `}
`;

export const Text = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  &:last-of-type {
    margin-bottom: 3rem;

    ${({ theme }) => theme.media.sm`
      margin-bottom: 2rem;
    `}
  }

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Control = styled.div`
  margin: 3rem 0;
`;

export const Tip = styled(BaseTip)`
  margin: 0;
`;

export const UploadArea = styled.div`
  display: flex;
  height: 85vh;
  align-items: center;
`;

export const UploadTitle = styled.h1`
  font-weight: 600;
  font-size: 3.6rem;
  line-height: 4.4rem;
  text-align: center;
  color: #000000;
`;

export const BrowseButton = styled.button`
  background-color: #e5e7eb;
  border-radius: 0.6rem;
  padding: 0.4rem 1.6rem;
  text-align: center;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: #111827;
`;

export const UploadAreaWrapper = styled.div`
  flex: 1;
`;

export const UploadAreaContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;
`;

export const UploadIconWrapper = styled.div`
  width: 2.64rem;
  margin: auto;
`;

export const InfoIconWrapper = styled.span`
  flex-shrink: 0;
  width: 2rem;
  color: #4b5563;
`;

export const UploadAreaContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;
  box-shadow: none;
  padding: 2.4rem;
  min-width: 32.4rem;
  max-width: 40.9rem;
  border-radius: 0.8rem;
  border: 0.1rem dashed ${({ theme }) => theme.styleGuide.colors.gray[500]};
  line-height: 2rem;
  color: #111827;
`;

export const UploadAreaInfo = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 1rem;
`;

export const FileUploadError = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 2.4rem;
`;

export const ErrorFileName = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.8rem;
  width: 100%;
  gap: 1rem;
  border-radius: 0.8rem;
  background: #f3f4f6;
  word-break: break-all;
`;

export const ErrorIconWrapper = styled.span`
  flex-shrink: 0;
  width: 2rem;
  color: #b31a1a;
`;

export const Error = styled(Text)`
  margin-top: 3rem;
  text-align: center;
  color: ${({ theme }) => theme.styleGuide.colors.red[500]};
`;

export const Fields = styled.div`
  margin: 3rem 0;
`;

export const FieldDivider = styled.div`
  width: 100%;
  height: 0.1rem;
  margin: 3rem 0;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[500]};
`;

export const ButtonGridContainer = styled.div`
  display: flex;
  justify-content: center;
  margin: 3rem 0;

  ${({ theme }) => theme.media.sm`
    margin: 2rem 0;
  `}
`;

export const ButtonGrid = styled.div`
  display: flex;
  margin: -0.5rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `}
`;

export const ButtonCell = styled.div`
  padding: 0.5rem;
`;
