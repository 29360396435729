import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { reorderContactItems, removeContactItem } from "../../api/user";
import { useSession } from "../../Session";
import IconDelete from "../icons/material/action/IconDelete";
import { Tip, Item, Key, Value, Remove } from "./styled/user-contact-items";

function UserContactItems() {
  const { currentUser, contactItems, setContactItems } = useSession();

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const newContactItems = [...contactItems];
    const [removed] = newContactItems.splice(result.source.index, 1);
    newContactItems.splice(result.destination.index, 0, removed);
    reorderContactItems(currentUser.id, newContactItems);
    setContactItems(newContactItems);
  };

  return (
    <>
      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="user-contact-list-droppable">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {contactItems.map((contactItem, index) => (
                <UserContactItem
                  key={contactItem.id}
                  index={index}
                  id={contactItem.id}
                  itemKey={contactItem.key}
                  prettyKey={contactItem.prettyKey}
                  value={contactItem.value}
                />
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <Tip>
        {!contactItems.length &&
          "No contact items found. Try adding a new contact item above."}
        {contactItems.length === 1 &&
          "Tip: You can reorder contact items when you have two or more items."}
        {contactItems.length > 1 &&
          "Tip: Drag and drop the items in the list to change their order of appearance."}
      </Tip>
    </>
  );
}

function UserContactItem({ index, id, itemKey, prettyKey, value }) {
  const { currentUser, contactItems, setContactItems } = useSession();
  const isDragDisabled = contactItems.length <= 1;

  const handleDelete = () => {
    removeContactItem(currentUser.id, id);
    setContactItems((contactItems) =>
      contactItems.filter((contactItem) => contactItem.id !== id)
    );
  };

  return (
    <Draggable
      draggableId={`contact-item-${id}`}
      index={index}
      isDragDisabled={isDragDisabled}>
      {(provided, snapshot) => (
        <Item
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}>
          <Key>{prettyKey || itemKey}:</Key>
          <Value>{value}</Value>
          <Remove onClick={handleDelete}>
            <IconDelete />
          </Remove>
        </Item>
      )}
    </Draggable>
  );
}

UserContactItem.propTypes = {
  index: PropTypes.number.isRequired,
  id: PropTypes.number.isRequired,
  itemKey: PropTypes.string.isRequired,
  prettyKey: PropTypes.string,
  value: PropTypes.string.isRequired
};

export default UserContactItems;
