import camelcaseKeys from "camelcase-keys";
import api from "./api";

export function addAdjustment(listingId, adjustment) {
  return api
    .post(`/listings/${listingId}`, {
      adjustment: {
        name: adjustment.name,
        value: String(adjustment.value)
      }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateAdjustment(listingId, adjustment) {
  return api
    .put(`/listings/${listingId}/${adjustment.id}`, {
      adjustment: {
        name: adjustment.name,
        value: String(adjustment.value)
      }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function removeAdjustment(listingId, adjustmentId) {
  return api
    .delete(`/listings/${listingId}/${adjustmentId}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateListingNote(reportId, listingId, note) {
  return api
    .put(`/reports/${reportId}/listings/${listingId}`, {
      listing: { note }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}
