import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  margin: -1rem;
  overflow: hidden;

  ${({ theme }) => theme.media.sm`
    flex-wrap: wrap;
  `};
`;

export const Cell = styled.div`
  display: flex;
  width: 50%;
  padding: 1rem;
  color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) => theme.media.sm`
    width: auto;
  `};

  svg {
    width: 11.5rem;
    height: 15rem;
  }
`;
