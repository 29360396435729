import { sort } from "timsort";

/**
 * Obtain the average from a range of numbers
 *
 * @param {Array} arr
 */
export function average(arr) {
  let value = arr.reduce((prev, curr) => (curr += prev), 0) / arr.length;

  if (Number.isNaN(value)) {
    value = 0;
  }

  return value;
}

/**
 * Obtain the median from a range of numbers
 *
 * @param {Array} arr
 */
export function median(arr) {
  if (!arr.length) return 0;

  const values = [...arr];

  sort(values, (a, b) => a - b);

  const half = Math.floor(values.length / 2);
  let value =
    values.length % 2 ? values[half] : (values[half - 1] + values[half]) / 2;

  if (Number.isNaN(value)) {
    value = 0;
  }

  return value;
}

export const getDistanceBetweenTwoLatLngPoints = (
  latlngA,
  latlngB,
  isMiles = true
) => {
  const toRadian = (angle) => (Math.PI / 180) * angle;
  const distance = (a, b) => (Math.PI / 180) * (a - b);
  const RADIUS_OF_EARTH_IN_KM = 6371;

  let lat1 = Number(latlngA[0]);
  let lat2 = Number(latlngB[0]);
  const lon1 = Number(latlngA[1]);
  const lon2 = Number(latlngB[1]);

  if (!lat1 || !lat2 || !lon1 || !lon2) return 0;

  const dLat = distance(lat2, lat1);
  const dLon = distance(lon2, lon1);

  lat1 = toRadian(lat1);
  lat2 = toRadian(lat2);

  // Haversine Formula
  const a =
    Math.pow(Math.sin(dLat / 2), 2) +
    Math.pow(Math.sin(dLon / 2), 2) * Math.cos(lat1) * Math.cos(lat2);
  const c = 2 * Math.asin(Math.sqrt(a));

  let finalDistance = RADIUS_OF_EARTH_IN_KM * c;

  if (isMiles) {
    finalDistance /= 1.60934;
  }

  return finalDistance;
};
