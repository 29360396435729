import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

const templateKey = "My Custom Templates";

let allTemplatesBeforeModification = {};
let selectedBeforeModification = {};

export function request(state, action) {
  allTemplatesBeforeModification = state.template.all;
  selectedBeforeModification = state.selected;

  return {
    ...state,
    template: requestState({
      ...state.template,
      all: {
        ...state.template.all,
        [templateKey]: state.template.all[templateKey].filter(
          ([, key]) => key != action.payload.template.key
        )
      },
      selected: {
        key: "default",
        title: "Default Template"
      },
      load: false,
      remove: true
    })
  };
}

export function resolved(state) {
  return {
    ...state,
    template: resolvedState({
      ...state.template,
      selected: {
        key: "default",
        title: "Default Template"
      },
      load: false,
      isModified: false
    })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    template: rejectedState(
      {
        ...state.template,
        all: allTemplatesBeforeModification,
        selected: selectedBeforeModification,
        remove: false
      },
      action.payload.error
    )
  };
}
