import React, { useState, useEffect } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import { useQueryString } from "../../utils/url";
import { useSession } from "../../Session";
import Wrapper from "../common/Wrapper";
import Search from "../common/Search";
import CardCreate from "../common/CardCreate";
import GettingStarted from "../common/CardGettingStarted";
import { ReportCardSkeleton } from "../common/ReportCard";
import Notifications from "../common/Notifications";
import { GET_CMAS } from "./queries";
import CMACard from "./CMACard";
import { Container, Header, Grid, Cell, Title, Paginate } from "./styled/cmas";

function CMAs() {
  const { features, config } = useSession();
  const { params, removeTimestampParam } = useQueryString();
  const [search, setSearch] = useState(params.q || "");
  const [currentPage, setCurrentPage] = useState(params.page || 1);
  const [getCMAS, { data, loading }] = useLazyQuery(GET_CMAS, {
    fetchPolicy: "cache-and-network"
  });
  const meta = get(data, "cmas.meta", {});
  const cmas = get(data, "cmas.results", []);
  const showOnboardingVideo = !loading && meta.totalResults < 5;
  const showLeadVideo = !loading && meta.totalResults < 4;

  useEffect(() => {
    getCMAS({
      variables: { page: Number(currentPage || 1), search, ts: params.ts || "" }
    });
  }, [currentPage, search]);

  useEffect(() => {
    if (params.ts) {
      removeTimestampParam();
    }
  }, []);

  return (
    <>
      <Notifications />
      <Wrapper>
        <Container>
          <Header>
            <Title>CMA Reports</Title>
            <Search
              defaultValue={search}
              placeholder="Search CMAs"
              onSearch={(searchTerm) => {
                setSearch(searchTerm);
                setCurrentPage(1);
              }}
              onClear={() => {
                setSearch("");
                setCurrentPage(1);
              }}
            />
          </Header>
          <Grid>
            <Cell>
              <CardCreate as="a" href="/cmas/new">
                Create CMA Report
              </CardCreate>
            </Cell>
            {loading &&
              new Array(5).fill().map((_, index) => (
                <Cell key={index}>
                  <ReportCardSkeleton />
                </Cell>
              ))}
            {!loading &&
              cmas.map((cma) => (
                <Cell key={cma.id}>
                  <CMACard
                    id={cma.id}
                    guid={cma.guid}
                    title={cma.title}
                    type={cma.type}
                    status={cma.status}
                    pdfLink={cma.pdfLink}
                    notes={cma.notes}
                    thumbnail={cma.thumbnail}
                    createdWith={cma.createdWith}
                    address={cma.addressParts[0]}
                    cityStateZip={cma.addressParts[1]}
                    beds={cma.subjectProperty ? cma.subjectProperty.beds : null}
                    baths={
                      cma.subjectProperty ? cma.subjectProperty.baths : null
                    }
                    sqft={cma.subjectProperty ? cma.subjectProperty.sqft : null}
                    lotSize={
                      cma.subjectProperty ? cma.subjectProperty.lotSize : null
                    }
                    garages={
                      cma.subjectProperty ? cma.subjectProperty.garages : null
                    }
                    propertyType={cma.propertyType}
                    propertySubType={cma.propertySubType}
                    viewCount={cma.eventSummary.viewCount}
                    lastViewedAt={cma.eventSummary.lastViewedAt}
                    updatedAt={cma.updatedAt}
                    investorOffer={cma.investorOffer}
                    transaction={cma.transaction}
                    refetchQuery={GET_CMAS}
                    isLiveEnabled={features.hasLive}
                    isSlideshowEnabled={features.hasPresent}
                  />
                </Cell>
              ))}
            {showOnboardingVideo && (
              <Cell>
                <GettingStarted
                  videoTitle="Getting Started with CMAs"
                  videoId={config.cmaKickStartVideoId}>
                  Getting Started with CMAs
                </GettingStarted>
              </Cell>
            )}
            {showLeadVideo && (
              <Cell>
                <GettingStarted
                  videoTitle="Generate Leads with CMA"
                  videoId="sa0m3cux0z">
                  Generate Leads with CMA
                </GettingStarted>
              </Cell>
            )}
          </Grid>
          {!loading && (
            <Paginate
              scrollToTopOnPageChange
              currentPage={meta.currentPage}
              totalPages={meta.totalPages}
              perPage={meta.perPage}
              totalResults={meta.totalResults}
              onPageChange={setCurrentPage}
            />
          )}
        </Container>
      </Wrapper>
    </>
  );
}

export default CMAs;
