import React from "react";

function IconStarFilled() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
      <path d="M14.4 9L12 4.8v-.6L9 8.4 3 9.6l4.2 4.8-.6 6 5.4-3 5.4 1.8-.6-5.4 3.6-4.2-6-.6z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2.1a.9.9 0 01.807.502l2.612 5.292 5.84.849a.9.9 0 01.5 1.535l-4.227 4.12.998 5.816a.9.9 0 01-1.306.95L12 18.416l-5.224 2.746a.9.9 0 01-1.306-.949l.998-5.816-4.226-4.12a.9.9 0 01.498-1.535l5.84-.849 2.613-5.292A.9.9 0 0112 2.1zm0 2.934L9.986 9.115a.9.9 0 01-.678.492l-4.504.655 3.259 3.177a.9.9 0 01.259.796l-.77 4.486 4.03-2.118a.9.9 0 01.837 0l4.028 2.118-.77-4.486a.9.9 0 01.26-.796l3.259-3.177-4.504-.655a.9.9 0 01-.678-.492L12 5.034z"
      />
    </svg>
  );
}

export default IconStarFilled;
