export const LISTINGS_RESET_LISTING_REQUEST = "LISTINGS_RESET_LISTING_REQUEST";
export const LISTINGS_SORT = "LISTINGS_SORT";
export const LISTINGS_TOGGLE = "LISTINGS_TOGGLE";
export const LISTINGS_REORDER = "LISTINGS_REORDER";
export const LISTINGS_ADD = "LISTINGS_ADD";
export const LISTINGS_UPDATE = "LISTINGS_UPDATE";
export const LISTINGS_REMOVE = "LISTINGS_REMOVE";
export const LISTINGS_GET = "LISTINGS_GET";
export const LISTINGS_ADD_ADJUSTMENT = "LISTINGS_ADD_ADJUSTMENT";
export const LISTINGS_REMOVE_ADJUSTMENT = "LISTINGS_REMOVE_ADJUSTMENT";
export const LISTINGS_UPDATE_ADJUSTMENT = "LISTINGS_UPDATE_ADJUSTMENT";
export const LISTINGS_UPDATE_DESCRIPTION = "LISTINGS_UPDATE_DESCRIPTION";
export const LISTINGS_UPDATE_NOTE = "LISTINGS_UPDATE_NOTE";
