export const MAX_NOTE_LENGTH = 420;

export function load(storageKey, fallbackValue) {
  const item = window.localStorage.getItem(storageKey);
  if (item) return JSON.parse(item);
  return fallbackValue;
}

export function save(storageKey, value) {
  window.localStorage.setItem(storageKey, JSON.stringify(value));
}

export function useStorage(storageKey) {
  return {
    load: (fallbackValue) => load(storageKey, fallbackValue),
    save: (value) => save(storageKey, value)
  };
}
