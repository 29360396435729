import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return requestState({ ...state, filter: "net-sheet" });
}

export function resolved(state, action) {
  const { startIndex, endIndex } = action.payload;
  const net_sheet_items = [...state.net_sheet_items];
  const [removed] = net_sheet_items.splice(startIndex, 1);

  net_sheet_items.splice(endIndex, 0, removed);

  return resolvedState({ ...state, net_sheet_items, filter: "" });
}

export function rejected(state, action) {
  return rejectedState({ ...state, filter: "" }, action.payload.error);
}
