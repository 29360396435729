import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { IconTrashCan } from "@wrstudios/icons";
import { removeNetSheetItem } from "../../actions/subject-property";
import AlertDanger from "../common/AlertDanger";
import { Container } from "./styled/net-sheet-list-item-remove";

function NetSheetListItemRemove({ subjectProperty, item, removeNetSheetItem }) {
  const [showAlert, setShowAlert] = useState(false);

  return (
    <>
      <Container type="button" onClick={() => setShowAlert(true)}>
        <IconTrashCan />
      </Container>
      {showAlert && (
        <AlertDanger
          title="Delete Net Sheet Item"
          confirmButton="Delete Forever"
          onClose={() => setShowAlert(false)}
          onConfirm={() => {
            removeNetSheetItem(subjectProperty.id, item.id);
            setShowAlert(false);
          }}>
          Are you sure you want to remove adjustment:{" "}
          <strong>{item.key}</strong>?
        </AlertDanger>
      )}
    </>
  );
}

NetSheetListItemRemove.propTypes = {
  subjectPropertyId: PropTypes.shape({
    id: PropTypes.number.isRequired
  }),
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired
  }),
  removeNetSheetItem: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  subjectProperty: state.subjectProperty
});

const mapDispatchToProps = {
  removeNetSheetItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetSheetListItemRemove);
