import styled, { keyframes } from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { default as BaseInput } from "../../../common/Input";
import { default as BaseMapStatic } from "../../../common/MapStatic";
import BaseHomebeatViewerButton from "../HomebeatViewerButton";

const teeter = keyframes`
  0% { transform: rotate(10deg); }
  50% { transform: rotate(-10deg); }
  100% { transform: rotate(0deg); }
`;

const slide = keyframes`
  0% { transform: translate(2%); }
  50% { transform: translate(-2%); }
  100% { transform: translate(2%); }
`;

export const Modal = styled(BaseModal)`
  justify-content: flex-end;
  padding: 0;
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 44.2rem;
  height: 100%;
  padding: 0;
  border-radius: 0;
  transform: translateX(110%);
  transition: transform 0.2s;
  position: relative;

  .address-auto-complete-input {
    height: 3.6rem;
    padding: 0.8rem 1.2rem;
    border-width: 0.1rem;

    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.homebeat.colors.primary};
    }
  }

  .address-auto-complete-menu {
    border-width: 0.1rem;
  }

  .is-highlighted {
    color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const ModalHeader = styled(BaseModal.Header)`
  font-weight: 600;
  padding: 3.2rem 3.2rem 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const ModalClose = styled(BaseModal.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const ModalBody = styled(BaseModal.Body)`
  margin: 0;
  padding: 0 3.2rem 3.2rem;
`;

export const ModalFooter = styled(BaseModal.Footer)`
  margin: 0;
  padding: 2.4rem 3.2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
`;

export const Guide = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: auto;
  padding: 1.6rem;
`;

export const GuideIllustration = styled.div`
  .animated {
    transform-origin: center;
    animation: ${teeter} 2.5s forwards;
  }

  .shadow {
    transform-origin: center;
    animation: ${slide} 2.5s forwards;
  }

  svg {
    width: 14.8rem;
    height: 8.8rem;
  }
`;

export const GuideText = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.6rem 0;

  text-align: center;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const MapStatic = styled(BaseMapStatic)`
  position: absolute;
`;

export const Button = styled(BaseHomebeatViewerButton)`
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    fill: currentColor;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem;
  overflow: hidden;
`;

export const Cell = styled.div`
  padding: 0.8rem;
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 0 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `};
`;

export const Input = styled(BaseInput)`
  padding: 0.8rem 1.2rem;
  border-width: 0.1rem;
  color: ${({ theme, hasTaxData }) =>
    hasTaxData && theme.styleGuide.colors.purple[500]};

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const Error = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.2rem 0 0;
  color: ${({ theme }) => theme.styleGuide.colors.red[500]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;
