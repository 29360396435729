import React from "react";
import Icon from "./Icon";

function IconDocument(props) {
  return (
    <Icon {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-4-2h24v24H-4z" />
        <path
          fill="currentColor"
          d="M10 0H2C.9 0 .01.9.01 2L0 18c0 1.1.89 2 1.99 2H14c1.1 0 2-.9 2-2V6l-6-6zm2 16H4v-2h8v2zm0-4H4v-2h8v2zM9 7V1.5L14.5 7H9z"
        />
      </g>
    </Icon>
  );
}

export default IconDocument;
