import styled from "styled-components";
import { default as BaseDatePicker } from "react-datepicker";
import IconChevronLeft from "../images/IconChevronLeft";
import IconChevronRight from "../images/IconChevronRight";

export const Container = styled.div`
  position: relative;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    transform: translateY(-50%);
    position: absolute;
    top: 50%;
    right: 0.8rem;
    z-index: 1;
  }

  .react-datepicker {
    padding: 1rem;
    background-color: ${({ theme }) => theme.styleGuide.colors.white};
    border-radius: 0.5rem;
    border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
    box-shadow: ${({ theme }) => theme.styleGuide.shadows.overlay};

    &-popper {
      z-index: 20;

      &[data-placement="top-start"] .react-datepicker__triangle {
        bottom: -1.1rem;

        &:before,
        &:after {
          bottom: 0;
        }

        &:after {
          bottom: 0.1rem;
        }
      }

      &[data-placement="bottom-start"] .react-datepicker__triangle {
        top: 0.1rem;

        &:before,
        &:after {
          top: 0;
        }

        &:after {
          top: 0.1rem;
        }
      }
    }

    &__triangle {
      position: absolute;
      left: 2rem;

      &:before,
      &:after {
        content: "";
        width: 1.2rem;
        height: 1.2rem;
        background-color: ${({ theme }) => theme.styleGuide.colors.white};
        transform: translate(-0.25rem, -0.7rem) rotate(45deg);
        position: absolute;
      }

      &:before {
        border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
        border-top-left-radius: 0.2rem;
        z-index: 1;
      }

      &:after {
        z-index: 2;
      }
    }

    &__navigation {
      width: 2.4rem;
      height: 2.4rem;
      overflow: hidden;
      color: transparent;
      background: center center no-repeat;
      position: absolute;
      top: 1.9rem;

      &--previous {
        background-image: url("${IconChevronLeft}");
        left: 1rem;
      }

      &--next {
        background-image: url("${IconChevronRight}");
        right: 1rem;
      }
    }

    &__day-names,
    &__week {
      display: flex;
      align-items: center;
    }

    &__current-month {
      font-weight: 700;
      padding: 1rem 0 1rem;
      text-align: center;
    }

    &__day-name,
    &__day {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3.5rem;
      height: 3.5rem;
      border-radius: 0.2rem;
    }

    &__day-name {
      font-weight: 700;
    }

    &__day {
      cursor: pointer;

      &:hover,
      &:focus,
      &--selected {
        background-color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
        color: ${({ theme }) => theme.styleGuide.colors.white};
      }

      &--outside-month {
        opacity: 0.5;

        &:hover,
        &:focus {
          opacity: 1;
        }
      }
    }

    &__input-container {
      position: relative;
      z-index: 1;
    }
  }
`;

export const DatePicker = styled(BaseDatePicker)`
 display: block;
  width: ${({ isFullWidth }) => isFullWidth && "100%"};
  font-size: 1.4rem;
  line-height: 2rem;
  min-width: 0;
  padding: 0.7rem 1.2rem;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  appearance: none;
  outline: none;
  position: relative;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  }

  &::placeholder {
    color: ${({ theme }) => theme.styleGuide.colors.gray[600]};
  }
}
`;
