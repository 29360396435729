import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../../Icon";

function IconLayoutFlyer2(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#c4c8d0"
            d="M184.74 148.73h23.59v3h-23.59zM91.85 148.73h79.98v3H91.85zM91.85 167.14h116.48v3H91.85zM91.85 130.38h112.47v3H91.85zM91.85 202.72h23.59v3H91.85zM128.35 202.72h73.98v3h-73.98zM91.85 221.13h116.48v3H91.85zM91.85 184.37h116.48v3H91.85zM184.74 255.21h23.59v3h-23.59zM91.85 255.21h79.98v3H91.85zM91.85 273.62h109.47v3H91.85zM91.85 236.87h107.47v3H91.85zM26.44 23.75h36.83v4H26.44zM83.43 23.75h124.9v4H83.43zM33.49 129.35h36.83v4H33.49zM30.05 149.14h43.71v4H30.05zM38.53 166.66h26.75v4H38.53z"
          />
          <rect
            fill="#e1e5e9"
            x="28.3"
            y="43.39"
            width="80.27"
            height="69.6"
            rx="4.92"
            ry="4.92"
          />
          <path
            fill="#576474"
            d="M103.64 114.49H33.22a6.43 6.43 0 0 1-6.42-6.43V48.31a6.43 6.43 0 0 1 6.42-6.42h70.42a6.44 6.44 0 0 1 6.43 6.42v59.75a6.44 6.44 0 0 1-6.43 6.43zm-70.42-69.6a3.42 3.42 0 0 0-3.42 3.42v59.75a3.43 3.43 0 0 0 3.42 3.43h70.42a3.44 3.44 0 0 0 3.43-3.43V48.31a3.43 3.43 0 0 0-3.43-3.42z"
          />
          <rect
            fill="#e1e5e9"
            x="122.47"
            y="43.39"
            width="80.27"
            height="69.6"
            rx="4.92"
            ry="4.92"
          />
          <path
            fill="#576474"
            d="M197.81 114.49h-70.42a6.43 6.43 0 0 1-6.42-6.43V48.31a6.43 6.43 0 0 1 6.42-6.42h70.42a6.44 6.44 0 0 1 6.43 6.42v59.75a6.44 6.44 0 0 1-6.43 6.43zm-70.42-69.6a3.42 3.42 0 0 0-3.39 3.42v59.75a3.43 3.43 0 0 0 3.42 3.43h70.42a3.44 3.44 0 0 0 3.43-3.43V48.31a3.43 3.43 0 0 0-3.43-3.42z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#20b575"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#20b575"
            d="M184.74 148.73h23.59v3h-23.59zM91.85 148.73h79.98v3H91.85zM91.85 167.14h116.48v3H91.85zM91.85 130.38h112.47v3H91.85zM91.85 202.72h23.59v3H91.85zM128.35 202.72h73.98v3h-73.98zM91.85 221.13h116.48v3H91.85zM91.85 184.37h116.48v3H91.85zM184.74 255.21h23.59v3h-23.59zM91.85 255.21h79.98v3H91.85zM91.85 273.62h109.47v3H91.85zM91.85 236.87h107.47v3H91.85zM26.44 23.75h36.83v4H26.44zM83.43 23.75h124.9v4H83.43zM33.49 129.35h36.83v4H33.49zM30.05 149.14h43.71v4H30.05zM38.53 166.66h26.75v4H38.53z"
          />
          <rect
            fill="#e0e6ea"
            x="28.3"
            y="43.39"
            width="80.27"
            height="69.6"
            rx="4.92"
            ry="4.92"
          />
          <path
            fill="#20b575"
            d="M103.64 114.49H33.22a6.43 6.43 0 0 1-6.42-6.43V48.31a6.43 6.43 0 0 1 6.42-6.42h70.42a6.44 6.44 0 0 1 6.43 6.42v59.75a6.44 6.44 0 0 1-6.43 6.43zm-70.42-69.6a3.42 3.42 0 0 0-3.42 3.42v59.75a3.43 3.43 0 0 0 3.42 3.43h70.42a3.44 3.44 0 0 0 3.43-3.43V48.31a3.43 3.43 0 0 0-3.43-3.42z"
          />
          <rect
            fill="#e0e6ea"
            x="122.47"
            y="43.39"
            width="80.27"
            height="69.6"
            rx="4.92"
            ry="4.92"
          />
          <path
            fill="#20b575"
            d="M197.81 114.49h-70.42a6.43 6.43 0 0 1-6.42-6.43V48.31a6.43 6.43 0 0 1 6.42-6.42h70.42a6.44 6.44 0 0 1 6.43 6.42v59.75a6.44 6.44 0 0 1-6.43 6.43zm-70.42-69.6a3.42 3.42 0 0 0-3.39 3.42v59.75a3.43 3.43 0 0 0 3.42 3.43h70.42a3.44 3.44 0 0 0 3.43-3.43V48.31a3.43 3.43 0 0 0-3.43-3.42z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutFlyer2.propTypes = {
  hover: PropTypes.bool
};

IconLayoutFlyer2.defaultProps = {
  hover: false
};

export default IconLayoutFlyer2;
