import styled from "styled-components";

export const Card = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0.1rem 0.4rem rgba(0, 0, 0, 0.15);
  border-radius: ${({ theme }) => theme.borderRadius.xl};
`;

export const Header = styled.h3`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 1.1rem;
  color: ${({ theme }) => theme.colors.purpleDarker};
`;

export const Back = styled.button.attrs({ type: "button" })`
  display: flex;
  align-items: center;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin-bottom: 1.1rem;
  background-color: transparent;
  border: 0;
  color: ${({ theme }) => theme.colors.purpleDarker};
  appearance: none;
`;

export const BackText = styled.span`
  text-decoration: underline;
  margin-left: 0.7rem;
`;

export const Body = styled.div`
  display: flex;
  align-items: flex-end;
`;

export const Content = styled.div`
  max-width: 78%;

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `};
`;

export const Stamp = styled.div`
  flex-shrink: 0;
  margin-left: auto;

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

export const Type = styled.div`
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin-bottom: 1.2rem;
`;

export const Text = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 1.8rem;
`;
