import { IconCopy, IconEmail, IconHomeHeart } from "@wrstudios/icons";
import { stringify } from "qs";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSession } from "../../Session";
import EmailReport from "../common/EmailReport";
import Image from "../common/Image";
import { useCopyText } from "../common/useCopyText";
import { Button, Header, Option, Options } from "./styled/publish-share";
import { getReportDetails } from "./utils";

function PublishShare({ report, pdfLink }) {
  const { features, currentUser } = useSession();
  const [isEmailing, setIsEmailing] = useState(false);
  const reportDetails = getReportDetails(report.type);
  const image = report.thumbnail_url_with_timestamp ? (
    <Image src={report.thumbnail_url_with_timestamp} alt={report.title} />
  ) : (
    reportDetails.image
  );
  const [copyPdfLinkText, copyPdfLink] = useCopyText({
    originalText: "Copy PDF Link",
    copiedText: "PDF Link Copied!"
  });
  const [copyLiveLinkText, copyLiveLink] = useCopyText({
    originalText: "Copy Live Link",
    copiedText: "Live Link Copied!"
  });

  return (
    <>
      <Header>Sharing options:</Header>
      <Options>
        <Option>
          <Button onClick={() => setIsEmailing(true)}>
            <IconEmail />
            Email Report
          </Button>
        </Option>
        <Option>
          <CopyToClipboard text={pdfLink} onCopy={copyPdfLink}>
            <Button>
              <IconCopy />
              {copyPdfLinkText}
            </Button>
          </CopyToClipboard>
        </Option>
        {report.type === "Tour" && currentUser.liveToursEnabled && (
          <>
            <Option>
              <CopyToClipboard
                text={`${window.location.origin}/tours/${report.id}`}
                onCopy={copyLiveLink}>
                <Button>
                  <IconCopy />
                  {copyLiveLinkText}
                </Button>
              </CopyToClipboard>
            </Option>
          </>
        )}
        {report.type === "Cma" && (
          <>
            <Option>
              <CopyToClipboard
                text={`${window.location.origin}/live/${report.guid}`}
                onCopy={copyLiveLink}>
                <Button>
                  <IconCopy />
                  {copyLiveLinkText}
                </Button>
              </CopyToClipboard>
            </Option>
            {features.hasHomebeat && (
              <Option>
                <Button
                  as="a"
                  target="_self"
                  href={`/homebeats/new?${stringify({
                    client: report.title,
                    address: [
                      report.report_address_parts[0],
                      report.report_address_parts[1]
                    ].join(", "),
                    beds: report.subject_property.beds,
                    baths: report.subject_property.baths,
                    sqft: report.subject_property.sqft,
                    lot_size: report.subject_property.lotsize,
                    garages: report.subject_property.garages,
                    prop_type: report.prop_type,
                    prop_sub_type: report.prop_sub_type
                  })}`}>
                  <IconHomeHeart />
                  Create Homebeat
                </Button>
              </Option>
            )}
          </>
        )}
      </Options>
      {isEmailing && (
        <EmailReport
          reportId={report.id}
          guid={report.guid}
          type={report.type}
          typeLabel={reportDetails.label}
          pdfLink={pdfLink}
          title={report.title}
          address={report.report_address_parts[0]}
          cityStateZip={report.report_address_parts[1]}
          image={image}
          onClose={() => setIsEmailing(false)}
        />
      )}
    </>
  );
}

export default PublishShare;
