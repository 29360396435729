import React, { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { orderNew, orderReset } from "../../actions/order";
import { useBraintreeToken } from "../braintree/useBraintreeToken";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import CheckboxLabel from "../common/CheckboxLabel";
import Loading from "../common/Loading";
import OrderCardExisting from "./OrderCardExisting";
import OrderCardForm from "./OrderCardForm";
import OrderDisclaimer from "./OrderDisclaimer";
import { Action, Actions, Container, Error, Link } from "./styled/order-form";

function OrderForm({
  sku,
  order,
  amount,
  title,
  user,
  onCheckout,
  orderNew,
  orderReset
}) {
  const hasCard = !!user?.subscription?.card_number;
  const [getToken, setGetToken] = useState();
  const [error, setError] = useState();
  const [tosAccepted, setTosAccepted] = useState(false);
  const [discount, setDiscount] = useState("");
  const { data: token } = useBraintreeToken();

  // TODO: How does this work with cards on file?
  const handleSubmit = async () => {
    setError(undefined);

    const { nonce } = (await getToken?.()) || { nonce: undefined };
    await orderNew(sku, {
      nonce,
      discount,
      order: { sku }
    });

    onCheckout();
  };

  const orderResetRef = useRef(orderReset);
  useEffect(() => {
    orderResetRef.current = orderReset;
  });

  useEffect(() => {
    return () => {
      orderResetRef.current();
    };
  }, []);

  return (
    <Container>
      {!hasCard && (
        <OrderCardForm
          btToken={token}
          sku={sku}
          discount={discount}
          onDiscountChange={setDiscount}
          error={error}
          setError={error}
          setGetToken={setGetToken}
        />
      )}
      {hasCard && (
        <OrderCardExisting
          user={user}
          sku={sku}
          amount={order.amount || amount}
          discount={discount}
          onDiscountChange={setDiscount}
          error={error}
          setError={error}
          setGetToken={setGetToken}
        />
      )}
      <OrderDisclaimer
        amount={order.amount || amount}
        title={title}
        hasCard={hasCard}
      />
      <Actions hasCard={hasCard}>
        <Action>
          <CheckboxLabel>
            <Checkbox
              checked={tosAccepted}
              onChange={(e) => setTosAccepted(e.target.checked)}
            />
            I have read and agree with the{" "}
            <Link href="//cloudcma.com/tos" target="_blank">
              Terms of Service
            </Link>
          </CheckboxLabel>
        </Action>
        <Action>
          <Button
            app="suite"
            disabled={!tosAccepted || order?.request?.fetching}
            onClick={handleSubmit}>
            {!order?.request?.fetching && "Start Free Trial"}
            {order?.request?.fetching && <Loading>Starting free Trial</Loading>}
          </Button>
          {(order?.request?.error || error) && (
            <Error>{order?.request?.error?.message || error?.message}</Error>
          )}
        </Action>
      </Actions>
    </Container>
  );
}

const mapStateToProps = (state) => ({
  user: state.user,
  order: state.order
});

const mapDispatchToProps = {
  orderReset,
  orderNew
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
