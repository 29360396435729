import { connect } from "react-redux";
import { isCustomTemplate } from "../../../utils/report";
import { getPageKeys } from "../../../utils/page";
import { getReportType } from "../../../utils/types";
import {
  saveTemplate,
  selectTemplate,
  removeTemplate
} from "../../../actions/customize";
import {
  groupTemplates,
  ungroupTemplates,
  groupActivePages
} from "../../../selectors/customize";
import CustomizeModalTemplateCard from "../CustomizeModalTemplateCard";

function select({ report, user, ...state }, { templateId }) {
  const groupedTemplates = groupTemplates(state);
  const ungroupedTemplates = ungroupTemplates(state);
  const templateNames = ungroupedTemplates.map(({ label }) =>
    (label || "").toLowerCase()
  );
  const template =
    ungroupedTemplates.find((template) => template.value == templateId) || {};
  const hasModified = state.customize.template.hasModified || false;
  const isCustom = isCustomTemplate(
    { title: template.label },
    groupedTemplates
  );
  const isActive =
    state.customize.template.selected.key == templateId && !hasModified;

  return {
    reportId: report.id || -1,
    reportType: getReportType(report.type) || "",
    userId: user.id || -1,
    pages: getPageKeys(groupActivePages(state)),
    templateNames,
    label: template.label || "",
    value: String(template.value || ""),
    pageCount: template.pageCount || 0,
    hasPageCount: !!template.pageCount,
    isCustom,
    isActive,
    isFetching: state.customize.template.request.fetching,
    hasFetched: state.customize.template.request.fetched,
    hasError: !!Object.values(state.customize.template.request.error || {})
      .length
  };
}

const actions = {
  saveTemplate,
  selectTemplate,
  removeTemplate
};

export default connect(select, actions)(CustomizeModalTemplateCard);
