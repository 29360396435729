/**
 * Grabs just the text from an html string
 *
 * @param {String} html
 */
export function getTextFromHtmlString(html) {
  const fakeElement = document.createElement("div");
  fakeElement.innerHTML = html;

  return fakeElement.textContent;
}

/**
 * Checks to see if the safari version is 8 or above.
 */
export function isSafari8orAbove() {
  const body = document.querySelector("body");

  return [
    body.classList.contains("safari8"),
    body.classList.contains("safari9"),
    body.classList.contains("safari10")
  ].some(Boolean);
}
