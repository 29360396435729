import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "@wrstudios/components";
import { updateFont } from "../../actions/customize";
import {
  ModalContent,
  Body,
  Default,
  Font,
  Header,
  Text
} from "./styled/customize-modal-font";

function CustomizeModalFont({
  reportId,
  fonts,
  selected,
  updateFont,
  onClose
}) {
  const handleClick = (font, key) => {
    updateFont(reportId, { key, title: font });
    onClose();
  };

  // Loading fonts here and keeping them in head instead of the virtual dom.
  // This will help keep the calls to google fonts down.
  useEffect(() => {
    const fragment = document.createDocumentFragment();

    // Fonts not supported by Google.
    const invalidFonts = [
      "Arial",
      "Georgia",
      "Josefin Sans STD Light",
      "Tahoma",
      "Times New Roman",
      "Verdana"
    ];
    // Loading fonts 1 by 1 in case one fails the entire stylesheet won't fail
    fonts.forEach(([font, key]) => {
      if (invalidFonts.includes(font)) return;

      const id = `loaded-font-${key}`;

      if (document.querySelector(`#${id}`)) return;

      const link = document.createElement("link");
      link.rel = "stylesheet";
      link.href = `//fonts.googleapis.com/css?family=${font.replace(
        /\s/g,
        "+"
      )}:300,400`;
      link.id = id;

      fragment.appendChild(link);
    });

    if (!fragment.querySelectorAll("*").length) return;

    document.head.appendChild(fragment);
  }, []);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Font
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          {!!fonts.length && (
            <Default onClick={() => handleClick("Default Font", null)}>
              Use theme default
            </Default>
          )}
          <Body>
            {fonts.map(([font, key]) => (
              <Font
                key={font}
                onClick={() => handleClick(font, key)}
                style={{ fontFamily: font }}
                autoFocus={key === selected.key}
                isActive={key === selected.key}>
                <Header>{font}</Header>
                <Text>
                  A comparative market analysis (CMA) is an evaluation of a
                  home's value based on similar, recently sold homes in the same
                  neighborhood.
                </Text>
              </Font>
            ))}
            {!fonts.length && <Text>There are no fonts to select from</Text>}
          </Body>
        </Modal.Body>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalFont.propTypes = {
  reportId: PropTypes.number,
  fonts: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.string)),
  onClose: PropTypes.func,
  updateFont: PropTypes.func
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  fonts: state.customize.font.all,
  selected: state.customize.font.selected
});

const mapDispatchToProps = {
  updateFont
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeModalFont);
