import React from "react";
import parser from "parse-address";
import { Modal } from "@wrstudios/components";
import { IconChecked } from "@wrstudios/icons";
import Button from "../../common/Button";
import { useHomebeatEditor } from "./HomebeatEditorProvider";
import {
  ModalContent,
  ModalIcon,
  ModalHeader,
  ModalContact
} from "./styled/homebeat-editor-sent-modal";

function HomebeatEditorUpdatedModal({ onClose }) {
  const { name, email, address } = useHomebeatEditor();
  const parsedAddress = parser.parseLocation(address) || {
    number: "",
    street: "",
    type: ""
  };

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <ModalIcon>
          <IconChecked />
        </ModalIcon>
        <ModalHeader>Your Homebeat has updated!</ModalHeader>
        <ModalContact>
          <strong>{name}</strong>
          <div>{email}</div>
          <div>
            {[parsedAddress.number, parsedAddress.street, parsedAddress.type]
              .filter(Boolean)
              .join(" ")}
          </div>
        </ModalContact>
        <Button app="cma" onClick={onClose}>
          Close
        </Button>
      </ModalContent>
    </Modal>
  );
}

export default HomebeatEditorUpdatedModal;
