import styled from "styled-components";
import { default as BaseImage } from "../../common/Image";

export const Grid = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: -1rem;
`;

export const Cell = styled.div`
  width: 50%;
  padding: 1rem;
`;

export const Item = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

export const Image = styled(BaseImage)`
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: 0.4rem;
`;

export const EmptyImage = styled.div`
  width: 100%;
  padding-bottom: 129%;
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;
`;

export const Caption = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 1rem;
`;

export const Title = styled.div`
  padding-right: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Controls = styled.div`
  text-align: center;
`;

export const Control = styled.div`
  flex-shrink: 0;
  cursor: pointer;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  }
`;

export const Input = styled.input`
  width: 0;
  height: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;

  &:focus + label {
    outline: auto 0.5rem -webkit-focus-ring-color;
  }
`;
