import styled from "styled-components";

export const Photo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
`;

export const PhotoFile = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  font-weight: 600;
  border-radius: 0.4rem;
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) => theme.media.lg`
    min-height: 15rem;
  `};
`;

export const PhotoFileInner = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.4rem;
  overflow: hidden;
`;

export const PhotoFileInput = styled.input`
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;

  &:focus,
  &:active {
    + ${PhotoFile} ${PhotoFileInner} {
      outline: auto 0.5rem -webkit-focus-ring-color;
    }
  }
`;

export const PhotoFilePicker = styled.label`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const PhotoFilePickerIcon = styled.span`
  margin-bottom: 0.7rem;
`;

export const PhotoFilePickerText = styled.span`
  font-size: 1.2rem;
`;

export const PhotoFilePreview = styled.div`
  width: 100%;
  height: 100%;
  background: center center / contain no-repeat;
  position: relative;
`;

export const PhotoFileControls = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 1.3rem;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  color: ${({ theme }) => theme.colors.white};
  position: absolute;
  bottom: 0;
  left: 0;
`;

export const PhotoFileControlsChange = styled.button`
  width: 50%;
  padding: 0.6rem 0.8rem 0.6rem 0;
  text-align: right;
`;

export const PhotoFileControlsDelete = styled.button`
  display: flex;
  align-items: center;
  width: 50%;
  padding: 0.6rem 0 0.6rem 0.8rem;
  text-align: left;

  svg {
    margin-right: 0.5rem;
  }
`;
