import React from "react";
import DropdownList from "./DropdownList";

function DropdownGroup({ items, toggleMenu }) {
  return (
    <div className="dropdown-group">
      <DropdownList items={items} close={toggleMenu} />
    </div>
  );
}

export default DropdownGroup;
