/**
 * Checks to see if the status of a report is publishing
 *
 * @param {Object} report
 */
export function isPublishing(report) {
  if (!report) return false;

  return report.status === "active-report";
}

/**
 * Checks to see if the template of the report is a custom template
 *
 * @param {Object} template
 * @param {Array} templates
 */
export function isCustomTemplate(template, templates) {
  const customReports = templates.find((group) =>
    group.label.includes("Custom")
  );

  if (customReports) {
    return !!customReports.value.find(
      (report) => report.label === template.title
    );
  }

  return false;
}

/**
 * Checks to see if the theme is a modern theme
 *
 * @param {Object} theme
 */
export function isModernTheme(theme) {
  return (
    (theme && theme.templates && theme.templates.includes("modern")) || false
  );
}

/**
 * Checks to see if the theme is a classic theme
 *
 * @param {Object} theme
 */
export function isClassicTheme(theme) {
  return !isModernTheme(theme);
}

/**
 * Checks to see if our theme has a feature
 *
 * @param {Object} theme
 * @param {String} feature
 */
export function hasFeature(theme = {}, feature) {
  return (theme && theme.features && theme.features.includes(feature)) || false;
}
