import React from "react";
import PropTypes from "prop-types";
import CustomizeInactivePagesGroup from "./CustomizeInactivePagesGroup";
import CustomizeInactivePage from "./CustomizeInactivePage";

function CustomizeInactivePages({ pages }) {
  return (
    <div className="list-reset pl-2">
      {pages.map((page, index) => (
        <div key={index} className={index > 0 ? "mt-4" : ""}>
          {/* Group */}
          {!!page.children.length && (
            <CustomizeInactivePagesGroup
              pages={page.children}
              title={page.title}
            />
          )}

          {/* No Group */}
          {!page.children.length && !page.is_parent && (
            <div className="mt-6">
              <CustomizeInactivePage page={page} />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

CustomizeInactivePages.propTypes = {
  pages: PropTypes.arrayOf(PropTypes.object).isRequired
};

export default CustomizeInactivePages;
