import camelcaseKeys from "camelcase-keys";
import api from "./api";

function normalizeBlank(data) {
  if (data === null || data === undefined || data === "" || data === "null") {
    return "";
  }
  return data;
}

export function updateSubjectProperty(reportId, details, photo, cropData) {
  const formData = new FormData();

  for (let key in details) {
    formData.append("cma[comparison_fields][]", key);
  }

  for (let key in details) {
    if (normalizeBlank(details[key]) !== null) {
      formData.append(`subject_property[${key}]`, normalizeBlank(details[key]));
    }
  }

  if (photo && cropData) {
    formData.append("subject_property[photo][file]", photo);
    formData.append("subject_property[photo][crop][width]", cropData.width);
    formData.append("subject_property[photo][crop][height]", cropData.height);
    formData.append("subject_property[photo][crop][x]", cropData.x);
    formData.append("subject_property[photo][crop][y]", cropData.y);
  }

  return api
    .put(`/cmas/${reportId}`, formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }).subjectProperty);
}

export function deleteSubjectPropertyPhoto(reportId, subjectPropertyId) {
  return api
    .delete(
      `/reports/${reportId}/subject_properties/${subjectPropertyId}/photo`
    )
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateSubjectPropertyPrice(reportId, subjectPropertyId, price) {
  return api
    .put(`/reports/${reportId}/subject_properties/${subjectPropertyId}`, {
      subject_property: { price }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateSubjectPropertyNote(reportId, subjectPropertyId, note) {
  return api
    .put(`/reports/${reportId}/subject_properties/${subjectPropertyId}`, {
      subject_property: { note }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

function formatData(netSheetItems, exclude = []) {
  const formData = new FormData();

  netSheetItems.forEach(({ id, key, value, percentage, dateId }) => {
    if (!exclude.includes(id) && !exclude.includes(dateId)) {
      formData.append(`net_sheet_items[items][${id}][key]`, key);
      formData.append(`net_sheet_items[items][${id}][value]`, value);
      if (percentage !== null || percentage !== 0 || percentage != undefined)
        formData.append(
          `net_sheet_items[items][${id}][percentage]`,
          percentage
        );
    }
  });

  formData.append("send_back_as_json", 1);

  return formData;
}

export function updateNetSheetItems(subjectPropertyId, netSheetItems) {
  return api
    .post(
      `/subject_property/${subjectPropertyId}/net_sheet_items`,
      formatData(netSheetItems)
    )
    .then((res) => {
      return res;
    });
}
