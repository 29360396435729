import styled from "styled-components";
import Color from "color";
import BaseWrapper from "../../common/Wrapper";
import BaseTitle from "../../common/Title";
import BasePaginate from "../../common/Paginate";
import {
  Table as BaseTable,
  TableRow as BaseTableRow,
  TableHeading as BaseTableHeading,
  TableData as BaseTableData
} from "../../common/Table";
import BaseTooltip from "../../common/Tooltip";
import { getFrequencyColors } from "../utils";

export const Wrapper = styled(BaseWrapper)`
  max-width: 100%;
`;

export const Container = styled.div`
  margin: 1.5rem 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2.4rem;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const Title = styled(BaseTitle)`
  ${({ theme }) => theme.media.md`
    margin-bottom: 1rem;
  `}
`;

export const SearchContainer = styled.div`
  display: flex;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `}
`;

export const SearchActions = styled.div`
  margin-left: 1rem;

  ${({ theme }) => theme.media.sm`
    margin-top: 1rem;
    margin-left: 0;
  `}
`;

export const SearchGrid = styled.div`
  display: flex;
  align-items: center;
  margin: -0.5rem;
`;

export const SearchCell = styled.div`
  padding: 0.5rem;

  ${({ theme }) => theme.media.sm`
    width: 50%;
  `}

  a {
    ${({ theme }) => theme.media.sm`
      width: 100%;
    `}
  }
`;

export const SubHeader = styled.div`
  display: flex;
  margin-bottom: 2.4rem;
  overflow-x: auto;
`;

export const SubHeaderInner = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;

  ${({ theme }) => theme.media.md`
    width: auto;
  `}
`;

export const Export = styled.a`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin-left: auto;
  color: ${({ theme }) => theme.styleGuide.colors.green[600]};
  text-decoration: none;

  ${({ theme }) => theme.media.md`
    margin-left: 2.4rem;
  `}

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const TableContainer = styled.div`
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border-radius: 0.4rem;
`;

export const Table = styled(BaseTable)`
  .checkbox-container {
    display: inline-block;
  }
`;

export const TableHeading = styled(BaseTableHeading)`
  cursor: ${({ onClick }) => (onClick ? "pointer" : "default")};

  svg {
    &.desc {
      display: ${({ sortDir, showSort }) =>
        showSort && sortDir === "desc" ? "block" : "none"};
    }

    &.asc {
      display: ${({ sortDir, showSort }) =>
        showSort && sortDir === "asc" ? "block" : "none"};
    }
  }

  &:hover {
    svg {
      &.desc {
        display: ${({ showSort }) => !showSort && "block"};
        fill: ${({ theme, showSort }) =>
          !showSort && theme.styleGuide.colors.gray[600]};
      }
    }
  }
`;

export const TableHeadingContent = styled.div`
  display: flex;
  align-items: center;
`;

export const TableHeadingIcon = styled.div`
  width: 1.6rem;
  height: 1.6rem;

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-left: 0.4rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;

export const TableData = styled(BaseTableData)`
  cursor: ${({ onClick }) => onClick && "pointer"};
  position: relative;

  &:after {
    content: "";
    display: ${({ deleting }) => (deleting ? "block" : "none")};
    width: 100%;
    height: 100%;
    opacity: 0.8;
    background-color: ${({ theme }) => theme.styleGuide.colors.white};
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const TableDataEmpty = styled(TableData)`
  text-align: center;
`;

export const TableDataGettingStarted = styled(TableData)`
  text-align: center;
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
`;

export const TableRow = styled(BaseTableRow)`
  ${TableData} {
    background-color: ${({ theme, selected }) =>
      selected && Color(theme.styleGuide.colors.cma[100]).alpha(0.5)};
  }

  &:hover {
    ${TableData} {
      background-color: ${({ theme, selected }) =>
        selected && Color(theme.styleGuide.colors.cma[100]).alpha(0.5)};
    }
  }

  &:last-child {
    ${TableData} {
      &:first-child {
        border-bottom-left-radius: 0.4rem;
      }

      &:last-child {
        border-bottom-right-radius: 0.4rem;
      }
    }
  }
`;

export const TableIconText = styled.span``;

export const TableIcon = styled.button`
  width: 2rem;
  height: 2rem;
  padding: 0;
  appearance: none;
  cursor: pointer;

  &:disabled {
    ${TableIconText} {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  svg {
    width: 100%;
    height: 100%;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;

export const Paginate = styled(BasePaginate)`
  margin-top: 3rem;
`;

export const Frequency = styled.div`
  display: inline-block;
  font-weight: 400;
  font-size: 1.1rem;
  line-height: 1.2rem;
  padding: ${({ hasIcon }) =>
    hasIcon ? "0.25rem 0.6rem 0.25rem 2.1rem" : "0.25rem 0.6rem"};
  color: ${({ frequency, inactive }) =>
    getFrequencyColors(frequency, inactive).color};
  background-color: ${({ frequency, inactive }) =>
    getFrequencyColors(frequency, inactive).backgroundColor};
  border-radius: 99rem;
  position: relative;

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: currentColor;
    position: absolute;
    /* top: 0.3rem; */
    left: 0.6rem;
  }
`;

export const FrequencyInner = styled.div`
  transform: translateY(-0.05rem);
`;

export const Controls = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: -0.8rem;
`;

export const Control = styled.div`
  flex-shrink: 0;
  padding: 0.8rem;
`;

export const Preview = styled.a`
  font-weight: 600;
  text-decoration: none;
  color: ${({ theme }) => theme.styleGuide.colors.cma[600]};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;

export const Name = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
  font-size: 1.5rem;
`}
`;

export const Street = styled.span`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const CityStateZip = styled.span`
  font-size: 1.2rem;
  font-weight: 500;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Tooltip = styled(BaseTooltip)`
  .tooltip-text {
    display: flex;
    width: auto;
  }
`;

export const CopiedText = styled.span`
  display: inline-flex;
  align-items: center;
  width: 9rem;

  svg {
    flex-shrink: 0;
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }
`;

export const RightSkeleton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
