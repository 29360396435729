import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import { reorderNetSheetItems } from "../../actions/subject-property";
import { addBodyClass, removeBodyClass } from "../../utils/page";
import NetSheetListItem from "./NetSheetListItem";
import NetSheetListItemAdd from "./NetSheetListItemAdd";

class NetSheetList extends Component {
  static propTypes = {
    subjectProperty: PropTypes.shape({
      id: PropTypes.number.isRequired,
      net_sheet_items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.number.isRequired,
          key: PropTypes.string.isRequired,
          value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
            .isRequired,
          request: PropTypes.object.isRequired
        })
      ).isRequired
    }).isRequired,
    reorderNetSheetItems: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);
  }

  onDragStart() {
    addBodyClass("cursor-move");
  }

  onDragEnd(result) {
    if (!result.destination) return;

    this.props.reorderNetSheetItems(
      this.props.subjectProperty.id,
      result.source.index,
      result.destination.index
    );

    removeBodyClass("cursor-move");
  }

  render() {
    const { net_sheet_items } = this.props.subjectProperty;
    const isDragDisabled = net_sheet_items.length <= 1;

    return (
      <Fragment>
        <DragDropContext
          onDragStart={this.onDragStart}
          onDragEnd={this.onDragEnd}>
          <Droppable
            droppableId={`droppable-net-sheet`}
            type="net-sheet"
            isDropDisabled={isDragDisabled}>
            {(provided) => (
              <div
                className="relative z-10 net-sheet-draggables"
                ref={provided.innerRef}>
                {net_sheet_items.map((item, index) => (
                  <NetSheetListItem
                    key={item.id}
                    index={index}
                    item={item}
                    isDragDisabled={isDragDisabled}
                  />
                ))}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <NetSheetListItemAdd />
      </Fragment>
    );
  }
}

const mapStateToProps = (state) => ({
  subjectProperty: state.subjectProperty
});

const mapDispatchToProps = (dispatch) => ({
  reorderNetSheetItems: (subjectPropertyId, startIndex, endIndex) =>
    dispatch(reorderNetSheetItems(subjectPropertyId, startIndex, endIndex))
});

export default connect(mapStateToProps, mapDispatchToProps)(NetSheetList);
