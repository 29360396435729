import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { resetListingRequest, updateDescription } from "../../actions/listings";
import Input from "../common/Input";
import Loading from "../common/Loading";

class ListingDetailsDescription extends Component {
  state = {
    description: this.props.listing.data.remarks || ""
  };

  render() {
    const { listing, user, title } = this.props;
    const { description } = this.state;
    const { fetching, fetched, error = {} } = listing.request;
    const hasError = error && !!Object.keys(error).length;
    const isEnabled = user.enable_edit_remarks && !this.props.disabled;

    return (
      <Fragment>
        <h3 className="text-sbase leading-sxl font-normal text-grey-darker mb-4">
          {title}
        </h3>
        {!isEnabled && <p>{description}</p>}
        {isEnabled && (
          <form onSubmit={this.onUpdate.bind(this)}>
            <Input
              as="textarea"
              rows={6}
              value={description}
              onChange={(e) => this.setState({ description: e.target.value })}
            />
            <div className="flex items-center mt-4">
              <div>
                {hasError && (
                  <span className="text-red text-sm">{error.message}</span>
                )}
              </div>
              <div className="ml-auto text-right">
                <button
                  className="button button-cma"
                  disabled={fetching || fetched}>
                  {!fetching && !fetched && "Update"}
                  {fetched && "Updated!"}
                  {fetching && <Loading>Updating</Loading>}
                </button>
              </div>
            </div>
          </form>
        )}
      </Fragment>
    );
  }

  componentDidUpdate() {
    if (this.props.listing.request.fetched) {
      setTimeout(
        () => this.props.resetListingRequest(this.props.listing.id),
        1000
      );
    }
  }

  onUpdate(e) {
    e.preventDefault();

    const { listing, report, updateDescription } = this.props;
    const { description } = this.state;

    updateDescription(report.id, listing.id, description);
  }
}

ListingDetailsDescription.propTypes = {
  disabled: PropTypes.bool,
  title: PropTypes.string,
  listing: PropTypes.shape({
    id: PropTypes.number.isRequired,
    data: PropTypes.shape({
      remarks: PropTypes.string
    }).isRequired,
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired,
      fetched: PropTypes.bool.isRequired,
      error: PropTypes.shape({
        message: PropTypes.string
      })
    }).isRequired
  }).isRequired,
  report: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired
};

ListingDetailsDescription.defaultProps = {
  disabled: false,
  title: "Remarks",
  listing: {
    data: {
      remarks: ""
    }
  }
};

const mapStateToProps = ({ report, user }) => ({
  report,
  user
});

const mapDispatchToProps = {
  resetListingRequest,
  updateDescription
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingDetailsDescription);
