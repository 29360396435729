import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import image from "../../images/order/tomferry-logo-white-md";
import Button from "../common/Button";
import Wistia from "../common/Wistia";
import {
  PreviewModalContent,
  PreviewModalClose,
  TitleAlt,
  SubTitleAlt,
  TitleImage,
  Video,
  Actions,
  Action
} from "./styled/customize-modal-kit";

function CustomizeModalTomFerry({ onConfirm, onClose }) {
  return (
    <Modal onClose={onClose}>
      <PreviewModalContent>
        <PreviewModalClose />
        <Modal.Body>
          <TitleAlt>Listing Presentation Kit</TitleAlt>
          <SubTitleAlt>
            by <TitleImage src={image} />
          </SubTitleAlt>
          <Video>
            <Wistia videoId="50ddm5ii8d" autoplay={true} />
          </Video>
          <Actions>
            <Action>
              <Button app="suite" onClick={onConfirm}>
                Try for Free
              </Button>
            </Action>
          </Actions>
        </Modal.Body>
      </PreviewModalContent>
    </Modal>
  );
}

CustomizeModalTomFerry.propTypes = {
  onClose: PropTypes.func
};

export default CustomizeModalTomFerry;
