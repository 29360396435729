import React, { useState } from "react";
import Helmet from "react-helmet";
import { Markers } from "@wrstudios/components";
import Map from "../../common/Map";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerNavigation from "./HomebeatViewerNavigation";
import HomebeatViewerListingPreview, {
  useListingPreviewResizer
} from "./HomebeatViewerListingPreview";
import { Container, HomebeatViewerAgent } from "./styled/homebeat-viewer-map";

function HomebeatViewerMap() {
  const { subjectProperty } = useHomebeatViewer();
  const { headerRef, mobileHeaderRef, previewRef, headerDetails } =
    useListingPreviewResizer();
  const [previewListingId, setPreviewListingId] = useState(null);
  const markers = useListingMarkers({
    previewListingId,
    setPreviewListingId
  });

  return (
    <>
      <Helmet>
        <title>Map - Homebeat</title>
      </Helmet>
      <HomebeatViewerNavigation
        headerRef={headerRef}
        mobileHeaderRef={mobileHeaderRef}>
        Your Homebeat
      </HomebeatViewerNavigation>
      <Container>
        <Map
          markers={markers}
          boundMarkers={markers}
          spLat={subjectProperty.coords.lat}
          spLon={subjectProperty.coords.lon}
          isAutoZoomDisabled
          isScrollZoomEnabled
          isInitialZoomDisabled
        />
        {previewListingId && (
          <HomebeatViewerListingPreview
            ref={previewRef}
            style={{ top: headerDetails.bottom }}
            listingId={previewListingId}
            initialTab="tour"
            showInModal={true}
            onClose={() => setPreviewListingId(null)}
          />
        )}
      </Container>
      <HomebeatViewerAgent />
    </>
  );
}

function useListingMarkers({ previewListingId, setPreviewListingId }) {
  const { listings } = useHomebeatViewer();

  return listings.map((listing) => {
    const isSelected = listing.id === previewListingId;
    return {
      id: listing.id,
      lat: listing.coords.lat,
      lon: listing.coords.lon,
      zIndex: isSelected ? 2 : null,
      component: (
        <Markers.ListingMarker
          isSelected={isSelected}
          price={listing.price}
          onClick={({ event, map }) => {
            event.stopPropagation();
            map.easeTo({ center: [listing.coords.lon, listing.coords.lat] });
            setPreviewListingId(listing.id);
          }}
          color={listing.statusColor}
        />
      )
    };
  });
}

export default HomebeatViewerMap;
