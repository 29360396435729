import React from "react";
import PropTypes from "prop-types";
import { Container, Placeholder } from "./styled/image";

function Image({ src, ...props }) {
  if (!src) {
    return (
      <Container {...props} as="div">
        {props["data-error"] && (
          <Placeholder>{props["data-error"]}</Placeholder>
        )}
      </Container>
    );
  }
  return <Container {...props} src={src} />;
}

Image.defaultProps = {
  src: ""
};

Image.propTypes = {
  src: PropTypes.string,
  "data-error": PropTypes.string,
  alt: PropTypes.string
};

export default Image;
