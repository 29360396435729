import React, { useState, useEffect } from "react";
import Fuse from "fuse.js";
import { snakeCase } from "lodash";
import { Select, Option } from "../common/Select";
import { useCSV } from "./CSVProvider";
import { Container, Label, Group, SelectContainer } from "./styled/csv-field";

function CSVField({ label, column, columnsToMatch }) {
  const { columns } = useCSV();
  const fuse = new Fuse(columns, {
    minMatchCharLength: 2,
    threshold: 0.4
  });
  const slug = snakeCase(label);

  // Try to find the column in the csv
  let foundColumns = [
    ...new Set(
      columnsToMatch
        .map((column) => {
          const [result] = fuse.search(column);
          return result && result.item;
        })
        .filter(Boolean)
    )
  ];

  // If no columns are found we use a blank value to
  // use the default value of the dropdown
  if (!foundColumns.length) {
    foundColumns = [""];
  }

  return (
    <Container>
      <Label htmlFor={slug}>{label}</Label>
      <Group>
        {foundColumns.map((matchColumn, index) => (
          <SelectContainer key={matchColumn}>
            <CSVSelect
              id={index === 0 ? slug : undefined}
              column={column}
              matchColumn={matchColumn}
              index={index}
            />
          </SelectContainer>
        ))}
      </Group>
    </Container>
  );
}

function CSVSelect({ id, column, matchColumn, index }) {
  const { columns, setSelectedColumns } = useCSV();
  const [selectedColumn, setSelectedColumn] = useState(matchColumn);

  useEffect(() => {
    setSelectedColumns((selectedColumns) => {
      const currentColumnValues = [...(selectedColumns[column] || [])];
      currentColumnValues[index] = selectedColumn;
      return {
        ...selectedColumns,
        [column]: currentColumnValues
      };
    });
  }, [column, selectedColumn, index, setSelectedColumns]);

  return (
    <Select
      id={id}
      value={selectedColumn}
      onChange={(e) => setSelectedColumn(e.target.value)}>
      <Option value="">Blank</Option>
      {columns.map((column) => (
        <Option key={column}>{column}</Option>
      ))}
    </Select>
  );
}

export default CSVField;
