import React from "react";
import Icon from "./Icon";

function IconText(props) {
  return (
    <Icon {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-5-4h24v24H-5z" />
        <path fill="#89929D" d="M0 0v3h5.5v12h3V3H14V0z" />
      </g>
    </Icon>
  );
}

export default IconText;
