import React, { Component } from "react";
import PropTypes from "prop-types";
import { getSizeInMB } from "../../utils/file";
import Button from "../common/Button";
import FilePickerSizeErrorAlert from "./FilePickerSizeErrorAlert";

class FilePicker extends Component {
  state = {
    originalFiles: {},
    selectedFiles: [],
    invalidFiles: [],
    maxSizeInMB: this.props.maxSizeInMB,
    timestamp: Date.now()
  };

  render() {
    const {
      id,
      name,
      multiple,
      accept,
      label,
      tip,
      showLabel,
      showSelectedFiles,
      buttonLabel
    } = this.props;
    const { invalidFiles, selectedFiles, maxSizeInMB, timestamp } = this.state;

    return (
      <div className="file-picker" title={selectedFiles.join(", ")}>
        <input
          key={timestamp}
          type="file"
          onChange={this.handleOnChange}
          name={`${name}[file]`}
          multiple={multiple}
          accept={accept}
          id={id}
          className="file-picker-input"
        />
        {showLabel && (
          <label className="label file-picker-label" htmlFor={id}>
            {(label && <span className="flex-no-shrink">{label}</span>) ||
              "Photo"}
            {tip && <i className="file-picker-tip">{tip}</i>}
          </label>
        )}
        <div className="flex items-center file-picker-cta-container">
          <Button app="cma" as="label" htmlFor={id}>
            {buttonLabel}
          </Button>
          {showSelectedFiles && (
            <span className="text-sm truncate ml-6">
              {selectedFiles.join(", ")}
            </span>
          )}
        </div>
        {!!invalidFiles.length && (
          <FilePickerSizeErrorAlert
            files={invalidFiles}
            onClose={this.handleOnClose}
            maxSizeInMB={maxSizeInMB}
          />
        )}
      </div>
    );
  }

  // There is a bug in chrome which will reset the input
  // field when a user clicks "cancel"
  // https://bugs.chromium.org/p/chromium/issues/detail?id=2508
  handleOnChange = (e) => {
    const { maxSizeInMB } = this.state;
    const files = e.target.files;

    const invalidFiles = [...files].filter((file) =>
      getSizeInMB(file) <= maxSizeInMB ? null : file
    );

    // Show error message if we have invalid files.
    if (invalidFiles.length) {
      return this.setState({ invalidFiles }, () =>
        this.props.onChange(invalidFiles, {})
      );
    }

    this.setState({ originalFiles: files }, () => {
      const selectedFiles = [...files].map((file) => file.name);

      if (!selectedFiles.length) {
        this.setState({
          selectedFiles: [`no file${this.props.multiple ? "s" : ""} selected`]
        });
      } else {
        this.setState({ selectedFiles });
      }

      this.props.onChange(files, {});
    });
  };

  handleOnClose = () => {
    this.setState(({ selectedFiles }) => {
      const filteredSelectedFiles = selectedFiles.filter(
        (file) => file !== "no file selected" && file !== "no files selected"
      );

      if (!filteredSelectedFiles.length) {
        return { invalidFiles: [], timestamp: Date.now() };
      }

      return { invalidFiles: [] };
    });
  };
}

FilePicker.getDerivedStateFromProps = function (
  { files, multiple },
  { selectedFiles }
) {
  if (selectedFiles.length) {
    return {
      selectedFiles
    };
  }

  if (files.length) {
    return {
      selectedFiles: files
    };
  }

  return {
    selectedFiles: [`no file${multiple ? "s" : ""} selected`],
    timestamp: Date.now()
  };
};

FilePicker.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  tip: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.string),
  multiple: PropTypes.bool,
  maxSizeInMB: PropTypes.number,
  accept: PropTypes.arrayOf(PropTypes.string),
  showLabel: PropTypes.bool,
  showSelectedFiles: PropTypes.bool,
  buttonLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  buttonClassName: PropTypes.string,
  onChange: PropTypes.func
};

FilePicker.defaultProps = {
  tip: "Max 5MB in size",
  removable: false,
  files: [],
  multiple: false,
  maxSizeInMB: 5,
  accept: ["*/*"],
  showLabel: true,
  showSelectedFiles: true,
  buttonLabel: "Choose File",
  onChange: function () {}
};

export default FilePicker;
