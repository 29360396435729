import React from "react";
import PropTypes from "prop-types";
import { formatCurrency } from "@wrstudios/utils";
import Adjustment from "../common/Adjustment";
import { Row } from "./styled/comparable";
import {
  Container,
  Street,
  CityStateZip,
  Price,
  PriceLabel,
  Status
} from "./styled/comparable-address";

function ComparableAddress({
  street,
  cityStateZip,
  adjustedPrice,
  status,
  statusColor,
  primaryColor,
  price,
  priceLabel,
  onPriceClick,
  ...props
}) {
  return (
    <Container {...props}>
      <Row>
        <Street title={street} className="font-primary comparable-street">
          {street}
        </Street>
        {!priceLabel && (
          <Price
            as={!!onPriceClick ? "button" : "div"}
            title={
              adjustedPrice || price
                ? formatCurrency(adjustedPrice || price)
                : ""
            }
            className="font-primary comparable-price"
            onClick={onPriceClick}>
            <Adjustment
              value={price}
              adjustedValue={adjustedPrice}
              shouldDim={false}
            />
          </Price>
        )}
        {priceLabel && (
          <Price
            as={!!onPriceClick ? "button" : "div"}
            style={{ color: primaryColor }}
            className="font-primary comparable-price comparable-price-action"
            hasClickEvent={!!onPriceClick}
            title={priceLabel}
            onClick={onPriceClick}>
            <PriceLabel>{priceLabel}</PriceLabel>
          </Price>
        )}
      </Row>
      <Row>
        <CityStateZip title={cityStateZip}>{cityStateZip}</CityStateZip>
        <Status title={status} style={{ color: statusColor }}>
          {status}
        </Status>
      </Row>
    </Container>
  );
}

ComparableAddress.propTypes = {
  street: PropTypes.string,
  cityStateZip: PropTypes.string,
  adjustedPrice: PropTypes.number,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  primaryColor: PropTypes.string,
  price: PropTypes.number,
  priceLabel: PropTypes.string,
  onPriceClick: PropTypes.func
};

export default ComparableAddress;
