import styled from "styled-components";
import { TabPanel } from "react-tabs";

export const Container = styled(TabPanel)`
  display: none;
  padding-top: 2rem;

  &.react-tabs__tab-panel--selected {
    display: block;
  }
`;
