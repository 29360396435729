import React from "react";
import PropTypes from "prop-types";
import { getTimeAgo } from "@wrstudios/utils";
import { Container, Grid, Cell, Stat, Value, Label } from "./styled/stats";

function Stats({
  viewCount,
  lastViewedAt,
  lastRequestedCMAAt,
  lastUpdatedAt,
  showLastRequesteCMAAt,
  ...props
}) {
  const viewCountFormatted = viewCount || "N/A";
  const lastViewedAtFormatted = lastViewedAt ? getTimeAgo(lastViewedAt) : "N/A";
  const lastRequestedCMAAtFormatted = lastRequestedCMAAt
    ? getTimeAgo(lastRequestedCMAAt)
    : "N/A";
  const lastUpdatedFormatted = lastUpdatedAt
    ? getTimeAgo(lastUpdatedAt)
    : "N/A";

  return (
    <Container {...props}>
      <Grid>
        <Cell>
          <Stat>
            <Value title={viewCountFormatted}>{viewCountFormatted}</Value>
            <Label title="View Count">View Count</Label>
          </Stat>
        </Cell>
        <Cell>
          <Stat>
            <Value title={lastViewedAtFormatted}>{lastViewedAtFormatted}</Value>
            <Label title="Last Viewed">Last Viewed</Label>
          </Stat>
        </Cell>
        {showLastRequesteCMAAt && (
          <Cell>
            <Stat>
              <Value title={lastRequestedCMAAtFormatted}>
                {lastRequestedCMAAtFormatted}
              </Value>
              <Label title="Last Requested CMA">Last Requested CMA</Label>
            </Stat>
          </Cell>
        )}
        {!showLastRequesteCMAAt && (
          <Cell>
            <Stat>
              <Value title={lastUpdatedFormatted}>{lastUpdatedFormatted}</Value>
              <Label title="Last Updated">Last Updated</Label>
            </Stat>
          </Cell>
        )}
      </Grid>
    </Container>
  );
}

Stats.defaultProps = {
  showLastRequesteCMAAt: false
};

Stats.propTypes = {
  viewCount: PropTypes.number,
  lastViewedAt: PropTypes.string,
  lastRequestedCMAAt: PropTypes.string,
  lastUpdatedAt: PropTypes.string,
  showLastRequesteCMAAt: PropTypes.bool
};

export default Stats;
