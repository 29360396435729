import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import AlertDanger from "../common/AlertDanger";
import IconMoreHoriz from "../icons/material/navigation/IconMoreHoriz";
import {
  Container,
  Actions,
  ActionsButton,
  Action,
  Label,
  PageCount,
  Form,
  Controls,
  Cancel,
  Save
} from "./styled/customize-modal-template-card";

class CustomizeModalTemplateCard extends Component {
  constructor(props) {
    super(props);

    // TODO: Figure out why I can't bind clicks at a class level.
    this.groups = [
      [
        {
          text: "Rename",
          title: "Rename Template",
          onClick: this.handleOnRename
        }
      ],
      [{ text: "Delete Template", danger: true, onClick: this.handleOnDelete }]
    ];
  }

  state = {
    showRename: false,
    showAlert: false,
    name: "",
    isFocused: this.props.isActive || false
  };

  render() {
    const isDisabled =
      !this.state.name ||
      this.props.templateNames.includes(this.state.name.toLowerCase());

    return (
      <>
        <Container isActive={this.props.isActive || this.state.isFocused}>
          {this.props.isCustom && (
            <Actions
              direction="right-top"
              groups={this.groups}
              buttonAriaLabel="Template Controls"
              button={(isOpen) => (
                <ActionsButton
                  aria-label={`Button: ${
                    !isOpen ? "Open" : "Close"
                  } Report Controls`}>
                  <IconMoreHoriz />
                </ActionsButton>
              )}
            />
          )}
          {!this.state.showRename && (
            <Action
              autoFocus={this.state.isFocused}
              onClick={this.handleOnClick}
              onFocus={this.handleOnFocus}
              onBlur={this.handleOnBlur}>
              <Label>{this.props.label}</Label>
              <PageCount>{`${this.props.pageCount} page${
                this.props.pageCount === 1 ? "" : "s"
              }`}</PageCount>
            </Action>
          )}
          {this.state.showRename && (
            <Form onSubmit={this.handleOnSubmit}>
              <Input
                autoFocus
                value={this.state.name}
                onChange={this.handleOnChange}
                onKeyDown={this.handleOnKeyDown}
              />
              <Controls>
                <Cancel type="button" onClick={this.handleOnCancel}>
                  Cancel
                </Cancel>
                <Save disabled={isDisabled}>Save</Save>
              </Controls>
            </Form>
          )}
        </Container>
        {this.state.showAlert && (
          <AlertDanger
            title="Delete Template"
            confirmButton="Delete Forever"
            onConfirm={this.handleOnDeleteConfirm}
            onClose={this.handleOnDeleteCancel}>
            Are you sure you want to remove: <strong>{this.props.label}</strong>
            ?
          </AlertDanger>
        )}
      </>
    );
  }

  handleOnClick = () => {
    if (!this.props.isActive) {
      this.props.selectTemplate(this.props.reportId, this.props.reportType, {
        key: this.props.value,
        title: this.props.label
      });
    }
    this.props.onClose();
  };

  handleOnRename = () => {
    this.setState({ showRename: true });
  };

  handleOnDelete = () => {
    this.setState({ showAlert: true });
  };

  handleOnDeleteConfirm = () => {
    this.setState({ showAlert: false });
    this.props.removeTemplate(this.props.userId, { key: this.props.value });
  };

  handleOnDeleteCancel = () => {
    this.setState({ showAlert: false });
  };

  handleOnChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleOnKeyDown = (e) => {
    if (e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ showRename: false, name: "" });
    }
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    if (!this.state.name) return;

    this.props.saveTemplate(
      this.props.reportId,
      this.props.reportType,
      {
        title: this.state.name,
        key: this.props.value
      },
      this.props.pages
    );
    this.setState({ showRename: false, name: "" });
  };

  handleOnCancel = () => {
    this.setState({ showRename: false, name: "" });
  };

  handleOnFocus = () => {
    this.setState({ isFocused: true });
  };

  handleOnBlur = () => {
    this.setState({ isFocused: false });
  };
}

CustomizeModalTemplateCard.propTypes = {
  reportId: PropTypes.number.isRequired,
  reportType: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  pages: PropTypes.array.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  pageCount: PropTypes.number.isRequired,
  hasPageCount: PropTypes.bool.isRequired,
  isCustom: PropTypes.bool.isRequired,
  isActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired,
  selectTemplate: PropTypes.func.isRequired,
  removeTemplate: PropTypes.func.isRequired
};

export default CustomizeModalTemplateCard;
