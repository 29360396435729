import React from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import {
  Container,
  Content,
  Confetti,
  Icon,
  Text,
  GlobalStyle
} from "./styled/customize-modal-congratulations";

function CustomizeModalPowerPackCongratulations({ onClick }) {
  return (
    <Container>
      <GlobalStyle />
      <Confetti />
      <Content>
        <Icon />
        <Text>
          Your Power Pack content is ready to be added to your report.
        </Text>
        <Button app="suite" onClick={onClick}>
          Back to Report
        </Button>
      </Content>
    </Container>
  );
}

CustomizeModalPowerPackCongratulations.propTypes = {
  onClick: PropTypes.func
};

export default CustomizeModalPowerPackCongratulations;
