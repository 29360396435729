import React from "react";

function IconHomebeat(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 243 243" {...props}>
      <path
        d="M152.1 144.81a1.5 1.5 0 0 1-1.5-1.5v-28.67l-29.1-28.18-29.1 28.18v28.67a1.5 1.5 0 0 1-3 0V114a1.49 1.49 0 0 1 .45-1.07l30.61-29.63a1.48 1.48 0 0 1 2.08 0l30.61 29.63a1.49 1.49 0 0 1 .45 1.07v29.31a1.5 1.5 0 0 1-1.5 1.5z"
        fill="#576474"
      />
      <path
        fill="#e1e5e9"
        d="M127.93 143.31v-16.42a6.43 6.43 0 1 0-12.86 0v16.42"
      />
      <path
        d="M127.93 144.81a1.5 1.5 0 0 1-1.5-1.5v-16.42a4.93 4.93 0 1 0-9.86 0v16.42a1.5 1.5 0 0 1-3 0v-16.42a7.93 7.93 0 1 1 15.86 0v16.42a1.5 1.5 0 0 1-1.5 1.5z"
        fill="#576474"
      />
      <path
        d="M121.5 218a96.53 96.53 0 1 1 96.5-96.5 96.64 96.64 0 0 1-96.5 96.5zm0-190a93.53 93.53 0 1 0 93.5 93.5A93.64 93.64 0 0 0 121.5 28z"
        fill="#c4c8d0"
      />
      <path
        d="M121.5 243A121.5 121.5 0 1 1 243 121.5 121.64 121.64 0 0 1 121.5 243zm0-240A118.5 118.5 0 1 0 240 121.5 118.64 118.64 0 0 0 121.5 3z"
        fill="#c4c8d0"
      />
      <path
        d="M121.5 243A121.5 121.5 0 1 1 243 121.5 121.64 121.64 0 0 1 121.5 243zm0-240A118.5 118.5 0 1 0 240 121.5 118.64 118.64 0 0 0 121.5 3z"
        fill="#c4c8d0"
      />
      <path
        d="M121.5 193.45a71.95 71.95 0 1 1 71.95-71.95 72 72 0 0 1-71.95 71.95zm0-140.9a68.95 68.95 0 1 0 68.95 69 69 69 0 0 0-68.95-69z"
        fill="#c4c8d0"
      />
      <path
        d="M206.91 64.41a9.46 9.46 0 1 1-9.45-9.41 9.45 9.45 0 0 1 9.45 9.41zM45.38 161a9.46 9.46 0 1 1-9.45-9.45 9.45 9.45 0 0 1 9.45 9.45z"
        fill="#00b672"
        className="dots"
      />
    </svg>
  );
}

export default IconHomebeat;
