import styled from "styled-components";

export const Container = styled.div`
  display: none;
  align-items: center;
  justify-content: space-between;
  width: 10.1rem;
  height: 4.2rem;
  margin-bottom: ${({ hasFooter }) => (hasFooter ? "2.4rem" : 0)};
  border-radius: 10rem;
  background-color: rgba(87, 100, 116, 0.76);
  box-shadow: ${({ theme }) => theme.shadows.overlay};
  transform: translateX(-50%);
  position: fixed;
  left: 50%;
  bottom: ${({ hasFooter }) => (hasFooter ? "6rem" : "2rem")};
  z-index: 10;

  &.show {
    display: flex;

    ${({ theme }) => theme.media.sm`
      display: none;
    `}
  }

  ${({ theme }) => theme.media.sm`
    display: none;
  `}

  svg {
    width: 1.2rem;
    height: 1.2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3rem;
  height: 100%;
  padding: 0;
  appearance: none;
  cursor: pointer;
`;
