import { UserAvatar } from "@wrstudios/components";
import { IconHomeDollar } from "@wrstudios/icons";
import { getInitials } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { transformSubjectProperty } from "../../../utils/subject-property";
import AddressAutoComplete from "../../address/AddressAutoComplete";
import Label from "../../common/Label";
import Loading from "../../common/Loading";
import { requestCMA } from "./api";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerRating from "./HomebeatViewerRating";
import {
  AgentCell,
  AgentCompany,
  AgentGrid,
  AgentLink,
  AgentName,
  Cell,
  Content,
  Details,
  Error,
  Grid,
  HomebeatViewerButton,
  Input,
  Intro,
  IntroCell,
  IntroGrid,
  Modal,
  ModalBody,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Quote,
  QuoteName,
  RatingHeader,
  RequestContent,
  Text,
  TextArea
} from "./styled/homebeat-viewer-valuation-modal";

function HomebeatViewerValuationModal({ onClose }) {
  const { agent, report, subjectProperty, setSubjectProperty } =
    useHomebeatViewer();
  const modalContentRef = useRef();
  const [address, setAddress] = useState(subjectProperty.address || "");
  const [addressCoords, setAddressCords] = useState({
    lat: subjectProperty.coords.lat || "",
    lng: subjectProperty.coords.lon || ""
  });
  const [beds, setBeds] = useState(subjectProperty.beds || "");
  const [baths, setBaths] = useState(subjectProperty.baths || "");
  const [sqft, setSqft] = useState(subjectProperty.sqft || "");
  const [garages, setGarages] = useState(subjectProperty.garages || "");
  const [lotsize, setLotsize] = useState(subjectProperty.lotSize || "");
  const [details, setDetails] = useState(subjectProperty.details || "");
  const [conditionRating, setConditionRating] = useState(
    Number(subjectProperty.conditionRating || 3)
  );
  const [taxData, setTaxData] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [error, setError] = useState("");
  const [isClosing, setIsClosing] = useState(false);

  const handleAddressSelect = (address, taxData) => {
    const [lng, lat] = address.coordinates;
    setAddress(address.value);
    setAddressCords({ lat, lng });
    setTaxData(taxData);
    if (taxData.beds) setBeds(taxData.beds);
    if (taxData.baths) setBaths(taxData.baths);
    if (taxData.sqft) setSqft(taxData.sqft);
    if (taxData.garages) setGarages(taxData.garages);
    if (taxData.lotsize) setLotsize(taxData.lotsize);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const homebeat = await requestCMA(
        report.guid,
        {
          address,
          geo_lat: addressCoords.lat,
          geo_lon: addressCoords.lon,
          sqft,
          beds,
          baths,
          garages,
          lot_size: lotsize
        },
        {
          details: details
        },
        {
          condition: conditionRating
        }
      );
      setSubjectProperty((subjectProperty) => ({
        ...subjectProperty,
        ...transformSubjectProperty(homebeat.subjectProperty || {})
      }));
      setHasSent(true);
      modalContentRef.current.style.transition = "none";
      modalContentRef.current.style.transform = `translateX(0%)`;
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Hack around mounting and animating
  useEffect(() => {
    modalContentRef.current.style.transition = "transform 0.2s";

    if (!isClosing) {
      setTimeout(() => {
        modalContentRef.current.style.transform = `translateX(0%)`;
      });
    } else {
      modalContentRef.current.style.transform = `translateX(110%)`;
    }
  }, [isClosing, hasSent]);

  return (
    <>
      {!hasSent && (
        <Modal
          focusLock={{ returnFocus: true }}
          onClose={() => setIsClosing(true)}>
          <ModalContent
            ref={modalContentRef}
            onTransitionEnd={() => isClosing && onClose()}>
            <ModalHeader>
              Expert Home Valuation
              <ModalClose />
            </ModalHeader>
            <ModalBody>
              <Intro>
                <IntroGrid>
                  <IntroCell>
                    <UserAvatar
                      size="md"
                      initials={getInitials(agent.name)}
                      avatarUrl={agent.avatarUrl}
                    />
                  </IntroCell>
                  <IntroCell>
                    <i>
                      <Quote>
                        Your home's true market value depends on <br />
                        unique qualities and an analysis from a local <br />
                        market professional. I'd be happy to help you <br />
                        assess pricing for your home with a <br />
                        no-obligation consultation.
                      </Quote>{" "}
                      <QuoteName>-{agent.name}</QuoteName>
                    </i>
                  </IntroCell>
                </IntroGrid>
              </Intro>
              <Content>
                <Text>
                  Please confirm the details of your home and note your homes
                  unique characteristics.
                </Text>
                <Grid>
                  <Cell style={{ width: "100%" }}>
                    <AddressAutoComplete
                      address={subjectProperty.address}
                      onSelect={handleAddressSelect}
                    />
                  </Cell>
                  <Cell style={{ width: "calc(100% / 3)" }}>
                    <Label htmlFor="beds">Beds</Label>
                    <Input
                      id="beds"
                      hasTaxData={!!taxData.beds}
                      value={beds}
                      onChange={(e) => {
                        setTaxData(({ beds, ...taxData }) => taxData);
                        setBeds(e.target.value);
                      }}
                    />
                  </Cell>
                  <Cell style={{ width: "calc(100% / 3)" }}>
                    <Label htmlFor="baths">Baths</Label>
                    <Input
                      id="baths"
                      hasTaxData={!!taxData.baths}
                      value={baths}
                      onChange={(e) => {
                        setTaxData(({ baths, ...taxData }) => taxData);
                        setBaths(e.target.value);
                      }}
                    />
                  </Cell>
                  <Cell style={{ width: "calc(100% / 3)" }}>
                    <Label htmlFor="sqft">Sqft</Label>
                    <Input
                      id="sqft"
                      hasTaxData={!!taxData.sqft}
                      value={sqft}
                      onChange={(e) => {
                        setTaxData(({ sqft, ...taxData }) => taxData);
                        setSqft(e.target.value);
                      }}
                    />
                  </Cell>
                  <Cell style={{ width: "calc(100% / 3)" }}>
                    <Label htmlFor="garages">Garages</Label>
                    <Input
                      id="garages"
                      hasTaxData={!!taxData.garages}
                      value={garages}
                      onChange={(e) => {
                        setTaxData(({ garages, ...taxData }) => taxData);
                        setGarages(e.target.value);
                      }}
                    />
                  </Cell>
                  <Cell style={{ width: "calc(100% / 3)" }}>
                    <Label htmlFor="lot-size">Lot Size</Label>
                    <Input
                      id="lot-size"
                      hasTaxData={!!taxData.lotsize}
                      value={lotsize}
                      onChange={(e) => {
                        setTaxData(({ lotsize, ...taxData }) => taxData);
                        setLotsize(e.target.value);
                      }}
                    />
                  </Cell>
                  <Cell style={{ width: "100%" }}>
                    <RatingHeader>
                      How would you rate the property's condition?
                    </RatingHeader>
                    <HomebeatViewerRating
                      rating={conditionRating}
                      onChange={(rating) => setConditionRating(rating)}
                    />
                  </Cell>
                  <Cell style={{ width: "100%" }}>
                    <Details>
                      <Label htmlFor="details">
                        Tell us about your home's unique features and details
                      </Label>
                      <TextArea
                        id="details"
                        as="textarea"
                        rows={5}
                        placeholder="You can list renovations, updates, or unique features compared to other homes in your area."
                        value={details}
                        onChange={(e) => setDetails(e.target.value)}
                      />
                    </Details>
                  </Cell>
                </Grid>
              </Content>
            </ModalBody>
            <ModalFooter>
              <HomebeatViewerButton
                isFullWidth
                disabled={loading}
                onClick={handleSubmit}>
                {loading ? (
                  <Loading>Requesting</Loading>
                ) : (
                  <>
                    <IconHomeDollar /> Request Free Home Valuation
                  </>
                )}
              </HomebeatViewerButton>
              {error && <Error>{error}</Error>}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {hasSent && (
        <Modal onClose={() => setIsClosing(true)}>
          <ModalContent
            ref={modalContentRef}
            onTransitionEnd={() => isClosing && onClose()}>
            <ModalHeader>
              Request Sent
              <ModalClose />
            </ModalHeader>
            <ModalBody>
              <RequestContent>
                <Text>
                  Thank you for requesting a free home valuation. Expect to hear
                  from me soon. If you need to get in touch sooner, feel free to
                  contact me:
                </Text>
                <AgentGrid>
                  <AgentCell>
                    <UserAvatar
                      size="md"
                      initials={getInitials(agent.name)}
                      avatarUrl={agent.avatarUrl}
                    />
                  </AgentCell>
                  <AgentCell>
                    <AgentName>{agent.name}</AgentName>
                    {!!agent.companyName && (
                      <AgentCompany>{agent.companyName}</AgentCompany>
                    )}
                    {!!(agent.mobilePhone || agent.officePhone) && (
                      <div>
                        <AgentLink
                          href={`tel:${
                            agent.mobilePhone || agent.officePhone
                          }`}>
                          {agent.mobilePhone || agent.officePhone}
                        </AgentLink>
                      </div>
                    )}
                    {!!agent.email && (
                      <div>
                        <AgentLink href={`mailto:${agent.email}`}>
                          {agent.email}
                        </AgentLink>
                      </div>
                    )}
                  </AgentCell>
                </AgentGrid>
              </RequestContent>
            </ModalBody>
            <ModalFooter>
              <HomebeatViewerButton
                isFullWidth
                onClick={() => setIsClosing(true)}>
                Close
              </HomebeatViewerButton>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

HomebeatViewerValuationModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default HomebeatViewerValuationModal;
