import { IconWarning } from "@wrstudios/icons";
import PropTypes from "prop-types";
import React, { Component } from "react";
import { Draggable } from "react-beautiful-dnd";
import { connect } from "react-redux";
import { snakeCase } from "../../utils/formatters";
import { onTransitionEnd, removeOnTransitionEnd } from "../../utils/transition";
import CustomizeActivePagesDroppable from "./CustomizeActivePagesDroppable";
import CustomizePageEdit from "./CustomizePageEdit";
import CustomizePageEditHeadline from "./CustomizePageEditHeadline";
import CustomizePagePreview from "./CustomizePagePreview";
import CustomizePageRemove from "./CustomizePageRemove";
import {
  Control,
  Controls,
  Title,
  Warning
} from "./styled/customize-active-page";

class CustomizeActivePage extends Component {
  static propTypes = {
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    page: PropTypes.object.isRequired,
    isDragDisabled: PropTypes.bool.isRequired,
    enableDrag: PropTypes.func.isRequired,
    disableDrag: PropTypes.func.isRequired
  };

  state = {
    page: this.props.page
  };

  componentDidMount() {
    const { page } = this.props;

    this.setState({ page }, this.animate.bind(this));
  }

  UNSAFE_componentWillReceiveProps({ page }) {
    this.setState({ page }, this.animate.bind(this));
  }

  animate() {
    if (this.item) {
      const movable = document.querySelector(
        `.cma-${snakeCase(this.props.page.key)}-movable-element`
      );

      if (movable) {
        this.item.classList.remove(
          "customize-active-item-new",
          "customize-active-item-activate"
        );
        this.item.classList.add("customize-active-item-new");
        const { left, top, height } = this.item.getBoundingClientRect();
        // TODO: Remove hard coded values
        const leftOffset = this.props.page.parent === "listings" ? 27 : 32;

        movable.style.left = `${left + window.scrollX + leftOffset}px`;
        movable.style.top = `${top + window.scrollY}px`;
        movable.style.height = `${height}px`;

        onTransitionEnd(movable, () => {
          if (this.item) {
            this.item.classList.remove("customize-active-item-new");
            this.item.classList.add("customize-active-item-activate");
          }

          removeOnTransitionEnd(movable);

          // Explicitly check for the element again
          if (
            document.querySelector(
              `.cma-${snakeCase(this.props.page.key)}-movable-element`
            )
          ) {
            document.body.removeChild(movable);
          }
        });
      }
    }
  }

  render() {
    const { index, type, draggable, isDragDisabled, enableDrag, disableDrag } =
      this.props;
    const { page } = this.state;
    const isEmpty = !!page?.meta?.empty;
    const isCover = page.key === "cover" || page.key === "cover_map";
    const isRdcPage = page.key.startsWith("rdc_");

    return (
      <div ref={(item) => (this.item = item)}>
        <Draggable
          draggableId={`draggable-${page.key}`}
          isDragDisabled={isDragDisabled}
          index={index}
          type={type}>
          {(provided, snapshot) => (
            <>
              <div
                className={`mt-4 customize-active-item-draggable customize-active-item-draggable-${snakeCase(
                  page.key
                )} ${snapshot.isDragging ? "dragging" : ""} ${
                  page.key === "listings"
                    ? `border border-grey-super-light shadow-raised bg-white rounded-md p-5`
                    : ""
                } ${
                  snapshot.isDragging && page.key === "listings"
                    ? "shadow-overlay cursor-move"
                    : ""
                } ${isDragDisabled ? "" : "cursor-move"}`}
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}>
                <div
                  className={`flex items-center relative customize-active-item customize-active-item-${snakeCase(
                    page.key
                  )} ${page.key === "listings" ? "mb-6" : ""}`}>
                  {page.key !== "listings" && (
                    <CustomizePageRemove page={page} />
                  )}
                  <div
                    className={`w-full flex flex-wrap items-center customize-active-item-inner ${
                      snapshot.isDragging && page.key !== "listings"
                        ? "shadow-overlay cursor-move"
                        : ""
                    } ${
                      page.key === "listings"
                        ? ""
                        : "border-1 border-grey-super-light shadow-raised bg-white rounded-md p-5"
                    } ${isDragDisabled ? "" : "cursor-move"}`}
                    aria-label={`Report Option: ${page.title}`}>
                    {page.key === "listings" && (
                      <Title isActive isEmpty={isEmpty}>
                        {page.title}
                      </Title>
                    )}
                    {page.key !== "listings" && (
                      <CustomizePagePreview
                        isActive
                        isEmpty={isEmpty}
                        page={page}
                        onOpen={disableDrag}
                        onClose={enableDrag}
                      />
                    )}
                    <Controls isCover={isCover}>
                      {isCover && (
                        <Control isCover={isCover}>
                          <CustomizePageEditHeadline
                            isEmpty={isEmpty}
                            page={page}
                            onEdit={disableDrag}
                            onSave={enableDrag}
                          />
                        </Control>
                      )}
                      {!isCover && isEmpty && (
                        <Control isCover={isCover}>
                          <Warning>
                            <IconWarning />
                            <span>Empty</span>
                          </Warning>
                        </Control>
                      )}
                      {!isCover && (
                        <Control isCover={isCover}>
                          <CustomizePageEdit
                            page={page}
                            onOpen={disableDrag}
                            onClose={enableDrag}
                          />
                        </Control>
                      )}
                      {isRdcPage && (
                        <Control isCover={false}>
                          <svg
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 24 23">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M24 12.1 12.012 0 0 12.1h3.664v10.856h16.68V12.1H24Zm-7.414-3.17c-.317-.312-.823-.483-1.433-.483-1.005 0-1.638.412-2.073.77l-.008.008c-.435.411-.933.971-1.345 2.059l.019-.863.005-.248c.016-.606.024-1.127.024-1.19 0-.131-.095-.271-.301-.271H7.573v.326l.103.008c.316.023.617.054.894.249a.976.976 0 0 1 .443.676c.079.334.079 1.313.079 1.733v5.556c0 .567-.016 1.345-.103 1.865-.158.505-.324.66-.665.832-.19.085-.427.147-.696.178l-.11.008v.327h5.823v-.327l-.103-.008a1.977 1.977 0 0 1-.649-.155c-.174-.062-.324-.218-.482-.505-.23-.482-.372-1.026-.372-2.347v-2.316c0-.7.032-1.375.055-1.67.261-1.85 1.282-3.335 1.947-3.335.253 0 .42.272.601.708.214.497.515 1.025 1.377 1.025.855 0 1.306-.707 1.306-1.414.008-.482-.135-.87-.435-1.197Z"
                              fill="#D92228"
                            />
                          </svg>
                        </Control>
                      )}
                    </Controls>
                  </div>
                </div>
                {page.children && page.children.length > 0 && (
                  <CustomizeActivePagesDroppable
                    pages={page.children}
                    type="listings"
                    draggable={draggable}
                    enableDrag={enableDrag}
                    disableDrag={disableDrag}
                  />
                )}
              </div>
              {provided.placeholder}
            </>
          )}
        </Draggable>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => ({
  moveInactivePage: (key, location) => dispatch(moveInactivePage(key, location))
});

export default connect(null, mapDispatchToProps)(CustomizeActivePage);
