import React from "react";
import Icon from "../../Icon";

function IconKeyboardArrowDown(props) {
  return (
    <Icon {...props}>
      <path d="M7.41 7.84L12 12.42l4.59-4.58L18 9.25l-6 6-6-6z" />
      <path d="M0-.75h24v24H0z" fill="none" />
    </Icon>
  );
}

export default IconKeyboardArrowDown;
