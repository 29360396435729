import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import {
  IconCalendar,
  IconChecked,
  IconClosePill,
  IconCopy,
  IconOrderArrowDown,
  IconOrderArrowUp,
  IconPencil,
  IconTrashCan
} from "@wrstudios/icons";
import { formatNumber, pluralize } from "@wrstudios/utils";
import { format } from "date-fns";
import { get } from "lodash";
import PropTypes from "prop-types";
import React, { useEffect, useLayoutEffect, useRef, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Link, useHistory } from "react-router-dom";
import { useSession } from "../../Session";
import { useQueryString } from "../../utils/url";
import AlertDanger from "../common/AlertDanger";
import Button from "../common/Button";
import Checkbox from "../common/Checkbox";
import FAB from "../common/FAB";
import Loading from "../common/Loading";
import Navigation from "../common/Navigation";
import Notifications from "../common/Notifications";
import Search from "../common/Search";
import Skeleton from "../common/Skeleton";
import Tabs from "../common/Tabs";
import WistiaModal from "../common/WistiaModal";
import HomebeatEditor from "./editor/HomebeatEditor";
import HomebeatMarketing from "./HomebeatMarketing";
import {
  DELETE_HOMEBEAT,
  DELETE_HOMEBEATS,
  GET_HOMEBEATS,
  RESEND_HOMEBEAT
} from "./queries";
import {
  CityStateZip,
  Container,
  Control,
  Controls,
  CopiedText,
  Export,
  Frequency,
  Header,
  Name,
  Paginate,
  Preview,
  RightSkeleton,
  SearchActions,
  SearchCell,
  SearchContainer,
  SearchGrid,
  Street,
  SubHeader,
  SubHeaderInner,
  Table,
  TableContainer,
  TableData,
  TableDataEmpty,
  TableDataGettingStarted,
  TableHeading,
  TableHeadingContent,
  TableHeadingIcon,
  TableIcon,
  TableIconText,
  TableRow,
  Title,
  Tooltip,
  Wrapper
} from "./styled/homebeats";
import { getFrequencyFriendlyName } from "./utils";

const TOTAL_HOMEBEAT_LIMIT = 2000;

function Homebeats() {
  const { features, config } = useSession();
  const { params, updateSearchQuery } = useQueryString();
  const [search, setSearch] = useState(params.q || "");
  const [currentPage, setCurrentPage] = useState(params.page || 1);
  const [sortBy, setSortBy] = useState(params.sort_by || "last_sent");
  const [sortDir, setSortDir] = useState(params.sort_direction || "desc");
  const [filter, setFilter] = useState(params.filter || "");
  const [showGettingStartedVideo, setShowGettingStartedVideo] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [getHomebeats, { data, loading }] = useLazyQuery(GET_HOMEBEATS, {
    fetchPolicy: "cache-and-network"
  });
  const meta = get(data, "homebeats.meta", {});
  const homebeats = get(data, "homebeats.results", []);
  const [selectedHomebeatIds, setSelectedHomebeatIds] = useState([]);
  const selectedHomebeats = homebeats.filter((homebeat) =>
    selectedHomebeatIds.includes(homebeat.id)
  );
  const selectedHomebeatsThatCanBeSetup = selectedHomebeats.filter(
    (homebeat) => {
      const frequency = !homebeat.optInAt && homebeat.frequency
        ? "pending_opt_in"
        : homebeat.frequency;
      const frequencyLabel = getFrequencyFriendlyName(
        frequency,
        !!homebeat.inactiveAt
      );
      return frequencyLabel === "Not Set Up";
    }
  );
  const selectedHomebeatsThatCanBeSetupSnapshotRef = useRef(
    selectedHomebeatsThatCanBeSetup
  );
  const [isSettingUp, setIsSettingUp] = useState(false);
  const allHomebeatsChecked =
    homebeats.length > 0 &&
    selectedHomebeatIds.length === homebeats.length &&
    !loading;
  const [deleteHomebeats, { loading: deletingHomebeats }] = useMutation(
    DELETE_HOMEBEATS,
    {
      refetchQueries: [
        {
          query: GET_HOMEBEATS,
          variables: {
            page: Number(currentPage || 1),
            search,
            sortBy,
            sortDir,
            filter
          }
        }
      ],
      awaitRefetchQueries: true,
      variables: { ids: selectedHomebeatIds.join("&ids[]=") },
      onCompleted: () => {
        setSelectedHomebeatIds([]);
      }
    }
  );

  const handleFilter = (filter) => {
    updateSearchQuery({ filter });
    setFilter(filter);
  };

  const handleSort = (newSortBy) => {
    if (loading) return;

    const newSortDir =
      sortBy !== newSortBy || sortDir === "asc" ? "desc" : "asc";

    updateSearchQuery({ sort_by: newSortBy, sort_direction: newSortDir });
    setSortBy(newSortBy);
    setSortDir(newSortDir);
  };

  useLayoutEffect(() => {
    if (features.hasHomebeat) {
      setSelectedHomebeatIds([]);
      getHomebeats({
        variables: {
          page: Number(currentPage || 1),
          search,
          sortBy,
          sortDir,
          filter
        }
      });
    }
  }, [currentPage, search, sortBy, sortDir, filter]);

  if (features.offerHomebeat) {
    return <HomebeatMarketing />;
  }

  if (isSettingUp) {
    return (
      <HomebeatEditor
        homebeats={selectedHomebeatsThatCanBeSetupSnapshotRef.current}
        isEditing
        onFinish={() => {
          setIsSettingUp(false);
          setSelectedHomebeatIds([]);
          selectedHomebeatsThatCanBeSetupSnapshotRef.current = [];
        }}
      />
    );
  }

  return (
    <>
      <Navigation />
      <Notifications />
      <Wrapper>
        <Container>
          <Header>
            <div>
              <Title>Homebeats</Title>
            </div>
            <SearchContainer>
              <Search
                defaultValue={search}
                placeholder="Search Homebeats"
                onSearch={(searchTerm) => {
                  setSearch(searchTerm);
                  setCurrentPage(1);
                }}
                onClear={() => {
                  setSearch("");
                  setCurrentPage(1);
                }}
              />
              <SearchActions>
                <SearchGrid>
                  <SearchCell>
                    <Button
                      as={Link}
                      app="cma"
                      to="/homebeats/import"
                      variant="secondary">
                      Import
                    </Button>
                  </SearchCell>
                  <SearchCell>
                    <Button app="cma" as={Link} to="/homebeats/new">
                      Add
                    </Button>
                  </SearchCell>
                </SearchGrid>
              </SearchActions>
            </SearchContainer>
          </Header>
          <SubHeader>
            <SubHeaderInner>
              <Tabs>
                <Tabs.Tab isActive={!filter} onClick={() => handleFilter("")}>
                  All
                </Tabs.Tab>
                <Tabs.Tab
                  isActive={filter === "active"}
                  onClick={() => handleFilter("active")}>
                  Active
                </Tabs.Tab>
                <Tabs.Tab
                  isActive={filter === "not_set_up"}
                  onClick={() => handleFilter("not_set_up")}>
                  Not Set Up
                </Tabs.Tab>
                <Tabs.Tab
                  isActive={filter === "pending_opt_in"}
                  onClick={() => handleFilter("pending_opt_in")}>
                  Pending
                </Tabs.Tab>
                <Tabs.Tab
                  isActive={filter === "inactive"}
                  onClick={() => handleFilter("inactive")}>
                  Inactive
                </Tabs.Tab>
                <Tabs.Tab
                  isActive={filter === "unsubscribed"}
                  onClick={() => handleFilter("unsubscribed")}>
                  Unsubscribed
                </Tabs.Tab>
              </Tabs>
              <Export href="/homebeats/report.csv">Export</Export>
            </SubHeaderInner>
          </SubHeader>
          <TableContainer>
            <Table>
              <thead>
                <TableRow>
                  <TableHeading>
                    <Checkbox
                      checked={allHomebeatsChecked}
                      onChange={() => {
                        const ids = allHomebeatsChecked
                          ? []
                          : homebeats.map(({ id }) => id);
                        setSelectedHomebeatIds(ids);
                      }}
                    />
                  </TableHeading>
                  <TableHeading
                    onClick={() => handleSort("name")}
                    showSort={sortBy === "name"}
                    sortDir={sortDir}>
                    <TableHeadingContent>
                      Name
                      <TableHeadingIcon>
                        <IconOrderArrowDown className="desc" />
                        <IconOrderArrowUp className="asc" />
                      </TableHeadingIcon>
                    </TableHeadingContent>
                  </TableHeading>
                  <TableHeading
                    onClick={() => handleSort("address")}
                    showSort={sortBy === "address"}
                    sortDir={sortDir}>
                    <TableHeadingContent>
                      Address
                      <TableHeadingIcon>
                        <IconOrderArrowDown className="desc" />
                        <IconOrderArrowUp className="asc" />
                      </TableHeadingIcon>
                    </TableHeadingContent>
                  </TableHeading>
                  <TableHeading
                    onClick={() => handleSort("email")}
                    showSort={sortBy === "email"}
                    sortDir={sortDir}>
                    <TableHeadingContent>
                      Email
                      <TableHeadingIcon>
                        <IconOrderArrowDown className="desc" />
                        <IconOrderArrowUp className="asc" />
                      </TableHeadingIcon>
                    </TableHeadingContent>
                  </TableHeading>
                  <TableHeading
                    onClick={() => handleSort("status")}
                    showSort={sortBy === "status"}
                    sortDir={sortDir}>
                    <TableHeadingContent>
                      Status
                      <TableHeadingIcon>
                        <IconOrderArrowDown className="desc" />
                        <IconOrderArrowUp className="asc" />
                      </TableHeadingIcon>
                    </TableHeadingContent>
                  </TableHeading>
                  <TableHeading
                    onClick={() => handleSort("last_sent")}
                    showSort={sortBy === "last_sent"}
                    sortDir={sortDir}>
                    <TableHeadingContent>
                      Last Sent
                      <TableHeadingIcon>
                        <IconOrderArrowDown className="desc" />
                        <IconOrderArrowUp className="asc" />
                      </TableHeadingIcon>
                    </TableHeadingContent>
                  </TableHeading>
                  <TableHeading>CMA Request</TableHeading>
                  <TableHeading>Last Viewed</TableHeading>
                  <TableHeading>Views</TableHeading>
                  <TableHeading />
                </TableRow>
              </thead>
              <tbody>
                {loading &&
                  new Array(20).fill().map((_, index) => (
                    <TableRow isLoading key={index}>
                      <TableData>
                        <Skeleton style={{ width: "2rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "6rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "29rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "15rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "10rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "8rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "6.5rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "6.5rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <Skeleton style={{ width: "4rem", height: "2rem" }} />
                      </TableData>
                      <TableData>
                        <RightSkeleton>
                          <Skeleton
                            style={{ width: "15.4rem", height: "2rem" }}
                          />
                        </RightSkeleton>
                      </TableData>
                    </TableRow>
                  ))}
                {!loading && !homebeats.length && !filter && !search && (
                  <TableRow>
                    <TableDataGettingStarted
                      colSpan={10}
                      tabIndex="0"
                      onClick={() => setShowGettingStartedVideo(true)}>
                      Getting Started with Homebeat
                    </TableDataGettingStarted>
                  </TableRow>
                )}
                {!loading && !homebeats.length && !!(filter || search) && (
                  <TableRow>
                    <TableDataEmpty colSpan={10}>
                      No Homebeats Found
                    </TableDataEmpty>
                  </TableRow>
                )}
                {!loading &&
                  homebeats.map((homebeat) => (
                    <Homebeat
                      key={homebeat.id}
                      homebeat={homebeat}
                      currentPage={currentPage}
                      search={search}
                      sortBy={sortBy}
                      sortDir={sortDir}
                      filter={filter}
                      deletingHomebeats={deletingHomebeats}
                      selectedHomebeatIds={selectedHomebeatIds}
                      setSelectedHomebeatIds={setSelectedHomebeatIds}
                    />
                  ))}
              </tbody>
            </Table>
          </TableContainer>
          <Paginate
            scrollToTopOnPageChange
            currentPage={meta.currentPage}
            totalPages={meta.totalPages}
            perPage={meta.perPage}
            totalResults={meta.totalResults}
            onPageChange={setCurrentPage}>
            {formatNumber(TOTAL_HOMEBEAT_LIMIT - meta.totalResults)} out of{" "}
            {formatNumber(TOTAL_HOMEBEAT_LIMIT)} Homebeats remaining.
          </Paginate>
        </Container>
      </Wrapper>
      {showGettingStartedVideo && (
        <WistiaModal
          videoId={config.homebeatKickStartVideoId}
          videoTitle="Getting Started with Homebeat"
          onClose={() => setShowGettingStartedVideo(false)}>
          Getting Started with Homebeat
        </WistiaModal>
      )}
      {!!selectedHomebeats.length && (
        <FAB>
          {!!selectedHomebeatsThatCanBeSetup.length && (
            <FAB.Item
              onClick={() => {
                selectedHomebeatsThatCanBeSetupSnapshotRef.current =
                  selectedHomebeatsThatCanBeSetup;
                setIsSettingUp(true);
              }}>
              <IconCalendar /> Set Up
            </FAB.Item>
          )}
          <FAB.Item
            disabled={deletingHomebeats}
            onClick={() => setShowDeleteConfirm(true)}>
            {!deletingHomebeats && (
              <>
                <IconTrashCan /> Delete
              </>
            )}
            {deletingHomebeats && <Loading>Deleting</Loading>}
          </FAB.Item>
        </FAB>
      )}
      {showDeleteConfirm && (
        <AlertDanger
          title="Delete Homebeats"
          confirmButton="Delete Forever"
          onClose={() => setShowDeleteConfirm(false)}
          onConfirm={deleteHomebeats}>
          Are you sure you want to remove{" "}
          <strong>{selectedHomebeats.length}</strong>{" "}
          {pluralize("Homebeat", selectedHomebeats.length)}?
        </AlertDanger>
      )}
    </>
  );
}

function Homebeat({
  homebeat,
  selectedHomebeatIds,
  currentPage,
  search,
  sortBy,
  sortDir,
  filter,
  deletingHomebeats,
  setSelectedHomebeatIds
}) {
  const history = useHistory();
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const checked = selectedHomebeatIds.includes(homebeat.id);
  const frequency = !homebeat.optInAt && homebeat.frequency ? "pending_opt_in" : homebeat.frequency;
  const frequencyLabel = getFrequencyFriendlyName(
    frequency,
    !!homebeat.inactiveAt
  );
  const [deleteHomebeat, { loading: deleting }] = useMutation(DELETE_HOMEBEAT, {
    refetchQueries: [
      {
        query: GET_HOMEBEATS,
        variables: {
          page: Number(currentPage || 1),
          search,
          sortBy,
          sortDir,
          filter
        }
      }
    ],
    awaitRefetchQueries: true,
    variables: { id: homebeat.id },
    onCompleted() {
      setSelectedHomebeatIds((ids) => ids.filter((id) => id !== homebeat.id));
    }
  });
  const [resendHomebeat, { loading: resending }] = useMutation(
    RESEND_HOMEBEAT,
    {
      variables: {
        input: { id: homebeat.id }
      }
    }
  );
  const isSelected = selectedHomebeatIds.includes(homebeat.id);
  const isDeleting = (deletingHomebeats && isSelected) || deleting;
  const isDisabled = frequencyLabel === "Unsubscribed";
  const [street, cityStateZip] = homebeat.subjectProperty.addressParts || [];
  const [hasCopied, setHasCopied] = useState(false);
  const isMountedRef = useRef();

  const handleEdit = () => {
    history.push(`/homebeats/${homebeat.id}/edit`);
  };

  const handleChange = () => {
    setSelectedHomebeatIds((ids) =>
      checked ? ids.filter((id) => id !== homebeat.id) : [...ids, homebeat.id]
    );
  };

  const handleCopied = () => {
    setHasCopied(true);
    setTimeout(() => {
      if (!isMountedRef) return;
      setHasCopied(false);
    }, 2500);
  };

  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  return (
    <TableRow key={homebeat.id} selected={isSelected}>
      <TableData deleting={isDeleting}>
        <Checkbox checked={checked} onChange={handleChange} />
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        <Name>{homebeat.lead.name}</Name>
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        <Street>
          {street} <CityStateZip>{cityStateZip}</CityStateZip>
        </Street>
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        {homebeat.lead.email}
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        <Frequency
          frequency={frequency}
          inactive={!!homebeat.inactiveAt}
          hasIcon={
            ["Unsubscribed", "Inactive"].includes(frequencyLabel) ||
            !["Unsubscribed", "Inactive", "Not Set Up", "Pending"].includes(
              frequencyLabel
            )
          }>
          {frequencyLabel !== "Not Set Up" && (
            <>
              {["Unsubscribed", "Inactive"].includes(frequencyLabel) && (
                <IconClosePill />
              )}
              {!["Unsubscribed", "Inactive", "Not Set Up", "Pending"].includes(
                frequencyLabel
              ) && <IconCalendar />}
            </>
          )}
          <div>{frequencyLabel}</div>
        </Frequency>
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        {frequency && homebeat.lastSent
          ? format(new Date(homebeat.lastSent), "M/d/yyyy")
          : "-"}
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        {homebeat.report.eventSummary.lastRequestedCMAAt
          ? format(
              new Date(homebeat.report.eventSummary.lastRequestedCMAAt),
              "M/d/yyyy"
            )
          : "-"}
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        {homebeat.report.eventSummary.lastViewedAt
          ? format(
              new Date(homebeat.report.eventSummary.lastViewedAt),
              "M/d/yyyy"
            )
          : "-"}
      </TableData>
      <TableData
        deleting={isDeleting}
        tabIndex={isDisabled ? undefined : 0}
        onClick={isDisabled ? undefined : handleEdit}>
        {homebeat.report.eventSummary.viewCount
          ? formatNumber(homebeat.report.eventSummary.viewCount, "0,0")
          : "-"}
      </TableData>
      <TableData deleting={isDeleting}>
        <Controls>
          <Control>
            {!["Not Set Up", "Pending"].includes(frequencyLabel) && (
              <Preview
                href={`${window.location.origin}/homebeat/${homebeat.report.guid}`}
                target="_blank"
                rel="noopener noreferrer">
                View
              </Preview>
            )}
            {frequencyLabel === "Pending" && (
              <Preview
                as="button"
                disabled={resending}
                onClick={() => resendHomebeat()}>
                {resending ? "Sending" : "Resend"}
              </Preview>
            )}
            {frequencyLabel === "Not Set Up" && (
              <Preview as={Link} to={`/homebeats/${homebeat.id}/edit`}>
                Set Up
              </Preview>
            )}
          </Control>
          <Control>
            <TableIcon
              disabled={isDisabled}
              onClick={isDisabled ? undefined : handleEdit}>
              <Tooltip text={isDisabled ? "Client Unsubscribed" : "Edit"}>
                <TableIconText>
                  <IconPencil />
                </TableIconText>
              </Tooltip>
            </TableIcon>
          </Control>
          <Control>
            <TableIcon as="div">
              <CopyToClipboard
                text={`${window.location.origin}/homebeat/${homebeat.report.guid}`}
                onCopy={handleCopied}>
                <Tooltip
                  text={
                    hasCopied ? (
                      <CopiedText>
                        <IconChecked />
                        Link Copied!
                      </CopiedText>
                    ) : (
                      "Copy Link"
                    )
                  }>
                  <IconCopy />
                </Tooltip>
              </CopyToClipboard>
            </TableIcon>
          </Control>
          <Control>
            <TableIcon onClick={() => setShowDeleteConfirm(true)}>
              <Tooltip text="Delete">
                <IconTrashCan />
              </Tooltip>
            </TableIcon>
            {showDeleteConfirm && (
              <AlertDanger
                title="Delete Homebeat"
                confirmButton="Delete Forever"
                onClose={() => setShowDeleteConfirm(false)}
                onConfirm={deleteHomebeat}>
                Are you sure you want to remove the homebeat:{" "}
                <strong>{homebeat.lead.name}</strong>?
              </AlertDanger>
            )}
          </Control>
        </Controls>
      </TableData>
    </TableRow>
  );
}

Homebeat.propTypes = {
  homebeat: PropTypes.object.isRequired,
  selectedHomebeatIds: PropTypes.array.isRequired,
  setSelectedHomebeatIds: PropTypes.func.isRequired
};

export default Homebeats;
