import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Stats = styled.div``;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 -1.5rem;
`;

export const Cell = styled.div`
  min-width: 0;
  padding: 0 1.5rem;
`;

export const Stat = styled.div`
  color: ${({ theme }) => theme.colors.grayDark};
`;

export const Value = styled.div`
  font-size: 1.9rem;
  line-height: 2.6rem;
  margin-bottom: 0.3rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const Label = styled.div`
  font-size: 1.1rem;
  line-height: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
