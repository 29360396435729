import { api } from "../utils/redux";
import { ORDER_RESET, ORDER_NEW, ORDER_DISCOUNT } from "../actionTypes/order";

export function orderReset() {
  return {
    type: ORDER_RESET
  };
}

export function orderNew(sku, data) {
  return api(
    ORDER_NEW,
    { sku },
    {
      method: "post",
      url: "/orders",
      ensure: true,
      data
    }
  );
}

export function orderDiscount(sku, discount) {
  return api(
    ORDER_DISCOUNT,
    { sku, discount },
    {
      method: "get",
      url: "/orders/discount_update",
      ensure: true,
      data: {
        sku,
        discount
      }
    }
  );
}
