import { initialRequestState } from "../../utils/redux";

export function resetListingDefaultRequest(state, action) {
  const { id } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === id) {
      listing = initialRequestState(listing);
    }

    return listing;
  });

  return { ...state, all: listings };
}
