import { connect } from "react-redux";
import CustomizeActiveHiddenInputs from "../CustomizeActiveHiddenInputs";

function select({
  customize: { theme, layout, template, cover, font, language, illustrations }
}) {
  return {
    hasTemplateModified: template.hasModified || false,
    selectedTheme: theme.selected,
    selectedLayout: layout.selected,
    selectedTemplate: template.selected,
    selectedCover: cover.selected,
    selectedFont: font.selected,
    selectedLanguage: language.selected,
    illustrations
  };
}

export default connect(select)(CustomizeActiveHiddenInputs);
