import { useEffect, useRef } from "react";

export function useHighlighter() {
  const lastHighlightedName = useRef();

  const handleMouseEnter = (e) => {
    const name = e.target.dataset.name;
    lastHighlightedName.current = name;
    document.body.classList.add(`highlight-${name}`);
  };

  const handleMouseLeave = () => {
    document.body.classList.remove(`highlight-${lastHighlightedName.current}`);
  };

  useEffect(() => {
    return () => {
      handleMouseLeave();
    };
  }, []);

  return {
    handleMouseEnter,
    handleMouseLeave
  };
}

function normalizeBlank(value) {
  if (
    value === "" ||
    value === null ||
    value === undefined ||
    value === "null"
  ) {
    return "-";
  }
  return value;
}

export function getDisplayValue(listing, field) {
  if (listing) {
    return normalizeBlank(listing[field.lookupName]);
  }
  if (field.columnName === "acres") {
    return parseFloat(field.value).toFixed(2);
  }
  return normalizeBlank(field.value);
}
