import React from "react";

function IconLockOutline(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="21" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-4-1h24v24H-4z" />
        <path
          fill="currentColor"
          d="M14 7h-1V5c0-2.76-2.24-5-5-5S3 2.24 3 5v2H2C.9 7 0 7.9 0 9v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zM4.9 5c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H4.9V5zM14 19H2V9h12v10z"
        />
      </g>
    </svg>
  );
}

export default IconLockOutline;
