import React, { useState, useEffect, useRef } from "react";
import Helmet from "react-helmet";
import { sortBy } from "lodash";
import { getMLSInstructions, updateUserMLS } from "../../api/user";
import { useSession } from "../../Session";
import FormGroup from "../common/FormGroup";
import Label from "../common/Label";
import { Select, Option } from "../common/Select";
import Input from "../common/Input";
import Flash from "../common/Flash";
import Button from "../common/Button";
import Tip from "../common/Tip";
import Loading from "../common/Loading";
import { Header } from "./styled/settings-mls-credentials";
import { translate } from "../../utils/locale";

function SettingsMLSCredentials() {
  const { currentUser, mls, setMls } = useSession();
  const mounted = useRef();
  const mlsHash = mls.list.reduce(
    (list, [state, mlses]) => ({
      ...list,
      [state]: mlses.reduce(
        (list, [mlsValue, mlsKey]) => ({ ...list, [mlsKey]: mlsValue }),
        {}
      )
    }),
    {}
  );
  const [currentState] =
    Object.entries(mlsHash).find(([, mlses]) =>
      Object.keys(mlses).includes(mls.code)
    ) || [];
  const [state, setState] = useState(currentState || "");
  const [provider, setProvider] = useState(mls.code || "");
  const [userId, setUserId] = useState(mls.name || "");
  const [password, setPassword] = useState("");
  const [instructions, setInstructions] = useState(mls.instructions);
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });
  const mlses = state
    ? mlsHash[state]
    : Object.values(mlsHash).reduce((list, mls) => ({ ...list, ...mls }), {});
  const sortedMlses = sortBy(Object.entries(mlses), ([, mlsName]) =>
    mlsName.toLowerCase()
  );
  const isDisabled = !provider || !userId || request.loading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setRequest({ loading: true, success: false, error: null });
      await updateUserMLS(currentUser.id, {
        code: provider,
        name: userId,
        password
      });
      setRequest({ loading: false, success: true, error: null });
      setMls((mls) => ({ ...mls, code: provider, name: userId, instructions }));
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error: error.originalResponse.data.error
      });
    }
  };

  useEffect(() => {
    let isCurrent = true;
    if (mounted.current && provider) {
      getMLSInstructions(provider).then(({ instructions }) => {
        if (isCurrent) {
          setInstructions(instructions);
        }
      });
    }
    mounted.current = true;
    return () => {
      isCurrent = false;
    };
  }, [provider]);

  return (
    <>
      <Helmet>
        <title>
          {translate("mls.label", "MLS")} Credentials - Account Settings - Cloud
          CMA
        </title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Header>{translate("mls.label", "MLS")} Credentials</Header>
        {!mls.canEdit && (
          <Flash variant="note">
            Because you are accessing Cloud CMA using Single Sign-On, these
            settings cannot be changed.
          </Flash>
        )}
        {mls.canEdit && (
          <>
            {instructions && (
              <Flash variant="note">
                <strong>Special instructions:</strong> {instructions}
              </Flash>
            )}
            <FormGroup>
              <Label htmlFor="state">State / Province</Label>
              <Select
                id="state"
                isFullWidth
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  setProvider("");
                }}>
                <Option value="" disabled>
                  Select a State / Province
                </Option>
                {Object.keys(mlsHash).map((state) => (
                  <Option key={state} value={state}>
                    {state}
                  </Option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="mls-provider">
                {translate("mls.label", "MLS")} Provider
              </Label>
              <Select
                id="mls-provider"
                isFullWidth
                value={provider}
                onChange={(e) => setProvider(e.target.value)}>
                <Option value="" disabled>
                  Select an {translate("mls.label", "MLS")} Provider
                </Option>
                {sortedMlses.map(([key, value]) => (
                  <Option key={key} value={key}>
                    {value}
                  </Option>
                ))}
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="mls-user-id">
                {translate("mls.label")} User Id
              </Label>
              <Input
                id="mls-user-id"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="mls-user-password">
                {translate("mls.label", "MLS")} Password or Pin
              </Label>
              <Input
                id="mls-user-password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Button app="cma" isFullWidth disabled={isDisabled}>
                {!request.loading &&
                  `Update ${translate("mls.label", "MLS")} Credentials`}
                {request.loading && (
                  <Loading>
                    Updating {translate("mls.label", "MLS")} Credentials
                  </Loading>
                )}
              </Button>
            </FormGroup>
            {request.error && (
              <FormGroup>
                <Flash variant="error">{request.error}</Flash>
              </FormGroup>
            )}
            {request.success && (
              <FormGroup>
                <Flash variant="success">
                  Your {translate("mls.label", "MLS")} credentials have been
                  updated!
                </Flash>
              </FormGroup>
            )}
            <Tip>
              Your {translate("mls.label", "MLS")} credentials are both stored
              and transmitted using RSA public key encryption.
            </Tip>
          </>
        )}
      </form>
    </>
  );
}

export default SettingsMLSCredentials;
