import api from "../../../api/api";
import camelcaseKeys from "camelcase-keys";

export function getHomebeat(guid) {
  return api
    .get(`/homebeat/${guid}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function editAutomation(automationId, automation) {
  return api
    .put(`/automations/${automationId}`, { automation })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function requestCMA(guid, subjectProperty, notes, ratings) {
  return api
    .put(`/homebeats/${guid}`, {
      homebeat: { subject_property: subjectProperty, notes, ratings }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function contactAgent(guid, question) {
  return api
    .post(`/homebeats/${guid}/question`, { homebeat: { question } })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateSubjectProperty(id, apiKey, subjectProperty) {
  return api
    .put(`/automations/${id}`, {
      automation: {
        api_key: apiKey,
        subject_property: subjectProperty
      }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}
