// This needs to live here instead of a selector
// because we get report types not tied to the store
// in certain parts of the app.
export function getReportType(type) {
  if (typeof type === "undefined" || type === null) {
    throw new Error("getReportType needs a type.");
  }

  switch (type.toLowerCase()) {
    case "cma":
      return "cmas";
    case "tour":
      return "tours";
    case "property":
      return "properties";
    case "flyer":
      return "flyers";
    case "document":
      return "documents";
    default:
      console.warn(`${type} is not supported!`);
  }
}

/**
 * Grabs the type of an api key
 *
 * @param {String} type
 */
export function getAPIType(type) {
  if (typeof type === "undefined" || type === null) {
    throw new Error("getAPIType needs a type.");
  }

  switch (type.toLowerCase()) {
    case "zillow_agent_reviews":
      return "zillow";
    case "realsatisfied_agent_reviews":
      return "realsatisfied";
    case "ratedagent_reviews":
      return "ratedagent";
    case "reach150_reviews":
      return "reach150";
    case "testimonialtree_reviews":
      return "testimonialtree";
    default:
      return type;
  }
}
