import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Sidebar = styled.div`
  display: flex;
  flex-direction: column;
  width: 34rem;
  height: calc(100vh - 6rem);
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.sticky};
  position: sticky;
  top: 6rem;
  z-index: 1;

  ${({ theme, isShowingMobilePreview }) => theme.media.md`
    display: ${isShowingMobilePreview ? "none" : "flex"};
    width: 100%;
    height: auto;
  `}
`;

export const SidebarOverflow = styled.div`
  overflow: auto;
`;

export const SidebarHeader = styled.div`
  display: none;
  padding: 2.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  position: sticky;
  top: 0;
  z-index: 10;

  ${({ theme }) => theme.media.sm`
    display: block;
  `}
`;

export const SidebarContent = styled.div`
  padding: 2rem;
`;

export const SidebarFooter = styled.div`
  margin-top: auto;
  position: sticky;
  bottom: 0;
  z-index: 10;
`;
