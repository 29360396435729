import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/comparable-image";

function ComparableImage(props) {
  return <Container {...props} />;
}

ComparableImage.propTypes = {
  image: PropTypes.string,
  onClick: PropTypes.func
};

export default ComparableImage;
