import { colors } from "@wrstudios/theme";
import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  padding: 3.4rem;

  ${({ theme }) => theme.media.xl`
    flex-direction: column;
  `};
`;

export const ContentContainer = styled.div`
  margin-right: 1.6rem;

  ${({ theme }) => theme.media.xl`
    margin: 0 0 1.6rem;
  `}
`;

export const Subheader = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

export const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.6rem;
    line-height: 2rem;
  `}
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Image = styled.img`
  width: 29.6rem;
  height: 32.1rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[300]};
  border-radius: 1.2rem;
  object-fit: cover;
`;

export const ImageContainer = styled.div`
  flex-shrink: 0;
  margin-left: auto;

  ${({ theme }) => theme.media.xl`
    margin-left: 0;
  `}
`;

export const ButtonIcon = styled.span`
  display: inline-block;

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
    color: currentColor;
  }
`;

export const ProTip = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 1.6rem 0;
  padding: 1.2rem 1.6rem;
  color: ${colors.gray[900]};
  background-color: ${colors.gray[200]};
  border-radius: 0.8rem;

  strong {
    font-weight: 700;
  }

  blockquote {
    display: inline;

    &:before,
    &:after {
      content: '"';
    }
  }
`;
