import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;

  img {
    flex-shrink: 0;
    border-radius: 0.3rem;
    margin-right: 0.5rem;
  }
`;

export const Notes = styled.span`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`;
