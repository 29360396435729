import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const GlobalStyle = createGlobalStyle`
  .fill-primary {
    fill: ${({ theme }) => theme.homebeat.colors.primary};
  }

  .fill-secondary {
    fill: ${({ theme }) => theme.homebeat.colors.secondary};
  }

  .stroke-primary {
    stroke: ${({ theme }) => theme.homebeat.colors.primary};
  }

  .stroke-secondary {
    stroke: ${({ theme }) => theme.homebeat.colors.secondary};
  }

  .text-primary {
    color: ${({ theme }) => theme.homebeat.colors.primary};
  }

  .text-secondary {
    color: ${({ theme }) => theme.homebeat.colors.secondary};
  }

  .bg-primary {
    background-color: ${({ theme }) => theme.homebeat.colors.primary};
  }

  .bg-secondary {
    background-color: ${({ theme }) => theme.homebeat.colors.secondary};
  }
`;
