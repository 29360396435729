import styled, { css } from "styled-components";

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;

  ${({ theme, isCover }) => theme.media.lg`
  width: ${isCover && "100%"};
    margin-top: ${isCover && "1rem"};
    margin-left: ${isCover && 0};
  `}
`;

export const Control = styled.div`
  margin-left: 1.2rem;

  &:first-child,
  &:empty {
    margin-left: 0;
  }

  ${({ theme, isCover }) => theme.media.lg`
    width: ${isCover && "100%"};
  `}

  svg {
    width: 2.2rem;
    height: 2.2rem;
  }
`;

export const Warning = styled.div`
  display: flex;
  align-items: center;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.yellow[700]};

  svg {
    width: 1.6rem;
    height: 1.6rem;
    margin-right: 0.8rem;
    color: ${({ theme }) => theme.styleGuide.colors.yellow[600]};
  }
`;

export const Title = styled.span`
  ${({ isActive, isEmpty, theme }) =>
    isActive &&
    css`
      font-size: 1.6rem;
      line-height: 2.2rem;
      font-weight: 600;
      color: ${isEmpty && theme.styleGuide.colors.gray[600]};
    `}
`;
