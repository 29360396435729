import { useLazyQuery } from "@apollo/react-hooks";
import { pluralize } from "@wrstudios/utils";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import IconDollar from "../icons/IconDollar";
import { Card, CardBody } from "./Card";
import Skeleton from "./Skeleton";
import {
  Actions,
  Action,
  Container,
  Details,
  Image,
  Link,
  Loading,
  Notes,
  Text,
  Title
} from "./styled/basic-report-card";

const GET_REPORT_STATUS = (type) => gql`
  query GetReportStatus($id: ID!) {
    getReportStatus(id: $id)
      @rest(
        type: "${type}"
        path: "reports/{args.id}/status_update"
      ) {
      id
      status
      pdfLink: pdf_url
    }
  }
`;

export const PUBLISH_REPORT = (type) => gql`
  mutation PublishReport($id: ID!) {
    publishReport(id: $id)
      @rest(type: "${type}", path: "cmas/{args.id}/publish") {
      id
      status
    }
  }
`;

function BasicReportCard({
  id,
  guid,
  type,
  title,
  status,
  pdfLink,
  address,
  cityStateZip,
  notes,
  image,
  isInvestorConnectEnabled,
  isLiveEnabled,
  liveUrl,
  isSlideshowEnabled,
  onPublish
}) {
  const [localStatus, setLocalStatus] = useState(status);
  const [localPdfLink, setLocalPdfLink] = useState(pdfLink);
  const isPublished = localStatus === "published-report";
  const isActive = localStatus === "active-report";
  const isHomebeat = type === "Homebeat";
  const pdfLabel = isHomebeat ? "Homebeat" : "PDF";

  const [getReportStatus] = useLazyQuery(GET_REPORT_STATUS(type), {
    pollInterval: isActive ? 4000 : 0,
    variables: { id },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getReportStatus }) => {
      setLocalStatus(getReportStatus.status);
      setLocalPdfLink(getReportStatus.pdfLink);
      onPublish(getReportStatus);
    }
  });

  useEffect(() => {
    if (isActive) {
      getReportStatus();
    }
  }, [isActive]);

  return (
    <Container>
      <Card>
        <CardBody>
          <Image>{image}</Image>
          <Details>
            <Title title={title}>{title}</Title>
            <Text title={address}>
              {address}
              {!cityStateZip && isInvestorConnectEnabled && <IconDollar />}
            </Text>
            {cityStateZip && (
              <Text title={cityStateZip}>
                {cityStateZip}
                {isInvestorConnectEnabled && <IconDollar />}
              </Text>
            )}
            <Notes>{notes}</Notes>
            <Actions>
              <Action>
                {!isActive && (
                  <Link
                    disabled={!isPublished}
                    href={!isPublished ? null : localPdfLink}
                    target="_blank"
                    title={
                      !isPublished
                        ? "You must publish your report before you can view it."
                        : null
                    }>
                    View {pdfLabel}
                  </Link>
                )}
                {isActive && <Loading>Publishing</Loading>}
              </Action>
              {!isLiveEnabled && isSlideshowEnabled && !isActive && (
                <Action>
                  <Link
                    disabled={!isPublished}
                    href={!isPublished ? null : `/present/${guid}`}
                    target="_blank"
                    title={
                      !isPublished
                        ? "You must publish your report before you can view the slideshow."
                        : null
                    }>
                    View Slideshow
                  </Link>
                </Action>
              )}
              {isLiveEnabled && (
                <Action>
                  <Link href={liveUrl} target="_blank">
                    View Live
                  </Link>
                </Action>
              )}
              {isInvestorConnectEnabled && !isActive && (
                <Action>
                  <Link
                    href={`/${pluralize(
                      type.toLowerCase(),
                      2
                    )}/${id}/cash_offer`}
                    title={
                      !isPublished
                        ? "You must publish your report before you can view the slideshow."
                        : null
                    }>
                    Request Offer
                  </Link>
                </Action>
              )}
            </Actions>
          </Details>
        </CardBody>
      </Card>
    </Container>
  );
}

BasicReportCard.defaultProps = {
  isInvestorConnectEnabled: false,
  isLiveEnabled: false,
  onPublish: () => {}
};

BasicReportCard.propTypes = {
  id: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
  address: PropTypes.string,
  cityStateZip: PropTypes.string,
  notes: PropTypes.element,
  image: PropTypes.node,
  isInvestorConnectEnabled: PropTypes.bool,
  liveUrl: PropTypes.bool,
  isLiveEnabled: PropTypes.bool,
  onPublish: PropTypes.func
};

function BasicReportCardSkeleton() {
  return (
    <Card>
      <CardBody>
        <Image>
          <Skeleton
            style={{
              width: "6.8rem",
              height: "8.8rem",
              borderRadius: "0.2rem"
            }}
          />
        </Image>
        <Details>
          <Title>
            <Skeleton style={{ width: "12.7rem" }} />
          </Title>
          <Text>
            <Skeleton
              style={{
                width: "6.6rem",
                height: "95%",
                marginTop: "0.4rem"
              }}
            />
          </Text>
        </Details>
      </CardBody>
    </Card>
  );
}

export { BasicReportCardSkeleton };
export default BasicReportCard;
