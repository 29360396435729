import React, { Component } from "react";
import PropTypes from "prop-types";
import { getSizeInMB } from "../../utils/file";
import { getBase64 } from "../../utils/image";
import Alert from "../common/Alert";

class FilePickerSizeErrorAlert extends Component {
  state = {
    loaded: false,
    filesWithPreviews: []
  };

  componentDidMount() {
    this.getFilePreviews(this.props.files);
  }

  render() {
    const { title, onClose } = this.props;
    const { filesWithPreviews } = this.state;

    return (
      <Alert title={title} confirmButton="Ok" onConfirm={onClose}>
        We're sorry, but the following files are too big:
        {!filesWithPreviews.length && (
          <p className="my-8">Loading Invalid Files...</p>
        )}
        {!!filesWithPreviews.length && (
          <ul className="my-8 list-reset text-center">
            {filesWithPreviews.map((file, index) => (
              <li className="flex items-center" key={index}>
                <span
                  className={`w-16 h-16 rounded-full mr-4 bg-cover bg-center`}
                  style={{
                    backgroundImage: `url('${file.base64url}')`
                  }}
                />
                <span>
                  <span className="inline-block mr-2">{file.name}</span>
                  <small>
                    ({getSizeInMB(file).toFixed(2)}
                    MB)
                  </small>
                </span>
              </li>
            ))}
          </ul>
        )}
      </Alert>
    );
  }

  getFilePreviews = async (files) => {
    const filesWithPreviews = await Promise.all(
      [...files]
        .map(async (file) => {
          file = getSizeInMB(file) <= this.state.maxSizeInMB ? null : file;

          if (file !== null) {
            file.base64url = await getBase64(file);
          }

          return file;
        })
        .filter(Boolean)
    );

    this.setState({
      filesWithPreviews
    });
  };
}

FilePickerSizeErrorAlert.propTypes = {
  title: PropTypes.string,
  files: PropTypes.array.isRequired,
  maxSizeInMB: PropTypes.number,
  onClose: PropTypes.func.isRequired
};

FilePickerSizeErrorAlert.defaultProps = {
  title: "Error",
  maxSizeInMB: 5
};

export default FilePickerSizeErrorAlert;
