import React from "react";
import PropTypes from "prop-types";
import { hasKeys, hasMoreThanOneItem } from "./utils";
import CustomizeControlsTheme from "./connected/CustomizeControlsTheme";
import CustomizeControlsLayout from "./connected/CustomizeControlsLayout";
import CustomizeControlsTemplate from "./connected/CustomizeControlsTemplate";
import CustomizeControlsCover from "./connected/CustomizeControlsCover";
import CustomizeControlsFont from "./connected/CustomizeControlsFont";
import CustomizeControlsLanguage from "./language/connected/CustomizeControlsLanguage";
import CustomizeControlsTitle from "./connected/CustomizeControlsTitle";
import CustomizeControlsDescription from "./connected/CustomizeControlsDescription";
import { Container, Grid, Cell } from "./styled/customize-controls";

function CustomizeControls({
  reportType,
  theme,
  layout,
  template,
  cover,
  font,
  language,
  title,
  description,
  isModernTheme,
  hasModernFlyers
}) {
  return (
    <Container>
      <Grid>
        {hasKeys(theme.selected) && (
          <Cell reportType={reportType}>
            <CustomizeControlsTheme />
          </Cell>
        )}
        {hasKeys(layout.selected) && hasMoreThanOneItem(layout.all) && (
          <Cell reportType={reportType}>
            <CustomizeControlsLayout />
          </Cell>
        )}
        {hasKeys(template.selected) && reportType !== "flyer" && (
          <Cell reportType={reportType}>
            <CustomizeControlsTemplate />
          </Cell>
        )}
        {hasKeys(cover.selected) && cover.showCover && (
          <Cell reportType={reportType}>
            <CustomizeControlsCover />
          </Cell>
        )}
        {hasKeys(font.selected) && hasMoreThanOneItem(font.all) && (
          <Cell reportType={reportType}>
            <CustomizeControlsFont />
          </Cell>
        )}
        {language.show && hasMoreThanOneItem(language.all) && (
          <Cell reportType={reportType}>
            <CustomizeControlsLanguage />
          </Cell>
        )}
        {hasKeys(title.selected) && (isModernTheme || hasModernFlyers) && (
          <Cell reportType={reportType}>
            <CustomizeControlsTitle />
          </Cell>
        )}
        {hasKeys(description) && (
          <Cell reportType={reportType}>
            <CustomizeControlsDescription />
          </Cell>
        )}
      </Grid>
    </Container>
  );
}

CustomizeControls.propTypes = {
  reportType: PropTypes.string,
  theme: PropTypes.shape({
    selected: PropTypes.object
  }),
  layout: PropTypes.shape({
    selected: PropTypes.object
  }),
  template: PropTypes.shape({
    selected: PropTypes.object
  }),
  cover: PropTypes.shape({
    selected: PropTypes.object
  }),
  font: PropTypes.shape({
    selected: PropTypes.object
  }),
  language: PropTypes.object,
  title: PropTypes.shape({
    selected: PropTypes.object
  }),
  description: PropTypes.shape({
    value: PropTypes.string
  }),
  isModernTheme: PropTypes.bool,
  hasModernFlyers: PropTypes.bool
};

export default CustomizeControls;
