import { Modal } from "@wrstudios/components";
import { formatNumber } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import * as reportApi from "../../api/report";
import * as userApi from "../../api/user";
import { useSession } from "../../Session";
import AlertDanger from "../common/AlertDanger";
import Button from "../common/Button";
import Editor from "../common/Editor";
import Flash from "../common/Flash";
import FormGroup from "../common/FormGroup";
import Loading from "../common/Loading";
import { OptGroup, Option, Select } from "../common/Select";
import Skeleton from "../common/Skeleton";
import {
  Cell,
  Control,
  ControlDelete,
  Disclaimer,
  EditorContainer,
  EditorDisabled,
  FlashContainer,
  FooterActions,
  Grid,
  ModalContent,
  TemplateDisclaimer
} from "./styled/user-custom-pages-edit";
import UserCustomPagesEditAdd from "./UserCustomPagesEditAdd";
import UserCustomPageEditRename from "./UserCustomPagesEditRename";
import { whitelistTemplateKeys } from "./utils";

// 64kb in decimal
const MAX_CHAR_COUNT = 65536;

function UserCustomPagesEdit({
  reportId,
  pageKey,
  hiddenPageKeys,
  onClose,

  onSave
}) {
  const {
    currentUser,
    customPages: templatePages,
    setCustomPages
  } = useSession();
  const [isTemplate, setIsTemplate] = useState(!reportId);
  const [isLoading, setIsLoading] = useState(false);
  const [hasSaved, setHasSaved] = useState(false);
  const [hasLoadedReportPages, setHasLoadedReportPages] = useState(false);
  const [pages, setPages] = useState(templatePages);
  const [currentPageKey, setCurrentPageKey] = useState(pageKey || "");
  const [editorKey, setEditorKey] = useState(currentPageKey);
  const page =
    (isTemplate ? templatePages : pages).find((page) =>
      [page.key, page.oldKey].includes(currentPageKey)
    ) || {};
  const isWhitelisted = whitelistTemplateKeys.includes(currentPageKey);
  const [content, setContent] = useState(page.value || page.content || "");
  const [initialContent, setInitialContent] = useState(content);
  const [isRenaming, setIsRenaming] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [isEditorLoaded, setIsEditorLoaded] = useState(false);
  const [showMaxCountError, setShowMaxCountError] = useState(
    content.length > MAX_CHAR_COUNT
  );
  const defaultPages = pages.filter(
    (page) => page.isDefault && !hiddenPageKeys.includes(page.key)
  );
  const customPages = pages.filter(
    (page) => !page.isDefault && !hiddenPageKeys.includes(page.key)
  );
  const isDisabled = !currentPageKey || currentPageKey === "new";

  const handleSelect = (e) => {
    const page = pages.find((page) => page.key === e.target.value) || {};
    const content = page.value || page.content || "";
    setCurrentPageKey(e.target.value);
    setEditorKey(`editor-key-${Math.random().toString(16).substr(2)}`);
    setContent(content);
    setInitialContent(content);
    if (!!reportId) setIsTemplate(false);
  };

  const handleEditTemplate = () => {
    const page =
      templatePages.find((page) =>
        [page.key, page.oldKey].includes(currentPageKey)
      ) || {};
    const content = page.value || page.content || "";
    setEditorKey(`editor-key-${Math.random().toString(16).substr(2)}`);
    setContent(content);
    setInitialContent(content);
    setIsTemplate(true);
  };

  const handleEditPage = () => {
    const page =
      pages.find((page) => page.key === currentPageKey && page.isCustomPage) ||
      templatePages.find((page) => page.key === currentPageKey) ||
      {};
    const content = page.value || page.content || "";
    setEditorKey(`editor-key-${Math.random().toString(16).substr(2)}`);
    setContent(content);
    setInitialContent(content);
    setIsTemplate(!reportId);
  };

  const handleSave = async () => {
    setIsLoading(true);

    const details = { key: page.key, value: content };
    let newPage;

    if (isTemplate || !isWhitelisted) {
      newPage = !page.id
        ? await userApi.addCustomPage(currentUser.id, details)
        : await userApi.updateCustomPage(currentUser.id, page.id, details);
    } else {
      newPage = !page.isCustomPage
        ? await reportApi.addCustomPage(reportId, details)
        : await reportApi.updateCustomPage(reportId, page.id, details);
    }

    if (isTemplate || !isWhitelisted) {
      setCustomPages((templatePages) =>
        templatePages.map((templatePage) => {
          if (templatePage.key === newPage.key) {
            return { ...templatePage, ...newPage };
          }
          return templatePage;
        })
      );
    }

    setPages((pages) =>
      pages.map((page) => {
        if (isTemplate || !isWhitelisted) return page;
        if (page.key === newPage.key) {
          return { ...page, ...newPage, isCustomPage: true };
        }
        return page;
      })
    );
    setIsLoading(false);
    setHasSaved(true);
    setInitialContent(content);
    onSave({ key: newPage.key, content });
  };

  const handleDelete = async () => {
    const newPage =
      isTemplate || !isWhitelisted
        ? await userApi.removeCustomPage(currentUser.id, page.id)
        : await reportApi.removeCustomPage(reportId, page.id);
    setCurrentPageKey("");
    setContent("");
    setInitialContent("");
    setEditorKey(`editor-key-${Math.random().toString(16).substr(2)}`);
    setPages((pages) => pages.filter((page) => page.id !== newPage.id));
    setCustomPages((templatePages) =>
      templatePages.filter((templatePage) => templatePage.id !== page.id)
    );
  };

  const handleRevertToTemplate = async () => {
    const removedPage = await reportApi.removeCustomPage(reportId, page.id);
    const templatePage =
      templatePages.find((page) =>
        [page.key, page.oldKey].includes(removedPage.key)
      ) || {};
    const content = templatePage.value || templatePage.content || "";
    setEditorKey(`editor-key-${Math.random().toString(16).substr(2)}`);
    setContent(content);
    setInitialContent(content);
    setPages((pages) =>
      pages.map((page) => {
        if (page.id === removedPage.id) return templatePage;
        return page;
      })
    );
  };

  useEffect(() => {
    if (!isTemplate && !hasLoadedReportPages) {
      reportApi.getCustomPages(reportId).then((customPages) => {
        const newPages = templatePages.map((page) => {
          const customPage = customPages.find(
            (customPage) => customPage.key === page.key
          );
          return { ...page, ...(customPage || {}), isCustomPage: !!customPage };
        });
        const page =
          newPages.find((page) =>
            [page.key, page.oldKey].includes(currentPageKey)
          ) || {};
        const content = page.value || page.content || "";
        setCurrentPageKey(page.key);
        setContent(content);
        setInitialContent(content);
        setEditorKey(`editor-key-${Math.random().toString(16).substr(2)}`);
        setPages(newPages);
        setHasLoadedReportPages(true);
      });
    }
  }, [templatePages, currentPageKey, isTemplate, hasLoadedReportPages]);

  useEffect(() => {
    let isCurrent = true;
    let timeout;

    if (hasSaved) {
      timeout = setTimeout(() => {
        if (isCurrent) {
          setHasSaved(false);
        }
      }, 1500);
    }

    return () => {
      isCurrent = false;
      clearTimeout(timeout);
    };
  }, [hasSaved]);

  useEffect(() => {
    setPages(templatePages);
  }, [templatePages]);

  return (
    <Modal
      id="modal"
      focusLock={{
        whiteList: (node) => {
          const tinymce = document.querySelector(".tox-tinymce-aux");
          if (tinymce && tinymce.contains(node)) return false;
          return true;
        }
      }}
      onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Custom Pages
          <Modal.Close />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            {currentPageKey !== "new" && !isRenaming && (
              <Grid>
                <Cell>
                  <Select value={currentPageKey} onChange={handleSelect}>
                    <Option value="" disabled>
                      Select an Option
                    </Option>
                    <OptGroup label="Create">
                      <Option value="new">Add a new page</Option>
                    </OptGroup>
                    {!!defaultPages.length && (
                      <OptGroup label="Default Pages">
                        {defaultPages.map((page) => (
                          <Option key={page.key} value={page.key}>
                            {page.title}
                            {whitelistTemplateKeys.includes(page.key) &&
                              !isTemplate &&
                              " (This CMA*)"}
                            {whitelistTemplateKeys.includes(page.key) &&
                              isTemplate &&
                              " (This Template*)"}
                          </Option>
                        ))}
                      </OptGroup>
                    )}
                    {!!customPages.length && (
                      <OptGroup label="My Custom Pages">
                        {customPages.map((page) => (
                          <Option key={page.key} value={page.key}>
                            {page.title}
                          </Option>
                        ))}
                      </OptGroup>
                    )}
                  </Select>
                </Cell>
                {isWhitelisted && !isTemplate && page.isDefault && (
                  <>
                    <Cell>
                      <Control onClick={handleEditTemplate}>
                        Edit this template
                      </Control>
                    </Cell>
                    {page.isCustomPage && (
                      <Cell>
                        <ControlDelete onClick={handleRevertToTemplate}>
                          Revert to template
                        </ControlDelete>
                      </Cell>
                    )}
                  </>
                )}
                {isWhitelisted && isTemplate && !!reportId && page.isDefault && (
                  <Cell>
                    <Control onClick={handleEditPage}>
                      Edit this CMA page
                    </Control>
                  </Cell>
                )}
                {page.key && !page.isDefault && (
                  <>
                    <Cell>
                      <Control onClick={() => setIsRenaming(true)}>
                        Rename
                      </Control>
                    </Cell>
                    <Cell>
                      <ControlDelete onClick={() => setIsDeleting(true)}>
                        Delete
                      </ControlDelete>
                    </Cell>
                  </>
                )}
              </Grid>
            )}
            {currentPageKey === "new" && (
              <UserCustomPagesEditAdd
                onClose={(currentPageKey) => {
                  setCurrentPageKey(currentPageKey);
                  setEditorKey(
                    `editor-key-${Math.random().toString(16).substr(2)}`
                  );
                }}
              />
            )}
            {isRenaming && (
              <UserCustomPageEditRename
                id={page.id}
                pageKey={page.key}
                onClose={(currentPageKey) => {
                  setIsRenaming(false);
                  setCurrentPageKey(currentPageKey);
                  setEditorKey(
                    `editor-key-${Math.random().toString(16).substr(2)}`
                  );
                }}
              />
            )}
            {isDeleting && (
              <AlertDanger
                title="Delete Custom Page"
                confirmButton="Delete Forever"
                onConfirm={handleDelete}
                onClose={() => setIsDeleting(false)}>
                Are you sure you want to remove the custom page:{" "}
                <strong>{page.title}</strong>?
              </AlertDanger>
            )}
          </FormGroup>
          <FormGroup>
            {isWhitelisted && !isTemplate && (
              <Disclaimer>*This page will be unique to this report.</Disclaimer>
            )}
            {isWhitelisted && isTemplate && (
              <TemplateDisclaimer>
                * A template is the starting point for this page in future
                reports.
              </TemplateDisclaimer>
            )}
          </FormGroup>
          <EditorContainer>
            {currentPageKey === "new" && (
              <EditorDisabled>
                Content editing is not available while creating a new page.
              </EditorDisabled>
            )}
            {!currentPageKey && (
              <EditorDisabled>Select a page to edit content.</EditorDisabled>
            )}
            {!isEditorLoaded && !hasLoadedReportPages && (
              <EditorDisabled>
                <Skeleton style={{ width: "100%", height: "100%" }} />
              </EditorDisabled>
            )}
            <Editor
              key={editorKey}
              id="editor"
              autoFocus
              disabled={isDisabled}
              maxCharCount={MAX_CHAR_COUNT}
              options={{
                setup: (ed) => {
                  ed.on("init", () => {
                    setIsEditorLoaded(true);
                  });
                },
                auto_focus: !isDisabled ? "editor" : false,
                height: "100%",
                file_picker_types: "image",
                file_picker_callback: (cb) => {
                  const input = document.createElement("input");
                  input.setAttribute("type", "file");
                  input.setAttribute("accept", "image/*");
                  input.onchange = async (e) => {
                    const [file] = e.target.files;
                    if (file) {
                      const image = await userApi.addCustomPageImage(file);
                      const reader = new FileReader();
                      reader.onload = function () {
                        const newImage = new Image();
                        newImage.onload = function () {
                          cb(image.url, {
                            title: file.name,
                            width: this.width,
                            height: this.height
                          });
                        };
                        newImage.src = reader.result;
                      };
                      reader.readAsDataURL(file);
                    }
                  };
                  input.click();
                }
              }}
              initialValue={content}
              onEditorChange={(content) => {
                const showError = content.length > MAX_CHAR_COUNT;
                setContent(content);
                setShowMaxCountError(showError);
              }}
            />
          </EditorContainer>
        </Modal.Body>
        <Modal.Footer>
          <FooterActions>
            <Button
              app="cma"
              disabled={isLoading || hasSaved || initialContent === content}
              onClick={handleSave}>
              {isLoading && !hasSaved && <Loading>Saving</Loading>}
              {!isLoading && !hasSaved && "Save"}
              {hasSaved && "Saved!"}
            </Button>
          </FooterActions>
          {showMaxCountError && (
            <FlashContainer>
              <Flash variant="error" closable={false}>
                Your page content exceeds the maximum number of{" "}
                {formatNumber(MAX_CHAR_COUNT, "0,0")} characters. Your changes
                will not be saved until you meet this limit.
              </Flash>
            </FlashContainer>
          )}
        </Modal.Footer>
      </ModalContent>
    </Modal>
  );
}

UserCustomPagesEdit.defaultProps = {
  hiddenPageKeys: [],
  onSave: () => {}
};

UserCustomPagesEdit.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number
  }),
  pageKey: PropTypes.string,
  hiddenPageKeys: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func
};

export default UserCustomPagesEdit;
