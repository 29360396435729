import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { orderDiscount } from "../../actions/order";
import Button from "../common/Button";
import Loading from "../common/Loading";
import { Input } from "./styled/order-discount";
import { Error, InputGroup, Label, Success } from "./styled/order-form";

function OrderDiscount({
  label,
  discount,
  sku,
  request,
  orderDiscount,
  onChange
}) {
  const { fetching, fetched, error = {} } = request;
  const hasError = error && !!Object.keys(error).length;

  return (
    <>
      {label && <Label htmlFor="order-discount">{label}</Label>}
      <InputGroup>
        <Input value={discount} id="order-discount" onChange={onChange} />
        <Button
          app="suite"
          type="button"
          disabled={!discount}
          onClick={() => orderDiscount(sku, discount)}>
          {!fetching && "Apply"}
          {fetching && <Loading />}
        </Button>
      </InputGroup>
      {fetched && <Success>Discount code applied</Success>}
      {hasError && <Error>Invalid discount code</Error>}
    </>
  );
}

OrderDiscount.defaultProps = {
  label: "Discount Code"
};

OrderDiscount.propTypes = {
  label: PropTypes.string,
  discount: PropTypes.string,
  sku: PropTypes.string,
  request: PropTypes.shape({
    fetching: PropTypes.bool,
    fetched: PropTypes.bool,
    error: PropTypes.shape({
      message: PropTypes.string
    })
  }),
  onChange: PropTypes.func,
  orderDiscount: PropTypes.func
};

const mapStateToProps = (state) => ({
  request: state.order.discount.request
});

const mapDispatchToProps = {
  orderDiscount
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderDiscount);
