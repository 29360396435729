import styled from "styled-components";
import { Modal } from "@wrstudios/components";

export const ModalBody = styled(Modal.Body)`
  max-width: 53.2rem;
  margin: 0;
  padding: 0;

  ${({ theme }) => theme.media.sm`
    max-width: 100%;
  `}
`;

export const ModalContent = styled(Modal.Content)`
  padding: 0;
`;
