import React from "react";
import { Container } from "./styled/skeleton";

function Skeleton(props) {
  return (
    <Container {...props} aria-hidden>
      &nbsp;
    </Container>
  );
}

export default Skeleton;
