import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadImages } from "../../utils/image";
import Loading from "../common/Loading";
import {
  Container,
  Images,
  ImageContainer,
  Image,
  LoadingContainer
} from "./styled/customize-modal-theme-images";

class CustomizeModalThemeImages extends Component {
  static propTypes = {
    reportType: PropTypes.string.isRequired,
    theme: PropTypes.shape({
      key: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired
    }).isRequired
  };

  state = {
    images: []
  };

  async loadImages() {
    const { theme } = this.props;
    let { reportType } = this.props;

    if (reportType === "document") {
      reportType = "cma";
    }

    const imagesArray = [
      `/images/reports/${reportType}/${theme.key}_0.jpg`,
      `/images/reports/${reportType}/${theme.key}_1.jpg`,
      `/images/reports/${reportType}/${theme.key}_2.jpg`
    ];
    const images = await loadImages(imagesArray);

    if (this._isMounted) {
      this.setState({ images });
    }
  }

  componentDidMount() {
    this._isMounted = true;

    this.loadImages();
  }
  componentWillUnmount() {
    this._isMounted = false;
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (JSON.stringify(props.theme) !== JSON.stringify(this.props.theme)) {
      this.setState({ images: [] }, () => this.loadImages());
    }
  }

  render() {
    const { theme } = this.props;
    const { images } = this.state;

    return (
      <Container>
        <Images>
          {!images.length && (
            <LoadingContainer>
              <Loading>Loading Preview</Loading>
            </LoadingContainer>
          )}
          {images.map((image, index) => (
            <ImageContainer key={index}>
              <Image
                src={image}
                alt={`${theme.title} Preview Image ${index + 1}`}
              />
            </ImageContainer>
          ))}
        </Images>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({
  reportType: state.report.type.toLowerCase()
});

export default connect(mapStateToProps)(CustomizeModalThemeImages);
