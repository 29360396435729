import { IconMoney } from "@wrstudios/icons";
import React, { Fragment, useEffect, useState } from "react";
import { sendInvestorEvent, submitInvestorOffer } from "../../api/report";
import { getVideoIdFromUrl, getVideoTypeFromUrl } from "../../utils/video";
import Button from "../common/Button";
import Loading from "../common/Loading";
import IconLockOutline from "../icons/IconLockOutline";
import IconScribble from "../icons/IconScribble";
import IconSeal from "../icons/investor/IconSeal";
import IconArrowBack from "../icons/IconArrowBack";
import InvestorGenericQuestionnaire from "./InvestorGenericQuestionnaire";
import InvestorToggle from "./InvestorToggle";
import {
  ButtonIcon,
  BackButton,
  BackButtonIcon,
  Error,
  Intro,
  IntroCellLeft,
  IntroCellRight,
  IntroGrid,
  IntroHeader,
  Link,
  List,
  ListItem,
  ListItemIcon,
  Text,
  Title,
  TitlePoint,
  TOS,
  TOSIcon,
  TOSText,
  Wistia,
  YouTube,
  Subheader
} from "./styled/investor-generic";

const steps = {
  INTRO: 0,
  QUESTIONNAIRE: 1,
  NO_QUESTIONNAIRE: 2,
  REQUESTED: 3
};

function InvestorGeneric({
  report,
  lead,
  setLead,
  showCashOfferStep,
  setLocalShowCashOffer,
  showIntroBack
}) {
  const acceptedOffer = report.investor_offer && report.investor_offer_accepted;
  const [step, setStep] = useState(
    acceptedOffer ? steps.REQUESTED : steps.INTRO
  );
  const hasQuestions = !!(lead.survey_questions || []).length;

  useEffect(() => {
    sendInvestorEvent(report.id, lead.id, "offer_displayed");
  }, []);

  return (
    <>
      {step === steps.INTRO && (
        <InvestorGenericIntro
          report={report}
          lead={lead}
          hasQuestions={hasQuestions}
          showBack={showIntroBack}
          onNextStep={() =>
            hasQuestions ? setStep(steps.QUESTIONNAIRE) : null
          }
          onQualified={() => setStep(steps.REQUESTED)}
          onDisqualified={() =>
            setLead((lead) => ({
              ...lead,
              investor: { ...lead.investor, token: "disqualified" }
            }))
          }
          setLocalShowCashOffer={setLocalShowCashOffer}
        />
      )}
      {step === steps.QUESTIONNAIRE && (
        <InvestorGenericQuestionnaire
          report={report}
          lead={lead}
          setLead={setLead}
          onReset={() => setStep(steps.INTRO)}
          onQualified={() => setStep(steps.REQUESTED)}
          onDisqualified={() =>
            setLead((lead) => ({
              ...lead,
              investor: { ...lead.investor, token: "disqualified" }
            }))
          }
        />
      )}
      {step === steps.NO_QUESTIONNAIRE && (
        <InvestorGenericQuestionnaire report={report} lead={lead} />
      )}
      {step === steps.REQUESTED && <InvestorGenericRequested />}
      <InvestorToggle
        show={showCashOfferStep}
        text='Show "Cash Offer" step when available for my subject property.'
        feature="ibuyer_connect"
      />
    </>
  );
}

function InvestorGenericIntro({
  report,
  lead,
  hasQuestions,
  showBack,
  onNextStep,
  onQualified,
  onDisqualified,
  setLocalShowCashOffer
}) {
  const [network, setNetwork] = useState({
    isFetching: false,
    hasFetched: false,
    hasError: false
  });

  const handleClick = async () => {
    setNetwork({ isFetching: true, hasFetched: false, hasError: false });
    sendInvestorEvent(report.id, lead.id, "request_offer");

    if (!hasQuestions) {
      try {
        await submitInvestorOffer(report.id, lead.id);
        onQualified();
        window.location.href = "/";
        return;
      } catch (error) {
        onDisqualified();
        return;
      }
    }

    sendInvestorEvent(report.id, lead.id, "request_send");
    onNextStep();
  };

  return (
    <Fragment>
      <Subheader>While we're preparing that:</Subheader>
      <Title>
        Represent an investor with a{" "}
        <TitlePoint>
          cash
          <IconScribble />
        </TitlePoint>{" "}
        offer.
      </Title>
      <InvestorGenericVideo lead={lead} />
      <Intro>
        <IntroHeader hasCustomBranding={false}>
          Properties in the area have sold to investors. Would you like to
          represent an investor with a cash offer on this property?
        </IntroHeader>
        <IntroGrid>
          <IntroCellLeft>
            <List>
              <ListItem>
                <ListItemIcon>{String.fromCodePoint(129309)}</ListItemIcon>
                <span>
                  The investor talks <strong>exclusively with you</strong>, the
                  agent.
                </span>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  {String.fromCodePoint(9997) + String.fromCodePoint(65039)}
                </ListItemIcon>
                <span>
                  You <strong>represent the investor</strong> as the buyer.
                </span>
              </ListItem>
              <ListItem>
                <ListItemIcon>{String.fromCodePoint(129296)}</ListItemIcon>
                <span>
                  The investor <strong>will not contact</strong> seller
                  directly.
                </span>
              </ListItem>
            </List>
          </IntroCellLeft>
          <IntroCellRight>
            <IconSeal />
          </IntroCellRight>
        </IntroGrid>
      </Intro>
      {showBack && (
        <BackButton
          app="cma"
          variant="secondary"
          size="large"
          onClick={() => setLocalShowCashOffer(false)}>
          <BackButtonIcon>
            <IconArrowBack />
          </BackButtonIcon>
          Back
        </BackButton>
      )}
      <Button
        app="cma"
        size="large"
        disabled={network.isFetching || network.hasFetched}
        onClick={handleClick}>
        {network.isFetching ? (
          <Loading>Requesting Offer</Loading>
        ) : network.hasFetched ? (
          "Offer Requested!"
        ) : (
          <>
            <ButtonIcon>
              <IconMoney />
            </ButtonIcon>
            Get Cash Offer
          </>
        )}
      </Button>
      <TOS>
        <TOSIcon>
          <IconLockOutline />
        </TOSIcon>
        <TOSText>
          We have not shared any data about the property with third parties. By
          clicking "Request Offer," you agree to the iBuyer Connect{" "}
          <Link
            href="//cloudagentsuite.com/investor-connect/tos"
            target="_blank">
            Terms of Service
          </Link>
          . To learn more{" "}
          <Link href="//cloudagentsuite.com/ibuyerconnect#faq" target="_blank">
            visit our FAQ
          </Link>
          .
        </TOSText>
      </TOS>
      {network.hasError && (
        <Error>
          We're sorry, but an error has occurred while requesting a cash offer.
          Please try again.
        </Error>
      )}
    </Fragment>
  );
}

function InvestorGenericRequested() {
  return (
    <Fragment>
      <Title>Thank You</Title>
      <Text>
        Expect a buyer to contact you soon, usually within 24 hours or less.
        Please refer back to our{" "}
        <Link
          href="//cloudagentsuite.com/ibuyerconnect"
          target="_blank"
          rel="noopener">
          iBuyer Connect FAQ
        </Link>{" "}
        if you have any questions. If you don’t hear back feel free to contact{" "}
        <Link href="mailto:support@cloudcma.com">support</Link>.
      </Text>
    </Fragment>
  );
}

function InvestorGenericVideo({ lead }) {
  const url = lead.investor.video_url;
  const videoId = getVideoIdFromUrl(url);
  const videoType = getVideoTypeFromUrl(url);

  return (
    <>
      {videoType === "wistia" && (
        <Wistia videoId={videoId} controlsVisibleOnLoad={false} muted={true} />
      )}
      {videoType === "youtube" && <YouTube videoId={videoId} />}
    </>
  );
}

export default InvestorGeneric;
