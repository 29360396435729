import React, { Component, Children, cloneElement } from "react";
import PropTypes from "prop-types";
import {
  List,
  ListItems,
  Item
} from "./styled/customize-controls-language-dropdown";

class CustomizeControlsLanguageDropdownList extends Component {
  state = {
    highlightedIndex: this.props.selectedIndex
  };

  componentDidMount() {}

  render() {
    const { setSelectedIndex } = this.props;
    const { highlightedIndex } = this.state;

    return (
      <List>
        <ListItems>
          {Children.map(this.props.children, (child, index) => {
            return (
              <Item
                isHighlighted={index === highlightedIndex}
                onClick={setSelectedIndex.bind(this, index)}
                onFocus={this.handleHighlight.bind(this, index)}
                onMouseEnter={this.handleHighlight.bind(this, index)}>
                {cloneElement(child)}
              </Item>
            );
          })}
        </ListItems>
      </List>
    );
  }

  handleHighlight = (highlightedIndex) => {
    this.setState({ highlightedIndex });
  };
}

CustomizeControlsLanguageDropdownList.propTypes = {
  selectedIndex: PropTypes.number,
  setSelectedIndex: PropTypes.func
};

export default CustomizeControlsLanguageDropdownList;
