import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  border: 0.3rem solid ${({ theme }) => theme.styleGuide.colors.cma[300]};
  border-radius: 1.2rem;
  transform: translateX(-50%);
  position: fixed;
  bottom: 3.4rem;
  left: 50%;
`;

export const Item = styled.button`
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  padding: 1.8rem 2.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.white};
  border-left: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[600]};
  appearance: none;
  cursor: pointer;
  text-decoration: none;

  &:first-child {
    border-left: 0;
  }

  &:disabled {
    cursor: not-allowed;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 1.4rem;
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }
`;
