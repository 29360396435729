import React from "react";
import PropTypes from "prop-types";
import ReportCard from "../common/ReportCard";
import CreatedWith from "../common/CreatedWith";
import Image from "../common/Image";
import IconProperty from "../icons/report/IconProperty";
import { DELETE_PROPERTY } from "./queries";

function PropertyCard({
  id,
  guid,
  title,
  type,
  status,
  pdfLink,
  notes,
  thumbnail,
  address,
  cityStateZip,
  createdWith,
  viewCount,
  lastViewedAt,
  updatedAt,
  refetchQuery
}) {
  return (
    <ReportCard
      id={id}
      guid={guid}
      title={title}
      type={type}
      typeLabel="Property Report"
      status={status}
      pdfLink={pdfLink}
      address={address}
      cityStateZip={cityStateZip}
      notes={<CreatedWith host={createdWith} notes={notes} />}
      viewCount={viewCount}
      lastViewedAt={lastViewedAt}
      updatedAt={updatedAt}
      image={
        thumbnail ? <Image src={thumbnail} alt={title} /> : <IconProperty />
      }
      isSlideshowEnabled={false}
      isCopySlideshowEnabled={false}
      deleteQuery={DELETE_PROPERTY}
      refetchQuery={refetchQuery}
    />
  );
}

PropertyCard.propTypes = {
  id: PropTypes.number.isRequired,
  guid: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
  notes: PropTypes.string,
  thumbnail: PropTypes.string,
  address: PropTypes.string.isRequired,
  cityStateZip: PropTypes.string,
  createdWith: PropTypes.string,
  viewCount: PropTypes.number,
  lastViewedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  refetchQuery: PropTypes.object
};

export default PropertyCard;
