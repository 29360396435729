import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../../Icon";

function IconLayoutFlyer1(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#c4c8d0"
            d="M183.86 244.8h23.59v3h-23.59zM90.97 244.8h79.98v3H90.97zM90.97 263.21h116.48v3H90.97zM90.97 226.46h116.48v3H90.97zM25.56 23.75h36.83v4H25.56zM82.54 23.75h124.9v4H82.54zM32.6 225.42h36.83v4H32.6zM29.16 245.21h43.71v4H29.16zM37.64 262.74h26.75v4H37.64z"
          />
          <rect
            x="25.68"
            y="44.51"
            width="181.64"
            height="158.34"
            rx="5"
            ry="5"
            fill="#e1e5e9"
          />
          <path
            d="M202.32 204.34H30.68a6.5 6.5 0 0 1-6.5-6.5V49.51a6.5 6.5 0 0 1 6.5-6.5h171.64a6.5 6.5 0 0 1 6.5 6.5v148.33a6.5 6.5 0 0 1-6.5 6.5zM30.68 46a3.5 3.5 0 0 0-3.5 3.5v148.34a3.5 3.5 0 0 0 3.5 3.5h171.64a3.5 3.5 0 0 0 3.5-3.5V49.51a3.5 3.5 0 0 0-3.5-3.5z"
            fill="#576474"
          />
          <path
            fill="#fff"
            transform="rotate(-48.88 32.884 51.489)"
            d="M31.38 49.49h3v4h-3z"
          />
          <path
            fill="#fff"
            d="M189.38 190.1l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.24 2-2.26 6 5.24zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.24 2-2.26 6 5.24zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24z"
          />
          <path
            fill="#fff"
            transform="rotate(-48.88 197.869 195.521)"
            d="M196.37 193.53h3v4h-3z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.41 33.268 195.976)"
            d="M31.28 194.47h4v3h-4z"
          />
          <path
            fill="#fff"
            d="M41.74 190.5l-2-2.25 6-5.27 2 2.25zM53.68 180l-2-2.24 6-5.27 2 2.24zm11.94-10.54l-2-2.25 6-5.26 2 2.25zm11.94-10.53l-2-2.25 6-5.27 2 2.25zm11.94-10.58l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm12-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zM185 64l-2-2.25 6-5.27 2 2.25z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.41 197.477 51.04)"
            d="M195.48 49.54h4v3h-4z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#20b575"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#20b575"
            d="M183.86 244.8h23.59v3h-23.59zM90.97 244.8h79.98v3H90.97zM90.97 263.21h116.48v3H90.97zM90.97 226.46h116.48v3H90.97zM25.56 23.75h36.83v4H25.56zM82.54 23.75h124.9v4H82.54zM32.6 225.42h36.83v4H32.6zM29.16 245.21h43.71v4H29.16zM37.64 262.74h26.75v4H37.64z"
          />
          <rect
            x="25.68"
            y="44.51"
            width="181.64"
            height="158.34"
            rx="5"
            ry="5"
            fill="#e1e5e9"
          />
          <path
            fill="#20b575"
            d="M202.32 204.34H30.68a6.5 6.5 0 0 1-6.5-6.5V49.51a6.5 6.5 0 0 1 6.5-6.5h171.64a6.5 6.5 0 0 1 6.5 6.5v148.33a6.5 6.5 0 0 1-6.5 6.5zM30.68 46a3.5 3.5 0 0 0-3.5 3.5v148.34a3.5 3.5 0 0 0 3.5 3.5h171.64a3.5 3.5 0 0 0 3.5-3.5V49.51a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <path
            fill="#fff"
            transform="rotate(-48.88 32.884 51.489)"
            d="M31.38 49.49h3v4h-3z"
          />
          <path
            fill="#fff"
            d="M189.38 190.1l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.24 2-2.26 6 5.24zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.24 2-2.26 6 5.24zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24zm-12-10.48l-6-5.23 2-2.26 6 5.23zm-12-10.47l-6-5.24 2-2.26 6 5.24z"
          />
          <path
            fill="#fff"
            transform="rotate(-48.88 197.869 195.521)"
            d="M196.37 193.53h3v4h-3z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.41 33.268 195.976)"
            d="M31.28 194.47h4v3h-4z"
          />
          <path
            fill="#fff"
            d="M41.74 190.5l-2-2.25 6-5.27 2 2.25zM53.68 180l-2-2.24 6-5.27 2 2.24zm11.94-10.54l-2-2.25 6-5.26 2 2.25zm11.94-10.53l-2-2.25 6-5.27 2 2.25zm11.94-10.58l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm12-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zm11.94-10.54l-2-2.25 6-5.27 2 2.25zM185 64l-2-2.25 6-5.27 2 2.25z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.41 197.477 51.04)"
            d="M195.48 49.54h4v3h-4z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutFlyer1.propTypes = {
  hover: PropTypes.bool
};

IconLayoutFlyer1.defaultProps = {
  hover: false
};

export default IconLayoutFlyer1;
