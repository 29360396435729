import PropTypes from "prop-types";
import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "./styled/pdf";

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

function PDF({ url, loading, error, onLoad, onError }) {
  const [totalPages, setTotalPages] = useState(0);
  const [isLandscape, setIsLandScape] = useState(false);

  const handlePageLoad = (page) => {
    let { width, height } = page;

    if (page.rotate === 90 || page.rotate === 270) {
      width = page.height;
      height = page.width;
    }

    if (width > height) setIsLandScape(true);
  };

  return (
    <Document
      file={url}
      loading={loading}
      error={error}
      options={{
        cMapUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/cmaps`,
        cMapPacked: true,
        standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
      }}
      onLoadSuccess={({ numPages }) => {
        setTotalPages(numPages);
        onLoad();
      }}
      onLoadError={onError}>
      {new Array(totalPages).fill().map((_, index) => (
        <Page
          height={isLandscape ? 816 : undefined}
          width={isLandscape ? undefined : 816}
          key={index}
          pageNumber={index + 1}
          loading={loading}
          isLandscape={isLandscape}
          renderAnnotationLayer={false}
          renderInteractiveForms={false}
          onLoadSuccess={handlePageLoad}
        />
      ))}
    </Document>
  );
}

PDF.defaultProps = {
  onLoad: () => {},
  onError: () => {}
};

PDF.propTypes = {
  url: PropTypes.string.isRequired,
  loading: PropTypes.element,
  error: PropTypes.element,
  onLoad: PropTypes.func,
  onError: PropTypes.func
};

export default PDF;
