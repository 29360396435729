import React from "react";
import { Container, Circle, Input } from "./styled/radio";

function Radio({ className, ...props }) {
  return (
    <Container>
      <Input {...props} type="radio" />
      <Circle className={className} />
    </Container>
  );
}

export default Radio;
