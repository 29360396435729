import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Cell = styled.div`
  width: calc(100% / 3);

  &:last-child {
    display: flex;
    justify-content: flex-end;
  }
`;

export const View = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  color: #6b7280;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `};

  strong {
    font-weight: normal;
    color: #111827;
  }
`;

export const Children = styled.div`
  text-align: center;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #4b5563;
`;

const PaginateButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.6rem;
  height: 3.6rem;
  color: #4b5563;
  border: 0.1rem solid #e5e7eb;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border-radius: 0.4rem;
  cursor: pointer;
  appearance: none;

  &:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: currentColor;
  }
`;

export const Prev = styled(PaginateButton)`
  margin-right: -0.1rem;
  border-radius: 0.4rem 0 0 0.4rem;
`;

export const Next = styled(PaginateButton)`
  border-radius: 0 0.4rem 0.4rem 0;
`;
