import React from "react";
import PropTypes from "prop-types";
import { useTheme } from "styled-components";
import { FullscreenMedia } from "@wrstudios/components";
import { useSession } from "../../../Session";
import IconBackArrow from "../../icons/IconBackArrow";
import { BackArrow } from "./styled/homebeat-viewer-listing-fullscreen-media";

function HomebeatViewerListingFullscreenMedia({ listing, onExit, ...props }) {
  const theme = useTheme();
  const { config } = useSession();

  return (
    <FullscreenMedia
      street={listing.street}
      city={listing.city}
      state={listing.state}
      zip={listing.zip}
      markerLat={listing.coords.lat}
      markerLon={listing.coords.lon}
      markerColor={theme.homebeat.colors.primary}
      controlColor={theme.homebeat.colors.primary}
      photos={listing.photos}
      env={config.railsEnv}
      mapboxApiToken={config.mapboxApiToken}
      virtualTour={listing.virtualTour}
      useFullscreen={false}
      exitIcon={
        <BackArrow>
          <IconBackArrow />
        </BackArrow>
      }
      onExit={(e) => {
        e.stopPropagation();
        onExit(e);
      }}
      {...props}
    />
  );
}

HomebeatViewerListingFullscreenMedia.propTypes = {
  listing: PropTypes.object.isRequired,
  onExit: PropTypes.func.isRequired
};

export default HomebeatViewerListingFullscreenMedia;
