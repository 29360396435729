import { useQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import React from "react";
import News from "../common/News";
import Notifications from "../common/Notifications";
import Title from "../common/Title";
import Wrapper from "../common/Wrapper";
import TourBanner from "../tour/TourBanner";
import HomeReports from "./HomeReports";
import { GET_REPORTS } from "./queries";
import {
  Container,
  Header,
  NewsContainer,
  ReportsContainer,
  SubTitle
} from "./styled/home";

function Home() {
  const { data, loading } = useQuery(GET_REPORTS);
  const reports = get(data, "data.reports", []);
  const news = get(data, "data.news", []);

  return (
    <>
      <Notifications />
      <TourBanner />
      <Container>
        <Wrapper>
          <ReportsContainer>
            <Header>
              <Title>Recent Reports</Title>
            </Header>
            <HomeReports reports={reports} isLoading={loading} />
          </ReportsContainer>
          <NewsContainer>
            <SubTitle>What's New</SubTitle>
            <News articles={news} isLoading={loading} />
          </NewsContainer>
        </Wrapper>
      </Container>
    </>
  );
}

export default Home;
