import styled from "styled-components";

export const Container = styled.div`
  margin: 1.5rem 0;
`;

export const Header = styled.div`
  margin-bottom: 3rem;
`;

export const ReportsContainer = styled.div`
  margin-bottom: 5.5rem;
`;

export const NewsContainer = styled.div`
  margin: 3rem 0 2rem;
`;

export const SubTitle = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.colors.grayDark};

  ${({ theme }) => theme.media.sm`
    text-align: center;
  `};
`;
