import { useEffect, useRef, useState } from "react";
import { getPageConfig } from "../../api/page";

const cache = new Map();

export default function usePageConfig({ reportId, pageKey, onSuccess }) {
  const cacheKey = `report-${reportId}-page-${pageKey}`;
  const [data, setData] = useState(cache.get(cacheKey) || {});
  const [isLoading, setIsLoading] = useState(!cache.get(cacheKey));
  const [error, setError] = useState();

  const onSuccessRef = useRef(onSuccess);
  useEffect(() => {
    onSuccessRef.current = onSuccess;
  }, [onSuccess]);

  useEffect(() => {
    getPageConfig({ reportId, pageId: pageKey })
      .then((data) => {
        cache.set(cacheKey, data);
        setData(data);
        onSuccessRef.current?.(data);
      })
      .catch((error) => setError(error.message))
      .finally(() => setIsLoading(false));
  }, [reportId, pageKey, cacheKey]);

  return { data, isLoading, error };
}
