import React, { useState, useRef } from "react";
import { colors } from "@wrstudios/theme";
import { getInitials } from "@wrstudios/utils";
import { CropperModal, UserAvatar } from "@wrstudios/components";
import { updateUserAvatar, removeUserAvatar } from "../../api/user";
import { useSession } from "../../Session";
import Button from "../common/Button";
import AlertDanger from "../common/AlertDanger";
import { Container, Grid, Cell, Remove } from "./styled/user-avatar-uploader";

function UserAvatarUploader() {
  const { currentUser, setCurrentUser } = useSession();
  const thumbnailRef = useRef();
  const [file, setFile] = useState();
  const [fileKey, setFileKey] = useState();
  const [showCropper, setShowCropper] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });

  const handleChange = async (e) => {
    const [file] = e.target.files;
    thumbnailRef.current = URL.createObjectURL(file);
    setFile(file);
    setShowCropper(true);
    setFileKey(Date.now());
  };

  const handleUpload = async (cropper) => {
    const dimensions = cropper.getData();
    const image = cropper.getDataURL();

    try {
      setRequest({ loading: true, success: false, error: null });
      await updateUserAvatar(currentUser.id, file, dimensions);
      setRequest({ loading: false, success: true, error: null });
      setCurrentUser((currentUser) => ({ ...currentUser, avatarUrl: image }));
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error: error.originalResponse.data.error
      });
    }
  };

  const handleDelete = () => {
    removeUserAvatar(currentUser.id);
    setIsDeleting(false);
    setCurrentUser((currentUser) => ({ ...currentUser, avatarUrl: "" }));
  };

  const handleCropperClose = () => {
    setShowCropper(false);
    URL.revokeObjectURL(thumbnailRef.current);
  };

  return (
    <Container>
      <UserAvatar
        avatarUrl={currentUser.avatarUrl}
        size="xl"
        loading={request.loading}
        initials={getInitials(currentUser.name)}
        initialsColor={colors.cma[500]}
      />
      <input
        key={fileKey}
        type="file"
        id="avatar-file"
        accept={[
          "image/png",
          "image/x-png",
          "image/gif",
          "image/jpg",
          "image/jpeg"
        ].join(",")}
        onChange={handleChange}
      />
      <Button app="cma" as="label" htmlFor="avatar-file">
        Change Avatar
      </Button>
      <div>
        <Remove type="button" onClick={() => setIsDeleting(true)}>
          Remove my avatar and show initials instead
        </Remove>
      </div>
      {showCropper && (
        <CropperModal
          circle
          aspectRatio={1}
          imageUrl={thumbnailRef.current}
          onClose={handleCropperClose}
          footer={(cropper) => (
            <Grid>
              <Cell>
                <Button variant="secondary" onClick={handleCropperClose}>
                  Cancel
                </Button>
              </Cell>
              <Cell>
                <Button
                  app="cma"
                  onClick={() => {
                    handleCropperClose();
                    handleUpload(cropper);
                  }}>
                  Confirm
                </Button>
              </Cell>
            </Grid>
          )}
        />
      )}
      {isDeleting && (
        <AlertDanger
          title="Delete Profile Image"
          confirmButton="Delete Forever"
          onConfirm={handleDelete}
          onClose={() => setIsDeleting(false)}>
          Are you sure you want to remove your profile picture?
        </AlertDanger>
      )}
    </Container>
  );
}

export default UserAvatarUploader;
