import styled from "styled-components";
import { NavLink as BaseNavLink } from "react-router-dom";
import Color from "color";

export const Container = styled.nav`
  height: 6rem;
  padding: 0 2.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.sticky};
  position: sticky;
  top: 0;
  z-index: 501;

  ${({ theme }) => theme.media.sm`
    height: 6rem;
    position: static;
  `}
`;

export const Grid = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
`;

const Cell = styled.div`
  width: calc(100% / 3);

  ${({ theme }) => theme.media.sm`
    width: auto;
  `}
`;

export const LogoContainer = styled(Cell)`
  flex-shrink: 0;
`;

export const TitleContainer = styled(Cell)`
  padding: 0 1rem;
  text-overflow: ellipsis;
  text-align: center;
  overflow: hidden;
  white-space: nowrap;
`;

export const ControlsContainer = styled(Cell)`
  display: flex;
  justify-content: flex-end;
  flex-shrink: 0;
  margin-right: -1.2rem;
`;

export const CompanyLogo = styled.img`
  max-width: 10.7rem;
  max-height: 3.5rem;

  ${({ theme }) => theme.media.sm`
    max-width: 10.7rem;
    max-height: 3.5rem;
  `}
`;

export const Logo = styled.div`
  display: flex;
  align-items: center;
`;

export const LogoIcon = styled.span`
  svg {
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;

export const MobileLogo = styled.span`
  display: none;

  ${({ theme }) => theme.media.sm`
    display: block;
  `}

  svg {
    width: 3rem;
    height: 2.4rem;
  }
`;

export const NonMobileLogo = styled.span`
  display: block;
  width: 14rem;
  height: 2.4rem;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const Title = styled.h1`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const MobileTitle = styled.h1`
  display: none;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 2.9rem;
  padding: 1.55rem 2.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  overflow: hidden;
  text-overflow: ellipsis;
  position: sticky;
  top: 0;
  z-index: 101;

  ${({ theme }) => theme.media.sm`
    display: block;
  `}
`;

export const ControlGrid = styled.div`
  display: flex;
  margin: -0.2rem;
`;

export const ControlCell = styled.div`
  flex-shrink: 0;
  padding: 0.2rem;
`;

export const Control = styled(BaseNavLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  padding: 0.7rem 1.2rem;
  border-radius: 0.6rem;
  text-decoration: none;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}

  ${({ theme }) => theme.media.xxs`
    padding: 0.7rem;
  `}

  &:hover,
  &.active {
    color: ${({ theme }) => theme.homebeat.colors.primary};
    background-color: ${({ theme }) =>
      Color(theme.homebeat.colors.primary).fade(0.95).toString()};

    svg {
      fill: ${({ theme }) => theme.homebeat.colors.primary};
    }
  }

  svg {
    flex-shrink: 0;
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  }
`;

export const ControlText = styled.span`
  display: inline-block;
  margin-left: 1rem;

  ${({ theme }) => theme.media.lg`
    display: none;
  `}
`;
