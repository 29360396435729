import PropTypes from "prop-types";
import React, { useState } from "react";
import { useSession } from "../../Session";
import CustomizeModalRdc from "../customize/CustomizeModalRdc";
import Skeleton from "./Skeleton";
import rdc from "./images/rdc.png";
import {
  Article,
  Badge,
  Body,
  Cell,
  Grid,
  Image,
  ImageContainer,
  More,
  SkeletonImage,
  Title
} from "./styled/news";

function News({ articles, isLoading }) {
  const { features } = useSession();
  const [showRdcModal, setShowRdcModal] = useState(false);
  const modifiedArticles = features.offerRdcToolkit
    ? [
        {
          type: "rdc",
          title:
            "Impress your clients with the new Realtor.com® Listing Toolkit.",
          image: rdc
        },
        ...articles.slice(0, articles.length - 1)
      ]
    : articles;

  if (isLoading) {
    return <NewsSkeleton />;
  }

  return (
    <div>
      <Grid>
        {modifiedArticles.map((article) => {
          const isRdcArticle = article.type === "rdc";

          return (
            <Cell key={article.title}>
              <Article
                as={isRdcArticle ? "div" : "a"}
                href={article.link}
                target="_blank"
                rel="noopener"
                data-pendo-id="rdc-whats-new-tile"
                title={article.title}
                aria-label={
                  isRdcArticle
                    ? undefined
                    : `Link to News Article: ${article.title}`
                }
                onClick={() => {
                  if (isRdcArticle) {
                    setShowRdcModal(true);
                  }
                }}>
                <ImageContainer>
                  <Image
                    isRdcArticle={isRdcArticle}
                    as={article.image ? "img" : "div"}
                    src={article.image}
                    alt={article.image ? article.title : undefined}>
                    {article.image ? undefined : article.title}
                  </Image>
                  {article.badge && (
                    <Badge type={article.type}>{article.badge}</Badge>
                  )}
                </ImageContainer>
                <Body>
                  <Title>{article.title}</Title>
                  <More>{article.readMore || "Learn More"}</More>
                </Body>
              </Article>
            </Cell>
          );
        })}
      </Grid>
      {showRdcModal && (
        <CustomizeModalRdc
          confirmLink="https://succeed.realtor.com/listingtoolkit?utm_source=cloud_cma&utm_medium=website&utm_campaign=ccma_recent_reports&utm_term=whatsnew"
          onClose={() => setShowRdcModal(false)}
        />
      )}
    </div>
  );
}

function NewsSkeleton() {
  return (
    <Grid>
      {new Array(4).fill().map((_, index) => (
        <Cell key={index}>
          <Article>
            <ImageContainer>
              <SkeletonImage />
            </ImageContainer>
            <Body>
              <Title>
                <div>
                  <Skeleton style={{ width: "90%" }} />
                </div>
                <div style={{ transform: "translateY(0.2rem)" }}>
                  <Skeleton style={{ width: "70%", height: "1.8rem" }} />
                </div>
              </Title>
              <More>
                <Skeleton style={{ width: "20%", height: "1.8rem" }} />
              </More>
            </Body>
          </Article>
        </Cell>
      ))}
    </Grid>
  );
}

News.propTypes = {
  articles: PropTypes.arrayOf(
    PropTypes.shape({
      link: PropTypes.string,
      title: PropTypes.string,
      image: PropTypes.string,
      type: PropTypes.string,
      badge: PropTypes.string,
      readMore: PropTypes.string
    })
  ),
  isLoading: PropTypes.bool
};

export default News;
