import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { default as BaseInput } from "../../../common/Input";
import { Container as BaseInputStyle } from "../../../common/styled/input";
import BaseHomebeatViewerButton from "../HomebeatViewerButton";

export const Modal = styled(BaseModal)`
  justify-content: flex-end;
  padding: 0;
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 44.2rem;
  height: 100%;
  padding: 0;
  border-radius: 0;
  transform: translateX(110%);
  transition: transform 0.2s;
  position: relative;

  .address-auto-complete-input {
    height: 3.6rem;
    padding: 0.8rem 1.2rem;
    border-width: 0.1rem;

    &:active,
    &:focus {
      border-color: ${({ theme }) => theme.homebeat.colors.primary};
    }
  }

  .address-auto-complete-menu {
    border-width: 0.1rem;
  }

  .is-highlighted {
    color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const ModalHeader = styled(BaseModal.Header)`
  font-weight: 600;
  padding: 3.2rem 3.2rem 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const ModalClose = styled(BaseModal.Close)`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4rem;
  width: 4rem;
  position: absolute;
  top: 1.2rem;
  right: 1.2rem;

  svg {
    width: 2rem;
    height: 2rem;
  }
`;

export const ModalBody = styled(BaseModal.Body)`
  margin: 0;
  padding: 0;
`;

export const ModalFooter = styled(BaseModal.Footer)`
  margin: 0;
  padding: 2.4rem 3.2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
`;

export const Intro = styled.div`
  padding: 2.4rem 3.2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
`;

export const IntroGrid = styled.div`
  display: flex;
  margin: -0.8rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    align-items: center;
  `}
`;

export const IntroCell = styled.div`
  padding: 0.8rem;
`;

export const Quote = styled.blockquote`
  display: inline;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const QuoteName = styled.strong`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -0.8rem;
  overflow: hidden;
`;

export const Cell = styled.div`
  padding: 0.8rem;
`;

export const Content = styled.div`
  padding: 3.2rem;
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 0 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `};
`;

export const RequestContent = styled(Content)`
  padding-top: 0.4rem;

  ${Text} {
    margin-bottom: 2.4rem;
  }
`;

export const Details = styled.div`
  margin-top: 0.8rem;
`;

export const Input = styled(BaseInput)`
  padding: 0.8rem 1.2rem;
  border-width: 0.1rem;
  color: ${({ theme, hasTaxData }) =>
    hasTaxData && theme.styleGuide.colors.purple[500]};

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const TextArea = styled(BaseInputStyle)`
  width: 100%;
  padding: 0.8rem 1.2rem;
  border-width: 0.1rem;
  resize: vertical;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const Error = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.2rem 0 0;
  color: ${({ theme }) => theme.styleGuide.colors.red[500]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const RatingHeader = styled.strong`
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 600;
  line-height: 2.2rem;
  margin-bottom: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const HomebeatViewerButton = styled(BaseHomebeatViewerButton)`
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1.2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }
`;

export const AgentGrid = styled.div`
  display: flex;
  margin: -0.8rem;
`;

export const AgentCell = styled.div`
  padding: 0.8rem;
`;

export const AgentName = styled.strong`
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 700;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.4rem;
  `}
`;

export const AgentCompany = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.4rem;
  `}
`;

export const AgentLink = styled.a`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  color: ${({ theme }) => theme.homebeat.colors.primary};
  text-decoration: none;

  ${({ theme }) => theme.media.sm`
    font-size: 1.4rem;
  `}
`;
