import React from "react";
import { useSession } from "../../Session";
import { getNewCmaUrl, useQueryString } from "../../utils/url";
import { Badge, Banner, Button, Container } from "./styled/tour-banner";

export default function TourBanner() {
  const { mlsnums, features, currentUser } = useSession();
  const { stringify } = useQueryString();

  if (!features.hasLiveTours || currentUser.liveToursEnabled) {
    return null;
  }

  let params = {
    jwt: currentUser.casJwt
  };

  if (Array.isArray(mlsnums) && !!mlsnums.length) {
    params = { ...params, mlsnums: mlsnums.join(",") };
  }

  return (
    <Container>
      <Banner>
        <Badge>New Feature</Badge>
        <div>You can now create new interactive live tours.</div>
        <Button href={`${getNewCmaUrl()}/tours/new?${stringify(params)}`}>
          Try Live Tours →
        </Button>
      </Banner>
    </Container>
  );
}
