import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { activeListings, errorListings } from "../../../selectors/listings";
import {
  Container,
  Content,
  Header,
  Text,
  Progress,
  ProgressBar
} from "./styled/listings-map-loading";

function ListingsMapLoading({ listings, activeListings, errorListings }) {
  const progress =
    (activeListings.length / (listings.length - errorListings.length)) * 100;
  const isLoading =
    listings.length - errorListings.length !== activeListings.length &&
    progress < 100;

  if (!isLoading) return null;

  return (
    <Container>
      <Content>
        <Header>Gathering Comparable Listings</Header>
        <Progress>
          <ProgressBar style={{ width: `${progress}%` }} />
        </Progress>
        <Text>
          {activeListings.length} of {listings.length - errorListings.length}{" "}
          listings
        </Text>
      </Content>
    </Container>
  );
}

ListingsMapLoading.propTypes = {
  listings: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeListings: PropTypes.arrayOf(PropTypes.object).isRequired,
  errorListings: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = (state) => ({
  listings: state.listings.all,
  activeListings: activeListings(state),
  errorListings: errorListings(state)
});

export default connect(mapStateToProps)(ListingsMapLoading);
