import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

function ListingQuickInfo({ beds, baths, sqft, yearBuilt, report }) {
  const hasBeds = !!beds;
  const hasBaths = !!baths;
  const hasSqft = !!sqft && report.includes.sqft;
  const hasYearBuilt = !!yearBuilt;

  let details = [];
  if (hasBeds) details.push(`${beds} Bds`);
  if (hasBaths) details.push(`${baths} Ba`);
  if (hasSqft) details.push(`${sqft} sqft`);
  if (hasYearBuilt) details.push(yearBuilt);

  return (
    <div
      className="text-sm text-grey-dark"
      dangerouslySetInnerHTML={{ __html: details.join(" &bull; ") }}
    />
  );
}

ListingQuickInfo.propTypes = {
  beds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  baths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  sqft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  yearBuilt: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  report: PropTypes.shape({
    includes: PropTypes.shape({
      sqft: PropTypes.bool
    })
  })
};

const mapStateToProps = (state) => ({
  report: state.report
});

export default connect(mapStateToProps)(ListingQuickInfo);
