import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { Draggable } from "react-beautiful-dnd";
import Listing from "./Listing";

class ListingDraggable extends Component {
  static propTypes = {
    listing: PropTypes.object.isRequired,
    index: PropTypes.number.isRequired,
    isDragDisabled: PropTypes.bool.isRequired
  };

  state = {
    isDragDisabled: this.props.isDragDisabled || false,
    isOpen: this.props.isOpen || false
  };

  toggleDrag(isOpen = false) {
    if (this.props.isDragDisabled) return;

    this.setState(({ isDragDisabled }) => ({
      isDragDisabled: !isDragDisabled,
      isOpen
    }));
  }

  render() {
    const { listing, index } = this.props;
    const { isDragDisabled, isOpen } = this.state;

    return (
      <Draggable
        draggableId={`draggable-${listing.id}`}
        isDragDisabled={isDragDisabled}
        index={index}>
        {(provided, snapshot) => (
          <Fragment>
            <div
              className={`listing-draggable mt-4 rounded-md ${
                snapshot.isDragging ? "shadow-overlay dragging" : ""
              } ${!isOpen && !isDragDisabled ? "cursor-move" : ""}`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}>
              <Listing
                listing={listing}
                onDetailsToggle={this.toggleDrag.bind(this)}
              />
            </div>
            {provided.placeholder}
          </Fragment>
        )}
      </Draggable>
    );
  }
}

export default ListingDraggable;
