import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../../Icon";

function IconLayoutFlyer7(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#c4c8d0"
            d="M25.56 23.75h36.83v4H25.56zM82.54 23.75h124.9v4H82.54zM182.05 244.8h23.59v3h-23.59zM89.16 244.8h79.98v3H89.16zM89.16 263.21h116.48v3H89.16zM89.16 226.46h116.48v3H89.16zM30.8 225.42h36.83v4H30.8zM27.36 245.21h43.71v4H27.36zM35.84 262.74h26.75v4H35.84z"
          />
          <rect
            fill="#e1e5e9"
            x="25.78"
            y="40.1"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
          />
          <path
            fill="#576474"
            d="M72.31 85.82H30.78a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM30.78 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e1e5e9"
            x="90.73"
            y="40.1"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
          />
          <path
            fill="#576474"
            d="M137.27 85.82H95.73a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM95.73 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e1e5e9"
            x="155.69"
            y="40.1"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
          />
          <path
            fill="#576474"
            d="M202.22 85.82h-41.53a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM160.69 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e1e5e9"
            x="25.78"
            y="99.61"
            width="181.45"
            height="103.83"
            rx="6.3"
            ry="6.3"
          />
          <path
            fill="#576474"
            d="M200.92 205H32.08a7.81 7.81 0 0 1-7.8-7.8v-91.29a7.81 7.81 0 0 1 7.8-7.8h168.84a7.81 7.81 0 0 1 7.8 7.8v91.24a7.81 7.81 0 0 1-7.8 7.85zM32.08 101.11a4.81 4.81 0 0 0-4.8 4.8v91.24a4.81 4.81 0 0 0 4.8 4.8h168.84a4.81 4.81 0 0 0 4.8-4.8v-91.24a4.81 4.81 0 0 0-4.8-4.8z"
          />
          <path
            fill="#fff"
            transform="rotate(-62 33.705 107.856)"
            d="M32.21 105.85h3v4h-3z"
          />
          <path
            fill="#fff"
            d="M188.44 191.81l-7-3.71 1.41-2.65 7 3.71zm-14-7.43l-7-3.71 1.46-2.67 7 3.71zm-14-7.42l-7-3.72 1.41-2.65 7 3.72zm-14-7.43l-7-3.71 1.41-2.65 7 3.71zm-14-7.43l-7-3.71 1.41-2.65 7 3.71zm-14-7.42l-7-3.72 1.41-2.65 7 3.72zm-14-7.43l-7-3.71 1.4-2.65 7 3.71zm-14-7.43l-7-3.71 1.4-2.65 7 3.71zm-14-7.42l-7-3.72L71.1 126l7 3.72zm-14-7.43l-7-3.71 1.41-2.65 7 3.71zm-14-7.43l-7-3.71 1.41-2.65 7 3.71z"
          />
          <path
            fill="#fff"
            transform="rotate(-62.01 197.924 195.14)"
            d="M196.4 193.14h3v4h-3z"
          />
          <path
            fill="#fff"
            transform="rotate(-28.25 34.11 195.413)"
            d="M32.1 193.91h4v3h-4z"
          />
          <path
            fill="#fff"
            d="M43.52 192.05l-1.42-2.65 7-3.73 1.42 2.64zm13.91-7.48L56 181.93l7-3.74 1.42 2.65zm13.9-7.47l-1.42-2.64 7-3.74 1.42 2.64zm13.9-7.47L83.81 167l7-3.74 1.42 2.64zm13.91-7.47l-1.42-2.65 7-3.73 1.42 2.64zm13.9-7.48l-1.42-2.68 6.95-3.73L120 151zm13.9-7.47l-1.42-2.64 7-3.74 1.42 2.65zm13.9-7.47l-1.42-2.64 7-3.74 1.42 2.64zm13.91-7.47l-1.42-2.64 6.95-3.74 1.42 2.64zm13.9-7.47l-1.42-2.65 7-3.73 1.42 2.64zm13.9-7.48l-1.42-2.64 7-3.73 1.42 2.64z"
          />
          <path
            fill="#fff"
            transform="rotate(-28.25 197.509 107.591)"
            d="M195.51 106.08h4v3h-4z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#20b575"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#20b575"
            d="M25.56 23.75h36.83v4H25.56zM82.54 23.75h124.9v4H82.54zM182.05 244.8h23.59v3h-23.59zM89.16 244.8h79.98v3H89.16zM89.16 263.21h116.48v3H89.16zM89.16 226.46h116.48v3H89.16zM30.8 225.42h36.83v4H30.8zM27.36 245.21h43.71v4H27.36zM35.84 262.74h26.75v4H35.84z"
          />
          <rect
            fill="#e0e6ea"
            x="25.78"
            y="40.1"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
          />
          <path
            fill="#20b575"
            d="M72.31 85.82H30.78a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM30.78 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e0e6ea"
            x="90.73"
            y="40.1"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
          />
          <path
            fill="#20b575"
            d="M137.27 85.82H95.73a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM95.73 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e0e6ea"
            x="155.69"
            y="40.1"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
          />
          <path
            fill="#20b575"
            d="M202.22 85.82h-41.53a6.51 6.51 0 0 1-6.5-6.5V45.1a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM160.69 41.6a3.5 3.5 0 0 0-3.5 3.5v34.22a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V45.1a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e0e6ea"
            x="25.78"
            y="99.61"
            width="181.45"
            height="103.83"
            rx="6.3"
            ry="6.3"
          />
          <path
            fill="#20b575"
            d="M200.92 205H32.08a7.81 7.81 0 0 1-7.8-7.8v-91.29a7.81 7.81 0 0 1 7.8-7.8h168.84a7.81 7.81 0 0 1 7.8 7.8v91.24a7.81 7.81 0 0 1-7.8 7.85zM32.08 101.11a4.81 4.81 0 0 0-4.8 4.8v91.24a4.81 4.81 0 0 0 4.8 4.8h168.84a4.81 4.81 0 0 0 4.8-4.8v-91.24a4.81 4.81 0 0 0-4.8-4.8z"
          />
          <path
            fill="#fff"
            transform="rotate(-62 33.705 107.856)"
            d="M32.21 105.85h3v4h-3z"
          />
          <path
            fill="#fff"
            d="M188.44 191.81l-7-3.71 1.41-2.65 7 3.71zm-14-7.43l-7-3.71 1.46-2.67 7 3.71zm-14-7.42l-7-3.72 1.41-2.65 7 3.72zm-14-7.43l-7-3.71 1.41-2.65 7 3.71zm-14-7.43l-7-3.71 1.41-2.65 7 3.71zm-14-7.42l-7-3.72 1.41-2.65 7 3.72zm-14-7.43l-7-3.71 1.4-2.65 7 3.71zm-14-7.43l-7-3.71 1.4-2.65 7 3.71zm-14-7.42l-7-3.72L71.1 126l7 3.72zm-14-7.43l-7-3.71 1.41-2.65 7 3.71zm-14-7.43l-7-3.71 1.41-2.65 7 3.71z"
          />
          <path
            fill="#fff"
            transform="rotate(-62.01 197.924 195.14)"
            d="M196.4 193.14h3v4h-3z"
          />
          <path
            fill="#fff"
            transform="rotate(-28.25 34.11 195.413)"
            d="M32.1 193.91h4v3h-4z"
          />
          <path
            fill="#fff"
            d="M43.52 192.05l-1.42-2.65 7-3.73 1.42 2.64zm13.91-7.48L56 181.93l7-3.74 1.42 2.65zm13.9-7.47l-1.42-2.64 7-3.74 1.42 2.64zm13.9-7.47L83.81 167l7-3.74 1.42 2.64zm13.91-7.47l-1.42-2.65 7-3.73 1.42 2.64zm13.9-7.48l-1.42-2.68 6.95-3.73L120 151zm13.9-7.47l-1.42-2.64 7-3.74 1.42 2.65zm13.9-7.47l-1.42-2.64 7-3.74 1.42 2.64zm13.91-7.47l-1.42-2.64 6.95-3.74 1.42 2.64zm13.9-7.47l-1.42-2.65 7-3.73 1.42 2.64zm13.9-7.48l-1.42-2.64 7-3.73 1.42 2.64z"
          />
          <path
            fill="#fff"
            transform="rotate(-28.25 197.509 107.591)"
            d="M195.51 106.08h4v3h-4z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutFlyer7.propTypes = {
  hover: PropTypes.bool
};

IconLayoutFlyer7.defaultProps = {
  hover: false
};

export default IconLayoutFlyer7;
