import React from "react";

function IconAlerts() {
  return (
    <svg
      width="16"
      height="15"
      viewBox="0 0 16 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.25 5.75C2.25 2.57436 4.82436 0 8 0C11.1756 0 13.75 2.57436 13.75 5.75V7.03685C13.75 8.00584 14.0368 8.95315 14.5743 9.7594L15.624 11.334C15.7775 11.5641 15.7918 11.86 15.6613 12.1039C15.5307 12.3478 15.2766 12.5 15 12.5H10.7388C10.6125 13.9016 9.43451 15 8 15C6.56549 15 5.38752 13.9016 5.26121 12.5H1C0.723405 12.5 0.46926 12.3478 0.338745 12.1039C0.208231 11.86 0.222535 11.5641 0.375963 11.334L1.42568 9.7594C1.96318 8.95315 2.25 8.00584 2.25 7.03685V5.75ZM6.77501 12.5C6.89082 13.0706 7.39526 13.5 8 13.5C8.60474 13.5 9.10918 13.0706 9.225 12.5H6.77501ZM8 1.5C5.65279 1.5 3.75 3.40279 3.75 5.75V7.03685C3.75 8.30198 3.37552 9.5388 2.67375 10.5915L2.40139 11H13.5986L13.3263 10.5915C12.6245 9.5388 12.25 8.30198 12.25 7.03685V5.75C12.25 3.40279 10.3472 1.5 8 1.5Z"
        fill="#0AA1DC"
      />
    </svg>
  );
}

export default IconAlerts;
