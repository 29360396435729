import styled from "styled-components";

export const Container = styled.div`
  display: flex;
`;

export const Label = styled.label`
  width: 45%;
  font-size: 2rem;
  line-height: 2.6rem;
  margin-top: 0.5rem;
  padding-right: 2rem;
  text-align: left;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    width: 25%;
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

export const Group = styled.div`
  width: 55%;

  ${({ theme }) => theme.media.sm`
    width: 75%;
  `}
`;

export const SelectContainer = styled.div`
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
`;
