import React from "react";
import PropTypes from "prop-types";
import Button from "../common/Button";
import CustomizeModalCoverImage from "./CustomizeModalCoverImage";
import {
  Header,
  Title,
  TitleContainer,
  Badge,
  Grid,
  Cell
} from "./styled/customize-modal-cover-images";

function CustomizeModalCoverImages({
  title,
  images,
  tag,
  offer,
  onOfferClick,
  onOfferImageClick,
  onClick
}) {
  return (
    <>
      <Header>
        <TitleContainer>
          <Title>{title}</Title>
          {tag && <Badge>{tag}</Badge>}
        </TitleContainer>
        <div>
          {offer && (
            <Button app="cma" size="small" onClick={onOfferClick}>
              Try Covers Free
            </Button>
          )}
        </div>
      </Header>
      <Grid>
        {images.map((cover, index) => (
          <Cell key={index} className="">
            <CustomizeModalCoverImage
              cover={cover}
              offer={offer}
              onOfferClick={onOfferImageClick}
              onClick={onClick}
            />
          </Cell>
        ))}
      </Grid>
    </>
  );
}

CustomizeModalCoverImages.propTypes = {
  title: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      image: PropTypes.string
    })
  ),
  tag: PropTypes.string,
  offer: PropTypes.bool,
  onOfferClick: PropTypes.func,
  onClick: PropTypes.func
};

export default CustomizeModalCoverImages;
