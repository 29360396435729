import { gql } from "apollo-boost";

export const GET_PROPERTIES = gql`
  query GetProperties($page: Int = 1, $limit: Int = 17, $search: String = "") {
    properties(page: $page, limit: $limit, search: $search)
      @rest(
        type: "Properties"
        path: "properties?page={args.page}&limit={args.limit}&q={args.search}"
      ) {
      meta @type(name: "PropertiesMeta") {
        currentPage
        perPage
        totalPages
        totalResults
      }
      results @type(name: "Property") {
        id
        guid
        title
        type
        status
        notes
        thumbnail: thumbnail_url_with_timestamp
        createdWith: created_via
        pdfLink: pdf_permalink
        addressParts: report_address_parts
        updatedAt: updated_at
        eventSummary: event_summary @type(name: "ReportEventSummary") {
          viewCount: view_count
          lastViewedAt: last_view_at
        }
      }
    }
  }
`;

export const DELETE_PROPERTY = gql`
  mutation DeleteProperty($id: ID!) {
    deleteProperty(id: $id)
      @rest(type: "Property", path: "properties/{args.id}", method: "DELETE") {
      id
    }
  }
`;
