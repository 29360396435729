/**
 * Finds a page with a specific key in an array of pages.
 *
 * @param {String} key
 * @param {Array} pages
 */
export function findPage(key, pages) {
  let data;
  let index = -1;
  const page = pages.find((p, i) => {
    if (p.key === key) {
      index = i;
      return p;
    }
  });

  if (page) {
    data = {
      index,
      page
    };
  }

  return data;
}

/**
 * Finds children of a page.
 *
 * @param {String} parent The key of the parent
 * @param {Array} pages
 */
export function findChildren(parent, pages) {
  return pages.filter((page) => page.parent === parent);
}

/**
 * Adds class(es) to the body element
 *
 * @param {Array|String} classes
 */
export function addBodyClass(classes) {
  document.querySelector("body").classList.add(classes);
}

/**
 * Removes class(es) on the body element
 *
 * @param {Array|String} classes
 */
export function removeBodyClass(classes) {
  document.querySelector("body").classList.remove(classes);
}

/**
 * Checks to see if an element has a class(es)
 *
 * @param {HTMLElement} element
 * @param {Array|String} classes
 */
export function hasClass(element, classes) {
  if (typeof element === "undefined") return false;
  if (!Array.isArray(classes)) classes = [classes];

  return classes
    .map((clazz) => document.querySelector(element).classList.contains(clazz))
    .some((c) => c);
}

/**
 * Gets the app CSRF Param
 */
export function getCSRFParam() {
  const paramEl = document.querySelector('meta[name="csrf-param"]');
  const param = paramEl ? paramEl.getAttribute("content") : "";
  return param;
}

/**
 * Gets the app CSRF Token
 */
export function getCSRFToken() {
  const tokenEl = document.querySelector('meta[name="csrf-token"]');
  const token = tokenEl ? tokenEl.getAttribute("content") : "";
  return token;
}

/**
 * Constructs an array with child parent relationships
 *
 * @param {Array} pages
 */
export function getPageKeys(pages = []) {
  return [
    ...new Set(
      pages.map((page) => {
        if (page.children && page.children.length) {
          return {
            [page.key]: page.children.map((child) => child.key)
          };
        }

        return page.key;
      })
    )
  ];
}
