import React from "react";
import {
  Container,
  Table as StyledTable,
  Row,
  Heading,
  Data,
  Footer
} from "./styled/table";

function Table({ children, ...props }) {
  return (
    <Container {...props}>
      <StyledTable>{children}</StyledTable>
    </Container>
  );
}

function TableRow(props) {
  return <Row {...props} />;
}

function TableHeading(props) {
  return <Heading {...props} />;
}

function TableData(props) {
  return <Data {...props} />;
}

function TableFooter(props) {
  return <Footer {...props} />;
}

export { Table, TableRow, TableHeading, TableData, TableFooter };
