import styled from "styled-components";

export const Plans = styled.div`
  display: flex;
  justify-content: center;
`;

export const Plan = styled.label`
  display: flex;
  align-items: center;
  margin-bottom: 0.5rem;
  cursor: pointer;

  &:last-child {
    margin-bottom: 0;
  }
`;

export const PlanLabel = styled.span`
  display: block;
  margin-left: 1rem;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
`;

export const Cell = styled.div`
  width: 50%;
  padding: 1rem;

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `};
`;

export const Error = styled.span`
  display: inline-block;
  font-size: 1.3rem;
  margin-top: 0.3rem;
  color: ${({ theme }) => theme.colors.red};
`;
