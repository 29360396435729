import React from "react";

function IconPlay(props) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 26 26"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <g
        transform="translate(2 2)"
        stroke="currentColor"
        strokeWidth="2"
        fill="none"
        fillRule="evenodd">
        <circle cx="11" cy="11" r="12" />
        <path d="M15.61 11L7.926 6.271v9.458L15.61 11z" />
      </g>
    </svg>
  );
}

export default IconPlay;
