import React from "react";

function IconTemplate() {
  return (
    <svg
      width="18"
      height="16"
      viewBox="0 0 18 16"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-1054.000000, -326.000000)">
        <g transform="translate(1044.000000, 307.000000)">
          <g transform="translate(0.000000, 9.000000)">
            <g transform="translate(7.000000, 6.000000)">
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M8,20 L8,11 L3,11 L3,20 L8,20 Z M21,20 L21,11 L10,11 L10,20 L21,20 Z M3,4 L3,9 L21,9 L21,4 L3,4 L3,4 Z"
                fill="#89929D"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconTemplate;
