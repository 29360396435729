import { formatCurrency, getDateLong } from "@wrstudios/utils";
import React, { useState } from "react";
import { cancelOrder } from "../../api/user";
import { useSession } from "../../Session";
import AlertDanger from "../common/AlertDanger";
import IconExternal from "../icons/IconExternal";
import {
  Cancel,
  Details,
  Order,
  Orders,
  Text,
  Title
} from "./styled/user-orders";

function UserOrders() {
  const { orders } = useSession();

  return (
    <>
      {!!orders.recurring.length && (
        <Orders>
          <Text>
            Your <strong>recurring</strong> transactions are listed below.
          </Text>
          {orders.recurring.map((order) => (
            <UserOrder
              key={order.id}
              id={order.id}
              name={order.name}
              price={order.price}
              chargeDate={order.chargeDate}
              isPaid={order.isPaid}
              billingUrl={order.billingUrl}
            />
          ))}
        </Orders>
      )}
      {!!orders.oneTime.length && (
        <Orders>
          <Text>
            Your <strong>one-time</strong> transactions are listed below.
          </Text>
          {orders.oneTime.map((order) => (
            <UserOrder
              key={order.id}
              id={order.id}
              name={order.name}
              price={order.price}
              chargeDate={order.chargeDate}
              isPaid={order.isPaid}
              billingUrl={order.billingUrl}
            />
          ))}
        </Orders>
      )}
    </>
  );
}

function UserOrder({ id, name, price, chargeDate, isPaid, billingUrl }) {
  const { setOrders } = useSession();
  const [isCancelling, setIsCancelling] = useState(false);

  const handleCancel = () => {
    cancelOrder(id);
    setOrders((orders) => ({
      ...orders,
      recurring: orders.recurring.filter((order) => order.id !== id),
      oneTime: orders.oneTime.filter((order) => order.id !== id)
    }));
  };

  return (
    <Order>
      <Title>
        {name}
        {!!price && <span>{formatCurrency(price, "$0[.]00")}</span>}
      </Title>
      <Details>
        {getDateLong(chargeDate)}
        {!isPaid && (
          <>
            {" - "}
            <Cancel
              as={billingUrl ? "a" : "button"}
              type={billingUrl ? undefined : "button"}
              href={billingUrl ? billingUrl : undefined}
              onClick={billingUrl ? undefined : () => setIsCancelling(true)}>
              {billingUrl ? (
                <>
                  See Billing <IconExternal />
                </>
              ) : (
                "Cancel Order"
              )}
            </Cancel>
          </>
        )}
      </Details>
      {isCancelling && (
        <AlertDanger
          title="Cancel Order"
          confirmButton="Cancel Forever"
          onConfirm={handleCancel}
          onClose={() => setIsCancelling(false)}>
          Are you sure you want to cancel the order: <strong>{name}</strong>?
        </AlertDanger>
      )}
    </Order>
  );
}

export default UserOrders;
