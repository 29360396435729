import { requestState, resolvedState, rejectedState } from "../../utils/redux";

export function request(state, action) {
  const { reportId } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === reportId) {
      report = requestState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function resolved(state, action) {
  const { reportId } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === reportId) {
      report = resolvedState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function rejected(state, action) {
  const { reportId } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === reportId) {
      report = rejectedState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}
