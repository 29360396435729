import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import IconErrorOutline from "../icons/material/alert/IconErrorOutline";
import {
  ModalContent,
  Actions,
  Action,
  Button,
  Icon
} from "./styled/alert-danger";

function AlertDanger({
  title,
  cancelButton,
  confirmButton,
  children,
  onCancel,
  onConfirm,
  onClose,
  ...props
}) {
  return (
    <Modal {...props} onClose={onClose}>
      <ModalContent>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Actions>
            <Action>
              <Button
                app="attract"
                variant="secondary"
                onClick={() => {
                  onCancel();
                  onClose();
                }}>
                {cancelButton}
              </Button>
            </Action>
            <Action>
              <Button
                app="attract"
                data-autofocus
                onClick={() => {
                  onConfirm();
                  onClose();
                }}>
                <Icon>
                  <IconErrorOutline />
                </Icon>
                {confirmButton}
              </Button>
            </Action>
          </Actions>
        </Modal.Footer>
      </ModalContent>
    </Modal>
  );
}

AlertDanger.defaultProps = {
  title: "Danger",
  cancelButton: "Cancel",
  confirmButton: "Confirm",
  onCancel: () => {},
  onConfirm: () => {}
};

AlertDanger.propTypes = {
  title: PropTypes.string,
  cancelButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  confirmButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

export default AlertDanger;
