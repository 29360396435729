import styled from "styled-components";

export const Container = styled.i`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 0.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;
