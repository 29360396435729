import React, { useState, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "@wrstudios/components";
import { updateCover } from "../../actions/customize";
import CustomizeModalPowerPack from "./CustomizeModalPowerPack";
import CustomizeModalPowerPackCheckout from "./CustomizeModalPowerPackCheckout";
import CustomizeModalTomFerry from "./CustomizeModalTomFerry";
import CustomizeModalTomFerryCheckout from "./CustomizeModalTomFerryCheckout";
import CustomizeModalCoverImages from "./CustomizeModalCoverImages";
import CustomizeModalCoverRemove from "./CustomizeModalCoverRemove";
import {
  ModalContent,
  Body,
  Remove,
  Group
} from "./styled/customize-modal-cover";

function CustomizeModalCover({
  report,
  offerMarketingCovers,
  offerLuxCovers,
  offerTomCovers,
  sections,
  showMarketingCovers,
  showLuxCovers,
  showTomCovers,
  selectedCover,
  marketingCovers,
  tomCovers,
  luxCovers,
  details,
  updateCover,
  onClose
}) {
  const { marketing, tom, lux } = details;
  const [showMarketingModal, setShowMarketingModal] = useState(false);
  const [showMarketingCheckoutModal, setShowMarketingCheckoutModal] =
    useState(false);
  const [showPowerPackModal, setShowPowerPackModal] = useState(false);
  const [showPowerPackCheckoutModal, setShowPowerPackCheckoutModal] =
    useState(false);
  const [showTomFerryModal, setShowTomFerryModal] = useState(false);
  const [showTomFerryCheckoutModal, setShowTomFerryCheckoutModal] =
    useState(false);
  const [clickedCover, setClickedCover] = useState(null);

  const handleCheckout = () => {
    setShowMarketingModal(false);
    setShowMarketingCheckoutModal(false);
    setShowPowerPackModal(false);
    setShowPowerPackCheckoutModal(false);
    setShowTomFerryModal(false);
    setShowTomFerryCheckoutModal(false);

    if (clickedCover) {
      updateCover(report.id, clickedCover);
      onClose();
    }
  };

  return (
    <>
      <Modal onClose={onClose}>
        <ModalContent>
          <Modal.Header>
            Cover
            <Modal.Close />
          </Modal.Header>
          <Modal.Body>
            {selectedCover.key && selectedCover.key !== "no_cover" && (
              <Remove>
                <CustomizeModalCoverRemove onClick={onClose} />
              </Remove>
            )}
            <Body>
              {sections.map((section, index) => (
                <Fragment key={index}>
                  {showMarketingCovers && section === "power_pack" && (
                    <Group>
                      <CustomizeModalCoverImages
                        title="Power Pack Designer Covers"
                        images={marketingCovers}
                        onClick={onClose}
                        offer={offerMarketingCovers}
                        clickedCover={clickedCover}
                        onOfferClick={() => {
                          setClickedCover(null);
                          setShowMarketingCheckoutModal(true);
                        }}
                        onOfferImageClick={(cover) => {
                          setClickedCover(cover);
                          setShowMarketingModal(true);
                        }}
                      />
                    </Group>
                  )}
                  {showTomCovers && section === "tom" && (
                    <Group>
                      <CustomizeModalCoverImages
                        title="Tom Ferry Lifestyle Covers"
                        images={tomCovers}
                        tag="premium"
                        onClick={onClose}
                        offer={offerTomCovers}
                        clickedCover={clickedCover}
                        onOfferClick={() => {
                          setClickedCover(null);
                          setShowTomFerryCheckoutModal(true);
                        }}
                        onOfferImageClick={(cover) => {
                          setClickedCover(cover);
                          setShowTomFerryModal(true);
                        }}
                      />
                    </Group>
                  )}
                  {showLuxCovers && section === "lux" && (
                    <Group>
                      <CustomizeModalCoverImages
                        title="Power Pack Designer Covers"
                        images={luxCovers}
                        tag="premium"
                        onClick={onClose}
                        offer={offerLuxCovers}
                        clickedCover={clickedCover}
                        onOfferClick={() => {
                          setClickedCover(null);
                          setShowPowerPackCheckoutModal(true);
                        }}
                        onOfferImageClick={(cover) => {
                          setClickedCover(cover);
                          setShowPowerPackModal(true);
                        }}
                      />
                    </Group>
                  )}
                </Fragment>
              ))}
            </Body>
          </Modal.Body>
        </ModalContent>
      </Modal>
      {showMarketingModal && (
        <CustomizeModalPowerPack
          onConfirm={() => {
            setShowMarketingModal(false);
            setShowMarketingCheckoutModal(true);
          }}
          onClose={() => setShowMarketingModal(false)}
        />
      )}
      {showMarketingCheckoutModal && (
        <CustomizeModalPowerPackCheckout
          sku={marketing.sku}
          amount={marketing.amount}
          onCheckout={handleCheckout}
          onClose={() => setShowMarketingCheckoutModal(false)}
        />
      )}
      {showPowerPackModal && (
        <CustomizeModalPowerPack
          onConfirm={() => {
            setShowPowerPackModal(false);
            setShowPowerPackCheckoutModal(true);
          }}
          onClose={() => setShowPowerPackModal(false)}
        />
      )}
      {showPowerPackCheckoutModal && (
        <CustomizeModalPowerPackCheckout
          sku={lux.sku}
          amount={lux.amount}
          onCheckout={handleCheckout}
          onClose={() => setShowPowerPackCheckoutModal(false)}
        />
      )}
      {showTomFerryModal && (
        <CustomizeModalTomFerry
          onConfirm={() => {
            setShowTomFerryModal(false);
            setShowTomFerryCheckoutModal(true);
          }}
          onClose={() => setShowTomFerryModal(false)}
        />
      )}
      {showTomFerryCheckoutModal && (
        <CustomizeModalTomFerryCheckout
          sku={tom.sku}
          amount={tom.amount}
          onCheckout={handleCheckout}
          onClose={() => setShowTomFerryCheckoutModal(false)}
        />
      )}
    </>
  );
}

CustomizeModalCover.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number
  }),
  offerMarketingCovers: PropTypes.bool,
  offerLuxCovers: PropTypes.bool,
  offerTomCovers: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.string),
  showMarketingCovers: PropTypes.bool,
  showLuxCovers: PropTypes.bool,
  showTomCovers: PropTypes.bool,
  luxCovers: PropTypes.array,
  selectedCover: PropTypes.array,
  marketingCovers: PropTypes.array,
  selectedCover: PropTypes.shape({
    key: PropTypes.string
  }),

  details: PropTypes.shape({
    marketing: PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    lux: PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    tom: PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  updateCover: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = (state) => {
  const { report } = state;
  const { powerPacks, cover } = state.customize;
  const { offer, show, sections, details } = powerPacks;
  const {
    pack_marketing_2013: marketingCovers,
    tom_ferry_lpkt: tomCovers,
    luxe_power_pack: luxCovers
  } = cover.all;

  return {
    report,
    offerMarketingCovers: offer.marketing,
    offerLuxCovers: offer.lux,
    offerTomCovers: offer.tom,
    showMarketingCovers: show.marketing,
    showLuxCovers: show.lux,
    showTomCovers: show.tom,
    sections,
    marketingCovers,
    tomCovers,
    luxCovers,
    selectedCover: cover.selected,
    details
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateCover: (reportId, cover) => dispatch(updateCover(reportId, cover))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalCover);
