import React from "react";
import PropTypes from "prop-types";
import {
  formatDate,
  formatNumber,
  formatCurrency,
  getYoutubeId,
  getVimeoId,
  getMatterportId
} from "@wrstudios/utils";
import Adjustment from "../common/Adjustment";
import { useHighlighter } from "./utils";
import {
  Row,
  Label,
  Text,
  MobileText,
  Value,
  PriceDifference,
  Link
} from "./styled/comparable";

function ComparableDetails({
  originalListPrice,
  listPrice,
  soldPrice,
  price,
  adjustedPrice,
  soldToListPricePercentage,
  showAdjustments,
  soldDate,
  pricePerSqft,
  adjustedPricePerSqft,
  pricePerAcre,
  adjustedPricePerAcre,
  dom,
  hasAcres,
  virtualTour,
  ...props
}) {
  const youtubeId = getYoutubeId(virtualTour);
  const vimeoId = getVimeoId(virtualTour);
  const matterportId = getMatterportId(virtualTour);
  const hasSupportedTourType = Boolean(youtubeId || vimeoId || matterportId);
  const { handleMouseEnter, handleMouseLeave } = useHighlighter();

  return (
    <div {...props}>
      <Row
        className="original-list-price"
        data-name="original-list-price"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Original List Price">
          <Text>Orig. Price</Text>
          <MobileText>Orig. Price</MobileText>
        </Label>
        <Value
          className="comparable-value"
          title={originalListPrice ? formatCurrency(originalListPrice) : ""}>
          {originalListPrice ? formatCurrency(originalListPrice) : "-"}
        </Value>
      </Row>
      <Row
        className="list-price"
        data-name="list-price"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="List Price">
          <Text>List Price</Text>
          <MobileText>List Price</MobileText>
        </Label>
        <Value
          className="comparable-value"
          title={listPrice ? formatCurrency(listPrice) : ""}
          shouldDim={listPrice === originalListPrice}>
          {listPrice ? formatCurrency(listPrice) : "-"}
        </Value>
      </Row>
      <Row
        className="sold-price"
        data-name="sold-price"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Sold Price">
          <Text>
            Sold Price
            {!!soldToListPricePercentage && (
              <PriceDifference>
                &nbsp;{formatNumber(soldToListPricePercentage, "0.[0]")}%
              </PriceDifference>
            )}
          </Text>
          <MobileText>
            Sold Price
            {!!soldToListPricePercentage && (
              <PriceDifference>
                &nbsp;{formatNumber(soldToListPricePercentage, "0.[0]")}%
              </PriceDifference>
            )}
          </MobileText>
        </Label>
        <Value
          className="comparable-value"
          title={soldPrice ? formatCurrency(soldPrice) : ""}>
          {soldPrice ? formatCurrency(soldPrice) : "-"}
        </Value>
      </Row>
      {!!showAdjustments && (
        <Row
          className="adjusted-price"
          data-name="adjusted-price"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <Label title="Adj. Price">
            <Text>Adj. Price</Text>
            <MobileText>Adj. Price</MobileText>
          </Label>
          <Value
            className="comparable-value"
            title={adjustedPrice ? formatCurrency(adjustedPrice) : ""}>
            {adjustedPrice ? (
              <Adjustment
                className="comparable-value"
                value={price}
                adjustedValue={adjustedPrice}
              />
            ) : (
              "-"
            )}
          </Value>
        </Row>
      )}
      {!hasAcres && (
        <Row
          className="price-per-sqft"
          data-name="price-per-sqft"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <Label title="Price per Sq. Ft.">
            <Text>Price per Sq. Ft.</Text>
            <MobileText>$/Sq. Ft.</MobileText>
          </Label>
          <Value
            className="comparable-value"
            title={
              adjustedPricePerSqft || pricePerSqft
                ? formatCurrency(adjustedPricePerSqft || pricePerSqft)
                : ""
            }>
            {adjustedPricePerSqft || pricePerSqft ? (
              <Adjustment
                value={pricePerSqft}
                adjustedValue={adjustedPricePerSqft}
                shouldDim={false}
              />
            ) : (
              "-"
            )}
          </Value>
        </Row>
      )}
      {hasAcres && (
        <Row
          className="price-per-acre"
          data-name="price-per-acre"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}>
          <Label title="Price per Acre">
            <Text>Price per Acre</Text>
            <MobileText>$/Acre</MobileText>
          </Label>
          <Value
            className="comparable-value"
            title={
              adjustedPricePerAcre || pricePerAcre
                ? formatCurrency(adjustedPricePerAcre || pricePerAcre)
                : ""
            }>
            {adjustedPricePerAcre || pricePerAcre ? (
              <Adjustment
                value={pricePerAcre}
                adjustedValue={adjustedPricePerAcre}
                shouldDim={false}
                className="comparable-value"
              />
            ) : (
              "-"
            )}
          </Value>
        </Row>
      )}
      <Row
        className="sold-date"
        data-name="sold-date"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Sold Date">
          <Text>Sold Date</Text>
          <MobileText>Sold Date</MobileText>
        </Label>
        <Value
          className="comparable-value"
          title={
            soldDate
              ? formatDate({
                  dateValue: new Date(soldDate),
                  formating: "MM/dd/yyyy"
                })
              : ""
          }>
          {soldDate
            ? formatDate({
                dateValue: new Date(soldDate),
                formating: "MM/dd/yyyy"
              })
            : "-"}
        </Value>
      </Row>
      <Row
        className="dom"
        data-name="dom"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Days on Market">
          <Text>Days on Market</Text>
          <MobileText>DOM</MobileText>
        </Label>
        <Value
          className="comparable-value"
          title={dom ? formatNumber(dom) : ""}>
          {dom ? formatNumber(dom) : "-"}
        </Value>
      </Row>
      <Row
        className="virtual-tour"
        data-name="virtual-tour"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Virtual Tour">
          <Text>Virtual Tour</Text>
          <MobileText>Virtual Tour</MobileText>
        </Label>
        <Value title={virtualTour}>
          {virtualTour ? (
            <Link
              data-virtual-tour
              as={hasSupportedTourType ? "button" : "a"}
              href={hasSupportedTourType ? undefined : virtualTour}
              target={hasSupportedTourType ? undefined : "_blank"}
              rel={hasSupportedTourType ? undefined : "noopeneer noreferer"}
              onClick={(e) => {
                !hasSupportedTourType && e.stopPropagation();
              }}>
              View
            </Link>
          ) : (
            "-"
          )}
        </Value>
      </Row>
    </div>
  );
}

ComparableDetails.defaultProps = {
  virtualTour: ""
};

ComparableDetails.propTypes = {
  originalListPrice: PropTypes.number,
  listPrice: PropTypes.number,
  soldPrice: PropTypes.number,
  price: PropTypes.number,
  adjustedPrice: PropTypes.number,
  soldToListPricePercentage: PropTypes.number,
  showAdjustments: PropTypes.bool,
  soldDate: PropTypes.string,
  pricePerSqft: PropTypes.number,
  adjustedPricePerSqft: PropTypes.number,
  pricePerAcre: PropTypes.number,
  adjustedPricePerAcre: PropTypes.number,
  dom: PropTypes.number,
  virtualTour: PropTypes.string
};

export default ComparableDetails;
