import { connect } from "react-redux";
import { saveTemplate } from "../../../actions/customize";
import { getPageKeys } from "../../../utils/page";
import { getReportType } from "../../../utils/types";
import {
  groupActivePages,
  ungroupTemplates
} from "../../../selectors/customize";
import CustomizeModalTemplateCreate from "../CustomizeModalTemplateCreate";

function select({ report, ...state }) {
  const pageCountWithoutListingsParent = (
    state.customize.active.pages || []
  ).filter((page) => page.key !== "listings").length;

  return {
    reportId: report.id || -1,
    reportType: getReportType(report.type),
    pages: getPageKeys(groupActivePages(state)),
    templates: ungroupTemplates(state),
    activePageCount: pageCountWithoutListingsParent,
    isActive: state.customize.template.selected.key === "unsaved",
    isRenaming: state.customize.template.rename || false,
    isDeleting: state.customize.template.remove || false,
    isFetching: state.customize.template.request.fetching,
    hasFetched: state.customize.template.request.fetched,
    hasError: !!Object.values(state.customize.template.request.error || {})
      .length
  };
}

const actions = {
  saveTemplate
};

export default connect(select, actions)(CustomizeModalTemplateCreate);
