import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import IconDocument from "../../icons/IconDocument";
import IconErrorOutline from "../../icons/material/alert/IconErrorOutline";
import CustomizeModalDescription from "../CustomizeModalDescription";
import CustomizeControlsButton from "./CustomizeControlsButton";
import {
  Container,
  Icon,
  Text,
  Title,
  SubTitle
} from "./styled/customize-controls-button";

function CustomizeControlsDescription({
  description: {
    value,
    request: { fetching, error = {} }
  }
}) {
  const hasError = error && !!Object.keys(error).length;

  return (
    <CustomizeControlsButton>
      {({ isModalOpen, openModal, closeModal }) => (
        <Fragment>
          <Container
            disabled={fetching}
            aria-label="Enter a Description"
            onClick={openModal}>
            <Icon hasError={hasError}>
              {!fetching && !hasError && (
                <IconDocument width={24} height={24} viewBox="-4 -2 24 24" />
              )}
              {fetching && <Loading />}
              {!fetching && hasError && (
                <IconErrorOutline width={24} height={24} />
              )}
            </Icon>
            <Text hasError={hasError}>
              <Title>Description</Title>
              <SubTitle>{hasError ? error.message : value}</SubTitle>
            </Text>
          </Container>
          {isModalOpen && <CustomizeModalDescription onClose={closeModal} />}
        </Fragment>
      )}
    </CustomizeControlsButton>
  );
}

CustomizeControlsDescription.propTypes = {
  description: PropTypes.shape({
    value: PropTypes.string.isRequired,
    request: PropTypes.object.isRequired
  })
};

export default CustomizeControlsDescription;
