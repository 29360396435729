import styled, { createGlobalStyle } from "styled-components";

export const Container = styled.div`
  margin-bottom: 3rem;
  position: sticky;
  top: 0;
  z-index: 1000;

  .logo {
    max-width: none;
  }
`;

export const Global = createGlobalStyle`
  .menu-item-content {
    .label {
      color: unset;
      font-size: unset;
      vertical-align: unset;
      margin-bottom: unset;
    }
  }
`;
