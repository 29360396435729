import { connect } from "react-redux";
import CustomizeControlsDescription from "../CustomizeControlsDescription";

function select({ customize: { description } }) {
  return {
    description
  };
}

const actions = {};

export default connect(select, actions)(CustomizeControlsDescription);
