import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return requestState(state);
}

export function resolved(state, action) {
  const { comparableId, toggleState } = action.payload;
  const toggleAll =
    typeof comparableId === "undefined" && typeof toggleState !== "undefined";

  const listings = [...state.all].map((listing) => {
    if (toggleAll) {
      listing.hide = !toggleState;
    } else if (listing.id === comparableId) {
      listing.hide =
        typeof toggleState === "undefined" ? !listing.hide : toggleState;
    }

    return listing;
  });

  return resolvedState({ ...state, all: listings });
}

export function rejected(state, action) {
  return rejectedState(state, action.error);
}
