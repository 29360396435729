import { formatCurrency, formatDate } from "@wrstudios/utils";
import { addDays } from "date-fns";
import PropTypes from "prop-types";
import React from "react";
import IconBadge from "../icons/IconBadge";
import {
  Body,
  Disclaimer,
  Header,
  Stamp,
  Strong,
  Text
} from "./styled/order-form";

function OrderDisclaimer({ title, amount, hasCard }) {
  return (
    <Disclaimer hasCard={hasCard}>
      <Body>
        <Header>Try it free for 14 days</Header>
        <Text>
          Here's the deal, you'll have full access to {title} through the entire
          trial period.{" "}
          <Strong>
            Your trial will end at midnight on{" "}
            {formatDate({
              dateValue: addDays(new Date(), 14),
              formating: "MMMM d, yyyy"
            })}
          </Strong>
          . Only then will you incur a one-time charge of{" "}
          <Strong>{formatCurrency(amount, "$0[.]00")}</Strong>. We'll send you a
          reminder email 10 days before the end of your trial. Your credit card
          will not be billed if you cancel within trial period. Go get 'em
          tiger!
        </Text>
      </Body>
      {!hasCard && (
        <Stamp>
          <IconBadge />
        </Stamp>
      )}
    </Disclaimer>
  );
}

OrderDisclaimer.propTypes = {
  title: PropTypes.string,
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  hasCard: PropTypes.bool
};

export default OrderDisclaimer;
