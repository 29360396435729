import { sort } from "timsort";
import { cloneDeep } from "lodash";
import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

const templateKey = "My Custom Templates";

let allTemplatesBeforeModification = {};
let selectedTemplateBeforeModification = {};

export function request(state, action) {
  allTemplatesBeforeModification = cloneDeep(state.template.all);
  selectedTemplateBeforeModification = cloneDeep(state.template.selected);

  const { template } = action.payload;
  let allTemplates = cloneDeep(state.template.all);

  if (typeof template.key !== "undefined") {
    if (!allTemplates.hasOwnProperty(templateKey)) {
      allTemplates[templateKey] = [];
    }

    allTemplates[templateKey] = allTemplates[templateKey].map((t) => {
      if (t[1] == template.key) {
        t[0] = template.title;
        t[2] = (state.active.pages || []).length;
      }

      return t;
    });

    return {
      ...state,
      template: requestState({
        ...state.template,
        all: allTemplates,
        selected: template,
        load: false,
        save: false,
        rename: true,
        hasModified: false
      })
    };
  }

  return {
    ...state,
    template: requestState({
      ...state.template,
      all: allTemplates,
      load: false,
      save: true,
      rename: false
    })
  };
}

export function resolved(state, action) {
  const { template_id: key, template_page_count: count } = action.payload[0];
  const { template } = action.payload;
  let allTemplates = cloneDeep(state.template.all);

  if (!allTemplates.hasOwnProperty(templateKey)) {
    allTemplates[templateKey] = [];
  }

  const containsTemplate = !!allTemplates[templateKey].find(
    ([, templateKey]) => templateKey == key
  );

  if (!containsTemplate) {
    allTemplates[templateKey].push([template.title, key, count]);

    sort(allTemplates[templateKey], ([titleA], [titleB]) => {
      if (titleA > titleB) return 1;
      if (titleA < titleB) return -1;
      return 0;
    });

    return {
      ...state,
      template: resolvedState({
        ...state.template,
        all: allTemplates,
        selected: { key, title: template.title },
        load: true,
        save: true,
        rename: false,
        hasModified: false
      })
    };
  }

  return {
    ...state,
    template: resolvedState({
      ...state.template,
      load: true,
      save: false,
      rename: false,
      hasModified: false
    })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    template: rejectedState(
      {
        ...state.template,
        all: allTemplatesBeforeModification,
        selected: selectedTemplateBeforeModification,
        save: false
      },
      action.payload.error
    )
  };
}
