import {
  CUSTOMIZE_ADD_ACTIVE_PAGE,
  CUSTOMIZE_ADD_CUSTOM_PAGE,
  CUSTOMIZE_ADD_CUSTOM_PDF_PAGE,
  CUSTOMIZE_CLEAR_TEMPLATE,
  CUSTOMIZE_EDIT_CUSTOM_PAGE,
  CUSTOMIZE_PAGE_META,
  CUSTOMIZE_REMOVE_ACTIVE_PAGE,
  CUSTOMIZE_REMOVE_CUSTOM_PAGE,
  CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE,
  CUSTOMIZE_REMOVE_TEMPLATE,
  CUSTOMIZE_RENAME_CUSTOM_PAGE,
  CUSTOMIZE_RESET_PAGES,
  CUSTOMIZE_RESET_REQUEST,
  CUSTOMIZE_RESET_SELECTED_PAGE,
  CUSTOMIZE_SAVE_CUSTOM_PAGE,
  CUSTOMIZE_SAVE_TEMPLATE,
  CUSTOMIZE_SELECT_TEMPLATE,
  CUSTOMIZE_SORT_ACTIVE_PAGES,
  CUSTOMIZE_SORT_ACTIVE_PAGE_CHILDREN,
  CUSTOMIZE_UPDATE_COVER,
  CUSTOMIZE_UPDATE_DESCRIPTION,
  CUSTOMIZE_UPDATE_FONT,
  CUSTOMIZE_UPDATE_HEADLINE,
  CUSTOMIZE_UPDATE_ILLUSTRATION,
  CUSTOMIZE_UPDATE_LANGUAGE,
  CUSTOMIZE_UPDATE_LAYOUT,
  CUSTOMIZE_UPDATE_TEMPLATE,
  CUSTOMIZE_UPDATE_THEME,
  CUSTOMIZE_UPDATE_TITLE
} from "../actionTypes/customize";
import { api } from "../utils/redux";

export function resetRequestState(key) {
  return {
    type: CUSTOMIZE_RESET_REQUEST,
    payload: { key }
  };
}

export function resetSelectedPage() {
  return {
    type: CUSTOMIZE_RESET_SELECTED_PAGE
  };
}

export function addActivePage(key) {
  return {
    type: CUSTOMIZE_ADD_ACTIVE_PAGE,
    payload: { key }
  };
}

export function removeActivePage(key) {
  return {
    type: CUSTOMIZE_REMOVE_ACTIVE_PAGE,
    payload: { key }
  };
}

export function sortActivePages(startIndex, endIndex) {
  return {
    type: CUSTOMIZE_SORT_ACTIVE_PAGES,
    payload: { startIndex, endIndex }
  };
}

export function sortActivePageChildren(type, startIndex, endIndex) {
  return {
    type: CUSTOMIZE_SORT_ACTIVE_PAGE_CHILDREN,
    payload: { type, startIndex, endIndex }
  };
}

function update(reportId, data) {
  return {
    method: "put",
    url: `/reports/${reportId}`,
    data,
    ensure: true
  };
}

function updateFlyer(reportId, data) {
  return {
    method: "put",
    url: `/flyers/${reportId}`,
    data: { flyer: data },
    ensure: true
  };
}

export function resetPages(reportId, reportType) {
  return api(
    CUSTOMIZE_RESET_PAGES,
    {},
    {
      method: "get",
      url: `/${reportType}/${reportId}/customize`,
      ensure: true,
      data: { pages: "default" }
    }
  );
}

export function updateTheme(reportId, reportType, theme) {
  return api(CUSTOMIZE_UPDATE_THEME, { theme }, [
    update(reportId, { theme: theme.key, font: null, template: null }),
    {
      method: "get",
      url: `/${reportType}/${reportId}/customize`,
      ensure: true,
      data: { pages: "default" }
    }
  ]);
}

export function updateLayout(reportId, layout) {
  return api(
    CUSTOMIZE_UPDATE_LAYOUT,
    { layout },
    update(reportId, { layout: layout.key })
  );
}

export function selectTemplate(reportId, reportType, template) {
  return api(CUSTOMIZE_SELECT_TEMPLATE, { template }, [
    update(reportId, { page_template: template.key }),
    {
      method: "get",
      url: `/${reportType}/${reportId}/customize`,
      ensure: true,
      data: { template: template.key }
    }
  ]);
}

export function saveTemplate(reportId, reportType, template, pages) {
  return api(CUSTOMIZE_SAVE_TEMPLATE, { template, pages }, [
    {
      method: "post",
      url: `/${reportType}/${reportId}/template`,
      data: {
        pages,
        template: template.title,
        template_key: template.key
      }
    },
    {
      method: "put",
      url: `/reports/${reportId}`,
      data: (_, newState) => ({ page_template: newState.template_id })
    }
  ]);
}

export function removeTemplate(userId, template) {
  return api(
    CUSTOMIZE_REMOVE_TEMPLATE,
    { template },
    {
      method: "delete",
      url: `/users/${userId}/page_templates/${template.key}`,
      ensure: true
    }
  );
}

export function updateTemplate(reportId, reportType, template) {
  return api(CUSTOMIZE_UPDATE_TEMPLATE, { template }, [
    update(reportId, { page_template: template.key }),
    {
      method: "get",
      url: `/${reportType}/${reportId}/customize`,
      ensure: true,
      data: { template: template.key }
    }
  ]);
}

export function clearTemplate(reportId, reportType, ensure = false) {
  return api(
    CUSTOMIZE_CLEAR_TEMPLATE,
    {},
    {
      method: "put",
      url: `/${reportType}/${reportId}/clear_page_template`,
      ensure
    }
  );
}

export function updateCover(reportId, cover) {
  return api(
    CUSTOMIZE_UPDATE_COVER,
    { cover },
    update(reportId, { cover: cover.key })
  );
}

export function updateFont(reportId, font) {
  return api(
    CUSTOMIZE_UPDATE_FONT,
    { ...font },
    update(reportId, { font: font.key })
  );
}

export function updateLanguage(reportId, locale) {
  return api(
    CUSTOMIZE_UPDATE_LANGUAGE,
    { locale },
    update(reportId, { locale })
  );
}

// Flyers title
export function updateTitle(reportId, title) {
  return api(
    CUSTOMIZE_UPDATE_TITLE,
    { title },
    updateFlyer(reportId, { headline: title.key })
  );
}

export function updateDescription(reportId, description) {
  return api(
    CUSTOMIZE_UPDATE_DESCRIPTION,
    { description },
    updateFlyer(reportId, { description })
  );
}

export function updateHeadline(reportId, headline) {
  return api(
    CUSTOMIZE_UPDATE_HEADLINE,
    { headline },
    update(reportId, { headline })
  );
}

export function includeIllustrations(reportId, includeIllustrations) {
  return api(
    CUSTOMIZE_UPDATE_ILLUSTRATION,
    { includeIllustrations },
    update(reportId, { illustrations: Number(includeIllustrations) })
  );
}

export function addCustomPage(userId, title) {
  return api(
    CUSTOMIZE_ADD_CUSTOM_PAGE,
    { title },
    {
      method: "post",
      url: `/users/${userId}/page_contents`,
      ensure: true,
      data: { title, key: title }
    }
  );
}

export function editCustomPage(pageKey) {
  return api(
    CUSTOMIZE_EDIT_CUSTOM_PAGE,
    { pageKey },
    {
      method: "get",
      url: `/page_contents/update_content`,
      ensure: true,
      data: { key: pageKey }
    }
  );
}

export function renameCustomPage(userId, page, title) {
  return api(
    CUSTOMIZE_RENAME_CUSTOM_PAGE,
    { page, newTitle: title },
    {
      method: "put",
      url: `/users/${userId}/page_contents/${page.id}/rename`,
      ensure: true,
      data: { title, key: title }
    }
  );
}

export function removeCustomPage(userId, page) {
  return api(
    CUSTOMIZE_REMOVE_CUSTOM_PAGE,
    { page },
    {
      method: "delete",
      url: `/users/${userId}/page_contents/${page.id}`,
      ensure: true
    }
  );
}

export function saveCustomPage(userId, page) {
  return api(
    CUSTOMIZE_SAVE_CUSTOM_PAGE,
    { page },
    {
      method: "put",
      url: `/users/${userId}/page_contents/${page.id}`,
      ensure: true,
      data: {
        page_content: {
          key: page.key,
          value: page.value
        }
      }
    }
  );
}

export function addCustomPDFPage(userId, formData) {
  return api(
    CUSTOMIZE_ADD_CUSTOM_PDF_PAGE,
    {},
    {
      method: "post",
      url: `/users/${userId}/custom_pages`,
      ensure: true,
      data: formData
    }
  );
}

export function removeCustomPDFPage(userId, pageId) {
  return api(
    CUSTOMIZE_REMOVE_CUSTOM_PDF_PAGE,
    { pageId },
    {
      method: "delete",
      url: `/users/${userId}/custom_pages/${pageId}`,
      ensure: true
    }
  );
}

export function updatePageMeta(key, meta) {
  return {
    type: CUSTOMIZE_PAGE_META,
    payload: { key, meta }
  };
}
