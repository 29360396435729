import { filter, mean, meanBy, minBy, maxBy, uniq } from "lodash";
import { median } from "./math";
import { sum } from "lodash";

export function getPredictionInterval({ actual, predicted }) {
  // some zValue options to make the prediction band smaller or larger.
  // the keys represent the percent likelihood that a new prediction will
  // fall within the interval given.
  const zValuesByPercent = {
    68: 1.0,
    80: 1.28,
    90: 1.645,
    95: 1.96,
    98: 2.33
  };
  const zValue = zValuesByPercent[80]; // change this number until damien is happy :D
  const squaredErrors = actual.map((_, i) =>
    Math.pow(predicted[i][1] - actual[i][1], 2)
  );
  const standardDeviation = Math.sqrt(
    (1 / (actual.length - 2)) * sum(squaredErrors)
  );
  return zValue * standardDeviation;
}

export function getLocalStorageItem(key, fallback) {
  const item = window.localStorage.getItem(key);
  if (!item) return fallback;
  return JSON.parse(item);
}

export function setLocalStorageItem(key, value) {
  return window.localStorage.setItem(key, JSON.stringify(value));
}

// * Notes: "price" represents price + adjustments from the back end
// * "rawPrice" represents price - adjustments from the back end

export function getMedianPrice(listings) {
  const listingPrices = listings
    .map((listing) => listing.price)
    .filter(Boolean);
  if (!listingPrices.length) return 0;
  return median(listingPrices);
}

export function getMedianAdjustedPrice(listings) {
  const listingPrices = listings
    .map((listing) => listing.adjustedPrice)
    .filter(Boolean);
  if (!listingPrices.length) return 0;
  return median(listingPrices);
}

export function getAveragePrice(listings) {
  const listingPrices = listings
    .map((listing) => listing.price)
    .filter(Boolean);
  if (!listingPrices.length) return 0;
  return mean(listingPrices);
}

export function getAverageAdjustedPrice(listings) {
  const listingPrices = listings
    .map((listing) => listing.adjustedPrice)
    .filter(Boolean);
  if (!listingPrices.length) return 0;
  return mean(listingPrices);
}

export function getAverageSqft(listings) {
  const listingSqfts = listings.map((listing) => listing.sqft).filter(Boolean);
  if (!listingSqfts.length) return 0;
  return mean(listingSqfts);
}

export function getAveragePricePerSqft(listings) {
  const listingsPricePerSqft = listings
    .map((listing) => listing.pricePerSqft)
    .filter(Boolean);
  if (!listingsPricePerSqft.length) return 0;
  return mean(listingsPricePerSqft);
}

export function getAveragePricePerSqftByLotSize(listings) {
  const listingsPricePerSqftByLotSize = listings
    .map((listing) => listing.pricePerSqftByLotSize)
    .filter(Boolean);
  if (!listingsPricePerSqftByLotSize.length) return 0;
  return mean(listingsPricePerSqftByLotSize);
}

export function getAverageAdjustedPricePerSqft(listings) {
  const listingsAdjustedPricePerSqft = listings
    .map((listing) => listing.adjustedPricePerSqft)
    .filter(Boolean);
  if (!listingsAdjustedPricePerSqft.length) return 0;
  return mean(listingsAdjustedPricePerSqft);
}

export function getAverageAdjustedPricePerSqftByLotSize(listings) {
  const listingsAdjustedPricePerSqftByLotSize = listings
    .map((listing) => listing.adjustedPricePerSqftByLotSize)
    .filter(Boolean);
  if (!listingsAdjustedPricePerSqftByLotSize.length) return 0;
  return mean(listingsAdjustedPricePerSqftByLotSize);
}

export function getAverageAcres(listings) {
  const listingAcres = listings.map((listing) => listing.acres).filter(Boolean);
  if (!listingAcres.length) return 0;
  return mean(listingAcres);
}

export function getAveragePricePerAcre(listings) {
  const listingsPricePerAcre = listings
    .map((listing) => listing.pricePerAcre)
    .filter(Boolean);
  if (!listingsPricePerAcre.length) return 0;
  return mean(listingsPricePerAcre);
}

export function getAverageAdjustedPricePerAcre(listings) {
  const listingsAdjustedPricePerAcre = listings
    .map((listing) => listing.adjustedPricePerAcre)
    .filter(Boolean);
  if (!listingsAdjustedPricePerAcre.length) return 0;
  return mean(listingsAdjustedPricePerAcre);
}

// * This will use price if no adjusted price is found
export function getListingByHighestAdjustedPrice(listings) {
  return maxBy(listings, "adjustedPrice");
}

// * This will use price if no adjusted price is found
export function getListingByLowestAdjustedPrice(listings) {
  return minBy(listings, "adjustedPrice");
}

export function getListingByMappedStatus(listings, mappedStatus) {
  if (!mappedStatus) return listings;
  return listings.filter((listing) => listing.mappedStatus === mappedStatus);
}

export function getAverageZestimate(listings) {
  const listingsZestimates = listings
    .map((listing) => listing.zestimate)
    .filter(Boolean);
  if (!listingsZestimates.length) return 0;
  return meanBy(filter(listingsZestimates, "value"), "value");
}

export function getHighestZestimateDifferences(listings) {
  const highestPriceDifference = Math.max(
    ...listings.map((listing) => Math.abs(listing.zestimate.priceDifference))
  );
  const highestPercentageDifference = Math.max(
    ...listings.map((listing) =>
      Math.abs(listing.zestimate.priceDifferencePercentage)
    )
  );
  return {
    priceDifference: highestPriceDifference,
    priceDifferencePercentage: highestPercentageDifference
  };
}

export function getAverageZestimatePriceDifferencePercentage(listings) {
  const priceDifferencePercentages = listings
    .map((listing) => Math.abs(listing.zestimate.priceDifferencePercentage))
    .filter((percentage) => percentage > 0.01);
  if (!priceDifferencePercentages.length) return 0;
  return mean(priceDifferencePercentages);
}

export function hasAveragePriceAdjustments(listings) {
  const averagePrice = getAveragePrice(listings);
  const averageAdjustedPrice = getAverageAdjustedPrice(listings);
  return Boolean(
    averagePrice &&
      averageAdjustedPrice &&
      averagePrice !== averageAdjustedPrice
  );
}

export function getAverageDOM(listings) {
  const doms = listings.map((listing) => listing.dom).filter(Boolean);
  if (!doms.length) return 0;
  return mean(doms);
}

export function getAverageBaths(listings) {
  const baths = listings.map((listing) => listing.baths).filter(Boolean);
  if (!baths.length) return 0;
  return mean(baths);
}

export function getAverageBeds(listings) {
  const beds = listings.map((listing) => listing.beds).filter(Boolean);
  if (!beds.length) return 0;
  return mean(beds);
}

export function getAverageGarages(listings) {
  const garages = listings.map((listing) => listing.garages).filter(Boolean);
  if (!garages.length) return 0;
  return mean(garages);
}

export function getAverageLotSize(listings) {
  const lotSizes = listings.map((listing) => listing.lotSize).filter(Boolean);
  if (!lotSizes.length) return 0;
  return mean(lotSizes);
}

export function getAverageSoldToListPricePercentage(listings) {
  const soldListings = listings.filter((listing) => listing.soldPrice);
  const diffs = soldListings.map(
    (listing) => listing.soldToListPricePercentage
  );
  if (!diffs.length) return 0;
  return mean(diffs);
}

export function allAreAcreage(listings) {
  if (!listings.length) return false;
  return listings.every((listing) => listing.isAcreage);
}

export function getMappedStatus(listings) {
  const mappedStatus = listings.map((listing) => listing.mappedStatus);
  return uniq(mappedStatus);
}
