import React from "react";

function IconStayValuable(props) {
  return (
    <svg viewBox="0 0 96 78" {...props}>
      <defs>
        <style>
          {
            ".Stay_valuable_svg__cls-1{fill:#576474}.Stay_valuable_svg__cls-2{fill:#fd5154}"
          }
        </style>
      </defs>
      <g id="Stay_valuable_svg__Layer_1" data-name="Layer 1">
        <path
          className="Stay_valuable_svg__cls-1"
          d="M3 26a1 1 0 0 0 1 1 1 1 0 0 0 1-1V14.38a1 1 0 0 0-.3-.71 1 1 0 0 0-.71-.3h-.2L17 2.32l13.23 11H30a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1 1 1 0 0 0 1-1v-12h2a1 1 0 0 0 .65-1.78L17.64.23a1 1 0 0 0-1.29 0L.36 13.61a1 1 0 0 0-.3 1.12 1 1 0 0 0 1 .66H3z"
        />
        <path
          className="Stay_valuable_svg__cls-2"
          d="M18.35 25v-1.33c2.22-.23 3.42-1.35 3.42-3.19 0-2.52-2.28-3.12-4.39-3.35-1.05-.09-2.1-.27-2.1-1.13s1-1.21 2-1.21a2.63 2.63 0 0 1 2.31 1.12l1.75-.81a3.49 3.49 0 0 0-3-2v-1.19h-1.87v1.17c-1.74.14-3.36 1.05-3.38 2.92 0 2.5 2 2.91 4.11 3.11 1.24.1 2.34.47 2.34 1.37 0 1.11-1 1.29-2.27 1.29a3.23 3.23 0 0 1-2.82-1.55l-1.85.89a4.54 4.54 0 0 0 3.8 2.57V25z"
        />
        <path
          className="Stay_valuable_svg__cls-1"
          d="M95.64 18.16l-16-13.34a1 1 0 0 0-1.3 0l-16 13.37a1 1 0 0 0-.3 1.12A1 1 0 0 0 63 20h2v10.62a1 1 0 0 0 1 1 1 1 0 0 0 1-1V19a1 1 0 0 0-1-1h-.2L79 6.91l13.23 11H92a1 1 0 0 0-1 1v13a1 1 0 0 0 1 1 1 1 0 0 0 1-1V20h2a1 1 0 0 0 1-.66 1 1 0 0 0-.36-1.18z"
        />
        <path
          className="Stay_valuable_svg__cls-2"
          d="M79.26 26.35a3.21 3.21 0 0 1-2.82-1.55l-1.85.89a4.54 4.54 0 0 0 3.8 2.57v1.34h1.94v-1.35c2.22-.22 3.42-1.34 3.42-3.19 0-2.52-2.28-3.12-4.4-3.35-1-.08-2.1-.27-2.1-1.12s1-1.22 2-1.22a2.65 2.65 0 0 1 2.31 1.12l1.74-.8a3.48 3.48 0 0 0-3-2v-1.2h-1.84v1.18c-1.75.14-3.36 1-3.38 2.92 0 2.49 2 2.91 4.11 3.11 1.24.1 2.34.47 2.34 1.36 0 1.11-.98 1.29-2.27 1.29z"
        />
        <path
          d="M94.16 35.93l-92-5.75a1 1 0 1 0-.12 2l47 2.93a1.33 1.33 0 0 0-.14.19l-8.15 16.75h.93a9.4 9.4 0 0 1 1.23.15l6.87-14.15L57.52 54a9.28 9.28 0 0 1 2.36.26l-9.19-18.94-.06-.09L94 37.94h.07a1 1 0 0 0 1-1 1 1 0 0 0-.91-1.01z"
          fill="#c4c8d0"
        />
        <path
          className="Stay_valuable_svg__cls-1"
          d="M56.05 54.1l-1.58.21c-1 .12-1.67.14-3.88.2H49.5a2.8 2.8 0 0 1-1-.23c-.4-.15-.84-.36-1.32-.59a16.35 16.35 0 0 0-4.27-1.5 9.4 9.4 0 0 0-1.23-.15h-.93c-5.4 0-8 3.17-10.06 5.72-.53.65-1 1.27-1.54 1.78-1.38 1.4-4.61 1.55-8 1.21l.05-1.3a2.76 2.76 0 0 0-2.2-2.79l-9.18-1.27a1 1 0 1 0-.28 2h.19l8.9 1.23a.7.7 0 0 1 .56.72v2.14l-.05 1-.33 8.23v2l-.13 3.1-.08.08-7.77-.5h-.11a1 1 0 0 0-1 .93 1 1 0 0 0 .94 1.07l7.78.5h.12a2.1 2.1 0 0 0 2.1-2l.12-2.93c4.88.64 11.63 1.5 13.59 1.7h.83c6.17 0 22.46-5 25.12-6.12 2-.85 9.86-6.59 14.14-10.06.81-.64 2.94-2.37 1.84-4.31-1.51-2.7-4.53-2.83-9-.4-.82.45-2.47 1.31-4.34 2.24a.16.16 0 0 0 0-.07 4.75 4.75 0 0 0-3-1.82 9.28 9.28 0 0 0-2.44-.12 10.58 10.58 0 0 0-1.47.1zm5.11 3.08a.83.83 0 0 1 0 .94c0 .05 0 .1-.08.16-.52.83-2.63 1.5-3.65 1.82h-.17l-.32.11c-3.53 1.19-4.55 1.27-7.31 1.13l-6.48-.62a1 1 0 0 0-.25 0 1 1 0 0 0-.85.88 1 1 0 0 0 .91 1.11l6.52.62c.66 0 1.23.06 1.77.06a17.83 17.83 0 0 0 6.33-1.29L58 62c1.94-.61 3.91-1.33 4.75-2.67a3.89 3.89 0 0 0 .47-1.11c2.14-1.05 4.07-2.06 5-2.56 4.92-2.7 5.86-1 6.22-.38.23.41-.86 1.36-1.34 1.75-4.55 3.68-12 9.07-13.65 9.77-2.54 1.05-20.14 6.41-25 5.93-1.93-.2-8.82-1.08-13.71-1.71l.33-8.25c3.77.35 7.62.11 9.52-1.82a24.74 24.74 0 0 0 1.67-1.92c1.83-2.25 3.72-4.56 7.48-4.93a11 11 0 0 1 1.83 0h.42a6.41 6.41 0 0 1 1.08.18 15.93 15.93 0 0 1 3.25 1.24 16.67 16.67 0 0 0 1.83.78 4.29 4.29 0 0 0 1.41.23h1.09c2.18-.06 2.9-.09 3.94-.2l1.82-.24a8 8 0 0 1 3.2.17 3 3 0 0 1 1.43.79.74.74 0 0 1 .12.13z"
        />
        <path
          d="M15.78 72.88a1.34 1.34 0 1 1-1.34-1.34 1.32 1.32 0 0 1 1.21.87 1.34 1.34 0 0 1 .13.47z"
          fill="#fff"
        />
        <path
          className="Stay_valuable_svg__cls-1"
          d="M14.48 71.55a1.37 1.37 0 1 0 1.25.88 1.32 1.32 0 0 0-1.25-.88z"
        />
      </g>
    </svg>
  );
}

export default IconStayValuable;
