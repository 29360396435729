import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/vimeo";

function Vimeo({ videoId, width, height, autoplay, muted, ...props }) {
  return (
    <Container {...props}>
      <iframe
        width={width}
        height={height}
        title="vimeo-player"
        src={`https://player.vimeo.com/video/${videoId}&autoplay=${
          autoplay ? 1 : 0
        }&muted=${muted ? 1 : 0}`}
        frameBorder="0"
        allowFullScreen
      />
    </Container>
  );
}

Vimeo.defaultProps = {
  width: "100%",
  height: "100%",
  autoplay: true,
  muted: true
};

Vimeo.propTypes = {
  videoId: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoplay: PropTypes.bool,
  muted: PropTypes.bool
};

export default Vimeo;
