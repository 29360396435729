import React from "react";

function IconCloudLogo(props) {
  return (
    <svg
      width="93.6"
      height="73.99"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 93.6 73.99"
      {...props}>
      <path d="M62.36,71.85H55.45a1.38,1.38,0,0,1-1.38-1.38V64.3a1.15,1.15,0,0,1,1.15-1.15h7.23A19.68,19.68,0,0,0,82.19,43.41,19.32,19.32,0,0,0,66,24.63l-0.26,0a3.84,3.84,0,0,1-2.81-2.1l-0.12-.24A20.17,20.17,0,0,0,44.58,10.84,20.41,20.41,0,0,0,24.37,29.36l0,0.32A3.84,3.84,0,0,1,21.85,33h0A15.8,15.8,0,0,0,11.43,46.86a15.52,15.52,0,0,0,28.72,8.92L46,46.08a1.14,1.14,0,0,1,1.56-.38L53.07,49a1.14,1.14,0,0,1,.38,1.56l-5.89,9.72A24.22,24.22,0,1,1,16.05,26,29,29,0,0,1,69.61,16.5a28,28,0,0,1,21.27,27C91,59.08,78,71.85,62.36,71.85Z" />
    </svg>
  );
}

export default IconCloudLogo;
