import { numeral } from "@wrstudios/utils";
import { stripNumber } from "./number";

/**
 * Formats a number or string.
 *
 * This will auto force formats with numbers larger than 10 million.
 * This will auto force falsely and infinity numbers to 0.
 *
 * @param {Number|String} value
 * @param {String} format
 * @param {Boolean} skipLargeFormat
 * @param {Number} precision
 */
function formatValue(
  value,
  format = "$0,0",
  skipLargeFormat = false,
  precision
) {
  if (
    Object.is(value, -0) ||
    Object.is(value, "-") ||
    Object.is(value, "$0-")
  ) {
    return "-$0";
  }

  value = stripNumber(value);

  if (Number.isNaN(value) || !Number.isFinite(value)) value = 0;
  if (typeof format === "undefined" || format === null) format = "$0,0";
  if (value >= 10000000 && !skipLargeFormat) format = "$0[.]0a";
  if (typeof precision !== "undefined" && precision !== null)
    value = value.toPrecision(precision);

  return numeral(value).format(format).toUpperCase();
}

/**
 * Transforms a value into currency with a specified format.
 *
 * @param {Number|String} value
 * @param {String} format
 * @param {Boolean} skipLargeFormat
 * @param {Number} precision
 */
export function currency(
  value,
  format = "$0,0",
  skipLargeFormat = false,
  precision
) {
  return formatValue(value, format, skipLargeFormat, precision);
}

/**
 * Transforms a value into comma delimited value.
 *
 * @param {Number|String} value
 */
export function comma(value) {
  return formatValue(value, "0,0", true);
}

/**
 * Snakecases a string
 *
 * @param {String} string
 */
export function snakeCase(string) {
  return string
    .replace(/[^\w\s]/gi, "") // Remove nonalpha chars
    .trim()
    .replace(/ /g, "_") // Replace spaces with underscores
    .toLowerCase();
}

/**
 * Pulls initials from a name.
 *
 * @param {String} name
 */
export function initials(name) {
  const nameAsArray = name.split(" ");
  const letters = nameAsArray.map((word) => word[0]).filter((word) => word);

  return letters.join("").trim();
}
