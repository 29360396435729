import camelcaseKeys from "camelcase-keys";
import api from "./api";

export function updatePageConfig(reportId, pageId, config) {
  return api.put(`/reports/${reportId}/pages/${pageId}`, { page: { config } });
}

export function getPageConfig({ reportId, pageId }) {
  return api
    .get("/pages/page_config", {
      params: { report_id: reportId, page_id: pageId }
    })
    .then((res) => {
      const data = camelcaseKeys(res.data, { deep: true }) || {
        config: null
      };
      return data.config || {};
    });
}
