import "tinymce/tinymce";
import "tinymce/icons/default";
import "tinymce/themes/silver";
import "tinymce/plugins/code";
import "tinymce/plugins/link";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/lists";
import React, { useState, useEffect, useLayoutEffect } from "react";
import PropTypes from "prop-types";
import { Editor as TinyMCE } from "@tinymce/tinymce-react";
import { formatNumber } from "@wrstudios/utils";
import { Container, GlobalStyle } from "./styled/editor";

function Editor({ className, maxCharCount, options, ...props }) {
  const [mounted, setMounted] = useState(false);

  useLayoutEffect(() => {
    charCountPlugin(maxCharCount);
  }, [maxCharCount]);

  useEffect(() => {
    // This delays the mount of tinymce. There's some cases where
    // the delay of 0 will not mount the component and throw an error.
    setMounted(true);
  }, []);

  if (!mounted) return null;

  return (
    <Container className={className}>
      <GlobalStyle />
      <TinyMCE
        {...props}
        init={{
          plugins: "code link image media lists charactercount",
          menubar: false,
          toolbar: [
            "fontsizeselect forecolor",
            "bold italic underline",
            "alignleft aligncenter alignright indent outdent",
            "bullist numlist",
            "link unlink image media",
            "undo redo code removeformat"
          ].join(" | "),
          branding: false,
          paste_data_images: true,
          image_title: true,
          media_poster: false,
          skin: "oxide",
          skin_url: "/skins/ui/oxide",
          content_css: "/skins/content/default/content.min.css",
          target_list: false,
          ...options
        }}
      />
    </Container>
  );
}

Editor.defaultProps = {
  options: {},
  maxCount: 0
};

Editor.propTypes = {
  options: PropTypes.object
};

export default Editor;

function charCountPlugin(maxCount) {
  tinymce.PluginManager.add("charactercount", function (editor) {
    if (maxCount) {
      editor.on("init", () => {
        const container = document.querySelector(
          ".tox-statusbar__text-container"
        );

        if (container) {
          const countContainer = document.createElement("div");
          countContainer.classList.add("count-container");
          container.appendChild(countContainer);

          editor.on("SetContent BeforeAddUndo Undo Redo keyup", () => {
            const contentLength = editor.getContent().length;

            countContainer.innerHTML = `${formatNumber(
              contentLength
            )} / ${formatNumber(maxCount)}`;

            countContainer.style.color =
              contentLength > maxCount ? "#d22d2d" : null;
          });
        }
      });
    }

    return {
      getMetadata: function () {
        return {
          name: "Character Count Plugin",
          url: "https://wrstudios.com"
        };
      }
    };
  });
}
