import styled from "styled-components";
import Dropdown from "../../dropdown/Dropdown";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  min-height: 10rem;
  transition: box-shadow 0.2s ease-in;
  box-shadow: ${({ theme }) => theme.shadows.raised};
  border: 0.2rem solid
    ${({ theme, isActive }) =>
      isActive ? theme.styleGuide.colors.cma[500] : theme.colors.grayLight};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  background-color: ${({ theme }) => theme.colors.white};
  position: relative;

  &:hover {
    box-shadow: ${({ theme }) => theme.shadows.overlay};
    border-color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  }
`;

export const Action = styled.button`
  width: 100%;
  height: 100%;
  padding: 2rem 1rem;

  &:focus {
    outline: none;
  }
`;

export const Label = styled.div`
  margin-bottom: 0.5rem;
  color: ${({ theme }) => theme.colors.gray};
`;

export const PageCount = styled.div`
  font-size: 1.4rem;
  color: ${({ theme }) => theme.colors.grayAlt};
`;

export const Actions = styled(Dropdown)`
  &.dropdown {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
  }

  .dropdown-menu {
    margin-top: -0.6rem;
    margin-left: -1.5rem;
  }
`;

export const ActionsButton = styled.span`
  color: ${({ theme }) => theme.colors.grayAlt};
`;

export const Input = styled.input`
  width: 100%;
  padding: 0.8rem;
`;

export const Form = styled.form`
  margin-top: ${({ isFetchingOrSuccess }) => !isFetchingOrSuccess && "2rem"};
  padding: 0 1rem;
`;

export const Controls = styled.div`
  font-size: 1.3rem;
  margin-top: 0.3rem;
  text-align: right;
`;

export const Cancel = styled.button`
  appearance: none;
  color: ${({ theme }) => theme.colors.gray};
`;

export const Save = styled.button`
  margin-left: 1rem;
  appearance: none;
  text-decoration: underline;
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};

  &[disabled] {
    cursor: not-allowed;
  }
`;
