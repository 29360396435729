import React, { useState } from "react";
import { cancelAccount } from "../../api/user";
import { useSession } from "../../Session";
import AlertDanger from "../common/AlertDanger";
import { Text, Cancel } from "./styled/user-cancel";

function UserCancel() {
  const { currentUser, subscription, config } = useSession();
  const [isCancelling, setIsCancelling] = useState(false);

  const handleConfirm = async () => {
    await cancelAccount();
    window.location.href = "/";
  };

  if (currentUser.isCASUser) {
    return (
      <>
        <Text align="center">
          To change your billing details or cancel your account, please visit
          Cloud Agent Suite.
        </Text>
        <Text align="center">
          <a
            href={`${config.casRootUrl}/app/billing`}
            target="_blank"
            rel="noreferrer">
            Go to Cloud Agent Suite
          </a>
        </Text>
      </>
    );
  }

  if (currentUser.isShared && currentUser.isAccountAdmin) {
    return (
      <>
        <Text>
          Since you are the account admin and this is a shared account that
          affects other users, you must contact{" "}
          <a
            href={`mailto: ${config.supportEmail}?subject=Shared Account Cancellation`}>
            {config.supportEmail}
          </a>{" "}
          if you wish to cancel it.
        </Text>
      </>
    );
  }

  return (
    <>
      <Text>
        We don't want to see you go! If there is anything we can help you with,
        please contact us at{" "}
        <a href={`mailto:${config.supportEmail}?subject=Account Cancellation`}>
          {config.supportEmail}
        </a>
        .
      </Text>
      <Text>
        Please keep in mind that once your account is cancelled, you will lose
        access to all your reports that we host in the cloud -{" "}
        <strong>
          please be sure to download them <i>first</i> if you wish to keep a
          copy.
        </strong>
      </Text>
      {subscription.isPurchasedFromOutsideStore &&
        !!subscription.affiliateName && (
          <>
            {subscription.affiliateToken === "mris" && (
              <Text>
                If you are an MRIS customer who purchased your subscription
                through the MRIS.com website, you must contact the MRIS Support
                Center to cancel at 1-888-838-8200. Please note that MRIS
                requires the cancellation to be in writing.
              </Text>
            )}
            {subscription.affiliateToken === "brightmls" && (
              <Text>
                If you are an BrightMLS customer who purchased your subscription
                through the BrightMLS.com website, you must contact the
                BrightMLS Support Center to cancel at 1-888-838-8200. Please
                note that BrightMLS requires the cancellation to be in writing.
              </Text>
            )}
            {!["mris", "brightmls"].includes(subscription.affiliateToken) && (
              <Text>
                {subscription.affiliateName} Store users must cancel their
                accounts from the store where they made their purchase.
              </Text>
            )}
          </>
        )}
      {!subscription.isPurchasedFromOutsideStore && (
        <>
          {subscription.amount > 0 && (
            <Text>
              If you are currently being billed, you won't be charged again, but
              you are responsible for any charges already incurred.
            </Text>
          )}
          <Text>
            I understand this is irreversible -{" "}
            <Cancel type="button" onClick={() => setIsCancelling(true)}>
              Please cancel my account
            </Cancel>
          </Text>
          {isCancelling && (
            <AlertDanger
              title="Cancel Account"
              confirmButton="Cancel Forever"
              onConfirm={handleConfirm}
              onClose={() => setIsCancelling(false)}>
              <Text normal>
                Are you sure you want to cancel your account? Remember - this is
                irreversible!
              </Text>
              <Text normal>
                You will receive an e-mail confirming your account has been
                successfully cancelled. Please contact{" "}
                <a href={`mailto:${config.supportEmail}`}>
                  {config.supportEmail}
                </a>{" "}
                if you do not receive confirmation or if you have any questions.
              </Text>
            </AlertDanger>
          )}
        </>
      )}
    </>
  );
}

export default UserCancel;
