import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { hasFeature } from "../../utils/report";
import IconLoading from "../icons/IconLoading";

function isFetching(obj) {
  return obj && obj.request && obj.request.fetching;
}

function CustomizeFlyer({ theme, layout, font, title, description, reportId }) {
  const refresh =
    isFetching(theme) ||
    isFetching(layout) ||
    isFetching(font) ||
    isFetching(title) ||
    isFetching(description);

  return (
    <div
      className={`customize-flyer-preview ${
        hasFeature(theme.selected, "modern_flyers")
          ? "customize-flyer-preview-modern"
          : "customize-flyer-preview-classic"
      }`}>
      <div className="preview-responsive">
        <div className="flex items-center justify-center w-full h-full absolute pin-t pin-l text-green leading-4xl text-2xl text-center">
          <div className="flex flex-col">
            <div className="text-center">
              <IconLoading />
            </div>
            <span className="block mt-8 text-lg leading-2xl">
              Loading Preview
            </span>
          </div>
        </div>

        {!refresh && (
          <iframe
            src={`/flyers/${reportId}/publish.html?preview`}
            className="hidden"
            frameBorder="0"
            onLoad={(e) => e.target.classList.remove("hidden")}
          />
        )}
      </div>
    </div>
  );
}

CustomizeFlyer.propTypes = {
  reportId: PropTypes.number.isRequired,
  theme: PropTypes.shape({
    selected: PropTypes.shape({
      key: PropTypes.string.isRequired
    }).isRequired,
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired
    })
  }).isRequired,
  layout: PropTypes.shape({
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired
    })
  }),
  font: PropTypes.shape({
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired
    })
  }),
  title: PropTypes.shape({
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired
    })
  }),
  description: PropTypes.shape({
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired
    })
  })
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  theme: state.customize.theme,
  layout: state.customize.layout,
  font: state.customize.font,
  title: state.customize.title,
  description: state.customize.description
});

export default connect(mapStateToProps)(CustomizeFlyer);
