import styled, { css } from "styled-components";

const placeholder = css`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-size: 1.3rem;
  font-weight: 500;
  font-style: italic;
  line-height: 1.8rem;
  padding: 2rem;
  text-align: center;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[400]};
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  position: absolute;
  top: 0;
  left: 0;
`;

export const Container = styled.img`
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  vertical-align: middle;
  object-fit: cover;
  position: relative;

  &:before {
    content: attr(data-error);
    ${placeholder};
  }
`;

export const Placeholder = styled.div`
  ${placeholder}
`;
