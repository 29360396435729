import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { loadImages } from "../../utils/image";
import { isModernTheme, hasFeature } from "../../utils/report";
import IconAutoRenew from "../icons/material/action/IconAutoRenew";
import CustomizeModalLayoutItemIcon from "./CustomizeModalLayoutItemIcon";
import CustomizeModalLayoutItemImage from "./CustomizeModalLayoutItemImage";

class CustomizeModalLayoutItems extends Component {
  state = {
    images: []
  };

  componentDidMount() {
    this.loadImages();
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  render() {
    const { layouts, theme, onClick } = this.props;
    const { images } = this.state;

    if (isModernTheme(theme) || hasFeature(theme, "modern_icons")) {
      return (
        <div className="flex flex-wrap -m-4">
          {layouts.map(([title, key], index) => (
            <div className="w-1/2 sm:w-1/3 md:w-1/4 p-4" key={index}>
              <CustomizeModalLayoutItemIcon
                layout={{ title, key }}
                onClick={onClick}
              />
            </div>
          ))}
        </div>
      );
    }

    if (!images.length) {
      return (
        <div className="flex items-center">
          <div className="spin mr-2">
            <IconAutoRenew />
          </div>
          <span>Loading Layouts</span>
        </div>
      );
    }

    return (
      <div className="flex flex-wrap -m-4 -mt-2">
        {layouts.map(([title, key], index) => (
          <div className="w-1/2 sm:w-1/3 md:w-1/4 p-4" key={index}>
            <CustomizeModalLayoutItemImage
              layout={{ title, key }}
              onClick={onClick}
            />
          </div>
        ))}
      </div>
    );
  }

  async loadImages() {
    const { theme, layouts } = this.props;

    if (
      this._isUnmounted ||
      isModernTheme(theme) ||
      hasFeature(theme, "modern_icons")
    )
      return;

    const images = await loadImages(
      layouts.map(([, key]) => `/images/reports/layouts/${key}.jpg`)
    );

    this.setState({ images });
  }
}

CustomizeModalLayoutItems.propTypes = {
  theme: PropTypes.shape({
    key: PropTypes.string,
    features: PropTypes.array
  }),
  layouts: PropTypes.arrayOf(PropTypes.array),
  onClick: PropTypes.func
};

const mapStateToProps = (state) => ({
  theme: state.customize.theme.selected,
  layouts: state.customize.layout.all
});

export default connect(mapStateToProps)(CustomizeModalLayoutItems);
