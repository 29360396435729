import {
  CUSTOMIZE_RESET_PAGES,
  CUSTOMIZE_UPDATE_THEME
} from "../../actionTypes/customize";
import { LISTINGS_ADD, LISTINGS_GET } from "../../actionTypes/listings";
import { resolved as getResolved } from "./get";

export default function (state = {}, action) {
  switch (action.type) {
    case `${LISTINGS_ADD}_RESOLVED`:
    case `${LISTINGS_GET}_RESOLVED`:
      return getResolved(state, action);

    case `${CUSTOMIZE_RESET_PAGES}_RESOLVED`:
      return { ...state, ...action.payload.report };

    case `${CUSTOMIZE_UPDATE_THEME}_RESOLVED`:
      return { ...state, is_modern: action.payload?.[0]?.report?.is_modern };

    default:
      return state;
  }
}
