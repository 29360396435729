import { useLazyQuery } from "@apollo/react-hooks";
import { get } from "lodash";
import React, { useEffect, useState } from "react";
import { updateUser } from "../../api/user";
import { useSession } from "../../Session";
import { useQueryString } from "../../utils/url";
import CardCreate from "../common/CardCreate";
import GettingStarted from "../common/CardGettingStarted";
import Notifications from "../common/Notifications";
import { ReportCardSkeleton } from "../common/ReportCard";
import Search from "../common/Search";
import Wrapper from "../common/Wrapper";
import IconSwitch from "../icons/IconSwitch";
import { GET_TOURS } from "./queries";
import {
  Cell,
  Container,
  Grid,
  Header,
  Paginate,
  SwitchBack,
  Title
} from "./styled/tours";
import TourBanner from "./TourBanner";
import TourCard from "./TourCard";

function Tours() {
  const { config, currentUser, setCurrentUser } = useSession();
  const { params } = useQueryString();
  const [search, setSearch] = useState(params.q || "");
  const [currentPage, setCurrentPage] = useState(params.page || 1);
  const [getTours, { data, loading }] = useLazyQuery(GET_TOURS, {
    fetchPolicy: "cache-and-network"
  });
  const meta = get(data, "tours.meta", {});
  const tours = get(data, "tours.results", []);
  const showOnboardingVideo = !loading && meta.totalResults < 5;

  useEffect(() => {
    getTours({ variables: { page: Number(currentPage || 1), search } });
  }, [currentPage, search]);

  return (
    <>
      <Notifications />
      <TourBanner />
      <Wrapper>
        <Container>
          <Header>
            <Title>Buyer Tours</Title>
            <Search
              defaultValue={search}
              placeholder="Search Buyer Tours"
              onSearch={(searchTerm) => {
                setSearch(searchTerm);
                setCurrentPage(1);
              }}
              onClear={() => {
                setSearch("");
                setCurrentPage(1);
              }}
            />
          </Header>
          <Grid>
            <Cell>
              <CardCreate as="a" href="/tours/new">
                Create Buyer Tour
              </CardCreate>
            </Cell>
            {loading &&
              new Array(5).fill().map((_, index) => (
                <Cell key={index}>
                  <ReportCardSkeleton />
                </Cell>
              ))}
            {!loading &&
              tours.map((tour) => (
                <Cell key={tour.id}>
                  <TourCard
                    id={tour.id}
                    guid={tour.guid}
                    title={tour.title}
                    type={tour.type}
                    status={tour.status}
                    pdfLink={tour.pdfLink}
                    notes={tour.notes}
                    thumbnail={tour.thumbnail}
                    createdWith={tour.createdWith}
                    addresses={tour.addresses || []}
                    cities={tour.cities || []}
                    viewCount={tour.eventSummary.viewCount}
                    lastViewedAt={tour.eventSummary.lastViewedAt}
                    updatedAt={tour.updatedAt}
                    refetchQuery={GET_TOURS}
                  />
                </Cell>
              ))}
            {showOnboardingVideo && (
              <Cell>
                <GettingStarted
                  videoTitle="Getting Started with Buyer Tours"
                  videoId={config.tourKickStartVideoId}>
                  Getting Started with Buyer Tours
                </GettingStarted>
              </Cell>
            )}
          </Grid>
          {!loading && (
            <Paginate
              scrollToTopOnPageChange
              currentPage={meta.currentPage}
              totalPages={meta.totalPages}
              perPage={meta.perPage}
              totalResults={meta.totalResults}
              onPageChange={setCurrentPage}
            />
          )}
          {currentUser.liveToursEnabled && (
            <SwitchBack
              onClick={() => {
                updateUser(currentUser.id, { live_tours_enabled: false });
                setCurrentUser({ liveToursEnabled: false });
              }}>
              <IconSwitch />
              <span>Switch to Classic Buyer Tours</span>
            </SwitchBack>
          )}
        </Container>
      </Wrapper>
    </>
  );
}

export default Tours;
