import React from "react";

function IconPartyPopper(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="64" {...props}>
      <text
        fill="#C5C9D1"
        fillRule="evenodd"
        fontFamily="AppleColorEmoji, Apple Color Emoji"
        fontSize="64"
        transform="translate(-331 -138)">
        <tspan x="331" y="197">
          {String.fromCharCode(0xd83c, 0xdf89)}
        </tspan>
      </text>
    </svg>
  );
}

export default IconPartyPopper;
