import { post } from "axios";
import { get } from "lodash";

const FORGE_TOKEN_BY_ENV = {
  production:
    "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjozLCJjdXJyZW50X2xvZ2luIjoiMjAxOC0wNi0yNiAwMjo1MTo0NyJ9.28KD4ZM7nBI4Pd0nb3L4TmbYTHTvoh88qdWYVpn0Qek",
  staging:
    "eyJhbGciOiJIUzI1NiJ9.eyJ1c2VyX2lkIjoyLCJjdXJyZW50X2xvZ2luIjoiMjAxOC0wNi0yNiAwMjo1MToxMSJ9.1KK8Rja4TA9gtEr14EfDAn1nptAdmv8V4_yI3iWBAGA"
};

const env = get(window, "sessionData.config.railsEnv", "staging");
const FORGE_TOKEN = FORGE_TOKEN_BY_ENV[env] || FORGE_TOKEN_BY_ENV["staging"];

async function forge(query, variables = {}) {
  return post(
    "https://graph.wr-studios.com/search",
    { query, variables },
    {
      headers: {
        Authorization: `Bearer ${FORGE_TOKEN}`
      }
    }
  ).then((res) => res.data);
}

export async function geocode(address, mlsCode) {
  const query = /* GraphQL */ `
    query AddressQuery($mlsCode: String!, $address: String!) {
      mls(key: $mlsCode) {
        address_suggestions(search: $address) {
          results {
            mlsnum
            lat
            lon
            address
            city
            state
            zipcode
          }
        }
      }
    }
  `;
  const variables = { address, mlsCode };
  const { data } = await forge(query, variables);
  return data.mls.address_suggestions.results;
}

export async function getPinsWithinBounds({
  mlsCode,
  mlsStatuses,
  bounds,
  monthsBack,
  propType,
  propSubType
}) {
  const query = /* GraphQL */ `
    query GetPinsWithinBounds($mlsCode: String!, $filters: [FilterQueryType]) {
      mls(key: $mlsCode) {
        listings(filter: $filters) {
          pins(precision: 9) {
            lat
            lon
            records {
              id
              status
            }
          }
        }
      }
    }
  `;
  const variables = {
    mlsCode,
    filters: [
      { field: "location", within: JSON.stringify([bounds]) },
      propType && { field: "prop_type", eq: [propType] },
      propSubType && { field: "prop_sub_type", eq: [propSubType] },
      {
        field: "active_group",
        filter_group: [
          {
            field: "status",
            eq: [
              ...(mlsStatuses.active || []),
              ...(mlsStatuses.pending || []),
              ...(mlsStatuses.backup || [])
            ]
          }
        ]
      },
      {
        field: "offmarket_group",
        filter_group: [
          {
            field: "status",
            eq: (mlsStatuses.closed || []).filter(
              (status) =>
                !["Cancelled", "Canceled", "Withdrawn"].includes(status)
            )
          },
          monthsBack && {
            field: "date_offmarket",
            gte: `${monthsBack} months ago`
          }
        ].filter(Boolean)
      }
    ].filter(Boolean)
  };
  const { data } = await forge(query, variables);
  const mls = data.mls || {};
  const listings = mls.listings || {};
  const pins = listings.pins || [];
  return pins.map((pin) => {
    return {
      ...pin,
      records: (pin.records || []).map((record) => ({
        ...record,
        mappedStatus: getMappedStatusFromStatus(record.status, mlsStatuses)
      }))
    };
  });
}

export async function getListingsById({ mlsCode, mlsStatuses, listingIds }) {
  const query = /* GraphQL */ `
    query GetListingsById($mlsCode: String!, $filters: [FilterQueryType]) {
      mls(key: $mlsCode) {
        listings(filter: $filters) {
          results {
            id
            mlsnum
            lat
            lon
            priceRaw: price
            price
            beds
            baths
            bathsFull: baths_full
            bathsThreeQuarter: baths_three_quarter
            bathsHalf: baths_half
            bathQuarter: baths_quarter
            changes
            sqft
            lotsize
            acres
            taxes
            photos
            garages
            dom
            street: address
            city
            state
            zip
            status
            dateList: date_list
            dateSold: date_sold
            priceListOrigin: price_list_orig
            priceList: price_list
            priceSold: price_sold
            remarks
            features
            propType: prop_type
            yearBuilt: year_built
            updatedAt: updated_at
            officeList: office_list {
              name
            }
          }
        }
      }
    }
  `;
  const variables = { mlsCode, filters: [{ field: "id", eq: listingIds }] };
  const { data } = await forge(query, variables);
  const mls = data.mls || {};
  const listings = mls.listings || {};
  const results = listings.results || [];
  return results.map((listing) => ({
    ...listing,
    mappedStatus: getMappedStatusFromStatus(listing.status, mlsStatuses)
  }));
}

function getMappedStatusFromStatus(status, statusMap) {
  const [mappedStatus] =
    Object.entries(statusMap).find(([, values]) =>
      (values || []).includes(status)
    ) || [];
  return mappedStatus;
}
