import React, {
  forwardRef,
  useState,
  useEffect,
  useLayoutEffect,
  useRef
} from "react";
import PropTypes from "prop-types";
import { useHistory, useParams } from "react-router-dom";
import FocusLock from "react-focus-lock";
import IconTimes from "../../icons/IconTimes";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerListingDetailsModal from "./HomebeatViewerListingDetailsModal";
import HomebeatViewerListingToggle from "./HomebeatViewerListingToggle";
import {
  Container,
  Header,
  Close,
  HomebeatViewerListing
} from "./styled/homebeat-viewer-listing-preview";

const HomebeatViewerListingPreview = forwardRef(
  ({ listingId, showInModal, onClose, ...props }, ref) => {
    const history = useHistory();
    const { guid } = useParams();
    const { listings, subjectProperty } = useHomebeatViewer();
    const containerRef = useRef();
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const [initialTab, setInitialTab] = useState("map");
    const [showFullscreenMedia, setShowFullscreenMedia] = useState(false);
    const [isClosing, setIsClosing] = useState(false);
    const listing = listings.find((listing) => listing.id === listingId);

    const handleClick = (e) => {
      if (showInModal) {
        if (e.target.dataset.virtualTour) {
          setInitialTab("tour");
          setShowFullscreenMedia(true);
        }
        setShowDetailsModal(true);
      } else {
        history.push(`/live/${guid}/comps/listing/${listing.id}`);
      }
    };

    useEffect(() => {
      const handler = (e) => {
        if (
          showDetailsModal ||
          e.target === containerRef.current ||
          containerRef.current.contains(e.target)
        ) {
          return;
        }
        setIsClosing(true);
      };
      window.addEventListener("click", handler);
      return () => {
        window.removeEventListener("click", handler);
      };
    }, [showDetailsModal]);

    // Hack around mounting and animating
    useEffect(() => {
      if (!isClosing) {
        setTimeout(() => {
          containerRef.current.style.transform = `translateX(0%)`;
        });
      } else {
        containerRef.current.style.transform = `translateX(-110%)`;
      }
    }, [isClosing]);

    return (
      <FocusLock returnFocus={true}>
        <Container
          {...props}
          onKeyDown={(e) => e.key === "Escape" && setIsClosing(true)}
          onTransitionEnd={(e) => isClosing && onClose(e)}
          ref={(node) => {
            containerRef.current = node;
            if (ref) ref.current = node;
          }}>
          <Header>
            <Close data-autofocus onClick={() => setIsClosing(true)}>
              <IconTimes />
            </Close>
            <HomebeatViewerListingToggle listingId={listing.id} />
          </Header>
          <HomebeatViewerListing
            subjectProperty={subjectProperty}
            listing={listing}
            onClick={handleClick}
          />
          {showDetailsModal && (
            <HomebeatViewerListingDetailsModal
              listingId={listing.id}
              initialTab={initialTab}
              showFullscreenMedia={showFullscreenMedia}
              onClose={() => {
                event.stopPropagation();
                setShowDetailsModal(false);
              }}
            />
          )}
        </Container>
      </FocusLock>
    );
  }
);

HomebeatViewerListingPreview.defaultProps = {
  onTransitionEnd: () => {},
  onClose: () => {}
};

HomebeatViewerListingPreview.propTypes = {
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  showInModal: PropTypes.bool,
  onTransitionEnd: PropTypes.func,
  onClose: PropTypes.func
};

function useListingPreviewResizer() {
  const matchMedia = useRef(window.matchMedia("(max-width: 991px)"));
  const previewRef = useRef();
  const headerRef = useRef();
  const mobileHeaderRef = useRef();
  const headerDetailsRef = useRef({ bottom: 0 });

  useLayoutEffect(() => {
    const handler = () => {
      if (!headerRef.current && !mobileHeaderRef.current) return;
      const header = headerRef.current;
      const mobileHeader = mobileHeaderRef.current;
      const currentHeader =
        window.getComputedStyle(mobileHeader).display !== "none"
          ? mobileHeader
          : header;

      const pageHeaderRect = currentHeader.getBoundingClientRect();
      const pageHeight = "100vh";
      const pageHeaderBottom = `${pageHeaderRect.bottom}px`;
      const height = matchMedia.current.matches
        ? "auto"
        : `calc(${pageHeight} - ${pageHeaderBottom})`;

      headerDetailsRef.current = { bottom: pageHeaderBottom };

      if (previewRef.current) {
        previewRef.current.style.height = height;
        previewRef.current.style.top = pageHeaderBottom;
      }
    };
    handler();
    window.addEventListener("scroll", handler);
    window.addEventListener("resize", handler);
    return () => {
      window.removeEventListener("scroll", handler);
      window.removeEventListener("resize", handler);
    };
  }, []);

  useLayoutEffect(() => {
    const mql = matchMedia.current;
    const handler = (e) => {
      matchMedia.current = e;
    };
    handler(mql);
    mql.addListener(handler);
    return () => {
      mql.removeListener(handler);
    };
  }, []);

  return {
    headerRef,
    mobileHeaderRef,
    headerDetails: headerDetailsRef.current,
    previewRef
  };
}

export { useListingPreviewResizer };
export default HomebeatViewerListingPreview;
