import React from "react";
import PropTypes from "prop-types";
import HomebeatViewerListingDetails from "./HomebeatViewerListingDetails";
import {
  Modal,
  ModalContent
} from "./styled/homebeat-viewer-listing-details-modal";

function HomebeatViewerListingDetailsModal({
  listingId,
  initialTab,
  initialSlideIndex,
  showFullscreenMedia,
  onClose
}) {
  return (
    <Modal focusLock={{ returnFocus: true }} onClose={onClose}>
      <ModalContent>
        <HomebeatViewerListingDetails
          key={listingId}
          isPreview
          listingId={listingId}
          initialTab={initialTab}
          initialSlideIndex={initialSlideIndex}
          showFullscreenMedia={showFullscreenMedia}
          onClose={onClose}
        />
      </ModalContent>
    </Modal>
  );
}

HomebeatViewerListingDetailsModal.propTypes = {
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  initialTab: PropTypes.oneOf(["map", "photos", "tour"]),
  initialSlideIndex: PropTypes.number,
  showFullscreenMedia: PropTypes.bool,
  onClose: PropTypes.func.isRequired
};

export default HomebeatViewerListingDetailsModal;
