import styled from "styled-components";
import { Container as BaseButtonStyle } from "../../common/styled/button";
import { default as BaseIconClose } from "../../icons/material/navigation/IconClose";
import { default as BaseIconLockOutline } from "../../icons/material/action/IconLockOutline";

export const IconClose = styled(BaseIconClose)`
  flex-shrink: 0;
  margin-right: 1.1rem;
  fill: ${({ theme }) => theme.colors.red};
`;

export const IconLockOutline = styled(BaseIconLockOutline)`
  flex-shrink: 0;
  margin-right: 1.1rem;
  color: ${({ theme }) => theme.colors.green};
`;

export const Title = styled.h2`
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3.2rem;
  margin-bottom: 1.2rem;
`;

export const Steps = styled.i`
  flex-shrink: 0;
  font-size: 1.3rem;
  color: ${({ theme }) => theme.colors.grayAlt};
`;

export const Progress = styled.div`
  display: flex;
  align-items: center;
  height: 0.2rem;
  margin: 1rem -0.2rem 2rem;
`;

export const ProgressStep = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 0.2rem;
  background-color: ${({ theme }) => theme.colors.grayLighter};
  position: relative;

  &:after {
    content: "";
    width: ${({ active }) => (active ? "100%" : "0")};
    height: 100%;
    transition: width 0.2s;
    background-color: ${({ theme }) => theme.colors.green};
    position: absolute;
    top: 0;
    left: 0;
  }
`;

export const Options = styled.div`
  margin: 3.1rem 2rem 0;
`;

export const Option = styled.div`
  margin-top: 1.5rem;

  &:first-of-type {
    margin-top: 0;
  }
`;

export const RadioContainer = styled.label`
  display: inline-block;
  cursor: pointer;
`;

export const RadioText = styled.span`
  display: inline-block;
  margin-left: 1rem;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  list-style: none;
`;

export const ListItem = styled.li`
  display: flex;
  line-height: 1.6;
  margin-left: 0;

  &:not(:first-child) {
    margin-top: 0.7rem;
  }
`;

export const Text = styled.p`
  line-height: 2.4rem;
  margin-top: ${({ small }) => (small ? 1 : 2)}rem;

  &:first-child,
  &:first-of-type {
    margin-top: 0;
  }

  + ${List} {
    margin-top: ${({ small }) => (small ? 1 : 2)}rem;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 2.4rem;
`;

export const FooterRight = styled.div`
  margin-left: auto;
`;

export const Cancel = styled.button`
  display: flex;
  align-items: center;
  font-weight: 600;
  line-height: 2rem;
  padding: 0.8rem 2rem 0.8rem 0;
  cursor: pointer;
  appearance: none;
  color: ${({ theme }) => theme.colors.gray};

  &:focus {
    outline: none;
    color: ${({ theme }) => theme.colors.green};
  }

  svg {
    margin-right: 0.5rem;
  }
`;

export const ButtonLink = styled(BaseButtonStyle.withComponent("a"))`
  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;
