import { sort } from "timsort";
import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return requestState(state);
}

export function resolved(state, action) {
  const { sortDirection, sortBy } = action.payload;
  const listings = [...state.all];
  const sortBys = [sortBy, "position"];

  // Sort by field (using timsort to maintain stability)
  sort(listings, (a, b) => {
    let sortIndex = 0;

    sortBys.forEach((sortByItem) => {
      if (sortIndex) return;

      let aSortBy = a.data[sortByItem] || a[sortByItem];
      let bSortBy = b.data[sortByItem] || b[sortByItem];

      if (typeof aSortBy === "number") aSortBy = Number(aSortBy);
      if (typeof bSortBy === "number") bSortBy = Number(bSortBy);

      if (sortByItem !== "position") {
        if (sortDirection === "asc") {
          if (aSortBy > bSortBy) sortIndex = 1;
          if (aSortBy < bSortBy) sortIndex = -1;
        } else if (sortDirection === "desc") {
          if (aSortBy > bSortBy) sortIndex = -1;
          if (aSortBy < bSortBy) sortIndex = 1;
        }
      } else {
        if (sortDirection === "asc") {
          sortIndex = aSortBy - bSortBy;
        } else if (sortDirection === "desc") {
          sortIndex = bSortBy - aSortBy;
        }
      }
    });

    return sortIndex;
  });

  listings.forEach((listing, index) => (listing.sortPosition = index));

  return {
    ...resolvedState({ ...state, all: listings, sortDirection, sortBy })
  };
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
