import React from "react";
import PropTypes from "prop-types";
import { Option, Colors, Color } from "./styled/select";

function SelectDropdownOption({ option, autoFocus, getItemProps }) {
  return (
    <Option {...getItemProps({ item: option })} autoFocus={autoFocus}>
      {option.colors && !!option.colors.length && (
        <Colors>
          {option.colors.map((color, index) => (
            <Color
              key={index}
              className="select-color"
              style={{ backgroundColor: `#${color}` }}
            />
          ))}
        </Colors>
      )}
      {option.label}
    </Option>
  );
}

SelectDropdownOption.propTypes = {
  option: PropTypes.shape({
    colors: PropTypes.arrayOf(PropTypes.string)
  }),
  autoFocus: PropTypes.bool,
  getItemProps: PropTypes.func
};

export default SelectDropdownOption;
