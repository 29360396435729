import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  width: 100%;
  height: 6rem;
  padding: 1.4rem 2.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.sticky};
  position: sticky;
  top: 0;
  z-index: 1000;
`;

export const Cell = styled.div`
  display: flex;
  width: calc(100% / 3);

  ${({ theme }) => theme.media.sm`
    width: 50%;
  `}

  &:nth-child(2) {
    justify-content: center;

    ${({ theme }) => theme.media.sm`
      display: none;
    `}
  }

  &:nth-child(3) {
    justify-content: flex-end;
  }
`;

export const Header = styled.div`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 2.9rem;
  `}
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
`;

export const Skip = styled.button`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin-right: 1.6rem;
  padding: 0;
  appearance: none;
  cursor: pointer;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;
