import { connect } from "react-redux";
import CustomizeControlsFont from "../CustomizeControlsFont";

function select({ customize: { font } }) {
  return {
    font
  };
}

const actions = {};

export default connect(select, actions)(CustomizeControlsFont);
