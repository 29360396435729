import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateCover } from "../../actions/customize";
import { Container, Badge, Image } from "./styled/customize-modal-cover-image";

function CustomizeModalCoverImage({
  reportId,
  cover,
  selectedCover,
  offer,
  onOfferClick,
  onClick,
  updateCover
}) {
  const isActive = cover.key === selectedCover.key;

  return (
    <Container
      isActive={isActive}
      autoFocus={isActive}
      onClick={() => {
        if (offer) {
          onOfferClick(cover);
        } else {
          updateCover(reportId, cover);
          onClick();
        }
      }}>
      {isActive && <Badge>Currently Selected</Badge>}
      <Image src={cover.image} alt={cover.title} />
    </Container>
  );
}

CustomizeModalCoverImage.propTypes = {
  reportId: PropTypes.number,
  selectedCover: PropTypes.shape({
    key: PropTypes.string
  }),
  cover: PropTypes.shape({
    title: PropTypes.string,
    image: PropTypes.string
  }),
  offer: PropTypes.bool,
  updateCover: PropTypes.func,
  onClick: PropTypes.func
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  selectedCover: state.customize.cover.selected
});

const mapDispatchToProps = {
  updateCover
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalCoverImage);
