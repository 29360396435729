import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, illustrations: requestState(state.illustrations) };
}

export function resolved(state, action) {
  return {
    ...state,
    illustrations: resolvedState({
      ...state.illustrations,
      active: action.payload.includeIllustrations
    })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    illustrations: rejectedState(state.illustrations, action.payload.error)
  };
}
