import styled from "styled-components";
import { default as BaseTip } from "../../common/Tip";

export const Tip = styled(BaseTip)`
  display: block;
  text-align: center;
`;

export const Item = styled.div`
  display: flex;
  align-items: center;
  padding: 0.5rem 1rem;
  border-top: ${({ theme, isDragging }) =>
    !isDragging && `0.1rem solid ${theme.styleGuide.colors.gray[500]}`};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ isDragging }) =>
    isDragging && "0 0.2rem 2rem 0 rgba(0, 0, 0, 0.15)"};
  border-radius: ${({ isDragging }) => isDragging && "0.2rem"};

  &:first-child {
    border-top: 0;
  }
`;

export const Key = styled.span`
  flex-shrink: 0;
  width: 10rem;
  text-align: right;
  white-space: nowrap;
`;

export const Value = styled.strong`
  flex-grow: 1;
  padding: 0 1rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const Remove = styled.button`
  flex-shrink: 0;
  padding: 0;
  appearance: none;
  cursor: pointer;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  }
`;
