import React from "react";

// This is slightly different from the arrow found at components/icons/material/navigation/IconArrowBack
function IconArrowBack({ ...props }) {
  return (
    <svg width="19" height="13" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g
        stroke="currentColor"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="square">
        <path d="M6.583.417L.5 6.5M6.5 12.5l-6-6M1.5 6.5h16.031" />
      </g>
    </svg>
  );
}

export default IconArrowBack;
