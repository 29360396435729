import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  width: 100%;
  margin: 2.4rem 0;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  border-radius: 1.2rem;

  ${({ theme }) => theme.media.lg`
    flex-direction: column;
  `}
`;

export const Heading = styled.h2`
  font-size: 2rem;
  font-weight: 600;
  line-height: 2.6rem;
  margin-bottom: 1.8rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `}
`;

export const CardContainer = styled.div`
  padding: 3.4rem;
  border-right: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};

  ${({ theme }) => theme.media.lg`
    border-right: 0;
    border-bottom: 0.1rem solid ${({ theme }) =>
      theme.styleGuide.colors.gray[500]};
  `}
`;

export const CardInner = styled.div`
  width: 37.6rem;

  ${({ theme }) => theme.media.lg`
    width: 100%;
  `}
`;

export const ContentContainer = styled.div`
  flex-grow: 1;
`;

export const ShareContainer = styled.div`
  margin: 1.8rem auto;
`;

export const ShareContentContainer = styled.div`
  width: 37.6rem;
  padding: 3.4rem;

  ${({ theme }) => theme.media.lg`
    width: 100%;
  `}
`;

export const BasicCardContainer = styled.div`
  margin-bottom: 1.8rem;
`;

export const Back = styled.a`
  display: inline-flex;
  align-items: center;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  text-decoration: none;
  color: ${({ theme }) => theme.styleGuide.colors.cma[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1.2rem;
    fill: currentColor;
  }
`;

export const PageHeader = styled.div`
  margin-bottom: 3rem;
`;
