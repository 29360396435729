import React from "react";
import Helmet from "react-helmet";
import UserCustomPagesAdd from "../user/UserCustomPagesAdd";
import UserCustomPages from "../user/UserCustomPages";
import UserCustomPDFPages from "../user/UserCustomPDFPages";
import { Section, Header, Text } from "./styled/settings-custom-pages";

function SettingsCustomPages() {
  return (
    <>
      <Helmet>
        <title>Custom Pages - Account Settings - Cloud CMA</title>
      </Helmet>
      <Section>
        <Header>Custom Pages</Header>
        <Text>
          Edit, update, and delete your custom pages and custom pdf pages.
        </Text>
      </Section>
      <Section>
        <Header>Add New Custom Page</Header>
        <UserCustomPagesAdd />
      </Section>
      <Section>
        <Header>Custom Pages List</Header>
        <UserCustomPages />
      </Section>
      <Section>
        <Header>Custom PDF Pages</Header>
        <UserCustomPDFPages />
      </Section>
    </>
  );
}

export default SettingsCustomPages;
