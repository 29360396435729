import styled from "styled-components";
import { default as BaseButton } from "../../common/Button";
import { Container as BaseButtonStyle } from "../../common/styled/button";

export const Title = styled.h2`
  font-size: 2.8rem;
  font-weight: 600;
  line-height: 3.2rem;
  margin-bottom: 2.5rem;

  ${({ theme }) => theme.media.sm`
    text-align:center;
  `}
`;

export const Text = styled.p`
  font-size: 1.4rem;
  line-height: 2.4rem;
  margin-bottom: ${({ small }) => (small ? 1 : 2.4)}rem;

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.green};
  }
`;

export const Investor = styled.strong`
  font-weight: 600;
`;

export const Button = styled(BaseButton)`
  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;

export const ButtonLink = styled(BaseButtonStyle.withComponent("a"))`
  margin-right: ${({ hasMoreLeads }) => hasMoreLeads && "1.6rem"};

  ${({ theme }) => theme.media.sm`
    width: 100%;
    margin-right: 0;
    margin-bottom: ${({ hasMoreLeads }) => hasMoreLeads && "1.6rem"};
  `}
`;

export const Actions = styled.div`
  margin-top: 2.4rem;
  text-align: center;
`;

export const Learn = styled.p`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1.9rem;
  margin-top: 2.4rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.gray};

  svg {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    margin-top: -0.2rem;
    fill: ${({ theme }) => theme.colors.gray};
  }
`;

export const LearnLink = styled.a`
  text-decoration: none;
  color: ${({ theme }) => theme.colors.green};
`;

export const Description = styled.div`
  padding: 2.8rem 3.4rem 3.4rem;
  background-color: ${({ theme }) => theme.colors.grayLightest};

  ${({ theme }) => theme.media.sm`
    padding: 2rem;
  `}

  svg {
    width: 7.4rem;
    height: 9.6rem;
  }
`;

export const DescriptionGrid = styled.div`
  display: flex;
  margin: -1.6rem;
`;

export const DescriptionCell = styled.div`
  padding: 1.6rem;

  &:first-child {
    ${({ theme }) => theme.media.sm`
      display: none;
    `}
  }
`;

export const DescriptionTitle = styled.div`
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 3rem;
  margin-top: 0.6rem;
  color: ${({ theme }) => theme.colors.grayDark};

  ${({ theme }) => theme.media.sm`
    margin-top: 0;
  `}
`;

export const DescriptionText = styled.div`
  font-size: 1.4rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.grayDark};
`;
