import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, layout: requestState(state.layout) };
}

export function resolved(state, action) {
  return {
    ...state,
    layout: resolvedState({
      ...state.layout,
      selected: { ...action.payload.layout }
    })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    layout: rejectedState(state.layout, action.payload.error)
  };
}
