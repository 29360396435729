import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ActionLink from "../form/ActionLink";

function BreadcrumbsAction({ url, text, textLoading, form }) {
  return (
    <div className="sm:-mt-6">
      <ActionLink
        href={url}
        className="button button-cma"
        loadingClassName="button button-loading"
        loadingText={textLoading}
        text={text}
        form={form}
      />
    </div>
  );
}

BreadcrumbsAction.propTypes = {
  text: PropTypes.string.isRequired,
  textLoading: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  form: PropTypes.string
};

BreadcrumbsAction.defaultProps = {
  form: "#form"
};

const mapStateToProps = (state) => ({
  loading: state.page.loading
});

export default connect(mapStateToProps)(BreadcrumbsAction);
