import React from "react";

function IconArrowLeft() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="8"
      height="14"
      viewBox="0 0 8 14">
      <g fill="none" fillRule="evenodd" stroke="#FFF" strokeLinecap="round">
        <path d="M7.5 0L.76 6.76M7.5 13.5L.76 6.74" />
      </g>
    </svg>
  );
}

export default IconArrowLeft;
