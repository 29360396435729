import React, { useState } from "react";
import { stringify } from "qs";
import { get } from "lodash";
import { useSession } from "../../Session";
import TransactionWizard from "../transaction/TransactionWizard";
import IconTransaction from "../icons/IconTransaction";
import IconAlerts from "../icons/IconAlerts";
import IconHomebeat from "../icons/IconHomebeat";
import IconFlyer from "../icons/IconFlyer";
import IconCashOffer from "../icons/IconCashOffer";

import {
  Container,
  CardGrid,
  Subheader,
  CardAction,
  CardActionIcon,
  Link
} from "./styled/publish-actions";
import { useQueryString } from "../../utils/url";

function PublishActions({
  report,
  streams,
  transaction,
  cashOffer,
  localShowCashOffer,
  setTransaction,
  setLocalShowCashOffer
}) {
  const lead = cashOffer.leads[0] || { investor: {} };
  const investorToken = lead.investor.token;
  const isDisqualifiedForCashOffer =
    !investorToken ||
    investorToken === "disqualified" ||
    cashOffer.isEligible === false;
  const session = useSession();
  const {
    currentUser,
    features: { hasIBuyerConnect, hasTransactions, hasHomebeat, hasFlyers }
  } = session;

  const { params } = useQueryString();
  const [isTransactionWizardOpen, setIsTransactionWizardOpen] = useState(
    params.td_create_report_id || false
  );

  const showStreamsAlert = Boolean(streams.available && streams.subscribed);
  const transactionUrl =
    transaction && transaction.id
      ? `/transactions/sso_link/${transaction.id}`
      : "";

  const transactionType = get(transaction, "type");
  const isTransactionAuthed =
    (transactionType === "ZipForm" && currentUser.zipFormToken) ||
    (transactionType === "TransactionDesk" && currentUser.transactionDeskToken);

  return (
    <Container>
      <Subheader>Other things you can do with this property</Subheader>
      <CardGrid>
        {/* Transaction */}
        {hasTransactions && transactionUrl && isTransactionAuthed && (
          <Link href={transactionUrl} target="_self">
            <CardAction>
              <CardActionIcon>
                <IconTransaction />
              </CardActionIcon>
              View Transaction
            </CardAction>
          </Link>
        )}

        {hasTransactions && transactionUrl && !isTransactionAuthed && (
          <CardAction onClick={() => setIsTransactionWizardOpen(true)}>
            <CardActionIcon>
              <IconTransaction />
            </CardActionIcon>
            View Transaction
          </CardAction>
        )}

        {hasTransactions && !transactionUrl && (
          <CardAction onClick={() => setIsTransactionWizardOpen(true)}>
            <CardActionIcon>
              <IconTransaction />
            </CardActionIcon>
            Create Transaction
          </CardAction>
        )}

        {/* Streams */}
        {showStreamsAlert && (
          <CardAction
            onClick={() => {
              if (typeof window.ga === "function") {
                window.ga(
                  "cloudAgentSuiteTracker.send",
                  "event",
                  "Cross Product",
                  "Login"
                );
              }
              window.open(streams.product_link, "_blank");
            }}>
            <CardActionIcon>
              <IconAlerts />
            </CardActionIcon>
            Set up Alerts
          </CardAction>
        )}

        {/* Homebeat */}
        {hasHomebeat && (
          <Link
            href={`/homebeats/new?${stringify({
              client: report.title,
              address: [
                report.report_address_parts[0],
                report.report_address_parts[1]
              ].join(", "),
              beds: report.subject_property.beds,
              baths: report.subject_property.baths,
              sqft: report.subject_property.sqft,
              lot_size: report.subject_property.lotsize,
              garages: report.subject_property.garages,
              prop_type: report.prop_type,
              prop_sub_type: report.prop_sub_type
            })}`}>
            <CardAction>
              <CardActionIcon>
                <IconHomebeat />
              </CardActionIcon>
              Create Homebeat
            </CardAction>
          </Link>
        )}

        {/* Cash Offer */}
        {hasIBuyerConnect &&
          !isDisqualifiedForCashOffer &&
          !localShowCashOffer && (
            <CardAction onClick={() => setLocalShowCashOffer(true)}>
              <CardActionIcon>
                <IconCashOffer />
              </CardActionIcon>
              Get Cash Offer
            </CardAction>
          )}

        {/* Flyers */}
        {hasFlyers && (
          <Link href="/flyers/new">
            <CardAction href="/flyers/new">
              <CardActionIcon>
                <IconFlyer />
              </CardActionIcon>
              Make Flyer
            </CardAction>
          </Link>
        )}
      </CardGrid>

      {isTransactionWizardOpen && (
        <TransactionWizard
          report={report}
          transaction={transaction}
          setTransaction={setTransaction}
          onClose={() => setIsTransactionWizardOpen(false)}
        />
      )}
    </Container>
  );
}

export default PublishActions;
