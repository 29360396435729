import styled from "styled-components";
import { default as BaseInput } from "../../../common/Input";

export const Grid = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: -0.8rem -1.2rem;
`;

export const Cell = styled.div`
  width: ${({ columns = 1, totalColumns = 12 }) =>
    (columns / totalColumns) * 100}%;
  flex-grow: ${({ grow }) => (grow ? 1 : 0)};
  padding: 0.8rem 1.2rem;

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;

export const Input = styled(BaseInput)`
  color: ${({ theme, hasTaxData }) =>
    hasTaxData && theme.styleGuide.colors.purple[500]};
`;

export const EditorContainer = styled.div`
  height: 18.8rem;

  .placeholder {
    color: ${({ theme }) => theme.styleGuide.colors.purple[500]};
  }
`;

export const Italic = styled.i`
  display: inline-block;
  font-size: 1.3rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[600]};
`;
