import React, { useState } from "react";
import {
  sendInvestorEvent,
  submitInvestorOffer,
  submitInvestorQuestionnaire
} from "../../api/report";
import Button from "../common/Button";
import Loading from "../common/Loading";
import Radio from "../common/Radio";
import IconArrowBack from "../icons/IconArrowBack";
import {
  Cancel,
  Footer,
  FooterRight,
  IconClose,
  IconLockOutline,
  List,
  ListItem,
  Option,
  Options,
  Progress,
  ProgressStep,
  RadioContainer,
  RadioText,
  Steps,
  Text,
  Title
} from "./styled/investor-generic-questionnaire";

const steps = {
  INTRO: 0,
  QUALIFIED: 1
};

function InvestorGenericQuestionnaire({
  report,
  lead,
  onReset,
  onQualified,
  onDisqualified
}) {
  const [step, setStep] = useState(steps.INTRO);

  if (step === steps.INTRO)
    return (
      <InvestorGenericQuestionnaireQuestions
        report={report}
        lead={lead}
        onReset={onReset}
        onQualified={() => setStep(steps.QUALIFIED)}
        onDisqualified={onDisqualified}
      />
    );
  if (step === steps.QUALIFIED)
    return (
      <InvestorGenericQuestionnaireQualified
        report={report}
        lead={lead}
        onQualified={onQualified}
        onDisqualified={onDisqualified}
      />
    );

  return null;
}

export function InvestorGenericQuestionnaireQuestions({
  report,
  lead,
  onReset,
  onQualified,
  onDisqualified
}) {
  const [network, setNetwork] = useState({
    isFetching: false,
    hasFetched: false,
    hasError: false
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [questions, setQuestions] = useState(lead.survey_questions || []);
  const question = questions[currentStep];
  const totalQuestions = questions.length;

  const handleAnswerQuestion = (question, answer) => {
    const newQuestions = questions.map((surveyQuestion) => {
      if (question === surveyQuestion.question) surveyQuestion.answer = answer;
      return surveyQuestion;
    });
    setQuestions(newQuestions);
  };

  const handleNext = async () => {
    const newCurrentStep = currentStep + 1;

    if (currentStep === totalQuestions - 1) {
      try {
        setNetwork({ isFetching: true, hasFetched: false, hasError: false });
        await submitInvestorQuestionnaire(
          report.id,
          lead.id,
          questions,
          `q${newCurrentStep}`
        );
        setNetwork({ isFetching: false, hasFetched: true, hasError: false });
        onQualified();
      } catch (error) {
        setNetwork({ isFetching: false, hasFetched: false, hasError: true });
        onDisqualified();
      }
      return;
    }

    sendInvestorEvent(report.id, lead.id, `q${newCurrentStep}`);
    setCurrentStep(newCurrentStep);
  };

  const handlePrev = () => {
    if (currentStep - 1 < 0) return onReset();
    setCurrentStep(currentStep - 1);
  };

  return (
    <>
      <Steps>
        Question {currentStep + 1} of {totalQuestions}
      </Steps>
      <Progress>
        {new Array(totalQuestions).fill().map((_, index) => (
          <ProgressStep key={index} active={index <= currentStep} />
        ))}
      </Progress>
      {question && (
        <>
          <div>{question.question}</div>
          <Options>
            {question.options.map((option, index) => (
              <Option key={index}>
                <RadioContainer>
                  <Radio
                    name={question.question}
                    value={option}
                    checked={question.answer === option}
                    onChange={(e) =>
                      handleAnswerQuestion(question.question, e.target.value)
                    }
                  />
                  <RadioText>{option}</RadioText>
                </RadioContainer>
              </Option>
            ))}
          </Options>
        </>
      )}
      <Footer>
        <Cancel app="cma" variant="secondary" onClick={handlePrev}>
          <IconArrowBack />
          Back
        </Cancel>
        <FooterRight>
          {currentStep < totalQuestions - 1 && (
            <Button app="cma" onClick={handleNext} disabled={!question.answer}>
              Next
            </Button>
          )}
          {currentStep === totalQuestions - 1 && (
            <Button
              app="cma"
              onClick={handleNext}
              disabled={network.isFetching || !question.answer}>
              {network.isFetching && <Loading>Checking</Loading>}
              {!network.isFetching && !network.hasFetched && "Next"}
            </Button>
          )}
        </FooterRight>
      </Footer>
    </>
  );
}

export function InvestorGenericQuestionnaireQualified({
  report,
  lead,
  onQualified,
  onDisqualified
}) {
  const [network, setNetwork] = useState({
    isFetching: false,
    hasFetched: false,
    hasError: false
  });

  const handleClick = async () => {
    setNetwork({ isFetching: true, hasFetched: false, hasError: false });
    try {
      await submitInvestorOffer(report.id, lead.id);
      onQualified();
    } catch (error) {
      onDisqualified();
    }
  };

  return (
    <>
      <Title>We found a potential buyer.</Title>
      <Text>
        You're about to securely submit this property for a private cash offer.
      </Text>
      <Text>
        <strong>The following will be sent to a third-party iBuyer:</strong>
      </Text>
      <List>
        <ListItem>
          <IconLockOutline width="24" height="24" viewBox="-4 -2 24 24" />
          Property address and comparables
        </ListItem>
        <ListItem>
          <IconLockOutline width="24" height="24" viewBox="-4 -2 24 24" />
          Details you've provided about the property's condition
        </ListItem>
      </List>
      <Text>
        <strong>The iBuyer will not:</strong>
      </Text>
      <List>
        <ListItem>
          <IconClose width="24" height="24" />
          See your private notes or other account information
        </ListItem>
        <ListItem>
          <IconClose width="24" height="24" />
          Contact the seller directly under any circumstance
        </ListItem>
      </List>
      <Footer>
        <FooterRight>
          <Button app="cma" disabled={network.isFetching} onClick={handleClick}>
            {!network.isFetching && "Send Request"}
            {network.isFetching && <Loading>Sending Request</Loading>}
          </Button>
        </FooterRight>
      </Footer>
    </>
  );
}

export default InvestorGenericQuestionnaire;
