export function request(state) {
  return { ...state, isResetting: true };
}

export function resolved(state, action) {
  return { ...state, isResetting: false, ...action.payload.customize };
}

export function rejected(state) {
  return { ...state, isResetting: false };
}
