import styled from "styled-components";

export const Container = styled.label`
  display: inline-flex;
  align-items: center;
  cursor: ${({ isLoading }) => (isLoading ? "default" : "pointer")};
`;

export const Label = styled.span`
  display: inline-block;
  margin-right: 0.8rem;
`;

export const Control = styled.span`
  display: inline-block;
`;
