import React from "react";
import PropTypes from "prop-types";
import { pluralize, currency, formatNumber } from "@wrstudios/utils";
import {
  Container,
  Status,
  Price,
  PricePerSqft,
  Dom
} from "./styled/comparable-price";

function ComparablePrice({ price, pricePerSqft, status, statusColor, dom }) {
  return (
    <Container>
      <Status style={{ color: statusColor }}>{status || "N/A"}</Status>
      <Price>
        {price ? currency(price) : "N/A"}{" "}
        {pricePerSqft && (
          <PricePerSqft>
            {pricePerSqft ? currency(pricePerSqft.toFixed(0)) : "N/A"} sq/ft
          </PricePerSqft>
        )}
      </Price>
      <Dom>
        {dom ? formatNumber(dom) : "N/A"} {pluralize("Days", dom)} on market
      </Dom>
    </Container>
  );
}

ComparablePrice.propTypes = {
  price: PropTypes.number,
  pricePerSqft: PropTypes.number,
  status: PropTypes.string,
  statusColor: PropTypes.string,
  dom: PropTypes.number
};

export default ComparablePrice;
