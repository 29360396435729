import {
  ApplicationSwitcher,
  Navbar,
  NavbarAdditionalItems,
  NavbarHelpLink,
  NavbarNavigationBar,
  NavbarUserMenu
} from "@helix/navbar";
import React, { useEffect, useState } from "react";
import { useSession } from "../../Session";
import { getCasDomain, getNewCmaUrl } from "../../utils/url";
import { Container } from "./styled/navigation";

export default function LegacyNavigation() {
  const { currentUser, features, config } = useSession();
  const { data: appSwitcher } = useAppSwitcher();

  return (
    <Container id="navigation">
      <Navbar
        dataLwtId="default"
        applicationName={!appSwitcher?.enabled ? "Cloud CMA" : undefined}>
        {appSwitcher?.enabled && (
          <ApplicationSwitcher
            applicationName="Cloud CMA"
            appSections={[
              {
                sectionName: "Promote",
                sectionItems: appSwitcher.products.map((product) => ({
                  selected: product.key === "cloud_cma",
                  icon:
                    product.key === "cloud_cma"
                      ? "compare"
                      : product.key === "cloud_streams"
                      ? "alert"
                      : product.key === "cloud_mlx"
                      ? "search"
                      : product.key === "cloud_attract"
                      ? "agent"
                      : product.key === "launchpad"
                      ? "cards"
                      : undefined,
                  label: product.name || "N/A",
                  description: product.tagline || "",
                  onClick:
                    product.key !== "cloud_cma"
                      ? () => product.url && window.open(product.url, "_self")
                      : undefined
                }))
              }
            ]}
          />
        )}
        <NavbarNavigationBar
          navItems={[
            { label: "CMA", url: "/cmas" },
            { label: "Buyer Tour", url: "/tours" },
            { label: "Property", url: "/properties" },
            features.hasFlyers ? { label: "Flyer", url: "/flyers" } : undefined,
            features.hasDocuments
              ? { label: "Documents", url: "/documents" }
              : undefined,
            features.hasHomebeat || features.offerHomebeat
              ? { label: "Homebeat", url: "/homebeats" }
              : undefined
          ].filter(Boolean)}
          linkWrapper={({ item }) => (
            <a
              href={item.url}
              data-turbolinks={item.url === "/homebeats" ? "false" : "true"}>
              {item.label}
            </a>
          )}
        />
        <NavbarAdditionalItems>
          <NavbarHelpLink
            url="https://lonewolf.my.site.com/s/cloud-cma-resources"
            linkWrapper={({ url, label }) => (
              <a href={url} target="_blank" rel="noreferrer">
                {label}
              </a>
            )}
          />
          <NavbarUserMenu
            id="avatar"
            user={{
              name: currentUser?.name,
              email: currentUser?.email,
              avatarURL: currentUser?.avatarUrl
            }}
            menuItems={[
              { label: "Account Settings", url: "/settings" },
              currentUser.role >= 99990
                ? {
                    label: "Brokers",
                    url: `${getNewCmaUrl()}/admin/brokers?jwt=${
                      currentUser.casJwt
                    }`
                  }
                : features.hasBrokerAccess
                ? {
                    label: "Dashboard",
                    url: `${getNewCmaUrl()}/dashboard?jwt=${currentUser.casJwt}`
                  }
                : (currentUser.isAdmin || currentUser.role >= 9990) &&
                  currentUser.isSmallBroker
                ? {
                    label: "Dashboard",
                    url: `/admin/account/${currentUser.accountId}`
                  }
                : {},
              { divider: true },
              { label: "Custom Pages", url: "/settings/custom-pages" },
              features.hasAPIButton
                ? { label: "Lead Generation", url: "/settings/lead-generation" }
                : {},
              { divider: true },
              {
                label: "Learning Resources",
                url: "https://www.lwolf.com/services/training/cloud-agent-suite"
              },
              {
                label: "Support Center",
                url: "https://lonewolf.my.site.com/s/cloud-cma-resources"
              },
              {
                label: "Launchpad",
                url: `${getCasDomain()}/app`
              },
              { divider: true },
              {
                label: currentUser.loggedInAs
                  ? "Back to your account"
                  : "Sign Out",
                url: config.logoutUrl
              }
            ].filter((item) => !!Object.keys(item).length)}
            linkWrapper={({ item }) => {
              if (item.url === config.logoutUrl || item.label === "Launchpad") {
                return (
                  <a href={item.url} data-turbolinks="false">
                    {item.label}
                  </a>
                );
              }

              const isExternal = [
                "Learning Resources",
                "Support Center"
              ].includes(item.label);

              if (isExternal) {
                return (
                  <a href={item.url} target="_blank" rel="noreferrer">
                    {item.label}
                  </a>
                );
              }

              return <a href={item.url}>{item.label}</a>;
            }}
          />
        </NavbarAdditionalItems>
      </Navbar>
    </Container>
  );
}

function useAppSwitcher() {
  const { currentUser, mls } = useSession();
  const [data, setData] = useState();

  useEffect(() => {
    if (currentUser.isAuthSSO) {
      setData({ enabled: false, products: [] });
      return;
    }

    fetch(`${getCasDomain()}/graphql/protected`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${currentUser.casJwt}`,
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        operationName: "ProductAvailability",
        query: /* GraphQL */ `
          mutation ProductAvailability($user: ExternalUserInputType!) {
            userProductAvailability(external_user: $user) {
              enabled: enable_app_switcher
              products {
                key
                name
                tagline
                url: switch_route
              }
            }
          }
        `,
        variables: {
          user: {
            application_key: "cloud_cma",
            id: currentUser.id,
            email: currentUser.email,
            cas_user_id: currentUser.casUserId,
            mls_code: mls.code
          }
        }
      })
    })
      .then((res) => res.json())
      .then((res) => {
        const productsWithLaunchpad = res.data.userProductAvailability.products;
        const enableLaunchpad = !!currentUser?.casUserId;

        if (enableLaunchpad) {
          productsWithLaunchpad.push({
            key: "launchpad",
            name: "Launchpad",
            tagline: "Learn and manage billing",
            url: `${getCasDomain()}/launchpad`
          });
        }

        setData({
          enabled: res.data.userProductAvailability.enabled,
          products: productsWithLaunchpad.map((product) => ({
            ...product,
            url: `${product.url}?jwt=${currentUser.casJwt}`
          }))
        });
      })
      .catch(() => {
        setData({ enabled: false, products: [] });
      });
  }, [currentUser]);

  return { data };
}
