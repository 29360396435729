import styled from "styled-components";

export const Container = styled.div`
  padding: ${({ noPad }) => (noPad ? 0 : "1.2rem 1.6rem")};
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  &:first-child {
    border-top: 0;
  }
`;

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
  margin: -0.5rem;
`;

export const Cell = styled.div`
  padding: 0.5rem;
`;

export const Status = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Dom = styled.span`
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.6rem;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Price = styled.h2`
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.6rem;
  margin: 1.2rem 0 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  @media (max-width: 1000px) {
    margin-top: 0.5rem;
  }

  ${({ theme }) => theme.media.sm`
    font-size: 2rem;
    line-height: 2.4rem;
  `}
`;

export const PricePerSqft = styled.span`
  display: inline-block;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Detail = styled.div`
  display: inline-block;
  margin-left: 1.6rem;

  &:first-child {
    margin-left: 0;
  }
`;

export const DetailValue = styled.strong`
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const DetailName = styled.span`
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.7rem;
    line-height: 2.4rem;
  `}
`;

export const Date = styled.span`
  display: inline-block;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-left: 1.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}

  &:first-child {
    margin-left: 0;
  }
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RowName = styled.span`
  font-size: 1.4rem;
  font-weight: ${({ isTotal }) => isTotal && 700};
  line-height: 2rem;
  color: ${({ theme, isTotal }) =>
    isTotal
      ? theme.styleGuide.colors.gray[900]
      : theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const RowValue = styled.strong`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  padding-right: ${({ isTotal, isReportOwnedByCurrentAgent }) =>
    isTotal && isReportOwnedByCurrentAgent && "2.8rem"};
  color: ${({ theme, isDim }) =>
    isDim
      ? theme.styleGuide.colors.gray[700]
      : theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const PriceDifference = styled.strong`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;    
  `}
`;

export const Difference = styled.strong`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${({ theme, value }) =>
    value > 0
      ? theme.styleGuide.colors.red[600]
      : theme.styleGuide.colors.green[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;    
  `}
`;

export const Disclaimer = styled.div`
  margin-top: 1.6rem;
  padding: 0 1.6rem;

  .mlsnum {
    color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const IncludeValuationContainer = styled.div`
  display: none;

  @media (max-width: 1000px) {
    display: block;
  }
`;

export const GetValuationContainer = styled(Container)`
  display: none;

  @media (max-width: 1000px) {
    display: block;
  }
`;
