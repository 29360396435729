import React from "react";

function IconError() {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.61548 2.57349C9.38013 1.80884 10.6199 1.80884 11.3845 2.57349L17.4265 8.61548C18.1912 9.38013 18.1912 10.6199 17.4265 11.3845L11.3845 17.4265C10.6199 18.1912 9.38013 18.1912 8.61548 17.4265L2.57349 11.3845C1.80884 10.6199 1.80884 9.38013 2.57349 8.61548L8.61548 2.57349Z"
        fill="currentColor"
      />

      <path
        d="M10.7302 11.2244C10.7054 11.6055 10.3891 11.902 10.0072 11.902C9.62529 11.902 9.30896 11.6055 9.28422 11.2244L8.94932 6.06477C8.91189 5.48822 9.36945 5 9.94722 5H10.0672C10.645 5 11.1025 5.48821 11.0651 6.06477L10.7302 11.2244ZM8.9082 13.932C8.9082 13.624 9.01554 13.3627 9.2302 13.148C9.44487 12.924 9.70154 12.812 10.0002 12.812C10.3082 12.812 10.5695 12.924 10.7842 13.148C11.0082 13.3627 11.1202 13.624 11.1202 13.932C11.1202 14.24 11.0082 14.5013 10.7842 14.716C10.5695 14.9307 10.3082 15.038 10.0002 15.038C9.70154 15.038 9.44487 14.9307 9.2302 14.716C9.01554 14.5013 8.9082 14.24 8.9082 13.932Z"
        fill="white"
      />
    </svg>
  );
}

export default IconError;
