import { requestState, rejectedState } from "../../../utils/redux";

export function request(state, action) {
  const { id } = action.payload;
  const net_sheet_items = [...state.net_sheet_items].map((item) => {
    if (item.id === id) {
      item = requestState(item, action.payload.error);
    }

    return item;
  });

  return { ...state, net_sheet_items, filter: "net-sheet" };
}

export function resolved(state, action) {
  const { net_sheet_items, offer_copy } = action.payload;

  return { ...state, net_sheet_items, offer_copy, filter: "" };
}

export function rejected(state, action) {
  const { id } = action.payload;
  const net_sheet_items = [...state.net_sheet_items].map((item) => {
    if (item.id === id) {
      item = rejectedState(item, action.payload.error);
    }

    return item;
  });

  return { ...state, net_sheet_items, filter: "" };
}
