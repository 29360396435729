import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return requestState({ ...state, filter: "net-sheet" });
}

export function resolved(state, action) {
  const { net_sheet_items, offer_copy } = action.payload;

  return resolvedState({ ...state, net_sheet_items, offer_copy, filter: "" });
}

export function rejected(state, action) {
  return rejectedState({ ...state, filter: "" }, action.payload.error);
}
