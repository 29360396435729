import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { IconDropdown } from "@wrstudios/icons";
import {
  Wrapper,
  Overflow,
  Button,
  Value,
  Icon,
  Colors,
  Color
} from "./styled/select";

/**
 * Grabs the longest word based on letter count.
 *
 * TODO: See about measuring length with canvas instead.
 *
 * @param {array} words
 */
const getLongestWordFromLetters = (words) => {
  let longestWord = "";

  words.forEach((option) => {
    if (String(option).length > String(longestWord).length) {
      longestWord = option;
    }
  });

  return longestWord;
};

function SelectDropdownButton({
  id,
  options,
  selected,
  placeholder,
  disabled,
  autoFocus,
  toggleMenu
}) {
  let fakeOptions = [...options];

  if (placeholder.length) {
    fakeOptions = [...fakeOptions, { label: placeholder }];
  }

  let longestLetterWord = getLongestWordFromLetters(
    fakeOptions.map((option) => option.label)
  );

  return (
    <Fragment>
      {/* This button helps with proper widths of the dropdown */}
      <Overflow>
        <Wrapper>
          <Button tabIndex="-1" disabled={disabled}>
            <Value>{longestLetterWord}</Value>
            <Icon>
              <IconDropdown />
            </Icon>
          </Button>
        </Wrapper>
      </Overflow>
      <Wrapper>
        <Button
          id={id}
          autoFocus={autoFocus}
          onClick={toggleMenu}
          disabled={disabled}
          title={selected.label || placeholder}>
          {selected.colors && !!selected.colors.length && (
            <Colors className="select-colors">
              {selected.colors.map((color, index) => (
                <Color
                  key={index}
                  className="select-color"
                  style={{ backgroundColor: `#${color}` }}
                />
              ))}
            </Colors>
          )}
          <Value isPlaceholder={Boolean(!selected.label && placeholder)}>
            {selected.value && selected.label
              ? selected.label || placeholder
              : placeholder}
          </Value>
          <Icon>
            <IconDropdown />
          </Icon>
        </Button>
      </Wrapper>
    </Fragment>
  );
}

SelectDropdownButton.propTypes = {
  id: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  ),
  selected: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    colors: PropTypes.arrayOf(PropTypes.string)
  }),
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  toggleMenu: PropTypes.func
};

SelectDropdownButton.defaultProps = {
  autoFocus: false
};

export default SelectDropdownButton;
