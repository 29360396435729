import styled from "styled-components";

export const Container = styled.div`
  padding: 1.2rem 1.2rem 0.8rem;
  background: ${({ theme }) => theme.styleGuide.colors.gray[400]};
  border-radius: 0.4rem;
`;

export const Grid = styled.div`
  display: flex;
`;

export const Cell = styled.div`
  display: flex;
  justify-content: center;
  width: 20%;
`;

export const Star = styled.button`
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  appearance: none;
  cursor: pointer;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}

  svg {
    width: 2.4rem;
    height: 2.4rem;
    margin-bottom: 0.7rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  }
`;
