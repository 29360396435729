import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { colors } from "@wrstudios/theme";
import { IconMoney, IconPencil } from "@wrstudios/icons";
import AddressAutoComplete from "../../address/AddressAutoComplete";
import Label from "../../common/Label";
import Image from "../../common/Image";
import Loading from "../../common/Loading";
import {
  ComparableImage,
  ComparableAddress,
  ComparableDifferences
} from "../../comparable";
import { transformSubjectProperty } from "../../../utils/subject-property";
import SubjectPropertyPin from "../../common/SubjectPropertyPin";
import IllustrationScale from "../../illustrations/IllustrationScale";
import { updateSubjectProperty } from "./api";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerValuationModal from "./HomebeatViewerValuationModal";
import {
  Comparable,
  Overflow,
  HeaderEdit,
  Tag,
  GlobalStyle
} from "./styled/homebeat-viewer-listing";
import {
  Modal,
  ModalContent,
  ModalHeader,
  ModalClose,
  ModalBody,
  ModalFooter,
  Guide,
  GuideIllustration,
  GuideText,
  MapStatic,
  Button,
  Grid,
  Cell,
  Text,
  Input as TaxDataInput,
  Error
} from "./styled/homebeat-viewer-subject-property";

function HomebeatViewerSubjectProperty(props) {
  const { theme, subjectProperty, showAcresInsteadOfLotSize, isAcreageReport } =
    useHomebeatViewer();
  const [showModal, setShowModal] = useState(false);
  const [showValuationModal, setShowValuationModal] = useState(false);
  const primaryLotSize =
    (showAcresInsteadOfLotSize || isAcreageReport
      ? subjectProperty.acres
      : subjectProperty.lotSize) || 0;

  return (
    <>
      <GlobalStyle />
      <Comparable {...props} isSubjectProperty>
        <ComparableImage>
          <Tag>Your Home</Tag>
          {!subjectProperty.photo &&
            !!subjectProperty.coords.lat &&
            !!subjectProperty.coords.lon && (
              <MapStatic
                width={328}
                height={210}
                lat={subjectProperty.coords.lat}
                lon={subjectProperty.coords.lon}
                pin={
                  <SubjectPropertyPin color={theme.homebeat.colors.primary} />
                }
              />
            )}
          {!!subjectProperty.photo && (
            <Image
              src={subjectProperty.photo}
              data-error="Image Unavailable"
              alt="Image Unavailable"
            />
          )}
        </ComparableImage>
        <ComparableAddress
          street={subjectProperty.street}
          cityStateZip={subjectProperty.cityStateZip}
          status="Subject"
          statusColor={colors.purple[500]}
          primaryColor={theme.homebeat.colors.primary}
          price={subjectProperty.priceRange.min}
          priceLabel="Get Valuation"
          onPriceClick={() => setShowValuationModal(true)}
        />
        <ComparableDifferences
          primaryBeds={subjectProperty.beds}
          primaryBaths={subjectProperty.baths}
          primarySqft={subjectProperty.sqft}
          primaryLotSize={primaryLotSize}
          primaryGarages={subjectProperty.garages}
          showAcresInsteadOfLotSize={showAcresInsteadOfLotSize}
        />
        <Overflow>
          <HeaderEdit
            title="Edit Property Details"
            onClick={() => setShowModal(true)}>
            <IconPencil />
            Edit Property Details
          </HeaderEdit>
          <Guide>
            <GuideIllustration>
              <IllustrationScale />
            </GuideIllustration>
            <GuideText>
              Let's find out what your home is really worth. Start your free
              home valuation today.
            </GuideText>
            <Button onClick={() => setShowValuationModal(true)}>
              <IconMoney />
              Get Free Home Valuation
            </Button>
          </Guide>
        </Overflow>
      </Comparable>
      {showModal && (
        <HomebeatViewerSubjectPropertyModal
          onClose={() => setShowModal(false)}
        />
      )}
      {showValuationModal && (
        <HomebeatViewerValuationModal
          onClose={() => setShowValuationModal(false)}
        />
      )}
    </>
  );
}

function HomebeatViewerSubjectPropertyModal({ onClose }) {
  const {
    agent,
    automation,
    subjectProperty,
    setSubjectProperty,
    showAcresInsteadOfLotSize
  } = useHomebeatViewer();
  const modalContentRef = useRef();
  const [address, setAddress] = useState(subjectProperty.address || "");
  const [addressCoords, setAddressCords] = useState({
    lat: subjectProperty.coords.lat || "",
    lng: subjectProperty.coords.lon || ""
  });
  const [beds, setBeds] = useState(subjectProperty.beds || "");
  const [baths, setBaths] = useState(subjectProperty.baths || "");
  const [sqft, setSqft] = useState(subjectProperty.sqft || "");
  const [garages, setGarages] = useState(subjectProperty.garages || "");
  const [lotsize, setLotsize] = useState(
    (showAcresInsteadOfLotSize
      ? subjectProperty.acres
      : subjectProperty.lotSize) || ""
  );
  const [taxData, setTaxData] = useState({});
  const [loading, setLoading] = useState(false);
  const [hasSent, setHasSent] = useState(false);
  const [error, setError] = useState("");
  const [isClosing, setIsClosing] = useState(false);

  const handleAddressSelect = (address, taxData) => {
    const [lng, lat] = address.coordinates;
    setAddress(address.value);
    setAddressCords({ lat, lng });
    setTaxData(taxData);
    if (taxData.beds) setBeds(taxData.beds);
    if (taxData.baths) setBaths(taxData.baths);
    if (taxData.sqft) setSqft(taxData.sqft);
    if (taxData.garages) setGarages(taxData.garages);
    if (taxData.lotsize || taxData.acres)
      setLotsize(taxData.lotsize || taxData.acres);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError("");

    try {
      const homebeat = await updateSubjectProperty(automation.id, agent.guid, {
        address,
        geo_lat: addressCoords.lat,
        geo_lon: addressCoords.lon,
        sqft,
        beds,
        baths,
        garages,
        lot_size: showAcresInsteadOfLotSize ? undefined : lotsize,
        acres: showAcresInsteadOfLotSize ? lotsize : undefined
      });
      setSubjectProperty((subjectProperty) => ({
        ...subjectProperty,
        ...transformSubjectProperty(homebeat.subjectProperty || {})
      }));
      setHasSent(true);
      modalContentRef.current.style.transition = "none";
      modalContentRef.current.style.transform = `translateX(0%)`;
    } catch (error) {
      setError(error.message);
      setLoading(false);
    }
  };

  // Hack around mounting and animating
  useEffect(() => {
    modalContentRef.current.style.transition = "transform 0.2s";

    if (!isClosing) {
      setTimeout(() => {
        modalContentRef.current.style.transform = `translateX(0%)`;
      });
    } else {
      modalContentRef.current.style.transform = `translateX(110%)`;
    }
  }, [isClosing, hasSent]);

  return (
    <>
      {!hasSent && (
        <Modal
          focusLock={{ returnFocus: true }}
          onClose={() => setIsClosing(true)}>
          <ModalContent
            ref={modalContentRef}
            onTransitionEnd={() => isClosing && onClose()}>
            <ModalHeader>
              Your Home Details
              <ModalClose />
            </ModalHeader>
            <ModalBody>
              <Grid>
                <Cell style={{ width: "100%" }}>
                  <AddressAutoComplete
                    address={subjectProperty.address}
                    onSelect={handleAddressSelect}
                  />
                </Cell>
                <Cell style={{ width: "calc(100% / 3)" }}>
                  <Label htmlFor="beds">Beds</Label>
                  <TaxDataInput
                    id="beds"
                    hasTaxData={!!taxData.beds}
                    value={beds}
                    onChange={(e) => {
                      setTaxData(({ beds, ...taxData }) => taxData);
                      setBeds(e.target.value);
                    }}
                  />
                </Cell>
                <Cell style={{ width: "calc(100% / 3)" }}>
                  <Label htmlFor="baths">Baths</Label>
                  <TaxDataInput
                    id="baths"
                    hasTaxData={!!taxData.baths}
                    value={baths}
                    onChange={(e) => {
                      setTaxData(({ baths, ...taxData }) => taxData);
                      setBaths(e.target.value);
                    }}
                  />
                </Cell>
                <Cell style={{ width: "calc(100% / 3)" }}>
                  <Label htmlFor="sqft">Sqft</Label>
                  <TaxDataInput
                    id="sqft"
                    hasTaxData={!!taxData.sqft}
                    value={sqft}
                    onChange={(e) => {
                      setTaxData(({ sqft, ...taxData }) => taxData);
                      setSqft(e.target.value);
                    }}
                  />
                </Cell>
                <Cell style={{ width: "calc(100% / 3)" }}>
                  <Label htmlFor="garages">Garages</Label>
                  <TaxDataInput
                    id="garages"
                    hasTaxData={!!taxData.garages}
                    value={garages}
                    onChange={(e) => {
                      setTaxData(({ garages, ...taxData }) => taxData);
                      setGarages(e.target.value);
                    }}
                  />
                </Cell>
                <Cell style={{ width: "calc(100% / 3)" }}>
                  <Label htmlFor="lot-size">Lot Size</Label>
                  <TaxDataInput
                    id="lot-size"
                    hasTaxData={
                      showAcresInsteadOfLotSize
                        ? !!taxData.acres
                        : !!taxData.lotsize
                    }
                    value={lotsize}
                    onChange={(e) => {
                      setTaxData(({ lotsize, ...taxData }) => taxData);
                      setLotsize(e.target.value);
                    }}
                  />
                </Cell>
              </Grid>
            </ModalBody>
            <ModalFooter>
              <Button isFullWidth disabled={loading} onClick={handleSubmit}>
                {loading ? <Loading>Updating</Loading> : "Update Your Details"}
              </Button>
              {error && <Error>{error}</Error>}
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {hasSent && (
        <Modal
          focusLock={{ returnFocus: true }}
          onClose={() => setIsClosing(true)}>
          <ModalContent
            ref={modalContentRef}
            onTransitionEnd={() => isClosing && onClose()}>
            <ModalHeader>
              Your Home Details
              <ModalClose />
            </ModalHeader>
            <ModalBody>
              <Text>Your home details have been updated.</Text>
            </ModalBody>
            <ModalFooter>
              <Button isFullWidth onClick={() => setIsClosing(true)}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </>
  );
}

HomebeatViewerSubjectPropertyModal.propTypes = {
  onClose: PropTypes.func.isRequired
};

export default HomebeatViewerSubjectProperty;
