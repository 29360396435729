import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import { useSession } from "../../Session";
import { addActivePage } from "../../actions/customize";
import { snakeCase } from "../../utils/formatters";
import IconAddCircleOutline from "../icons/material/content/IconAddCircleOutline";
import CustomizeModalPowerPack from "./CustomizeModalPowerPack";
import CustomizeModalPowerPackCheckout from "./CustomizeModalPowerPackCheckout";
import CustomizeModalRdc from "./CustomizeModalRdc";
import CustomizeModalTestimonial from "./CustomizeModalTestimonial";
import CustomizeModalTomFerry from "./CustomizeModalTomFerry";
import CustomizeModalTomFerryCheckout from "./CustomizeModalTomFerryCheckout";

function CustomizePageAdd({ page, details, activePages, addActivePage }) {
  const { currentUser, features } = useSession();
  const buttonRef = useRef();
  const { marketing, tom, lux } = details;
  const [showRdcModal, setShowRdcModal] = useState(false);
  const [showTestimonialModal, setShowTestimonialModal] = useState(false);
  const [showMarketingModal, setShowMarketingModal] = useState(false);
  const [showMarketingCheckoutModal, setShowMarketingCheckoutModal] =
    useState(false);
  const [showPowerPackModal, setShowPowerPackModal] = useState(false);
  const [showPowerPackCheckoutModal, setShowPowerPackCheckoutModal] =
    useState(false);
  const [showTomFerryModal, setShowTomFerryModal] = useState(false);
  const [showTomFerryCheckoutModal, setShowTomFerryCheckoutModal] =
    useState(false);

  const isRdc = page.key.startsWith("rdc_");

  const cloneElement = () => {
    const node = buttonRef.current.parentNode;
    const { left, top } = node.getBoundingClientRect();
    const clonedElement = node.cloneNode(true);

    clonedElement.style.left = `${left + window.scrollX}px`;
    clonedElement.style.top = `${top + window.scrollY}px`;
    clonedElement.classList.add(
      "customize-movable-element",
      `cma-${snakeCase(page.key)}-movable-element`
    );

    document.body.appendChild(clonedElement);
  };

  const handleClick = (forceClick = false) => {
    const testimonialKey = getTestimonialKeyFromPageKey(page.key);
    const testimonial = currentUser[testimonialKey];

    if (!forceClick) {
      if (testimonial && !testimonial.name) {
        setShowTestimonialModal(true);
        return;
      }

      if (isRdc && features.offerRdcToolkit) {
        setShowRdcModal(true);
        return;
      }

      if (page.offerMarketingPack) {
        setShowMarketingModal(true);
        return;
      }

      if (page.offerPowerPack) {
        setShowPowerPackModal(true);
        return;
      }

      if (page.offerTomFerry) {
        setShowTomFerryModal(true);
        return;
      }
    }

    cloneElement();

    const isParentActive = activePages.some((p) => page.parent === p.key);

    // Add the parent item first
    if (
      page.key !== "listings_chapter" &&
      page.parent === "listings" &&
      !isParentActive
    ) {
      addActivePage(page.parent);
    }

    // Add the item after parent has been added.
    addActivePage(page.key);
  };

  const handleUpdate = (forceClick) => {
    setShowRdcModal(false);
    setShowTestimonialModal(false);
    setShowMarketingModal(false);
    setShowMarketingCheckoutModal(false);
    setShowPowerPackModal(false);
    setShowPowerPackCheckoutModal(false);
    setShowTomFerryModal(false);
    setShowTomFerryCheckoutModal(false);
    handleClick(forceClick);
  };

  return (
    <>
      <button
        ref={buttonRef}
        className="add-cta mr-4 flex items-center"
        type="button"
        data-pendo-id={isRdc ? "rdc-add-page" : undefined}
        onClick={() => handleClick(false)}
        aria-label={`Add ${page.title} to your report.`}>
        <span className="text-green">
          <IconAddCircleOutline width={24} height={24} />
        </span>
      </button>
      {showRdcModal && (
        <CustomizeModalRdc
          confirmLink="https://succeed.realtor.com/listingtoolkit?utm_source=cloud_cma&utm_medium=website&utm_campaign=ccma_customize_addpage&utm_term=addpage"
          onClose={() => setShowRdcModal(false)}
        />
      )}
      {showTestimonialModal && (
        <CustomizeModalTestimonial
          testimonialKey={getTestimonialKeyFromPageKey(page.key)}
          onUpdate={handleUpdate}
          onClose={() => setShowTestimonialModal(false)}
        />
      )}
      {showMarketingModal && (
        <CustomizeModalPowerPack
          onConfirm={() => {
            setShowMarketingModal(false);
            setShowMarketingCheckoutModal(true);
          }}
          onClose={() => setShowMarketingModal(false)}
        />
      )}
      {showMarketingCheckoutModal && (
        <CustomizeModalPowerPackCheckout
          sku={marketing.sku}
          amount={marketing.amount}
          onCheckout={handleUpdate}
          onClose={() => setShowMarketingCheckoutModal(false)}
        />
      )}
      {showPowerPackModal && (
        <CustomizeModalPowerPack
          onConfirm={() => {
            setShowPowerPackModal(false);
            setShowPowerPackCheckoutModal(true);
          }}
          onClose={() => setShowPowerPackModal(false)}
        />
      )}
      {showPowerPackCheckoutModal && (
        <CustomizeModalPowerPackCheckout
          sku={lux.sku}
          amount={lux.amount}
          onCheckout={handleUpdate}
          onClose={() => setShowPowerPackCheckoutModal(false)}
        />
      )}
      {showTomFerryModal && (
        <CustomizeModalTomFerry
          onConfirm={() => {
            setShowTomFerryModal(false);
            setShowTomFerryCheckoutModal(true);
          }}
          onClose={() => setShowTomFerryModal(false)}
        />
      )}
      {showTomFerryCheckoutModal && (
        <CustomizeModalTomFerryCheckout
          sku={tom.sku}
          amount={tom.amount}
          onCheckout={handleUpdate}
          onClose={() => setShowTomFerryCheckoutModal(false)}
        />
      )}
    </>
  );
}

CustomizePageAdd.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
  page: PropTypes.shape({
    key: PropTypes.string,
    parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    testimonial: PropTypes.shape({
      required: PropTypes.bool
    })
  }),
  details: PropTypes.shape({
    marketing: PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    lux: PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    }),
    tom: PropTypes.shape({
      sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    })
  }),
  activePages: PropTypes.array,
  addActivePage: PropTypes.func
};

const mapStateToProps = (state) => ({
  report: state.report,
  activePages: state.customize.active.pages,
  details: state.customize.powerPacks.details
});

const mapDispatchToProps = {
  addActivePage
};

function getTestimonialKeyFromPageKey(pageKey) {
  return {
    zillow_agent_reviews: "zillow",
    realsatisfied_agent_reviews: "realSatisfied",
    ratedagent_reviews: "ratedAgent",
    reach150_reviews: "reach150",
    testimonialtree_reviews: "testimonialTree",
    titlefy: "titlefy"
  }[pageKey];
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizePageAdd);
