import { PAGE_LOADING } from "../../actionTypes/page";
import { loading } from "./loading";

export default function (state = {}, action) {
  switch (action.type) {
    case PAGE_LOADING:
      return loading(state, action);

    default:
      return state;
  }
}
