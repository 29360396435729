import React from "react";
import PropTypes from "prop-types";
import { IconSpinner } from "@wrstudios/icons";
import { Container, Spinner } from "./styled/button";

function Button({
  isFullWidth,
  variant,
  app,
  size,
  loading,
  children,
  ...props
}) {
  return (
    <Container
      {...props}
      variant={variant}
      app={app}
      size={size}
      full={isFullWidth}>
      {loading ? (
        <Spinner>
          <IconSpinner />
        </Spinner>
      ) : (
        children
      )}
    </Container>
  );
}

Button.defaultProps = {
  variant: "primary",
  app: "none",
  size: "default"
};

Button.propTypes = {
  isFullWidth: PropTypes.bool,
  variant: PropTypes.oneOf(["primary", "secondary", "tertiary", "danger"]),
  app: PropTypes.oneOf(["none", "cma", "streams", "mlx", "attract", "suite"]),
  size: PropTypes.oneOf(["small", "default", "large"]),
  loading: PropTypes.bool
};

export default Button;
