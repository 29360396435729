import styled, { css } from "styled-components";

export const Container = styled.div`
  padding-bottom: 3.5rem;
  background-color: ${({ theme }) => theme.colors.white};
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
`;

export const Cell = styled.div`
  padding: 1rem;
  position: relative;

  ${({ reportType }) =>
    reportType === "flyer"
      ? css`
          width: 20%;

          ${({ theme }) => theme.media.lg`
            width: calc(100% / 3);
          `}
        `
      : css`
          width: calc(100% / 3);

          ${({ theme }) => theme.media.lg`
            width: 50%;
          `}
        `}

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;
