import { Modal as BaseModal } from "@wrstudios/components";
import styled from "styled-components";
import BaseCheckboxLabel from "../../common/CheckboxLabel";
import BaseLabel from "../../common/Label";

export const Note = styled.div`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #6b7280;
  margin-top: 0.4rem;
`;

export const Label = styled(BaseLabel)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Optional = styled.span`
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #9ca3af;
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 53.2rem;
`;

export const ModalFooter = styled(BaseModal.Footer)`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 1.2rem;
`;

export const Error = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-right: 2.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Saved = styled.p`
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-right: 2.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.cma[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const AgentNotes = styled.div`
  margin-bottom: 2.4rem;
`;

export const Checkboxes = styled.div`
  padding-left: 1rem;
`;

export const CheckboxContainer = styled.div`
  display: flex;

  &:first-child {
    margin-bottom: 1.6rem;
  }
`;

export const CheckboxLabel = styled(BaseCheckboxLabel)`
  font-weight: 400;
  font-size: 1.4rem;
  line-height: 2rem;
  color: #111827;
`;
