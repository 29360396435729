import React, { Fragment } from "react";
import { useSession } from "../../Session";
import { ReportCardSkeleton } from "../common/ReportCard";
import GettingStarted from "../common/CardGettingStarted";
import CMACard from "../cma/CMACard";
import TourCard from "../tour/TourCard";
import PropertyCard from "../property/PropertyCard";
import FlyerCard from "../flyer/FlyerCard";
import HomeCreateReport from "./HomeCreateReport";
import { GET_REPORTS } from "./queries";
import { Grid, Cell } from "./styled/home-reports";

function HomeReports({ reports, isLoading }) {
  const { config } = useSession();
  const showOnboardingVideo = !isLoading && reports.length < 5;

  return (
    <Grid>
      <Cell>
        <HomeCreateReport hasReports={!!reports.length} />
      </Cell>
      {isLoading &&
        new Array(5).fill().map((_, index) => (
          <Cell key={index}>
            <ReportCardSkeleton />
          </Cell>
        ))}
      {!isLoading &&
        reports.map((report) => (
          <Fragment key={report.id}>
            {report.type === "Cma" && (
              <Cell>
                <CMACard
                  id={report.id}
                  guid={report.guid}
                  title={report.title}
                  type={report.type}
                  status={report.status}
                  pdfLink={report.pdfLink}
                  notes={report.notes}
                  thumbnail={report.thumbnail}
                  createdWith={report.createdWith}
                  address={report.addressParts[0]}
                  cityStateZip={report.addressParts[1]}
                  beds={
                    report.subjectProperty ? report.subjectProperty.beds : null
                  }
                  baths={
                    report.subjectProperty ? report.subjectProperty.baths : null
                  }
                  sqft={
                    report.subjectProperty ? report.subjectProperty.sqft : null
                  }
                  lotSize={
                    report.subjectProperty
                      ? report.subjectProperty.lotSize
                      : null
                  }
                  garages={
                    report.subjectProperty
                      ? report.subjectProperty.garages
                      : null
                  }
                  viewCount={report.eventSummary.viewCount}
                  lastViewedAt={report.eventSummary.lastViewedAt}
                  updatedAt={report.updatedAt}
                  investorOffer={report.investorOffer}
                  transaction={report.transaction}
                  refetchQuery={GET_REPORTS}
                />
              </Cell>
            )}
            {report.type === "Tour" && (
              <Cell>
                <TourCard
                  id={report.id}
                  guid={report.guid}
                  title={report.title}
                  type={report.type}
                  status={report.status}
                  pdfLink={report.pdfLink}
                  notes={report.notes}
                  thumbnail={report.thumbnail}
                  createdWith={report.createdWith}
                  addresses={report.addresses || []}
                  cities={report.cities || []}
                  viewCount={report.eventSummary.viewCount}
                  lastViewedAt={report.eventSummary.lastViewedAt}
                  updatedAt={report.updatedAt}
                  refetchQuery={GET_REPORTS}
                />
              </Cell>
            )}
            {report.type === "Property" && (
              <Cell>
                <PropertyCard
                  id={report.id}
                  guid={report.guid}
                  title={report.title}
                  type={report.type}
                  status={report.status}
                  pdfLink={report.pdfLink}
                  notes={report.notes}
                  thumbnail={report.thumbnail}
                  createdWith={report.createdWith}
                  address={report.addressParts[0]}
                  cityStateZip={report.addressParts[1]}
                  viewCount={report.eventSummary.viewCount}
                  lastViewedAt={report.eventSummary.lastViewedAt}
                  updatedAt={report.updatedAt}
                  refetchQuery={GET_REPORTS}
                />
              </Cell>
            )}
            {report.type === "Flyer" && (
              <Cell>
                <FlyerCard
                  id={report.id}
                  guid={report.guid}
                  title={report.title}
                  type={report.type}
                  status={report.status}
                  pdfLink={report.pdfLink}
                  notes={report.notes}
                  thumbnail={report.thumbnail}
                  createdWith={report.createdWith}
                  address={report.addressParts[0]}
                  cityStateZip={report.addressParts[1]}
                  viewCount={report.eventSummary.viewCount}
                  lastViewedAt={report.eventSummary.lastViewedAt}
                  updatedAt={report.updatedAt}
                  refetchQuery={GET_REPORTS}
                />
              </Cell>
            )}
          </Fragment>
        ))}
      {showOnboardingVideo && (
        <Cell>
          <GettingStarted
            videoTitle="Getting Started with CMA"
            videoId={config.homeKickStartVideoId}>
            Watch Welcome Video
          </GettingStarted>
        </Cell>
      )}
    </Grid>
  );
}

export default HomeReports;
