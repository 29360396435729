import React from "react";
import Icon from "../../Icon";

function IconKeyboardArrowUp(props) {
  return (
    <Icon {...props}>
      <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </Icon>
  );
}

export default IconKeyboardArrowUp;
