import React from "react";
import ListingsControlsSort from "./ListingsControlsSort";
import ListingsControlsToggle from "./ListingsControlsToggle";

function ListingsControls() {
  return (
    <div className="flex flex-wrap md:flex-no-wrap items-center justify-between mb-6">
      <div className="flex items-center justify-center sm:justify-start w-full sm:w-1/2">
        <ListingsControlsSort />
      </div>
      <div className="flex items-center justify-center sm:justify-end w-full sm:w-1/2 mt-8 sm:mt-0">
        <ListingsControlsToggle />
      </div>
    </div>
  );
}

export default ListingsControls;
