import styled, { css } from "styled-components";
import { Card as BaseCard } from "../../common/Card";

export const Container = styled(BaseCard)`
  display: flex;
  flex-direction: column;
  width: 32.8rem;
  overflow: hidden;
  box-shadow: 0 0 0 0.1rem ${({ theme }) => theme.styleGuide.colors.gray[400]};

  ${({ theme }) => theme.media.sm`
    width: auto;
    border-radius: 0;
  `}
`;

export const Row = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 1.2rem;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
  border-left: 0;
  border-right: 0;
  transition: background-color 0.2s ease;

  &:first-of-type {
    border-top-width: 0;
  }
`;

export const Label = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Text = styled.span`
  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const MobileText = styled(Text)`
  display: none;

  ${({ theme }) => theme.media.sm`
    display: inline;
  `}
`;

export const Value = styled.div`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  color: ${({ theme, shouldDim }) =>
    shouldDim
      ? theme.styleGuide.colors.gray[700]
      : theme.styleGuide.colors.gray[900]};

  ${({ hasOverflow }) =>
    !hasOverflow &&
    css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `}

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Difference = styled.strong`
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${({ theme, value }) =>
    value > 0
      ? theme.styleGuide.colors.red[600]
      : theme.styleGuide.colors.green[700]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;    
  `}
`;

export const PriceDifference = styled.strong`
  display: inline-block;
  font-size: 1.2rem;
  font-weight: 700;
  line-height: 1.6rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;    
  `}
`;

export const Link = styled.a`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  padding: 0;
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  text-decoration: none;
  appearance: none;
  cursor: pointer;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;
