import styled from "styled-components";
import { default as BaseToolTip } from "../Tooltip";

export const Container = styled(BaseToolTip)`
  cursor: help;
  border-bottom: 0.1rem dashed
    ${({ theme }) => theme.styleGuide.colors.gray[700]};

  .tooltip-text {
    width: auto;
    font-size: 1.2rem;
    line-height: 1.6rem;
    padding: 0.2rem 0.6rem;
    white-space: nowrap;

    ${({ theme }) => theme.media.sm`
      font-size: 1.3rem;
      line-height: 1.7rem;
    `}
  }
`;
