import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { toggle } from "../../actions/listings";
import IconCheckmark from "../icons/IconCheckmark";
import IconKeyboardArrowDown from "../icons/material/hardware/IconKeyboardArrowDown";
import IconKeyboardArrowUp from "../icons/material/hardware/IconKeyboardArrowUp";

function ListingActions({
  id,
  report,
  status,
  hide,
  isOpen,
  isManual,
  toggle,
  onClick,
  onEditClick
}) {
  const isSubjectProperty = status === "Subject Property";

  return (
    <div className="listing-actions">
      {!isSubjectProperty && (
        <div className="w-full flex align-items">
          {isManual && (
            <button
              className="text-xs text-grey-darker mr-4 hidden sm:inline-block md:ml-auto listing-edit"
              onClick={onEditClick}>
              Edit
            </button>
          )}
          <div
            className={`checkbox checkbox-small ${
              isManual ? "" : "md:ml-auto"
            }`}>
            <input
              type="checkbox"
              className="checkbox-input"
              id={`checkbox-${id}`}
              checked={!hide}
              onChange={() => toggle(report.id, id)}
            />
            <label className="checkbox-label" htmlFor={`checkbox-${id}`}>
              <span className="checkbox-marker m-0">
                <IconCheckmark />
              </span>
              {/* <span className="sm:hidden text-sm ml-4">Select this property</span> */}
            </label>
          </div>
          {isManual && (
            <button
              className="text-xs text-grey-darker sm:hidden ml-4 listing-edit"
              onClick={onEditClick}>
              Edit
            </button>
          )}
        </div>
      )}

      {isSubjectProperty && <div className="hidden md:block">&nbsp;</div>}

      <div
        className={`md:w-full ${
          isSubjectProperty ? "mx-auto sm:mx-0 sm:ml-auto" : "ml-auto"
        } md:text-right`}>
        <button
          className="inline-flex items-center text-xs listing-cta text-grey-darker whitespace-no-wrap"
          type="button"
          onClick={onClick}>
          {status === "Subject Property" && (
            <span className="underline">Net Sheet & Notes</span>
          )}
          {status !== "Subject Property" && (
            <span className="underline">
              {report.type.toLowerCase() === "cma"
                ? "Notes & Adjustments"
                : "Details"}
            </span>
          )}
          <span>
            {isOpen && <IconKeyboardArrowUp viewBox="0 -2 24 24" />}
            {!isOpen && <IconKeyboardArrowDown viewBox="0 -2 24 24" />}
          </span>
        </button>
      </div>
    </div>
  );
}

ListingActions.propTypes = {
  id: PropTypes.number,
  type: PropTypes.string,
  report: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
  status: PropTypes.string,
  hide: PropTypes.bool,
  isOpen: PropTypes.bool,
  isManual: PropTypes.bool,
  toggle: PropTypes.func,
  onClick: PropTypes.func
};

const mapStateToProps = (state) => ({
  report: state.report
});

const mapDispatchToProps = {
  toggle
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingActions);
