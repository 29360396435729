import { useLazyQuery, useMutation } from "@apollo/react-hooks";
import { Menu } from "@wrstudios/components";
import { IconView } from "@wrstudios/icons";
import { pluralize } from "@wrstudios/utils";
import { gql } from "apollo-boost";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSession } from "../../Session";
import { useQueryString } from "../../utils/url";
import AlertDanger from "../common/AlertDanger";
import { CardFooter } from "../common/Card";
import EmailReport from "../common/EmailReport";
import Stats from "../common/Stats";
import IconTransaction from "../icons/IconTransaction";
import IconMoreHoriz from "../icons/material/navigation/IconMoreHoriz";
import Skeleton from "./Skeleton";
import {
  Action,
  Actions,
  Card,
  CardBody,
  Container,
  CopyContainer,
  DeleteOverlay,
  Details,
  Image,
  Link,
  Loading,
  MenuButton,
  MenuItem,
  MenuItemDanger,
  MenuList,
  Notes,
  StatsContainer,
  Text,
  TextLine,
  Title,
  TitleWithMenu,
  TransactionLink,
  IconTransactionContainer,
  Verb,
  ViewCountAction
} from "./styled/report-card";
import Tooltip from "./Tooltip";
import { useCopyText } from "./useCopyText";

const GET_REPORT_STATUS = (type) => gql`
  query GetReportStatus($id: ID!) {
    getReportStatus(id: $id)
      @rest(
        type: "${type}"
        path: "reports/{args.id}/status_update"
      ) {
      id
      status
      pdfLink: pdf_url
    }
  }
`;

export const PUBLISH_REPORT = (type) => gql`
  mutation PublishReport($id: ID!) {
    publishReport(id: $id)
      @rest(type: "${type}", path: "cmas/{args.id}/publish") {
      id
      status
    }
  }
`;

function ReportCard({
  id,
  guid,
  type,
  typeLabel,
  title,
  status,
  pdfLink,
  address,
  cityStateZip,
  beds,
  baths,
  sqft,
  lotSize,
  garages,
  propertyType,
  propertySubType,
  notes,
  viewCount,
  lastViewedAt,
  lastRequestedCMAAt,
  updatedAt,
  image,
  deleteQuery,
  refetchQuery,
  isCreateHomebeatEnabled,
  isInvestorConnectEnabled,
  isLiveEnabled,
  isCopyLiveEnabled,
  isSlideshowEnabled,
  isCopySlideshowEnabled,
  isCopyEnabled,
  isEmailEnabled,
  isDeleteEnabled,
  showLastRequesteCMAAt,
  transaction,
  onCreateTransactionClick,
  onEditClick,
  liveUrl: _liveUrl
}) {
  const { currentUser, features } = useSession();
  const { params, stringify } = useQueryString();
  const [localStatus, setLocalStatus] = useState(status);
  const [localPdfLink] = useState(pdfLink);
  const [isEmailing, setIsEmailing] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const hasTransactions = type === "Cma" && features.hasTransactions;
  const isPublished = localStatus === "published-report";
  const isActive = localStatus === "active-report";
  const isHomebeat = type === "Homebeat";
  const label = isHomebeat ? "Homebeat" : "Report";
  const pdfLabel = isHomebeat ? "Homebeat" : "PDF";
  const [copyReportLinkText, copyReportLink] = useCopyText({
    originalText: `Copy ${pdfLabel} Link`,
    copiedText: `${pdfLabel} Link Copied!`
  });
  const [copyLiveLinkText, copyLiveLink] = useCopyText({
    originalText: "Copy Live Link",
    copiedText: "Live Link Copied!"
  });
  const [copySlideshowLinkText, copySlideshowLink] = useCopyText({
    originalText: "Copy Slideshow Link",
    copiedText: "Slideshow Link Copied!"
  });
  const [deleteReport, { loading: deleting }] = useMutation(deleteQuery, {
    refetchQueries: [
      {
        query: refetchQuery,
        variables: { page: Number(params.page || 1), search: params.q || "" }
      }
    ],
    awaitRefetchQueries: true,
    variables: { id }
  });
  const [publishReport] = useMutation(PUBLISH_REPORT(type), {
    variables: { id }
  });
  const [getReportStatus] = useLazyQuery(GET_REPORT_STATUS(type), {
    pollInterval: isActive ? 4000 : 0,
    variables: { id },
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ getReportStatus: { status } }) => {
      setLocalStatus(status);
    }
  });
  const showAdminOptions =
    process.env.NODE_ENV !== "production" && currentUser.isAdmin && !isHomebeat;

  const [isStatsOpen, setIsStatsOpen] = useState(false);

  const transactionUrl =
    transaction && transaction.id
      ? `/transactions/sso_link/${transaction.id}`
      : "";

  const transactionType = get(transaction, "type");
  const isTransactionAuthed =
    (transactionType === "ZipForm" && currentUser.zipFormToken) ||
    (transactionType === "TransactionDesk" && currentUser.transactionDeskToken);
  const liveUrl = _liveUrl ?? `/live/${guid}`;

  useEffect(() => {
    if (isActive) {
      getReportStatus();
    }
  }, [isActive]);

  return (
    <Container>
      {deleting && (
        <DeleteOverlay>
          <Loading>Deleting...</Loading>
        </DeleteOverlay>
      )}
      <Card>
        <CardBody>
          <StatsContainer isOpen={isStatsOpen}>
            <Stats
              viewCount={viewCount}
              lastViewedAt={lastViewedAt}
              lastRequestedCMAAt={lastRequestedCMAAt}
              lastUpdatedAt={updatedAt}
              showLastRequesteCMAAt={showLastRequesteCMAAt}
            />
          </StatsContainer>
          <Image>{image}</Image>
          <Details>
            <TitleWithMenu>
              <Title title={title}>{title}</Title>
              <Menu>
                <MenuButton>
                  <IconMoreHoriz />
                </MenuButton>
                <MenuList showPoint={false}>
                  {isCopyEnabled && (
                    <MenuItem
                      disabled={!isPublished}
                      closeOnClick={false}
                      title={
                        !isPublished
                          ? `You must publish your report before you can share it.`
                          : null
                      }>
                      {!isPublished && (
                        <CopyContainer>{copyReportLinkText}</CopyContainer>
                      )}
                      {isPublished && (
                        <CopyToClipboard
                          text={localPdfLink}
                          onCopy={copyReportLink}>
                          <CopyContainer>{copyReportLinkText}</CopyContainer>
                        </CopyToClipboard>
                      )}
                    </MenuItem>
                  )}
                  {isLiveEnabled && isCopyLiveEnabled && (
                    <Menu.Item closeOnClick={false}>
                      <CopyToClipboard
                        text={`${window.location.origin}${liveUrl}`}
                        onCopy={copyLiveLink}>
                        <CopyContainer>{copyLiveLinkText}</CopyContainer>
                      </CopyToClipboard>
                    </Menu.Item>
                  )}
                  {isSlideshowEnabled && isCopySlideshowEnabled && (
                    <Menu.Item
                      disabled={!isPublished}
                      title={
                        !isPublished
                          ? "You must publish your report before you can view the slideshow."
                          : null
                      }
                      onClick={() => window.open(`/present/${guid}`, "_blank")}>
                      View Slideshow
                    </Menu.Item>
                  )}
                  {isSlideshowEnabled && isCopySlideshowEnabled && (
                    <Menu.Item
                      disabled={!isPublished}
                      closeOnClick={false}
                      title={
                        !isPublished
                          ? `You must publish your ${label.toLowerCase()} before you can share the slideshow.`
                          : null
                      }>
                      {!isPublished && (
                        <CopyContainer>{copySlideshowLinkText}</CopyContainer>
                      )}
                      {isPublished && (
                        <CopyToClipboard
                          text={`${window.location.origin}/present/${guid}`}
                          onCopy={copySlideshowLink}>
                          <CopyContainer>{copySlideshowLinkText}</CopyContainer>
                        </CopyToClipboard>
                      )}
                    </Menu.Item>
                  )}
                  {isEmailEnabled && (
                    <Menu.Item
                      disabled={!isPublished}
                      title={
                        !isPublished
                          ? `You must publish your ${label.toLowerCase()} before you can share it.`
                          : null
                      }
                      onClick={() => setIsEmailing(true)}>
                      Email {label}
                    </Menu.Item>
                  )}
                  {isCreateHomebeatEnabled && (
                    <Menu.LinkExternal
                      target="_self"
                      href={`/homebeats/new?${stringify({
                        client: title,
                        address: [address, cityStateZip].join(", "),
                        beds,
                        baths,
                        sqft,
                        lot_size: lotSize,
                        garages,
                        prop_type: propertyType,
                        prop_sub_type: propertySubType
                      })}`}>
                      Create Homebeat
                    </Menu.LinkExternal>
                  )}
                  <Menu.Divider />
                  {isDeleteEnabled && (
                    <MenuItemDanger onClick={() => setIsDeleting(true)}>
                      Delete {label}
                    </MenuItemDanger>
                  )}
                  {showAdminOptions && <Menu.Divider />}
                  {showAdminOptions && <Menu.Header>Admin</Menu.Header>}
                  {showAdminOptions && (
                    <Menu.LinkExternal
                      target="_self"
                      href={`/${pluralize(type.toLowerCase(), 2)}/${id}`}>
                      Listings
                    </Menu.LinkExternal>
                  )}
                  {showAdminOptions && (
                    <Menu.LinkExternal
                      target="_self"
                      href={`/${pluralize(
                        type.toLowerCase(),
                        2
                      )}/${id}/customize`}>
                      Customize
                    </Menu.LinkExternal>
                  )}
                  {showAdminOptions && isInvestorConnectEnabled && (
                    <Menu.LinkExternal
                      target="_self"
                      href={`/${pluralize(
                        type.toLowerCase(),
                        2
                      )}/${id}/cash_offer`}>
                      Cash Offer
                    </Menu.LinkExternal>
                  )}
                  {showAdminOptions && (
                    <Menu.LinkExternal
                      target="_self"
                      href={`/${pluralize(
                        type.toLowerCase(),
                        2
                      )}/${id}/publish.html`}>
                      Publish (HTML)
                    </Menu.LinkExternal>
                  )}
                  {showAdminOptions && (
                    <Menu.Item
                      onClick={() => {
                        setLocalStatus("active-report");
                        publishReport();
                      }}>
                      Publish (PDF)
                    </Menu.Item>
                  )}
                </MenuList>
              </Menu>
            </TitleWithMenu>
            <Text>
              <TextLine title={address}>{address}</TextLine>
              {!cityStateZip && hasTransactions && transactionUrl && (
                <Tooltip fitContent text="View Transaction">
                  <TransactionLink href={transactionUrl} target="_self">
                    <IconTransactionContainer>
                      <IconTransaction />
                    </IconTransactionContainer>
                  </TransactionLink>
                </Tooltip>
              )}
              {!cityStateZip &&
                hasTransactions &&
                !transactionUrl &&
                isTransactionAuthed && (
                  <Tooltip fitContent text="Create Transaction">
                    <IconTransactionContainer
                      onClick={onCreateTransactionClick}>
                      <IconTransaction />
                    </IconTransactionContainer>
                  </Tooltip>
                )}
              {!cityStateZip &&
                hasTransactions &&
                transactionUrl &&
                !isTransactionAuthed && (
                  <Tooltip fitContent text="View Transaction">
                    <IconTransactionContainer
                      active
                      onClick={onCreateTransactionClick}>
                      <IconTransaction />
                    </IconTransactionContainer>
                  </Tooltip>
                )}
            </Text>
            {cityStateZip && (
              <Text>
                <TextLine title={cityStateZip}>{cityStateZip}</TextLine>
                {hasTransactions && !transactionUrl && (
                  <Tooltip fitContent text="Create Transaction">
                    <IconTransactionContainer
                      onClick={onCreateTransactionClick}>
                      <IconTransaction />
                    </IconTransactionContainer>
                  </Tooltip>
                )}
                {hasTransactions && transactionUrl && isTransactionAuthed && (
                  <Tooltip fitContent text="View Transaction">
                    <TransactionLink href={transactionUrl} target="_self">
                      <IconTransactionContainer active>
                        <IconTransaction />
                      </IconTransactionContainer>
                    </TransactionLink>
                  </Tooltip>
                )}
                {hasTransactions && transactionUrl && !isTransactionAuthed && (
                  <Tooltip fitContent text="View Transaction">
                    <IconTransactionContainer
                      active
                      onClick={onCreateTransactionClick}>
                      <IconTransaction />
                    </IconTransactionContainer>
                  </Tooltip>
                )}
              </Text>
            )}

            <Notes>{notes}</Notes>
          </Details>
        </CardBody>
        <CardFooter>
          <Actions>
            <Action>
              {!isActive && (
                <Link
                  disabled={!isPublished}
                  href={!isPublished ? null : localPdfLink}
                  target="_blank"
                  title={
                    !isPublished
                      ? "You must publish your report before you can view it."
                      : null
                  }>
                  <Verb>View</Verb> {pdfLabel}
                </Link>
              )}
              {isActive && <Loading>Publishing...</Loading>}
            </Action>
            {!isLiveEnabled && isSlideshowEnabled && !isActive && (
              <Action>
                <Link
                  disabled={!isPublished}
                  href={!isPublished ? null : `/present/${guid}`}
                  target="_blank"
                  title={
                    !isPublished
                      ? "You must publish your report before you can view the slideshow."
                      : null
                  }>
                  <Verb>View</Verb> Slideshow
                </Link>
              </Action>
            )}
            {isLiveEnabled && (
              <Action>
                <Link href={liveUrl} target="_blank">
                  <Verb>View</Verb> Live
                </Link>
              </Action>
            )}
            {isInvestorConnectEnabled && !isActive && (
              <Action>
                <Link
                  href={`/${pluralize(
                    type.toLowerCase(),
                    2
                  )}/${id}/publish?show_cash_offer=true`}
                  title={
                    !isPublished
                      ? "You must publish your report before you can view the slideshow."
                      : null
                  }>
                  <Verb>Request</Verb> Offer
                </Link>
              </Action>
            )}

            <ViewCountAction
              onMouseEnter={() => setIsStatsOpen(true)}
              onMouseLeave={() => setIsStatsOpen(false)}
              data-testid="view-count">
              <IconView />
              {viewCount}
            </ViewCountAction>

            <Action>
              <Link
                as={onEditClick ? "button" : "a"}
                href={
                  onEditClick
                    ? null
                    : `/${pluralize(type.toLowerCase(), 2)}/${id}/edit`
                }
                onClick={onEditClick}>
                Edit
              </Link>
            </Action>
          </Actions>
        </CardFooter>
      </Card>
      {isEmailing && (
        <EmailReport
          reportId={id}
          guid={guid}
          type={type}
          typeLabel={typeLabel}
          pdfLink={localPdfLink}
          title={title}
          address={address}
          cityStateZip={cityStateZip}
          image={image}
          onClose={() => setIsEmailing(false)}
        />
      )}
      {isDeleting && (
        <AlertDanger
          title={`Delete ${label}`}
          confirmButton="Delete Forever"
          onConfirm={deleteReport}
          onClose={() => setIsDeleting(false)}>
          Are you sure you want to remove the {label.toLowerCase()}:{" "}
          <strong>{title}</strong>?
        </AlertDanger>
      )}
    </Container>
  );
}

ReportCard.defaultProps = {
  liveLabel: "Live",
  isCreateHomebeatEnabled: false,
  isInvestorConnectEnabled: false,
  isLiveEnabled: false,
  isCopyLiveEnabled: true,
  isSlideshowEnabled: true,
  isCopySlideshowEnabled: true,
  isCopyEnabled: true,
  isEmailEnabled: true,
  isDeleteEnabled: true
};

ReportCard.propTypes = {
  id: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  typeLabel: PropTypes.string,
  title: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
  address: PropTypes.string,
  cityStateZip: PropTypes.string,
  beds: PropTypes.number,
  baths: PropTypes.string,
  sqft: PropTypes.number,
  lotSize: PropTypes.string,
  garages: PropTypes.string,
  property: PropTypes.string,
  propertySubType: PropTypes.string,
  notes: PropTypes.element,
  viewCount: PropTypes.number,
  lastViewedAt: PropTypes.string,
  lastRequestedCMAAt: PropTypes.string,
  updatedAt: PropTypes.string,
  image: PropTypes.node,
  deleteQuery: PropTypes.object,
  refetchQuery: PropTypes.object,
  isCreateHomebeatEnabled: PropTypes.bool,
  isInvestorConnectEnabled: PropTypes.bool,
  isLiveEnabled: PropTypes.bool,
  isSlideshowEnabled: PropTypes.bool,
  isCopySlideshowEnabled: PropTypes.bool,
  isCopyEnabled: PropTypes.bool,
  isEmailEnabled: PropTypes.bool,
  isDeleteEnabled: PropTypes.bool,
  showLastRequesteCMAAt: PropTypes.bool,
  onEditClick: PropTypes.func
};

function ReportCardSkeleton() {
  return (
    <Card>
      <CardBody>
        <Image>
          <Skeleton
            style={{
              width: "6.8rem",
              height: "8.8rem",
              borderRadius: "0.2rem"
            }}
          />
        </Image>
        <Details>
          <Title>
            <Skeleton style={{ width: "12.7rem" }} />
          </Title>
          <Text>
            <Skeleton
              style={{
                width: "6.6rem",
                height: "95%",
                marginTop: "0.4rem"
              }}
            />
          </Text>
        </Details>
      </CardBody>
      <CardFooter>
        <Skeleton style={{ width: "9.1rem" }} />
      </CardFooter>
    </Card>
  );
}

export { ReportCardSkeleton };
export default ReportCard;
