import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import Button from "../common/Button";
import Wistia from "../common/Wistia";
import IconPowerPack from "../icons/IconPowerPack";
import {
  PreviewModalContent,
  PreviewModalClose,
  Title,
  Video,
  Stats,
  Stat,
  StatNumber,
  StatText,
  Actions,
  Action
} from "./styled/customize-modal-kit";

function CustomizeModalPowerPack({ onConfirm, onClose }) {
  return (
    <Modal onClose={onClose}>
      <PreviewModalContent>
        <PreviewModalClose />
        <Modal.Body>
          <Title>
            <IconPowerPack />
          </Title>
          <Video>
            <Wistia videoId="lhpbavjnlp" autoplay={true} />
          </Video>
          <Stats>
            <Stat>
              <StatNumber>25</StatNumber>
              <StatText>Expertly written articles</StatText>
            </Stat>
            <Stat>
              <StatNumber>74</StatNumber>
              <StatText>Designer covers</StatText>
            </Stat>
            <Stat>
              <StatNumber>50</StatNumber>
              <StatText>Custom illustrations</StatText>
            </Stat>
          </Stats>
          <Actions>
            <Action>
              <Button app="suite" onClick={onConfirm}>
                Try for Free
              </Button>
            </Action>
          </Actions>
        </Modal.Body>
      </PreviewModalContent>
    </Modal>
  );
}

CustomizeModalPowerPack.propTypes = {
  onConfirm: PropTypes.func,
  onClose: PropTypes.func
};

export default CustomizeModalPowerPack;
