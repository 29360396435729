import React, { useState } from "react";
import PropTypes from "prop-types";
import { renameCustomPage } from "../../api/user";
import { useSession } from "../../Session";
import Input from "../common/Input";
import Flash from "../common/Flash";
import {
  Grid,
  Cell,
  ErrorCell,
  Control
} from "./styled/user-custom-pages-edit";

function UserCustomPagesEditRename({ id, pageKey, onClose }) {
  const { currentUser, customPages, setCustomPages } = useSession();
  const [key, setKey] = useState(pageKey || "");
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });
  const customPageKeys = customPages.map((page) => page.key);
  const pageExists = customPageKeys.includes(key);
  const isDisabled = !key || pageExists || request.loading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setRequest({ loading: true, success: false, error: null });
      const page = await renameCustomPage(currentUser.id, id, key);
      setRequest({ loading: false, success: true, error: null });
      setKey("");
      setCustomPages((customPages) =>
        customPages.map((p) => {
          if (p.key === pageKey) {
            p.key = page.key;
            p.oldKey = pageKey;
            p.title = page.title || page.key;
          }
          return p;
        })
      );
      onClose(page.key);
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error: error.originalResponse.data.error
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Grid>
        <Cell>
          <Input
            autoFocus
            value={key}
            onChange={(e) => setKey(e.target.value)}
            onFocus={(e) => e.target.select()}
          />
        </Cell>
        <Cell>
          <Control disabled={isDisabled}>
            {!request.loading && "Update"}
            {request.loading && "Updating"}
          </Control>
        </Cell>
        <Cell>
          <Control type="button" onClick={() => onClose(pageKey)}>
            Cancel
          </Control>
        </Cell>
        {request.error && (
          <ErrorCell>
            <Flash variant="error">{request.error}</Flash>
          </ErrorCell>
        )}
        {pageExists && (
          <ErrorCell>
            <Flash>That page already exists.</Flash>
          </ErrorCell>
        )}
      </Grid>
    </form>
  );
}

UserCustomPagesEditRename.propTypes = {
  id: PropTypes.number.isRequired,
  pageKey: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired
};

export default UserCustomPagesEditRename;
