import React, { useEffect } from "react";
import ReactDiv100vh from "react-div-100vh";
import { Redirect, Route, useLocation, useParams } from "react-router-dom";
import FauxLoader from "../../common/FauxLoader";
import HomebeatViewerComparables from "./HomebeatViewerComparables";
import HomebeatViewerListingDetails from "./HomebeatViewerListingDetails";
import HomebeatViewerMap from "./HomebeatViewerMap";
import {
  HomebeatViewerProvider,
  useHomebeatViewer
} from "./HomebeatViewerProvider";
import HomebeatViewerStats from "./HomebeatViewerStats";
import HomebeatViewerSubscriptionModal from "./HomebeatViewerSubscriptionModal";
import { Container, GlobalStyle } from "./styled/homebeat-viewer";

function HomebeatViewer() {
  const { guid } = useParams();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const { loading, theme, agent } = useHomebeatViewer();

  // This is used for the homebeat editor preview
  useEffect(() => {
    window.parent.postMessage(
      {
        source: "homebeat",
        payload: loading ? "loading" : "loaded"
      },
      // This is ok because we aren't sending anything sensitive,
      // only that the homebeat has loaded. If we get concerned
      // about privacy, we'll switch to https://cloudcma.com.
      "*"
    );
  }, [loading]);

  // This is used for the homebeat editor preview
  if (query.get("show-loading") === "false" && loading) {
    return null;
  }

  if (loading) {
    return (
      <ReactDiv100vh>
        <FauxLoader
          agentCompanyName={agent.companyName}
          agentCompanyLogo={agent.companyLogo}
          agentWebsite={agent.website}
          primaryColor={theme.homebeat.colors.primary}
          secondaryColor={theme.homebeat.colors.secondary}
          stallOnStep={4}
          steps={[
            "Fetching listings",
            "Assembling maps",
            "Calculating values",
            "Finalizing"
          ]}
        />
      </ReactDiv100vh>
    );
  }

  return (
    <Container id="homebeat">
      <GlobalStyle />
      <HomebeatViewerSubscriptionModal />
      <Route path="/homebeat/:guid" exact>
        <HomebeatViewerComparables />
      </Route>
      <Route path="/homebeat/:guid/map">
        <HomebeatViewerMap />
      </Route>
      <Route path="/homebeat/:guid/valuate">
        <Redirect to={`/homebeat/${guid}/stats`} />
      </Route>
      <Route path="/homebeat/:guid/stats">
        <HomebeatViewerStats />
      </Route>
      <Route path="/homebeat/:guid/listing/:listingId">
        <HomebeatViewerListingDetails />
      </Route>
    </Container>
  );
}

function HomebeatViewerWithProvider() {
  return (
    <HomebeatViewerProvider>
      <HomebeatViewer />
    </HomebeatViewerProvider>
  );
}

export default HomebeatViewerWithProvider;
