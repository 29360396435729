import React, { useState } from "react";
import PropTypes from "prop-types";
import { useQueryString } from "../../utils/url";
import IconSearch from "../icons/material/action/IconSearch";
import IconClose from "../icons/material/navigation/IconClose";
import { Container, SearchIcon, Input, Clear } from "./styled/search";

function Search({ defaultValue, placeholder, onSearch, onClear, ...props }) {
  const { updateSearchQuery } = useQueryString();
  const [searchTerm, setSearchTerm] = useState(defaultValue || "");

  const handleSubmit = (e) => {
    e.preventDefault();
    updateSearchQuery({ q: searchTerm, page: 1 });
    onSearch(searchTerm);
  };

  const handleClear = (e) => {
    updateSearchQuery({ q: null, page: null });
    setSearchTerm("");
    onClear(e);
  };

  return (
    <Container {...props} onSubmit={handleSubmit}>
      <SearchIcon>
        <IconSearch />
      </SearchIcon>
      <Input
        value={searchTerm}
        placeholder={placeholder}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      {searchTerm && (
        <Clear type="button" onClick={handleClear}>
          <IconClose />
        </Clear>
      )}
    </Container>
  );
}

Search.defaultProps = {
  defaultValue: "",
  placeholder: "Search",
  onSearch: () => {},
  onClear: () => {}
};

Search.propTypes = {
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  onSearch: PropTypes.func,
  onClear: PropTypes.func
};

export default Search;
