import React, { useState } from "react";
import PropTypes from "prop-types";
import { IconClose } from "@wrstudios/icons";
import { Container, Close } from "./styled/flash";

function Flash({ variant, closable, children, ...props }) {
  const [show, setShow] = useState(true);

  if (!show) return null;

  return (
    <Container {...props} variant={variant} role="alert">
      {variant === "error" ? (
        <div dangerouslySetInnerHTML={{ __html: children }}></div>
      ) : (
        children
      )}
      {closable && (
        <Close onClick={() => setShow(false)}>
          <IconClose />
        </Close>
      )}
    </Container>
  );
}

Flash.defaultProps = {
  variant: "info",
  closable: true
};

Flash.propTypes = {
  variant: PropTypes.oneOf(["info", "error", "success", "note", "warning"]),
  closable: PropTypes.bool
};

export default Flash;
