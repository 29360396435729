import { requestState, rejectedState } from "../../utils/redux";

export function request(state, action) {
  const { reportId } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === reportId) {
      report = requestState({ ...report, actionType: "delete" });
    }

    return report;
  });

  return { ...state, all: reports };
}

export function resolved(state, action) {
  return { ...state, all: [...action.payload.reports] };
}

export function rejected(state, action) {
  const { reportId } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === reportId) {
      report = rejectedState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}
