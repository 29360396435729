import styled, { css } from "styled-components";
import { Label as BaseLabel } from "../../../styles/Text";

export const Container = styled.div`
  padding: 0 3rem;
`;

export const Title = styled.h2`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.3rem;
  margin-bottom: 3.4rem;
`;

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1rem;
`;

export const Cell = styled.div`
  width: 50%;
  padding: 1rem;

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `};
`;

export const Header = styled.h3`
  font-size: 1.8rem;
  line-height: 2.4rem;
  margin-bottom: 1.4rem;
  color: ${({ theme }) => theme.colors.purpleDarker};
`;

export const Disclaimer = styled.div`
  display: flex;
  align-items: flex-start;
  margin: 3rem 0 2rem;

  ${({ hasCard }) =>
    hasCard &&
    css`
      max-width: 42rem;
      margin: 3rem auto 2rem;
    `};

  ${Header} {
    text-align: ${({ hasCard }) => hasCard && "center"};
  }
`;

export const Body = styled.div``;

export const Stamp = styled.div`
  flex-shrink: 0;
  margin-left: 4rem;

  ${({ theme }) => theme.media.sm`
    display: none;
  `};
`;

export const Label = styled(BaseLabel)``;

export const Success = styled.span`
  display: inline-block;
  font-size: 1.3rem;
  margin-top: 0.3rem;
  color: ${({ theme }) => theme.colors.green};
`;

export const Error = styled(Success)`
  color: ${({ theme }) => theme.colors.red};
`;

export const Text = styled.p`
  font-size: 1.3rem;
  line-height: 2.2rem;
`;

export const Link = styled.a`
  text-decoration: underline;
  color: ${({ theme }) => theme.colors.purpleDark};
`;

export const Strong = styled.strong``;

export const Action = styled.div`
  margin: 0 auto 2rem;
  text-align: center;

  &:first-of-type {
    margin: 0 0 4rem;
    text-align: left;
  }

  ${Error} {
    display: block;
    font-size: 1.5rem;
    margin-top: 2rem;
  }
`;

export const Actions = styled.div`
  display: flex;
  flex-direction: column;

  ${({ hasCard }) =>
    hasCard &&
    css`
      ${Action} {
        margin: 0 auto 2rem;
        text-align: center;

        &:first-of-type {
          margin: 0 auto 4rem;
        }
      }
    `};
`;

export const InputGroup = styled.div`
  display: flex;

  input {
    margin-right: 1rem;
  }
`;
