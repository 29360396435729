import url from "url";
import qs from "qs";

/**
 *
 * Gets the video id from the url
 *
 * @param {String} videoUrl
 */
export function getVideoIdFromUrl(videoUrl) {
  if (videoUrl) {
    const lowerUrl = videoUrl.toLowerCase();
    const parsedUrl = url.parse(videoUrl);

    if (lowerUrl.includes("wistia") || lowerUrl.includes("youtu.be")) {
      return parsedUrl.pathname.split("/").pop();
    } else if (lowerUrl.includes("youtube")) {
      return qs.parse(parsedUrl.query).v;
    } else {
      throw new Error(`Unknown video type for url: ${lowerUrl}`);
    }
  }
}

/**
 * Gets the type of video associated with a url
 *
 * @param {String} url
 */
export function getVideoTypeFromUrl(url) {
  if (url) {
    const lowerUrl = url.toLowerCase();

    if (lowerUrl.includes("wistia")) {
      return "wistia";
    } else if (lowerUrl.includes("youtube") || lowerUrl.includes("youtu.be")) {
      return "youtube";
    }

    throw new Error(`Unknown video type for url: ${url}`);
  }
}
