import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  const activePages = [...state.active.pages].map((page) => {
    if (page.key === "cover" || page.key === "cover_map") {
      page = requestState(page);
    }

    return page;
  });

  return { ...state, active: { ...state.active, pages: activePages } };
}

export function resolved(state, action) {
  const { headline } = action.payload;
  const activePages = [...state.active.pages].map((page) => {
    if (page.key === "cover" || page.key === "cover_map") {
      page = resolvedState(page);
    }

    return page;
  });

  return {
    ...state,
    headline: { ...headline, current: headline },
    active: { ...state.active, pages: activePages }
  };
}

export function rejected(state, action) {
  const activePages = [...state.active.pages].map((page) => {
    if (page.key === "cover" || page.key === "cover_map") {
      page = rejectedState(page, action.payload.error);
    }

    return page;
  });

  return { ...state, active: { ...state.active, pages: activePages } };
}
