import React from "react";

function IconHomebeatLogo() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 174.49 28.28">
      <path d="M3.76 14.78h8.57V5.6h3.76V28h-3.76V18H3.76v10H0V5.6h3.76zM33.09 20.88c0 4.32-2.11 7.4-6.88 7.4-4.54 0-6.91-2.92-6.91-7.4v-2c0-4.77 2.53-7.37 6.91-7.37s6.88 2.6 6.88 7.37zm-3.44-.09V18.9c0-2.82-1.14-4.41-3.44-4.41s-3.47 1.62-3.47 4.41v1.89c0 2.53.84 4.51 3.47 4.51 2.46 0 3.44-1.98 3.44-4.51zM36.1 11.77h3.41v1.46a4.31 4.31 0 0 1 3.8-1.76 5.22 5.22 0 0 1 4.63 2.34 5.73 5.73 0 0 1 4.74-2.34c3.51 0 5.61 2.15 5.61 6.36V28h-3.47v-9.78c0-2.66-1.1-3.66-2.88-3.66s-3 1.29-3 3.47V28h-3.46v-9.78c0-2.75-1.2-3.66-2.86-3.66s-3 1.36-3 3.63V28H36.1zM74.22 25.52c-1.13 2-3 2.76-5.81 2.76-4.64 0-7.1-3-7.1-7.33v-2.21c0-4.77 2.33-7.27 6.58-7.27 4.45 0 6.56 2.66 6.56 7.47v1.78h-9.7v.07c0 2.53 1 4.51 3.53 4.51 1.92 0 2.7-.59 3.35-1.59zm-9.44-7.46H71c-.13-2.37-1.11-3.57-3.09-3.57s-2.97 1.36-3.13 3.57zM81.13 13A4.71 4.71 0 0 1 85 11.47c4 0 6.46 2.6 6.46 7.37v2c0 4.32-2.11 7.4-6.46 7.4a4.76 4.76 0 0 1-3.89-1.46V28h-3.32V5.6h3.34zM88 20.79V18.9c0-2.82-1.1-4.41-3.44-4.41s-3.41 1.56-3.44 4.22v2.08c0 2.53.91 4.51 3.44 4.51S88 23.32 88 20.79zM107.1 25.52c-1.14 2-3 2.76-5.81 2.76-4.64 0-7.11-3-7.11-7.33v-2.21c0-4.77 2.34-7.27 6.59-7.27 4.45 0 6.55 2.66 6.55 7.47v1.78h-9.7v.07c0 2.53 1 4.51 3.54 4.51 1.91 0 2.69-.59 3.34-1.59zm-9.45-7.46h6.2c-.13-2.37-1.1-3.57-3.08-3.57s-2.95 1.36-3.12 3.57zM115.89 18.42h3.5v-1.36c0-2-.78-2.6-3.21-2.6-1.75 0-2.63.19-3.41 1.46l-2.5-1.75c1.17-1.92 2.92-2.7 6.14-2.7 4.15 0 6.39 1.3 6.39 5.62V28h-3.41v-1.5c-.78 1.26-1.78 1.78-3.76 1.78-3.57 0-5.78-1.82-5.78-5.06 0-3.47 2.57-4.8 6.04-4.8zm3.5 2.66h-3.24c-2.05 0-2.89.71-2.89 2.14s1.1 2.11 2.76 2.11c2.11 0 3.37-.65 3.37-3.47zM126 8.2h3.44v3.57h3.44v3h-3.44v7.43c0 1.88.3 2.69 2.11 2.69h1.33V28h-1.59c-3.89 0-5.29-1.33-5.29-5.16z" />
      <path
        fill="#fd5154"
        d="M157.85 11.74a3 3 0 0 0-3 3c0 4.65 6 7.51 6 7.51s6-2.86 6-7.51a3 3 0 0 0-6 0 3 3 0 0 0-3-3z"
      />
      <path
        fill="#fd5154"
        d="M174.06 9.61L161.42.23a1.12 1.12 0 0 0-1.36 0l-12.64 9.38a1.11 1.11 0 0 0-.43.88v15.62a2.17 2.17 0 0 0 2.17 2.17h23.16a2.17 2.17 0 0 0 2.17-2.17V10.49a1.11 1.11 0 0 0-.43-.88zm-2.57 15.67H150V11.44l10.75-8 10.75 8z"
      />
    </svg>
  );
}

export default IconHomebeatLogo;
