import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export const request = (state) => {
  return { ...state, title: requestState(state.title) };
};

export const resolved = (state, action) => {
  return {
    ...state,
    title: resolvedState({ ...state.title, selected: action.payload.title })
  };
};

export const rejected = (state, action) => {
  return { ...state, title: rejectedState(state.title, action.payload.error) };
};
