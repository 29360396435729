import { Button as WrButton } from "@wrstudios/components";
import { IconError, IconTrashCan } from "@wrstudios/icons";
import React, { createRef, useEffect, useRef, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useTheme } from "styled-components";
import {
  updateNetSheetItems,
  updateSubjectPropertyNote,
  updateSubjectPropertyPrice
} from "../../api/subject-property";
import { stripNumber } from "../../utils/number";
import { formatCurrentInputValue } from "../../utils/string";
import AgentNote from "../agent-note/AgentNote";
import Button from "../common/Button";
import Input from "../common/Input";
import Loading from "../common/Loading";
import Switch from "../common/Switch";
import IconCloseSlim from "../icons/IconCloseSlim";
import IconDrag from "../icons/IconDrag";
import {
  AddButton,
  AddNoteSection,
  AgentNoteHeader,
  AgentNoteLabel,
  BorderLessInput,
  ClearContainer,
  ClearLink,
  Close,
  Container,
  ControlGroup,
  DeleteLink,
  Footer,
  Header,
  HeaderDescription,
  Label,
  NetSheetHeader,
  Optional,
  Percentage,
  RangeInputContainer,
  Section,
  SheetColumnFive,
  SheetColumnFour,
  SheetColumnOne,
  SheetColumnThree,
  SheetColumnTwo,
  SheetLabel,
  SheetRow,
  SwitchText,
  Title
} from "./styled/suggested-list-price-edit";

export default function SuggestedListPriceEdit({
  report,
  subjectProperty,
  setSubjectProperty,
  onClose
}) {
  const theme = useTheme();
  const expenseNameRef = useRef();
  const [isRange, setIsRange] = useState(
    subjectProperty.priceRange.max > 0 ? true : false
  );
  const [minPrice, setMinPrice] = useState(subjectProperty.priceRange.min);
  const [maxPrice, setMaxPrice] = useState(subjectProperty.priceRange.max);
  const [price, setPrice] = useState(subjectProperty.priceRange.min);
  const netSheetItemsDefault = JSON.parse(
    JSON.stringify(subjectProperty.netSheetItems)
  );
  const [netSheetItemsState, setNetSheetItemsState] =
    useState(netSheetItemsDefault);

  const [expenseName, setExpenseName] = useState("");
  const [expensePercentage, setExpensePercentage] = useState("");
  const [expenseCost, setExpenseCost] = useState(0);
  const [note, setNote] = useState(subjectProperty.note || "");

  const [isActiveUpdate, setIsActiveUpdate] = useState(false);
  const [clearConfirm, setClearConfirm] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [netProceeds, setNetProceeds] = useState(0);
  const [rangeError, setRangeError] = useState(false);
  const deleteOutRef = createRef();

  useDetectOutClick(deleteOutRef, () => {
    setConfirmDelete(null);
  });

  useEffect(() => {
    isRange && stripNumber(maxPrice) <= stripNumber(minPrice)
      ? setRangeError(true)
      : setRangeError(false);
  }, [isRange, minPrice, maxPrice]);

  useEffect(() => {
    let t = 0;
    netSheetItemsState &&
      netSheetItemsState.map(
        (netSheetItem) => (t = t + parseFloat(netSheetItem.value))
      );

    setNetProceeds(price - t);
  }, [netSheetItemsState]);

  const handleRangeChange = () => {
    setIsRange(!isRange);
    setIsActiveUpdate(true);
  };

  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => (document.body.style.overflow = "unset");
  }, []);

  const [isUpdating, setIsUpdating] = useState(false);
  const handleUpdate = async () => {
    setIsUpdating(true);

    let p;
    !isRange
      ? (p = price)
      : stripNumber(maxPrice) > stripNumber(minPrice)
      ? (p = stripNumber(minPrice) + "-" + stripNumber(maxPrice))
      : (p = minPrice);

    updateSubjectPropertyPrice(report.id, subjectProperty.id, p);
    updateSubjectPropertyNote(report.id, subjectProperty.id, note);

    const resp = await updateNetSheetItems(
      subjectProperty.id,
      netSheetItemsState
    );

    if (resp.status === 200) {
      const tempObj = { ...subjectProperty };
      tempObj.netSheetItems = netSheetItemsState;

      if (isRange === false) {
        tempObj.priceRange.min = stripNumber(price);
        tempObj.priceRange.max = 0;
        tempObj.priceRange.sep = "";
      } else {
        tempObj.priceRange.min = stripNumber(minPrice);
        tempObj.priceRange.max = stripNumber(maxPrice);
        tempObj.priceRange.sep = "-";
      }

      tempObj.note = note;

      setSubjectProperty(tempObj);
      setIsUpdating(false);
    }
    onClose?.();
  };

  const handleClear = () => {
    setClearConfirm(true);
  };

  const confirmClear = () => {
    setNetSheetItemsState([]);
    setClearConfirm(false);
    setIsActiveUpdate(true);
  };

  const cancelClear = () => {
    setClearConfirm(false);
  };

  const handleImportLast = () => {
    setNetSheetItemsState(netSheetItemsDefault);
    setIsActiveUpdate(true);
  };

  const handleMinPriceChange = (e) => {
    setMinPrice(formatCurrentInputValue(e.target.value));
    setIsActiveUpdate(true);
  };

  const handleMaxPriceChange = (e) => {
    setMaxPrice(formatCurrentInputValue(e.target.value));
    setIsActiveUpdate(true);
  };

  const handlePriceChange = (e) => {
    setPrice(formatCurrentInputValue(e.target.value));
    setIsActiveUpdate(true);
  };

  useEffect(() => {
    stripNumber(maxPrice) > stripNumber(minPrice)
      ? setPrice((stripNumber(minPrice) + stripNumber(maxPrice)) / 2)
      : setPrice(stripNumber(minPrice));
  }, [minPrice, maxPrice]);

  const handleKeyChange = (e, index) => {
    netSheetItemsState[index].key = e.target.value;
    setIsActiveUpdate(true);
  };

  const handleValueChange = (e, index) => {
    const tempArr = [...netSheetItemsState];
    tempArr[index].value = stripNumber(e.target.value);
    setNetSheetItemsState(tempArr);
    setIsActiveUpdate(true);
  };

  const handlePercentageChange = (e, index) => {
    const tempArr = [...netSheetItemsState];
    tempArr[index].value =
      (stripNumber(price) * stripNumber(e.target.value)) / 100;
    tempArr[index].percentage = stripNumber(e.target.value);
    setNetSheetItemsState(tempArr);
    setIsActiveUpdate(true);
  };
  useEffect(() => {
    const tempArr = [...netSheetItemsState];
    for (var i = 0; i < tempArr.length; i++)
      if (tempArr[i].percentage)
        tempArr[i].value = (stripNumber(price) * tempArr[i].percentage) / 100;
    setNetSheetItemsState(tempArr);
  }, [price]);

  const handleDelete = (index) => {
    setConfirmDelete(index);
  };

  const deletePermanently = (index) => {
    const newArr = [...netSheetItemsState];
    newArr.splice(index, 1);
    setNetSheetItemsState(newArr);
    setConfirmDelete(null);
    setIsActiveUpdate(true);
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newNetSheetItemsState = reorder(
      netSheetItemsState,
      result.source.index,
      result.destination.index
    );

    setNetSheetItemsState(newNetSheetItemsState);
    setIsActiveUpdate(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const handleNewSheetItemName = (e) => {
    setExpenseName(e.target.value);
  };

  const handleNewSheetItemValue = (e) => {
    setExpenseCost(stripNumber(e.target.value));
  };

  const handleNewSheetItemPercentage = (e) => {
    setExpensePercentage(stripNumber(e.target.value));
    setExpenseCost((price * stripNumber(e.target.value)) / 100);
  };

  const addNewNetSheetItem = () => {
    const currentDateId =
      new Date().getSeconds() * new Date().getMilliseconds();

    const newNetsheetItem = {
      id: currentDateId,
      dateId: currentDateId,
      subjectPropertyId: subjectProperty.id,
      key: expenseName,
      value: expenseCost,
      percentage: expensePercentage
    };
    setNetSheetItemsState([...netSheetItemsState, newNetsheetItem]);
    setIsActiveUpdate(true);
    setExpenseName("");
    setExpenseCost("");
    setExpensePercentage("");
  };

  const handleEnterPress = (e) => {
    if (
      e.keyCode === 13 &&
      expenseName !== "" &&
      expenseCost !== "" &&
      expenseCost !== 0
    ) {
      addNewNetSheetItem();
      expenseNameRef.current.focus();
    }
  };

  const ThemedButton = theme.live ? WrButton : Button;

  return (
    <Container>
      <Header>
        <div>
          <Title>Pricing</Title>
          <HeaderDescription>
            Add a suggested list price or price range to compare the subject
            property to the comps.
          </HeaderDescription>
        </div>
        <Close type="button" onClick={onClose}>
          <IconCloseSlim fill={theme.styleGuide.colors.gray[600]} />
        </Close>
      </Header>
      <Section>
        <Label>Suggested List Price</Label>
        <ControlGroup>
          <Switch
            onChange={handleRangeChange}
            isOn={isRange}
            color={theme.live?.colors.primary}
          />
          <SwitchText>Use Price Range</SwitchText>
        </ControlGroup>
        <ControlGroup>
          <RangeInputContainer>
            {isRange ? (
              <>
                <Input
                  id="suggested-list-price-min"
                  value={formatCurrentInputValue(minPrice)}
                  onChange={(e) => handleMinPriceChange(e)}
                />
                <Input
                  id="suggested-list-price-max"
                  value={formatCurrentInputValue(maxPrice)}
                  onChange={(e) => handleMaxPriceChange(e)}
                  style={{ borderColor: rangeError ? "red" : "" }}
                />
              </>
            ) : (
              <Input
                id="suggested-list-price"
                value={formatCurrentInputValue(price)}
                onChange={(e) => handlePriceChange(e)}
              />
            )}
          </RangeInputContainer>
        </ControlGroup>
        {isRange && rangeError && (
          <div style={{ width: "100%" }}>
            <div
              style={{
                float: "right",
                width: "50%",
                paddingLeft: "0.6rem",
                color: theme.styleGuide.colors.red[600]
              }}>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <div style={{ marginTop: "-0.7rem" }}>
                  <IconError
                    style={{
                      fill: theme.styleGuide.colors.red[600],
                      height: "36px",
                      width: "36px"
                    }}
                  />
                </div>
                <div style={{ paddingLeft: "0.5rem", fontSize: "1.4rem" }}>
                  Amount should be higher than first amount.
                </div>
              </div>
            </div>
          </div>
        )}
      </Section>
      <Section>
        <NetSheetHeader>
          <Label>Net Sheet</Label>
          {netSheetItemsState.length > 0 ? (
            <ClearLink type="button" onClick={() => handleClear()}>
              Clear
            </ClearLink>
          ) : (
            <ClearLink type="button" onClick={() => handleImportLast()}>
              Import Last
            </ClearLink>
          )}
        </NetSheetHeader>
        {clearConfirm ? (
          <ClearContainer>
            <h3>Clear Net Sheet?</h3>
            <div>This will delete all the expense lines items</div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingTop: "1em"
              }}>
              <ThemedButton
                app="cma"
                type="button"
                style={{
                  margin: "0.4rem",
                  color: theme.styleGuide.colors.red[600],
                  borderColor: theme.styleGuide.colors.red[600]
                }}
                variant="secondary"
                onClick={confirmClear}>
                Yes, clear this net sheet
              </ThemedButton>
              <ClearLink
                type="button"
                style={{ margin: "0.4rem", textDecorationLine: "underline" }}
                onClick={() => cancelClear()}>
                Nevermind
              </ClearLink>
            </div>
          </ClearContainer>
        ) : (
          <>
            <SheetRow style={{ borderRadius: "5px 5px 0 0" }}>
              <SheetColumnOne />
              <SheetColumnTwo>
                <SheetLabel>Expense</SheetLabel>
              </SheetColumnTwo>
              <SheetColumnThree />
              <SheetColumnFour>
                <SheetLabel>Amount</SheetLabel>
              </SheetColumnFour>
              <SheetColumnFive></SheetColumnFive>
            </SheetRow>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={{ overflowY: "scroll", maxHeight: "22vh" }}>
                    {netSheetItemsState.map((netSheetItem, index) => (
                      <Draggable
                        key={netSheetItem.id}
                        draggableId={netSheetItem.key}
                        index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}>
                            <SheetRow>
                              <SheetColumnOne
                                style={{
                                  display: "inline"
                                }}>
                                <div {...provided.dragHandleProps}>
                                  <IconDrag
                                    style={{
                                      height: "25px",
                                      display: "inline"
                                    }}
                                  />
                                </div>
                              </SheetColumnOne>
                              <SheetColumnTwo>
                                <BorderLessInput
                                  index={index}
                                  defaultValue={
                                    netSheetItem.key ? netSheetItem.key : ""
                                  }
                                  onChange={(e) => handleKeyChange(e, index)}
                                  type="text"></BorderLessInput>
                              </SheetColumnTwo>

                              {confirmDelete === index ? (
                                <div
                                  style={{
                                    minWidth: "205px",
                                    textAlign: "right"
                                  }}>
                                  <DeleteLink
                                    type="button"
                                    ref={deleteOutRef}
                                    as="button"
                                    onClick={() => deletePermanently(index)}>
                                    Delete Permanently
                                  </DeleteLink>
                                </div>
                              ) : (
                                <>
                                  <SheetColumnThree>
                                    <BorderLessInput
                                      style={{ fontWeight: "600" }}
                                      index={index}
                                      value={
                                        netSheetItem.percentage
                                          ? netSheetItem.percentage + "%"
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handlePercentageChange(e, index)
                                      }
                                      type="text"></BorderLessInput>
                                  </SheetColumnThree>
                                  <SheetColumnFour>
                                    <BorderLessInput
                                      style={{ textAlign: "right" }}
                                      index={index}
                                      value={
                                        netSheetItem.value
                                          ? formatCurrentInputValue(
                                              Math.round(netSheetItem.value)
                                            )
                                          : ""
                                      }
                                      onChange={(e) =>
                                        handleValueChange(e, index)
                                      }
                                      type="text"></BorderLessInput>
                                  </SheetColumnFour>
                                  <SheetColumnFive>
                                    <IconTrashCan
                                      style={{
                                        fill: theme.styleGuide.colors.gray[600],
                                        height: "20px"
                                      }}
                                      onClick={() => handleDelete(index)}
                                    />
                                  </SheetColumnFive>
                                </>
                              )}
                            </SheetRow>
                          </div>
                        )}
                      </Draggable>
                    ))}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <SheetRow style={{ borderTop: "none" }}>
              <SheetColumnOne />
              <SheetColumnTwo>
                <BorderLessInput
                  placeholder="Expense Name"
                  onChange={(e) => handleNewSheetItemName(e)}
                  onKeyUp={(e) => {
                    handleEnterPress(e);
                  }}
                  ref={expenseNameRef}
                  type="text"
                  value={expenseName}></BorderLessInput>
              </SheetColumnTwo>
              <SheetColumnThree>
                <BorderLessInput
                  placeholder="0%"
                  style={{ fontWeight: "600" }}
                  value={expensePercentage ? expensePercentage + "%" : ""}
                  onChange={(e) => handleNewSheetItemPercentage(e)}
                  onKeyUp={(e) => {
                    handleEnterPress(e);
                  }}
                  type="text"></BorderLessInput>
              </SheetColumnThree>
              <SheetColumnFour>
                <BorderLessInput
                  style={{ textAlign: "right" }}
                  placeholder="$0"
                  value={formatCurrentInputValue(Math.round(expenseCost))}
                  onChange={(e) => handleNewSheetItemValue(e)}
                  onKeyUp={(e) => {
                    handleEnterPress(e);
                  }}
                  type="text"></BorderLessInput>
              </SheetColumnFour>
              <SheetColumnFive>
                {expenseCost && expenseName ? (
                  <AddButton type="button" onClick={() => addNewNetSheetItem()}>
                    Add
                  </AddButton>
                ) : (
                  ""
                )}
              </SheetColumnFive>
            </SheetRow>
            <SheetRow style={{ borderRadius: "0 0 5px 5px" }}>
              <SheetColumnOne />
              <SheetColumnTwo>
                <SheetLabel>Net Proceeds</SheetLabel>
              </SheetColumnTwo>
              <SheetColumnThree />
              <SheetColumnFour>
                <SheetLabel>
                  {formatCurrentInputValue(Math.round(netProceeds))}
                </SheetLabel>
              </SheetColumnFour>
              <SheetColumnFive />
            </SheetRow>
          </>
        )}
        <Percentage>
          Percentage based amounts are calculated based on the range's median
          price of{" "}
          <strong>{price ? formatCurrentInputValue(price) : "N/A"}</strong>.
        </Percentage>
      </Section>
      <AddNoteSection>
        <AgentNoteHeader>
          <AgentNoteLabel htmlFor="agent-note">Add a note</AgentNoteLabel>
          <Optional>Optional</Optional>
        </AgentNoteHeader>
        <AgentNote
          id="agent-note"
          note={note}
          onChange={(e) => {
            setNote(e.target.value);
            setIsActiveUpdate(true);
          }}
        />
      </AddNoteSection>
      <Footer>
        <ThemedButton
          type="button"
          app="cma"
          onClick={handleUpdate}
          disabled={
            isUpdating || (isActiveUpdate && !rangeError ? false : true)
          }>
          {isUpdating && <Loading>Saving</Loading>}
          {!isUpdating && "Save"}
        </ThemedButton>
      </Footer>
    </Container>
  );
}

function useDetectOutClick(ref, handler) {
  useEffect(() => {
    const listener = (event) => {
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);

    return () => {
      document.removeEventListener("mousedown", listener);
    };
  }, [ref, handler]);
}
