import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { pluralize } from "@wrstudios/utils";
import { errorListings } from "../../../selectors/listings";
import Flash from "../../common/Flash";
import { Container } from "./styled/listings-map-error";

function ListingMapError({ errorListings }) {
  if (!errorListings.length) return null;

  return (
    <Container>
      <Flash variant="error">
        {pluralize("Listing", errorListings.length, true)} failed to load. Id's
        that failed are: [
        {errorListings.map((listing) => listing.id).join(", ")}]
      </Flash>
    </Container>
  );
}

ListingMapError.propTypes = {
  errorListings: PropTypes.arrayOf(PropTypes.object).isRequired
};

const mapStateToProps = (state) => ({
  errorListings: errorListings(state)
});

export default connect(mapStateToProps)(ListingMapError);
