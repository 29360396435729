import { combineReducers } from "redux";
import attachments from "./attachments";
import customize from "./customize";
import listings from "./listings";
import order from "./order";
import page from "./page";
import report from "./report";
import reports from "./reports";
import subjectProperty from "./subject-property";
import user from "./user";

export default combineReducers({
  attachments,
  customize,
  listings,
  order,
  page,
  report,
  reports,
  subjectProperty,
  user
});
