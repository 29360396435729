import { IconArrowLeft } from "@wrstudios/icons";
import { pluralize } from "@wrstudios/utils";
import React, { useState, useEffect } from "react";
import { useSession } from "../../Session";
import { getReportType } from "../../utils/types";
import BasicReportCard from "../common/BasicReportCard";
import Breadcrumbs from "../common/Breadcrumbs";
import Image from "../common/Image";
import Title from "../common/Title";
import Wrapper from "../common/Wrapper";
import PublishAlerts from "./PublishAlerts";
import PublishCashOffer from "./PublishCashOffer";
import PublishShare from "./PublishShare";
import PublishActions from "./PublishActions";
import {
  Back,
  BasicCardContainer,
  CardContainer,
  CardInner,
  Container,
  ContentContainer,
  Heading,
  PageHeader,
  ShareContainer,
  ShareContentContainer
} from "./styled/publish";
import { getReportDetails } from "./utils";
import { getNewCmaUrl, useQueryString } from "../../utils/url";

function Publish({
  report,
  cash_offer: cashOffer,
  streams_availability: streams,
  transaction: existingTransaction
}) {
  const { features, currentUser } = useSession();
  const [pdfLink, setPdfLink] = useState(report.pdf_permalink || "");
  const [isPublished, setIsPublished] = useState(
    report.status === "published-report"
  );
  const reportType = getReportType(report.type);
  const reportDetails = getReportDetails(report.type);
  const image = report.thumbnail_url_with_timestamp ? (
    <Image src={report.thumbnail_url_with_timestamp} alt={report.title} />
  ) : (
    reportDetails.image
  );
  const initialShowCashOffer = Boolean(
    cashOffer.forceShowCashOffer || cashOffer.showCashOffer
  );
  const [localShowCashOffer, setLocalShowCashOffer] =
    useState(initialShowCashOffer);
  const showCashOfferIntroBack = !initialShowCashOffer && localShowCashOffer;
  const showPublishActions =
    reportType === "cmas" && (initialShowCashOffer || !localShowCashOffer);
  const showStreamsAlert =
    reportType !== "cmas" && Boolean(streams.available && streams.subscribed);
  const [transaction, setTransaction] = useState(existingTransaction);
  const address =
    report.type === "Tour"
      ? !report.listings_addresses.length
        ? "No addresses available"
        : pluralize("Properties", report.listings_addresses.length, true)
      : report.report_address_parts[0];
  const cityStateZip =
    report.type === "Tour"
      ? !report.listings_cities.length
        ? "No cities Available"
        : report.listings_cities.join(", ")
      : report.report_address_parts[1];

  const { params, removeTimestampParam } = useQueryString();
  useEffect(() => {
    if (params.ts) {
      removeTimestampParam();
    }
  }, []);

  const showTourHeader = reportType === "tours" && !!features.hasLiveTours;

  return (
    <Wrapper>
      <PageHeader>
        <Title>Publish {reportDetails.title}</Title>
      </PageHeader>
      <Breadcrumbs>
        {showTourHeader ? (
          <Breadcrumbs.Item href={`${getNewCmaUrl()}/tours/${report.id}`}>
            Tour
          </Breadcrumbs.Item>
        ) : (
          <Breadcrumbs.Item href={`/${reportType}/${report.id}/edit`}>
            Criteria
          </Breadcrumbs.Item>
        )}
        {!showTourHeader && (
          <Breadcrumbs.Item href={`/${reportType}/${report.id}`}>
            Listings
          </Breadcrumbs.Item>
        )}
        <Breadcrumbs.Item href={`/${reportType}/${report.id}/customize`}>
          Customize
        </Breadcrumbs.Item>
        <Breadcrumbs.Item>Publish</Breadcrumbs.Item>
      </Breadcrumbs>
      <Container>
        <CardContainer>
          <CardInner>
            <Heading>
              Your {reportDetails.title} is{" "}
              {isPublished ? "ready" : "publishing..."}
            </Heading>
            <BasicCardContainer>
              <BasicReportCard
                id={report.id}
                guid={report.guid}
                title={report.title}
                type={report.type}
                status={report.status}
                pdfLink={report.pdf_permalink}
                address={address}
                cityStateZip={cityStateZip}
                propertyType={report.prop_type}
                propertySubType={report.prop_sub_type}
                image={image}
                isLiveEnabled={
                  (features.hasLive && report.type === "Cma") ||
                  (currentUser.liveToursEnabled && report.type === "Tour")
                }
                liveUrl={
                  features.hasLive && report.type === "Cma"
                    ? `/live/${report.guid}`
                    : currentUser.liveToursEnabled && report.type === "Tour"
                    ? `/tours/${report.id}`
                    : undefined
                }
                isInvestorConnectEnabled={false}
                onPublish={({ status, pdfLink }) => {
                  setIsPublished(status === "published-report");
                  setPdfLink(pdfLink);
                }}
              />
            </BasicCardContainer>
            {(localShowCashOffer || showStreamsAlert || showPublishActions) && (
              <ShareContainer>
                <PublishShare report={report} pdfLink={pdfLink} />
              </ShareContainer>
            )}
            <Back href={reportDetails.url}>
              <IconArrowLeft />
              {reportDetails.urlLabel}
            </Back>
          </CardInner>
        </CardContainer>
        <ContentContainer>
          {!localShowCashOffer && !showStreamsAlert && !showPublishActions && (
            <ShareContentContainer>
              <PublishShare report={report} pdfLink={pdfLink} />
            </ShareContentContainer>
          )}
          {!localShowCashOffer && showStreamsAlert && !showPublishActions && (
            <PublishAlerts report={report} streams={streams} />
          )}
          {localShowCashOffer && (
            <PublishCashOffer
              cashOffer={cashOffer}
              report={cashOffer.report}
              leads={cashOffer.leads}
              isEligible={cashOffer.isEligible}
              showCashOfferStep={cashOffer.showCashOffer}
              showIntroBack={showCashOfferIntroBack}
              showEstimate={cashOffer.showEstimate}
              hasAcceptedOffers={cashOffer.hasAcceptedOffers}
              estimate={cashOffer.estimate}
              setLocalShowCashOffer={setLocalShowCashOffer}
            />
          )}
          {showPublishActions && (
            <PublishActions
              report={report}
              streams={streams}
              transaction={transaction}
              cashOffer={cashOffer}
              localShowCashOffer={localShowCashOffer}
              setTransaction={setTransaction}
              setLocalShowCashOffer={setLocalShowCashOffer}
            />
          )}
        </ContentContainer>
      </Container>
    </Wrapper>
  );
}

export default Publish;
