import React from "react";

function IconInvestorInterested(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 74 96"
      {...props}>
      <path
        fill="#fff"
        d="M71.78.4H2.51C1.51.4.7 1.2.7 2.2v91.4c0 1 .82 1.82 1.82 1.82h69.27c1 0 1.82-.81 1.82-1.82V2.21c0-1-.81-1.81-1.82-1.81z"
      />
      <path
        fill="#C4C8D0"
        d="M71.78 95.83H2.55A2.23 2.23 0 01.33 93.6V2.21A2.22 2.22 0 012.53 0h69.22A2.23 2.23 0 0174 2.21v91.41a2.21 2.21 0 01-2.22 2.2zM2.52.79A1.43 1.43 0 001.1 2.21v91.41a1.43 1.43 0 001.43 1.42h69.23a1.43 1.43 0 001.45-1.42V2.22A1.42 1.42 0 0071.78.78H2.52z"
      />
      <path
        fill="#20B575"
        d="M36.5 41.34v-2a4.93 4.93 0 01-2.91-1.45 4.04 4.04 0 01-1.12-2.38l2.31-.55a2.38 2.38 0 001.5 2.07c.35.13.73.2 1.12.19.5.03 1-.11 1.42-.4a1.22 1.22 0 00.5-1c0-.3-.13-.6-.35-.8-.3-.26-.66-.43-1.05-.5l-1.68-.4a4.6 4.6 0 01-2.38-1.26c-.58-.6-.9-1.42-.88-2.26A3.47 3.47 0 0134 28.1a4.54 4.54 0 012.51-1.24v-2h1.9v2.04a4.36 4.36 0 012.4 1.29 4.06 4.06 0 01.96 1.89l-2.3.65a2.08 2.08 0 00-.53-1.11c-.19-.2-.4-.34-.64-.45-.3-.13-.64-.19-.97-.18-.5-.03-.99.13-1.37.44a1.34 1.34 0 00-.48 1.02 1.1 1.1 0 00.32.8c.27.25.6.42.97.49l1.7.38c1 .16 1.91.64 2.6 1.37.53.62.81 1.42.8 2.24a3.5 3.5 0 01-.9 2.34 4.62 4.62 0 01-2.56 1.29v2.02l-1.9-.04z"
      />
      <path
        fill="#20B575"
        d="M37.15 47.21a14.2 14.2 0 01-13.11-8.69 14.08 14.08 0 013.04-15.4 14.18 14.18 0 0124.2 9.98 14.1 14.1 0 01-14.13 14.11zm0-27.44a13.4 13.4 0 00-12.38 8.2 13.3 13.3 0 009.72 18.2 13.4 13.4 0 0013.73-5.66 13.3 13.3 0 00-1.64-16.82c-2.5-2.5-5.9-3.9-9.43-3.92z"
      />
      <path
        fill="#576474"
        d="M46.1 74.98a3.38 3.38 0 00-2.97-3.1l-2.44-.3a1.12 1.12 0 01-.98-1.1v-1.42a7.58 7.58 0 002.34-5.74c0-3.72-1.4-5.93-4.9-5.93-3.5 0-4.9 2.2-4.9 5.93a7.57 7.57 0 002.3 5.7l.04 1.46a1.12 1.12 0 01-.98 1.1l-2.44.3a3.4 3.4 0 00-2.98 3.1l-.63 5.44h19.17l-.63-5.44z"
      />
    </svg>
  );
}

export default IconInvestorInterested;
