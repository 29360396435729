import React from "react";
import Icon from "./Icon";

function IconDrag(props) {
  return (
    <Icon {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 1C0 1.55 0.45 2 1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 1C4 1.55 4.45 2 5 2C5.55 2 6 1.55 6 1C6 0.45 5.55 0 5 0C4.45 0 4 0.45 4 1Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 9.55 0.45 10 1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 5C0 5.55 0.45 6 1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 9C4 9.55 4.45 10 5 10C5.55 10 6 9.55 6 9C6 8.45 5.55 8 5 8C4.45 8 4 8.45 4 9Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 5C4 5.55 4.45 6 5 6C5.55 6 6 5.55 6 5C6 4.45 5.55 4 5 4C4.45 4 4 4.45 4 5Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 13C0 13.55 0.45 14 1 14C1.55 14 2 13.55 2 13C2 12.45 1.55 12 1 12C0.45 12 0 12.45 0 13Z"
        fill="#9CA3AF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4 13C4 13.55 4.45 14 5 14C5.55 14 6 13.55 6 13C6 12.45 5.55 12 5 12C4.45 12 4 12.45 4 13Z"
        fill="#9CA3AF"
      />
    </Icon>
  );
}

export default IconDrag;
