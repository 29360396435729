import styled from "styled-components";

export const Container = styled.button`
  width: 100%;
  padding: 0;
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: 0.4rem;
  background-color: transparent;
  appearance: none;
  overflow: hidden;
  cursor: pointer;
  position: relative;
`;

export const Badge = styled.span`
  font-size: 1.2rem;
  line-height: 1.6rem;
  padding: 0 0.2rem;
  transform: translateX(-50%);
  white-space: nowrap;
  color: ${({ theme }) => theme.styleGuide.colors.white};
  background-color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
  position: absolute;
  top: 0;
  left: 50%;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;

export const Title = styled.span`
  display: block;
  font-size: 1.2rem;
  line-height: 1.6rem;
  margin-top: 1rem;
  text-align: center;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;
