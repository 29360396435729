import React, { useEffect } from "react";
import { sendInvestorEvent } from "../../api/report";
import IconInvestorInterested from "../icons/investor/IconInvestorInterested";
import IconHelpOutline from "../icons/material/action/IconHelpOutline";
import {
  Actions,
  Button,
  ButtonLink,
  Description,
  DescriptionCell,
  DescriptionGrid,
  DescriptionText,
  DescriptionTitle,
  Investor,
  Learn,
  LearnLink,
  Text,
  Title
} from "./styled/investor-disqualified";
import { getReportDetails } from "./utils";

function InvestorDisqualified({
  report,
  lead,
  previousLead,
  hasMoreLeads,
  setLead,
  setPreviousLead,
  setLeads
}) {
  const previousLeadIsOpendoor = previousLead.investor.token === "opendoor";
  const reportDetails = getReportDetails(report.type);

  const handleClick = () => {
    setLeads((leads) => {
      const [, ...newLeads] = leads;

      if (newLeads.length) {
        setLead(newLeads[0]);
        setPreviousLead(newLeads[0]);
      }

      return newLeads;
    });
  };

  useEffect(() => {
    sendInvestorEvent(report.id, lead.id, "no_qualify");
  }, []);

  return (
    <>
      <Title>Sorry, this property doesn't qualify.</Title>
      <Text>
        This property doesn’t qualify for an{" "}
        <Investor>{previousLeadIsOpendoor ? "Opendoor" : "iBuyer"}</Investor>{" "}
        offer at this time.
      </Text>
      {hasMoreLeads && (
        <Description>
          <DescriptionGrid>
            <DescriptionCell>
              <IconInvestorInterested />
            </DescriptionCell>
            <DescriptionCell>
              <DescriptionTitle>
                We have another investor interested.
              </DescriptionTitle>
              <DescriptionText>
                Properties in the area have sold to other investors as well.
                Would you like to check if other investors are interested in
                making a cash offer on this property?
              </DescriptionText>
            </DescriptionCell>
          </DescriptionGrid>
        </Description>
      )}
      <Actions>
        <ButtonLink
          app="cma"
          variant="secondary"
          hasMoreLeads={hasMoreLeads}
          href={reportDetails.url}>
          {reportDetails.urlLabel}
        </ButtonLink>
        {hasMoreLeads && (
          <Button app="cma" onClick={handleClick}>
            Request Offer
          </Button>
        )}
      </Actions>
      {previousLeadIsOpendoor && (
        <Learn>
          <IconHelpOutline /> Learn more about{" "}
          <LearnLink
            href="https://www.cloudagentsuite.com/opendoor"
            target="_blank">
            Cloud CMA and {previousLead.investor.name}
          </LearnLink>
        </Learn>
      )}
    </>
  );
}

export default InvestorDisqualified;
