import React, { Component } from "react";
import { get } from "axios";
import { translate } from "../../../utils/locale";

const KEYS = {
  38: "up",
  40: "down",
  13: "enter"
};

const Doug = {
  debounce: function (fn, context, delay) {
    var timeoutId;
    if (context == null) {
      context = null;
    }
    if (delay == null) {
      delay = 100;
    }
    timeoutId = void 0;
    return function () {
      var args;
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      args = arguments;
      return (timeoutId = setTimeout(function () {
        return fn.apply(context, args);
      }, delay));
    };
  },
  extend: function (obj1, obj2) {
    var key, result;
    result = {};
    for (key in obj1) {
      result[key] = obj1[key];
    }
    for (key in obj2) {
      result[key] = obj2[key];
    }
    return result;
  }
};

const AddressAutoCompleteStyle = {
  margin: ""
};

const AddressListItemStyle = {
  color: "#596473",
  margin: 0,
  padding: "8px 16px",
  boxSizing: "border-box",
  cursor: "pointer",
  fontFamily:
    '-apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Open Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif',
  fontSize: "15px",
  lineHeight: "20px"
};

const AddressListStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  backgroundColor: "white",
  width: "100%",
  listStyle: "none",
  margin: 0,
  boxShadow: "0 2px 10px 0 rgba(0,0,0,0.15)",
  borderRadius: "0 0 3px 3px",
  overflow: "hidden",
  transition: "height 1s linear",
  zIndex: 100,
  padding: 0
};

const AddressInputStyle = {
  width: "100%"
};

class AddressListItem_wmhw extends Component {
  handleMouseDown() {
    return this.props.selectItem(this.props.itemData);
  }

  highlight() {
    return this.props.highlightItem(this.props.index);
  }

  render() {
    var styleDiff;
    styleDiff = {};
    if (this.props.highlighted) {
      styleDiff.backgroundColor = "#20b575";
      styleDiff.color = "white";
    } else {
      styleDiff.backgroundColor = "white";
      styleDiff.color = "#666666";
    }

    return (
      <li
        style={Doug.extend(AddressListItemStyle, styleDiff)}
        onMouseDown={this.handleMouseDown.bind(this)}
        onMouseEnter={this.highlight.bind(this)}
        onMouseLeave={this.props.resetHighlight}>
        {this.props.itemData.value || this.props.itemData.address}
      </li>
    );
  }
}

class AddressList_wmhw extends Component {
  selectItem(item) {
    return this.props.selectAddress(item);
  }

  highlightItem(index) {
    return this.props.highlightItem(index);
  }

  renderOptions() {
    const { options, highlightedIndex, resetHighlight } = this.props;

    if (!Array.isArray(options)) {
      options.coordinates = [options.lon, options.lat];

      return (
        <AddressListItem_wmhw
          index={0}
          itemData={options}
          highlighted={true}
          highlightItem={this.highlightItem.bind(this)}
          resetHighlight={resetHighlight}
          selectItem={this.selectItem.bind(this)}
        />
      );
    }

    return options.map((option, i) => (
      <AddressListItem_wmhw
        key={i}
        index={i}
        itemData={option}
        highlighted={i === highlightedIndex}
        highlightItem={this.highlightItem.bind(this)}
        resetHighlight={resetHighlight}
        selectItem={this.selectItem.bind(this)}
      />
    ));
  }

  render() {
    var styleDiff;
    styleDiff = {};
    if (this.props.options.length === 0) {
      styleDiff.borderTop = "none";
    } else {
      styleDiff.borderTop = "1px solid #E1E5E9";
    }
    return (
      <ul style={Doug.extend(AddressListStyle, styleDiff)}>
        {this.renderOptions()}
      </ul>
    );
  }
}

class AddressInput_wmhw extends Component {
  handleChange(event) {
    return this.props.updateQuery(event.target.value);
  }

  handleFocus(event) {
    return this.props.updateQuery(event.target.value);
  }

  handleBlur() {
    return this.props.clearSuggestions();
  }

  handleKeyDown(e) {
    if (KEYS[e.keyCode] === "enter") {
      e.preventDefault();
      return this.props.selectHighlighted();
    } else if (e.keyCode in KEYS) {
      return this.props.traverseOptions(KEYS[e.keyCode]);
    }
  }

  render() {
    return (
      <input
        style={AddressInputStyle}
        type="text"
        onChange={this.handleChange.bind(this)}
        onFocus={this.handleFocus.bind(this)}
        onBlur={this.handleBlur.bind(this)}
        onKeyDown={this.handleKeyDown.bind(this)}
        placeholder="Enter your property address"
        name="cma[address]"
        id="cma_address"
        autoComplete="off"
        value={this.props.queryText}
      />
    );
  }
}

class HiddenLatLonInputs extends Component {
  render() {
    return (
      <div className="sp-params">
        <input
          value={this.props.lat}
          name="subject_property[geo_lat]"
          id="subject_property_geo_lat"
          type="hidden"
        />
        <input
          value={this.props.lon}
          name="subject_property[geo_lon]"
          id="subject_property_geo_lon"
          type="hidden"
        />
      </div>
    );
  }
}

class AddressAutoComplete_wmhw extends Component {
  constructor(props) {
    super(props);

    this.state = {
      options: [],
      query: props.address || "",
      highlightedIndex: -1,
      selected: void 0,
      hasSelected: false,
      coordinates: ["", ""]
    };
  }
  UNSAFE_componentWillMount() {
    return (this.getResults = Doug.debounce(this.getResults, this, 600));
  }
  getResults() {
    if (this.state.query) {
      get("/api/geocode", {
        params: {
          query: this.state.query,
          guid: this.props.guid
        }
      })
        .then((res) => res.data)
        .then((data) => {
          this.setState({
            options: data
          });

          this.resetHighlight();
        })
        .catch((err) => console.error("ERROR:", err));
    } else {
      this.setState({
        options: []
      });
    }
  }
  updateQuery(query) {
    this.setState(
      {
        query: query,
        hasSelected: false
      },
      () => this.getResults()
    );
  }
  clearOptions() {
    return this.setState({
      options: []
    });
  }
  setHighlighted(index) {
    return this.setState({
      highlightedIndex: index
    });
  }
  resetHighlight() {
    return this.setState({
      highlightedIndex: -1
    });
  }
  traverseItems(direction) {
    var newIndex;
    newIndex = void 0;
    switch (direction) {
      case "up":
        newIndex = this.state.highlightedIndex - 1;
        break;
      case "down":
        newIndex = this.state.highlightedIndex + 1;
    }
    if (newIndex > this.state.options.length - 1) {
      newIndex = 0;
    } else if (newIndex < 0) {
      newIndex = this.state.options.length - 1;
    }
    return this.setHighlighted(newIndex);
  }
  lookupAddress() {
    var addressQueryString;
    addressQueryString = this.state.query.replace(/\s\s+/g, "+");
    return jQuery.ajax({
      url: "/api/geocode",
      data: {
        address: addressQueryString,
        guid: this.props.guid
      },
      method: "get",
      success: (function (_this) {
        return function (res) {
          if (res === null || res.quality < 0.8) {
            return alert(
              `No ${translate(
                "mls.label"
              )} listings found. Check the address - make sure you're including city/state or zip - and try again.`
            );
          } else {
            _this.selectAddress({
              coordinates: [res.lon, res.lat],
              value: res.address,
              options: []
            });
            return $("#find_property_button").trigger("click");
          }
        };
      })(this),
      error: function () {
        return alert(
          `No ${translate(
            "mls.label",
            "MLS"
          )} listings found. Check the address - make sure you're including city/state or zip - and try again.`
        );
      }
    });
  }
  selectAddress(addressObj) {
    return this.setState({
      selected: addressObj,
      coordinates: addressObj.coordinates,
      hasSelected: true,
      query: addressObj.value,
      options: []
    });
  }
  selectHighlighted() {
    if (this.state.highlightedIndex < 0) {
      return this.lookupAddress();
    } else {
      if (this.state.hasSelected) {
        return $("#find_property_button").trigger("click");
      } else {
        return this.selectAddress(
          this.state.options[this.state.highlightedIndex]
        );
      }
    }
  }
  render() {
    return (
      <div style={AddressAutoCompleteStyle}>
        <AddressInput_wmhw
          updateQuery={this.updateQuery.bind(this)}
          clearSuggestions={this.clearOptions.bind(this)}
          queryText={this.state.query}
          traverseOptions={this.traverseItems.bind(this)}
          selectHighlighted={this.selectHighlighted.bind(this)}
        />
        <AddressList_wmhw
          options={this.state.options}
          selectAddress={this.selectAddress.bind(this)}
          highlightedIndex={this.state.highlightedIndex}
          highlightItem={this.setHighlighted.bind(this)}
          resetHighlight={this.resetHighlight.bind(this)}
        />
        <HiddenLatLonInputs
          lat={this.state.coordinates[1]}
          lon={this.state.coordinates[0]}
        />
      </div>
    );
  }
}

export default AddressAutoComplete_wmhw;
