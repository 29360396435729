import styled from "styled-components";

export const Badge = styled.div`
  font-weight: 600;
  font-size: 1.2rem;
  line-height: 1.6rem;
  letter-spacing: 0.04em;
  text-transform: uppercase;
  color: #4b5563;
  margin-top: 3.2rem;
  margin-bottom: -0.8rem;

  ${({ theme }) => theme.media.sm`
    font-size: 1.3rem;
    line-height: 1.7rem;
  `}
`;
