import React, { useState } from "react";
import { addCustomPage } from "../../api/user";
import { useSession } from "../../Session";
import FormGroup from "../common/FormGroup";
import Label from "../common/Label";
import Input from "../common/Input";
import Flash from "../common/Flash";
import Loading from "../common/Loading";
import UserCustomPagesEdit from "./UserCustomPagesEdit";
import { InputContainer, Button } from "./styled/user-custom-pages-add";

function UserCustomPagesAdd() {
  const { currentUser, setCustomPages } = useSession();
  const [pageKey, setPageKey] = useState("");
  const [title, setTitle] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });
  const isDisabled = !title || request.loading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setRequest({ loading: true, success: false, error: null });
      const page = await addCustomPage(currentUser.id, { title, key: title });
      setRequest({ loading: false, success: true, error: null });
      setTitle("");
      setCustomPages((customPages) => [
        ...customPages,
        {
          id: page.id,
          key: page.key,
          title: page.title || page.key,
          content: "",
          isDefault: false
        }
      ]);
      setPageKey(page.key);
      setIsEditing(true);
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error: error.originalResponse.data.error
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Label htmlFor="page-title">Page Title</Label>
        <InputContainer>
          <Input
            id="page-title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
          <Button app="cma" disabled={isDisabled}>
            {!request.loading && "Add"}
            {request.loading && <Loading>Adding</Loading>}
          </Button>
        </InputContainer>
        {request.error && (
          <FormGroup>
            <Flash variant="error">{request.error}</Flash>
          </FormGroup>
        )}
      </form>
      {isEditing && (
        <UserCustomPagesEdit
          pageKey={pageKey}
          onClose={() => setIsEditing(false)}
        />
      )}
    </>
  );
}

export default UserCustomPagesAdd;
