import React from "react";
import PropTypes from "prop-types";
import Label from "../common/Label";
import LinkToggler from "../togglers/LinkToggler";
import IconArrowDropdown from "../icons/material/navigation/IconArrowDropdown";
import MoreCriteriaMin from "./MoreCriteriaMin";
import MoreCriteriaMax from "./MoreCriteriaMax";

function MoreCriteria({ open, title, tip, options }) {
  return (
    <LinkToggler
      open={open}
      title={title}
      tip={tip}
      buttonAriaLabel="More Criteria">
      {options.map(({ label, min, max }, index) => (
        <div className="w-1/2 p-4 more-criteria-option" key={index}>
          <Label htmlFor={`option-min-${index}`}>{label}</Label>
          <div className="flex flex-col sm:flex-row items-center">
            <div className="w-full">
              <MoreCriteriaMin
                name={min.name}
                type={min.type}
                value={min.value}
                selected={min.selected}
                index={index}
              />
            </div>
            <div className="mx-4">
              <span className="hidden sm:inline-block">-</span>
              <span className="text-grey inline-block sm:hidden">
                <IconArrowDropdown width={24} height={24} />
              </span>
            </div>
            <div className="w-full">
              <MoreCriteriaMax
                name={max.name}
                type={max.type}
                value={max.value}
                selected={max.selected}
                index={index}
              />
            </div>
          </div>
        </div>
      ))}
    </LinkToggler>
  );
}

MoreCriteria.propTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  tip: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      min: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool
        ]),
        selected: PropTypes.object
      }),
      max: PropTypes.shape({
        name: PropTypes.string.isRequired,
        type: PropTypes.string,
        value: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
          PropTypes.bool
        ]),
        selected: PropTypes.object
      })
    })
  )
};

MoreCriteria.defaultProps = {
  open: false
};

export default MoreCriteria;
