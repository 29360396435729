import React from "react";

function IconPhoto() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 2.25C2.0335 2.25 1.25 3.0335 1.25 4V16C1.25 16.9665 2.0335 17.75 3 17.75H17C17.9665 17.75 18.75 16.9665 18.75 16V4C18.75 3.0335 17.9665 2.25 17 2.25H3ZM2.75 4C2.75 3.86193 2.86193 3.75 3 3.75H17C17.1381 3.75 17.25 3.86193 17.25 4V10.7979L14.4881 8.43056C14.183 8.16902 13.7259 8.19447 13.4517 8.48826L6.45171 15.9883C6.37897 16.0662 6.32604 16.1555 6.29269 16.25H3C2.86193 16.25 2.75 16.1381 2.75 16V4ZM17.0119 12.5694C17.0843 12.6315 17.1652 12.6774 17.25 12.7073V16C17.25 16.1381 17.1381 16.25 17 16.25H8.25925L14.0578 10.0373L17.0119 12.5694ZM6.5 9C7.32843 9 8 8.32843 8 7.5C8 6.67157 7.32843 6 6.5 6C5.67157 6 5 6.67157 5 7.5C5 8.32843 5.67157 9 6.5 9Z"
      />
    </svg>
  );
}

export default IconPhoto;
