import styled from "styled-components";

export const Grid = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -1.5rem;
`;

export const Cell = styled.div`
  width: calc(100% / 3);
  padding: 1.5rem;

  ${({ theme }) => theme.media.lg`
    width: 50%;
  `};

  ${({ theme }) => theme.media.sm`
    width: 100%
  `};
`;
