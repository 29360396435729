import React from "react";

function IconLoading({ children }) {
  return (
    <div className="icon-loading">
      <div className="spinner">
        <div className="circle circle-1">
          <div className="circle-inner" />
        </div>
        <div className="circle circle-2">
          <div className="circle-inner" />
        </div>
      </div>
      <div className="icon-loading-content">{children}</div>
    </div>
  );
}

export default IconLoading;
