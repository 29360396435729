import styled from "styled-components";
import BaseHomebeatViewerAgent from "../HomebeatViewerAgent";

export const Container = styled.div`
  flex-grow: 1;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  position: relative;
`;

export const HomebeatViewerAgent = styled(BaseHomebeatViewerAgent)`
  right: 8rem;
  bottom: 2.4rem;
`;
