import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import Loading from "../common/Loading";
import {
  Container,
  Action,
  Label,
  PageCount,
  Form,
  Controls,
  Cancel,
  Save
} from "./styled/customize-modal-template-card";

class CustomizeModalTemplateCreate extends Component {
  state = {
    name: "",
    showForm: false,
    showSuccess: false,
    isFocused: false
  };

  render() {
    const { activePageCount, isActive, isFetching, hasError } = this.props;
    const { name, showForm, showSuccess, isFocused } = this.state;
    const isDisabled = !this.state.name;

    return (
      <Container isActive={isActive || isFocused} hasError={hasError}>
        {!showForm && (
          <Action
            autoFocus
            onClick={this.handleOnClick}
            onFocus={this.handleOnFocus}
            onBlur={this.handleOnBlur}>
            <Label>Save as Template</Label>
            <PageCount>
              {activePageCount} page{activePageCount === 1 ? "" : "s"}
            </PageCount>
          </Action>
        )}
        {showForm && (
          <Form
            isFetchingOrSuccess={isFetching || showSuccess}
            showSingleMessage={isFetching || showSuccess || hasError}
            onSubmit={this.handleOnSubmit}
            onKeyDown={this.handleOnKeyDown}>
            {!isFetching && !showSuccess && !hasError && (
              <Input
                autoFocus
                placeholder="My New Template"
                value={name}
                onChange={this.handleOnChange}
              />
            )}
            <Controls showSingleMessage={isFetching || showSuccess || hasError}>
              {!showSuccess && !hasError && (
                <>
                  {!isFetching && (
                    <>
                      <Cancel type="button" onClick={this.handleOnCancel}>
                        Cancel
                      </Cancel>
                      <Save disabled={isDisabled}>Save</Save>
                    </>
                  )}
                  {isFetching && <Loading>Saving Template</Loading>}
                </>
              )}
              {showSuccess && "Template Saved!"}
              {hasError && "An error has occurred, please try again later."}
            </Controls>
          </Form>
        )}
      </Container>
    );
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.hasFetched && this.props.hasFetched) {
      if (!prevProps.isRenaming && !prevProps.isDeleting) {
        this.props.changeTab(2);
      }

      this.setState({ name: "", showSuccess: true }, () => {
        setTimeout(() => {
          if (this._isUnmounted) return;

          this.setState({ showSuccess: false, showForm: false });
        }, 2000);
      });
    }
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  handleOnClick = () => {
    this.setState({ showForm: true });
  };

  handleOnChange = (e) => {
    this.setState({ name: e.target.value });
  };

  handleOnKeyDown = (e) => {
    if (e.key === "Escape") {
      e.preventDefault();
      e.stopPropagation();
      this.setState({ showForm: false, name: "" });
    }
  };

  handleOnSubmit = (e) => {
    e.preventDefault();

    if (!this.state.name) return;

    const key = (
      this.props.templates.find((template) => {
        return (template.label || "").toLowerCase() === this.state.name;
      }) || {}
    ).value;

    this.props.saveTemplate(
      this.props.reportId,
      this.props.reportType,
      { title: this.state.name, key },
      this.props.pages
    );
  };

  handleOnCancel = () => {
    this.setState({ showForm: false });
  };

  handleOnFocus = () => {
    this.setState({ isFocused: true });
  };

  handleOnBlur = () => {
    this.setState({ isFocused: false });
  };
}

CustomizeModalTemplateCreate.propTypes = {
  reportId: PropTypes.number.isRequired,
  reportType: PropTypes.string.isRequired,
  pages: PropTypes.array.isRequired,
  activePageCount: PropTypes.number.isRequired,
  isActive: PropTypes.bool.isRequired,
  isRenaming: PropTypes.bool.isRequired,
  isDeleting: PropTypes.bool.isRequired,
  isFetching: PropTypes.bool.isRequired,
  hasFetched: PropTypes.bool.isRequired,
  hasError: PropTypes.bool.isRequired,
  changeTab: PropTypes.func.isRequired,
  saveTemplate: PropTypes.func.isRequired
};

export default CustomizeModalTemplateCreate;
