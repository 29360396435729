import { useEffect, useState } from "react";
import { getBraintreeToken } from "../../api/braintree";

export function useBraintreeToken() {
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState();

  useEffect(() => {
    getBraintreeToken()
      .then((data) => setData(data.token))
      .catch(setError)
      .finally(() => setIsLoading(false));
  }, []);

  return { data, isLoading, error };
}
