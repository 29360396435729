class ConfettiParticle {
  static colors = [
    "DodgerBlue",
    "OliveDrab",
    "Gold",
    "Pink",
    "SlateBlue",
    "LightBlue",
    "Gold",
    "Violet",
    "PaleGreen",
    "SteelBlue",
    "SandyBrown",
    "Chocolate",
    "Crimson"
  ];

  constructor(width, height, particleCount = 40) {
    this.x = Math.random() * width;
    this.y = Math.random() * height - height;
    this.r = this.randomFromTo(11, 33);
    this.d = Math.random() * particleCount + 2;
    this.color =
      ConfettiParticle.colors[
        Math.floor(Math.random() * ConfettiParticle.colors.length)
      ];
    this.tilt = Math.floor(Math.random() * 33) - 11;
    this.tiltAngle = 0;
    this.tiltAngleIncremental = Math.random() * 0.07 + 0.05;
    this.outOfBounds = false;
  }

  randomFromTo(from, to) {
    return Math.floor(Math.random() * (to - from + 1) + from);
  }

  draw(context) {
    context.beginPath();
    context.lineWidth = this.r / 6;
    context.strokeStyle = this.color;
    // context.globalAlpha = 0.6
    context.moveTo(this.x + this.tilt + this.r / 3, this.y);
    context.lineTo(this.x + this.tilt, this.y + this.tilt + this.r / 5);

    return context.stroke();
  }
}

export default ConfettiParticle;
