import React from "react";
import { Braintree, HostedField } from "react-braintree-fields";
import { HostedFieldWrapper } from "../common/styled/input";
import OrderDiscount from "./OrderDiscount";
import { Cell, Error, Grid, Label } from "./styled/order-form";

export default function OrderCardForm({
  btToken,
  sku,
  discount,
  showDiscount = true,
  onDiscountChange,
  setGetToken,
  error,
  setError
}) {
  return (
    <Braintree
      authorization={btToken}
      getTokenRef={(getToken) => setGetToken(() => getToken)}
      onError={setError}>
      <Grid>
        <Cell>
          <Label htmlFor="order-first-name">Name on card</Label>
          <HostedFieldWrapper>
            <HostedField
              type="cardholderName"
              placeholder="John Doe"
              id="order-first-name"
            />
          </HostedFieldWrapper>
          {error?.details?.invalidFieldKeys?.includes("cardholderName") && (
            <Error>Invalid name</Error>
          )}
        </Cell>
        <Cell />
        <Cell>
          <Label htmlFor="order-credit-card-number">Card number</Label>
          <HostedFieldWrapper>
            <HostedField
              type="number"
              placeholder="5555 5555 5555 5555"
              id="order-credit-card-number"
            />
          </HostedFieldWrapper>
          {error?.details?.invalidFieldKeys?.includes("number") && (
            <Error>Invalid card number</Error>
          )}
        </Cell>
        <Cell>
          <Grid>
            <Cell>
              <Label htmlFor="order-credit-card-expiration">
                Card expiration
              </Label>
              <HostedFieldWrapper>
                <HostedField
                  type="expirationDate"
                  placeholder={`12 / ${new Date().getUTCFullYear()}`}
                  id="order-credit-card-expiration"
                />
              </HostedFieldWrapper>
              {error?.details?.invalidFieldKeys?.includes("expirationDate") && (
                <Error>Invalid expiration</Error>
              )}
            </Cell>
            <Cell>
              <Label htmlFor="order-credit-card-cvv">Card CVV</Label>
              <HostedFieldWrapper>
                <HostedField
                  type="cvv"
                  placeholder="123"
                  id="order-credit-card-cvv"
                />
              </HostedFieldWrapper>
              {error?.details?.invalidFieldKeys?.includes("cvv") && (
                <Error>Invalid CVV</Error>
              )}
            </Cell>
          </Grid>
        </Cell>
        <Cell>
          <Label htmlFor="order-credit-card-postal-code">
            Card postal code
          </Label>
          <HostedFieldWrapper>
            <HostedField
              type="postalCode"
              placeholder="12345"
              id="order-credit-card-postal-code"
            />
          </HostedFieldWrapper>
          {error?.details?.invalidFieldKeys?.includes("postalCode") && (
            <Error>Invalid postal code</Error>
          )}
        </Cell>
        {showDiscount && (
          <Cell>
            <OrderDiscount
              discount={discount}
              sku={sku}
              onChange={(e) => onDiscountChange(e.target.value)}
            />
          </Cell>
        )}
      </Grid>
    </Braintree>
  );
}
