import React from "react";

function IconNoCover() {
  return (
    <svg
      width="19"
      height="22"
      viewBox="0 0 19 22"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-34.000000, -84.000000)">
          <g transform="translate(12.000000, 10.000000)">
            <g transform="translate(20.000000, 73.000000)">
              <g>
                <polygon points="0 0 24 0 24 24 0 24" />
                <path
                  d="M16,1 L4,1 C2.9,1 2,1.9 2,3 L2,17 L4,17 L4,3 L16,3 L16,1 L16,1 Z M19,5 L8,5 C6.9,5 6,5.9 6,7 L6,21 C6,22.1 6.9,23 8,23 L19,23 C20.1,23 21,22.1 21,21 L21,7 C21,5.9 20.1,5 19,5 L19,5 Z M19,21 L8,21 L8,7 L19,7 L19,21 L19,21 Z"
                  fill="#89929D"
                />
                <polygon
                  fill="#89929D"
                  points="16.5 12 14.5 14 16.5 16 15.5 17 13.5 15 11.5 17 10.5 16 12.5 14 10.5 12 11.5 11 13.5 13 15.5 11"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconNoCover;
