import parser from "vcard-parser";

export function parseVCard(vCard) {
  const card = parser.parse(vCard);

  const name = ((card.fn || [])[0] || {}).value || "";
  const email = ((card.email || [])[0] || {}).value || "";
  const [organization] = ((card.org || [])[0] || {}).value || [""];
  const [, , street, city, state, zip] =
    ((card.adr || [])[0] || {}).value || [];
  const address = [street, city, [state, zip].filter(Boolean).join(" ")]
    .filter(Boolean)
    .join(", ");
  const phone = ((card.tel || [])[0] || {}).value || "";
  const photo = ((card.photo || [])[0] || {}).value || "";
  const url = ((card.url || [])[0] || {}).value || "";

  return {
    card,
    name,
    email,
    organization,
    address,
    phone,
    photo,
    url
  };
}
