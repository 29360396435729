import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return requestState({ ...state, filter: "update" });
}

export function resolved(state, action) {
  const error = action.payload.error_msg;

  if (error) {
    return rejectedState(state, { message: error });
  }

  const listing = { ...action.payload };
  const all = ((state.all && [...state.all]) || []).map((allListing) => {
    if (allListing.id === listing.id) {
      allListing = { ...listing };
    }

    return allListing;
  });

  return resolvedState({ ...state, all });
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
