import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export const request = (state) => {
  return { ...state, description: requestState(state.description) };
};

export const resolved = (state, action) => {
  return {
    ...state,
    description: resolvedState({
      ...state.description,
      value: action.payload.description
    })
  };
};

export const rejected = (state, action) => {
  return {
    ...state,
    description: rejectedState(state.description, action.payload.error)
  };
};
