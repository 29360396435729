import styled, { createGlobalStyle } from "styled-components";
import { Text as BaseText } from "../../../styles/Text";
import { Button as BaseButton } from "../../../styles/Button";
import { hasClass } from "../../../utils/page";
import { default as BaseConfetti } from "../../particles/Confetti";
import IconPartyPopper from "../../icons/IconPartyPopper";

export const Container = styled.div`
  overflow: hidden;
`;

export const Confetti = styled(BaseConfetti)`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
`;

export const Content = styled.div`
  max-width: 29.3rem;
  margin: 0 auto;
  text-align: center;
  position: relative;
  z-index: 2;
`;

export const Icon = styled(IconPartyPopper).attrs({
  width: hasClass("body", ["windows"]) ? 80 : 64,
  height: hasClass("body", ["windows"]) ? 80 : 64
})`
  margin: 5rem auto 4rem;

  ${({ theme }) => theme.media.sm`
    margin: 3rem auto 4rem;
  `};
`;

export const GlobalStyle = createGlobalStyle`
.windows {
  ${Icon} {
    margin: 2rem auto 3rem;
  }
}
`;

export const Text = styled(BaseText)`
  font-size: 1.8rem;
  font-weight: 500;
  line-height: 2.4rem;
  margin: 2rem auto 4rem;
  color: ${({ theme }) => theme.colors.purpleDark};
`;

export const Confirm = styled(BaseButton)`
  font-size: 1.6rem;
  margin-bottom: 2rem;
  border-color: ${({ theme }) => theme.colors.purpleDark};
  border-bottom-color: ${({ theme }) => theme.colors.purpleDarker};
  background-color: ${({ theme }) => theme.colors.purpleDark};

  &:hover,
  &:active,
  &:focus,
  &[disabled]:hover,
  &[disabled]:active,
  &[disabled]:focus {
    border-color: ${({ theme }) => theme.colors.purpleDark};
    border-bottom-color: ${({ theme }) => theme.colors.purpleDarker};
    background-color: ${({ theme }) => theme.colors.purpleDark};
  }
`;
