import { Modal } from "@wrstudios/components";
import React, { useEffect, useState } from "react";
import { get } from "lodash";
import { useSession } from "../../Session";
import Button from "../common/Button";
import FormGroup from "../common/FormGroup";
import Input from "../common/Input";
import Label from "../common/Label";
import Loading from "../common/Loading";
import IconError from "../icons/IconError";

import {
  connectZF,
  connectTD,
  createTransactionTD,
  createTransactionZF
} from "./api";
import {
  ModalHeader,
  ModalContent,
  ModalBody,
  ModalBodyRow,
  ModalFooter,
  Instructions,
  Title,
  TMCards,
  TMCard,
  TMCardIcon,
  FooterLink,
  FooterLinkContainer,
  ReportInfoCard,
  TMIconCard,
  IconTransactionContainer,
  Error,
  FooterTipLeft,
  FooterTipBottom,
  ModalFooterRow,
  FooterActions,
  ForgotPassword
} from "./styled/transaction-wizard";
import IconTransaction from "../icons/IconTransaction";
import IconTransactionDeskVert from "../icons/IconTransactionDeskVert";
import IconTransactionDeskHoriz from "../icons/IconTransactionDeskHoriz";
import IconZipFormVert from "../icons/IconZipFormVert";
import IconZipFormHoriz from "../icons/IconZipFormHoriz";
import { usePath, useQueryString } from "../../utils/url";

const STEPS = {
  CHOOSE_TRANSACTION_MANAGER: 0,
  CONNECT_ZF: 1,
  CONNECT_TD: 2,
  CREATE_TRANSACTION_ZF: 3,
  CREATE_TRANSACTION_TD: 4
};

const LINKS = {
  LEARN_MORE: "https://www.lwolf.com/member-benefits",
  ZF_FORGOT_PASS: "https://zipformplus.com",
  ZF_CREATE_ACCOUNT: "https://www.zipform.com/order/order.asp"
};

function TransactionWizard({ report, transaction, setTransaction, onClose }) {
  const { currentUser } = useSession();
  const { params, updateSearchQuery } = useQueryString();
  const transactionType = get(transaction, "type");

  let initialStep = STEPS.CHOOSE_TRANSACTION_MANAGER;
  if (params.td_create_report_id) {
    initialStep = STEPS.CREATE_TRANSACTION_TD;
  } else if (transactionType === "ZipForm" && !currentUser.zipFormToken) {
    initialStep = STEPS.CONNECT_ZF;
  } else if (
    transactionType === "TransactionDesk" &&
    !currentUser.transactionDeskToken
  ) {
    initialStep = STEPS.CONNECT_TD;
  } else if (currentUser.zipFormToken && !currentUser.transactionDeskToken) {
    initialStep = STEPS.CREATE_TRANSACTION_ZF;
  } else if (!currentUser.zipFormToken && currentUser.transactionDeskToken) {
    initialStep = STEPS.CREATE_TRANSACTION_TD;
  }
  // if has none => choose/connect
  // If has zipForm creds => create transaction type: zipForm
  // If has TransactionDesk session => create transaction type: transactionDesk
  // If has both => choose/create
  const [step, setStep] = useState(initialStep);

  useEffect(() => {
    if (params.td_create_report_id) {
      updateSearchQuery({ td_create_report_id: null }, { replace: true });
    }
  }, []);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        {step === STEPS.CHOOSE_TRANSACTION_MANAGER && (
          <TransactionChooseTM report={report} setStep={setStep} />
        )}
        {step === STEPS.CONNECT_ZF && (
          <TransactionConnectZF setStep={setStep} />
        )}
        {step === STEPS.CONNECT_TD && <TransactionConnectTD report={report} />}
        {step === STEPS.CREATE_TRANSACTION_ZF && (
          <TransactionCreateZF
            report={report}
            transaction={transaction}
            setTransaction={setTransaction}
            onClose={onClose}
          />
        )}
        {step === STEPS.CREATE_TRANSACTION_TD && (
          <TransactionCreateTD
            report={report}
            transaction={transaction}
            setTransaction={setTransaction}
            onClose={onClose}
          />
        )}
      </ModalContent>
    </Modal>
  );
}

function TransactionChooseTM({ setStep, report }) {
  const { currentUser } = useSession();
  const { path } = usePath();
  const returnPath = path.includes("publish_option")
    ? "publish_option"
    : "cmas";
  const hasBoth = currentUser.zipFormToken && currentUser.transactionDeskToken;

  return (
    <>
      <ModalHeader>
        <Title>Select Your Transaction Solution</Title>
        <Modal.Close />
      </ModalHeader>
      <Instructions>
        {`${
          hasBoth ? "Choose" : "Connect"
        } the solution you use today to link your CMAs and Transactions.`}
      </Instructions>
      <ModalBody>
        <TMCards>
          <TMCard>
            <TMCardIcon>
              <IconZipFormVert />
            </TMCardIcon>
            {hasBoth && (
              <Button
                app="cma"
                variant="secondary"
                onClick={() => setStep(STEPS.CREATE_TRANSACTION_ZF)}>
                Choose Transactions (zipForm Edition)
              </Button>
            )}
            {!hasBoth && (
              <Button
                app="cma"
                variant="secondary"
                onClick={() => setStep(STEPS.CONNECT_ZF)}>
                Connect Transactions (zipForm Edition)
              </Button>
            )}
          </TMCard>
          <TMCard>
            <TMCardIcon>
              <IconTransactionDeskVert />
            </TMCardIcon>
            {hasBoth && (
              <Button
                app="cma"
                variant="secondary"
                onClick={() => setStep(STEPS.CREATE_TRANSACTION_TD)}>
                Choose Transactions (TransactionDesk Edition)
              </Button>
            )}
            {!hasBoth && (
              <Button
                app="cma"
                variant="secondary"
                onClick={() =>
                  connectTD(currentUser.id, report.id, returnPath)
                }>
                Connect Transactions (TransactionDesk Edition)
              </Button>
            )}
          </TMCard>
        </TMCards>
      </ModalBody>
      <ModalFooter align="center">
        <FooterLinkContainer>
          <FooterLink href={LINKS.LEARN_MORE}>
            Learn more more about Lone Wolf Transactions
          </FooterLink>
        </FooterLinkContainer>
      </ModalFooter>
    </>
  );
}

function TransactionConnectZF({ setStep }) {
  const { currentUser, setCurrentUser } = useSession();
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [network, setNetwork] = useState({
    isFetching: false,
    hasFetched: false,
    error: false
  });

  function handleZipFormLoginSubmit(e) {
    e.preventDefault();
    handleZipFormLogin();
  }

  async function handleZipFormLogin() {
    setNetwork({ isFetching: true, hasFetched: false, error: "" });
    try {
      const response = await connectZF(currentUser.id, username, password);
      setCurrentUser({ ...currentUser, zipFormToken: response.contextId });
      setNetwork({ isFetching: false, hasFetched: true, error: "" });
      setStep(STEPS.CREATE_TRANSACTION_ZF);
    } catch (error) {
      console.error("ZipForm Login:", error);
      setNetwork({
        isFetching: false,
        hasFetched: false,
        error: error.errors || "Sorry, something went wrong..."
      });
    }
  }
  return (
    <>
      <ModalHeader>
        <Title>Transactions (zipForm Edition)</Title>
        <Modal.Close />
      </ModalHeader>
      <Instructions>
        Please enter your username and password for Transactions (ZipForm
        Edition).
      </Instructions>
      <form onSubmit={handleZipFormLoginSubmit}>
        <ModalBody>
          <FormGroup>
            <Label htmlFor="username">Username</Label>
            <Input
              id="username"
              value={username}
              onChange={(e) => setUsername(e.target.value)}
            />
          </FormGroup>
          <FormGroup>
            <Label htmlFor="password">Password</Label>
            <Input
              id="password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </FormGroup>
          <input type="submit" hidden={true} />
          <ForgotPassword>
            Forgot <a href={LINKS.ZF_FORGOT_PASS}>username</a> or{" "}
            <a href={LINKS.ZF_FORGOT_PASS}>password?</a>
          </ForgotPassword>
        </ModalBody>
      </form>
      {network.error && (
        <Error>
          <IconError />
          <span>
            <strong>Invalid password. </strong>
            {network.error}
          </span>
        </Error>
      )}
      <ModalFooter>
        <ModalFooterRow>
          <FooterTipLeft>
            Need a Transactions (zipForm Edition) account?{" "}
            <a href={LINKS.ZF_CREATE_ACCOUNT}>Create an account</a>
          </FooterTipLeft>
          <FooterActions>
            <Button
              app="cma"
              variant="secondary"
              onClick={() => setStep(STEPS.CHOOSE_TRANSACTION_MANAGER)}>
              Back
            </Button>
            <Button
              app="cma"
              disabled={network.isFetching}
              onClick={handleZipFormLogin}>
              {network.isFetching && <Loading>Connecting</Loading>}
              {!network.isFetching && "Connect"}
            </Button>
          </FooterActions>
          <FooterTipBottom>
            Need a Transactions (zipForm Edition) account?{" "}
            <a href={LINKS.ZF_CREATE_ACCOUNT}>Create an account</a>
          </FooterTipBottom>
        </ModalFooterRow>
      </ModalFooter>
    </>
  );
}

function TransactionConnectTD({ report }) {
  const { currentUser } = useSession();
  const { path } = usePath();
  const returnPath = path.includes("publish_option")
    ? "publish_option"
    : "cmas";
  const {
    title,
    report_address_parts: [addressLine1, addressLine2]
  } = report;
  useEffect(() => {
    connectTD(currentUser.id, report.id, returnPath);
  }, []);
  return (
    <>
      <ModalHeader>
        <Title>Connect to TransactionDesk</Title>
        <Modal.Close />
      </ModalHeader>

      <ModalBody>
        <ModalBodyRow>
          <ReportInfoCard>
            <p>
              <strong>{title}</strong>
            </p>
            <p>{addressLine1}</p>
            <p>{addressLine2}</p>
          </ReportInfoCard>
          <IconTransactionContainer>
            <IconTransaction />
          </IconTransactionContainer>
          <TMIconCard>
            <IconTransactionDeskHoriz />
          </TMIconCard>
        </ModalBodyRow>
      </ModalBody>

      <ModalFooter align="right">
        <Button app="cma" variant="primary" disabled={true}>
          <Loading>
            Connecting to Transactions (TransactionDesk Edition)
          </Loading>
        </Button>
      </ModalFooter>
    </>
  );
}

function TransactionCreateZF({ report, transaction, setTransaction, onClose }) {
  const { currentUser } = useSession();
  const { appendTimestampParam } = useQueryString();
  const [network, setNetwork] = useState({
    isFetching: false,
    hasFetched: false,
    error: ""
  });

  const {
    title,
    report_address_parts: [addressLine1, addressLine2]
  } = report;

  const transactionUrl =
    transaction && transaction.id
      ? `/transactions/sso_link/${transaction.id}`
      : "";

  useEffect(() => {
    async function createTransaction() {
      setNetwork({ isFetching: true, hasFetched: false, error: "" });
      try {
        const { transaction } = await createTransactionZF(
          currentUser.zipFormToken,
          report.id
        );
        setTransaction(transaction);
        setNetwork({ isFetching: false, hasFetched: true, error: "" });
      } catch (error) {
        console.error("Create Transaction:", error);
        setNetwork({
          isFetching: false,
          hasFetched: false,
          error:
            error.errors ||
            "Transactions (zipForm Edition) isn’t able to create a transaction at this time."
        });
      }
    }
    if (!transaction) createTransaction();
  }, []);
  return (
    <>
      <ModalHeader>
        <Title>New Transaction</Title>
        <Modal.Close />
      </ModalHeader>

      <ModalBody>
        <ModalBodyRow>
          <ReportInfoCard>
            <p>
              <strong>{title}</strong>
            </p>
            <p>{addressLine1}</p>
            <p>{addressLine2}</p>
          </ReportInfoCard>
          <IconTransactionContainer>
            <IconTransaction />
          </IconTransactionContainer>
          <TMIconCard>
            <IconZipFormHoriz />
          </TMIconCard>
        </ModalBodyRow>
      </ModalBody>

      {network.error && (
        <Error>
          <IconError />
          <span>
            <strong>Error. </strong>
            {network.error}
          </span>
        </Error>
      )}

      <ModalFooter align="right">
        <FooterActions>
          <Button app="cma" variant="secondary" onClick={onClose}>
            Close
          </Button>
          {!network.error && (
            <Button
              app="cma"
              variant="primary"
              disabled={network.isFetching}
              onClick={() => {
                appendTimestampParam();
                window.open(transactionUrl, "_self");
              }}>
              {network.isFetching && <Loading>Creating Transaction</Loading>}
              {transactionUrl && "View Transaction"}
            </Button>
          )}
        </FooterActions>
      </ModalFooter>
    </>
  );
}

function TransactionCreateTD({ report, transaction, setTransaction, onClose }) {
  const { currentUser } = useSession();
  const { appendTimestampParam } = useQueryString();
  const [network, setNetwork] = useState({
    isFetching: false,
    hasFetched: false,
    error: ""
  });

  const {
    title,
    report_address_parts: [addressLine1, addressLine2]
  } = report;

  const transactionUrl =
    transaction && transaction.id
      ? `/transactions/sso_link/${transaction.id}`
      : "";

  useEffect(() => {
    async function createTransaction() {
      setNetwork({ isFetching: true, hasFetched: false, error: "" });
      try {
        const { transaction } = await createTransactionTD(
          currentUser.transactionDeskToken,
          report.id
        );
        setTransaction(transaction);
        setNetwork({ isFetching: false, hasFetched: true, error: "" });
      } catch (error) {
        console.error("Create Transaction:", error);
        setNetwork({
          isFetching: false,
          hasFetched: false,
          error:
            error.errors ||
            "Transactions (TransactionDesk Edition) isn’t able to create a transaction at this time."
        });
      }
    }
    if (!transaction) createTransaction();
  }, []);
  return (
    <>
      <ModalHeader>
        <Title>New Transaction</Title>
        <Modal.Close />
      </ModalHeader>

      <ModalBody>
        <ModalBodyRow>
          <ReportInfoCard>
            <p>
              <strong>{title}</strong>
            </p>
            <p>{addressLine1}</p>
            <p>{addressLine2}</p>
          </ReportInfoCard>
          <IconTransactionContainer>
            <IconTransaction />
          </IconTransactionContainer>
          <TMIconCard>
            <IconTransactionDeskHoriz />
          </TMIconCard>
        </ModalBodyRow>
      </ModalBody>
      {network.error && (
        <Error>
          <IconError />
          <span>
            <strong>Error. </strong>
            {network.error}
          </span>
        </Error>
      )}

      <ModalFooter align="right">
        <FooterActions>
          <Button app="cma" variant="secondary" onClick={onClose}>
            Close
          </Button>
          {!network.error && (
            <Button
              app="cma"
              variant="primary"
              disabled={network.isFetching}
              onClick={() => {
                appendTimestampParam();
                window.open(transactionUrl, "_self");
              }}>
              {network.isFetching && <Loading>Creating Transaction</Loading>}
              {transactionUrl && "View Transaction"}
            </Button>
          )}
        </FooterActions>
      </ModalFooter>
    </>
  );
}

export default TransactionWizard;
