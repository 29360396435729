import styled from "styled-components";
import { Title as BaseTitle } from "./customize-modal-kit";

export const Title = styled(BaseTitle)`
  display: block;
  margin: 0;
  text-align: center;
  color: ${({ theme }) => theme.colors.purpleDark};
`;

export const SubTitle = styled(Title)`
  margin-top: 0.8rem;
  margin: 0.8rem 0 2rem;
`;

export const TitleImage = styled.img`
  height: 3.6rem;
`;
