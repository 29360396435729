import styled, { css } from "styled-components";

export const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0.6rem;

  i,
  label {
    margin: 0;
  }
`;

export const InputWithButtonContainer = styled.div`
  display: flex;
  position: relative;

  input[type="file"] {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  ${({ hasCompanyLogo }) =>
    hasCompanyLogo &&
    css`
      label {
        border-right: 0;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }

      button {
        border-left: 0;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    `}
`;

export const Placeholder = styled.span`
  opacity: 0;
`;

export const Grid = styled.div`
  display: flex;
  margin: -0.5rem;
`;

export const Cell = styled.div`
  padding: 0.5rem;
`;
