import { connect } from "react-redux";
import { includeIllustrations } from "../../../../actions/customize";
import CustomizeControlsIllustrations from "../CustomizeControlsIllustrations";

function select({
  report: { id },
  customize: {
    illustrations: { active, request }
  }
}) {
  return {
    reportId: id,
    active,
    request
  };
}

const actions = {
  includeIllustrations
};

export default connect(select, actions)(CustomizeControlsIllustrations);
