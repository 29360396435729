import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 60rem;
  background: linear-gradient(180deg, #2d335d, #131943);
`;

export const ModalHeader = styled(BaseModal.Header)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 2rem;
  font-weight: 400;
  line-height: 2.7rem;
  margin-bottom: 3rem;
  color: ${({ theme }) => theme.styleGuide.colors.white};
  position: relative;
`;

export const ModalClose = styled(BaseModal.Close)`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;

  svg {
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }
`;

export const ModalBody = styled(BaseModal.Body)`
  margin: 0;
`;

export const ModalFooter = styled(BaseModal.Footer)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
`;

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const HeaderIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 4.2rem;
  height: 4.2rem;
  margin-right: 1rem;
  border: 0.2rem solid ${({ theme }) => theme.styleGuide.colors.white};
  border-radius: 0.5rem;
  color: ${({ theme }) => theme.styleGuide.colors.white};

  svg {
    width: 2.4rem;
    height: 2.4rem;
    fill: currentColor;
  }
`;

export const Images = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27.5rem;
  margin-bottom: 2.5rem;
  position: relative;
`;

export const Image = styled.img`
  height: 100%;
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.overlay};
  position: relative;
  z-index: 2;

  &:first-child,
  &:last-child {
    position: absolute;
  }

  &:first-child,
  &:last-child {
    top: 0;
  }

  &:first-child {
    left: 0;
    z-index: 1;
  }

  &:last-child {
    right: 0;
    z-index: 3;
  }
`;
