import { gql } from "apollo-boost";

export const GET_REPORT_STATUS = gql`
  query GetReportStatus($reportId: ID!) {
    getReportStatus(reportId: $reportId)
      @rest(type: "Cma", path: "reports/{args.reportId}/status_update") {
      id
      status
      pdfLink: pdf_url
    }
  }
`;

export const GET_CMAS = gql`
  query GetCMAS(
    $page: Int = 1
    $limit: Int = 17
    $search: String = ""
    $ts: String = ""
  ) {
    cmas(page: $page, limit: $limit, search: $search, ts: $ts)
      @rest(
        type: "CMAs"
        path: "cmas?page={args.page}&limit={args.limit}&q={args.search}&ts={args.ts}"
      ) {
      meta @type(name: "CMAsMeta") {
        currentPage
        perPage
        totalPages
        totalResults
      }
      results @type(name: "CMA") {
        id
        guid
        title
        type
        status
        notes
        thumbnail: thumbnail_url_with_timestamp
        createdWith: created_via
        pdfLink: pdf_permalink
        addressParts: report_address_parts
        updatedAt: updated_at
        investorOffer: investor_offer
        propertyType: prop_type
        propertySubType: prop_sub_type
        eventSummary: event_summary @type(name: "ReportEventSummary") {
          viewCount: view_count
          lastViewedAt: last_view_at
        }
        subjectProperty: subject_property @type(name: "SubjectProperty") {
          beds
          baths
          sqft
          garages
          lotSize: lot_size
        }
        transaction: external_transaction @type(name: "ExternalTransaction") {
          createdAt: created_at
          guid
          id
          reportId: report_id
          updatedAt: updated_at
          url
          type
        }
      }
    }
  }
`;

export const PUBLISH_REPORT = gql`
  mutation PublishReport($reportId: ID!) {
    publishReport(reportId: $reportId)
      @rest(type: "Cma", path: "cmas/{args.reportId}/publish") {
      id
      status
    }
  }
`;

export const DELETE_CMA = gql`
  mutation DeleteCMA($id: ID!) {
    deleteCMA(id: $id)
      @rest(type: "Cma", path: "cmas/{args.id}", method: "DELETE") {
      id
    }
  }
`;
