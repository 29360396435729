import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 60rem;
`;

export const ModalFooter = styled(BaseModal.Footer)`
  display: flex;
  justify-content: center;
`;
