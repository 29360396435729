import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Draggable } from "react-beautiful-dnd";
import { editNetSheetItem } from "../../actions/subject-property";
import { stripNumber } from "../../utils/number";
import { formatCurrentInputValue } from "../../utils/string";
import Loading from "../common/Loading";
import IconMoreVert from "../icons/material/navigation/IconMoreVert";
import NetSheetListItemRemove from "./NetSheetListItemRemove";
import { Input, InputCurrency } from "./styled/net-sheet-item";

class NetSheetListItem extends Component {
  timer = null;

  state = {
    item: this.props.item
  };

  static getDerivedStateFromProps(props, state) {
    return {
      item: {
        ...props.item,
        key:
          typeof state.item.key === "undefined"
            ? props.item.key
            : state.item.key,
        value:
          typeof state.item.value === "undefined"
            ? props.item.value
            : state.item.value
      }
    };
  }

  componentWillUnmount() {
    this._isUnmounted = true;
  }

  componentDidUpdate(prevProps) {
    const { subjectProperty, editNetSheetItem } = this.props;
    const { item } = this.state;

    const rawPrevStateValue = stripNumber(prevProps.item.value);
    const rawStateValue = stripNumber(this.state.item.value);

    if (
      (prevProps.item.key !== this.state.item.key ||
        rawStateValue !== rawPrevStateValue) &&
      !item.request.fetching
    ) {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        editNetSheetItem(subjectProperty.id, { ...item, value: rawStateValue });
      }, 500);
    }
  }

  handleOnNameChange = (e) => {
    const value = e.target.value;
    this.setState(({ item }) => ({ item: { ...item, key: value } }));
  };

  handleOnValueChange = (e) => {
    const value = e.target.value;
    this.setState(({ item }) => ({ item: { ...item, value } }));
  };

  render() {
    const { index, isDragDisabled } = this.props;
    const { item } = this.state;
    const { id, key, value, request } = item;
    const hasError = request.error && Object.keys(request.error).length > 0;

    return (
      <Draggable
        draggableId={String(id)}
        index={index}
        type="net-sheet"
        isDragDisabled={isDragDisabled}>
        {(provided, snapshot) => (
          <Fragment>
            <div
              className={`tabley-row ${
                snapshot.isDragging ? "shadow-overlay dragging" : ""
              }`}
              ref={provided.innerRef}
              {...provided.draggableProps}
              {...provided.dragHandleProps}>
              <div
                className={`tabley-cell py-0 pl-12 border-b-1 break-all ${
                  snapshot.isDragging ? "border-0" : ""
                }`}>
                {!isDragDisabled && (
                  <span className="net-sheet-item-drag-handle-icon">
                    <IconMoreVert />
                  </span>
                )}
                <Input
                  value={key}
                  placeholder="Expense Name"
                  disabled={request.fetching}
                  title={
                    request.fetching
                      ? "You can only edit once the item has been saved."
                      : ""
                  }
                  onChange={this.handleOnNameChange}
                />
                {hasError && (
                  <span className="text-red ml-2">{request.error.message}</span>
                )}
              </div>
              <div
                className={`tabley-cell border-b-1 py-0 text-right break-all ${
                  snapshot.isDragging ? "border-0" : ""
                }`}>
                <InputCurrency
                  placeholder="$0"
                  disabled={request.fetching}
                  title={
                    request.fetching
                      ? "You can only edit once the item has been saved."
                      : ""
                  }
                  value={formatCurrentInputValue(value)}
                  onChange={this.handleOnValueChange}
                />
              </div>
              <div
                className={`tabley-cell border-b-1 text-right ${
                  snapshot.isDragging ? "border-0" : ""
                }`}>
                {request.fetching && <Loading />}
                {!request.fetching && !hasError && (
                  <NetSheetListItemRemove item={item} />
                )}
              </div>
            </div>
            {provided.placeholder}
          </Fragment>
        )}
      </Draggable>
    );
  }
}

NetSheetListItem.propTypes = {
  subjectProperty: PropTypes.shape({
    id: PropTypes.number
  }).isRequired,
  item: PropTypes.shape({
    id: PropTypes.number.isRequired,
    key: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    request: PropTypes.shape({
      fetching: PropTypes.bool.isRequired,
      error: PropTypes.shape({
        message: PropTypes.string
      })
    })
  }).isRequired,
  index: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool.isRequired,
  editNetSheetItem: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  subjectProperty: state.subjectProperty
});

const mapDispatchToProps = {
  editNetSheetItem
};

export default connect(mapStateToProps, mapDispatchToProps)(NetSheetListItem);
