import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/homebeat-viewer-button";

function HomebeatViewerButton({ isFullWidth, ...props }) {
  return <Container full={isFullWidth} {...props} />;
}

HomebeatViewerButton.propTypes = {
  isFullWidth: PropTypes.bool
};

export default HomebeatViewerButton;
