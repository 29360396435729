import styled from "styled-components";

export const Container = styled.button`
  display: flex;
  padding: 0;
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  appearance: none;
  cursor: pointer;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    margin-right: 1rem;
  }
`;
