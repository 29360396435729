import React from "react";

function IconInfo() {
  return (
    <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM10 7C10.5523 7 11 6.55228 11 6C11 5.44771 10.5523 5 10 5C9.44772 5 9 5.44771 9 6C9 6.55228 9.44772 7 10 7ZM10.0039 8C9.4557 8 9.01098 8.44385 9.00989 8.99207L9 13.999C8.99891 14.5515 9.4465 15 9.99901 15C10.5507 15 10.998 14.5527 10.998 14.001L10.998 8.99402C10.998 8.44504 10.5529 8 10.0039 8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconInfo;
