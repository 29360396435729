import styled from "styled-components";
import BaseButton from "../../../common/Button";

export const Container = styled.div`
  padding: 2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
`;

export const Action = styled.div`
  margin-top: 1rem;

  &:first-child {
    margin-top: 0;
  }
`;

export const Button = styled(BaseButton)`
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }
`;
