import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { Container as BaseButton } from "../../common/styled/button";

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 64rem;
`;

export const ModalHeader = styled(BaseModal.Header)`
  align-items: flex-start;
  margin: 1.4rem 0 2.4rem;
  button {
    margin-top: -1.4rem;
    svg {
      width: 1.6rem;
      height: 1.6rem;
      fill: ${({ theme }) => theme.styleGuide.colors.gray[800]};
    }
  }
  ${({ theme }) => theme.media.sm`
   margin: 1.2rem 0 2.4rem;
   button {
    margin-top: -1.2rem;
   }
  `}
`;

export const Title = styled.h2`
  font-weight: 600;
  font-size: 3rem;
  line-height: 3.6rem;
  margin: 0 0 0 1.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  ${({ theme }) => theme.media.sm`
    font-size: 2.4rem;
    line-height: 2.9rem;
  `};
`;

export const Instructions = styled.p`
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  margin: -1.2rem 1.4rem 2.4rem;
  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
    line-height: 1.8rem;
  `};
`;

export const ModalBody = styled(BaseModal.Body)`
  flex-grow: 0;
  max-height: 67vh;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  padding: 3.4rem;
  label {
    color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
    font-size: 1.4rem;
    line-height: 2rem;
  }
`;

export const ModalBodyRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ theme }) => theme.media.sm`
    flex-direction: column;
    justify-content: flex-start;
    div:not(:last-child) {
      margin-bottom: 2.4rem;
    }
  `};
`;

export const ModalFooter = styled(BaseModal.Footer)`
  margin: 2.4rem 0 0.4rem;
  text-align: ${({ align }) => align || ""};
`;

export const ModalFooterRow = styled.div`
  margin-left: 1.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `}
`;

export const FooterActions = styled.div`
  min-width: 21.2rem;
  text-align: right;
  ${BaseButton}:not(:first-of-type) {
    margin-left: 1.2rem;
  }
  ${({ theme }) => theme.media.sm`
    width: 100%;
    ${BaseButton}:not(:first-of-type) {
      margin-left: 0;
      margin-top: 1.2rem;
    }
    ${BaseButton} {
      width: 100%;
    }
  `}
`;

export const ForgotPassword = styled.p`
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  font-size: 1.3rem;
  line-height: 1.7rem;
  a {
    color: ${({ theme }) => theme.styleGuide.colors.cma[600]};
    text-decoration: none;
  }
`;

export const TMCards = styled.div`
  display: flex;
  align-items: center;
  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `}
`;

export const TMCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 27.8rem;
  padding: 2.4rem 3.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  &:not(:last-child) {
    margin: 0 1.6rem 0 0;
  }
  ${({ theme }) => theme.media.sm`
    &:not(:last-child) {
      margin: 0 0 1.6rem 0;
    }
  `}
`;

export const TMCardIcon = styled.div`
  margin-bottom: 1.2rem;
`;

export const FooterLinkContainer = styled.div`
  ${({ theme }) => theme.media.sm`
    max-width: 17rem;
    margin: 0 auto;
  `}
`;

export const FooterLink = styled.a`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.cma[600]};
  text-decoration: none;
  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `};
`;

export const ButtonLink = styled(BaseButton.withComponent("a"))``;

export const ReportInfoCard = styled.div`
  padding: 1.2rem 1.6rem;
  min-width: 24.6rem;
  background: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  font-size: 1.4rem;
  line-height: 2rem;
  p {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  ${({ theme }) => theme.media.sm`
    width: 100%;
    padding 2.4rem;
  `}
`;

export const TMIconCard = styled.div`
  padding: 1.8rem 1.6rem;
  min-width: 24.6rem;
  background: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: ${({ theme }) => theme.borderRadius.xl};
  ${({ theme }) => theme.media.sm`
    width: 100%;
    padding: 2.4rem 4.5rem;
  `}
`;

export const IconTransactionContainer = styled.div`
  svg {
    width: 3.2rem;
    height: 3.2rem;
    path {
      fill: ${({ theme }) => theme.styleGuide.colors.gray[600]};
    }
  }
`;

export const Error = styled.div`
  display: flex;
  flex-align: center;
  justify-content: center;
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  padding: 1rem;
  margin: 0 -2rem;
  color: ${({ theme }) => theme.styleGuide.colors.red[900]};
  background-color: ${({ theme }) => theme.styleGuide.colors.attract[100]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
  span:not(:first-child):last-child {
    margin-left: 1rem;
  }
`;

export const FooterTipLeft = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  display: block;
  max-width: 60%;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  a {
    color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
    text-decoration: none;
  }

  ${({ theme }) => theme.media.sm`
    display: none;
    font-size: 1.5rem;
  `}
`;

export const FooterTipBottom = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  display: none;
  margin-top: 2.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  a {
    color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
    text-decoration: none;
  }

  ${({ theme }) => theme.media.sm`
    display: block;
    font-size: 1.5rem;
  `}
`;
