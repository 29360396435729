import React from "react";
import { useSession } from "../../Session";
import { storeRedirectUrl } from "../../api/user";
import IconSparkle from "../icons/IconSparkle";
import {
  Badge,
  Banner,
  Button,
  Container
} from "./styled/customize-rdc-banner";

export default function CustomizeRdcBanner() {
  const { features } = useSession();

  if (!features.offerRdcToolkit) return null;

  return (
    <Container>
      <Banner>
        <Badge>
          <IconSparkle />
          New
        </Badge>
        <div>
          Impress your clients with the new Realtor.com® Listing Toolkit
        </div>
        <Button
          data-pendo-id="rdc-banner"
          href="https://succeed.realtor.com/listingtoolkit?utm_source=cloud_cma&utm_medium=website&utm_campaign=ccma_customize_banner&utm_term=banner"
          onClick={() => storeRedirectUrl("rdc", window.location.href)}>
          Learn more →
        </Button>
      </Banner>
    </Container>
  );
}
