import styled from "styled-components";
import { Container as BaseInputStyle } from "../../../common/styled/input";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  position: fixed;
  bottom: 2rem;
  right: 2rem;
  z-index: 10;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 6rem;
  height: 6rem;
  padding: 0;
  border-radius: 6rem;
  border: 0.2rem solid ${({ theme }) => theme.homebeat.colors.primary};
  background-color: ${({ theme }) => theme.homebeat.colors.primary};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.overlay};
  appearance: none;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }

  span {
    width: 5.6rem;
    height: 5.6rem;
  }
`;

export const Overlay = styled.div`
  width: 100%;
  max-width: 32.6rem;
  margin-bottom: 1.6rem;
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.overlay};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  border-radius: 0.8rem;
`;

export const Agent = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  padding: 1.6rem 2.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  border-bottom: 0.1rem solid
    ${({ theme }) => theme.styleGuide.colors.gray[500]};
  border-top-right-radius: 0.8rem;
  border-top-left-radius: 0.8rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Grid = styled.div`
  display: flex;
  margin: -0.8rem;
`;

export const Cell = styled.div`
  padding: 0.8rem;
`;

export const AgentName = styled.strong`
  font-weight: 600;
`;

export const AgentLink = styled.a`
  color: inherit;
  text-decoration: none;
`;

export const Content = styled.div`
  padding: 2.4rem;
`;

export const Message = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 0 2.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[800]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Controls = styled.div`
  margin-top: 1.6rem;
`;

export const TextArea = styled(BaseInputStyle)`
  width: 100%;
  padding: 0.8rem 1.2rem;
  border-width: 0.1rem;
  resize: vertical;

  &:active,
  &:focus {
    border-color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const Success = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 1.6rem 0 0;
  color: ${({ theme }) => theme.styleGuide.colors.green[500]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Error = styled(Success)`
  color: ${({ theme }) => theme.styleGuide.colors.red[500]};
`;
