import React from "react";
import { useHistory } from "react-router-dom";
import HomebeatEditor from "./editor/HomebeatEditor";

function HomebeatNew() {
  const history = useHistory();
  return (
    <HomebeatEditor
      homebeats={[]}
      onFinish={() => history.push("/homebeats")}
    />
  );
}

export default HomebeatNew;
