import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  position: relative;
`;

export const GridContainer = styled.div`
  width: 100%;
  overflow-y: visible;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    display: none;
  }

  &.pad {
    padding-bottom: ${({ hasFooter }) => (hasFooter ? "9.4rem" : "8.4rem")};

    ${({ theme }) => theme.media.sm`
      padding-bottom: 0;
    `}
  }
`;

export const Grid = styled.div`
  display: flex;
  width: 100%;

  ${({ theme }) => theme.media.sm`
    width: 100% !important;
  `}
`;

export const Cell = styled.div`
  flex-shrink: 0;
  scroll-snap-align: start;

  ${({ theme }) => theme.media.sm`
    width: 80%;
  `}

  &:first-child {
    position: sticky;
    left: 0;
    z-index: 10;

    ${({ theme }) => theme.media.sm`
      position: static;
    `}

    &.scrolling {
      clip-path: inset(0 -1rem 0 0);
      box-shadow: ${({ theme }) => theme.styleGuide.shadows.overlay};

      ${({ theme }) => theme.media.sm`
        box-shadow: none;
      `}
    }
  }
`;
