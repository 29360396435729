import { findPage } from "../../../utils/page";

export function remove(state, action) {
  const { key } = action.payload;
  let inactivePages = [...state.inactive.pages];
  let activePages = [...state.active.pages];
  let marketingPages = [...state.powerPacks.pages.marketing];
  let luxPages = [...state.powerPacks.pages.lux];
  let tomPages = [...state.powerPacks.pages.tom];
  let newState = { ...state };
  let { index, page } = findPage(key, activePages);

  if (page) {
    activePages.splice(index, 1);
    newState.active = { ...state.active, pages: activePages };

    switch (page.inactive_group) {
      case "inactive_marketing_2013":
        marketingPages = [...marketingPages, page];
        newState.powerPacks = {
          ...state.powerPacks,
          pages: { ...state.powerPacks.pages, marketing: marketingPages }
        };
        break;

      case "inactive_marketing_luxe":
        luxPages = [...luxPages, page];
        newState.powerPacks = {
          ...state.powerPacks,
          pages: { ...state.powerPacks.pages, lux: luxPages }
        };
        break;

      case "inactive_marketing_tom_ferry":
        tomPages = [...tomPages, page];
        newState.powerPacks = {
          ...state.powerPacks,
          pages: { ...state.powerPacks.pages, tom: tomPages }
        };
        break;

      default:
        if (page.key !== "listings") {
          inactivePages = [...inactivePages, page];
        }

        newState.inactive = { ...state.inactive, pages: inactivePages };
        break;
    }
  }

  return { ...newState, template: { ...newState.template, hasModified: true } };
}
