import PropTypes from "prop-types";
import React, { useState } from "react";
import { optOutOfFeature } from "../../api/user";
import Switch from "../common/Switch";
import { Container, Text } from "./styled/investor-toggle";

function InvestorToggle({ show, text, feature }) {
  const [isChecked, setIsChecked] = useState(!!show);

  const handleChange = (isChecked) => {
    setIsChecked(isChecked);
    optOutOfFeature(feature, !isChecked);
  };

  return (
    <Container>
      <Switch isOn={isChecked} onChange={handleChange} />
      <Text>{text}</Text>
    </Container>
  );
}

InvestorToggle.propTypes = {
  show: PropTypes.bool
};

export default InvestorToggle;
