import styled, { css } from "styled-components";

export const Container = styled.form`
  overflow: hidden;
  border-radius: 0.4rem;
  border: 0.2rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
`;

export const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  text-align: left;
`;

const cell = css`
  font-size: 1.3rem;
  line-height: 1.8rem;
  padding: 1rem 3rem;

  ${({ theme }) => theme.media.md`
    padding-left: 1rem;
    padding-right: 1rem;
  `}
`;

export const Header = styled.th`
  ${cell};

  &:nth-child(2) {
    text-align: right;
  }
`;

export const Label = styled.td`
  ${cell};
  width: 60%;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
`;

export const Value = styled.td`
  ${cell};
  width: 25%;
  text-align: right;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};
`;

export const Controls = styled.td`
  ${cell};
  width: 15%;
  text-align: right;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[500]};

  svg {
    width: 2rem;
    height: 2rem;
    fill: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  }
`;

export const AddInput = styled.input`
  width: 100%;
  height: 4rem;
  font-size: 1.3rem;
  line-height: 1.8rem;
  outline: none;

  &::placeholder {
    color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
  }
`;

export const AddLabel = styled(Label)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const AddValue = styled(Value)`
  padding-top: 0;
  padding-bottom: 0;

  ${AddInput} {
    text-align: right;
  }
`;

export const AddControls = styled(Controls)`
  padding-top: 0;
  padding-bottom: 0;
`;

export const AddControl = styled.button`
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
`;
