import React, { useState } from "react";
import PropTypes from "prop-types";
import { removeCustomPDFPage, uploadCustomPDF } from "../../api/user";
import { useSession } from "../../Session";
import FormGroup from "../common/FormGroup";
import Button from "../common/Button";
import AlertDanger from "../common/AlertDanger";
import Tip from "../common/Tip";
import Skeleton from "../common/Skeleton";
import IconDelete from "../icons/material/action/IconDelete";
import {
  Grid,
  Cell,
  Item,
  Image,
  EmptyImage,
  Caption,
  Title,
  Controls,
  Control,
  Input
} from "./styled/user-custom-pdf-pages";

function UserCustomPDFPages() {
  const { currentUser, customPDFPages, setCustomPDFPages } = useSession();
  const [uploadCount, setUploadCount] = useState(0);
  const [inputKey, setInputKey] = useState();

  const handleChange = async (e) => {
    const [file] = e.target.files;
    if (file) {
      setUploadCount((uploadCount) => uploadCount + 1);
      setInputKey(Math.random().toString());
      const page = await uploadCustomPDF(currentUser.id, file);
      setUploadCount((uploadCount) => uploadCount - 1);
      setCustomPDFPages((customPDFPages) => [
        ...customPDFPages,
        {
          id: page.id,
          title: page.pageFileName,
          url: page.url,
          image: page.image
        }
      ]);
    }
  };

  return (
    <div>
      <FormGroup>
        <Grid>
          {customPDFPages.map((pdf) => (
            <Cell key={pdf.id}>
              <UserCustomPDFPage
                id={pdf.id}
                title={pdf.title}
                url={pdf.url}
                image={pdf.image}
              />
            </Cell>
          ))}
          {new Array(uploadCount).fill().map((_, index) => (
            <Cell key={index}>
              <UserCustomPDFPage title="Uploading..." />
            </Cell>
          ))}
        </Grid>
      </FormGroup>
      <Controls>
        <Tip>Use the button below to upload PDF files.</Tip>
        <FormGroup>
          <Input
            key={inputKey}
            id="custom-pdf-input"
            type="file"
            accept="application/pdf"
            onChange={handleChange}
          />
          <Button app="cma" as="label" htmlFor="custom-pdf-input">
            Upload PDF
          </Button>
        </FormGroup>
      </Controls>
    </div>
  );
}

function UserCustomPDFPage({ id, title, url, image }) {
  const { currentUser, setCustomPDFPages } = useSession();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = () => {
    removeCustomPDFPage(currentUser.id, id);
    setCustomPDFPages((customPDFPages) =>
      customPDFPages.filter((customPDFPage) => customPDFPage.id !== id)
    );
  };

  return (
    <Item>
      {image && (
        <a href={url} target="_blank" rel="noreferrer noopener">
          <Image src={image} alt={title} data-error={title} />
        </a>
      )}
      {!image && (
        <EmptyImage>
          <Skeleton
            style={{
              width: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
              left: 0
            }}
          />
        </EmptyImage>
      )}
      <Caption>
        <Title title={title}>{title}</Title>
        {!!id && (
          <Control onClick={() => setIsDeleting(true)}>
            <IconDelete />
          </Control>
        )}
      </Caption>
      {isDeleting && (
        <AlertDanger
          title="Delete Custom PDF Page"
          confirmButton="Delete Forever"
          onConfirm={handleDelete}
          onClose={() => setIsDeleting(false)}>
          Are you sure you want to remove the custom pdf page:{" "}
          <strong>{title}</strong>?
        </AlertDanger>
      )}
    </Item>
  );
}

UserCustomPDFPage.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  url: PropTypes.string,
  image: PropTypes.string
};

export default UserCustomPDFPages;
