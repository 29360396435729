import React from "react";
import PropTypes from "prop-types";
import { colors } from "@wrstudios/theme";
import { Container, Tab as StyledTab } from "./styled/tabs";

function Tabs(props) {
  return <Container {...props} />;
}

function Tab({ isActive, activeColor, ...props }) {
  return <StyledTab isActive={isActive} activeColor={activeColor} {...props} />;
}

Tab.defaultProps = {
  activeColor: colors.cma[600]
};

Tab.propTypes = {
  isActive: PropTypes.bool,
  activeColor: PropTypes.string
};

Tabs.Tab = Tab;

export default Tabs;
