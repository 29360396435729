import styled from "styled-components";
import { default as BaseButton } from "../../common/Button";

export const InputContainer = styled.div`
  display: flex;

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const Button = styled(BaseButton)`
  flex-shrink: 0;
  margin-left: 1rem;

  ${({ theme }) => theme.media.md`
    margin: 1rem 0 0;
  `}
`;
