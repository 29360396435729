import React, { useState, useContext, createContext } from "react";

const UserContext = createContext();

function SessionProvider({ children }) {
  const [mlsnums, setMlsnums] = useState(window.sessionData.mlsnums);
  const [currentUser, setCurrentUser] = useState(
    window.sessionData.currentUser
  );
  const [contactItems, setContactItems] = useState(
    window.sessionData.contactItems
  );
  const [customPages, setCustomPages] = useState(
    window.sessionData.customPages
  );
  const [customPDFPages, setCustomPDFPages] = useState(
    window.sessionData.customPDFPages
  );
  const [subscription, setSubscription] = useState(
    window.sessionData.subscription
  );
  const [orders, setOrders] = useState(window.sessionData.orders);
  const [mls, setMls] = useState(window.sessionData.mls);
  const [features, setFeatures] = useState(window.sessionData.features);
  const [config, setConfig] = useState(window.sessionData.config);
  const [systemNotification, setSystemNotification] = useState(
    window.sessionData.systemNotification
  );
  const [translations, setTranslations] = useState(
    window.sessionData.translations
  );

  return (
    <UserContext.Provider
      value={{
        mlsnums,
        setMlsnums,
        currentUser,
        setCurrentUser,
        contactItems,
        setContactItems,
        customPages,
        setCustomPages,
        customPDFPages,
        setCustomPDFPages,
        subscription,
        setSubscription,
        orders,
        setOrders,
        mls,
        setMls,
        features,
        setFeatures,
        config,
        setConfig,
        translations,
        setTranslations,
        systemNotification,
        setSystemNotification
      }}>
      {children}
    </UserContext.Provider>
  );
}

function useSession() {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error(
      "the `useSession()` hook must be used as a child of <SessionProvider />"
    );
  }
  return context;
}

export { SessionProvider, useSession };
