import React from "react";

function IconChevronLeft(props) {
  return (
    <svg viewBox="0 0 9 14" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.53044 0.46967C8.82334 0.762563 8.82334 1.23744 8.53044 1.53033L3.06077 7L8.53044 12.4697C8.82334 12.7626 8.82334 13.2374 8.53044 13.5303C8.23755 13.8232 7.76268 13.8232 7.46978 13.5303L0.939453 7L7.46978 0.46967C7.76268 0.176777 8.23755 0.176777 8.53044 0.46967Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default IconChevronLeft;
