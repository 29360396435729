import api from "./api";
import { geocode } from "./forge";

export async function getAddresses({ address, guid, mlsCode, useForge }) {
  if (useForge) {
    const locations = await geocode(address, mlsCode);
    return locations.map((location) => {
      return {
        mlsNumber: location.mlsnum,
        coordinates: [location.lon, location.lat],
        value: [
          location.address,
          location.city,
          [location.state, location.zipcode].filter(Boolean).join(" ")
        ]
          .filter(Boolean)
          .join(", ")
      };
    });
  }

  return api("/api/geocode", { params: { query: address, guid } }).then(
    (res) => res.data
  );
}

export function getAddressDetails(address, guid) {
  return api("/api/v1/parcel.json", { params: { address, guid } }).then(
    (res) => res.data
  );
}
