import {
  ListingDetails,
  ListingHighlights,
  ListingSchools,
  ListingSlider,
  MlsDisclaimer
} from "@wrstudios/components";
import { IconMoney } from "@wrstudios/icons";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import Helmet from "react-helmet";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useTheme } from "styled-components";
import { getSchools } from "../../../api/report";
import { useSession } from "../../../Session";
import { transformSchools } from "../../../utils/listing";
import Loading from "../../common/Loading";
import Media from "../../common/Media";
import NotFound from "../../common/NotFound";
import SubjectPropertyPin from "../../common/SubjectPropertyPin";
import IconBackArrow from "../../icons/IconBackArrow";
import IconCloseSlim from "../../icons/IconCloseSlim";
import HomebeatViewerListingDetailsAside from "./HomebeatViewerListingDetailsAside";
import HomebeatViewerListingFullscreenMedia from "./HomebeatViewerListingFullscreenMedia";
import HomebeatViewerListingToggle from "./HomebeatViewerListingToggle";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerValuationModal from "./HomebeatViewerValuationModal";
import {
  Button,
  Container,
  Content,
  Control,
  Controls,
  Details,
  Disclaimer,
  Error,
  HeaderControls,
  Highlights,
  ListingHeader,
  Main,
  NotFoundContainer,
  Remarks,
  Sidebar
} from "./styled/homebeat-viewer-listing-details";

function HomebeatViewerListingDetails({
  isPreview,
  listingId: listingIdProp,
  initialTab: initialTabProp,
  initialSlideIndex: initialSlideIndexProp,
  showFullscreenMedia: showFullscreenMediaProp,
  onClose
}) {
  const theme = useTheme();
  const { guid, listingId } = useParams();
  const history = useHistory();
  const location = useLocation();
  const { mls, config } = useSession();
  const {
    report,
    subjectProperty,
    listings,
    setListings,
    isAcreageReport,
    showAcresInsteadOfLotSize
  } = useHomebeatViewer();
  const [showModal, setShowModal] = useState(false);
  const listing = listings.find(
    (listing) => listing.id === listingId || listing.id === listingIdProp
  );
  const [schoolsRequest, setSchoolsRequest] = useState({
    loading: false,
    success: !!((listing || {}).schools || []).length,
    error: null
  });

  const mountedRef = useRef();
  useEffect(() => {
    mountedRef.current = true;
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (
      schoolsRequest.loading ||
      schoolsRequest.success ||
      schoolsRequest.error ||
      !listing
    ) {
      return;
    }
    setSchoolsRequest({ loading: true, success: false, error: null });
    getSchools(report.id, {
      lat: listing.coords.lat,
      lon: listing.coords.lon
    })
      .then(({ schools }) => {
        if (!mountedRef.current) return;
        setListings((listings) =>
          listings.map((l) => {
            if (l.id === listing.id) {
              l.schools = transformSchools(schools);
            }
            return l;
          })
        );
        setSchoolsRequest({ loading: false, success: true, error: null });
      })
      .catch((error) => {
        if (!mountedRef.current) return;
        setSchoolsRequest({ loading: false, success: false, error });
      });
  }, [
    listing,
    report.id,
    schoolsRequest.loading,
    schoolsRequest.success,
    schoolsRequest.error
  ]);

  if (!listing) {
    return (
      <NotFoundContainer>
        <NotFound link={`/homebeat/${guid}`} />
      </NotFoundContainer>
    );
  }

  const locationState = location.state || {};
  const primaryLotSize =
    (showAcresInsteadOfLotSize || isAcreageReport
      ? listing.acres
      : listing.lotSize) || 0;
  const secondaryLotSize =
    (showAcresInsteadOfLotSize || isAcreageReport
      ? subjectProperty.acres
      : subjectProperty.lotSize) || 0;
  const [initialTab, setInitialTab] = useState(
    locationState.initialTab || initialTabProp
  );
  const [initialSlideIndex, setInitialSlideIndex] = useState(
    locationState.initialSlideIndex || initialSlideIndexProp
  );
  const [showFullscreenMedia, setShowFullscreenMedia] = useState(
    locationState.showFullscreenMedia || showFullscreenMediaProp
  );

  if (showFullscreenMedia) {
    return (
      <Media query={`(max-width: ${theme.styleGuide.breakpoints.md - 1}px)`}>
        {(matches) =>
          matches ? (
            <HomebeatViewerListingFullscreenMedia
              listing={listing}
              initialTab={initialTab}
              initialSlideIndex={initialSlideIndex}
              useFullscreen={!!isPreview}
              exitIcon={!isPreview ? <IconBackArrow /> : undefined}
              onExit={() => setShowFullscreenMedia(false)}
            />
          ) : (
            <HomebeatViewerListingFullscreenMedia
              listing={listing}
              initialTab={initialTab}
              initialSlideIndex={initialSlideIndex}
              useFullscreen={false}
              exitIcon={!isPreview ? <IconBackArrow /> : undefined}
              onExit={() => setShowFullscreenMedia(false)}
            />
          )
        }
      </Media>
    );
  }

  return (
    <>
      <Container>
        {!isPreview && (
          <Helmet>
            <title>{listing.street}</title>
          </Helmet>
        )}
        <ListingHeader
          isPreview={isPreview}
          address={listing.street}
          subAddress={listing.cityStateZip}
          showBackButton={true}
          backButtonIcon={isPreview ? <IconCloseSlim /> : undefined}
          onBackButtonClick={() =>
            isPreview && onClose ? onClose() : history.push(`/homebeat/${guid}`)
          }>
          <HeaderControls>
            <Controls>
              <Control>
                <HomebeatViewerListingToggle listingId={listing.id} />
              </Control>
              <Control>
                <Button onClick={() => setShowModal(true)}>
                  <IconMoney />
                  Get Valuation
                </Button>
              </Control>
            </Controls>
          </HeaderControls>
        </ListingHeader>
        <ListingSlider
          key={listing.id}
          mapboxApiToken={config.mapboxApiToken}
          latitude={listing.coords.lat}
          longitude={listing.coords.lon}
          photos={listing.photos}
          virtualTour={listing.virtualTour}
          subjectPropertyPin={
            <SubjectPropertyPin color={theme.homebeat.colors.primary} />
          }
          mapOptions={{ pitch: 50 }}
          onMapClick={() => {
            setInitialSlideIndex(0);
            setInitialTab("map");
            setShowFullscreenMedia(true);
          }}
          onPhotosClick={() => {
            setInitialSlideIndex(0);
            setInitialTab("map");
            setShowFullscreenMedia(true);
          }}
          onPhotoClick={(index) => {
            setInitialSlideIndex(index);
            setInitialTab("photos");
            setShowFullscreenMedia(true);
          }}
          onTourClick={() => {
            setInitialSlideIndex(0);
            setInitialTab("tour");
            setShowFullscreenMedia(true);
          }}
        />
        <Main>
          <Content>
            {[
              listing.beds,
              listing.baths,
              listing.sqft,
              listing.garages,
              listing.yearBuilt,
              primaryLotSize,
              secondaryLotSize
            ].some(Boolean) && (
              <Highlights>
                <ListingHighlights
                  primaryBeds={listing.beds}
                  primaryBaths={listing.baths}
                  primarySqft={listing.sqft}
                  primaryGarages={listing.garages}
                  primaryYearBuilt={listing.yearBuilt}
                  primaryLotSize={primaryLotSize}
                  secondaryBeds={subjectProperty.beds || 0}
                  secondaryBaths={subjectProperty.baths || 0}
                  secondarySqft={subjectProperty.sqft || 0}
                  secondaryGarages={subjectProperty.garages || 0}
                  secondaryYearBuilt={subjectProperty.yearBuilt || 0}
                  secondaryLotSize={secondaryLotSize}
                  hasAcres={showAcresInsteadOfLotSize || isAcreageReport}
                />
              </Highlights>
            )}
            {!!listing.remarks && <Remarks>{listing.remarks}</Remarks>}
            {!!listing.features.length && (
              <Details>
                <ListingDetails details={listing.features} />
              </Details>
            )}
            {schoolsRequest.loading && (
              <Loading>Loading school information</Loading>
            )}
            {schoolsRequest.success &&
              !!listing.schools &&
              !!listing.schools.length && (
                <ListingSchools
                  schools={listing.schools}
                  hasSchools={!!listing.schools.length}
                />
              )}
            {schoolsRequest.error && (
              <Error>Error loading school information.</Error>
            )}
            {!!mls.disclaimer && (
              <Disclaimer>
                <MlsDisclaimer disclaimer={mls.disclaimer} />
              </Disclaimer>
            )}
          </Content>
          <Sidebar>
            <HomebeatViewerListingDetailsAside listing={listing} />
          </Sidebar>
        </Main>
      </Container>
      {showModal && (
        <HomebeatViewerValuationModal onClose={() => setShowModal(false)} />
      )}
    </>
  );
}

HomebeatViewerListingDetails.defaultProps = {
  initialTab: "map",
  initialSlideIndex: 0,
  showFullscreenMedia: false
};

HomebeatViewerListingDetails.propTypes = {
  listingId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  initialTab: PropTypes.oneOf(["map", "photos", "tour"]),
  initialSlideIndex: PropTypes.number,
  showFullscreenMedia: PropTypes.bool,
  onClose: PropTypes.func
};

export default HomebeatViewerListingDetails;
