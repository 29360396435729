import React, { useState } from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import { removeCustomPage } from "../../api/user";
import { useSession } from "../../Session";
import Tooltip from "../common/Tooltip";
import AlertDanger from "../common/AlertDanger";
import PDFPreview from "../common/PDFPreview";
import IconEdit from "../icons/material/image/IconEdit";
import IconDelete from "../icons/material/action/IconDelete";
import IconRemoveRedEye from "../icons/material/image/IconRemoveRedEye";
import UserCustomPagesEdit from "./UserCustomPagesEdit";
import { whitelistTemplateKeys } from "./utils";
import {
  Item,
  Title,
  Badge,
  Controls,
  Control,
  ModalContent
} from "./styled/user-custom-pages";

function UserCustomPages() {
  const { customPages } = useSession();
  const [editingPageKey, setEditingPageKey] = useState("");
  const [isEditing, setIsEditing] = useState(false);

  return (
    <>
      {customPages.map((page) => (
        <UserCustomPage
          key={page.key}
          pageKey={page.key}
          id={page.id}
          title={page.title}
          isDefault={page.isDefault}
          onEdit={() => {
            setEditingPageKey(page.key);
            setIsEditing(true);
          }}
        />
      ))}
      {isEditing && (
        <UserCustomPagesEdit
          pageKey={editingPageKey}
          onClose={() => {
            setIsEditing(false);
            setEditingPageKey("");
          }}
        />
      )}
    </>
  );
}

function UserCustomPage({ pageKey, id, title, isDefault, onEdit }) {
  const { currentUser, setCustomPages } = useSession();
  const [isDeleting, setIsDeleting] = useState(false);
  const [isPreviewing, setIsPreviewing] = useState(false);

  const handleDelete = () => {
    removeCustomPage(currentUser.id, id);
    setCustomPages((customPages) =>
      customPages.filter((customPage) => customPage.id !== id)
    );
  };

  const handlePreview = () => {
    if (id) {
      setIsPreviewing(true);
    }
  };

  return (
    <Item>
      <Title isButton={!!id} onClick={onEdit}>
        {title}
        {whitelistTemplateKeys.includes(pageKey) && <Badge>Template</Badge>}
      </Title>
      <Controls>
        <Control>
          <button onClick={onEdit}>
            <IconEdit />
          </button>
        </Control>
        {!id && (
          <Control>
            <button disabled>
              <Tooltip text="You must edit a custom page to preview">
                <IconRemoveRedEye />
              </Tooltip>
            </button>
          </Control>
        )}
        {!!id && (
          <Control>
            <button onClick={handlePreview}>
              <IconRemoveRedEye />
            </button>
          </Control>
        )}
        {isDefault && (
          <Control>
            <button disabled>
              <Tooltip text="You cannot delete default pages">
                <IconDelete />
              </Tooltip>
            </button>
          </Control>
        )}
        {!isDefault && (
          <Control>
            <button onClick={() => setIsDeleting(true)}>
              <IconDelete />
            </button>
          </Control>
        )}
      </Controls>
      {isDeleting && (
        <AlertDanger
          title="Delete Custom Page"
          confirmButton="Delete Forever"
          onConfirm={handleDelete}
          onClose={() => setIsDeleting(false)}>
          Are you sure you want to remove the custom page:{" "}
          <strong>{title}</strong>?
        </AlertDanger>
      )}
      {isPreviewing && (
        <Modal onClose={() => setIsPreviewing(false)}>
          <ModalContent>
            <Modal.Header>
              {title} PDF Preview
              <Modal.Close />
            </Modal.Header>
            <Modal.Body>
              <PDFPreview
                url={`/users/${currentUser.id}/page_contents/${id}.pdf`}
              />
            </Modal.Body>
          </ModalContent>
        </Modal>
      )}
    </Item>
  );
}

UserCustomPage.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string.isRequired,
  isDefault: PropTypes.bool,
  onEdit: PropTypes.func.isRequired
};

export default UserCustomPages;
