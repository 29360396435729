import { formatCurrency, formatDate } from "@wrstudios/utils";
import { addDays } from "date-fns";
import React, { Fragment, useState } from "react";
import { useBraintreeToken } from "../braintree/useBraintreeToken";
import IconBadge from "../icons/IconBadge";
import IconArrowBack from "../icons/material/navigation/IconArrowBack";
import OrderCardForm from "./OrderCardForm";
import OrderDiscount from "./OrderDiscount";
import {
  Back,
  BackText,
  Body,
  Card,
  Content,
  Header,
  Stamp,
  Text,
  Type
} from "./styled/order-card";
import { Discount, Update } from "./styled/order-card-existing";

export default function OrderCardExisting({
  user,
  sku,
  amount,
  discount,
  onDiscountChange,
  error,
  setError,
  setGetToken
}) {
  const { subscription = { card_number: "" } } = user;
  const { card_number: cardNumber } = subscription;
  const [showUpdate, setShowUpdate] = useState(false);
  const { data: token } = useBraintreeToken();

  return (
    <Fragment>
      <Card>
        {!showUpdate && <Header>Your Card</Header>}
        {showUpdate && (
          <Back onClick={() => setShowUpdate(false)}>
            <IconArrowBack width={13} height={13} />
            <BackText>Back</BackText>
          </Back>
        )}
        <Body>
          {!showUpdate && (
            <Content>
              <Type>{cardNumber}</Type>
              <Text>
                We will use this card to process your payment of{" "}
                {formatCurrency(amount, "$0[.]00")} on{" "}
                {formatDate({
                  dateValue: addDays(new Date(), 30),
                  formating: "MMMM d, yyyy"
                })}
                .
              </Text>
              <Update onClick={() => setShowUpdate(true)}>
                Use a different card
              </Update>
            </Content>
          )}
          {showUpdate && (
            <Content>
              <OrderCardForm
                btToken={token}
                sku={sku}
                error={error}
                showDiscount={false}
                setError={setError}
                setGetToken={setGetToken}
              />
            </Content>
          )}
          <Stamp>
            <IconBadge width={108} height={108} />
          </Stamp>
        </Body>
      </Card>
      <Discount>
        <OrderDiscount
          label=""
          sku={sku}
          discount={discount}
          onChange={onDiscountChange}
        />
      </Discount>
    </Fragment>
  );
}
