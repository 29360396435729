import styled from "styled-components";

export const Warning = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-items: center;
  padding: 1.6rem;
  background-color: #fffbeb;
  color: #78350f;
  font-size: 1.4rem;

  svg {
    width: 2rem;
    height: 2rem;
    margin: 0 1rem;
    color: #da870b;
  }
`;
