import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";
import { default as BaseWistia } from "../Wistia";

export const Close = styled.button`
  padding: 0;
  border: 0;
  background-color: transparent;
  cursor: pointer;
  color: ${({ theme }) => theme.colors.gray};
  position: absolute;
  top: 1rem;
  right: 1rem;

  svg {
    width: 2.6rem;
    height: 2.6rem;
    fill: ${({ theme }) => theme.colors.white};
  }
`;

export const Modal = styled(BaseModal)`
  background-color: rgba(0, 0, 0, 0.8);
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 90rem;
  padding: 0;
  box-shadow: none;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.1);
  background-color: transparent;
`;

export const ModalHeader = styled(BaseModal.Header)`
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 3.3rem;
  text-shadow: none;
  color: ${({ theme }) => theme.colors.white};
`;

export const ModalBody = styled(BaseModal.Body)`
  display: flex;
  align-items: center;
`;

export const Wistia = styled(BaseWistia)`
  width: 100%;
`;
