// ! DO NOT UPDATE THIS FILE
// ! THIS FILE IS ONLY USED AS A BACKWARDS COMPATABILITY MECHANISM
// ! IF YOU NEED TO MAKE A CHANGE, CONSIDER USING `@wrstudios/theme` INSTEAD

import { css } from "styled-components";
import * as colors from "./colors";
import * as shadows from "./shadows";
import * as breakpoints from "./breakpoints";

export default {
  colors,
  shadows,
  breakpoints,
  status: {
    active: colors.green,
    sold: colors.red,
    closed: colors.red,
    pending: colors.yellow,
    canceled: colors.teal,
    expired: colors.teal
  },
  page: {
    padding: 2.4
  },
  layers: {
    flat: css`
      border: 0.1rem solid ${colors.greyLighter};
      border-radius: 0.4rem;
      background-color: ${colors.white};
      z-index: 0;
    `,
    raised: css`
      box-shadow: ${shadows.raised};
      border-radius: 0.4rem;
      background-color: ${colors.white};
      z-index: 1;
    `,
    overlay: css`
      box-shadow: ${shadows.overlay};
      border-radius: 0.4rem;
      background-color: ${colors.white};
      z-index: ${({ isNavOverlay }) => (isNavOverlay ? 3 : 2)};
    `,
    modal: css`
      box-shadow: ${shadows.modal};
      border-radius: 0.6rem;
      background-color: ${colors.white};
      z-index: 4;
    `
  },
  text: {
    h1: css`
      font-size: 4.2rem;
      font-weight: 600;
      line-height: 4.4rem;

      @media (max-width: ${breakpoints.page.oneColumn}px) {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    `,
    h2: css`
      font-size: 2.8rem;
      font-weight: 600;
      line-height: 3.2rem;

      @media (max-width: ${breakpoints.page.oneColumn}px) {
        font-size: 2.4rem;
        line-height: 2.8rem;
      }
    `,
    h3: css`
      font-size: 2.4rem;
      line-height: 2.8rem;
      font-weight: 600;
    `,
    h4: css`
      font-size: 1.8rem;
      line-height: 2.2rem;
      font-weight: 600;
    `,
    h5: css`
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 2rem;
      font-weight: 600;
    `,
    h6: css`
      font-size: 1.4rem;
      line-height: 1.8rem;
      text-transform: uppercase;
      color: ${colors.greyMedium};
    `,
    large: css`
      font-size: 1.6rem;
      line-height: 2.4rem;
    `,
    medium: css`
      font-size: 1.4rem;
      line-height: 2rem;
    `,
    small: css`
      font-size: 1.2rem;
      line-height: 1.8rem;
    `,
    micro: css`
      font-size: 1rem;
      line-height: 1.4rem;
      font-weight: 500;
      text-transform: uppercase;
    `
  },
  buttons: {
    base: css`
      display: inline-flex;
      font-size: 1.4rem;
      font-weight: 700;
      line-height: 2rem;
      padding: 0.8rem 1.2rem;
      border-radius: 0.4rem;
      border: 0.2rem solid transparent;
      cursor: pointer;

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `,
    large: css`
      padding: 1.4rem 1.6rem;
    `,
    primary: css`
      color: ${colors.white};
      background-color: ${colors.streamsBlue};
      border-color: ${colors.streamsBlue};

      &[href]:hover {
        color: ${colors.white};
      }
    `,
    secondary: css`
      color: ${colors.streamsBlue};
      background-color: ${colors.white};
      border-color: ${colors.streamsBlue};

      &[href]:hover {
        color: ${colors.streamsBlue};
      }
    `,
    tertiary: css`
      color: ${colors.streamsBlue};
      background-color: transparent;
      border-color: transparent;

      &[href]:hover {
        color: ${colors.streamsBlue};
      }
    `,
    danger: css`
      color: ${colors.white};
      background-color: ${colors.red};
      border-color: ${colors.red};

      &[href]:hover {
        color: ${colors.white};
      }
    `
  },
  inputs: {
    base: css`
      display: inline-block;
      width: 100%;
      font-size: 1.4rem;
      line-height: 2rem;
      padding: 0.8rem 1.2rem;
      background-color: ${colors.white};
      border: 0.2rem solid ${colors.greyLight};
      border-radius: 0.5rem;
      color: ${colors.greyMedium};
      outline: none;

      &::placeholder {
        color: rgba(137, 146, 157, 0.7);
      }

      &:focus {
        border-color: ${colors.streamsBlue};
      }

      &[disabled] {
        opacity: 0.5;
        cursor: not-allowed;
      }
    `,
    large: css`
      padding: 0.9rem;
    `
  },
  avatars: {
    xxs: {
      diameter: 2.4,
      fontSize: 1.1
    },
    xs: {
      diameter: 3.2,
      fontSize: 1.3
    },
    sm: {
      diameter: 4.8,
      fontSize: 2
    },
    md: {
      diameter: 6,
      fontSize: 2.4
    },
    lg: {
      diameter: 9,
      fontSize: 3
    },
    xl: {
      diameter: 12,
      fontSize: 3.8
    },
    xxl: {
      diameter: 15,
      fontSize: 5
    }
  },
  maxPageWidth: 108,
  noWrap: css`
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  `,
  removeScrollbar: css`
    overflow-x: auto;
    overflow-y: hidden;
    scrollbar-width: none;
    -ms-overflow-style: none;

    &::-webkit-scrollbar {
      display: none;
    }
  `
};
