import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { prices } from "../../selectors/listings";
import ListingsStatsPricesItem from "./ListingsStatsPricesItem";

function ListingsPrices({ low, high, average, median }) {
  return (
    <div className="mt-12 sm:mt-0 lg:mt-12">
      <h2 className="font-normal text-lg text-center sm:text-left text-grey-darker mb-8">
        Summary of Prices
      </h2>
      <ul className="list-reset leaders">
        <ListingsStatsPricesItem label="Low" value={low} />
        <ListingsStatsPricesItem label="Median" value={median} />
        <ListingsStatsPricesItem label="Average" value={average} />
        <ListingsStatsPricesItem label="High" value={high} />
      </ul>
    </div>
  );
}

ListingsPrices.propTypes = {
  low: PropTypes.number.isRequired,
  median: PropTypes.number.isRequired,
  average: PropTypes.number.isRequired,
  high: PropTypes.number.isRequired
};

const mapStateToProps = (state) => ({
  ...prices(state)
});

export default connect(mapStateToProps)(ListingsPrices);
