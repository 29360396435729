import React, { useState } from "react";
import PropTypes from "prop-types";
import { pickBy, identity } from "lodash";
import { Modal } from "@wrstudios/components";
import { updateUserAPI } from "../../api/user";
import { useSession } from "../../Session";
import FormGroup from "../common/FormGroup";
import Label from "../common/Label";
import Input from "../common/Input";
import Button from "../common/Button";
import Loading from "../common/Loading";
import Tip from "../common/Tip";
import Flash from "../common/Flash";

function CustomizeModalTestimonial({ testimonialKey, onUpdate, onClose }) {
  const { currentUser, setCurrentUser } = useSession();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });
  const testimonial = currentUser[testimonialKey];
  const labels = getLabels(testimonialKey);
  const isDisabled =
    request.loading ||
    !name ||
    (testimonialKey === "titlefy" && !!name && !password);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setRequest({ loading: true, success: false, error: null });
      await updateUserAPI(currentUser.id, {
        [testimonialKey]: pickBy({ name, password }, identity)
      });

      setCurrentUser((currentUser) => ({
        ...currentUser,
        [testimonialKey]: { ...testimonial, name, password }
      }));
      onUpdate(true);
    } catch (error) {
      setRequest({ loading: false, success: false, error: error.message });
    }
  };

  return (
    <Modal onClose={onClose}>
      <Modal.Content as="form" onSubmit={handleSubmit}>
        <Modal.Header>
          Testimonial Credentials
          <Modal.Close as="button" />
        </Modal.Header>
        <Modal.Body>
          <FormGroup>
            <Label htmlFor="name">{labels.name}</Label>
            <Input
              id="name"
              value={name}
              autoFocus={true}
              onChange={(e) => setName(e.target.value)}
            />
          </FormGroup>
          {testimonialKey === "titlefy" && (
            <FormGroup>
              <Label htmlFor="password">{labels.password}</Label>
              <Input
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
          )}
          {request.error && <Flash variant="error">{request.error}</Flash>}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={isDisabled}>
            {request.loading && <Loading>Updating Service</Loading>}
            {!request.loading && "Update Service"}
          </Button>
          <div>
            <Tip>
              Tip: You can change this setting later in the Settings -
              Integrations page.
            </Tip>
          </div>
        </Modal.Footer>
      </Modal.Content>
    </Modal>
  );
}

function getLabels(key) {
  return {
    ratedAgent: {
      name: "RatedAgent ID",
      password: "RatedAgent Password"
    },
    reach150: {
      name: "Reach150 Email",
      password: "Reach150 Password"
    },
    realSatisfied: {
      name: "RealSatisfied Vanity Name",
      password: "RealSatisfied Password"
    },
    testimonialTree: {
      name: "Testimonial Tree Email",
      password: "Testimonial Tree Password"
    },
    zillow: {
      name: "Zillow Screen Name or Email",
      password: "Zillow Password"
    },
    titlefy: {
      name: "Titlefy Name",
      password: "Titlefy Password"
    }
  }[key];
}

CustomizeModalTestimonial.defaultProps = {
  onUpdate: () => {}
};

CustomizeModalTestimonial.propTypes = {
  testimonialKey: PropTypes.string.isRequired,
  onUpdate: PropTypes.func,
  onClose: PropTypes.func.isRequired
};

export default CustomizeModalTestimonial;
