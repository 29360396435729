import styled from "styled-components";
import { Markers } from "@wrstudios/components";

export const Container = styled.div`
  width: 100%;
  height: 36rem;
  border-radius: 0.4rem;
  overflow: hidden;
  position: relative;

  ${({ theme }) => theme.media.sm`
    display: none;
  `}
`;

export const Popup = styled.div`
  position: relative;
`;

export const CheckboxContainer = styled.div`
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
`;

export const ListingMarker = styled(Markers.ListingMarker)`
  opacity: ${({ hide }) => hide && 0.6};
`;
