import styled from "styled-components";

export const Remove = styled.div`
  ${({ theme }) => theme.media.sm`
    margin-bottom: 1rem;
  `}

  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 0.8rem;
  }
`;
