import React from "react";
import { Route, NavLink } from "react-router-dom";
import Wrapper from "../common/Wrapper";
import Title from "../common/Title";
import SettingsProfile from "./SettingsProfile";
import SettingsContactInfo from "./SettingsContactInfo";
import SettingsPassword from "./SettingsPassword";
import SettingsMLSCredentials from "./SettingsMLSCredentials";
import SettingsCustomPages from "./SettingsCustomPages";
import SettingsIntegrations from "./SettingsIntegrations";
import SettingsBilling from "./SettingsBilling";
import SettingsBillingPayments from "./SettingsBillingPayments";
import { translate } from "../../utils/locale";
import {
  Container,
  Header,
  Aside,
  Main,
  List,
  ListItem
} from "./styled/settings";
import { useSession } from "../../Session";

function Settings() {
  const { currentUser, config } = useSession();

  return (
    <Wrapper>
      <Header>
        <Title>Account Settings</Title>
      </Header>
      <Container>
        <Aside>
          <nav>
            <List>
              <ListItem>
                <NavLink to="/settings" exact>
                  Profile
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/settings/contact-info">Contact Info</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/settings/password">Password</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/settings/mls-credentials">
                  {translate("mls.label", "MLS")} Credentials
                </NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/settings/custom-pages">Custom Pages</NavLink>
              </ListItem>
              <ListItem>
                <NavLink to="/settings/integrations">Integrations</NavLink>
              </ListItem>
              <ListItem>
                {currentUser.lwaJwt ? (
                  <a
                    href={`${config.lwAgentUrl}/billing?jwt=${currentUser.lwaJwt}`}>
                    Billing
                  </a>
                ) : (
                  <NavLink to="/settings/billing">Billing</NavLink>
                )}
              </ListItem>
            </List>
          </nav>
        </Aside>
        <Main>
          <Route path="/settings" exact>
            <SettingsProfile />
          </Route>
          <Route path="/settings/contact-info">
            <SettingsContactInfo />
          </Route>
          <Route path="/settings/password">
            <SettingsPassword />
          </Route>
          <Route path="/settings/mls-credentials">
            <SettingsMLSCredentials />
          </Route>
          <Route path="/settings/custom-pages">
            <SettingsCustomPages />
          </Route>
          <Route path="/settings/integrations">
            <SettingsIntegrations />
          </Route>
          <Route path="/settings/billing" exact>
            <SettingsBilling />
          </Route>
          <Route path="/settings/billing/payments">
            <SettingsBillingPayments />
          </Route>
        </Main>
      </Container>
    </Wrapper>
  );
}

export default Settings;
