import axios from "axios";
import { getCSRFToken } from "../utils/page";

// CSRF Helper for ajax
const CSRF_TOKEN = getCSRFToken();

axios.defaults.headers.common = {
  "X-CSRF-TOKEN": CSRF_TOKEN
};

export default axios;
