import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return requestState({ ...state, filter: "remove" });
}

export function resolved(state, action) {
  const error = action.payload.error_msg;

  if (error) {
    return rejectedState(state, { message: error });
  }

  const { listingId } = action.payload;
  const all = ((state.all && [...state.all]) || []).filter(
    (listing) => listing.id !== listingId
  );

  const newState = { ...state, all };

  delete newState.filter;

  return resolvedState(newState);
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
