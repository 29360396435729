import { IconWarning } from "@wrstudios/icons";
import { Warning } from "./styled/listing-warning";
import React from "react";

function ListingWarning({ children }) {
  return (
    <Warning>
      <span>
        <IconWarning />
      </span>
      <p>{children}</p>
    </Warning>
  );
}

export default ListingWarning;
