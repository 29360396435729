import React from "react";

function IconAddSlim() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30">
      <path d="M14 0h3v30h-3z" />
      <path d="M30.5 13.5v3H.5v-3z" />
    </svg>
  );
}

export default IconAddSlim;
