import React from "react";
import ListingsStatsCount from "./ListingsStatsCount";
import ListingsStatsPrices from "./ListingsStatsPrices";

function ListingsStats() {
  return (
    <div className="w-full lg:w-1/4 p-6">
      <div className="sticky listings-stats">
        <div className="flex flex-wrap">
          <div className="w-full sm:w-1/2 lg:w-full">
            <ListingsStatsCount />
          </div>
          <div className="w-full sm:w-1/2 lg:w-full">
            <ListingsStatsPrices />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ListingsStats;
