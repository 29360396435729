import styled from "styled-components";

export const Container = styled.div`
  padding: 1.6rem;
  border-bottom: 0.1rem solid
    ${({ theme }) => theme.styleGuide.colors.gray[400]};
`;

export const Status = styled.div`
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2rem;
  margin-bottom: 1.2rem;
  letter-spacing: 0.05rem;
  text-transform: uppercase;
`;

export const Price = styled.div`
  font-size: 3rem;
  font-weight: 600;
  line-height: 3.6rem;
  margin-bottom: 0.8rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;

export const PricePerSqft = styled.span`
  font-size: 1.4rem;
  line-height: 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[700]};
`;

export const Dom = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 0.2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
`;
