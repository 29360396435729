import React from "react";
import { IconSend } from "@wrstudios/icons";
import Loading from "../../common/Loading";
import { useHomebeatEditor } from "./HomebeatEditorProvider";
import { Container, Action, Button } from "./styled/homebeat-editor-actions";

function HomebeatEditorActions({ isPreview }) {
  const {
    homebeat,
    isDisabled,
    isEditing,
    isNewlyCreated,
    isGeneratingPreview,
    updatingHomebeat,
    creatingHomebeat,
    creatingError,
    updatingError,
    updateHomebeat,
    createHomebeat,
    generatePreview,
    setCreatingError,
    setUpdatingError,
    setShowPreview,
    setShowMobilePreview
  } = useHomebeatEditor();

  return (
    <Container>
      <Action>
        {isPreview && (
          <Button
            isFullWidth
            app="cma"
            variant="secondary"
            onClick={() => {
              setShowPreview(false);
              setShowMobilePreview(false);
              setCreatingError(false);
              setUpdatingError(false);
            }}>
            Hide Preview
          </Button>
        )}
        {!isPreview && (
          <Button
            isFullWidth
            variant="secondary"
            app="cma"
            disabled={isDisabled}
            onClick={generatePreview}>
            {isGeneratingPreview ? (
              <Loading>Generating Preview</Loading>
            ) : (
              "Show Preview"
            )}
          </Button>
        )}
      </Action>
      <Action>
        <Button
          isFullWidth
          app="cma"
          disabled={isDisabled || !!creatingError || !!updatingError}
          onClick={
            isEditing || isNewlyCreated ? updateHomebeat : createHomebeat
          }>
          {!isEditing && (
            <>
              {creatingHomebeat && !isGeneratingPreview ? (
                <Loading>Sending...</Loading>
              ) : (
                <>
                  <IconSend />
                  Send Now
                </>
              )}
            </>
          )}
          {isEditing && (
            <>
              {homebeat.frequency === null && (
                <>
                  {updatingHomebeat && !isGeneratingPreview ? (
                    <Loading>Sending...</Loading>
                  ) : (
                    <>
                      <IconSend />
                      Send Now
                    </>
                  )}
                </>
              )}
              {homebeat.frequency !== null && (
                <>
                  {updatingHomebeat && !isGeneratingPreview ? (
                    <Loading>Updating</Loading>
                  ) : (
                    "Update"
                  )}
                </>
              )}
            </>
          )}
        </Button>
      </Action>
    </Container>
  );
}

export default HomebeatEditorActions;
