import React from "react";

function IconDollar(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 18 18"
      {...props}>
      <g data-name="Locked stuff">
        <path
          fill="currentColor"
          d="M9 0a9 9 0 1 0 9 9 9 9 0 0 0-9-9zm0 16.36A7.36 7.36 0 1 1 16.36 9 7.37 7.37 0 0 1 9 16.36z"
        />
        <path
          fill="currentColor"
          d="M8.58 14.46v-1.69a2.84 2.84 0 0 1-1-.32 2.65 2.65 0 0 1-.74-.55 2.78 2.78 0 0 1-.45-.7 2.34 2.34 0 0 1-.2-.74l1.4-.34a1.48 1.48 0 0 0 .13.51 1.32 1.32 0 0 0 .3.44 1.19 1.19 0 0 0 .47.3 1.73 1.73 0 0 0 .68.11 1.34 1.34 0 0 0 .85-.23.73.73 0 0 0 .31-.61.67.67 0 0 0-.21-.5 1.24 1.24 0 0 0-.64-.3l-1-.23A2.76 2.76 0 0 1 7 8.86a1.91 1.91 0 0 1-.53-1.37 2.09 2.09 0 0 1 .17-.83A2.18 2.18 0 0 1 7.07 6a2.83 2.83 0 0 1 .67-.49 2.65 2.65 0 0 1 .84-.26V3.64h1.15v1.62a2.45 2.45 0 0 1 .84.3 2.38 2.38 0 0 1 .6.46 2.06 2.06 0 0 1 .38.56 2.35 2.35 0 0 1 .2.58l-1.39.39a1.17 1.17 0 0 0-.09-.33 1.2 1.2 0 0 0-.27-.34 1.19 1.19 0 0 0-.38-.27 1.39 1.39 0 0 0-.58-.11 1.17 1.17 0 0 0-.83.27.81.81 0 0 0-.21.61.66.66 0 0 0 .19.46 1.14 1.14 0 0 0 .59.3l1 .23a2.78 2.78 0 0 1 1.56.83 2 2 0 0 1 .5 1.35 2.12 2.12 0 0 1-.14.75 2 2 0 0 1-.4.66 2.42 2.42 0 0 1-.66.51 2.72 2.72 0 0 1-.9.29v1.7z"
        />
      </g>
    </svg>
  );
}

export default IconDollar;
