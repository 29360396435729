import { ListingDisclaimer } from "@wrstudios/components";
import {
  formatCurrency,
  formatNumber,
  getDateShort,
  getDateTime,
  pluralize
} from "@wrstudios/utils";
import React, { useState } from "react";
import { translate } from "../../../utils/locale";
import Adjustment from "../../common/Adjustment";
import BathsTooltip from "../../common/BathsTooltip";
import { Card } from "../../common/Card";
import HomebeatViewerButton from "./HomebeatViewerButton";
import HomebeatViewerListingToggle from "./HomebeatViewerListingToggle";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import HomebeatViewerValuationModal from "./HomebeatViewerValuationModal";
import {
  Cell,
  Container,
  Date,
  Detail,
  DetailName,
  DetailValue,
  Difference,
  Disclaimer,
  Dom,
  GetValuationContainer,
  Grid,
  IncludeValuationContainer,
  Price,
  PriceDifference,
  PricePerSqft,
  Row,
  RowName,
  RowValue,
  Status
} from "./styled/homebeat-viewer-listing-details-aside";

function HomebeatViewerListingDetailsAside({ listing }) {
  const [showModal, setShowModal] = useState(false);
  const { isAcreageReport } = useHomebeatViewer();
  const diffListPriceToOriginalListPrice =
    listing.listPrice - listing.originalListPrice;
  const diffSoldPriceToListPricePercentage = listing.soldToListPricePercentage;

  return (
    <>
      <Card>
        {(!!listing.status || !!listing.dom || !!listing.price) && (
          <Container>
            <Grid>
              <Cell>
                {!!listing.status && (
                  <Status style={{ color: listing.statusColor }}>
                    {listing.status}
                  </Status>
                )}
                {!!listing.status && !!listing.dom && <> &bull; </>}
                {!!listing.dom && (
                  <Dom>{pluralize("Days", listing.dom, true)}</Dom>
                )}
              </Cell>
              <Cell>
                <IncludeValuationContainer>
                  <HomebeatViewerListingToggle
                    listingId={listing.id}
                    label=""
                  />
                </IncludeValuationContainer>
              </Cell>
            </Grid>
            {!!listing.price && (
              <Price>
                {formatCurrency(listing.price)}
                {(!!listing.pricePerSqft || !!listing.pricePerAcre) && (
                  <PricePerSqft>
                    {isAcreageReport ? (
                      <>{formatCurrency(listing.pricePerAcre)}/acre</>
                    ) : (
                      <>{formatCurrency(listing.pricePerSqft)}/sqft</>
                    )}
                  </PricePerSqft>
                )}
              </Price>
            )}
          </Container>
        )}
        {(!!listing.beds || !!listing.baths || !!listing.sqft) && (
          <Container>
            {!!listing.beds && (
              <Detail>
                <DetailValue>
                  {formatNumber(listing.beds, "0[,][.][0]0")}
                </DetailValue>{" "}
                <DetailName>{pluralize("beds", listing.beds)}</DetailName>
              </Detail>
            )}
            {!!listing.baths && (
              <Detail>
                <DetailValue>
                  <BathsTooltip baths={listing.formattedBaths}>
                    {formatNumber(listing.baths, "0[,][.][0]0")}
                  </BathsTooltip>
                </DetailValue>{" "}
                <DetailName>{pluralize("baths", listing.baths)}</DetailName>
              </Detail>
            )}
            {!!listing.sqft && (
              <Detail>
                <DetailValue>
                  {formatNumber(listing.sqft, "0[,][.][0]0")}
                </DetailValue>{" "}
                <DetailName>sqft</DetailName>
              </Detail>
            )}
          </Container>
        )}
        {(!!listing.listDate || !!listing.soldDate) && (
          <Container>
            {!!listing.listDate && (
              <Date>Listed: {getDateShort(listing.listDate)}</Date>
            )}
            {!!listing.soldDate && (
              <Date>Sold: {getDateShort(listing.soldDate)}</Date>
            )}
          </Container>
        )}
        {!!listing.originalListPrice && (
          <Container>
            <Row>
              <RowName>Original List Price</RowName>
              <RowValue>{formatCurrency(listing.originalListPrice)}</RowValue>
            </Row>
          </Container>
        )}
        {!!listing.listPrice && (
          <Container>
            <Row>
              <RowName>
                List Price
                {!!diffListPriceToOriginalListPrice && (
                  <Difference value={diffListPriceToOriginalListPrice * -1}>
                    &nbsp;
                    <Adjustment
                      value={
                        diffListPriceToOriginalListPrice < 0
                          ? diffListPriceToOriginalListPrice + 1
                          : diffListPriceToOriginalListPrice - 1
                      }
                      adjustedValue={diffListPriceToOriginalListPrice}
                    />
                  </Difference>
                )}
              </RowName>
              <RowValue isDim={listing.originalListPrice === listing.listPrice}>
                {formatCurrency(listing.listPrice)}
              </RowValue>
            </Row>
          </Container>
        )}
        {!!listing.soldPrice && (
          <Container>
            <Row>
              <RowName>
                Sold Price
                {!!diffSoldPriceToListPricePercentage && (
                  <PriceDifference>
                    &nbsp;
                    {formatNumber(
                      diffSoldPriceToListPricePercentage,
                      "0[.][,][0]"
                    )}
                    %
                  </PriceDifference>
                )}
              </RowName>
              <RowValue>{formatCurrency(listing.soldPrice)}</RowValue>
            </Row>
          </Container>
        )}
        <GetValuationContainer>
          <HomebeatViewerButton isFullWidth onClick={() => setShowModal(true)}>
            Get Valuation
          </HomebeatViewerButton>
        </GetValuationContainer>
      </Card>
      <Disclaimer>
        <ListingDisclaimer
          mlsnum={listing.mlsNumber}
          mlsnumLabel={translate("mls.label", "MLS")}
          updatedAtDateShort={getDateShort(listing.updatedAt)}
          updatedAtTime={getDateTime(listing.updatedAt)}
          officeName={listing.listingOffice.name}
        />
      </Disclaimer>
      {showModal && (
        <HomebeatViewerValuationModal onClose={() => setShowModal(false)} />
      )}
    </>
  );
}

export default HomebeatViewerListingDetailsAside;
