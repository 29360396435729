import React from "react";
import { deleteSystemNotification } from "../../api/user";
import { useSession } from "../../Session";
import Notification from "./Notification";
import { Container } from "./styled/notifications";

function Notifications() {
  const { systemNotification, setSystemNotification } = useSession();

  if (!systemNotification) return null;

  const handleClose = () => {
    setSystemNotification(null);
    deleteSystemNotification(systemNotification.id);
  };

  return (
    <Container>
      <Notification
        message={systemNotification.message}
        type={systemNotification.style}
        onClose={handleClose}
      />
    </Container>
  );
}

export default Notifications;
