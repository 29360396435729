import AddressAutoComplete from "../components/address/AddressAutoComplete";
import OldAddressAutoComplete from "../components/address/old/AddressAutoComplete";
import OldAddressAutoCompleteWMHW from "../components/address/old/AddressAutoComplete_wmhw";
import Breadcrumbs from "../components/breadcrumbs/Breadcrumbs";
import CMAs from "../components/cma/CMAs";
import Flash from "../components/common/Flash";
import Navigation from "../components/common/Navigation";
import ReduxInjector from "../components/common/ReduxInjector";
import MoreCriteria from "../components/criteria/MoreCriteria";
import Customize from "../components/customize/Customize";
import CustomizeRdcBanner from "../components/customize/CustomizeRdcBanner";
import Documents from "../components/document/Documents";
import Dropzone from "../components/dropzone/Dropzone";
import Flyers from "../components/flyer/Flyers";
import ActionLink from "../components/form/ActionLink";
import FormUpdate from "../components/forms/FormUpdate";
import SubjectPropertyFormPartial from "../components/forms/cma/partials/SubjectPropertyFormPartial";
import Home from "../components/home/Home";
import HomebeatEdit from "../components/homebeat/HomebeatEdit";
import HomebeatNew from "../components/homebeat/HomebeatNew";
import Homebeats from "../components/homebeat/Homebeats";
import HomebeatsImport from "../components/homebeat/HomebeatsImport";
import HomebeatViewer from "../components/homebeat/viewer/HomebeatViewer";
import AdvancedOptions from "../components/options/AdvancedOptions";
import Properties from "../components/property/Properties";
import Publish from "../components/publish/Publish";
import Report from "../components/report/Report";
import Settings from "../components/settings/Settings";
import TourBanner from "../components/tour/TourBanner";
import Tours from "../components/tour/Tours";
import UserUnsubscribe from "../components/user/UserUnsubscribe";

// Notes: This is until we change to a SPA
export default {
  ActionLink,
  AddressAutoComplete,
  AdvancedOptions,
  Breadcrumbs,
  Customize,
  Dropzone,
  Flash,
  FormUpdate,
  MoreCriteria,
  Navigation,
  OldAddressAutoComplete,
  OldAddressAutoCompleteWMHW,
  Report,
  SubjectPropertyFormPartial,
  Publish,
  UserUnsubscribe,
  ReduxInjector,

  // TODO: Will be updated to react router routes once all work is completed.
  Home,
  CMAs,
  Tours,
  TourBanner,
  CustomizeRdcBanner,
  Properties,
  Flyers,
  Documents,
  Homebeats,
  HomebeatNew,
  HomebeatEdit,
  HomebeatsImport,
  HomebeatViewer,
  Settings
};
