import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { addNetSheetItem } from "../../actions/subject-property";
import { initialRequestState } from "../../utils/redux";
import { stripNumber } from "../../utils/number";
import { formatCurrentInputValue } from "../../utils/string";
import { Input, InputCurrency } from "./styled/net-sheet-item-add";

class NetSheetListItemAdd extends Component {
  static propTypes = {
    subjectPropertyId: PropTypes.number.isRequired,
    addNetSheetItem: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = this.initialState();

    this.add = this.add.bind(this);
    this.onKeyUp = this.onKeyUp.bind(this);
  }

  initialState() {
    const dateId = new Date().getSeconds() * new Date().getMilliseconds();

    return {
      ...initialRequestState({
        id: dateId,
        dateId,
        name: "",
        value: "",
        nameFocus: false
      })
    };
  }

  add() {
    const { subjectPropertyId, addNetSheetItem } = this.props;
    const { name } = this.state;

    addNetSheetItem(subjectPropertyId, {
      ...this.state,
      value: stripNumber(this.state.value),
      key: name
    });

    this.setState({ ...this.initialState(), nameFocus: true });
  }

  onKeyUp(e) {
    const { name, value } = this.state;

    if (e.key === "Enter" && name && value) {
      this.add();
    }
  }

  render() {
    const { dateId, name, value, nameFocus } = this.state;

    return (
      <div className="tabley-row">
        <div className="tabley-cell break-all py-0">
          <Input
            key={`text-${dateId}`}
            value={name}
            placeholder="Expense Name"
            focus={nameFocus}
            onKeyUp={this.onKeyUp}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        <div className="tabley-cell text-right break-all py-0">
          <InputCurrency
            key={`currency-${dateId}`}
            value={formatCurrentInputValue(value)}
            placeholder="$0"
            onKeyUp={this.onKeyUp}
            onChange={(e) => this.setState({ value: e.target.value })}
          />
        </div>
        <div className="tabley-cell text-right">
          <div className={`${name.length && value.length ? "" : "invisible"}`}>
            <button
              className="inline-block h-s7 text-green"
              type="button"
              onClick={this.add}>
              Save
            </button>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  subjectPropertyId: state.subjectProperty.id
});

const mapDispatchToProps = {
  addNetSheetItem
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NetSheetListItemAdd);
