import React, { useState } from "react";
import { useQuery } from "@apollo/react-hooks";
import { useHistory, useParams } from "react-router-dom";
import HomebeatEditor from "./editor/HomebeatEditor";
import { GET_HOMEBEAT } from "./queries";

function HomebeatEdit() {
  const history = useHistory();
  const params = useParams();
  const [editorKey, setEditorKey] = useState(Math.random());
  const { data, loading } = useQuery(GET_HOMEBEAT, {
    variables: { id: params.id },
    onCompleted: () => setEditorKey(Math.random())
  });

  return (
    <HomebeatEditor
      key={editorKey}
      homebeats={loading ? [] : [data.homebeat]}
      isLoading={loading}
      isEditing
      onFinish={() => history.push("/homebeats")}
    />
  );
}

export default HomebeatEdit;
