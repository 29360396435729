import styled from "styled-components";

export const Container = styled.span`
  color: ${({ theme, shouldDim }) =>
    shouldDim && theme.styleGuide.colors.gray[700]};
`;

export const Arrow = styled.span`
  color: ${({ theme, isPositive }) =>
    isPositive
      ? theme.styleGuide.colors.green[700]
      : theme.styleGuide.colors.red[600]};
`;
