import {
  LISTINGS_ADD,
  LISTINGS_ADD_ADJUSTMENT,
  LISTINGS_GET,
  LISTINGS_REMOVE,
  LISTINGS_REMOVE_ADJUSTMENT,
  LISTINGS_REORDER,
  LISTINGS_RESET_LISTING_REQUEST,
  LISTINGS_SORT,
  LISTINGS_TOGGLE,
  LISTINGS_UPDATE,
  LISTINGS_UPDATE_ADJUSTMENT,
  LISTINGS_UPDATE_DESCRIPTION,
  LISTINGS_UPDATE_NOTE
} from "../actionTypes/listings";
import { api } from "../utils/redux";

export function resetListingRequest(listingId) {
  return { type: LISTINGS_RESET_LISTING_REQUEST, payload: { id: listingId } };
}

export function sort(reportId, sortDirection, sortBy) {
  return api(
    LISTINGS_SORT,
    { sortDirection, sortBy },
    {
      method: "post",
      type: "text",
      url: `/listings/sort_by/${reportId}`,
      data: `key=${sortBy}&order=${sortDirection}`
    }
  );
}

export function toggle(reportId, comparableId, toggleState) {
  let url = `/reports/${reportId}/listings/${comparableId}/hide`;

  if (typeof toggleState !== "undefined") {
    url = `/reports/${reportId}/${toggleState ? "select_all" : "unselect_all"}`;
  }

  return api(
    LISTINGS_TOGGLE,
    { comparableId, toggleState },
    { method: "post", url }
  );
}

export function reorder(reportId, startIndex, endIndex) {
  return api(
    LISTINGS_REORDER,
    { startIndex, endIndex },
    {
      method: "post",
      type: "text",
      url: `/reports/${reportId}/listings/sort`,
      data: ({ listings }) =>
        listings.all.map(({ id }) => `report[]=${Number(id)}`).join("&")
    }
  );
}

export function addListing(reportId, formData, listings) {
  const newListingHasSqft = !!formData.get("listing[data][sqft]");
  const foundSqft = !!listings.map((listing) => listing.sqft);
  const hasSqft = newListingHasSqft || foundSqft;

  return api(
    LISTINGS_ADD,
    { includes: { sqft: hasSqft } },
    {
      method: "post",
      url: `/reports/${reportId}/listings`,
      data: formData,
      ensure: true
    }
  );
}

export function updateListing(reportId, listingId, formData) {
  return api(
    LISTINGS_UPDATE,
    {},
    {
      method: "put",
      url: `/reports/${reportId}/listings/${listingId}`,
      data: formData,
      ensure: true
    }
  );
}

export function getListings(reportId, reportType) {
  return api(
    LISTINGS_GET,
    {},
    {
      method: "get",
      url: `/${reportType}/${reportId}/loading_status`,
      ensure: true
    }
  );
}

export function updateDescription(reportId, listingId, description) {
  return api(
    LISTINGS_UPDATE_DESCRIPTION,
    { listingId },
    {
      method: "put",
      url: `/reports/${reportId}/listings/${listingId}`,
      ensure: true,
      data: { listing: { remarks: description } }
    }
  );
}

export function removeListing(reportId, listingId) {
  return api(
    LISTINGS_REMOVE,
    { listingId },
    {
      method: "delete",
      url: `/reports/${reportId}/listings/${listingId}`,
      ensure: true
    }
  );
}

export function removeAdjustment(listingId, adjustmentId) {
  if (String(adjustmentId).includes("-")) {
    return {
      type: `${LISTINGS_REMOVE_ADJUSTMENT}_REQUEST`,
      payload: { listingId, adjustmentId }
    };
  }

  return api(
    LISTINGS_REMOVE_ADJUSTMENT,
    { listingId, adjustmentId },
    { method: "delete", url: `/listings/${listingId}/${adjustmentId}` }
  );
}

export function addAdjustment(listingId, adjustment) {
  return api(
    LISTINGS_ADD_ADJUSTMENT,
    { listingId, uuid: adjustment.id, adjustment },
    {
      method: "post",
      url: `/listings/${listingId}`,
      ensure: true,
      data: {
        adjustment: {
          name: adjustment.name,
          value: String(adjustment.value)
        }
      }
    }
  );
}

export function updateAdjustment(listingId, adjustment) {
  return api(
    LISTINGS_UPDATE_ADJUSTMENT,
    { listingId, adjustment },
    {
      method: "put",
      url: `/listings/${listingId}/${adjustment.id}`,
      ensure: true,
      data: {
        adjustment: {
          name: adjustment.name,
          value: String(adjustment.value)
        }
      }
    }
  );
}

export function updateListingNote(listingId, note) {
  return {
    type: LISTINGS_UPDATE_NOTE,
    payload: { listingId, note }
  };
}
