import React from "react";

function IconYourBrand(props) {
  return (
    <svg viewBox="0 0 96 78" {...props}>
      <defs>
        <style>
          {
            ".Your_brand_svg__cls-1{fill:#c4c8d0}.Your_brand_svg__cls-2{fill:#576474}.Your_brand_svg__cls-3{fill:#fff}"
          }
        </style>
      </defs>
      <g id="Your_brand_svg__Layer_1" data-name="Layer 1">
        <path
          className="Your_brand_svg__cls-1"
          d="M94.44 18.06a5.26 5.26 0 0 0-3.76-1.57H68.89a1 1 0 0 0 0 2h21.79A3.33 3.33 0 0 1 94 21.82v50.86A3.32 3.32 0 0 1 90.63 76l-85.3-.08A3.33 3.33 0 0 1 2 72.59V21.73a3.32 3.32 0 0 1 3.32-3.32h21.59a1 1 0 0 0 0-2H5.33a5.32 5.32 0 0 0-5.28 5.32L0 72.59a5.29 5.29 0 0 0 1.55 3.76 5.35 5.35 0 0 0 3.77 1.57l85.3.08A5.32 5.32 0 0 0 96 72.68V21.82a5.25 5.25 0 0 0-1.56-3.76z"
        />
        <path
          className="Your_brand_svg__cls-1"
          d="M12.15 43.55a1 1 0 1 0 0 2h44.82a1 1 0 0 0 0-2H12.16z"
        />
        <path
          className="Your_brand_svg__cls-2"
          d="M65.84 45.6h17.35a1 1 0 0 0 0-2H65.84a1 1 0 0 0 0 2z"
        />
        <path
          className="Your_brand_svg__cls-1"
          d="M83.18 53.24H38.37a1 1 0 1 0 0 2h44.81a1 1 0 0 0 0-2z"
        />
        <path
          className="Your_brand_svg__cls-2"
          d="M12.14 55.17H29.5a1 1 0 0 0 0-2H12.15a1 1 0 0 0 0 2z"
        />
        <path
          className="Your_brand_svg__cls-1"
          d="M12.13 65.12h44.82a1 1 0 0 0 0-2l-44.81-.05a1 1 0 0 0-1 1 1 1 0 0 0 .99 1.05z"
        />
        <path
          className="Your_brand_svg__cls-2"
          d="M83.17 63.19H65.82a1 1 0 0 0 0 2h17.35a1 1 0 0 0 1-1 1 1 0 0 0-1-1z"
        />
        <path
          className="Your_brand_svg__cls-3"
          d="M46.81 26.86l-.42-.64c-2.83-.28-6.6-1-8.48.69a14.43 14.43 0 0 0 8.92 4l.74-3.49a1.32 1.32 0 0 1-.76-.56zm2.82-.66l-.43.66a1.35 1.35 0 0 1-.76.6l.74 3.54a14.5 14.5 0 0 0 8.89-4c-1.95-2-5.51-1.13-8.44-.8z"
        />
        <path
          className="Your_brand_svg__cls-1"
          d="M49.2 26.86l.43-.66H48c-.96 0-1.61.06-1.61.06l.42.64a1.32 1.32 0 0 0 .76.59l-.74 3.49c.35 0 .7.06 1.05.07H48c.4 0 .79 0 1.18-.06l-.74-3.49a1.35 1.35 0 0 0 .76-.64z"
        />
        <path
          d="M48.15 0H48a16.5 16.5 0 0 0-16.5 16.33v.13A16.51 16.51 0 0 0 47.86 33H48a16.51 16.51 0 0 0 16.5-16.36v-.14A16.5 16.5 0 0 0 48.15 0zm-8 16.6v-1a1.25 1.25 0 0 1 .85-1.06c-.06-.61-.1-1.52-.1-2.89C40.92 7 45.18 6 47.06 6a9.73 9.73 0 0 1 4.58 1.1 3.45 3.45 0 0 1 1 1.11c.13.08.43-.31 1 .41a4.64 4.64 0 0 1 1.12 3c0 1.33 0 2.22-.09 2.84a1.29 1.29 0 0 1 1 1.16v1.18A1.31 1.31 0 0 1 54.4 18v.64c-.16 2.33-3 6-6.45 6s-6.28-3.71-6.44-6V18a1.31 1.31 0 0 1-1.32-1.2zm9 14.35c-.39 0-.78.06-1.18.06h-.12c-.35 0-.7 0-1.05-.07a14.43 14.43 0 0 1-8.92-4c1.88-1.65 5.65-1 8.48-.69 0 0 .65-.06 1.61-.06h1.63c2.93-.33 6.49-1.18 8.44.73A14.5 14.5 0 0 1 49.18 31z"
          fill="#fd5154"
        />
        <path
          className="Your_brand_svg__cls-3"
          d="M41.45 18v.64c.16 2.33 3 6 6.44 6s6.29-3.71 6.45-6V18a1.31 1.31 0 0 0 1.32-1.2v-1.15a1.29 1.29 0 0 0-1-1.16 4.5 4.5 0 0 1-.23 1.37l-.23.14a.27.27 0 0 1-.33-.26v-1.97a14.34 14.34 0 0 1-.92-2 15.7 15.7 0 0 1-.57-2.17s-1.54 2.19-6.13 1.71a9.08 9.08 0 0 1-3.1-.92 9 9 0 0 1-.42 1.42 14 14 0 0 1-.91 1.76v2a.27.27 0 0 1-.33.26l-.25-.1a3.61 3.61 0 0 1-.24-1.19 1.25 1.25 0 0 0-.89 1.11v1.15a1.31 1.31 0 0 0 1.34 1.2zm4.93 1.89h3.21v.42a1.61 1.61 0 0 1-3.21 0z"
        />
        <path
          d="M41.49 15.87a.27.27 0 0 0 .33-.26v-2a14 14 0 0 0 .91-1.76 9 9 0 0 0 .42-1.42 9.08 9.08 0 0 0 3.1.92c4.59.48 6.13-1.71 6.13-1.71a15.7 15.7 0 0 0 .62 2.17 14.34 14.34 0 0 0 .92 2v1.92a.27.27 0 0 0 .33.26l.25-.09a4.5 4.5 0 0 0 .23-1.37c0-.62.09-1.51.09-2.84a4.64 4.64 0 0 0-1.12-3c-.6-.72-.9-.33-1-.41a3.45 3.45 0 0 0-1-1.11A9.73 9.73 0 0 0 47.06 6c-1.88 0-6.14.94-6.14 5.64 0 1.37 0 2.28.1 2.89a3.61 3.61 0 0 0 .22 1.23z"
          fill="#506475"
        />
        <path
          className="Your_brand_svg__cls-1"
          d="M48 21.91a1.6 1.6 0 0 0 1.61-1.6v-.42h-3.23v.42a1.6 1.6 0 0 0 1.62 1.6z"
        />
      </g>
    </svg>
  );
}

export default IconYourBrand;
