import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, customPages: requestState(state.customPages) };
}

export function resolved(state, action) {
  let selected = action.payload;
  let inactivePages = [...state.inactive.pages];

  selected.value = selected.value || "";
  selected.title = selected.title || selected.key;
  selected.canModify = selected.canModify || true;
  selected.position = selected.position || 99999;
  selected.active = selected.active || false;
  selected.parent = selected.parent || "user_custom_pages";
  selected.inactive_group = selected.inactive_group || "inactive_pages";

  // Force directly to resolved state
  selected = resolvedState(selected);

  // Add custom pages element if non existent
  const customPagesParent = {
    key: "user_custom_pages",
    title: "Custom Pages",
    position: 99998,
    active: false,
    parent: null,
    is_parent: true,
    inactive_group: "inactive_pages",
    canModify: false,
    request: { fetching: false, fetched: false, error: {}, data: {} }
  };

  const hasCustomPagesParent = !!inactivePages.find(
    (page) => page.key === "user_custom_pages"
  );

  if (!hasCustomPagesParent) {
    inactivePages = [...inactivePages, customPagesParent];
  }

  const customPages = [
    ...state.customPages.all["My Custom Pages"],
    [selected.title, selected.key]
  ];

  inactivePages = [...inactivePages, selected];

  return {
    ...state,
    inactive: { ...state.inactive, pages: inactivePages },
    customPages: resolvedState({
      ...state.customPages,
      all: {
        ...state.customPages.all,
        "My Custom Pages": customPages
      },
      selected
    })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    customPages: rejectedState(state.customPages, action.payload.error)
  };
}
