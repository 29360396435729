import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { MAX_NOTE_LENGTH } from "../../utils/storage";
import Button from "../common/Button";
import Input from "../common/Input";
import Loading from "../common/Loading";
import { Counter, Footer } from "./styled/agent-note";

export default function AgentNote({
  id,
  note,
  placeholder,
  onSubmit,
  onChange
}) {
  const [currentNote, setCurrentNote] = useState(note || "");
  const [isUpdating, setIsUpdating] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [updateError, setUpdateError] = useState();

  const isMountedRef = useRef();
  useEffect(() => {
    isMountedRef.current = true;

    let timeout;
    if (isSaved) {
      timeout = setTimeout(() => {
        if (isMountedRef.current) {
          setIsSaved(false);
        }
      }, 2000);
    }

    return () => {
      isMountedRef.current = false;
      clearTimeout(timeout);
    };
  }, [isSaved]);

  return (
    <Wrapper
      onSubmit={
        !onSubmit
          ? undefined
          : async (e) => {
              try {
                e.preventDefault();
                setIsUpdating(true);
                setUpdateError(undefined);
                await onSubmit(currentNote);
                setIsSaved(true);
              } catch (error) {
                setUpdateError(error.message);
              } finally {
                setIsUpdating(false);
              }
            }
      }>
      <Input
        id={id}
        value={currentNote}
        as="textarea"
        rows={6}
        placeholder={placeholder}
        maxLength={MAX_NOTE_LENGTH}
        style={{ resize: "vertical" }}
        onChange={(e) => {
          setCurrentNote(e.target.value);
          onChange?.(e);
        }}
      />
      <Counter>
        {currentNote?.length}/{MAX_NOTE_LENGTH} Characters
      </Counter>
      {!!onSubmit && (
        <Footer>
          <div>
            {updateError && (
              <Error className="text-red text-sm">{updateError}</Error>
            )}
          </div>
          <Button app="cma" disabled={isUpdating || isSaved}>
            {!isUpdating && !isSaved && !note && "Add Note"}
            {!isUpdating && !isSaved && note && "Update Note"}
            {isSaved && "Notes Updated!"}
            <Loading showSpinner={isUpdating}>{isUpdating && "Saving"}</Loading>
          </Button>
        </Footer>
      )}
    </Wrapper>
  );
}

AgentNote.propTypes = {
  note: PropTypes.string,
  placeholder: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func
};

function Wrapper({ children, onSubmit }) {
  if (!onSubmit) return children;

  return <form onSubmit={onSubmit}>{children}</form>;
}
