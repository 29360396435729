import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/input";

const Input = forwardRef(({ isFullWidth, ...props }, ref) => {
  return <Container {...props} ref={ref} isFullWidth={isFullWidth} />;
});

Input.defaultProps = {
  isFullWidth: true
};

Input.propTypes = {
  isFullWidth: PropTypes.bool
};

export default Input;
