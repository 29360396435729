import {
  SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_COPY_NET_SHEET,
  SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_UPDATE_LIST_PRICE,
  SUBJECT_PROPERTY_UPDATE_NET_SHEET_ITEMS,
  SUBJECT_PROPERTY_UPDATE_NOTE,
  SUBJECT_PROPERTY_UPDATE_PRICE_RANGE
} from "../../actionTypes/subject-property";
import {
  rejected as updateSubjectPropertyListPriceRejected,
  request as updateSubjectPropertyListPriceRequest,
  resolved as updateSubjectPropertyListPriceResolved
} from "./list-price";
import {
  rejected as addRejected,
  request as addRequest,
  resolved as addResolved
} from "./net-sheet/add";
import {
  rejected as copyRejected,
  request as copyRequest,
  resolved as copyResolved
} from "./net-sheet/copy";
import {
  rejected as editRejected,
  request as editRequest,
  resolved as editResolved
} from "./net-sheet/edit";
import {
  rejected as removeRejected,
  request as removeRequest,
  resolved as removeResolved
} from "./net-sheet/remove";
import {
  rejected as reorderRejected,
  request as reorderRequest,
  resolved as reorderResolved
} from "./net-sheet/reorder";

export default function (state = {}, action) {
  switch (action.type) {
    case `${SUBJECT_PROPERTY_COPY_NET_SHEET}_REQUEST`:
      return copyRequest(state, action);
    case `${SUBJECT_PROPERTY_COPY_NET_SHEET}_RESOLVED`:
      return copyResolved(state, action);
    case `${SUBJECT_PROPERTY_COPY_NET_SHEET}_REJECTED`:
      return copyRejected(state, action);

    case `${SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM}_REQUEST`:
      return addRequest(state, action);
    case `${SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM}_RESOLVED`:
      return addResolved(state, action);
    case `${SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM}_REJECTED`:
      return addRejected(state, action);

    case `${SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM}_REQUEST`:
      return removeRequest(state, action);
    case `${SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM}_RESOLVED`:
      return removeResolved(state, action);
    case `${SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM}_REJECTED`:
      return removeRejected(state, action);

    case `${SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM}_REQUEST`:
      return editRequest(state, action);
    case `${SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM}_RESOLVED`:
      return editResolved(state, action);
    case `${SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM}_REJECTED`:
      return editRejected(state, action);

    case `${SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM}_REQUEST`:
      return reorderRequest(state, action);
    case `${SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM}_RESOLVED`:
      return reorderResolved(state, action);
    case `${SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM}_REJECTED`:
      return reorderRejected(state, action);

    case `${SUBJECT_PROPERTY_UPDATE_LIST_PRICE}_REQUEST`:
      return updateSubjectPropertyListPriceRequest(state, action);
    case `${SUBJECT_PROPERTY_UPDATE_LIST_PRICE}_RESOLVED`:
      return updateSubjectPropertyListPriceResolved(state, action);
    case `${SUBJECT_PROPERTY_UPDATE_LIST_PRICE}_REJECTED`:
      return updateSubjectPropertyListPriceRejected(state, action);

    case SUBJECT_PROPERTY_UPDATE_NOTE:
      return { ...state, note: action.payload.note };

    case SUBJECT_PROPERTY_UPDATE_PRICE_RANGE:
      return { ...state, price_range: action.payload.price_range };

    case SUBJECT_PROPERTY_UPDATE_NET_SHEET_ITEMS:
      return { ...state, net_sheet_items: action.payload.net_sheet_items };

    default:
      return state;
  }
}
