import React from "react";
import { Droppable } from "react-beautiful-dnd";
import CustomizeActivePage from "./CustomizeActivePage";

// Since we have recursion on the main pages component,
// this is a wrapper that'll prevent an infinite loop.
const CustomizeActivePagesDroppable = ({
  pages,
  type,
  draggable,
  enableDrag,
  disableDrag
}) => (
  <Droppable droppableId={`droppable-${type}`} type={type}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        className={`${pages.length && type === "pages" ? "-ml-13" : ""}`}>
        {!pages.length && <p>No pages selected.</p>}
        {pages.map((page, index) => (
          <CustomizeActivePage
            key={page.key}
            index={index}
            type={type}
            page={page}
            draggable={draggable}
            isDragDisabled={
              pages.length === 1 || (pages.length > 1 && !draggable)
            }
            enableDrag={enableDrag}
            disableDrag={disableDrag}
          />
        ))}
        {provided.placeholder}
      </div>
    )}
  </Droppable>
);

export default CustomizeActivePagesDroppable;
