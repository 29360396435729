import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Text } from "./styled/tooltip";

function Tooltip({
  text,
  position,
  showArrow,
  fitContent,
  children,
  ...props
}) {
  const [showTip, setShowTip] = useState(false);

  return (
    <Container
      onMouseEnter={() => setShowTip(true)}
      onMouseLeave={() => setShowTip(false)}
      {...props}>
      {!!text && showTip && (
        <Text
          className="tooltip-text"
          position={position}
          showArrow={showArrow}
          fitContent={fitContent}>
          {text}
        </Text>
      )}
      {children}
    </Container>
  );
}

Tooltip.defaultProps = {
  text: "",
  position: "top",
  showArrow: true
};

Tooltip.propTypes = {
  text: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  position: PropTypes.oneOf(["top", "bottom", "left"]),
  showArrow: PropTypes.bool
};

export default Tooltip;
