import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  background: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};
  border-radius: 0.4rem;
`;

export const Body = styled.div`
  display: flex;
  flex-grow: 1;
  padding: 1rem;
`;

export const Footer = styled.div`
  padding: 1rem;
  border-top: 0.1rem solid ${({ theme }) => theme.styleGuide.colors.gray[400]};
`;
