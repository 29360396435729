import { Modal } from "@wrstudios/components";
import { pluralize } from "@wrstudios/utils";
import PropTypes from "prop-types";
import React, { useEffect, useReducer, useRef } from "react";
import { connect } from "react-redux";
import { updatePageMeta } from "../../actions/customize";
import { getPageConfig } from "../../api/page";
import {
  getMyRecentSalesCount,
  saveMyRecentSalesPreferences
} from "../../api/report";
import Button from "../common/Button";
import FormGroup from "../common/FormGroup";
import Input from "../common/Input";
import Label from "../common/Label";
import { Option, Select } from "../common/Select";
import {
  Description,
  Error,
  FooterContent,
  ListingCount,
  Loading,
  ModalBody,
  ModalContent,
  ModalHeader
} from "./styled/customize-modal-my-recent-sales";

function CustomizeModalMyRecentSales({
  reportId,
  page,
  onClose,
  updatePageMeta
}) {
  const [state, dispatch] = useReducer(
    (state, { type, payload }) => {
      switch (type) {
        case "SET_TITLE":
          return { ...state, title: payload };
        case "SET_SORT":
          return { ...state, sort: payload };
        case "SET_SIDES":
          return { ...state, sides: payload };
        case "SUBMIT_REQUESTED":
          return { ...state, error: undefined, isSubmitting: true };
        case "SUBMIT_REJECTED":
          return { ...state, error: payload, isSubmitting: false };
        case "COUNT_REQUESTED":
          return { ...state, error: undefined, isLoadingCount: true };
        case "COUNT_RESOLVED":
          return { ...state, count: payload, isLoadingCount: false };
        case "COUNT_REJECTED":
          return { ...state, error: payload, isLoadingCount: false };
        case "CONFIG_RESOLVED":
          return {
            ...state,
            title: payload.title || state.title || "",
            sort: payload.sort || "closest",
            sides: payload.sides || "both",
            isLoadingPreferences: false
          };
        case "CONFIG_REJECTED":
          return { ...state, error: payload };
        default:
          return state;
      }
    },
    {
      title: page?.title || "",
      sort: "closest", // closest | recency
      sides: "both", // both | seller | buyer
      count: 0,
      isLoadingPreferences: true,
      isLoadingCount: true,
      isSubmitting: false,
      error: undefined
    }
  );

  const isMountedRef = useRef();
  useEffect(() => {
    isMountedRef.current = true;
    return () => {
      isMountedRef.current = false;
    };
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch({ type: "SUBMIT_REQUESTED" });

    saveMyRecentSalesPreferences({
      reportId,
      pageId: page?.key,
      pageTitle: state.title,
      sort: state.sort,
      sides: state.sides
    })
      .then(() => {
        if (!isMountedRef.current) return;

        // Only update if the count has been loaded
        if (!state.isLoadingCount) {
          updatePageMeta(page?.key, { empty: state.count <= 0 });
        }
        onClose();
      })
      .catch((error) => {
        if (!isMountedRef.current) return;
        dispatch({ type: "SUBMIT_REJECTED", payload: error });
      });
  };

  useEffect(() => {
    dispatch({ type: "COUNT_REQUESTED" });
    getMyRecentSalesCount({ reportId, sort: state.sort, sides: state.sides })
      .then((data) => {
        if (!isMountedRef.current) return;
        dispatch({ type: "COUNT_RESOLVED", payload: data.count });
      })
      .catch((error) => {
        if (!isMountedRef.current) return;
        dispatch({ type: "COUNT_REJECTED", payload: error });
      });
  }, [reportId, state.sort, state.sides]);

  useEffect(() => {
    getPageConfig({ reportId, pageId: page.key })
      .then((data) => {
        if (!isMountedRef.current) return;
        dispatch({
          type: "CONFIG_RESOLVED",
          payload: {
            title: data.config?.title,
            sort: data.config?.sort,
            sides: data.config?.sides
          }
        });
      })
      .catch((error) => {
        if (!isMountedRef.current) return;
        dispatch({ type: "CONFIG_REJECTED", payload: error });
      });
  }, [reportId, page.key]);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <form onSubmit={handleSubmit}>
          <ModalHeader>
            <div>
              <div>My Recent Sales</div>
              <Description>
                Show off up to 12 previous sales on one page in your report.
              </Description>
            </div>
            <Modal.Close as="button" />
          </ModalHeader>
          <ModalBody>
            <FormGroup>
              <Label htmlFor="page-title">Page Title</Label>
              <Input
                id="page-title"
                disabled={state.isLoadingPreferences}
                value={state.title}
                onChange={(e) =>
                  dispatch({ type: "SET_TITLE", payload: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="sort">Sort Listings By</Label>
              <Select
                id="sort"
                disabled={state.isLoadingPreferences}
                value={state.sort}
                onChange={(e) =>
                  dispatch({ type: "SET_SORT", payload: e.target.value })
                }>
                <Option value="closest">Closest to subject property</Option>
                <Option value="recency">Recently Updated</Option>
              </Select>
            </FormGroup>
            <FormGroup>
              <Label htmlFor="sides">Sides</Label>
              <Select
                id="sides"
                disabled={state.isLoadingPreferences}
                value={state.sides}
                onChange={(e) =>
                  dispatch({ type: "SET_SIDES", payload: e.target.value })
                }>
                <Option value="both">Both seller and buyer side</Option>
                <Option value="seller">Seller side</Option>
                <Option value="buyer">Buyer Side</Option>
              </Select>
            </FormGroup>
          </ModalBody>
          <Modal.Footer>
            <FooterContent>
              <ListingCount>
                {state.isLoadingCount ? (
                  <Loading />
                ) : (
                  <>
                    {pluralize("Listings", state.count, true)} Found (12
                    Listings Max)
                  </>
                )}
              </ListingCount>
              <Button
                app="cma"
                disabled={state.isSubmitting || state.isLoadingPreferences}>
                {state.isSubmitting && <Loading>Saving</Loading>}
                {!state.isSubmitting && "Save"}
              </Button>
            </FooterContent>
            {state.error && (
              <Error>
                {state.error.message ||
                  "An error occurred while saving your preferences"}
              </Error>
            )}
          </Modal.Footer>
        </form>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalMyRecentSales.propTypes = {
  reportId: PropTypes.number.isRequired,
  page: PropTypes.shape({
    title: PropTypes.string,
    meta: PropTypes.shape({
      sort_by: PropTypes.oneOf(["closest", "recency"]),
      sides: PropTypes.oneOf(["both", "seller", "buyer"])
    })
  }).isRequired,
  onClose: PropTypes.func.isRequired,
  updatePageMeta: PropTypes.func.isRequired
};

export default connect(null, {
  updatePageMeta
})(CustomizeModalMyRecentSales);
