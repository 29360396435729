import styled from "styled-components";

export const Container = styled.div`
  flex-shrink: 0;
  padding-bottom: 64%;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[500]};
  position: relative;

  &:after {
    content: "";
    width: 1px;
    height: 100%;
    background-color: ${({ theme }) => theme.styleGuide.colors.white};
    position: absolute;
    right: 0;
  }

  .first-child & {
    &:after {
      right: -0.1rem;
    }
  }

  .last-child & {
    &:after {
      display: none;
    }
  }

  img,
  [data-error] {
    display: block;
    position: absolute;
  }
`;
