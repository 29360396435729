export const resolved = (state, action) => {
  const { sku } = action.payload;
  let marketingPages = state.powerPacks.pages.marketing;
  let luxPages = state.powerPacks.pages.lux;
  let tomPages = state.powerPacks.pages.tom;
  let offerMarketing = state.powerPacks.offer.marketing;
  let offerLux = state.powerPacks.offer.lux;
  let offerTom = state.powerPacks.offer.tom;

  switch (sku) {
    case "pack_marketing_2013":
      offerMarketing = false;
      marketingPages = marketingPages.map((page) => {
        page.offerMarketing = false;

        return page;
      });
      break;
    case "luxe_power_pack":
      offerLux = false;
      luxPages = luxPages.map((page) => {
        page.offerPowerPack = false;

        return page;
      });
      break;
    case "tom_ferry_lpkt":
      offerTom = false;
      tomPages = tomPages.map((page) => {
        page.offerTomFerry = false;

        return page;
      });
      break;
  }

  return {
    ...state,
    powerPacks: {
      ...state.powerPacks,
      offer: {
        marketing: offerMarketing,
        lux: offerLux,
        tom: offerTom
      },
      pages: {
        ...state.powerPacks.pages,
        marketing: marketingPages,
        lux: luxPages,
        tom: tomPages
      }
    }
  };
};
