import styled from "styled-components";
import BaseInput from "../../common/Input";

export const Input = styled(BaseInput)`
  padding: 0;
  border: 0;
`;

export const InputCurrency = styled(BaseInput)`
  padding: 0;
  border: 0;
  text-align: right;
`;
