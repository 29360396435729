import React from "react";
import IconAddSlim from "../icons/IconAddSlim";
import { Container, Icon } from "./styled/card-create";

function CardCreate({ children, ...props }) {
  return (
    <Container {...props}>
      <Icon>
        <IconAddSlim />
      </Icon>
      {children}
    </Container>
  );
}

export default CardCreate;
