import React from "react";
import PropTypes from "prop-types";
import { formatNumber } from "@wrstudios/utils";
import BathsTooltip from "../common/BathsTooltip";
import { useHighlighter } from "./utils";
import {
  Row,
  Label,
  Text,
  MobileText,
  Value,
  Difference
} from "./styled/comparable";

function ComparableDifferences({
  primaryBeds,
  primaryBaths,
  primaryFormattedBaths,
  primarySqft,
  primaryLotSize,
  primaryGarages,
  secondaryBeds,
  secondaryBaths,
  secondarySqft,
  secondaryLotSize,
  secondaryGarages,
  showBathCalc,
  primarySqftType,
  landMeasurement,
  ...props
}) {
  const { handleMouseEnter, handleMouseLeave } = useHighlighter();

  // This is a use case for CREB where they provide use a string of "True" or "False" for if
  // they have garages but they don't actually provide a number. We convert form "True" or "False"
  // inside of utils/listing.js
  const containsGaragesButNoNumberIsProvided = typeof primaryGarages === 'boolean' // prettier-ignore
  const modifiedPrimaryGarages = containsGaragesButNoNumberIsProvided
    ? 0
    : primaryGarages;

  const getFormattedLandMeasurement = () => {
    if (!landMeasurement) return "";
    if (landMeasurement === "sqft") return "(SqFt)";
    if (landMeasurement === "acres") return "(Acres)";
  };
  // Format numbers before performing calculations to sold precision issues.
  const formattedPrimaryBeds = Number(formatNumber(primaryBeds, "0[.][0]0")); // prettier-ignore
  const formattedSecondaryBeds = Number(formatNumber(secondaryBeds, "0[.][0]0")); // prettier-ignore
  const formattedPrimaryBaths = Number(formatNumber(primaryBaths, "0[.][0]0")); // prettier-ignore
  const formattedSecondaryBaths = Number(formatNumber(secondaryBaths, "0[.][0]0")); // prettier-ignore
  const formattedPrimarySqft = Number(formatNumber(primarySqft, "0[.][0]0")); // prettier-ignore
  const formattedSecondarySqft = Number(formatNumber(secondarySqft, "0[.][0]0")); // prettier-ignore
  const formattedPrimaryLotSize = Number(formatNumber(primaryLotSize, "0[.][0]0")); // prettier-ignore
  const formattedSecondaryLotSize = Number(formatNumber(secondaryLotSize, "0[.][0]0")); // prettier-ignore
  const formattedPrimaryGarages = Number(formatNumber(modifiedPrimaryGarages, "0[.][0]0")); // prettier-ignore
  const formattedSecondaryGarages = Number(formatNumber(secondaryGarages, "0[.][0]0")); // prettier-ignore
  const formattedPrimarySqftType = !!primarySqftType ? `(${primarySqftType})` : ""; // prettier-ignore
  const diffBeds = formattedSecondaryBeds - formattedPrimaryBeds; // prettier-ignore
  const diffBaths = showBathCalc ? formattedSecondaryBaths - formattedPrimaryBaths : 0; // prettier-ignore
  const diffSqft = formattedSecondarySqft - formattedPrimarySqft; // prettier-ignore
  const diffSqftPercentage = (diffSqft / secondarySqft) * 100; // prettier-ignore
  const diffLotSize = formattedSecondaryLotSize - formattedPrimaryLotSize; // prettier-ignore
  const diffLotSizePercentage = (diffLotSize / formattedSecondaryLotSize) * 100; // prettier-ignore
  const diffGarages = formattedSecondaryGarages - formattedPrimaryGarages; // prettier-ignore
  const formattedLandMeasurement = getFormattedLandMeasurement();
  const showDiffBeds = Boolean(formattedPrimaryBeds && formattedSecondaryBeds && diffBeds); // prettier-ignore
  const showDiffBaths = Boolean(formattedPrimaryBaths && formattedSecondaryBaths && diffBaths); // prettier-ignore
  const showDiffSqftPercentage = Boolean(formattedPrimarySqft && formattedSecondarySqft && diffSqft); // prettier-ignore
  const showDiffLotSizePercentage = Boolean(primaryLotSize && secondaryLotSize && diffLotSize); // prettier-ignore
  const showDiffGarages = Boolean(formattedPrimaryGarages && formattedSecondaryGarages && diffGarages); // prettier-ignore

  return (
    <div {...props}>
      <Row
        className="beds"
        data-name="beds"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Beds">
          <Text>Beds</Text>
          <MobileText>Beds</MobileText>
          {showDiffBeds && (
            <Difference value={diffBeds}>
              {diffBeds < 0 ? (
                <>&nbsp;&uarr; {formatNumber(diffBeds * -1, "0[.][,][0]0")}</>
              ) : diffBeds > 0 ? (
                <>&nbsp;&darr; {formatNumber(diffBeds, "0[.][,][0]0")}</>
              ) : null}
            </Difference>
          )}
        </Label>
        <Value
          className="comparable-value"
          title={
            formattedPrimaryBeds
              ? formatNumber(formattedPrimaryBeds, "0[.][,][0]")
              : ""
          }>
          {formattedPrimaryBeds
            ? formatNumber(formattedPrimaryBeds, "0[.][,][0]")
            : "-"}
        </Value>
      </Row>
      <Row
        className="baths"
        data-name="baths"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Baths">
          <Text>Baths</Text>
          <MobileText>Baths</MobileText>
          {showDiffBaths && (
            <Difference value={diffBaths}>
              {diffBaths < 0 ? (
                <>&nbsp;&uarr; {formatNumber(diffBaths * -1, "0[.][,][0]0")}</>
              ) : diffBaths > 0 ? (
                <>&nbsp;&darr; {formatNumber(diffBaths, "0[.][,][0]0")}</>
              ) : null}
            </Difference>
          )}
        </Label>
        <Value className="comparable-value" hasOverflow={true}>
          {formattedPrimaryBaths ? (
            primaryFormattedBaths ? (
              <BathsTooltip
                baths={primaryFormattedBaths}
                position="left"
                showArrow={false}>
                {formatNumber(formattedPrimaryBaths, "0.[0]0")}
              </BathsTooltip>
            ) : formattedPrimaryBaths ? (
              <>{formatNumber(formattedPrimaryBaths, "0.[0]0")}</>
            ) : (
              "-"
            )
          ) : (
            "-"
          )}
        </Value>
      </Row>
      <Row
        className="sqft"
        data-name="sqft"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Sq. Ft.">
          <Text>{`Sq. Ft. ${formattedPrimarySqftType}`}</Text>
          <MobileText>{`Sq. Ft. ${formattedPrimarySqftType}`}</MobileText>
          {showDiffSqftPercentage && (
            <Difference value={diffSqftPercentage}>
              {Math.abs(diffSqftPercentage) >= 0.1 && (
                <>
                  {diffSqftPercentage < 0 ? (
                    <>
                      &nbsp;&uarr;{" "}
                      {formatNumber(diffSqftPercentage * -1, "0,0")}%
                    </>
                  ) : diffSqftPercentage > 0 ? (
                    <>&nbsp;&darr; {formatNumber(diffSqftPercentage, "0,0")}%</>
                  ) : null}
                </>
              )}
              {Math.abs(diffSqftPercentage) < 0.1 && (
                <>
                  {diffSqftPercentage < 0 ? (
                    <>
                      &nbsp;&uarr;{" "}
                      {formatNumber(diffSqftPercentage * -1, "0.0[0]")}%
                    </>
                  ) : diffSqftPercentage > 0 ? (
                    <>
                      &nbsp;&darr; {formatNumber(diffSqftPercentage, "0.0[0]")}%
                    </>
                  ) : null}
                </>
              )}
            </Difference>
          )}
        </Label>
        <Value
          className="comparable-value"
          title={
            formattedPrimarySqft ? formatNumber(formattedPrimarySqft) : ""
          }>
          {formattedPrimarySqft ? formatNumber(formattedPrimarySqft) : "-"}
        </Value>
      </Row>
      <Row
        className="lot-size"
        data-name="lot-size"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Lot Size">
          <Text>{`Lot Size ${formattedLandMeasurement}`}</Text>
          <MobileText>{`Lot Size ${formattedLandMeasurement}`}</MobileText>
          {showDiffLotSizePercentage && (
            <Difference value={diffLotSizePercentage}>
              {Math.abs(diffLotSizePercentage) >= 0.1 && (
                <>
                  {diffLotSizePercentage < 0 ? (
                    <>
                      &nbsp;&uarr;{" "}
                      {formatNumber(diffLotSizePercentage * -1, "0,0")}%
                    </>
                  ) : diffLotSizePercentage > 0 ? (
                    <>
                      &nbsp;&darr; {formatNumber(diffLotSizePercentage, "0,0")}%
                    </>
                  ) : null}
                </>
              )}
              {Math.abs(diffLotSizePercentage) < 0.1 && (
                <>
                  {diffLotSizePercentage < 0 ? (
                    <>
                      &nbsp;&uarr;{" "}
                      {formatNumber(diffLotSizePercentage * -1, "0.0[0]")}%
                    </>
                  ) : diffLotSizePercentage > 0 ? (
                    <>
                      &nbsp;&darr;{" "}
                      {formatNumber(diffLotSizePercentage, "0.0[0]")}%
                    </>
                  ) : null}
                </>
              )}
            </Difference>
          )}
        </Label>
        <Value
          className="comparable-value"
          title={
            formattedPrimaryLotSize
              ? formatNumber(formattedPrimaryLotSize, "0[,][.][0]0")
              : ""
          }>
          {formattedPrimaryLotSize ? (
            <>{formatNumber(formattedPrimaryLotSize, "0[,][.][0]0")}</>
          ) : (
            "-"
          )}
        </Value>
      </Row>
      <Row
        className="garages"
        data-name="garages"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <Label title="Garage Spaces">
          <Text>Garage Spaces</Text>
          <MobileText>Garage Spaces</MobileText>
          {showDiffGarages && (
            <Difference value={diffGarages}>
              {diffGarages < 0 ? (
                <>&nbsp;&uarr; {formatNumber(diffGarages * -1, "0[.][,][0]")}</>
              ) : diffGarages > 0 ? (
                <>&nbsp;&darr; {formatNumber(diffGarages, "0[.][,][0]")}</>
              ) : null}
            </Difference>
          )}
        </Label>
        <Value
          className="comparable-value"
          title={
            containsGaragesButNoNumberIsProvided
              ? !!primaryGarages
                ? "Yes"
                : "-"
              : formattedPrimaryGarages
              ? formatNumber(formattedPrimaryGarages)
              : ""
          }>
          {containsGaragesButNoNumberIsProvided
            ? !!primaryGarages
              ? "Yes"
              : "-"
            : formattedPrimaryGarages
            ? formatNumber(formattedPrimaryGarages)
            : "-"}
        </Value>
      </Row>
    </div>
  );
}

ComparableDifferences.defaultProps = {
  primaryBeds: 0,
  primaryBaths: 0,
  primarySqft: 0,
  primaryLotSize: 0,
  primaryGarages: 0,
  secondaryBeds: 0,
  secondaryBaths: 0,
  secondarySqft: 0,
  secondaryLotSize: 0,
  secondaryGarages: 0,
  showBathCalc: true,
  primarySqftType: "",
  landMeasurement: ""
};

ComparableDifferences.propTypes = {
  primaryBeds: PropTypes.number,
  primaryBaths: PropTypes.number,
  primaryFormattedBaths: PropTypes.string,
  primarySqft: PropTypes.number,
  primaryLotSize: PropTypes.number,
  primaryGarages: PropTypes.oneOfType([PropTypes.number, PropTypes.bool]),
  secondaryBeds: PropTypes.number,
  secondaryBaths: PropTypes.number,
  secondarySqft: PropTypes.number,
  secondaryLotSize: PropTypes.number,
  secondaryGarages: PropTypes.number,
  showBathCalc: PropTypes.bool,
  primarySqftType: PropTypes.string,
  landMeasurement: PropTypes.string
};

export default ComparableDifferences;
