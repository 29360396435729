import React from "react";
import Helmet from "react-helmet";
import UserContactItemsAdd from "../user/UserContactItemsAdd";
import UserContactItems from "../user/UserContactItems";
import { Section, Header, Text } from "./styled/settings-contact-info";

function SettingsContactInfo() {
  return (
    <>
      <Helmet>
        <title>Contact Info - Account Settings - Cloud CMA</title>
      </Helmet>
      <Section>
        <Header>Contact Info</Header>
        <Text>
          Your Contact info will appear at the bottom of each report page.
          Include all your methods of contact. Keep in mind that your contact
          items will appear in the order shown below. You can re-order your list
          by clicking and dragging items.
        </Text>
      </Section>
      <Section>
        <Header>Add New Contact</Header>
        <UserContactItemsAdd />
      </Section>
      <Section>
        <Header>Contact List</Header>
        <UserContactItems />
      </Section>
    </>
  );
}

export default SettingsContactInfo;
