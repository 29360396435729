import React from "react";
import PropTypes from "prop-types";
import { hasAveragePriceAdjustments } from "../../../utils/listings";
import { getTag } from "../../../utils/listing";
import Image from "../../common/Image";
import {
  ComparableImage,
  ComparableAddress,
  ComparableDetails,
  ComparableDifferences
} from "../../comparable";
import { useHomebeatViewer } from "./HomebeatViewerProvider";
import {
  Header,
  ImageContainer,
  Tag,
  Comparable,
  Overflow,
  GlobalStyle
} from "./styled/homebeat-viewer-listing";

function LiveListing({ listing, onClick, ...props }) {
  const {
    listings,
    subjectProperty,
    isAcreageReport,
    showAcresInsteadOfLotSize
  } = useHomebeatViewer();
  const hasPriceAdjustments = hasAveragePriceAdjustments(listings);
  const tag = getTag(listing, listings, subjectProperty);
  const primaryLotSize =
    (showAcresInsteadOfLotSize || isAcreageReport
      ? listing.acres
      : listing.lotSize) || 0;
  const secondaryLotSize =
    (showAcresInsteadOfLotSize || isAcreageReport
      ? subjectProperty.acres
      : subjectProperty.lotSize) || 0;
  const showBathCalc =
    listings.every((listing) => Number.isInteger(Number(listing.baths))) &&
    Number.isInteger(Number(subjectProperty.baths));

  return (
    <>
      <GlobalStyle />
      <Comparable {...props} onClick={onClick}>
        <ImageContainer>
          {!!tag && <Tag>{tag}</Tag>}
          <ComparableImage>
            <Image
              src={listing.photos[0]}
              data-error="Image Unavailable"
              alt="Image Unavailable"
              onClick={onClick}
            />
          </ComparableImage>
        </ImageContainer>
        <ComparableAddress
          street={listing.street}
          cityStateZip={listing.cityStateZip}
          price={listing.price}
          adjustedPrice={listing.adjustedPrice}
          status={listing.status}
          statusColor={listing.statusColor}
        />
        <Overflow>
          <ComparableDifferences
            primaryBeds={listing.beds}
            primaryBaths={listing.baths}
            primaryFormattedBaths={listing.formattedBaths}
            primarySqft={listing.sqft}
            primaryLotSize={primaryLotSize}
            primaryGarages={listing.garages || listing.hasGarage}
            secondaryBeds={subjectProperty.beds}
            secondaryBaths={subjectProperty.baths}
            secondarySqft={subjectProperty.sqft}
            secondaryLotSize={secondaryLotSize}
            secondaryGarages={subjectProperty.garages}
            hasAcres={isAcreageReport}
            showAcresInsteadOfLotSize={showAcresInsteadOfLotSize}
            showBathCalc={showBathCalc}
          />
          <Header
            title="Listing Details"
            className="font-primary comparable-group-header">
            Listing Details
          </Header>
          <ComparableDetails
            originalListPrice={listing.originalListPrice}
            listPrice={listing.listPrice}
            soldPrice={listing.soldPrice}
            price={listing.price}
            adjustedPrice={listing.adjustedPrice}
            soldToListPricePercentage={listing.soldToListPricePercentage}
            showAdjustments={hasPriceAdjustments}
            soldDate={listing.soldDate}
            pricePerSqft={listing.pricePerSqft}
            adjustedPricePerSqft={listing.adjustedPricePerSqft}
            pricePerAcre={listing.pricePerAcre}
            adjustedPricePerAcre={listing.adjustedPricePerAcre}
            dom={listing.dom}
            hasAcres={isAcreageReport}
            virtualTour={listing.virtualTour}
          />
        </Overflow>
      </Comparable>
    </>
  );
}

LiveListing.defaultProps = {
  onClick: () => {}
};

LiveListing.propTypes = {
  listing: PropTypes.object.isRequired,
  onClick: PropTypes.func
};

export default LiveListing;
