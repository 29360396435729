import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { toggle } from "../../actions/listings";
import IconIncludeAll from "../icons/IconIncludeAll";
import IconExcludeAll from "../icons/IconExcludeAll";

function ListingsControlsToggle({ reportId, toggle }) {
  return (
    <Fragment>
      <button
        className="text-sm text-grey"
        onClick={() => toggle(reportId, undefined, true)}>
        <span className="mr-4">
          <IconIncludeAll />
        </span>
        <span>Include All</span>
      </button>
      <button
        className="text-sm text-grey ml-12"
        onClick={() => toggle(reportId, undefined, false)}>
        <span className="mr-4">
          <IconExcludeAll />
        </span>
        <span>Exclude All</span>
      </button>
    </Fragment>
  );
}

ListingsControlsToggle.propTypes = {
  reportId: PropTypes.number.isRequired,
  toggle: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  reportId: state.report.id
});

const mapDispatchToProps = (dispatch) => ({
  toggle: (reportId, listingId, toggleState) =>
    dispatch(toggle(reportId, listingId, toggleState))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListingsControlsToggle);
