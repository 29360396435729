import { Modal as BaseModal } from "@wrstudios/components";
import styled from "styled-components";
import BaseLoading from "../../common/Loading";

export const Loading = styled(BaseLoading)`
  vertical-align: middle;
`;

export const ModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 53.2rem;
`;

export const ModalBody = styled(BaseModal.Body)`
  padding: 2rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
`;

export const ModalHeader = styled(BaseModal.Header)`
  align-items: flex-start;
`;

export const FooterContent = styled(BaseModal.Footer)`
  display: flex;
  align-items: center;
`;

export const ListingCount = styled.div`
  flex-grow: 1;
  padding-right: 0.8rem;
`;

export const Error = styled.div`
  font-size: 1.4rem;
  line-height: 2rem;
  margin-top: 1rem;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};
`;

export const Description = styled.div`
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 2rem;
  margin-top: 1.2rem;
`;
