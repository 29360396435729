import React, { useLayoutEffect, useRef, Children } from "react";
import PropTypes from "prop-types";
import ComparableScrollbar from "./ComparableScrollbar";
import { Container, GridContainer, Grid, Cell } from "./styled/comparables";

function Comparables({ children, hasFooter, scrollLeft, onScroll, ...props }) {
  const gridContainer = useRef();
  const gridRef = useRef();
  const subjectPropertyRef = useRef();
  const secondComparableRef = useRef();
  const scrollbarRef = useRef();

  const handleScroll = (direction) => {
    if (!secondComparableRef.current) return;

    const currentGridScrollLeft = gridContainer.current.scrollLeft;
    const comparableWidth = Math.round(
      secondComparableRef.current.getBoundingClientRect().width
    );
    const comparableMarginLeft = parseInt(
      window.getComputedStyle(secondComparableRef.current).marginLeft,
      10
    );

    let newGridScrollLeft =
      currentGridScrollLeft +
      comparableWidth * (direction === "next" ? 1 : -1) +
      comparableMarginLeft * (direction === "next" ? 1 : -1);

    if (newGridScrollLeft <= 0) newGridScrollLeft = 0;
    gridContainer.current.scroll({
      left: newGridScrollLeft,
      behavior: "smooth"
    });
  };

  useLayoutEffect(() => {
    const subjectPropertyRect =
      subjectPropertyRef.current.getBoundingClientRect();
    const width = subjectPropertyRect.width * Children.count(children);
    gridRef.current.style.width = `${width}px`;
  }, [children]);

  // We manually update display so there is no lag on scroll.
  useLayoutEffect(() => {
    const handler = () => {
      gridContainer.current.scrollLeft > 0
        ? subjectPropertyRef.current.classList.add("scrolling")
        : subjectPropertyRef.current.classList.remove("scrolling");

      const bodyWidth = document.body.getBoundingClientRect().width;
      const gridWidth = gridRef.current.getBoundingClientRect().width;
      gridWidth > bodyWidth
        ? scrollbarRef.current.classList.add("show")
        : scrollbarRef.current.classList.remove("show");
      gridWidth > bodyWidth
        ? gridContainer.current.classList.add("pad")
        : gridContainer.current.classList.remove("pad");
    };
    handler();
    gridContainer.current.addEventListener("scroll", handler);
    window.addEventListener("resize", handler);
    return () => {
      gridContainer.current.removeEventListener("scroll", handler);
      window.removeEventListener("resize", handler);
    };
  });

  // Restore scroll position if coming from a listing
  useLayoutEffect(() => {
    gridContainer.current.scrollLeft = scrollLeft;
  }, [scrollLeft]);

  return (
    <Container>
      <GridContainer
        {...props}
        ref={gridContainer}
        hasFooter={hasFooter}
        onScroll={onScroll}>
        <Grid ref={gridRef}>
          {Children.map(children, (child, index) => (
            <Cell
              ref={
                index === 0
                  ? subjectPropertyRef
                  : index === 1
                  ? secondComparableRef
                  : null
              }
              className={
                index === 0
                  ? "first-child"
                  : index === Children.count(children) - 1
                  ? "last-child"
                  : "child"
              }
              key={index}>
              {child}
            </Cell>
          ))}
        </Grid>
      </GridContainer>
      <ComparableScrollbar
        hasFooter={hasFooter}
        ref={scrollbarRef}
        onPrev={() => handleScroll("prev")}
        onNext={() => handleScroll("next")}
      />
    </Container>
  );
}

Comparables.defaultProps = {
  scrollLeft: 0
};

Comparables.propTypes = {
  hasFooter: PropTypes.bool,
  scrollLeft: PropTypes.number.isRequired,
  onScroll: PropTypes.func
};

export default Comparables;
