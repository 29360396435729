import styled from "styled-components";
import { default as BaseButton } from "../../common/Button";
import { default as BaseTip } from "../../common/Tip";

export const Tip = styled(BaseTip)`
  display: block;
  text-align: center;
`;

export const Grid = styled.div`
  display: flex;
  margin: -0.5rem;

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `}
`;

export const Cell = styled.div`
  width: 50%;
  padding: 0.5rem;

  ${({ theme }) => theme.media.sm`
    width: 100%;
  `}
`;

export const Button = styled(BaseButton)`
  width: 100%;
  margin-top: 1rem;
`;
