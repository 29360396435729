import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state, action) {
  const { listingId, adjustment } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing.adjustments = (listing.adjustments || []).map((a) => {
        if (a.id === adjustment.id) {
          return requestState({ ...a, listing_id: listingId, value: adjustment.value });
        }
        return a;
      })

      listing = {
        ...listing,
        price: listing.adjustments.reduce((price, adjustment) => {
          return price + Number(adjustment.value);
        }, listing.price_raw)
      };
    }
    return listing;
  });
  return { ...state, all: listings };
}

export function resolved(state, action) {
  const { listingId, adjustment } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing.adjustments = (listing.adjustments || []).map((a) => {
        if (a.id === adjustment.id) {
          return resolvedState(adjustment);
        }
        return a;
      });
    }
    return listing;
  });
  return { ...state, all: listings };
}

export function rejected(state, action) {
  const { listingId, adjustment } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing.adjustments = (listing.adjustments || []).map((a) => {
        if (a.id === adjustment.id) {
          return rejectedState(adjustment);
        }
        return a;
      });
    }
    return { ...state, all: listings };
  });
  return { ...state, all: listings };
}
