import React from "react";
import PropTypes from "prop-types";
import { currency } from "../../utils/formatters";

function ListingsStatsPricesItem({ label, value }) {
  return (
    <li className={`text-xs ${label.toLowerCase() === "low" ? "" : "mt-6"}`}>
      <span className="text-base mr-auto bg-grey-lightest">{label}:</span>
      <span
        className="text-base bg-grey-lightest"
        title={currency(value, null, true)}>
        {currency(value)}
      </span>
    </li>
  );
}

ListingsStatsPricesItem.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.number.isRequired
};

export default ListingsStatsPricesItem;
