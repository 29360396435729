import React, { useState } from "react";
import PropTypes from "prop-types";
import { useSession } from "../../Session";
import { useQueryString } from "../../utils/url";
import ReportCard from "../common/ReportCard";
import CreatedWith from "../common/CreatedWith";
import TransactionWizard from "../transaction/TransactionWizard";
import Image from "../common/Image";
import IconCMA from "../icons/report/IconCMA";
import { DELETE_CMA } from "./queries";

function CMACard({
  id,
  guid,
  title,
  type,
  status,
  pdfLink,
  notes,
  thumbnail,
  address,
  cityStateZip,
  beds,
  baths,
  sqft,
  lotSize,
  garages,
  propertyType,
  propertySubType,
  createdWith,
  viewCount,
  lastViewedAt,
  updatedAt,
  investorOffer,
  transaction: existingTransaction,
  refetchQuery
}) {
  const { features } = useSession();
  const { params } = useQueryString();
  const [transaction, setTransaction] = useState(existingTransaction);
  const [isTransactionWizardOpen, setIsTransactionWizardOpen] = useState(
    params.td_create_report_id === "" + id || false
  );

  const report = {
    id,
    report_address_parts: [address, cityStateZip],
    title
  };

  return (
    <>
      <ReportCard
        id={id}
        guid={guid}
        title={title}
        type={type}
        typeLabel="CMA Report"
        status={status}
        pdfLink={pdfLink}
        address={address}
        cityStateZip={cityStateZip}
        beds={beds}
        baths={baths}
        sqft={sqft}
        lotSize={lotSize}
        garages={garages}
        propertyType={propertyType}
        propertySubType={propertySubType}
        notes={<CreatedWith host={createdWith} notes={notes} />}
        viewCount={viewCount}
        lastViewedAt={lastViewedAt}
        updatedAt={updatedAt}
        image={thumbnail ? <Image src={thumbnail} alt={title} /> : <IconCMA />}
        deleteQuery={DELETE_CMA}
        refetchQuery={refetchQuery}
        isLiveEnabled={features.hasLive}
        isSlideshowEnabled={features.hasPresent}
        isCreateHomebeatEnabled={features.hasHomebeat}
        isInvestorConnectEnabled={investorOffer}
        transaction={transaction}
        onCreateTransactionClick={() => setIsTransactionWizardOpen(true)}
      />
      {isTransactionWizardOpen && (
        <TransactionWizard
          report={report}
          transaction={transaction}
          setTransaction={setTransaction}
          onClose={() => setIsTransactionWizardOpen(false)}
        />
      )}
    </>
  );
}

CMACard.propTypes = {
  id: PropTypes.number.isRequired,
  guid: PropTypes.string,
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  pdfLink: PropTypes.string,
  notes: PropTypes.string,
  thumbnail: PropTypes.string,
  address: PropTypes.string.isRequired,
  cityStateZip: PropTypes.string,
  beds: PropTypes.number,
  baths: PropTypes.string,
  sqft: PropTypes.number,
  lotSize: PropTypes.string,
  garages: PropTypes.string,
  propertyType: PropTypes.string,
  propertySubType: PropTypes.string,
  createdWith: PropTypes.string,
  viewCount: PropTypes.number,
  lastViewedAt: PropTypes.string,
  updatedAt: PropTypes.string,
  refetchQuery: PropTypes.object
};

export default CMACard;
