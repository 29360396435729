import styled from "styled-components";
import { Modal as BaseModal } from "@wrstudios/components";

export const PreviewModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 61.1rem;
  padding: 2rem 2rem 3rem;
  background: linear-gradient(180deg, #2d335d, #131943);
  position: relative;
`;

export const PreviewModalClose = styled(BaseModal.Close)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 2;

  svg {
    fill: ${({ theme }) => theme.styleGuide.colors.white};
  }
`;

export const CheckoutModalContent = styled(BaseModal.Content)`
  width: 100%;
  max-width: 67.8rem;
  padding: 2rem 0;
  background-color: ${({ theme }) => theme.styleGuide.colors.gray[200]};
  position: relative;
  overflow: hidden;

  form {
    ${({ theme }) => theme.media.sm`
      max-height: 60vh;
      overflow-y: auto;
      overflow-x: hidden;
    `};
  }
`;

export const CheckoutModalClose = styled(BaseModal.Close)`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 2;

  svg {
    fill: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  }
`;

export const Title = styled.h2`
  display: flex;
  justify-content: center;
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.3rem;
  margin-bottom: 3rem;
  text-align: center;
  color: ${({ theme }) => theme.colors.white};
`;

export const TitleAlt = styled(Title)`
  display: block;
  text-align: center;
  margin: 0;
`;

export const SubTitle = styled(Title)``;

export const SubTitleAlt = styled(TitleAlt)`
  margin-top: 0.8rem;
  margin: 0.8rem 0 3rem;
`;

export const TitleImage = styled.img`
  height: 3.6rem;
`;

export const Video = styled.div`
  margin: 0 2.5rem;
  border: 0.2rem solid ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.media.sm`
    margin: 0;
  `};
`;

export const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 3rem 2.5rem;

  ${({ theme }) => theme.media.sm`
    margin: 3rem 0;
  `};

  ${({ theme }) => theme.media.sm`
    flex-direction: column;
  `};
`;

export const Stat = styled.div`
  text-align: center;

  ${({ theme }) => theme.media.sm`
    margin: 1rem 0;
  `};
`;

export const StatNumber = styled.h3`
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 2.4rem;
  margin-bottom: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const StatText = styled.span`
  color: ${({ theme }) => theme.colors.white};
`;

export const Actions = styled.div`
  margin-top: 3rem;
`;

export const Action = styled.div`
  text-align: center;
`;

export const Image = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 23.4rem;
  background: ${({ theme }) => theme.colors.white};
  box-shadow: 0 0 0.1rem rgba(0, 0, 0, 0.2), 0 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
  border-radius: 0.6rem;
  font-weight: 600;
  font-size: 2rem;
  line-height: 2.6rem;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 2.4rem;
  text-align: center;
`;
