import camelcaseKeys from "camelcase-keys";
import api from "./api";

export function getPublishStatus({ reportId }) {
  return api.get(`/reports/${reportId}/status_update`).then((res) => res.data);
}

export function getOpendoorHome(reportId) {
  return api.get(`/reports/${reportId}/opendoor`).then((res) => res.data);
}

export function sendInvestorEvent(reportId, investorId, analyticEvent) {
  return api.put(`/reports/${reportId}/investor_lead/${investorId}`, {
    event: analyticEvent
  });
}

export function submitInvestorQuestionnaire(
  reportId,
  investorId,
  questions,
  event
) {
  return api.put(`/reports/${reportId}/investor_lead/${investorId}`, {
    survey: questions,
    event
  });
}

export function submitInvestorOffer(reportId, investorId) {
  return api.post(`/reports/${reportId}/investor_lead/${investorId}`);
}

export function updateLinks(reportId, links) {
  return api
    .post(`/reports/${reportId}/links`, { links })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function toggleListing(reportId, listingId) {
  return api
    .post(`/reports/${reportId}/listings/${listingId}/hide`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function sortListings(reportId, listingIds) {
  const formData = listingIds.reduce((formData, listingId) => {
    formData.append("report[]", listingId);
    return formData;
  }, new FormData());
  return api
    .post(`/reports/${reportId}/listings/sort`, formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function addListing(reportId, details = {}) {
  const formData = new FormData();
  formData.append("listing[data][source]", details.source || "");
  formData.append("listing[mlsnum]", details.mlsnum || "");
  formData.append("subject_property[geo_lat]", details.lat || "");
  formData.append("subject_property[geo_lon]", details.lng || "");
  formData.append("subject_property[city]", details.city || "");
  formData.append("listing[data][address]", details.address || "");
  formData.append("subject_property[geo_source]", details.geoSource || "");
  formData.append("listing[data][beds]", details.beds || "");
  formData.append("listing[data][baths]", details.baths || "");
  formData.append("listing[data][sqft]", details.sqft || "");
  formData.append("listing[data][status]", details.status || "");
  formData.append("listing[data][price_list]", details.priceList || "");
  formData.append(
    "listing[data][price_list_orig]",
    details.priceListOrig || ""
  );
  formData.append("listing[data][price_sold]", details.priceSold || "");
  formData.append("listing[data][date_list]", details.dateList || "");
  formData.append("listing[data][date_sold]", details.dateSold || "");
  formData.append("listing[data][remarks]", details.remarks || "");
  formData.append("listing[photos][][file]", details.files || "");
  formData.append("listing[data][year_built]", details.yearBuilt || "");
  formData.append("listing[data][lotsize]", details.lotSize || "");
  formData.append("listing[data][lotdim]", details.lotDim || "");
  formData.append("listing[data][taxes]", details.taxes || "");
  formData.append("listing[data][garages]", details.garages || "");
  formData.append("listing[data][area]", details.area || "");
  formData.append("listing[data][subdivision]", details.subdivision || "");
  formData.append("listing[data][style]", details.style || "");
  return api
    .post(`/reports/${reportId}/listings`, formData)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function getZestimates(reportId) {
  return api
    .get(`/reports/${reportId}/zestimates`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function getRdcData(reportId) {
  return api
    .get(`/reports/${reportId}/rdc`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function getSchools(reportId, { lat, lon }) {
  return api
    .get(`/reports/${reportId}/schools`, { params: { lat, lon } })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function getCustomPages(reportId) {
  return api
    .get(`/reports/${reportId}/page_contents`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function addCustomPage(reportId, details) {
  return api
    .post(`/reports/${reportId}/page_contents`, details)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function updateCustomPage(reportId, customPageId, content) {
  return api
    .put(`/reports/${reportId}/page_contents/${customPageId}`, {
      page_content: content
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function renameCustomPage(reportId, customPageId, key) {
  return api
    .put(`/users/${reportId}/page_contents/${customPageId}/rename`, { key })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function removeCustomPage(reportId, customPageId) {
  return api
    .delete(`/reports/${reportId}/page_contents/${customPageId}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function getMyRecentSalesCount({ reportId, sort, sides }) {
  return api
    .get("/recent_sales/count", {
      params: { report_id: reportId, sort, sides }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function saveMyRecentSalesPreferences({
  reportId,
  pageId,
  pageTitle,
  sort,
  sides
}) {
  return api
    .put("/my_recent_sales/update", {
      config: { reportId, pageId, pageTitle, sort, sides }
    })
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}

export function getChartDetails(reportId, chartId) {
  return api
    .get(`/reports/${reportId}/charts/${chartId}`)
    .then((res) => camelcaseKeys(res.data, { deep: true }));
}
