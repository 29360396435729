import React from "react";

// TODO: Rework this svg.
function IconCheckmark() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 14 12"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-79.000000, -2509.000000)"
        strokeLinecap="square">
        <g
          transform="translate(76.000000, 2500.000000)"
          stroke="currentColor"
          strokeWidth="2">
          <g transform="translate(0.000000, 5.000000)">
            <g>
              <g transform="translate(5.000000, 6.000000)">
                <path d="M0,5 L3,8" />
                <path
                  d="M3,0 L10,8"
                  transform="translate(6.500000, 4.000000) scale(-1, 1) translate(-6.500000, -4.000000) "
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconCheckmark;
