import { requestState, resolvedState, rejectedState } from "../../utils/redux";

export function request(state, action) {
  const { reportId } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === reportId) {
      report = requestState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}

export function resolved(state, action) {
  const { reportId } = action.payload;
  let reportIndex = -1;
  const reports = [...state.all].map((report, index) => {
    if (report.id === reportId) {
      report = resolvedState({ ...report, status: "active-report" });
      reportIndex = index;
    }

    return report;
  });

  // Move the report to the front of the array
  if (reportIndex !== -1) {
    const removedItem = reports.splice(reportIndex, 1);

    if (removedItem[0]) {
      reports.unshift(removedItem[0]);
    }
  }

  return { ...state, all: reports };
}

export function rejected(state, action) {
  const { reportId } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === reportId) {
      report = rejectedState(report);
    }

    return report;
  });

  return { ...state, all: reports };
}
