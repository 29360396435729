import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return {
    ...state,
    template: requestState({ ...state.template, load: true, update: true })
  };
}

export function resolved(state, action) {
  const data = action.payload[1];
  const { customize } = data;

  return Object.assign({}, state, {
    ...JSON.parse(JSON.stringify(customize)),
    template: resolvedState({
      ...state.template,
      selected: { ...action.payload.template }
    }),
    load: false,
    update: false
  });
}

export function rejected(state, action) {
  return {
    ...state,
    template: rejectedState(state.template, action.payload.error)
  };
}
