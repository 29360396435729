import React from "react";
import { formatCurrency, formatNumber } from "@wrstudios/utils";
import { getDisplayValue, useHighlighter } from "./utils";
import {
  Row,
  Label,
  Text,
  MobileText,
  Value,
  Difference
} from "./styled/comparable";

function ComparableDifferencesDynamic({ subProp, listingProp, ...props }) {
  const { handleMouseEnter, handleMouseLeave } = useHighlighter();

  return (
    <div {...props}>
      {subProp.map((value) => {
        if (value.isActive === true) {
          const displayValue = getDisplayValue(listingProp, value);
          return (
            <Row
              style={value.isTemp ? { backgroundColor: "#DDDDDD" } : {}}
              className={value.columnName}
              key={value.columnName}
              data-name={value.columnName}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}>
              <Label title={value.displayName}>
                <Text>
                  {value.displayName}
                  {!listingProp &&
                    (value.columnName === "lot_size" ||
                      value.columnName === "sqft") &&
                    value.additionalDisplayText}
                </Text>
                <MobileText>
                  {value.displayName}
                  {!listingProp &&
                    (value.columnName === "lot_size" ||
                      value.columnName === "sqft") &&
                    value.additionalDisplayText}
                </MobileText>
                {listingProp &&
                  (value.comparisonPercentage ? (
                    !value.value ? (
                      ""
                    ) : //ispercentage
                    Math.abs(
                        (value.value - listingProp[value.lookupName]) /
                          value.value
                      ) >= 0.1 ? (
                      //percentage >=0.1
                      <>
                        {(value.value - listingProp[value.lookupName]) /
                          value.value <
                        0 ? (
                          <Difference
                            value={
                              value.comparisonInverted
                                ? ((value.value -
                                    listingProp[value.lookupName]) /
                                    value.value) *
                                  -1
                                : (value.value -
                                    listingProp[value.lookupName]) /
                                  value.value
                            }>
                            <>
                              &nbsp;&uarr;{" "}
                              {formatNumber(
                                ((value.value - listingProp[value.lookupName]) /
                                  value.value) *
                                  100 *
                                  -1,
                                "0,0"
                              )}
                              %
                            </>
                          </Difference>
                        ) : (value.value - listingProp[value.lookupName]) /
                            value.value >
                          0 ? (
                          <Difference
                            value={
                              value.comparisonInverted
                                ? ((value.value -
                                    listingProp[value.lookupName]) /
                                    value.value) *
                                  -1
                                : (value.value -
                                    listingProp[value.lookupName]) /
                                  value.value
                            }>
                            <>
                              &nbsp;&darr;{" "}
                              {formatNumber(
                                ((value.value - listingProp[value.lookupName]) /
                                  value.value) *
                                  100,
                                "0,0"
                              )}
                              %
                            </>
                          </Difference>
                        ) : null}
                      </>
                    ) : Math.abs(
                        (value.value - listingProp[value.lookupName]) /
                          value.value
                      ) < 0.1 ? (
                      //percentage <0.1
                      <>
                        {(value.value - listingProp[value.lookupName]) /
                          value.value <
                        0 ? (
                          <Difference
                            value={
                              value.comparisonInverted
                                ? ((value.value -
                                    listingProp[value.lookupName]) /
                                    value.value) *
                                  -1
                                : (value.value -
                                    listingProp[value.lookupName]) /
                                  value.value
                            }>
                            {" "}
                            <>
                              &nbsp;&uarr;{" "}
                              {formatNumber(
                                ((value.value - listingProp[value.lookupName]) /
                                  value.value) *
                                  100 *
                                  -1,
                                "0.0[0]"
                              )}
                              %
                            </>
                          </Difference>
                        ) : (value.value - listingProp[value.lookupName]) /
                            value.value >
                          0 ? (
                          <Difference
                            value={
                              value.comparisonInverted
                                ? ((value.value -
                                    listingProp[value.lookupName]) /
                                    value.value) *
                                  -1
                                : (value.value -
                                    listingProp[value.lookupName]) /
                                  value.value
                            }>
                            <>
                              &nbsp;&darr;{" "}
                              {formatNumber(
                                ((value.value - listingProp[value.lookupName]) /
                                  value.value) *
                                  100,
                                "0.0[0]"
                              )}
                              %
                            </>
                          </Difference>
                        ) : null}
                      </>
                    ) : null
                  ) : //not percentage
                  value.value - listingProp[value.lookupName] > 0 ? (
                    //diff >0
                    <Difference
                      value={
                        value.comparisonInverted
                          ? (value.value - listingProp[value.lookupName]) * -1
                          : (value.value - listingProp[value.lookupName]) /
                            value.value
                      }>
                      <>
                        &nbsp;&darr;{" "}
                        {formatNumber(
                          value.value - listingProp[value.lookupName],
                          "0[.][,][0]0"
                        )}
                      </>
                    </Difference>
                  ) : value.value - listingProp[value.lookupName] < 0 ? (
                    //diff <0
                    <Difference
                      value={
                        value.comparisonInverted
                          ? (value.value - listingProp[value.lookupName]) * -1
                          : value.value - listingProp[value.lookupName]
                      }>
                      <>
                        &nbsp;&uarr;{" "}
                        {formatNumber(
                          (value.value - listingProp[value.lookupName]) * -1,
                          "0[.][,][0]0"
                        )}
                      </>
                    </Difference>
                  ) : null)}
              </Label>
              <Value className="comparable-value" title={value.displayName}>
                {value.dataType === "money"
                  ? formatCurrency(displayValue)
                  : displayValue}
              </Value>
            </Row>
          );
        }
      })}
    </div>
  );
}

export default ComparableDifferencesDynamic;
