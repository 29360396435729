import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  const selected = requestState(state.customPages.selected);

  return { ...state, customPages: { ...state.customPages, selected } };
}

export function resolved(state, action) {
  const { newTitle: title, key, page } = action.payload;
  const hasInactivePages = state.inactive && state.inactive.pages;
  const hasActivePages = state.active && state.active.pages;
  const hasCustomPages =
    state.customPages &&
    state.customPages.all &&
    state.customPages.all["My Custom Pages"];
  let newState = { ...state };

  if (hasInactivePages) {
    const inactivePages = [...state.inactive.pages].map((inactivePage) => {
      if ([page.key, page.oldKey].includes(inactivePage.key)) {
        inactivePage.title = title;
        inactivePage.key = key;
        inactivePage.oldKey = page.oldKey;
      }

      return { ...inactivePage };
    });

    newState = {
      ...newState,
      inactive: { ...state.inactive, pages: inactivePages }
    };
  }

  if (hasActivePages) {
    const activePages = [...state.active.pages].map((activePage) => {
      if ([page.key, page.oldKey].includes(activePage.key)) {
        activePage.title = title;
        activePage.key = key;
        activePage.oldKey = page.oldKey;
      }

      return { ...activePage };
    });

    newState = { ...newState, active: { ...state.active, pages: activePages } };
  }

  if (hasCustomPages) {
    const customPages = [...state.customPages.all["My Custom Pages"]].map(
      ([label, value]) => {
        if ([key, page.oldKey].includes(value)) {
          return [title, key];
        }

        return [label, value];
      }
    );

    newState = {
      ...newState,
      customPages: {
        ...state.customPages,
        all: { ...state.customPages.all, "My Custom Pages": customPages },
        selected: resolvedState({
          ...state.customPages.selected,
          key,
          title
        })
      }
    };
  }

  return newState;
}

export function rejected(state, action) {
  const { error } = action.payload;
  const selected = rejectedState(state.customPages.selected, error);

  return { ...state, customPages: { ...state.customPages, selected } };
}
