import styled from "styled-components";

export const Header = styled.h2`
  font-size: 2.4rem;
  font-weight: 400;
  line-height: 3.3rem;
  margin: 0 0 2rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  text-align: center;
`;
