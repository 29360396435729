import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return requestState({ ...state, filter: "add" });
}

export function resolved(state, action) {
  const error = action.payload.error_msg;

  if (error) {
    return rejectedState(state, { message: error });
  }

  const listing = { ...action.payload };
  const all = (state.all && [...state.all]) || [];
  const newState = { ...state, all: [...all, listing] };

  delete newState.filter;

  return resolvedState(newState);
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
