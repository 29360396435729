import React, { useEffect } from "react";
import Helmet from "react-helmet";
import { useSession } from "../../Session";
import UserSubscription from "../user/UserSubscription";
import UserCancel from "../user/UserCancel";
import UserOrders from "../user/UserOrders";
import { Section, Header, Text } from "./styled/settings-billing";
import { useHistory } from "react-router";

function SettingsBilling() {
  const history = useHistory();
  const { currentUser, subscription, orders } = useSession();
  const hasOrders = !!(orders.recurring.length + orders.oneTime.length);

  useEffect(() => {
    if (currentUser.lwaId) {
      history.push("/settings");
    }
  }, []);

  return (
    <>
      <Helmet>
        <title>Billing - Account Settings - Cloud CMA</title>
      </Helmet>
      <Section>
        <Header>Billing</Header>
        <UserSubscription />
        {!subscription.isFree &&
          !currentUser.isCASUser &&
          currentUser.isAccountAdmin &&
          subscription.cardNumber && (
            <Text align="center">
              <a href="/settings/billing/payments">
                Edit my credit card and plan
              </a>
            </Text>
          )}
      </Section>
      <Section>
        <Header>Cancel</Header>
        <UserCancel />
      </Section>
      {(subscription.hasSubscriptionPayments || hasOrders) && (
        <Section>
          <Header>Orders</Header>
          <UserOrders />
          {currentUser.isAccountAdmin && (
            <Text align="center">
              <a
                href={`/users/${currentUser.id}/receipt`}
                target="_blank"
                rel="noreferrer">
                View receipt for payments
              </a>
            </Text>
          )}
        </Section>
      )}
    </>
  );
}

export default SettingsBilling;
