import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "@wrstudios/components";
import { updateTheme } from "../../actions/customize";
import { getReportType } from "../../utils/types";
import Button from "../common/Button";
import IconStar from "../icons/material/toggle/IconStar";
import {
  ModalContent,
  ModalHeader,
  ModalClose,
  ModalBody,
  ModalFooter,
  Header,
  HeaderIcon,
  Images,
  Image
} from "./styled/customize-modal-theme-try-modern";

class CustomizeModalThemeTryModern extends Component {
  render() {
    const { report, theme, updateTheme, onClose } = this.props;
    let reportType = report.type.toLowerCase();

    if (reportType === "document") {
      reportType = "cma";
    }

    return (
      <Modal onClose={onClose}>
        <ModalContent>
          <ModalHeader>
            <Header>
              <HeaderIcon>
                <IconStar />
              </HeaderIcon>
              <span>Try the new modern theme</span>
            </Header>
            <ModalClose />
          </ModalHeader>
          <ModalBody>
            <Images>
              <Image
                src={`/images/reports/${reportType}/modern_${theme.selected.key}_0.jpg`}
                alt={`${theme.title} Preview Image 1`}
              />
              <Image
                src={`/images/reports/${reportType}/modern_${theme.selected.key}_1.jpg`}
                alt={`${theme.title} Preview Image 2`}
              />
              <Image
                src={`/images/reports/${reportType}/modern_${theme.selected.key}_2.jpg`}
                alt={`${theme.title} Preview Image 3`}
              />
            </Images>
          </ModalBody>
          <ModalFooter>
            <Button
              app="cma"
              onClick={() => {
                const { id, type } = report;
                const { all } = theme;
                const newTheme = all.find(
                  (t) => t.key === `modern_${theme.selected.key}`
                );

                onClose();
                updateTheme(id, getReportType(type), newTheme);
              }}>
              Try Modern
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    );
  }
}

CustomizeModalThemeTryModern.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired
  }).isRequired,
  theme: PropTypes.shape({
    selected: PropTypes.shape({
      key: PropTypes.string.isRequired
    }).isRequired
  }).isRequired,
  updateTheme: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  report: state.report,
  theme: state.customize.theme
});

const mapDispatchToProps = (dispatch) => ({
  updateTheme: (reportId, reportType, theme) =>
    dispatch(updateTheme(reportId, reportType, theme))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalThemeTryModern);
