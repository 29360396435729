import { requestState, resolvedState, rejectedState } from "../../utils/redux";

export function request(state) {
  return requestState(state);
}

export function resolved(state, action) {
  const { id } = action.payload;
  const reports = [...state.all].map((report) => {
    if (report.id === id) {
      return { ...report, ...action.payload };
    }

    return report;
  });

  return resolvedState({ ...state, all: reports });
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
