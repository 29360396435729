import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { gql } from "apollo-boost";
import { useMutation } from "@apollo/react-hooks";
import { get, pickBy, identity } from "lodash";
import { Modal } from "@wrstudios/components";
import { stripHtml } from "../../utils/email";
import { useSession } from "../../Session";
import FormGroup from "./FormGroup";
import Label from "./Label";
import Input from "./Input";
import Button from "./Button";
import Checkbox from "./Checkbox";
import Loading from "./Loading";
import IconSend from "../icons/material/content/IconSend";
import IconCloudCMA from "../icons/logos/horizontal/IconCloudCMA";
import {
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Form,
  Message,
  Actions,
  Action,
  Success,
  Status,
  Recipient,
  Tabs,
  Tab,
  Customize,
  CheckboxLabel,
  Includes,
  IncludesHeader,
  Include,
  SendFromEmailApp,
  Preview,
  EmailIntro,
  EmailCard,
  EmailCardImage,
  Title,
  Address,
  CityStateZip,
  Links,
  Agent,
  AgentAvatar,
  AgentName,
  AgentPhone,
  AgentCompany,
  AgentCompanyLogo,
  PoweredBy
} from "./styled/email-report";

const SEND_EMAIL = gql`
  mutation SendEmail($reportId: ID!, $input: EmailInput!) {
    sendEmail(reportId: $reportId, input: $input)
      @rest(
        type: "Email"
        path: "reports/{args.reportId}/email"
        method: "POST"
      ) {
      status: String
    }
  }
`;

function getMailtoLink({ email, subject, body }) {
  const strippedBody = stripHtml(body).replace(/(?:\r\n|\r|\n)/g, "%0D%0A");
  let params = Object.entries(pickBy({ subject, body: strippedBody }, identity))
    .map(([key, value]) => `${key}=${value}`)
    .join("&");

  if (params.length) {
    params = `?${params}`;
  }

  return `mailto:${email}${params}`;
}

function getSubjectText({ address, type }) {
  const subjectTextType = {
    Cma: "CMA",
    Tour: "Buyer Tour",
    Property: "Property Report",
    Flyer: "Flyer"
  }[type];

  return `${subjectTextType} for ${address}`;
}

function getIntroText(type) {
  const subjectTextType = {
    Cma: "Your Comparative Market Analysis report",
    Tour: "Your Buyer Tour",
    Property: "Your Property report",
    Flyer: "Your Flyer"
  }[type];

  return `${subjectTextType} is ready:`;
}

function EmailReport({
  reportId,
  guid,
  type,
  typeLabel,
  pdfLink,
  title,
  address,
  cityStateZip,
  image,
  onClose
}) {
  const { currentUser, features } = useSession();
  const messageRef = useRef();
  const emailRef = useRef();
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(
    getSubjectText({ type, address }) || ""
  );
  const [intro, setIntro] = useState(getIntroText(type) || "");
  const [currentTab, setCurrentTab] = useState("customize");
  const [mailToLink, setMailToLink] = useState("");
  const [showPdfText, setShowPdfText] = useState(true);
  const [showSlideshowText, setShowSlideshowText] = useState(false);
  const [showLiveText, setShowLiveText] = useState(true);
  const [sendEmail, { loading, data }] = useMutation(SEND_EMAIL, {
    variables: {
      reportId,
      input: {
        email: {
          to: email,
          subject,
          msg: intro,
          show_pdf_url: showPdfText,
          show_slideshow_url: showSlideshowText,
          show_live_url: showLiveText
        }
      }
    }
  });
  const isDisabled = loading || !email || !subject || !intro;
  const isSlideshowControlVisible =
    features.hasPresent && (type === "Cma" || type === "Tour");
  const isLiveControlVisible = features.hasLive && type === "Cma";
  const status = get(data, "sendEmail.status", false);
  const hasSent = status === "ok";

  const handleSubmit = (e) => {
    e.preventDefault();
    sendEmail();
  };

  useEffect(() => {
    setMailToLink(
      getMailtoLink({ email, subject, body: messageRef.current.innerHTML })
    );
  }, [email, subject, showPdfText, showSlideshowText, showLiveText]);

  useEffect(() => {
    if (currentTab === "customize") {
      emailRef.current.focus();
    }
  }, [currentTab]);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          Email Report
          <Modal.Close />
        </ModalHeader>
        {!hasSent && (
          <Form onSubmit={handleSubmit}>
            <ModalBody>
              <Tabs>
                <Tab
                  isActive={currentTab === "customize"}
                  type="button"
                  onClick={() => setCurrentTab("customize")}>
                  Customize
                </Tab>
                <Tab
                  isActive={currentTab === "preview"}
                  type="button"
                  onClick={() => setCurrentTab("preview")}>
                  Preview
                </Tab>
              </Tabs>
              {currentTab === "customize" && (
                <Customize>
                  <div>
                    <FormGroup>
                      <Label htmlFor="email">To</Label>
                      <Input
                        ref={emailRef}
                        data-autofocus
                        id="email"
                        placeholder="Enter email address"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="subject">Subject</Label>
                      <Input
                        id="subject"
                        placeholder={getSubjectText({ type, address })}
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="intro">Intro</Label>
                      <Input
                        id="intro"
                        placeholder={getIntroText(type)}
                        value={intro}
                        onChange={(e) => setIntro(e.target.value)}
                      />
                    </FormGroup>
                  </div>
                  <IncludesHeader>Include</IncludesHeader>
                  <Includes>
                    <Message ref={messageRef} tabIndex="-1" aria-hidden>
                      {showPdfText && (
                        <EmailReportPdfText
                          type={typeLabel || type}
                          pdfLink={pdfLink}
                        />
                      )}
                      {isSlideshowControlVisible && showSlideshowText && (
                        <EmailReportSlideshowText
                          guid={guid}
                          type={typeLabel || type}
                        />
                      )}
                      {isLiveControlVisible && showLiveText && (
                        <EmailReportLiveText
                          guid={guid}
                          type={typeLabel || type}
                        />
                      )}
                      <EmailReportAgent />
                    </Message>
                    <Include>
                      <CheckboxLabel>
                        <Checkbox
                          checked={showPdfText}
                          onChange={() => setShowPdfText(!showPdfText)}
                        />
                        PDF Report
                      </CheckboxLabel>
                    </Include>
                    {isSlideshowControlVisible && (
                      <Include>
                        <CheckboxLabel>
                          <Checkbox
                            checked={showSlideshowText}
                            onChange={() =>
                              setShowSlideshowText(!showSlideshowText)
                            }
                          />
                          Slideshow Report
                        </CheckboxLabel>
                      </Include>
                    )}
                    {isLiveControlVisible && (
                      <Include>
                        <CheckboxLabel>
                          <Checkbox
                            checked={showLiveText}
                            onChange={() => setShowLiveText(!showLiveText)}
                          />
                          Live Report
                        </CheckboxLabel>
                      </Include>
                    )}
                  </Includes>
                </Customize>
              )}
              {currentTab === "preview" && (
                <Preview>
                  <div>
                    <EmailIntro>
                      {intro ||
                        "Your Comparative Market Analysis report is ready:"}
                    </EmailIntro>
                    <EmailCard>
                      <EmailCardImage>{image}</EmailCardImage>
                      <div>
                        <Title>{title}</Title>
                        <Address>{address}</Address>
                        <CityStateZip>{cityStateZip}</CityStateZip>
                        <Links>
                          {showPdfText && (
                            <a href={`${window.location.origin}/pdf/${guid}`}>
                              View PDF
                            </a>
                          )}
                          {showSlideshowText && (
                            <a
                              href={`${window.location.origin}/present/${guid}`}>
                              View Slideshow
                            </a>
                          )}
                          {showLiveText && (
                            <a href={`${window.location.origin}/live/${guid}`}>
                              View Live
                            </a>
                          )}
                        </Links>
                      </div>
                    </EmailCard>
                    <Agent>
                      {!!currentUser.avatarUrl && (
                        <AgentAvatar>
                          <img
                            src={currentUser.avatarUrl}
                            alt={currentUser.name}
                          />
                        </AgentAvatar>
                      )}
                      {!!currentUser.name && (
                        <AgentName>{currentUser.name}</AgentName>
                      )}
                      {!!currentUser.companyName && (
                        <AgentCompany>{currentUser.companyName}</AgentCompany>
                      )}
                      {(!!currentUser.mobilePhone ||
                        !!currentUser.officePhone) && (
                        <AgentPhone
                          href={`tel:${
                            currentUser.mobilePhone || currentUser.officePhone
                          }`}>
                          {currentUser.mobilePhone || currentUser.officePhone}
                        </AgentPhone>
                      )}
                      {!!currentUser.companyLogo && (
                        <AgentCompanyLogo>
                          <img
                            src={currentUser.companyLogo}
                            alt={currentUser.companyName}
                          />
                        </AgentCompanyLogo>
                      )}
                      <PoweredBy>
                        <div>Powered by</div>
                        <div>
                          <IconCloudCMA />
                        </div>
                      </PoweredBy>
                    </Agent>
                  </div>
                </Preview>
              )}
            </ModalBody>
            <ModalFooter>
              <Actions>
                <Action>
                  <SendFromEmailApp>
                    Send from your{" "}
                    <a
                      href={mailToLink}
                      target="_blank"
                      rel="noopener noreferrer">
                      email app
                    </a>
                  </SendFromEmailApp>
                </Action>
                <Action>
                  <Button app="cma" disabled={isDisabled}>
                    {!loading && "Send"}
                    {loading && <Loading>Emailing...</Loading>}
                  </Button>
                </Action>
              </Actions>
            </ModalFooter>
          </Form>
        )}
        {hasSent && (
          <ModalBody>
            <Success>
              <IconSend />
              <Status>Report Sent!</Status>
              <Recipient>{email}</Recipient>
            </Success>
          </ModalBody>
        )}
      </ModalContent>
    </Modal>
  );
}

EmailReport.propTypes = {
  reportId: PropTypes.number.isRequired,
  guid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  typeLabel: PropTypes.string,
  title: PropTypes.string,
  address: PropTypes.string,
  cityStateZip: PropTypes.string,
  image: PropTypes.element,
  onClose: PropTypes.func.isRequired
};

function EmailReportPdfText({ type, pdfLink }) {
  return (
    <div aria-hidden>
      Here is your customized {type}:<br />
      <a href={pdfLink} target="_blank" tabIndex="-1" rel="noreferrer">
        {pdfLink}
      </a>
      <br />
      <br />
    </div>
  );
}

EmailReportPdfText.propTypes = {
  type: PropTypes.string.isRequired,
  pdfLink: PropTypes.string.isRequired
};

function EmailReportSlideshowText({ guid, type }) {
  const url = `${window.location.origin}/present/${guid}`;

  return (
    <div aria-hidden>
      Here is your customized {type}:<br />
      <a href={url} target="_blank" tabIndex="-1" rel="noreferrer">
        {url}
      </a>
      <br />
      <br />
    </div>
  );
}

EmailReportSlideshowText.propTypes = {
  guid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

function EmailReportLiveText({ guid, type }) {
  const url = `${window.location.origin}/live/${guid}`;

  return (
    <div aria-hidden>
      Here is your customized live {type}:<br />
      <a href={url} target="_blank" tabIndex="-1" rel="noreferrer">
        {url}
      </a>
      <br />
      <br />
    </div>
  );
}

EmailReportLiveText.propTypes = {
  guid: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
};

function EmailReportAgent() {
  const { currentUser } = useSession();
  const details = [
    currentUser.name,
    currentUser.email,
    currentUser.officeAddress
  ].filter(Boolean);

  return (
    <div>
      Regards,
      <br />
      <span dangerouslySetInnerHTML={{ __html: details.join("<br />") }}></span>
    </div>
  );
}

export default EmailReport;
