import React, { useState, createContext, useContext, useCallback } from "react";

const CSVContext = createContext();

function CSVProvider({ children }) {
  const [data, setData] = useState([]);
  const [columns, setColumns] = useState([]);
  const [selectedColumns, setSelectedColumns] = useState({});

  const getMappedData = useCallback(() => {
    return data.map((data) => {
      const cleanedData = Object.entries(data)
        .filter(([key]) => Boolean(key))
        .reduce(
          (newData, [key, value]) => ({
            ...newData,
            [key.trim()]: (value || "").trim()
          }),
          {}
        );

      return Object.entries(selectedColumns).reduce(
        (newData, [column, columns]) => {
          const columnValue = Object.values(columns)
            .filter(Boolean)
            .map((column) => cleanedData[column])
            .join(" ");

          return {
            ...newData,
            [column]: columnValue
          };
        },
        {}
      );
    });
  }, [data, selectedColumns]);

  return (
    <CSVContext.Provider
      value={{
        data,
        setData,
        columns,
        setColumns,
        selectedColumns,
        setSelectedColumns,
        getMappedData
      }}>
      {children}
    </CSVContext.Provider>
  );
}

function useCSV() {
  const context = useContext(CSVContext);
  if (!context) {
    throw new Error("You must use the useCSV hook inside of a CSVProvider");
  }
  return context;
}

export { CSVProvider, useCSV };
