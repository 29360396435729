import React from "react";
import { AppSwitcher } from "@wrstudios/components";
import { useSession } from "../../Session";
import IconCloudLogo from "../icons/logos/IconCloudLogo";
import { Container, Link } from "./styled/logo";

function Logo() {
  const { currentUser, mls, config } = useSession();

  return (
    <Container>
      <Link href="/">
        <IconCloudLogo />
      </Link>
      <AppSwitcher
        currentApp="cloud_cma"
        env={config.railsEnv}
        userId={String(currentUser.id)}
        userEmail={currentUser.email}
        casUserId={currentUser.casUserId ? String(currentUser.casUserId) : ""}
        casJwt={currentUser.casJwt}
        mlsCode={mls.code}
      />
    </Container>
  );
}

export default Logo;
