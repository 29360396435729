import { requestState, rejectedState } from "../../../../utils/redux";

export function request(state, action) {
  const { pageId } = action.payload;
  const pdfs = [...state.customPages.pdfs].map((pdf) => {
    if (pdf.id === pageId) {
      pdf = requestState(pdf);
    }

    return pdf;
  });

  return { ...state, customPages: { ...state.customPages, pdfs } };
}

export function resolved(state, action) {
  const { id } = action.payload;
  const pdfs = [...state.customPages.pdfs].filter((pdf) => pdf.id !== id);

  return { ...state, customPages: { ...state.customPages, pdfs } };
}

export function rejected(state, action) {
  const { pageId } = action.payload;
  const pdfs = [...state.customPages.pdfs].map((pdf) => {
    if (pdf.id === pageId) {
      pdf = rejectedState(pdf, action.payload.error);
    }

    return pdf;
  });

  return { ...state, customPages: { ...state.customPages, pdfs } };
}
