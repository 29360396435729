import styled from "styled-components";

export const Container = styled.nav`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

export const Link = styled.a`
  text-decoration: none;
  color: ${({ theme, href }) =>
    href ? theme.colors.green : theme.colors.gray};

  &[disabled] {
    opacity: 0.5;
    color: ${({ theme }) => theme.colors.gray};
  }
`;

export const Arrow = styled.div`
  margin: 0 1.5rem;

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: ${({ theme }) => theme.colors.grayAlt};
  }
`;
