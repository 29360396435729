import React, { Fragment } from "react";
import PropTypes from "prop-types";
import Icon from "../../../Icon";

function IconLayoutFlyer9(props) {
  return (
    <Icon width="233" height="303" viewBox="0 0 233 303" {...props}>
      {!props.hover && (
        <Fragment>
          <path
            fill="#c4c8d0"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#c4c8d0"
            d="M184.74 244.8h23.59v3h-23.59zM91.85 244.8h79.98v3H91.85zM91.85 263.21h116.48v3H91.85zM91.85 226.46h116.48v3H91.85zM26.44 23.75h36.83v4H26.44zM83.43 23.75h124.9v4H83.43zM33.49 225.42h36.83v4H33.49zM30.05 245.21h43.71v4H30.05zM38.53 262.74h26.75v4H38.53z"
          />
          <rect
            fill="#e1e5e9"
            x="90.8"
            y="43.53"
            width="116.43"
            height="102"
            rx="5"
            ry="5"
            transform="rotate(-180 149.01 94.535)"
          />
          <path
            fill="#576474"
            d="M202.22 147H95.8a6.5 6.5 0 0 1-6.5-6.5v-92a6.51 6.51 0 0 1 6.5-6.5h106.42a6.51 6.51 0 0 1 6.5 6.5v92a6.5 6.5 0 0 1-6.5 6.5zM95.8 45a3.5 3.5 0 0 0-3.5 3.5v92a3.5 3.5 0 0 0 3.5 3.5h106.42a3.5 3.5 0 0 0 3.5-3.5v-92a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.12 199.02 51.231)"
            d="M197.02 49.73h4v3h-4z"
          />
          <path
            fill="#fff"
            d="M108.81 132l-2-2.26 6.41-5.6 2 2.26zm12.81-11.19l-2-2.26 6.4-5.59 2 2.26zm12.81-11.19l-2-2.26 6.41-5.59 2 2.26zm12.82-11.19l-2-2.25 6.41-5.6 2 2.26zm12.81-11.18l-2-2.26 6.41-5.6 2 2.26zm12.82-11.19l-2-2.26 6.41-5.59 2 2.26zm12.81-11.19l-2-2.26 6.4-5.59 2 2.26z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.12 99.899 137.768)"
            d="M97.91 136.26h4v3h-4z"
          />
          <path
            fill="#fff"
            transform="rotate(-48.56 198.802 138.035)"
            d="M197.29 136.03h3v4h-3z"
          />
          <path
            fill="#fff"
            d="M189.92 132.21l-6.38-5.63 2-2.25 6.36 5.67zM177.17 121l-6.38-5.63 2-2.25 6.37 5.63zm-12.76-11.26l-6.41-5.67 2-2.25 6.38 5.62zm-12.75-11.3l-6.38-5.63 2-2.25 6.38 5.63zm-12.75-11.26l-6.38-5.63 2-2.25 6.37 5.63zm-12.76-11.26l-6.37-5.62 2-2.25 6.38 5.62zM113.4 64.67L107 59l2-2.25 6.38 5.63z"
          />
          <path
            fill="#fff"
            transform="rotate(-48.56 100.147 50.965)"
            d="M98.64 48.96h3v4h-3z"
          />
          <rect
            fill="#e1e5e9"
            x="25.78"
            y="43.53"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 51.545 65.64)"
          />
          <path
            fill="#576474"
            d="M72.31 89.25H30.78a6.51 6.51 0 0 1-6.5-6.5V48.53a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM30.78 45a3.5 3.5 0 0 0-3.5 3.5v34.25a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V48.53a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e1e5e9"
            x="25.78"
            y="100.86"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 51.545 122.975)"
          />
          <path
            fill="#576474"
            d="M72.31 146.58H30.78a6.5 6.5 0 0 1-6.5-6.5v-34.21a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5v-34.21a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e1e5e9"
            x="155.69"
            y="159.2"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 181.455 181.305)"
          />
          <path
            fill="#576474"
            d="M202.22 204.91h-41.53a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.53a6.5 6.5 0 0 1 6.5 6.5v34.21a6.5 6.5 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e1e5e9"
            x="90.73"
            y="159.2"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 116.5 181.305)"
          />
          <path
            fill="#576474"
            d="M137.27 204.91H95.73a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM95.73 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e1e5e9"
            x="25.78"
            y="159.2"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 51.545 181.305)"
          />
          <path
            fill="#576474"
            d="M72.31 204.91H30.78a6.5 6.5 0 0 1-6.5-6.5V164.2a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM30.78 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
          />
        </Fragment>
      )}
      {props.hover && (
        <Fragment>
          <path
            fill="#20b575"
            d="M225.75 303H7.25A7.26 7.26 0 0 1 0 295.75V7.25A7.26 7.26 0 0 1 7.25 0h218.5A7.26 7.26 0 0 1 233 7.25v288.5a7.26 7.26 0 0 1-7.25 7.25zM7.25 3A4.26 4.26 0 0 0 3 7.25v288.5A4.26 4.26 0 0 0 7.25 300h218.5a4.26 4.26 0 0 0 4.25-4.25V7.25A4.26 4.26 0 0 0 225.75 3z"
          />
          <path
            fill="#20b575"
            d="M184.74 244.8h23.59v3h-23.59zM91.85 244.8h79.98v3H91.85zM91.85 263.21h116.48v3H91.85zM91.85 226.46h116.48v3H91.85zM26.44 23.75h36.83v4H26.44zM83.43 23.75h124.9v4H83.43zM33.49 225.42h36.83v4H33.49zM30.05 245.21h43.71v4H30.05zM38.53 262.74h26.75v4H38.53z"
          />
          <rect
            fill="#e0e6ea"
            x="90.8"
            y="43.53"
            width="116.43"
            height="102"
            rx="5"
            ry="5"
            transform="rotate(-180 149.01 94.535)"
          />
          <path
            fill="#20b575"
            d="M202.22 147H95.8a6.5 6.5 0 0 1-6.5-6.5v-92a6.51 6.51 0 0 1 6.5-6.5h106.42a6.51 6.51 0 0 1 6.5 6.5v92a6.5 6.5 0 0 1-6.5 6.5zM95.8 45a3.5 3.5 0 0 0-3.5 3.5v92a3.5 3.5 0 0 0 3.5 3.5h106.42a3.5 3.5 0 0 0 3.5-3.5v-92a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.12 199.02 51.231)"
            d="M197.02 49.73h4v3h-4z"
          />
          <path
            fill="#fff"
            d="M108.81 132l-2-2.26 6.41-5.6 2 2.26zm12.81-11.19l-2-2.26 6.4-5.59 2 2.26zm12.81-11.19l-2-2.26 6.41-5.59 2 2.26zm12.82-11.19l-2-2.25 6.41-5.6 2 2.26zm12.81-11.18l-2-2.26 6.41-5.6 2 2.26zm12.82-11.19l-2-2.26 6.41-5.59 2 2.26zm12.81-11.19l-2-2.26 6.4-5.59 2 2.26z"
          />
          <path
            fill="#fff"
            transform="rotate(-41.12 99.899 137.768)"
            d="M97.91 136.26h4v3h-4z"
          />
          <path
            fill="#fff"
            transform="rotate(-48.56 198.802 138.035)"
            d="M197.29 136.03h3v4h-3z"
          />
          <path
            fill="#fff"
            d="M189.92 132.21l-6.38-5.63 2-2.25 6.36 5.67zM177.17 121l-6.38-5.63 2-2.25 6.37 5.63zm-12.76-11.26l-6.41-5.67 2-2.25 6.38 5.62zm-12.75-11.3l-6.38-5.63 2-2.25 6.38 5.63zm-12.75-11.26l-6.38-5.63 2-2.25 6.37 5.63zm-12.76-11.26l-6.37-5.62 2-2.25 6.38 5.62zM113.4 64.67L107 59l2-2.25 6.38 5.63z"
          />
          <path
            fill="#fff"
            transform="rotate(-48.56 100.147 50.965)"
            d="M98.64 48.96h3v4h-3z"
          />
          <rect
            fill="#e0e6ea"
            x="25.78"
            y="43.53"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 51.545 65.64)"
          />
          <path
            fill="#20b575"
            d="M72.31 89.25H30.78a6.51 6.51 0 0 1-6.5-6.5V48.53a6.51 6.51 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.22a6.51 6.51 0 0 1-6.5 6.5zM30.78 45a3.5 3.5 0 0 0-3.5 3.5v34.25a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V48.53a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e0e6ea"
            x="25.78"
            y="100.86"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 51.545 122.975)"
          />
          <path
            fill="#20b575"
            d="M72.31 146.58H30.78a6.5 6.5 0 0 1-6.5-6.5v-34.21a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5v-34.21a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e0e6ea"
            x="155.69"
            y="159.2"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 181.455 181.305)"
          />
          <path
            fill="#20b575"
            d="M202.22 204.91h-41.53a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.53a6.5 6.5 0 0 1 6.5 6.5v34.21a6.5 6.5 0 0 1-6.5 6.5zm-41.53-44.21a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e0e6ea"
            x="90.73"
            y="159.2"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 116.5 181.305)"
          />
          <path
            fill="#20b575"
            d="M137.27 204.91H95.73a6.51 6.51 0 0 1-6.5-6.5V164.2a6.51 6.51 0 0 1 6.5-6.5h41.54a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM95.73 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.54a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
          />
          <rect
            fill="#e0e6ea"
            x="25.78"
            y="159.2"
            width="51.53"
            height="44.22"
            rx="5"
            ry="5"
            transform="rotate(-180 51.545 181.305)"
          />
          <path
            fill="#20b575"
            d="M72.31 204.91H30.78a6.5 6.5 0 0 1-6.5-6.5V164.2a6.5 6.5 0 0 1 6.5-6.5h41.53a6.51 6.51 0 0 1 6.5 6.5v34.21a6.51 6.51 0 0 1-6.5 6.5zM30.78 160.7a3.5 3.5 0 0 0-3.5 3.5v34.21a3.5 3.5 0 0 0 3.5 3.5h41.53a3.5 3.5 0 0 0 3.5-3.5V164.2a3.5 3.5 0 0 0-3.5-3.5z"
          />
        </Fragment>
      )}
    </Icon>
  );
}

IconLayoutFlyer9.propTypes = {
  hover: PropTypes.bool
};

IconLayoutFlyer9.defaultProps = {
  hover: false
};

export default IconLayoutFlyer9;
