function _updateMeta(pages, key, meta) {
  return pages.map((page) => {
    if (page.key === key) return { ...page, meta: { ...page.meta, ...meta } };
    return page;
  });
}

export function updateMeta(state, { payload }) {
  const { meta, key } = payload;
  return {
    ...state,
    inactive: {
      ...state.inactive,
      pages: _updateMeta(state.inactive.pages, key, meta)
    },
    active: {
      ...state.active,
      pages: _updateMeta(state.active.pages, key, meta)
    },
    powerPacks: {
      ...state.powerPacks,
      pages: {
        ...state.powerPacks.pages,
        marketing: _updateMeta(state.powerPacks.pages.marketing, key, meta),
        lux: _updateMeta(state.powerPacks.pages.lux, key, meta),
        tom: _updateMeta(state.powerPacks.pages.tom, key, meta)
      }
    }
  };
}
