import React from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import { ModalContent, Actions, Action, Button } from "./styled/alert";

function Alert({ title, confirmButton, children, onConfirm, ...props }) {
  return (
    <Modal {...props} onClose={onConfirm}>
      <ModalContent>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
          <Actions>
            <Action>
              <Button app="cma" data-autofocus onClick={onConfirm}>
                {confirmButton}
              </Button>
            </Action>
          </Actions>
        </Modal.Footer>
      </ModalContent>
    </Modal>
  );
}

Alert.defaultProps = {
  title: "Alert",
  confirmButton: "Confirm",
  onConfirm: () => {}
};

Alert.propTypes = {
  title: PropTypes.string,
  confirmButton: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  onConfirm: PropTypes.func
};

export default Alert;
