import React from "react";
import {
  Container,
  ListItem,
  ListItemLabel,
  ListItemValue
} from "./styled/dotted-list";

function List(props) {
  return <Container {...props} />;
}

function Item(props) {
  return <ListItem {...props} />;
}

function Label(props) {
  return <ListItemLabel {...props} />;
}

function Value(props) {
  return <ListItemValue {...props} />;
}

export { List, Item, Label, Value };
