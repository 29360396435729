import React, { Fragment } from "react";
import { Dropdown, Group, GroupHeader } from "./styled/select";
import SelectDropdownOption from "./SelectDropdownOption";

function SelectDropdown({ options, selected, getItemProps }) {
  return (
    <Dropdown>
      {options.map((option, index) => (
        <Fragment key={index}>
          {Array.isArray(option.value) && !!option.value.length && (
            <Group>
              <GroupHeader>{option.label}</GroupHeader>
              {option.value.map((option, childIndex) => {
                // Loose comparison since we are mixing numbers and strings
                return (
                  <SelectDropdownOption
                    key={childIndex}
                    autoFocus={
                      selected.value == option.value ||
                      (index === 0 && childIndex === 0)
                    }
                    getItemProps={getItemProps}
                    option={option}
                  />
                );
              })}
            </Group>
          )}
          {!Array.isArray(option.value) && (
            <SelectDropdownOption
              key={index}
              autoFocus={selected.value == option.value || index === 0}
              getItemProps={getItemProps}
              option={option}
            />
          )}
        </Fragment>
      ))}
    </Dropdown>
  );
}

export default SelectDropdown;
