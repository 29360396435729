import styled, { keyframes } from "styled-components";

const animate = keyframes`
  from { background-position: 100% 0%; }
  to { background-position: 0% 0%; }
`;

export const Container = styled.span`
  display: inline-block;
  width: 100%;
  border-radius: 0.4rem;
  background-image: ${({ theme }) =>
    `linear-gradient(to right,
      ${theme.colors.grayLighterSuper} 20%,
      ${theme.colors.grayLightest} 50%,
      ${theme.colors.grayLighterSuper} 80%
    )`};
  background-size: 400% 400%;
  user-select: none;
  animation: ${animate} 1.5s linear infinite;
`;
