import { connect } from "react-redux";
import { updateLanguage } from "../../../../../actions/customize";
import CustomizeControlsLanguage from "../CustomizeControlsLanguage";

function select({ report, customize: { language } }) {
  return {
    report,
    language
  };
}

const actions = {
  updateLanguage
};

export default connect(select, actions)(CustomizeControlsLanguage);
