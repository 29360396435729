import React, { useState } from "react";
import PropTypes from "prop-types";
import Input from "../common/Input";
import { Select, Option } from "../common/Select";

function MoreCriteriaMin({
  type,
  index,
  name,
  value: propsValue,
  selected: propsSelected
}) {
  const [value, setValue] = useState(propsValue || "");
  const [selected, setSelected] = useState((propsSelected || {}).value || "");

  return (
    <>
      {type !== "select" && (
        <>
          {type === "number" && (
            <Input
              name={name}
              id={`option-max-${index}`}
              value={value || ""}
              onChange={(e) => setValue(e.target.value)}
            />
          )}
          {type !== "number" && (
            <Input
              name={name}
              id={`option-max-${index}`}
              value={value}
              onChange={(e) => setValue(e.target.value)}
            />
          )}
        </>
      )}
      {type === "select" && (
        <Select
          name={name}
          value={selected}
          onChange={(e) => setSelected(e.target.value)}>
          <Option value="" />
          {[...Array(11)].map((_, index) => (
            <Option key={index} value={index + 1}>
              {index + 1}
            </Option>
          ))}
        </Select>
      )}
    </>
  );
}

MoreCriteriaMin.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
  })
};

MoreCriteriaMin.defaultProps = {
  type: "text",
  value: ""
};

export default MoreCriteriaMin;
