import React from "react";

function IconTransaction() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.25002 8C4.25002 8.41421 4.58581 8.75 5.00002 8.75H16C16.3034 8.75 16.5768 8.56727 16.6929 8.28701C16.809 8.00676 16.7449 7.68417 16.5304 7.46967L12.5304 3.46967C12.2375 3.17678 11.7626 3.17678 11.4697 3.46967C11.1768 3.76256 11.1768 4.23744 11.4697 4.53033L14.1894 7.25H5.00002C4.58581 7.25 4.25002 7.58579 4.25002 8Z"
        fill="#9c1616"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.81068 12.75L15 12.75C15.4142 12.75 15.75 12.4142 15.75 12C15.75 11.5858 15.4142 11.25 15 11.25L4.00002 11.25C3.69668 11.25 3.4232 11.4327 3.30711 11.713C3.19103 11.9932 3.25519 12.3158 3.46969 12.5303L7.46969 16.5303C7.76258 16.8232 8.23746 16.8232 8.53035 16.5303C8.82324 16.2374 8.82324 15.7626 8.53035 15.4697L5.81068 12.75Z"
        fill="#9c1616"
      />
    </svg>
  );
}

export default IconTransaction;
