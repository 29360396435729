import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import IconRemoveCircleOutline from "../icons/material/content/IconRemoveCircleOutline";
import { removeActivePage } from "../../actions/customize";

function CustomizePageRemove({ page, activePages, removeActivePage }) {
  return (
    <button
      className={`invisible text-red customize-active-item-remove ${
        page.parent === "listings" ? "pr-2" : "px-2"
      }`}
      type="button"
      onClick={() => {
        if (page.parent === "listings") {
          const listingsChildren = activePages.filter(
            (p) => p.parent === "listings" && p.key !== "listings_chapter"
          );

          if (!(listingsChildren.length - 1)) {
            removeActivePage("listings");
          }
        }

        // Then remove the item
        removeActivePage(page.key);
      }}
      aria-label={`Delete ${page.title} from your report`}>
      <IconRemoveCircleOutline width={24} height={24} />
    </button>
  );
}

CustomizePageRemove.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string,
    key: PropTypes.string,
    parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    children: PropTypes.array
  }),
  activePages: PropTypes.array,
  removeActivePage: PropTypes.func
};

const mapStateToProps = (state) => ({
  activePages: state.customize.active.pages
});

const mapDispatchToProps = (dispatch) => ({
  removeActivePage: (key) => dispatch(removeActivePage(key))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizePageRemove);
