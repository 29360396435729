import styled, { keyframes } from "styled-components";

const spinKeyframes = keyframes`
  100% {
    transform: rotate(360deg);
  }
`;

export const Container = styled.span`
  display: inline-flex;
  align-items: center;

  svg {
    width: 2rem;
    height: 2rem;
    flex-shrink: 0;
    margin-right: ${({ hasChildren }) => hasChildren && " 0.5rem"};
    animation: ${spinKeyframes} 0.75s linear infinite;
    fill: currentColor;
  }
`;
