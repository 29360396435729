import React from "react";
import PropTypes from "prop-types";
import { SearchMap, Markers } from "@wrstudios/components";
import { useSession } from "../../Session";

function Map({ markers, boundMarkers, spLat, spLon, showSpMarker, ...props }) {
  const hasSpCoords = !!spLat && !!spLon;
  const { currentUser, config } = useSession();
  // Don't try to get the user location if we have a subject property.
  const env = hasSpCoords ? undefined : config.railsEnv;
  const casJwt = hasSpCoords ? undefined : currentUser.casJwt;
  const center = hasSpCoords ? { lat: spLat, lon: spLon } : undefined;
  const subjectPropertyMarker = useSubjectPropertyMarker({
    lat: spLat,
    lon: spLon,
    showMarker: showSpMarker
  });
  const filteredMarkers = [...markers, subjectPropertyMarker].filter(Boolean);
  const filteredBoundMarkers = [...boundMarkers, subjectPropertyMarker].filter(
    Boolean
  );
  const pitch = 50;
  const padding = 50;
  const zoomPadding = padding - pitch;

  return (
    <SearchMap
      markers={filteredMarkers}
      boundMarkers={filteredBoundMarkers}
      zoomPadding={zoomPadding}
      {...props}
      env={env}
      casJwt={casJwt}
      mapOptions={{ pitch }}
      mapboxApiToken={config.mapboxApiToken}
      center={center}
    />
  );
}

Map.defaultProps = {
  markers: [],
  boundMarkers: [],
  showSpMarker: true
};

Map.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lat: PropTypes.number,
      lon: PropTypes.number,
      component: PropTypes.element
    })
  ),
  boundMarkers: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      lat: PropTypes.number,
      lon: PropTypes.number,
      component: PropTypes.element
    })
  ),
  spLat: PropTypes.number,
  spLon: PropTypes.number,
  showSpMarker: PropTypes.bool
};

function useSubjectPropertyMarker({ lat, lon, showMarker }) {
  if (!lat || !lon) return null;

  return {
    id: "subject-property-marker",
    lat,
    lon,
    component: showMarker ? <Markers.SubjectPropertyMarker /> : <div />
  };
}

export default Map;
