import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const Tab = styled.button`
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 2rem;
  margin: 0 1.2rem;
  padding: 0;
  color: ${({ theme, isActive, activeColor }) =>
    isActive ? activeColor : theme.styleGuide.colors.gray[800]};
  appearance: none;
  cursor: pointer;
  white-space: nowrap;

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}

  &:hover {
    color: ${({ activeColor }) => activeColor};
  }

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &:after {
    content: "";
    display: block;
    width: 100%;
    height: 0.2rem;
    margin-top: 0.8rem;
    background-color: ${({ isActive, activeColor }) =>
      isActive ? activeColor : "transparent"};
  }
`;
