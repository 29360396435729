import { requestState, rejectedState } from "../../../utils/redux";

export function request(state, action) {
  const { page } = action.payload;
  const hasInactivePages = state.inactive && state.inactive.pages;
  const hasActivePages = state.active && state.active.pages;
  let newState = { ...state };

  if (hasInactivePages) {
    const inactivePages = [...state.inactive.pages].map((p) => {
      if (p.id === page.id || p.key === page.key) {
        p = requestState(p);
      }

      return p;
    });

    newState = {
      ...newState,
      inactive: { ...state.inactive, pages: inactivePages }
    };
  }

  if (hasActivePages) {
    const activePages = [...state.active.pages].map((p) => {
      if (p.id === page.id || p.key === page.key) {
        p = requestState(p);
      }

      return p;
    });

    newState = { ...newState, active: { ...state.active, pages: activePages } };
  }

  return newState;
}

export function resolved(state, action) {
  const { key } = action.payload;
  const hasInactivePages = state.inactive && state.inactive.pages;
  const hasActivePages = state.active && state.active.pages;
  const hasCustomPages =
    state.customPages &&
    state.customPages.all &&
    state.customPages.all["My Custom Pages"];
  let newState = { ...state };

  if (hasInactivePages) {
    const inactivePages = [...state.inactive.pages].filter(
      (inactivePage) => inactivePage.key !== key
    );

    newState = {
      ...newState,
      inactive: { ...state.inactive, pages: inactivePages }
    };
  }

  if (hasActivePages) {
    const activePages = [...state.active.pages].filter(
      (activePage) => activePage.key !== key
    );

    newState = { ...newState, active: { ...state.active, pages: activePages } };
  }

  if (hasCustomPages) {
    const customPages = [...state.customPages.all["My Custom Pages"]].filter(
      ([, value]) => value !== key
    );

    newState = {
      ...newState,
      customPages: {
        ...state.customPages,
        all: { ...state.customPages.all, "My Custom Pages": customPages },
        selected: {}
      }
    };
  }

  return newState;
}

export function rejected(state, action) {
  const { page, error } = action.payload;
  let inactivePages = [];
  let activePages = [];
  const hasInactivePages = state.inactive && state.inactive.pages;
  const hasActivePages = state.active && state.active.pages;
  let newState = { ...state };

  if (hasInactivePages) {
    inactivePages = [...state.inactive.pages].map((p) => {
      if (p.id === page.id || p.key === page.key) {
        p = rejectedState(p, error);
      }

      return p;
    });

    newState = {
      ...newState,
      inactive: { ...state.inactive, pages: inactivePages }
    };
  }

  if (hasActivePages) {
    activePages = [...state.active.pages].map((p) => {
      if (p.id === page.id || p.key === page.key) {
        p = rejectedState(p, error);
      }

      return p;
    });

    newState = { ...newState, active: { ...state.active, pages: activePages } };
  }

  return newState;
}
