import { useState } from "react";

export function useCopyText({ originalText, copiedText, timer = 2500 }) {
  const [copyText, setCopyText] = useState(originalText);

  const handleCopy = () => {
    setCopyText(copiedText);
    const handler = setTimeout(() => {
      setCopyText(originalText);
    }, timer);
    return () => {
      clearTimeout(handler);
    };
  };

  return [copyText, handleCopy];
}
