import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 auto;

  input[type="file"] {
    width: 0;
    height: 0;
    overflow: hidden;
    position: absolute;
    top: 0;
    left: 0;

    &:focus + label {
      outline: auto 0.5rem -webkit-focus-ring-color;
    }
  }

  label {
    margin: 2.5rem 0 1rem;
  }
`;

export const Grid = styled.div`
  display: flex;
  margin: -0.5rem;
`;

export const Cell = styled.div`
  padding: 0.5rem;
`;

export const Remove = styled.button`
  font-size: 1.3rem;
  line-height: 2.4rem;
  padding: 0;
  color: ${({ theme }) => theme.styleGuide.colors.cma[500]};
  appearance: none;
  cursor: pointer;
`;
