import React, { useEffect } from "react";
import { get } from "lodash";
import { useSession } from "../../../Session";
import Button from "../../common/Button";
import Loading from "../../common/Loading";
import FauxLoader from "../../common/FauxLoader";
import { translate } from "../../../utils/locale";
import { useHomebeatEditor } from "./HomebeatEditorProvider";
import HomebeatEditorActions from "./HomebeatEditorActions";
import {
  Preview,
  PreviewText,
  PreviewIFrame,
  PreviewControls,
  Card,
  CardHeader,
  CardText,
  CardAction
} from "./styled/homebeat-editor-preview";

function HomebeatEditorPreview() {
  const { mls } = useSession();
  const {
    homebeat,
    showMobilePreview,
    creatingError,
    updatingError,
    generatePreview,
    isGeneratingPreview,
    setIsGeneratingPreview,
    showPreview,
    iFrameKey,
    isDisabled
  } = useHomebeatEditor();

  let primaryColor = get(homebeat, "report.themeColors[0]");
  let secondaryColor = get(homebeat, "report.themeColors[1]");

  if (primaryColor && !primaryColor.startsWith("#"))
    primaryColor = `#${primaryColor}`;
  if (secondaryColor && !secondaryColor.startsWith("#"))
    secondaryColor = `#${secondaryColor}`;

  const guid = get(homebeat, "report.guid");
  const companyName = get(homebeat, "report.user.companyName");
  const companyLogo = get(homebeat, "report.user.companyLogo");
  const website = get(homebeat, "report.user.website");

  const errorMessage =
    get(creatingError, "networkError.result.error") ||
    get(updatingError, "networkError.result.error");
  const errorStatus =
    get(creatingError, "networkError.statusCode") ||
    get(updatingError, "networkError.statusCode");
  const hasDuplicate = errorStatus === 406;
  const hasNoListings = errorMessage
    ?.toLowerCase()
    ?.includes("no matching listings");

  useEffect(() => {
    let isCurrent = true;
    const eventMethod = window.addEventListener
      ? "addEventListener"
      : "attachEvent";
    const eventer = window[eventMethod];
    const messageEvent = eventMethod == "attachEvent" ? "onmessage" : "message";

    eventer(
      messageEvent,
      (e) => {
        if (typeof e.data === "object" && e.data.source && e.data.payload) {
          if (e.data.source === "homebeat" && e.data.payload === "loaded") {
            if (isCurrent) setIsGeneratingPreview(false);
          }
        }
      },
      false
    );

    return () => {
      isCurrent = false;
    };
  }, []);

  return (
    <Preview isShowingMobilePreview={showMobilePreview}>
      {(creatingError || updatingError) && (
        <PreviewText hasError={true}>
          <Card>
            <CardHeader hasError={true}>
              {hasNoListings
                ? "No comparable homes found"
                : hasDuplicate
                ? "Duplicate Homebeat Found"
                : "Error"}
            </CardHeader>
            <CardText>
              {errorMessage || "An error has occurred. Please try again."}
            </CardText>
            {!hasDuplicate && (
              <CardText>Check to make sure your address is correct</CardText>
            )}
            <CardText>
              Your {translate("mls.label", "MLS")}: {mls.title}
            </CardText>
          </Card>
        </PreviewText>
      )}
      {!creatingError && !updatingError && (
        <>
          {!showPreview && !isGeneratingPreview && (
            <PreviewText>
              <Card>
                <CardHeader>Preview Your Homebeat</CardHeader>
                <CardText>
                  Once you've filled out the information on the left, you can
                  click "Show Preview" to see a live rendering of the Homebeat
                  before it's sent.
                </CardText>
                <CardAction>
                  <Button
                    app="cma"
                    variant="secondary"
                    disabled={isDisabled}
                    onClick={generatePreview}>
                    {isGeneratingPreview ? (
                      <Loading>Generating Preview</Loading>
                    ) : (
                      "Show Preview"
                    )}
                  </Button>
                </CardAction>
              </Card>
            </PreviewText>
          )}
          {isGeneratingPreview && (
            <PreviewText>
              <FauxLoader
                agentCompanyName={companyName}
                agentCompanyLogo={companyLogo}
                agentWebsite={website}
                primaryColor={primaryColor}
                secondaryColor={secondaryColor}
                stallOnStep={4}
                steps={[
                  "Fetching listings",
                  "Assembling maps",
                  "Calculating values",
                  "Finalizing"
                ]}
              />
            </PreviewText>
          )}
          {showPreview && (
            <PreviewIFrame
              id="homebeat-preview-iframe"
              key={iFrameKey}
              src={`${window.location.origin}/homebeat/${guid}?show-loading=false`}
              frameBorder="0"
              isGeneratingPreview={isGeneratingPreview}
            />
          )}
        </>
      )}
      <PreviewControls>
        <HomebeatEditorActions isPreview />
      </PreviewControls>
    </Preview>
  );
}

export default HomebeatEditorPreview;
