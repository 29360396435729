import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { colors } from "@wrstudios/theme";
import { formatCurrency } from "@wrstudios/utils";
import { Markers } from "@wrstudios/components";
import { toggle } from "../../../actions/listings";
import { activeListings } from "../../../selectors/listings";
import Map from "../../common/Map";
import Checkbox from "../../common/Checkbox";
import ListingsMapLoading from "./ListingsMapLoading";
import ListingsMapError from "./ListingsMapError";
import {
  Container,
  Popup,
  CheckboxContainer,
  ListingMarker
} from "./styled/listings-map";

function ListingsMap({ report, subjectProperty, activeListings, toggle }) {
  const markers = activeListings.map((listing) => {
    return {
      id: listing.id,
      lat: Number(listing.geo_lat || 0),
      lon: Number(listing.geo_lon || 0),
      zIndex: listing.hide ? 0 : 2,
      component: (
        <ListingMarker
          price={listing.price}
          onClick={({ map }) => {
            map.easeTo({ center: [listing.geo_lon, listing.geo_lat] });
          }}
          color={
            listing.hide ? colors.gray[600] : `#${listing.status_as_color}`
          }
          hide={listing.hide}
        />
      ),
      popupComponent: (
        <Popup>
          <Markers.ListingPopup
            id={listing.id}
            photos={(listing.data || {}).photos || []}
            address={(listing.data || {}).address}
            city={(listing.data || {}).city}
            priceFormatted={formatCurrency(listing.price)}
            state={(listing.data || {}).state}
            zip={(listing.data || {}).zip}
            statusLabel={listing.status}
            statusValue={(listing.data || {}).mapped_status}
          />
          <CheckboxContainer>
            <Checkbox
              checked={!listing.hide}
              onChange={() => {
                toggle(report.id, listing.id);
              }}
            />
          </CheckboxContainer>
        </Popup>
      )
    };
  });

  return (
    <>
      <Container>
        <Map
          markers={markers}
          boundMarkers={markers}
          spLat={subjectProperty ? Number(subjectProperty.geo_lat) : undefined}
          spLon={subjectProperty ? Number(subjectProperty.geo_lon) : undefined}
        />
        <ListingsMapLoading />
      </Container>
      <ListingsMapError />
    </>
  );
}

ListingsMap.propTypes = {
  listing: PropTypes.shape({
    data: PropTypes.shape({
      id: PropTypes.string.isRequired,
      lat: PropTypes.number.isRequired,
      lon: PropTypes.number.isRequired,
      address: PropTypes.string.isRequired,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.number,
      status: PropTypes.string.isRequired,
      price: PropTypes.number.isRequired,
      photos: PropTypes.arrayOf(PropTypes.string)
    }).isRequired,
    hide: PropTypes.bool.isRequired
  }),
  report: PropTypes.shape({
    id: PropTypes.number.isRequired
  }).isRequired,
  activeListings: PropTypes.arrayOf(PropTypes.object).isRequired,
  toggle: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  report: state.report,
  activeListings: activeListings(state),
  subjectProperty: state.subjectProperty
});

const mapDispatchToProps = {
  toggle
};

export default connect(mapStateToProps, mapDispatchToProps)(ListingsMap);
