import React from "react";
import PropTypes from "prop-types";
import Downshift from "downshift";
import SelectDropdownButton from "./SelectDropdownButton";
import SelectDropdown from "./SelectDropdown";
import { Select as StyledSelect } from "./styled/select";

function Select({
  id,
  name,
  small,
  options,
  selected,
  placeholder,
  disabled,
  autoFocus,
  onSelect
}) {
  selected = selected || { label: "", value: "" };

  return (
    <Downshift
      initialSelectedItem={selected}
      itemToString={(item) => (item ? item : "")}
      onSelect={onSelect}>
      {({ getItemProps, getRootProps, toggleMenu, isOpen, selectedItem }) => {
        return (
          <StyledSelect small={small} open={isOpen} {...getRootProps()}>
            {name && (
              <input
                type="hidden"
                name={name}
                value={(selectedItem || {}).value || ""}
              />
            )}
            <SelectDropdownButton
              id={id}
              options={options}
              placeholder={placeholder}
              selected={selectedItem}
              isOpen={isOpen}
              toggleMenu={toggleMenu}
              autoFocus={autoFocus}
              disabled={disabled}
            />
            {isOpen ? (
              <SelectDropdown
                options={options}
                selected={selectedItem}
                getItemProps={getItemProps}
              />
            ) : null}
          </StyledSelect>
        );
      }}
    </Downshift>
  );
}

Select.defaultProps = {
  placeholder: "",
  disabled: false,
  selected: { label: "", value: "" },
  small: false,
  autoFocus: false,
  onSelect: function () {}
};

Select.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  selected: PropTypes.shape({
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  autoFocus: PropTypes.bool,
  small: PropTypes.bool,
  options: PropTypes.array,
  onSelect: PropTypes.func
};

export default Select;
