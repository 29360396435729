import React, { useState } from "react";
import { addMonths } from "date-fns";
import { formatDate } from "@wrstudios/utils";
import { useSession } from "../../../Session";
import { acresToLotSize, lotSizeToAcres } from "../../../utils/number";
import Label from "../../common/Label";
import { Select, Option } from "../../common/Select";
import Editor from "../../common/Editor";
import AddressAutoComplete from "../../address/AddressAutoComplete";
import {
  frequencies,
  getFrequencyInMonths,
  getFrequencyFriendlyLabel
} from "../utils";
import { useHomebeatEditor } from "./HomebeatEditorProvider";
import {
  Grid,
  Cell,
  Input,
  EditorContainer,
  Italic
} from "./styled/homebeat-editor-form";

function Form() {
  const { mls } = useSession();
  const {
    name,
    setName,
    email,
    setEmail,
    address,
    setAddress,
    addressCoords,
    setAddressCoords,
    propertyType,
    setPropertyType,
    propertySubType,
    setPropertySubType,
    beds,
    setBeds,
    baths,
    setBaths,
    sqft,
    setSqft,
    garages,
    setGarages,
    lotSize,
    setLotSize,
    acres,
    setAcres,
    frequency,
    setFrequency,
    welcomeEmail,
    setWelcomeEmail,
    noFrequencies,
    setCreatingError,
    setUpdatingError
  } = useHomebeatEditor();
  const [taxData, setTaxData] = useState({});
  const landMeasurement =
    (window.agent || {}).landMeasurement || mls.landMeasurement;

  const resetErrors = () => {
    setCreatingError(null);
    setUpdatingError(null);
  };

  const handleAddressSelect = (address, taxData) => {
    const [lng, lat] = address.coordinates;
    let newLotSize = taxData.lotsize || lotSize;
    let newAcres = taxData.acres || acres;

    newLotSize = Number(newLotSize || acresToLotSize(newAcres).toFixed(2) || 0);
    newAcres = Number(newAcres || lotSizeToAcres(newLotSize).toFixed(2) || 0);

    setAddress(address.value);
    setAddressCoords({ lat, lng });
    setTaxData(taxData);
    if (newLotSize) setLotSize(newLotSize);
    if (newAcres) setAcres(newAcres);
    if (taxData.beds) setBeds(taxData.beds);
    if (taxData.baths) setBaths(taxData.baths);
    if (taxData.sqft) setSqft(taxData.sqft);
    if (taxData.garages) setGarages(taxData.garages);
    resetErrors();
  };

  return (
    <Grid>
      <Cell columns="12">
        <Label htmlFor="client-name">Client Name</Label>
        <Input
          data-autofocus
          id="client-name"
          placeholder="John Doe"
          value={name}
          onChange={(e) => {
            setName(e.target.value);
            resetErrors();
          }}
        />
      </Cell>
      <Cell columns="12">
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="email"
          placeholder="john@example.com"
          value={email}
          onChange={(e) => {
            setEmail(e.target.value);
            resetErrors();
          }}
        />
      </Cell>
      <Cell columns="12">
        <AddressAutoComplete
          id="address"
          name="address"
          address={address}
          lat={String(addressCoords.lat)}
          lng={String(addressCoords.lng)}
          onSelect={handleAddressSelect}
          onChange={(address) => {
            setAddress(address);
            resetErrors();
          }}
        />
      </Cell>
      {mls.propertyTypes.enabled && (
        <Cell columns="6">
          <Label htmlFor="property-type">Property Type</Label>
          <Select
            isFullWidth
            id="property-type"
            value={propertyType}
            onChange={(e) => {
              setPropertyType(e.target.value);
              resetErrors();
            }}>
            <Option value="">None</Option>
            {mls.propertyTypes.options.map((propertyType) => (
              <Option key={propertyType} value={propertyType}>
                {propertyType}
              </Option>
            ))}
          </Select>
        </Cell>
      )}
      {mls.propertyTypes.enabled && mls.propertySubTypes.enabled && (
        <Cell columns="6">
          <Label htmlFor="property-sub-type">Property Sub Type</Label>
          <Select
            isFullWidth
            id="property-sub-type"
            value={propertySubType}
            onChange={(e) => {
              setPropertySubType(e.target.value);
              resetErrors();
            }}>
            <Option value="">None</Option>
            {mls.propertySubTypes.options.map((propertySubType) => (
              <Option key={propertySubType} value={propertySubType}>
                {propertySubType}
              </Option>
            ))}
          </Select>
        </Cell>
      )}
      <Cell columns="4">
        <Label htmlFor="beds">Beds</Label>
        <Input
          id="beds"
          hasTaxData={!!taxData.beds}
          value={beds}
          onChange={(e) => {
            setTaxData(({ beds, ...taxData }) => taxData);
            setBeds(e.target.value);
            resetErrors();
          }}
        />
      </Cell>
      <Cell columns="4">
        <Label htmlFor="baths">Baths</Label>
        <Input
          id="baths"
          hasTaxData={!!taxData.baths}
          value={baths}
          onChange={(e) => {
            setTaxData(({ baths, ...taxData }) => taxData);
            setBaths(e.target.value);
            resetErrors();
          }}
        />
      </Cell>
      <Cell columns="4">
        <Label htmlFor="sqft">Sq Ft</Label>
        <Input
          id="sqft"
          hasTaxData={!!taxData.sqft}
          value={sqft}
          onChange={(e) => {
            setTaxData(({ sqft, ...taxData }) => taxData);
            setSqft(e.target.value);
            resetErrors();
          }}
        />
      </Cell>
      <Cell columns="4">
        <Label htmlFor="garages">Garages</Label>
        <Input
          name="garages"
          id="garages"
          hasTaxData={!!taxData.garages}
          value={garages}
          onChange={(e) => {
            setTaxData(({ garages, ...taxData }) => taxData);
            setGarages(e.target.value);
            resetErrors();
          }}
        />
      </Cell>
      {landMeasurement !== "acres" && (
        <Cell columns="4">
          <Label htmlFor="lot-size">Lot Size</Label>
          <Input
            name="lotSize"
            id="lot-size"
            hasTaxData={!!taxData.lotsize}
            value={lotSize}
            onChange={(e) => {
              setTaxData(({ lotsize, ...taxData }) => taxData);
              setLotSize(e.target.value);
              resetErrors();
            }}
          />
        </Cell>
      )}
      {landMeasurement === "acres" && (
        <Cell columns="4">
          <Label htmlFor="acres">Lot Size</Label>
          <Input
            name="acres"
            id="acres"
            hasTaxData={!!taxData.acres || !!taxData.lotsize}
            value={acres}
            onChange={(e) => {
              setTaxData(({ acres, ...taxData }) => taxData);
              setAcres(e.target.value);
              resetErrors();
            }}
          />
        </Cell>
      )}
      <Cell columns="12">
        <Label htmlFor="message">Message</Label>
        <EditorContainer>
          <Editor
            value={welcomeEmail}
            onEditorChange={(message) => {
              setWelcomeEmail(message);
              resetErrors();
            }}
            options={{
              toolbar: false,
              statusbar: false,
              plugins: "",
              paste_data_images: false,
              height: 188,
              resize: false
            }}
          />
        </EditorContainer>
      </Cell>
      <Cell columns="6">
        <Label htmlFor="frequency">Frequency</Label>
        <Select
          isFullWidth
          id="frequency"
          value={frequency}
          onChange={(e) => {
            setFrequency(e.target.value);
            resetErrors();
          }}>
          {frequencies.map((frequency) => (
            <Option key={frequency.label} value={frequency.value}>
              {frequency.label}
            </Option>
          ))}
        </Select>
      </Cell>
      <Cell columns="12">
        {!noFrequencies.includes(frequency) && (
          <Italic>
            The first Homebeat email will be sent now. Once accepted, the
            Homebeat will be automatically resent{" "}
            {getFrequencyFriendlyLabel(frequency)} starting{" "}
            {formatDate({
              dateValue: addMonths(new Date(), getFrequencyInMonths(frequency)),
              formating: "MMMM d, yyyy"
            })}
            .
          </Italic>
        )}
        {noFrequencies.includes(frequency) && (
          <Italic>A homebeat will not be sent.</Italic>
        )}
      </Cell>
    </Grid>
  );
}

export default Form;
