import React from "react";

function IconIncludeAll() {
  return (
    <svg
      width="21"
      height="14"
      viewBox="0 0 21 14"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink">
      <g
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        transform="translate(-804.000000, -809.000000)">
        <g transform="translate(177.000000, 795.000000)">
          <g transform="translate(625.000000, 8.000000)">
            <g>
              <polygon points="0 0 24 0 24 24 0 24" />
              <path
                d="M14,10 L2,10 L2,12 L14,12 L14,10 L14,10 Z M14,6 L2,6 L2,8 L14,8 L14,6 L14,6 Z M2,16 L10,16 L10,14 L2,14 L2,16 L2,16 Z M21.5,11.5 L23,13 L16.01,20 L11.5,15.5 L13,14 L16.01,17 L21.5,11.5 L21.5,11.5 Z"
                fill="#576474"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconIncludeAll;
