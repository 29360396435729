import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return { ...state, language: requestState(state.language) };
}

export function resolved(state, action) {
  return {
    ...state,
    language: resolvedState({
      ...state.language,
      selected: action.payload.locale
    })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    language: rejectedState(state.language, action.payload.error)
  };
}
