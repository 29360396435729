import styled from "styled-components";
import { TabList } from "react-tabs";

export const Container = styled(TabList)`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0;
  list-style: none;
`;
