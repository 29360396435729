import {
  SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_COPY_NET_SHEET,
  SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_REMOVE_PHOTO,
  SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM,
  SUBJECT_PROPERTY_UPDATE_LIST_PRICE,
  SUBJECT_PROPERTY_UPDATE_NET_SHEET_ITEMS,
  SUBJECT_PROPERTY_UPDATE_NOTE,
  SUBJECT_PROPERTY_UPDATE_PRICE_RANGE
} from "../actionTypes/subject-property";
import { api } from "../utils/redux";

/**
 * Formats our net sheet items into form data the server can understand.
 *
 * @param {array} netSheetItems
 * @param {array} exclude Array of net_sheet_item ids to exclude from the request
 */
function formatData(netSheetItems, exclude = []) {
  const formData = new FormData();

  netSheetItems.forEach(({ id, key, value, dateId }) => {
    if (!exclude.includes(id) && !exclude.includes(dateId)) {
      formData.append(`net_sheet_items[items][${id}][key]`, key);
      formData.append(`net_sheet_items[items][${id}][value]`, value);
    }
  });

  formData.append("send_back_as_json", 1);

  return formData;
}

export function copyNetSheet(subjectPropertyId, copy) {
  return api(
    SUBJECT_PROPERTY_COPY_NET_SHEET,
    { copy },
    {
      type: "text",
      method: "post",
      url: `/subject_property/${subjectPropertyId}/net_sheet_items`,
      data: `copy_last=${String(Number(copy))}&send_back_as_json=1`,
      ensure: true
    }
  );
}

export function addNetSheetItem(subjectPropertyId, netSheetItem) {
  return api(
    SUBJECT_PROPERTY_ADD_NET_SHEET_ITEM,
    { ...netSheetItem },
    {
      type: "text",
      method: "post",
      url: `/subject_property/${subjectPropertyId}/net_sheet_items`,
      data: ({ subjectProperty }) =>
        formatData(subjectProperty.net_sheet_items),
      ensure: true
    }
  );
}

export function editNetSheetItem(subjectPropertyId, netSheetItem) {
  return api(
    SUBJECT_PROPERTY_EDIT_NET_SHEET_ITEM,
    { ...netSheetItem },
    {
      type: "text",
      method: "post",
      url: `/subject_property/${subjectPropertyId}/net_sheet_items`,
      data: ({ subjectProperty }) => {
        const netSheetItems = subjectProperty.net_sheet_items.map((item) => {
          if (item.id === netSheetItem.id) {
            return { ...item, ...netSheetItem };
          }

          return item;
        });

        return formatData(netSheetItems);
      },
      ensure: true
    }
  );
}

export function removeNetSheetItem(subjectPropertyId, netSheetItemId) {
  return api(
    SUBJECT_PROPERTY_REMOVE_NET_SHEET_ITEM,
    { id: netSheetItemId },
    {
      type: "text",
      method: "post",
      url: `/subject_property/${subjectPropertyId}/net_sheet_items`,
      data: ({ subjectProperty }) =>
        formatData(subjectProperty.net_sheet_items, [netSheetItemId]),
      ensure: true
    }
  );
}

export function reorderNetSheetItems(subjectPropertyId, startIndex, endIndex) {
  return api(
    SUBJECT_PROPERTY_REORDER_NET_SHEET_ITEM,
    { startIndex, endIndex },
    {
      type: "text",
      method: "post",
      url: `/subject_property/${subjectPropertyId}/net_sheet_items`,
      data: ({ subjectProperty }) => formatData(subjectProperty.net_sheet_items)
    }
  );
}

export function updateSuggestedListPrice(reportId, subjectPropertyId, price) {
  return api(
    SUBJECT_PROPERTY_UPDATE_LIST_PRICE,
    { filter: "price" },
    {
      method: "put",
      url: `/reports/${reportId}/subject_properties/${subjectPropertyId}`,
      data: { subject_property: { price } },
      ensure: true
    }
  );
}

export function updateSuggestedListPriceManually(
  reportId,
  subjectPropertyId,
  price
) {
  return api(
    SUBJECT_PROPERTY_UPDATE_LIST_PRICE,
    { filter: "price_manually" },
    {
      method: "put",
      url: `/reports/${reportId}/subject_properties/${subjectPropertyId}`,
      data: { subject_property: { price } },
      ensure: true
    }
  );
}

export function removePhoto(reportId, subjectPropertyId) {
  return api(
    SUBJECT_PROPERTY_REMOVE_PHOTO,
    {},
    {
      method: "delete",
      url: `/reports/${reportId}/subject_properties/${subjectPropertyId}/photo`
    }
  );
}

export function updateSubjectPropertyNote(note) {
  return {
    type: SUBJECT_PROPERTY_UPDATE_NOTE,
    payload: { note }
  };
}

export function updateSubjectPropertyPriceRange(priceRange) {
  return {
    type: SUBJECT_PROPERTY_UPDATE_PRICE_RANGE,
    payload: { price_range: priceRange }
  };
}

export function updateSubjectPropertyNetSheetItems(netSheetItems) {
  return {
    type: SUBJECT_PROPERTY_UPDATE_NET_SHEET_ITEMS,
    payload: { net_sheet_items: netSheetItems }
  };
}
