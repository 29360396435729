import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatDate } from "@wrstudios/utils";
import { updateListing, removeListing } from "../../actions/listings";
import { stripNumber } from "../../utils/number";
import ListingAddress from "./ListingAddress";
import ListingQuickInfo from "./ListingQuickInfo";
import ListingPrice from "./ListingPrice";
import ListingStatus from "./ListingStatus";
import ListingActions from "./ListingActions";
import ListingDetails from "./ListingDetails";
import ListingForm from "./ListingForm";

class Listing extends Component {
  state = {
    showDetails: false,
    hideStatus: false,
    isEditing: false
  };

  render() {
    const { listing } = this.props;
    const { showDetails, hideStatus, isEditing } = this.state;
    const { addresses, data = { ...listing }, status_as_color } = listing;
    const { status = "Subject Property" } = listing;
    const { mlsnum, year_built, source } = data;
    const {
      beds = data.beds,
      formatted_baths: baths = data.baths,
      sqft = data.sqft
    } = listing;
    const isSubjectProperty = status === "Subject Property";
    let { price = data.price, price_raw = 0 } = listing;

    if (!isSubjectProperty) {
      price = stripNumber(price);
    }

    return (
      <div
        ref={(container) => (this.container = container)}
        className={`listing rounded-md ${
          isSubjectProperty
            ? "border bg-purple-accent border-purple listing-subject-property"
            : "bg-white shadow-raised"
        }`}>
        {isEditing && (
          <ListingForm
            listing={listing}
            isUpdating={true}
            onRemove={this.onRemoveClick}
            onSubmit={this.onSubmit}
            onClose={this.onEditClose}
          />
        )}
        {!isEditing && (
          <Fragment>
            <div className="px-6 py-4 text-left">
              <div
                className={`flex ${
                  isSubjectProperty ? "flex-col sm:flex-row" : ""
                }`}>
                <div className="flex flex-col md:justify-between w-full mr-auto">
                  <div
                    className={`flex flex-col md:flex-row ${
                      !listing.hide ? "" : "opacity-50"
                    }`}>
                    <ListingAddress addresses={addresses} />
                  </div>
                  <ListingQuickInfo
                    beds={beds}
                    baths={baths}
                    sqft={sqft}
                    yearBuilt={year_built}
                  />
                </div>
                <div
                  className={`flex flex-col flex-no-shrink md:justify-between sm:items-end md:ml-12 text-right ${
                    !listing.hide ? "" : "opacity-50"
                  }`}>
                  <ListingPrice
                    price={price}
                    priceRaw={price_raw}
                    isSubjectProperty={isSubjectProperty}
                  />
                  {!hideStatus && !isSubjectProperty && (
                    <ListingStatus
                      priceRaw={price_raw}
                      adjustments={listing.adjustments}
                      status={status}
                      color={`#${status_as_color}`}
                    />
                  )}
                </div>
                <div className="hidden md:block">
                  <div className="flex flex-col flex-no-shrink md:justify-between sm:items-end w-full md:w-auto sm:mt-4 md:mt-0 md:ml-12">
                    <ListingActions
                      id={listing.id}
                      status={status}
                      hide={listing.hide || false}
                      isOpen={showDetails}
                      isManual={source === "manual" && !mlsnum}
                      onClick={this.onClick}
                      onEditClick={this.onEditClick}
                    />
                  </div>
                </div>
              </div>
              <div className="block md:hidden">
                <div className="flex flex-col flex-no-shrink md:justify-between sm:items-end w-full md:w-auto sm:mt-4 md:mt-0 md:ml-12">
                  <ListingActions
                    id={listing.id}
                    status={status}
                    hide={listing.hide || false}
                    isOpen={showDetails}
                    isManual={source === "manual" && !mlsnum}
                    onClick={this.onClick}
                    onEditClick={this.onEditClick}
                  />
                </div>
              </div>
            </div>
            {showDetails && <ListingDetails listing={{ ...listing, status }} />}
          </Fragment>
        )}
      </div>
    );
  }

  slideToDetails = () => {
    if (this.state.showDetails || this.state.isEditing) {
      const headerHeight = document
        .querySelector("#navigation")
        .getBoundingClientRect().height;
      const containerTop = this.container.getBoundingClientRect().top;

      window.scrollBy({
        top: containerTop - headerHeight,
        left: 0,
        behavior: "smooth"
      });
    }
  };

  constructFormData = (form) => {
    const formData = new FormData(form);
    const listDateKey = "listing[data][date_list]";
    const listSoldKey = "listing[data][date_sold]";
    const listDate = formData.get(listDateKey);
    const soldDate = formData.get(listSoldKey);

    if (listDate) {
      formData.set(
        listDateKey,
        formatDate({ dateValue: new Date(listDate), formating: "yyyy-MM-dd" })
      );
    }

    if (soldDate) {
      formData.set(
        listSoldKey,
        formatDate({ dateValue: new Date(soldDate), formating: "yyyy-MM-dd" })
      );
    }

    return formData;
  };

  onClick = (status, hideStatus = false) => {
    if (status === "Subject Property") {
      this.setState(
        {
          showDetails: true,
          hideStatus
        },
        () => {
          this.slideToDetails();
          this.props.onDetailsToggle(this.state.showDetails);
        }
      );
    } else {
      this.setState(
        ({ showDetails }) => ({
          showDetails: !showDetails,
          hideStatus
        }),
        () => {
          this.slideToDetails();
          this.props.onDetailsToggle(this.state.showDetails);
        }
      );
    }
  };

  onEditClick = () => {
    this.setState({ isEditing: true }, () => {
      this.slideToDetails();
    });
  };

  onEditClose = () => {
    this.setState({ isEditing: false });
  };

  onSubmit = async (e) => {
    e.preventDefault();

    const { report, listing, updateListing } = this.props;
    const formData = this.constructFormData(e.target);
    const res = await updateListing(report.id, listing.id, formData);

    if (res.error_msg) return;

    this.onEditClose();
  };

  onRemoveClick = () => {
    const { report, listing } = this.props;

    this.props.removeListing(report.id, listing.id);
  };
}

Listing.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number
  }),
  // Mixing strings and numbers from backend
  listing: PropTypes.shape({
    id: PropTypes.number,
    hide: PropTypes.bool,
    beds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    baths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    sqft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    year_built: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    price_raw: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    data: PropTypes.shape({
      mlsnum: PropTypes.string,
      address: PropTypes.string,
      city: PropTypes.string,
      state: PropTypes.string,
      zip: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      beds: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      baths: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      sqft: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      year_built: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      price: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      status: PropTypes.string,
      source: PropTypes.string
    }),
    adjustments: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
      })
    )
  }),
  onDetailsToggle: PropTypes.func,
  updateListing: PropTypes.func
};

Listing.defaultProps = {
  onDetailsToggle: function () {}
};

const mapStateToProps = (state) => ({
  report: state.report
});

const mapDispatchToProps = {
  updateListing,
  removeListing
};

export default connect(mapStateToProps, mapDispatchToProps)(Listing);
