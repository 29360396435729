import React from "react";
import Icon from "../../Icon";

function IconArrowDropdown(props) {
  return (
    <Icon {...props}>
      <path d="M7 10l5 5 5-5z" />
      <path d="M0 0h24v24H0z" fill="none" />
    </Icon>
  );
}

export default IconArrowDropdown;
