import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "@wrstudios/components";
import CustomizeModalThemeSelect from "./CustomizeModalThemeSelect";
import CustomizeModalThemeImages from "./CustomizeModalThemeImages";
import CustomizeModalThemeSave from "./CustomizeModalThemeSave";
import {
  ModalContent,
  ModalBody,
  ModalFooter,
  SelectContainer
} from "./styled/customize-modal-theme";

function CustomizeModalTheme({ selectedTheme, rawThemes, onClose }) {
  const [theme, setTheme] = useState(selectedTheme);
  const { title: label, key: value, colors } = theme;

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <Modal.Header>
          Theme
          <Modal.Close />
        </Modal.Header>
        <ModalBody>
          <SelectContainer>
            <CustomizeModalThemeSelect
              selected={{ label, value, colors }}
              onSelect={(selected) => {
                const newTheme = rawThemes.find(
                  (theme) => theme.key === selected.value
                );
                setTheme(newTheme);
              }}
            />
          </SelectContainer>
          <CustomizeModalThemeImages theme={theme} />
        </ModalBody>
        <ModalFooter>
          <CustomizeModalThemeSave theme={theme} onClick={onClose} />
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

CustomizeModalTheme.propTypes = {
  selectedTheme: PropTypes.object,
  rawThemes: PropTypes.arrayOf(PropTypes.object),
  onClose: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
  selectedTheme: state.customize.theme.selected,
  rawThemes: state.customize.theme.all
});

export default connect(mapStateToProps)(CustomizeModalTheme);
