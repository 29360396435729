import PropTypes from "prop-types";
import React, { Children, cloneElement, useState } from "react";
import { connect } from "react-redux";
import {
  updateSubjectPropertyNetSheetItems,
  updateSubjectPropertyNote,
  updateSubjectPropertyPriceRange
} from "../../actions/subject-property";
import snakecaseKeys from "../../utils/snakecase-keys";
import { transformSubjectProperty } from "../../utils/subject-property";
import SuggestedListPriceEditModal from "../suggested-list-price/SuggestedListPriceEditModal";
import UserCustomPagesEdit from "../user/UserCustomPagesEdit";
import CustomizeModalAveragePricePerSqft from "./CustomizeModalAveragePricePerSqft";
import CustomizeModalMyRecentSales from "./CustomizeModalMyRecentSales";
import CustomizeModalTimeToSell from "./CustomizeModalTimeToSell";

function CustomizePageEdit({
  report,
  subjectProperty,
  page,
  customPages,
  onOpen,
  onClose,
  children,
  updateSubjectPropertyNote,
  updateSubjectPropertyPriceRange,
  updateSubjectPropertyNetSheetItems
}) {
  const [showModal, setShowModal] = useState(false);
  const editablePages = [
    "suggested_list_price",
    "average_price_per_sqft",
    "time_to_sell"
  ];

  const handleClick = (e) => {
    e.preventDefault();
    setShowModal(true);
    onOpen();
  };

  const handleClose = () => {
    setShowModal(false);
    onClose();
  };

  const isEditablePage = (page) => {
    let pages = [];

    for (let group in customPages) {
      pages.push(Object.values(customPages[group]));
    }

    pages = pages.reduce((acc, cur) => acc.concat(cur), []);

    return (
      editablePages.includes(page.key) ||
      (pages.find(([, value]) => value === page.key) &&
        page.parent !== "listings")
    );
  };

  if (!isEditablePage(page)) return null;

  const modals = {
    my_recent_sales: (
      <CustomizeModalMyRecentSales
        reportId={report.id}
        page={page}
        onClose={handleClose}
      />
    ),
    time_to_sell: (
      <CustomizeModalTimeToSell
        reportId={report.id}
        page={page}
        onClose={handleClose}
      />
    ),
    average_price_per_sqft: (
      <CustomizeModalAveragePricePerSqft
        reportId={report.id}
        page={page}
        onClose={handleClose}
      />
    ),
    suggested_list_price: (
      <SuggestedListPriceEditModal
        report={report}
        subjectProperty={transformSubjectProperty(subjectProperty || {})}
        setSubjectProperty={(data) => {
          updateSubjectPropertyNote(data.note);
          updateSubjectPropertyPriceRange(data.priceRange);
          updateSubjectPropertyNetSheetItems(
            (data.netSheetItems || []).map(snakecaseKeys)
          );
        }}
        onClose={handleClose}
      />
    )
  };
  const modal = modals[page.key] || (
    <UserCustomPagesEdit
      reportId={report.id}
      pageKey={page.key}
      onClose={handleClose}
    />
  );

  return (
    <>
      {Children.map(children, (child) =>
        cloneElement(child, { onClick: handleClick })
      )}
      {showModal && modal}
    </>
  );
}

CustomizePageEdit.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  children: (
    <button
      className="text-sm leading-2xl text-green ml-auto edit-cta"
      type="button">
      Edit
    </button>
  )
};

CustomizePageEdit.propTypes = {
  report: PropTypes.shape({ id: PropTypes.number.isRequired }).isRequired,
  subjectProperty: PropTypes.object.isRequired,
  page: PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
    parent: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  }),
  customPages: PropTypes.object,
  onOpen: PropTypes.func,
  onClose: PropTypes.func
};

const mapStateToProps = (state) => ({
  report: state.report,
  subjectProperty: state.subjectProperty,
  customPages: state.customize.customPages.all
});

const mapDispatchToProps = {
  updateSubjectPropertyNote,
  updateSubjectPropertyPriceRange,
  updateSubjectPropertyNetSheetItems
};

export default connect(mapStateToProps, mapDispatchToProps)(CustomizePageEdit);
