import styled, { css } from "styled-components";

export const Container = styled.span`
  display: inline;
  position: relative;

  &.inline-block {
    display: inline-block;
  }
`;

export const Text = styled.span`
  display: block;
  padding: 0.6rem;
  ${({ fitContent }) =>
    fitContent
      ? css`
          padding: 0.6rem;
          white-space: nowrap;
        `
      : css`
          width: 15.4rem;
          padding: 0.4rem;
        `}
  font-size: 1.1rem;
  line-height: 1.5rem;

  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  background-color: ${({ theme }) => theme.colors.charcoal};
  border-radius: ${({ theme }) => theme.borderRadius.sm};
  text-shadow: none;
  position: absolute;
  left: 50%;
  z-index: 10;

  ${({ position }) =>
    position === "top" &&
    css`
      transform: translate(-50%, -100%);
      top: -1.2rem;
    `};

  ${({ position }) =>
    position === "bottom" &&
    css`
      transform: translate(-50%, 100%);
      bottom: -1rem;
    `};

  ${({ position }) =>
    position === "left" &&
    css`
      transform: translate(-100%, -50%);
      top: 50%;
      left: -1rem;
    `};

  &:after {
    display: ${({ showArrow }) => (showArrow ? "block" : "none")};
    content: "";
    border: 0.5rem solid transparent;
    position: absolute;
    left: 50%;
    z-index: 11;

    ${({ position }) =>
      position === "top" &&
      css`
        border-top-color: ${({ theme }) => theme.colors.charcoal};
        transform: translate(-50%, 100%);
        bottom: 0;
      `};

    ${({ position }) =>
      position === "bottom" &&
      css`
        border-bottom-color: ${({ theme }) => theme.colors.charcoal};
        transform: translate(-50%, -100%);
        top: 0;
      `};

    ${({ position }) =>
      position === "left" &&
      css`
        border-left-color: ${({ theme }) => theme.colors.charcoal};
        transform: translate(100%, -50%);
        top: 50%;
        left: auto;
        right: 0;
      `};
  }
`;
