function sortParents(startIndex, endIndex, pages) {
  const filteredPages = pages.filter(
    (page) => page.parent !== "listings" || page.key === "listings_chapter"
  );
  const listingsIndex = filteredPages.findIndex(
    (page) => page.key === "listings"
  );
  const [removed] = filteredPages.splice(startIndex, 1);

  filteredPages.splice(endIndex, 0, removed);

  if (listingsIndex !== -1) {
    const listingsChildren = pages.filter(
      (page) => page.parent === "listings" && page.key !== "listings_chapter"
    );

    filteredPages.splice(listingsIndex, 0, ...listingsChildren);
  }

  return filteredPages;
}

function sortChildren(startIndex, endIndex, pages) {
  pages = [...pages];

  const [removed] = pages.splice(startIndex, 1);

  pages.splice(endIndex, 0, removed);

  return pages;
}

export function sortActivePages(state, action) {
  let { startIndex, endIndex } = action.payload;
  const { pages } = state.active;

  const sortedPages = sortParents(startIndex, endIndex, pages);

  return {
    ...state,
    active: { ...state.active, pages: sortedPages },
    template: { ...state.template, hasModified: true }
  };
}

export function sortActiveChildren(state, action) {
  const { type, startIndex, endIndex } = action.payload;
  let { pages } = state.active;
  const parent = pages.find((page) => page.key === type);
  const children =
    (parent &&
      pages.filter(
        (page) => page.parent === parent.key && page.key !== "listings_chapter"
      )) ||
    [];
  const startChild = children[startIndex];

  // Find the start and end children in the pages array that represent the children indexes
  const indexDifference = -(startIndex - endIndex);
  const startChildPageIndex = pages.findIndex(
    (page) => page.key === startChild.key
  );
  const endChildPageIndex = startChildPageIndex + indexDifference;

  pages = sortChildren(startChildPageIndex, endChildPageIndex, pages);

  return {
    ...state,
    active: { ...state.active, pages },
    template: { ...state.template, hasModified: true }
  };
}
