import React, { useState } from "react";
import Helmet from "react-helmet";
import { useSession } from "../../Session";
import { updateUser } from "../../api/user";
import Button from "../common/Button";
import Flash from "../common/Flash";
import FormGroup from "../common/FormGroup";
import Input from "../common/Input";
import Label from "../common/Label";
import Loading from "../common/Loading";
import { Divider, Header, LWButton } from "./styled/settings-password";

function SettingsPassword() {
  const { currentUser, config } = useSession();
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const isDisabled =
    !password ||
    !confirmPassword ||
    password !== confirmPassword ||
    request.loading;

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setRequest({ loading: true, success: false, error: null });
      await updateUser(currentUser.id, { password });
      setRequest({ loading: false, success: true, error: null });
      setPassword("");
      setConfirmPassword("");
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error: error.originalResponse.data.error
      });
    }
  };

  const isLWManaged = currentUser.lwaId;

  return (
    <>
      <Helmet>
        <title>Password - Account Settings - Cloud CMA</title>
      </Helmet>
      <Header>Lone Wolf Account</Header>
      {isLWManaged ? (
        <>
          <p className="helix-body">
            You are using your Lone Wolf login to sign into this account. To
            manage your password and app connections go to your Lone Wolf
            account.
          </p>
          <LWButton href={`${config.lwPlatformUrl}/account/manage`}>
            Manage Lone Wolf Account
          </LWButton>
        </>
      ) : (
        <>
          <p className="helix-body">
            A Lone Wolf Account allows you to sign into all of your Lone Wolf
            products with a single password, and switch between them seamlessly.
          </p>
          <LWButton href={`${config.url}/connect/lone_wolf`}>
            Create or Connect a Lone Wolf Account
          </LWButton>
        </>
      )}
      {!isLWManaged && (
        <>
          <Divider />
          <form onSubmit={handleSubmit}>
            <Header>Password</Header>
            <FormGroup>
              <Label htmlFor="password">Password</Label>
              <Input
                id="password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="confirm-password">Confirm Password</Label>
              <Input
                id="confirm-password"
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Button app="cma" isFullWidth disabled={isDisabled}>
                {!request.loading && "Update Password"}
                {request.loading && <Loading>Updating Password</Loading>}
              </Button>
            </FormGroup>
            {request.error && (
              <FormGroup>
                <Flash variant="error">{request.error}</Flash>
              </FormGroup>
            )}
            {request.success && (
              <FormGroup>
                <Flash variant="success">Password Updated!</Flash>
              </FormGroup>
            )}
          </form>
        </>
      )}
    </>
  );
}

export default SettingsPassword;
