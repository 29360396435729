import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 25rem;
  padding: 2rem;
  overflow: auto;
  background-color: ${({ theme }) => theme.colors.grayLightest};
  border: 0.2rem solid ${({ theme }) => theme.colors.grayLighter};
  border-radius: ${({ theme }) => theme.borderRadius.md};

  ${({ theme }) => theme.media.sm`
    display: block;
    width: 100%;
    height: auto;
  `};
`;

export const LoadingContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

export const Images = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  margin: -1rem;

  ${({ theme }) => theme.media.sm`
    display: block;
    height: auto;
  `};
`;

export const ImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: 1rem;
`;

export const Image = styled.img`
  width: 100%;
  box-shadow: 0 0.2rem 0.4rem 0 rgba(0, 0, 0, 0.2);

  ${({ theme }) => theme.media.sm`
    width: auto;
    max-width: 100%;
  `};
`;
