import { useMutation, useQuery } from "@apollo/react-hooks";
import { formatNumber, pluralize } from "@wrstudios/utils";
import { get } from "lodash";
import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { isEmail } from "validator";
import Button from "../common/Button";
import Loading from "../common/Loading";
import { Table, TableData, TableHeading, TableRow } from "../common/Table";
import Title from "../common/Title";
import Wrapper from "../common/Wrapper";
import { CSV, CSVAction, CSVDropArea, CSVField } from "../csv";
import IconChevronLeft from "../icons/IconChevronLeft";
import IconError from "../icons/IconError";
import IconInfo from "../icons/IconInfo";
import IconUpload from "../icons/IconUpload";
import HomebeatEditor from "./editor/HomebeatEditor";
import { GET_HOMEBEATS, UPLOAD_HOMEBEAT_CONTACTS } from "./queries";
import {
  BrowseButton,
  ButtonCell,
  ButtonGrid,
  ButtonGridContainer,
  Container,
  Content,
  Error,
  ErrorFileName,
  ErrorIconWrapper,
  FieldDivider,
  Fields,
  FileUploadError,
  Header,
  HomebeatWrapper,
  InfoIconWrapper,
  NavigationIconWrapper,
  NavigationLinkWrapper,
  NavigationTitle,
  NavigationWrapper,
  Text,
  UploadArea,
  UploadAreaContent,
  UploadAreaContentWrapper,
  UploadAreaInfo,
  UploadAreaWrapper,
  UploadIconWrapper,
  UploadTitle
} from "./styled/homebeats-import";

const RECORD_LENGTH = 500;
const MAX_HOMEBEAT_RECORD = 2000;

function HomebeatsImport() {
  const { data: homebeatdata, loading: loadingHomebeatData } =
    useQuery(GET_HOMEBEATS);
  const homebeatRecordsCount = get(homebeatdata, "homebeats.meta.totalResults");
  const remainingHomebeatsCount = Math.max(
    MAX_HOMEBEAT_RECORD - homebeatRecordsCount,
    0
  );

  const history = useHistory();
  const [data, setData] = useState([]);
  const [fileName, setFileName] = useState();
  const [serverData, setServerData] = useState([]);
  const [isCSV, setIsCSV] = useState(false);
  const [isVCard, setIsVCard] = useState(false);
  const [oneByOne, setOneByOne] = useState(false);
  const [hasNoRecordsInFile, setHasNoRecordsInFile] = useState(false);
  const [hasNoRecordsWithEmails, setHasNoRecordsWithEmails] = useState(false);
  const hasRecords = !!data.length;
  const hasTooManyRecords = data.length > RECORD_LENGTH;
  const hasServerRecords = !!serverData.length;
  const [uploadContacts, { loading, error }] = useMutation(
    UPLOAD_HOMEBEAT_CONTACTS,
    {
      onCompleted: ({ uploadHomebeatContacts }) => {
        setServerData(uploadHomebeatContacts);
      }
    }
  );

  const upload = async (data) => {
    const hasEmails = !!data.filter((record) => isEmail(record.email)).length;
    setHasNoRecordsWithEmails(!hasEmails);

    // Only send if we have records with emails
    if (hasEmails) {
      uploadContacts({ variables: { input: { data } } });
    }
  };

  const handleOpen = ({ data, isCSV, isVCard, filename }) => {
    setIsVCard(isVCard);
    setIsCSV(isCSV);
    setData(data);
    setFileName(filename);
    setHasNoRecordsInFile(!data.length);

    // Upload to server since everything is uniform for vCards
    if (isVCard) {
      upload(data);
    }
  };

  return (
    <>
      {!oneByOne && (
        <HomebeatWrapper>
          <NavigationWrapper>
            <NavigationLinkWrapper href="/homebeats">
              <NavigationIconWrapper>
                <IconChevronLeft />
              </NavigationIconWrapper>
              <span>Back </span>
            </NavigationLinkWrapper>
            <NavigationTitle>Import Contacts</NavigationTitle>
          </NavigationWrapper>
          <Wrapper>
            {!hasServerRecords && (
              <>
                <Header>
                  {hasRecords && !hasTooManyRecords && isCSV && (
                    <Title>Review Your Import</Title>
                  )}
                  {hasRecords && !hasTooManyRecords && isVCard && (
                    <Title>Import Contacts</Title>
                  )}
                </Header>
                <Container>
                  <CSV>
                    {(!hasRecords ||
                      hasTooManyRecords ||
                      (hasNoRecordsWithEmails && isVCard)) && (
                      <CSVDropArea
                        onDrop={handleOpen}
                        disabled={remainingHomebeatsCount === 0}>
                        <UploadArea>
                          <UploadAreaWrapper>
                            <UploadAreaContentWrapper>
                              <UploadIconWrapper>
                                <IconUpload />
                              </UploadIconWrapper>
                              <UploadTitle>Drag and drop to upload</UploadTitle>
                              <BrowseButton>or browse</BrowseButton>
                              <UploadAreaContent>
                                {hasTooManyRecords || hasNoRecordsInFile ? (
                                  <FileUploadError>
                                    <ErrorFileName>
                                      <ErrorIconWrapper>
                                        <IconError />
                                      </ErrorIconWrapper>
                                      {fileName}
                                    </ErrorFileName>

                                    {hasTooManyRecords && (
                                      <>
                                        The file is over the {RECORD_LENGTH}{" "}
                                        Homebeat limit. Check your CSV file or
                                        remove unsubscribed and/or inactive
                                        Homebeats to free up space and try
                                        again.
                                      </>
                                    )}

                                    {hasNoRecordsInFile && (
                                      <>This file contains no contacts</>
                                    )}
                                  </FileUploadError>
                                ) : (
                                  <>
                                    <UploadAreaInfo>
                                      <InfoIconWrapper>
                                        <IconInfo />
                                      </InfoIconWrapper>
                                      Upload a vCard (.vcf) file or .csv file of
                                      your contacts
                                    </UploadAreaInfo>
                                    <UploadAreaInfo>
                                      <InfoIconWrapper>
                                        <IconInfo />
                                      </InfoIconWrapper>
                                      {RECORD_LENGTH} contacts allowed per
                                      import.
                                    </UploadAreaInfo>
                                    <UploadAreaInfo>
                                      <InfoIconWrapper>
                                        <IconInfo />
                                      </InfoIconWrapper>
                                      {loadingHomebeatData
                                        ? "_ _"
                                        : formatNumber(
                                            remainingHomebeatsCount
                                          )}{" "}
                                      out of {formatNumber(MAX_HOMEBEAT_RECORD)}{" "}
                                      Homebeats remaining.
                                    </UploadAreaInfo>
                                  </>
                                )}
                              </UploadAreaContent>
                            </UploadAreaContentWrapper>
                          </UploadAreaWrapper>
                        </UploadArea>
                      </CSVDropArea>
                    )}
                    {hasRecords && !hasTooManyRecords && isCSV && (
                      <Content>
                        <Text>
                          Make sure you are mapping your contact fields to the
                          right place.
                        </Text>
                        <Text>
                          Once everything looks tip-top, finish the import.
                        </Text>
                        <Fields>
                          <CSVField
                            label="Name"
                            column="name"
                            columnsToMatch={["name", "first name", "last name"]}
                          />
                          <FieldDivider />
                          <CSVField
                            label="Email"
                            column="email"
                            columnsToMatch={["email"]}
                          />
                          <FieldDivider />
                          <CSVField
                            label="Address"
                            column="address"
                            columnsToMatch={[
                              "address",
                              "street",
                              "city",
                              "state",
                              "zip"
                            ]}
                          />
                        </Fields>
                        <CSVAction
                          app="cma"
                          disabled={loading}
                          onClick={({ getMappedData }) => {
                            upload(getMappedData());
                          }}>
                          {!loading && "Correct, Finish Import"}
                          {loading && <Loading>Uploading</Loading>}
                        </CSVAction>
                      </Content>
                    )}
                  </CSV>
                  {error && (
                    <Error>
                      {get(
                        error,
                        "networkError.result.error",
                        "An error occurred while uploading your contacts. Please try again."
                      )}
                    </Error>
                  )}
                  {hasRecords &&
                    !hasTooManyRecords &&
                    !hasNoRecordsWithEmails &&
                    isVCard &&
                    loading && (
                      <Content>
                        <Loading>Uploading</Loading>
                      </Content>
                    )}
                  {hasNoRecordsWithEmails && (
                    <Error>
                      We could not find any contacts with emails. A contact must
                      have an email associated with them.
                    </Error>
                  )}
                </Container>
              </>
            )}
            {hasServerRecords && (
              <>
                <Header>
                  <Title>
                    You Imported {pluralize("Contact", serverData.length, true)}
                  </Title>
                </Header>
                <Container>
                  <Content>
                    <Text>
                      How would you like to set them up for Homebeats?
                    </Text>
                    <Text>
                      You can set everyone up at once, or you can seet up each
                      contact one-by-one.
                    </Text>
                    <ButtonGridContainer>
                      <ButtonGrid>
                        <ButtonCell>
                          <Button app="cma" onClick={() => setOneByOne(true)}>
                            Set up your new contacts
                          </Button>
                        </ButtonCell>
                        <ButtonCell>
                          <Button
                            app="cma"
                            variant="secondary"
                            as={Link}
                            to="/homebeats">
                            I'll set them up later
                          </Button>
                        </ButtonCell>
                      </ButtonGrid>
                    </ButtonGridContainer>
                    <Table>
                      <thead>
                        <TableRow>
                          <TableHeading>Name</TableHeading>
                          <TableHeading>Email</TableHeading>
                          <TableHeading>Address</TableHeading>
                        </TableRow>
                      </thead>
                      <tbody>
                        {serverData.map((homebeat) => (
                          <TableRow key={homebeat.id}>
                            <TableData>{homebeat.lead.name}</TableData>
                            <TableData>{homebeat.lead.email}</TableData>
                            <TableData>
                              {homebeat.subjectProperty.address}
                            </TableData>
                          </TableRow>
                        ))}
                      </tbody>
                    </Table>
                  </Content>
                </Container>
              </>
            )}
          </Wrapper>
        </HomebeatWrapper>
      )}
      {oneByOne && (
        <HomebeatEditor
          homebeats={serverData}
          isEditing={true}
          onFinish={() => history.goBack()}
        />
      )}
    </>
  );
}

export default HomebeatsImport;
