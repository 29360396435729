import { connect } from "react-redux";
import { resetPages } from "../../../actions/customize";
import {
  groupInactivePages,
  groupLuxPages,
  groupMarketingPages,
  groupTomPages
} from "../../../selectors/customize";
import { isClassicTheme } from "../../../utils/report";
import { getReportType } from "../../../utils/types";
import CustomizeInactiveFolders from "../CustomizeInactiveFolders";
import { hasKeys } from "../controls/utils";

function select(state) {
  return {
    reportId: state.report.id,
    reportType: getReportType(state.report.type),
    pages: groupInactivePages(state),
    marketingPages: groupMarketingPages(state),
    luxPages: groupLuxPages(state),
    tomPages: groupTomPages(state),
    accountName: state.user.account_name,
    accountPages: state.customize.customPages.account_pdfs,
    isDocument: state.customize.powerPacks.isDocument,
    sections: state.customize.powerPacks.sections,
    offerMarketingPages: state.customize.powerPacks.offer.marketing,
    offerLuxPages: state.customize.powerPacks.offer.lux,
    offerTomPages: state.customize.powerPacks.offer.tom,
    showMarketingPages: state.customize.powerPacks.show.marketing,
    showLuxPages: state.customize.powerPacks.show.lux,
    showTomPages: state.customize.powerPacks.show.tom,
    illustrations: state.customize.illustrations,
    isClassicTheme: isClassicTheme(state.customize.theme.selected),
    showIllustrationControl:
      hasKeys(state.customize.illustrations) &&
      state.customize.illustrations.show &&
      hasKeys(state.customize.theme.selected) &&
      isClassicTheme(state.customize.theme.selected)
  };
}

const dispatch = {
  resetPages
};

export default connect(select, dispatch)(CustomizeInactiveFolders);
