import { Modal } from "@wrstudios/components";
import { IconWarning } from "@wrstudios/icons";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { useSession } from "../../Session";
import { addActivePage } from "../../actions/customize";
import { storeRedirectUrl } from "../../api/user";
import PDFPreview from "../common/PDFPreview";
import CustomizePageEdit from "./CustomizePageEdit";
import {
  Close,
  Confirm,
  Description,
  Edit,
  Flash,
  FlashContent,
  Message,
  ModalBody,
  ModalClose,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Title
} from "./styled/customize-modal-pdf-preview";

function getPageWarningMessage(page) {
  switch (page.key) {
    case "my_recent_sales":
      return "This page will not publish because we could not find recent sales.";
    case "suggested_list_price":
      return "This page will not publish because the Suggested List Price is empty.";
    default:
      return "This page will not publish because it's empty.";
  }
}

function CustomizeModalPdfPreview({
  page,
  activePages,
  url,
  isEmpty,
  onClose,
  addActivePage
}) {
  const { features } = useSession();
  const isRdc = page.key.startsWith("rdc_");
  const isActive = activePages.some((p) => page.key === p.key);
  const isParentActive = activePages.some((p) => page.parent === p.key);

  return (
    <Modal onClose={onClose}>
      <ModalContent>
        <ModalHeader>
          <Title>Preview</Title>
          {isRdc && <Description>This is a sample PDF report.</Description>}
          <ModalClose />
        </ModalHeader>
        {isEmpty && (
          <Flash variant="warning" closable={false}>
            <FlashContent>
              <IconWarning />
              <div>
                <Message>
                  <strong>Warning:</strong> {getPageWarningMessage(page)}
                </Message>
                <CustomizePageEdit page={page}>
                  <Edit>Edit &rarr;</Edit>
                </CustomizePageEdit>
              </div>
            </FlashContent>
          </Flash>
        )}
        <ModalBody hideFooter={!isActive}>
          <PDFPreview url={url} />
        </ModalBody>
        {!isActive && (
          <ModalFooter>
            <Close onClick={onClose}>Close</Close>
            {isRdc && features.offerRdcToolkit && (
              <Confirm
                data-pendo-id="rdc-preview-modal-cta"
                href="https://succeed.realtor.com/listingtoolkit?utm_source=cloud_cma&utm_medium=website&utm_campaign=ccma_customize_preview&utm_term=popup"
                onClick={() => storeRedirectUrl("rdc", window.location.href)}>
                Get it now
              </Confirm>
            )}
            {(!isRdc ||
              features.hasRdcToolkit ||
              (!features.hasRdcToolkit && !features.offerRdcToolkit)) && (
              <Confirm
                as="button"
                onClick={() => {
                  // Add the parent item first
                  if (
                    page.key !== "listings_chapter" &&
                    page.parent === "listings" &&
                    !isParentActive
                  ) {
                    addActivePage(page.parent);
                  }

                  // Add the item after parent has been added.
                  addActivePage(page.key);
                  onClose();
                }}>
                Add to report
              </Confirm>
            )}
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
}

CustomizeModalPdfPreview.defaultProps = {
  isEmpty: false
};

CustomizeModalPdfPreview.propTypes = {
  page: PropTypes.shape({
    title: PropTypes.string
  }),
  url: PropTypes.string.isRequired,
  isEmpty: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  addActivePage: PropTypes.func
};

const mapStateToProps = (state) => ({
  activePages: state.customize.active.pages
});

const mapDispatchToProps = {
  addActivePage
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalPdfPreview);
