import styled from "styled-components";
import { Document as BaseDocument, Page as BasePage } from "react-pdf";

export const Document = styled(BaseDocument)`
  flex-grow: 1;
  width: 100%;
  height: 100%;
  padding: 2.4rem;

  ${({ theme }) => theme.media.sm`
    padding: 1.2rem;
  `}

  .react-pdf__Page__textContent,
  .linkAnnotation {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
  }
`;

export const Page = styled(BasePage)`
  max-width: ${({ isLandscape }) => (isLandscape ? "11in" : "8.5in")};
  margin: 0 auto 2.4rem;
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
  box-shadow: ${({ theme }) => theme.styleGuide.shadows.raised};

  ${({ theme }) => theme.media.sm`
    margin-bottom: 1.2rem;
  `}

  &:last-child {
    margin-bottom: 0;

    ${({ theme }) => theme.media.sm`
      margin-bottom: 0;
    `}
  }

  canvas {
    width: 100% !important;
    height: auto !important;
  }
`;
