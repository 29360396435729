import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state, action) {
  const { listingId, adjustment } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing = {
        ...listing,
        adjustments: [
          ...(listing.adjustments || []),
          requestState({ ...adjustment, listing_id: listingId })
        ],
        price: listing.price + Number(adjustment.value)
      }
    }
    return listing;
  });
  return { ...state, all: listings };
}

export function resolved(state, action) {
  const { listingId, id, uuid } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing.adjustments = (listing.adjustments || []).map((adjustment) => {
        if (adjustment.id === id || adjustment.id === uuid) {
          return resolvedState(action.payload);
        }
        return adjustment;
      });
    }
    return listing;
  });
  return { ...state, all: listings };
}

export function rejected(state, action) {
  const { listingId, uuid, adjustment, error } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing.adjustments = (listing.adjustments || []).map((a) => {
        if (a.id === adjustment.id || a.id === uuid) {
          return rejectedState({ ...adjustment, listing_id: listingId }, error);
        }
        return a;
      });
    }
    return listing;
  });
  return { ...state, all: listings };
}