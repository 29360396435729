import styled from "styled-components";
import {
  Container as BaseContainer,
  Text as BaseText
} from "../../styled/customize-controls-button";

export const Container = styled(BaseContainer.withComponent("span")).attrs({
  type: null
})``;

export const Text = styled(BaseText)`
  overflow: inherit;
  position: relative;
`;
