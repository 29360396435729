import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import IconComputer from "../icons/IconComputer";
import { Container, Title, Text } from "./styled/not-found";

function NotFound({ link, useNativeLink }) {
  return (
    <Container>
      <Title>Page Not Found</Title>
      <Text>
        Awkward... wanna{" "}
        {useNativeLink ? (
          <a href={link}>go home</a>
        ) : (
          <Link to={link}>go home</Link>
        )}
        ?
      </Text>
      <IconComputer />
    </Container>
  );
}

NotFound.defaultProps = {
  link: "/",
  useNativeLink: false
};

NotFound.propTypes = {
  link: PropTypes.string.isRequired,
  useNativeLink: PropTypes.bool
};

export default NotFound;
