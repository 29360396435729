import React, { Component } from "react";
import { hasClass } from "../../utils/page";
import ConfettiParticle from "./ConfettiParticle";
import { Particles } from "./styled/confetti";

class Confetti extends Component {
  state = {
    animate: true,
    particleCount: 100,
    particles: [],
    loaded: false
  };

  constructor(props) {
    super(props);

    this.resize = this.resize.bind(this);
    this.draw = this.draw.bind(this);
  }

  componentDidMount() {
    this._isMounted = true;

    window.addEventListener("resize", this.resize);
  }

  componentWillUnmount() {
    this._isMounted = false;

    window.removeEventListener("resize", this.resize);
  }

  resize() {
    this.ref(this.canvas);
  }

  draw() {
    const width = this.canvas.width;
    const height = this.canvas.height;
    const { animate, particleCount, particles } = this.state;
    const stop =
      !this._isMounted || particles.every((particle) => particle.outOfBounds);

    !stop && requestAnimationFrame(this.draw);

    this.context.clearRect(0, 0, width, height);

    for (let i = 0; i < particleCount; i++) {
      let particle = particles[i];

      particle.draw(this.context);
      particle.tiltAngle += particle.tiltAngleIncremental;
      particle.y += (Math.cos(particle.d) + 3 + particle.r / 2) / 2;
      particle.tilt = Math.sin(particle.tiltAngle - i / 3) * 15;

      if (animate) {
        if (
          !particle.outOfBounds &&
          (particle.x > width + 30 || particle.x < -30 || particle.y > height)
        ) {
          // If a confetti has fluttered out of view,
          // bring it back to above the viewport and let if re-fall.
          particle.x = Math.random() * width;
          particle.y = -30;
          particle.tilt = Math.floor(Math.random() * 10) - 20;
        }
      } else {
        if (particle.y - 30 > height) {
          particle.outOfBounds = true;
        }
      }
    }
  }

  ref(container) {
    const { particleCount, loaded } = this.state;
    const particles = [];

    if (container) {
      this.canvas = container;
      this.canvas.width = container.clientWidth;
      this.canvas.height = container.clientHeight;
      this.context = this.canvas.getContext("2d");

      for (let i = 0; i < particleCount; i++) {
        particles.push(
          new ConfettiParticle(
            this.canvas.width,
            this.canvas.height,
            particleCount
          )
        );
      }

      if (!loaded) {
        this.setState({ particles, loaded: true }, () => {
          this.draw();

          setTimeout(
            () => this._isMounted && this.setState({ animate: false }),
            2500
          );
        });
      }
    }
  }

  render() {
    return !hasClass("body", ["mobile", "tablet"]) ? (
      <Particles ref={this.ref.bind(this)} {...this.props} />
    ) : null;
  }
}

export default Confetti;
