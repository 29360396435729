import React, { Component } from "react";
import PropTypes from "prop-types";
import Loading from "../../common/Loading";
import IconCheckmark from "../../icons/IconCheckmark";
import IconErrorOutline from "../../icons/material/alert/IconErrorOutline";
import {
  Container,
  Checkbox,
  Label,
  Input,
  Marker
} from "./styled/customize-controls.button-illustrations";

class CustomizeControlsIllustrations extends Component {
  render() {
    const {
      request: { fetching, error = {} },
      active
    } = this.props;
    const hasError = !!Object.keys(error).length;

    return (
      <Container>
        <Checkbox>
          <Label>
            <Input
              type="checkbox"
              onChange={this.handleOnChange}
              checked={active}
              aria-label={`Illustrations ${active ? "shown" : "hidden"}`}
            />
            <Marker isActive={active} hasError={hasError}>
              {!fetching && !hasError && active && <IconCheckmark />}
              {fetching && !hasError && <Loading />}
              {!fetching && hasError && <IconErrorOutline />}
            </Marker>
            Include Illustrations
          </Label>
        </Checkbox>
      </Container>
    );
  }

  handleOnChange = () => {
    const {
      reportId,
      active,
      includeIllustrations,
      request: { fetching }
    } = this.props;

    if (fetching) return;

    includeIllustrations(reportId, !active);
  };
}

CustomizeControlsIllustrations.propTypes = {
  reportId: PropTypes.number,
  active: PropTypes.bool,
  request: PropTypes.shape({
    fetching: PropTypes.bool,
    error: PropTypes.obj
  }),
  includeIllustrations: PropTypes.func
};

CustomizeControlsIllustrations.defaultProps = {
  active: false
};

export default CustomizeControlsIllustrations;
