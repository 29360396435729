import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { formatDate } from "@wrstudios/utils";
import { addListing } from "../../actions/listings";
import IconPlaylistAdd from "../icons/material/av/IconPlaylistAdd";
import ListingForm from "./ListingForm";

class ListingNew extends Component {
  static propTypes = {
    listings: PropTypes.array,
    report: PropTypes.shape({
      id: PropTypes.number
    }),
    addListing: PropTypes.func
  };

  state = {
    isOpen: false
  };

  constructor(props) {
    super(props);

    this.onOpen = this.onOpen.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  scroll() {
    if (this.container) {
      const containerTop = this.container.getBoundingClientRect().top;
      const headerHeight = document
        .querySelector("#navigation")
        .getBoundingClientRect().height;

      window.scrollBy({
        top: Math.abs(containerTop - headerHeight) || containerTop,
        left: 0,
        behavior: "smooth"
      });
    }
  }

  onOpen() {
    this.setState({ isOpen: true }, () => this.scroll());
  }

  onClose() {
    this.setState({ isOpen: false });
  }

  constructFormData(form) {
    const formData = new FormData(form);
    const listDateKey = "listing[data][date_list]";
    const listSoldKey = "listing[data][date_sold]";
    const listDate = formData.get(listDateKey);
    const soldDate = formData.get(listSoldKey);

    if (listDate) {
      formData.set(
        listDateKey,
        formatDate({ dateValue: new Date(listDate), formating: "yyyy-MM-dd" })
      );
    }

    if (soldDate) {
      formData.set(
        listSoldKey,
        formatDate({ dateValue: new Date(soldDate), formating: "yyyy-MM-dd" })
      );
    }

    return formData;
  }

  async onSubmit(e) {
    e.preventDefault();

    const { report, listings, addListing } = this.props;
    const formData = this.constructFormData(e.target);
    const res = await addListing(report.id, formData, listings);

    if (res.error_msg) return;

    this.onClose();
  }

  render() {
    return (
      <div className="mt-4">
        {!this.state.isOpen && (
          <button
            className="flex items-center justify-center w-full leading-2xl text-grey rounded-md border border-dashed bg-grey-lightest p-7"
            type="button"
            onClick={this.onOpen}>
            <span className="mr-4">
              <IconPlaylistAdd width={24} height={24} />
            </span>
            <span>Add a Listing</span>
          </button>
        )}
        {this.state.isOpen && (
          <div ref={(container) => (this.container = container)}>
            <ListingForm onSubmit={this.onSubmit} onClose={this.onClose} />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  listings: state.listings.all,
  report: state.report
});

const mapDispatchToProps = (dispatch) => ({
  addListing: (reportId, formData, listings) =>
    dispatch(addListing(reportId, formData, listings))
});

export default connect(mapStateToProps, mapDispatchToProps)(ListingNew);
