import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { updateLayout } from "../../actions/customize";
import IconLayoutPhotoMap from "../icons/report/layouts/IconLayoutPhotoMap";
import IconLayoutPhotoPhoto from "../icons/report/layouts/IconLayoutPhotoPhoto";
import IconLayoutPhotoMaxData from "../icons/report/layouts/IconLayoutPhotoMaxData";
import IconLayoutTwoProperties from "../icons/report/layouts/IconLayoutTwoProperties";
import IconLayoutThreeProperties from "../icons/report/layouts/IconLayoutThreeProperties";
import IconLayoutFourProperties from "../icons/report/layouts/IconLayoutFourProperties";
import IconLayoutFlyer1 from "../icons/report/layouts/flyers/IconLayoutFlyer1";
import IconLayoutFlyer2 from "../icons/report/layouts/flyers/IconLayoutFlyer2";
import IconLayoutFlyer3 from "../icons/report/layouts/flyers/IconLayoutFlyer3";
import IconLayoutFlyer4 from "../icons/report/layouts/flyers/IconLayoutFlyer4";
import IconLayoutFlyer5 from "../icons/report/layouts/flyers/IconLayoutFlyer5";
import IconLayoutFlyer6 from "../icons/report/layouts/flyers/IconLayoutFlyer6";
import IconLayoutFlyer7 from "../icons/report/layouts/flyers/IconLayoutFlyer7";
import IconLayoutFlyer8 from "../icons/report/layouts/flyers/IconLayoutFlyer8";
import IconLayoutFlyer9 from "../icons/report/layouts/flyers/IconLayoutFlyer9";
import IconLayoutFlyer10 from "../icons/report/layouts/flyers/IconLayoutFlyer10";
import IconLayoutFlyer11 from "../icons/report/layouts/flyers/IconLayoutFlyer11";
import IconLayoutFlyer12 from "../icons/report/layouts/flyers/IconLayoutFlyer12";
import {
  Container,
  Badge,
  Title
} from "./styled/customize-modal-layout-item-icon";

export function getLayoutIcon(key, hover) {
  switch (key.toLowerCase()) {
    case "photo_map":
      return <IconLayoutPhotoMap width="100%" height="100%" hover={hover} />;
    case "photo_photo":
      return <IconLayoutPhotoPhoto width="100%" height="100%" hover={hover} />;
    case "photo_max_data":
      return (
        <IconLayoutPhotoMaxData width="100%" height="100%" hover={hover} />
      );
    case "two_props":
      return (
        <IconLayoutTwoProperties width="100%" height="100%" hover={hover} />
      );
    case "three_cols":
      return (
        <IconLayoutThreeProperties width="100%" height="100%" hover={hover} />
      );
    case "four_cols":
    case "four_props":
      return (
        <IconLayoutFourProperties width="100%" height="100%" hover={hover} />
      );
    case "flyer1":
      return <IconLayoutFlyer1 width="100%" height="100%" hover={hover} />;
    case "flyer2":
      return <IconLayoutFlyer2 width="100%" height="100%" hover={hover} />;
    case "flyer3":
      return <IconLayoutFlyer3 width="100%" height="100%" hover={hover} />;
    case "flyer4":
      return <IconLayoutFlyer4 width="100%" height="100%" hover={hover} />;
    case "flyer5":
      return <IconLayoutFlyer5 width="100%" height="100%" hover={hover} />;
    case "flyer6":
      return <IconLayoutFlyer6 width="100%" height="100%" hover={hover} />;
    case "flyer7":
      return <IconLayoutFlyer7 width="100%" height="100%" hover={hover} />;
    case "flyer8":
      return <IconLayoutFlyer8 width="100%" height="100%" hover={hover} />;
    case "flyer9":
      return <IconLayoutFlyer9 width="100%" height="100%" hover={hover} />;
    case "flyer10":
      return <IconLayoutFlyer10 width="100%" height="100%" hover={hover} />;
    case "flyer11":
      return <IconLayoutFlyer11 width="100%" height="100%" hover={hover} />;
    case "flyer12":
      return <IconLayoutFlyer12 width="100%" height="100%" hover={hover} />;
    default:
      console.warn("Missing layout icon for:", key);
      return (
        <IconLayoutPhotoMaxData width="100%" height="100%" hover={hover} />
      );
  }
}

function CustomizeModalLayoutItemIcon({
  reportId,
  layout,
  selected,
  updateLayout,
  onClick
}) {
  const isActive = layout.key === selected.key;
  const [isHovering, setIsHovering] = useState(false);

  return (
    <>
      <Container
        autoFocus={isActive}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
        onClick={() => {
          updateLayout(reportId, layout);
          onClick();
        }}>
        {isActive && <Badge>Currently Selected</Badge>}
        {getLayoutIcon(layout.key, isHovering || isActive)}
      </Container>
      <Title>{layout.title}</Title>
    </>
  );
}

CustomizeModalLayoutItemIcon.propTypes = {
  reportId: PropTypes.number,
  layout: PropTypes.shape({
    key: PropTypes.string
  }),
  selected: PropTypes.shape({
    key: PropTypes.string
  }),
  updateLayout: PropTypes.func,
  onClick: PropTypes.func
};

const mapStateToProps = (state) => ({
  reportId: state.report.id,
  selected: state.customize.layout.selected
});

const mapDispatchToProps = {
  updateLayout
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalLayoutItemIcon);
