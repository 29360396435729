import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { getTextFromHtmlString } from "../../utils/dom";
import { currency } from "../../utils/formatters";
import { stripNumber } from "../../utils/number";
import NetSheetList from "./NetSheetList";
import NetSheetOfferCopy from "./NetSheetOfferCopy";

function getExpensesTotal(expenses = []) {
  return expenses
    .map((expense) => stripNumber(expense.value))
    .reduce((prev, curr) => prev + curr, 0);
}

function getNetPriceWithExpenses(price = 0, expenses = []) {
  const firstPrice = stripNumber(
    getTextFromHtmlString(price)
      .replace(/,/gi, "")
      .split(/\D*([\d,]*)/gi)
      .filter(Boolean)[0] || 0
  );

  return firstPrice - getExpensesTotal(expenses);
}

function NetSheet({ subjectProperty }) {
  const { price, net_sheet_items, offer_copy } = subjectProperty;
  const expenseTotal = getExpensesTotal(net_sheet_items);
  const netTotal = getNetPriceWithExpenses(price, net_sheet_items);

  return (
    <Fragment>
      <h3 className="text-sbase leading-sxl font-normal text-grey-darker mb-4">
        Net Sheet
      </h3>

      <div className="tabley tabley-flex net-sheet">
        <div className="tabley-header">
          <div className="tabley-cell">
            <strong>Expense</strong>
          </div>
          <div className="tabley-cell text-right">
            <strong>Amount</strong>
          </div>
          <div className="tabley-cell">&nbsp;</div>
        </div>

        <NetSheetList />

        <div className="tabley-row">
          <div className="tabley-cell">Total selling Expenses:</div>
          <div className="tabley-cell text-right">
            <span title={currency(expenseTotal, null, true)}>
              {currency(expenseTotal)}
            </span>
          </div>
          <div className="tabley-cell text-right" />
        </div>
        <div className="tabley-row">
          <div className="tabley-cell">
            <strong>Net to seller:</strong>
          </div>
          <div className="tabley-cell text-right">
            <strong title={currency(netTotal, null, true)}>
              {currency(netTotal)}
            </strong>
          </div>
          <div className="tabley-cell text-right" />
        </div>
      </div>

      {offer_copy && <NetSheetOfferCopy />}
    </Fragment>
  );
}

NetSheet.propTypes = {
  subjectProperty: PropTypes.shape({
    price: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    net_sheet_items: PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired
      })
    ).isRequired,
    offer_copy: PropTypes.bool.isRequired
  }).isRequired
};

const mapStateToProps = (state) => ({
  subjectProperty: state.subjectProperty
});

export default connect(mapStateToProps)(NetSheet);
