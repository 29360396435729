import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { random } from "lodash";
import { colors } from "@wrstudios/theme";
import { IconChecked } from "@wrstudios/icons";
import { useSession } from "../../Session";
import IconCloudCMA from "../icons/logos/horizontal/IconCloudCMA";
import IllustrationScale from "../illustrations/IllustrationScale";
import Loading from "./Loading";
import {
  Container,
  Content,
  Grid,
  Cell,
  Illustration,
  List,
  Item,
  IconContainer,
  ItemIconLoading,
  ItemIcon,
  ItemText,
  Logo,
  PoweredBy,
  Error
} from "./styled/faux-loader";

function FauxLoader({
  primaryColor,
  secondaryColor,
  agentCompanyName,
  agentCompanyLogo,
  agentWebsite,
  error,
  steps,
  stallOnStep,
  onComplete
}) {
  const { config } = useSession();
  const [currentStep, setCurrentStep] = useState(1);
  const timer = useRef();
  const totalSteps = steps.length;

  useEffect(() => {
    if (error || currentStep > totalSteps || currentStep >= stallOnStep) {
      clearTimeout(timer.current);
      return;
    }
    timer.current = setTimeout(() => {
      setCurrentStep((currentStep) => currentStep + 1);
    }, random(300, 750));
    return () => {
      clearTimeout(timer.current);
    };
  }, [error, currentStep, stallOnStep]);

  return (
    <Container
      className="loading"
      primaryColor={primaryColor}
      secondaryColor={secondaryColor}>
      <Logo
        href={
          !!agentCompanyLogo && !!agentWebsite
            ? `//${agentWebsite}`
            : config.url
        }
        target="noopener noreferrer"
        className="logo">
        {!agentCompanyLogo && <IconCloudCMA />}
        {!!agentCompanyLogo && (
          <img src={agentCompanyLogo} alt={agentCompanyName} />
        )}
      </Logo>
      <Content>
        <Grid>
          <Cell>
            <Illustration>
              <IllustrationScale />
            </Illustration>
          </Cell>
          <Cell>
            <List>
              {steps.map((step, index) => (
                <Item
                  key={index}
                  className={`${
                    currentStep === index + 1
                      ? "loading-incomplete"
                      : "loading-complete"
                  } ${
                    currentStep >= index + 1
                      ? "loading-visible"
                      : "loading-invisible"
                  }`}>
                  <IconContainer>
                    <ItemIconLoading
                      isVisible={currentStep >= index + 1}
                      isLoading={currentStep === index + 1}
                      className="loading-icon">
                      <Loading />
                    </ItemIconLoading>
                    <ItemIcon
                      isVisible={currentStep >= index + 1}
                      isLoading={currentStep === index + 1}
                      className="loading-icon"
                      onAnimationEnd={
                        index + 1 >= totalSteps ? onComplete : undefined
                      }>
                      <IconChecked className="fill-primary" />
                    </ItemIcon>
                  </IconContainer>
                  <ItemText
                    isVisible={currentStep >= index + 1}
                    isLoading={currentStep === index + 1}
                    className="loading-text">
                    {step}
                  </ItemText>
                </Item>
              ))}
            </List>
          </Cell>
        </Grid>
      </Content>
      {error && (
        <Error>
          Sorry, there was an error while loading your report. Please try again.
        </Error>
      )}
      {!agentCompanyLogo && (
        <PoweredBy
          href={config.url}
          target="noopener noreferrer"
          className="loading-powered-by">
          <div>Powered by</div>
          <IconCloudCMA />
        </PoweredBy>
      )}
    </Container>
  );
}

FauxLoader.defaultProps = {
  primaryColor: colors.cma[500],
  secondaryColor: colors.cma[700],
  steps: [
    "Fetching listings",
    "Assembling maps",
    "Creating charts",
    "Calculating pricing"
  ]
};

FauxLoader.propTypes = {
  primaryColor: PropTypes.string,
  secondaryColor: PropTypes.string,
  agentCompanyName: PropTypes.string,
  agentCompanyLogo: PropTypes.string,
  agentWebsite: PropTypes.string,
  error: PropTypes.string,
  steps: PropTypes.arrayOf(PropTypes.string).isRequired,
  stallOnStep: PropTypes.number,
  onComplete: PropTypes.func
};

export default FauxLoader;
