export function getReportDetails(type) {
  switch (type) {
    case "Cma":
      return { url: "/cmas", urlLabel: "Back to CMAs" };
    case "Tour":
      return { url: "/tours", urlLabel: "Back to Buyer Tours" };
    case "Property":
      return { url: "/properties", urlLabel: "Back to Property Reports" };
    case "Flyer":
      return { url: "/flyers", urlLabel: "Back to Flyers" };
    case "Homebeat":
      return { url: "/homebeats", urlLabel: "Back to Homebeats" };
    default:
      return { url: "/", urlLabel: "Go Home" };
  }
}
