import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return {
    ...state,
    template: requestState({ ...state.template, load: false })
  };
}

export function resolved(state) {
  return {
    ...state,
    template: resolvedState({
      ...state.template,
      selected: {
        key: "default",
        title: "Default Template"
      },
      load: true
    })
  };
}

export function rejected(state, action) {
  return {
    ...state,
    template: rejectedState(state.template, action.payload.error)
  };
}
