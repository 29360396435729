import { Modal } from "@wrstudios/components";
import PropTypes from "prop-types";
import React from "react";
import { storeRedirectUrl } from "../../api/user";
import Vimeo from "../common/Vimeo";
import {
  Action,
  Actions,
  PreviewModalClose,
  PreviewModalContent
} from "./styled/customize-modal-kit";
import {
  Confirm,
  ModalBody,
  Title,
  VideoContainer
} from "./styled/customize-modal-rdc";

function CustomizeModalRdc({ confirmLink, onClose }) {
  return (
    <Modal onClose={onClose}>
      <PreviewModalContent>
        <PreviewModalClose />
        <ModalBody>
          <Title>
            Impress your clients with the new Realtor.com® Listing Toolkit
          </Title>
          <VideoContainer>
            <Vimeo videoId="823184463?h=db93197ace" />
          </VideoContainer>
          {!!confirmLink && (
            <Actions>
              <Action>
                <Confirm
                  data-pendo-id="rdc-ad-modal-cta"
                  href={confirmLink}
                  onClick={() => storeRedirectUrl("rdc", window.location.href)}>
                  Get it now
                </Confirm>
              </Action>
            </Actions>
          )}
        </ModalBody>
      </PreviewModalContent>
    </Modal>
  );
}

CustomizeModalRdc.propTypes = {
  confirmLink: PropTypes.string,
  onClose: PropTypes.func
};

export default CustomizeModalRdc;
