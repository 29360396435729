import React, { Component, Children, createRef } from "react";
import PropTypes from "prop-types";
import IconKeyboardArrowDown from "../../../icons/material/hardware/IconKeyboardArrowDown";
import IconKeyboardArrowUp from "../../../icons/material/hardware/IconKeyboardArrowUp";
import CustomizeControlsLanguageDropdownList from "./CustomizeControlsLanguageDropdownList";
import CustomizeControlsLanguageDropdownItem from "./CustomizeControlsLanguageDropdownItem";
import {
  Container,
  Selected,
  SubTitle,
  Icon
} from "./styled/customize-controls-language-dropdown";

function findSelectedIndex(value, children) {
  return Children.toArray(children).findIndex(
    (child) => child.props.value === value
  );
}

class CustomizeControlsLanguageDropdown extends Component {
  ref = createRef();
  selectRef = createRef();

  constructor(props) {
    super(props);

    const selectedIndex = findSelectedIndex(
      this.props.value,
      this.props.children
    );

    this.state = {
      isOpen: false,
      selectedIndex
    };
  }

  componentDidMount() {
    window.addEventListener("click", this.handleOnWindowClick);
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleOnWindowClick);
  }

  render() {
    const { value, disabled, onChange, children } = this.props;
    const { isOpen, selectedIndex } = this.state;
    const selectedItem = Children.toArray(children)[selectedIndex];

    return (
      <div ref={this.ref}>
        <Container>
          {/* Pseduo element to help trigger change on native event. */}
          <select
            ref={this.selectRef}
            style={{ position: "absolute", zIndex: -100, opacity: 0 }}
            value={(selectedItem && selectedItem.props.value) || value}
            tabIndex="-1"
            onChange={onChange}>
            {Children.map(children, (child) => (
              <option value={child.props.value}>{child.props.children}</option>
            ))}
          </select>
          <Selected disabled={disabled} onClick={this.handleOnToggle}>
            <SubTitle>{selectedItem}</SubTitle>
            <Icon>
              {!isOpen && <IconKeyboardArrowDown />}
              {isOpen && <IconKeyboardArrowUp />}
            </Icon>
          </Selected>
          {isOpen && (
            <CustomizeControlsLanguageDropdownList
              selectedIndex={selectedIndex}
              setSelectedIndex={this.handleOnSelect}>
              {children}
            </CustomizeControlsLanguageDropdownList>
          )}
        </Container>
      </div>
    );
  }

  handleOnToggle = () => {
    this.setState(({ isOpen }) => ({ isOpen: !isOpen }));
  };

  handleOnSelect = (selectedIndex) => {
    this.setState({ selectedIndex, isOpen: false }, () => {
      let event;
      if (typeof Event === "function") {
        event = new Event("change", { bubbles: true, cancelable: true });
      } else {
        event = document.createEvent("Event");
        event.initEvent("change", true, true);
      }

      this.selectRef.current.dispatchEvent(event);
    });
  };

  handleOnWindowClick = (e) => {
    if (!this.ref.current.contains(e.target)) {
      this.setState({ isOpen: false });
    }
  };
}

CustomizeControlsLanguageDropdown.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  onChange: PropTypes.func
};

CustomizeControlsLanguageDropdown.defaultProps = {
  disabled: false,
  onChange: function () {}
};

export {
  CustomizeControlsLanguageDropdown,
  CustomizeControlsLanguageDropdownItem
};
