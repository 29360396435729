import {
  ListingHeader as BaseListingHeader,
  RouteContainer as BaseRouteContainer
} from "@wrstudios/components";
import styled from "styled-components";
import BaseHomebeatViewerButton from "../HomebeatViewerButton";

export const Container = styled.div`
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};
  background-color: ${({ theme }) => theme.styleGuide.colors.white};
`;

export const Main = styled(BaseRouteContainer)`
  display: flex;
  align-items: flex-start;
  margin: 2.4rem auto;

  ${({ theme }) => theme.media.md`
    margin: 0;
  `}

  ${({ theme }) => theme.media.md`
    flex-direction: column;
  `}
`;

export const Content = styled.div`
  width: 100%;
  min-width: 0;
  margin-top: 2.4rem;
`;

export const Remarks = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0 0 3.4rem;
  color: ${({ theme }) => theme.styleGuide.colors.gray[900]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const Details = styled.div`
  a {
    color: ${({ theme }) => theme.homebeat.colors.primary};
  }
`;

export const Highlights = styled.div`
  margin-bottom: 3.4rem;
`;

export const Disclaimer = styled.div`
  margin: 3.2rem 0 0.8rem;
`;

export const Sidebar = styled.div`
  width: 100%;
  max-width: 32.8rem;
  flex-shrink: 0;
  margin-left: 3.4rem;
  position: sticky;
  top: 8.4rem;

  ${({ theme }) => theme.media.md`
    max-width: 100%;
    margin: 1.6rem 0;
    order: -1;
    position: static;
  `}
`;

export const NotFoundContainer = styled.div`
  padding: 4.8rem 2.4rem;

  ${({ theme }) => theme.media.sm`
    padding: 2.4rem;
  `}
`;

export const Error = styled.p`
  font-size: 1.4rem;
  line-height: 2rem;
  margin: 0;
  color: ${({ theme }) => theme.styleGuide.colors.red[600]};

  ${({ theme }) => theme.media.sm`
    font-size: 1.5rem;
  `}
`;

export const ListingHeader = styled(BaseListingHeader)`
  min-height: 6rem;
  padding-top: 1.2rem;
  padding-bottom: 1.2rem;
`;

export const HeaderControls = styled.div`
  @media (max-width: 1000px) {
    display: none;
  }
`;

export const Controls = styled.div`
  display: flex;
  align-items: center;
  margin: -1.2rem;
`;

export const Control = styled.div`
  padding: 1.2rem;
`;

export const Button = styled(BaseHomebeatViewerButton)`
  svg {
    width: 2rem;
    height: 2rem;
    margin-right: 1rem;
    fill: currentColor;
  }
`;
