import "focus-visible";
import { createGlobalStyle } from "styled-components";
import CircularEOT from "../fonts/lineto-circular-book.eot";
import CircularSVG from "../fonts/lineto-circular-book.svg";
import CircularTTF from "../fonts/lineto-circular-book.ttf";
import CircularWOFF from "../fonts/lineto-circular-book.woff";
import CircularMediumEOT from "../fonts/lineto-circular-medium.eot";
import CircularMediumSVG from "../fonts/lineto-circular-medium.svg";
import CircularMediumTTF from "../fonts/lineto-circular-medium.ttf";
import CircularMediumWOFF from "../fonts/lineto-circular-medium.woff";

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Circular';
        src: url('${CircularEOT}');
        src: url('${CircularEOT}?#iefix') format('embedded-opentype'),
             url('${CircularWOFF}') format('woff'),
             url('${CircularTTF}') format('truetype'),
             url('${CircularSVG}#CircularSVG') format('svg');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'Circular';
        src: url('${CircularMediumEOT}');
        src: url('${CircularMediumEOT}?#iefix') format('embedded-opentype'),
             url('${CircularMediumWOFF}') format('woff'),
             url('${CircularMediumTTF}') format('truetype'),
             url('${CircularMediumSVG}#CircularBoldSVG') format('svg');
    font-weight: 500;
    font-style: normal;
  }

  html {
    font-size: 62.5%;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
  }

  body {
    min-width: 32rem;
    overflow-x: hidden;
    margin: 0;

    body,
    input,
    textarea,
    select {
      font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Open Sans", "Ubuntu", "Droid Sans", "Helvetica Neue", sans-serif;
      font-size: 1.5rem;
      line-height: 2rem;
    }

    img,
    svg,
    select,
    label,
    button {
      vertical-align: middle;
    }

    input[type='number']::-webkit-inner-spin-button,
    input[type='number']::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    svg {
      display: inherit;
      fill: currentColor;
    }

    [data-react-element] {
      display: inherit;
    }

    button {
      color: inherit;
      appearance: none;
    }
  }

  .js-focus-visible :focus:not(.focus-visible) {
    outline: none;
  }
  
  .intercom-lightweight-app {
    z-index: 2000 !important;
  }
`;
