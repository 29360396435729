import PropTypes from "prop-types";
import React, { Children } from "react";
import IconSpinner from "../icons/IconSpinner";
import { Container } from "./styled/loading";

function Loading({ children, showSpinner, ...props }) {
  return (
    <Container hasChildren={!!Children.count(children)} {...props}>
      {showSpinner && <IconSpinner />}
      {children}
    </Container>
  );
}

Loading.defaultProps = {
  showSpinner: true
};

Loading.propTypes = {
  showSpinner: PropTypes.bool
};

export default Loading;
