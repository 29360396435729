import styled from "styled-components";

export const Discount = styled.div`
  max-width: 32rem;
  margin: 3rem auto 0;
`;

export const Update = styled.button`
  font-size: 1.6rem;
  line-height: 2.4rem;
  color: ${({ theme }) => theme.colors.purpleDarker};
  text-decoration: underline;
  appearance: none;
`;
