import React, { useState, useEffect, useRef } from "react";
import { useLazyQuery } from "@apollo/react-hooks";
import { useQueryString } from "../../utils/url";
import { useSession } from "../../Session";
import WistiaModal from "../common/WistiaModal";
import Navigation from "../common/Navigation";
import IconHomebeatLogo from "../icons/IconHomebeatLogo";
import IconPlay from "../icons/IconPlay";
import IconAutoPilot from "../icons/IconAutoPilot";
import IconStayValuable from "../icons/IconStayValuable";
import IconYourBranding from "../icons/IconYourBrand";
import IconShowMeTheComps from "../icons/IconShowMeTheComps";
import IconMarketTrends from "../icons/IconMarketTrends";
import IconLivingCMA from "../icons/IconLivingCMA";
import IconCheckmark from "../icons/IconCheckmark";
import { GET_HOMEBEAT_FEATURE_STATUS } from "./queries";
import {
  Container,
  Wrapper,
  Hero,
  HeroLogo,
  HeroTitle,
  HeroDescription,
  Actions,
  Action,
  Preview,
  SectionTitle,
  SectionSubTitle,
  SectionIcon,
  SectionDescription,
  SectionNumber,
  Grid,
  Cell,
  Card,
  Button,
  Working,
  Clients,
  Client,
  ClientDetails,
  ClientIcon,
  GettingStarted,
  GettingStartedFooter,
  GettingStartedSubTitle,
  GettingStartedList,
  GettingStartedListItem
} from "./styled/homebeat-marketing";

function HomebeatMarketing() {
  const { config, features, setFeatures } = useSession();
  const workingRef = useRef();
  const [isShowingVideo, setIsShowingVideo] = useState(false);
  const [tries, setTries] = useState(1);
  const { params } = useQueryString();
  const [startPolling] = useLazyQuery(GET_HOMEBEAT_FEATURE_STATUS, {
    pollInterval: tries > 30 ? 0 : 5000,
    notifyOnNetworkStatusChange: true,
    onCompleted: ({ features: { homebeat } }) => {
      if (!homebeat.hasHomebeat) {
        setTries((tries) => tries + 1);
        return;
      }
      setFeatures((features) => ({
        ...features,
        offerHomebeat: homebeat.offerHomebeat,
        hasHomebeat: homebeat.hasHomebeat
      }));
    }
  });

  useEffect(() => {
    if (params.poll === "true" && features.offerHomebeat) {
      startPolling();
    }
  }, [params.poll, features.offerHomebeat]);

  return (
    <>
      <Navigation />
      <Container>
        <Hero>
          <Wrapper>
            <HeroLogo>
              <IconHomebeatLogo />
            </HeroLogo>
            <HeroTitle>Set It and Forget It.</HeroTitle>
            <HeroDescription>
              Schedule live automated CMAs that keep you top-of-mind with
              homeowners.
            </HeroDescription>
            <Actions>
              {config.homebeatAddonUrl && (
                <Action>
                  <Button
                    app="cma"
                    onClick={() => {
                      window.open(config.homebeatAddonUrl, "_blank");
                      startPolling();
                    }}>
                    Try for free
                  </Button>
                </Action>
              )}
              <Action>
                <Button
                  app="suite"
                  variant="secondary"
                  onClick={() => setIsShowingVideo(true)}>
                  <IconPlay />
                  Watch Video
                </Button>
              </Action>
            </Actions>
          </Wrapper>
        </Hero>
        <Preview>
          <Wrapper
            onClick={() =>
              window.scrollTo({
                top: workingRef.current.getBoundingClientRect().top,
                behavior: "smooth"
              })
            }>
            <img
              src="https://res.cloudinary.com/wr-studios/image/upload/dpr_auto,f_auto/v1/cas/marketing/homebeat/hero-homebeat.png"
              alt="Homebeat Hero"
            />
          </Wrapper>
        </Preview>
        <Working ref={workingRef}>
          <Wrapper>
            <SectionTitle>
              Homebeat is working, even when you're not.
            </SectionTitle>
            <Grid>
              <Cell>
                <Card>
                  <SectionNumber>1</SectionNumber>
                  <SectionIcon>
                    <IconAutoPilot />
                  </SectionIcon>
                  <SectionSubTitle>Auto-pilot</SectionSubTitle>
                  <SectionDescription>
                    Automatically send your clients a comparison of their home
                    to others on the market. Choose monthly all the way up to
                    annually.
                  </SectionDescription>
                </Card>
              </Cell>
              <Cell>
                <Card>
                  <SectionNumber>2</SectionNumber>
                  <SectionIcon>
                    <IconStayValuable />
                  </SectionIcon>
                  <SectionSubTitle>Stay Valuable</SectionSubTitle>
                  <SectionDescription>
                    Comparing homes is just the beginning. When your clients are
                    ready for a complete home valuation, they can contact you
                    for a full CMA report.
                  </SectionDescription>
                </Card>
              </Cell>
              <Cell>
                <Card>
                  <SectionNumber>3</SectionNumber>
                  <SectionIcon>
                    <IconYourBranding />
                  </SectionIcon>
                  <SectionSubTitle>Your branding</SectionSubTitle>
                  <SectionDescription>
                    You're always by their side to answer questions about the
                    value of their home and the market around them.
                  </SectionDescription>
                </Card>
              </Cell>
            </Grid>
          </Wrapper>
        </Working>
        <Clients>
          <Wrapper>
            <SectionTitle>Your clients will love their Homebeat.</SectionTitle>
            <Client>
              <ClientDetails>
                <SectionSubTitle>"Show me the comps"</SectionSubTitle>
                <SectionDescription>
                  Let them compare their home to actual comparables (not AVMs)
                </SectionDescription>
              </ClientDetails>
              <ClientIcon>
                <IconShowMeTheComps />
              </ClientIcon>
            </Client>
            <Client>
              <ClientIcon>
                <IconMarketTrends />
              </ClientIcon>
              <ClientDetails>
                <SectionSubTitle>Market trends</SectionSubTitle>
                <SectionDescription>
                  See how the market is trending in their zip code.
                </SectionDescription>
              </ClientDetails>
            </Client>
            <Client>
              <ClientDetails>
                <SectionSubTitle>It's a living CMA</SectionSubTitle>
                <SectionDescription>
                  This online CMA is always current and will show the latest
                  market data everytime they check the link.
                </SectionDescription>
              </ClientDetails>
              <ClientIcon>
                <IconLivingCMA />
              </ClientIcon>
            </Client>
          </Wrapper>
        </Clients>
        <GettingStarted>
          <Wrapper>
            <SectionTitle>It's easy to get started.</SectionTitle>
            <Grid>
              <Cell>
                <Card>
                  <SectionNumber>1</SectionNumber>
                  <SectionSubTitle>Pick your client</SectionSubTitle>
                  <SectionDescription>
                    All you need is your client's email and home address.
                  </SectionDescription>
                </Card>
              </Cell>
              <Cell>
                <Card>
                  <SectionNumber>2</SectionNumber>
                  <SectionSubTitle>Schedule frequency</SectionSubTitle>
                  <SectionDescription>
                    You pick how often to touch base: Monthly all the way up to
                    Annually
                  </SectionDescription>
                </Card>
              </Cell>
              <Cell>
                <Card>
                  <SectionNumber>3</SectionNumber>
                  <SectionSubTitle>Send and automate</SectionSubTitle>
                  <SectionDescription>
                    They'll get their first Homebeat right away and then
                    automatically in the future.
                  </SectionDescription>
                </Card>
              </Cell>
            </Grid>
            {config.homebeatAddonUrl && (
              <GettingStartedFooter>
                <GettingStartedSubTitle>
                  Send your contacts a Homebeat today
                </GettingStartedSubTitle>
                <Button
                  app="cma"
                  href={config.homebeatAddonUrl}
                  target="_blank"
                  onClick={() => {
                    window.open(config.homebeatAddonUrl, "_blank");
                    startPolling();
                  }}>
                  Try for free
                </Button>
                <GettingStartedList>
                  <GettingStartedListItem>
                    <IconCheckmark />
                    Free trial
                  </GettingStartedListItem>
                </GettingStartedList>
              </GettingStartedFooter>
            )}
          </Wrapper>
        </GettingStarted>
      </Container>
      {isShowingVideo && (
        <WistiaModal
          videoId="fun1dhrqqd"
          onClose={() => setIsShowingVideo(false)}
        />
      )}
    </>
  );
}

export default HomebeatMarketing;
