import { requestState, rejectedState, resolvedState } from "../../utils/redux";

export function request(state) {
  return requestState(state);
}

export function resolved(state, action) {
  return resolvedState({ ...state, all: action.payload.listings });
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
