import React, { useState } from "react";
import Helmet from "react-helmet";
import { pickBy, identity } from "lodash";
import { updateUserAPI } from "../../api/user";
import { useSession } from "../../Session";
import YouTubeModal from "../common/YouTubeModal";
import Flash from "../common/Flash";
import Loading from "../common/Loading";
import {
  Header,
  Section,
  SubSection,
  SubHeader,
  IntegrationLabel,
  FormRow,
  FormGroup,
  Label,
  Input,
  Button,
  RemoveButton,
  CopyInput,
  CopyButton,
  Description,
  SubSectionDescription,
  Tutorial
} from "./styled/settings-integrations";
import CopyToClipboard from "react-copy-to-clipboard";
import { connectZF, disconnectTD, disconnectZF } from "../transaction/api";
import { connectTD } from "../transaction/api";

function fillMissing({ key, name, password }) {
  return {
    key: key || "",
    name: name || "",
    password: password || ""
  };
}
/* eslint-disable */
function buildParams({ key, ...params }) {
  return pickBy(params, identity);
}
/* eslint-disable */

function SettingsIntegrations() {
  const { currentUser, setCurrentUser, features } = useSession();
  const [zipForm, setZipForm] = useState({
    key: "ZipForm",
    name: "",
    password: ""
  });
  const [zipFormNetwork, setZipFormNetwork] = useState({
    loading: false,
    success: false,
    errors: []
  });

  const [transactionDeskNetwork, setTransactionDeskNetwork] = useState({
    loading: false,
    success: false,
    errors: []
  });

  const [ratedAgent, setRatedAgent] = useState(
    fillMissing(currentUser.ratedAgent)
  );
  const [realSatisfied, setRealSatisfied] = useState(
    fillMissing(currentUser.realSatisfied)
  );
  const [reach150, setReach150] = useState(fillMissing(currentUser.reach150));
  const [testimonialTree, setTestimonialTree] = useState(
    fillMissing(currentUser.testimonialTree)
  );
  const [zillow, setZillow] = useState(fillMissing(currentUser.zillow));
  const [titlefy, setTitlefy] = useState(fillMissing(currentUser.titlefy));
  const [showReviewsTutorial, setShowReviewsTutorial] = useState(false);
  const [showZillowTechTutorial, setShowZillowTechTutorial] = useState(false);
  const [request, setRequest] = useState(
    [
      ratedAgent.key,
      realSatisfied.key,
      reach150.key,
      testimonialTree.key,
      zillow.key,
      titlefy.key
    ].reduce(
      (res, key) => ({
        ...res,
        [key]: { loading: false, success: false, errors: [] }
      }),
      {}
    )
  );

  const isDisabled = false; /* request.loading || (!!titlefy.name && !titlefy.password); */
  const [apiKeyCopied, setApiKeyCopied] = useState(false);

  const setIntegration = (key, value) => {
    const setter = {
      [ratedAgent.key]: setRatedAgent,
      [realSatisfied.key]: setRealSatisfied,
      [reach150.key]: setReach150,
      [testimonialTree.key]: setTestimonialTree,
      [zillow.key]: setZillow,
      [titlefy.key]: setTitlefy
    }[key];
    setter(value);
  };

  const setIntegrationRequest = (key, value) => {
    setRequest({
      ...request,
      [key]: value
    });
  };

  const addZipForm = async () => {
    setZipFormNetwork({ loading: true, success: false, errors: [] });
    try {
      const response = await connectZF(
        currentUser.id,
        zipForm.name,
        zipForm.password
      );
      setCurrentUser({ ...currentUser, zipFormToken: response.contextId });
      setZipFormNetwork({ loading: false, success: true, errors: [] });
    } catch (error) {
      console.error("ZipForm Connect:", error);
      setZipFormNetwork({
        loading: false,
        success: false,
        errors: error.errors
          ? [error.errors]
          : ["Sorry, something went wrong..."]
      });
    }
  };

  const removeZipForm = async () => {
    setZipFormNetwork({ loading: true, success: false, errors: [] });
    try {
      await disconnectZF();
      setCurrentUser({ ...currentUser, zipFormToken: "" });
      setZipForm({ ...zipForm, name: "", password: "" });
      setZipFormNetwork({ loading: false, success: true, errors: [] });
    } catch (error) {
      console.error("ZipForm Disconnect:", error);
      setZipFormNetwork({
        loading: false,
        success: false,
        errors: error.errors
          ? [error.errors]
          : ["Sorry, something went wrong..."]
      });
    }
  };

  const addTransactionDesk = () => {
    setTransactionDeskNetwork({ loading: true, success: false, errors: [] });
    connectTD(currentUser.id, "", "settings");
  };

  const removeTransactionDesk = async () => {
    setTransactionDeskNetwork({ loading: true, success: false, errors: [] });
    try {
      await disconnectTD();
      setCurrentUser({ ...currentUser, transactionDeskToken: "" });
      setTransactionDeskNetwork({ loading: false, success: true, errors: [] });
    } catch (error) {
      console.error("TransactionDesk disconnect:", error);
      setTransactionDeskNetwork({
        loading: false,
        success: false,
        errors: error.errors
          ? [error.errors]
          : ["Sorry, something went wrong..."]
      });
    }
  };

  const addIntegration = async (key) => {
    setIntegrationRequest(key, {
      loading: true,
      success: false,
      errors: []
    });
    const creds = {
      [ratedAgent.key]: ratedAgent,
      [realSatisfied.key]: realSatisfied,
      [reach150.key]: reach150,
      [testimonialTree.key]: testimonialTree,
      [zillow.key]: zillow,
      [titlefy.key]: titlefy
    }[key];
    const payload = { [key]: buildParams(creds) };
    const response = await updateUserAPI(currentUser.id, payload);
    if (Object.values(response.errors).length) {
      setIntegrationRequest(key, {
        loading: false,
        success: false,
        errors: response.errors[key]
      });
    } else {
      setIntegrationRequest(key, { loading: false, success: true, errors: [] });
      setCurrentUser((currentUser) => ({
        ...currentUser,
        [key]: creds
      }));
    }
  };

  const removeIntegration = async (key) => {
    setIntegrationRequest(key, {
      loading: true,
      success: false,
      errors: []
    });
    const payload = { [key]: {} };
    const response = await updateUserAPI(currentUser.id, payload);
    if (Object.values(response.errors).length) {
      setIntegrationRequest(key, {
        loading: false,
        success: false,
        errors: response.errors[key]
      });
    } else {
      setIntegrationRequest(key, { loading: false, success: true, errors: [] });
      setIntegration(key, fillMissing({ key }));
      setCurrentUser((currentUser) => ({
        ...currentUser,
        [key]: fillMissing({ key })
      }));
    }
  };

  return (
    <>
      <Helmet>
        <title>Integrations - Account Settings - Cloud CMA</title>
      </Helmet>

      <Header>Integrations</Header>
      <Description>
        Connect Cloud CMA to these services to unlock additional content in your
        reports and improve your workflow.
      </Description>
      <Section>
        {features.hasTransactions && (
          <SubSection>
            <SubHeader>Transactions</SubHeader>
            <IntegrationLabel>Transactions (zipForm Edition)</IntegrationLabel>
            <FormRow>
              {currentUser.zipFormToken ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={zipFormNetwork.loading}
                  onClick={removeZipForm}>
                  {!zipFormNetwork.loading &&
                    "Disconnect from Transactions (zipForm Edition)"}
                  {zipFormNetwork.loading && <Loading>Disconnecting</Loading>}
                </RemoveButton>
              ) : (
                <>
                  <FormGroup>
                    <Label htmlFor="zip-form-id">User name</Label>
                    <Input
                      id="zip-form-id"
                      value={zipForm.name}
                      isFullWidth
                      onChange={(e) =>
                        setZipForm({ ...zipForm, name: e.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor="zip-form-password">Password</Label>
                    <Input
                      id="zip-form-password"
                      type="password"
                      isFullWidth
                      value={zipForm.password}
                      onChange={(e) =>
                        setZipForm({ ...zipForm, password: e.target.value })
                      }
                    />
                  </FormGroup>
                  <FormGroup>
                    <Button
                      app="cma"
                      isFullWidth
                      variant="tertiary"
                      disabled={!zipForm.name || !zipForm.password}
                      onClick={addZipForm}>
                      {!zipFormNetwork.loading && "Connect"}
                      {zipFormNetwork.loading && <Loading>Connecting</Loading>}
                    </Button>
                  </FormGroup>
                </>
              )}
            </FormRow>
            {Boolean(zipFormNetwork.errors.length) && (
              <FormGroup>
                <Flash variant="error">{zipFormNetwork.errors}</Flash>
              </FormGroup>
            )}
            <IntegrationLabel>
              Transactions (TransactionDesk Edition)
            </IntegrationLabel>
            <FormRow>
              {currentUser.transactionDeskToken ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  onClick={removeTransactionDesk}>
                  {!transactionDeskNetwork.loading &&
                    "Disconnect from Transactions (TransactionDesk Edition)"}
                  {transactionDeskNetwork.loading && (
                    <Loading>Disconnecting</Loading>
                  )}
                </RemoveButton>
              ) : (
                <Button
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  onClick={addTransactionDesk}>
                  {!transactionDeskNetwork.loading &&
                    "Sign into Transactions (TransactionDesk Edition)"}
                  {transactionDeskNetwork.loading && (
                    <Loading>Connecting</Loading>
                  )}
                </Button>
              )}
            </FormRow>
            {Boolean(transactionDeskNetwork.errors.length) && (
              <FormGroup>
                <Flash variant="error">{transactionDeskNetwork.errors}</Flash>
              </FormGroup>
            )}
          </SubSection>
        )}
        <SubSection>
          <SubHeader>Testimonials</SubHeader>
          <IntegrationLabel>RatedAgent</IntegrationLabel>
          <FormRow>
            <FormGroup>
              <Label htmlFor="rated-agent-id">User ID</Label>
              <Input
                id="rated-agent-id"
                value={ratedAgent.name}
                readOnly={currentUser.ratedAgent.name}
                onChange={(e) =>
                  setRatedAgent({ ...ratedAgent, name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              {currentUser.ratedAgent.name ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={request.ratedagent.loading}
                  onClick={() => removeIntegration(ratedAgent.key)}>
                  {!request.ratedagent.loading && "Remove"}
                  {request.ratedagent.loading && <Loading>Removing</Loading>}
                </RemoveButton>
              ) : (
                <Button
                  app="cma"
                  variant="tertiary"
                  disabled={!ratedAgent.name}
                  isFullWidth
                  onClick={() => addIntegration(ratedAgent.key)}>
                  {!request.ratedagent.loading && "Add"}
                  {request.ratedagent.loading && <Loading>Adding</Loading>}
                </Button>
              )}
            </FormGroup>
          </FormRow>
          {Boolean(request.ratedagent.errors.length) && (
            <FormGroup>
              <Flash variant="error">{request.ratedagent.errors}</Flash>
            </FormGroup>
          )}
          <IntegrationLabel>Reach150</IntegrationLabel>
          <FormRow>
            <FormGroup>
              <Label htmlFor="reach150-id">Email</Label>
              <Input
                id="reach150-id"
                value={reach150.name}
                readOnly={currentUser.reach150.name}
                onChange={(e) =>
                  setReach150({ ...reach150, name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              {currentUser.reach150.name ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={request.reach150.loading}
                  onClick={() => removeIntegration(reach150.key)}>
                  {!request.reach150.loading && "Remove"}
                  {request.reach150.loading && <Loading>Removing</Loading>}
                </RemoveButton>
              ) : (
                <Button
                  app="cma"
                  variant="tertiary"
                  disabled={!reach150.name}
                  isFullWidth
                  onClick={() => addIntegration(reach150.key)}>
                  {!request.reach150.loading && "Add"}
                  {request.reach150.loading && <Loading>Adding</Loading>}
                </Button>
              )}
            </FormGroup>
          </FormRow>
          {Boolean(request.reach150.errors.length) && (
            <FormGroup>
              <Flash variant="error">{request.reach150.errors}</Flash>
            </FormGroup>
          )}
          <IntegrationLabel>RealSatisfied</IntegrationLabel>
          <FormRow>
            <FormGroup>
              <Label htmlFor="realsatisfied-id">Vanity Name</Label>
              <Input
                id="realsatisfied-id"
                value={realSatisfied.name}
                readOnly={currentUser.realSatisfied.name}
                onChange={(e) =>
                  setRealSatisfied({ ...realSatisfied, name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              {currentUser.realSatisfied.name ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={request.realsatisfied.loading}
                  onClick={() => removeIntegration(realSatisfied.key)}>
                  {!request.realsatisfied.loading && "Remove"}
                  {request.realsatisfied.loading && <Loading>Removing</Loading>}
                </RemoveButton>
              ) : (
                <Button
                  app="cma"
                  variant="tertiary"
                  disabled={!realSatisfied.name}
                  isFullWidth
                  onClick={() => addIntegration(realSatisfied.key)}>
                  {!request.realsatisfied.loading && "Add"}
                  {request.realsatisfied.loading && <Loading>Adding</Loading>}
                </Button>
              )}
            </FormGroup>
          </FormRow>
          {Boolean(request.realsatisfied.errors.length) && (
            <FormGroup>
              <Flash variant="error">{request.realsatisfied.errors}</Flash>
            </FormGroup>
          )}
          <IntegrationLabel>Testimonial Tree</IntegrationLabel>
          <FormRow>
            <FormGroup>
              <Label htmlFor="testimonial-tree-id">Email</Label>
              <Input
                id="testimonial-tree-id"
                value={testimonialTree.name}
                readOnly={currentUser.testimonialTree.name}
                onChange={(e) =>
                  setTestimonialTree({
                    ...testimonialTree,
                    name: e.target.value
                  })
                }
              />
            </FormGroup>
            <FormGroup>
              {currentUser.testimonialTree.name ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={request.testimonialtree.loading}
                  onClick={() => removeIntegration(testimonialTree.key)}>
                  {!request.testimonialtree.loading && "Remove"}
                  {request.testimonialtree.loading && (
                    <Loading>Removing</Loading>
                  )}
                </RemoveButton>
              ) : (
                <Button
                  app="cma"
                  variant="tertiary"
                  disabled={!testimonialTree.name}
                  isFullWidth
                  onClick={() => addIntegration(testimonialTree.key)}>
                  {!request.testimonialtree.loading && "Add"}
                  {request.testimonialtree.loading && <Loading>Adding</Loading>}
                </Button>
              )}
            </FormGroup>
          </FormRow>
          {Boolean(request.testimonialtree.errors.length) && (
            <FormGroup>
              <Flash variant="error">{request.testimonialtree.errors}</Flash>
            </FormGroup>
          )}

          <IntegrationLabel>Zillow</IntegrationLabel>
          <FormRow>
            <FormGroup>
              <Label htmlFor="zillow-id">Screen Name or Email</Label>
              <Input
                id="zillow-id"
                value={zillow.name}
                readOnly={currentUser.zillow.name}
                onChange={(e) => setZillow({ ...zillow, name: e.target.value })}
              />
            </FormGroup>
            <FormGroup>
              {currentUser.zillow.name ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={request.zillow.loading}
                  onClick={() => removeIntegration(zillow.key)}>
                  {!request.zillow.loading && "Remove"}
                  {request.zillow.loading && <Loading>Removing</Loading>}
                </RemoveButton>
              ) : (
                <Button
                  app="cma"
                  variant="tertiary"
                  disabled={!zillow.name}
                  isFullWidth
                  onClick={() => addIntegration(zillow.key)}>
                  {!request.zillow.loading && "Add"}
                  {request.zillow.loading && <Loading>Adding</Loading>}
                </Button>
              )}
            </FormGroup>
          </FormRow>
          {Boolean(request.zillow.errors.length) && (
            <FormGroup>
              <Flash variant="error">{request.zillow.errors}</Flash>
            </FormGroup>
          )}
        </SubSection>

        <SubSection>
          <SubHeader>Title Insurance</SubHeader>
          <IntegrationLabel>Titlefy</IntegrationLabel>
          <FormRow>
            <FormGroup>
              <Label htmlFor="titlefy-id">User name</Label>
              <Input
                id="titlefy-id"
                value={titlefy.name}
                readOnly={
                  currentUser.titlefy.name && currentUser.titlefy.password
                }
                isFullWidth
                onChange={(e) =>
                  setTitlefy({ ...titlefy, name: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              <Label htmlFor="titlefy-password">Password</Label>
              <Input
                id="titlefy-password"
                type="password"
                isFullWidth
                value={titlefy.password}
                onChange={(e) =>
                  setTitlefy({ ...titlefy, password: e.target.value })
                }
              />
            </FormGroup>
            <FormGroup>
              {currentUser.titlefy.name && currentUser.titlefy.password ? (
                <RemoveButton
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={request.titlefy.loading}
                  onClick={() => removeIntegration(titlefy.key)}>
                  {!request.titlefy.loading && "Remove"}
                  {request.titlefy.loading && <Loading>Removing</Loading>}
                </RemoveButton>
              ) : (
                <Button
                  app="cma"
                  isFullWidth
                  variant="tertiary"
                  disabled={!titlefy.name || !titlefy.password}
                  onClick={() => addIntegration(titlefy.key)}>
                  {!request.titlefy.loading && "Connect"}
                  {request.titlefy.loading && <Loading>Connecting</Loading>}
                </Button>
              )}
            </FormGroup>
          </FormRow>
          {Boolean(request.titlefy.errors.length) && (
            <FormGroup>
              <Flash variant="error">{request.titlefy.errors}</Flash>
            </FormGroup>
          )}
        </SubSection>

        {showReviewsTutorial && (
          <YouTubeModal
            videoId="sGE5AeZ40C4"
            onClose={() => setShowReviewsTutorial(false)}
            autoplay
          />
        )}
      </Section>

      {features.hasAPIButton && (
        <Section>
          <SubSection>
            <SubHeader>Cloud CMA</SubHeader>
            <IntegrationLabel>Your API Key</IntegrationLabel>
            <SubSectionDescription>
              Use this key in apps like Zillow Tech Connect.{" "}
              <Tutorial onClick={() => setShowZillowTechTutorial(true)}>
                See how
              </Tutorial>
            </SubSectionDescription>
            <FormRow>
              <CopyInput
                id="api-key"
                data-testid="input-copy"
                value={currentUser.guid}
                isFullWidth
                readOnly
              />
              <FormGroup>
                <CopyToClipboard
                  text={currentUser.guid}
                  onCopy={() => setApiKeyCopied(true)}>
                  <CopyButton
                    app="cma"
                    variant="tertiary"
                    disabled={isDisabled}
                    isFullWidth>
                    {apiKeyCopied ? "Copied!" : "Copy"}
                  </CopyButton>
                </CopyToClipboard>
              </FormGroup>
            </FormRow>
            {showZillowTechTutorial && (
              <YouTubeModal
                videoId="hiu9dqejtPQ"
                onClose={() => setShowZillowTechTutorial(false)}
                autoplay
              />
            )}
          </SubSection>
        </Section>
      )}
    </>
  );
}

export default SettingsIntegrations;
