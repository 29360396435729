import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { pickBy } from "lodash";
import { CropperModal } from "@wrstudios/components";
import { updateUserWithFormData, removeUserCompanyLogo } from "../../api/user";
import { useSession } from "../../Session";
import FormGroup from "../common/FormGroup";
import Label from "../common/Label";
import Input from "../common/Input";
import { Select, Option } from "../common/Select";
import Tip from "../common/Tip";
import Button from "../common/Button";
import AlertDanger from "../common/AlertDanger";
import Loading from "../common/Loading";
import Flash from "../common/Flash";
import UserAvatarUploader from "../user/UserAvatarUploader";
import {
  LabelContainer,
  InputWithButtonContainer,
  Placeholder,
  Grid,
  Cell
} from "./styled/settings-profile";

function SettingsProfile() {
  const { currentUser, setCurrentUser, config } = useSession();
  const [fullName, setFullName] = useState(currentUser.name || "");
  const [companyName, setCompanyName] = useState(currentUser.companyName || "");
  const [companyLogo, setCompanyLogo] = useState({
    name: currentUser.companyLogoName || ""
  });
  const [companyLogoDimensions, setCompanyLogoDimensions] = useState();
  const [email, setEmail] = useState(currentUser.email || "");
  const [city, setCity] = useState(currentUser.city || "");
  const [state, setState] = useState(currentUser.state || "");
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    error: null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const formData = new FormData();
      formData.append("user[name]", fullName);
      formData.append("user[companyname]", companyName);
      formData.append("user[email]", email);
      formData.append("user[city]", city);
      formData.append("user[state]", state);

      if (
        companyLogo &&
        !!Object.values(pickBy(companyLogoDimensions)).length
      ) {
        formData.append("user[logo][file]", companyLogo);
        formData.append("user[logo][crop][width]", companyLogoDimensions.width);
        formData.append(
          "user[logo][crop][height]",
          companyLogoDimensions.height
        );
        formData.append("user[logo][crop][x]", companyLogoDimensions.x);
        formData.append("user[logo][crop][y]", companyLogoDimensions.y);
      }

      setRequest({ loading: true, success: false, error: null });
      const user = await updateUserWithFormData(currentUser.id, formData);
      setRequest({ loading: false, success: true, error: null });
      setCurrentUser((currentUser) => {
        let data = {
          ...currentUser,
          name: fullName,
          companyName,
          email,
          city,
          state
        };

        if (companyLogo) {
          data = {
            ...data,
            companyLogo: user.logo,
            companyLogoName: companyLogo.name
          };
        }
        return data;
      });
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error: error.originalResponse.data.error
      });
    }
  };

  return (
    <>
      <Helmet>
        <title>Profile - Account Settings - Cloud CMA</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <FormGroup>
          <UserAvatarUploader />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="full-name">Full Name</Label>
          <Input
            id="full-name"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="company-name">Company Name</Label>
          <Input
            id="company-name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </FormGroup>
        <SettingsProfileCompanyLogo
          companyLogo={companyLogo}
          onCrop={({ file, dimensions }) => {
            setCompanyLogo(file);
            setCompanyLogoDimensions(dimensions);
          }}
          onDelete={() => {
            setCompanyLogo({ name: "" });
            setCompanyLogoDimensions({});
          }}
        />
        <FormGroup>
          <Label htmlFor="email">Email</Label>
          {currentUser.isSSO && (
            <p>
              Because you are accessing {config.appName} using Single Sign-On,
              this setting cannot be changed.
            </p>
          )}
          {!currentUser.isSSO && (
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          )}
        </FormGroup>
        <FormGroup>
          <Label htmlFor="city">City</Label>
          <Input
            id="city"
            type="city"
            value={city}
            onChange={(e) => setCity(e.target.value)}
          />
        </FormGroup>
        <FormGroup>
          <Label htmlFor="state">State</Label>
          <Select
            isFullWidth
            value={state}
            onChange={(e) => setState(e.target.value)}>
            <Option value="" disabled>
              Select an Option
            </Option>
            {config.states.map(([label, value]) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
          </Select>
        </FormGroup>
        <FormGroup>
          <Button app="cma" disabled={request.loading} isFullWidth>
            {!request.loading && "Save Profile"}
            {request.loading && <Loading>Saving Profile</Loading>}
          </Button>
        </FormGroup>
        {request.error && (
          <FormGroup>
            <Flash variant="error">{request.error}</Flash>
          </FormGroup>
        )}
        {request.success && (
          <FormGroup>
            <Flash variant="success">Profile Updated!</Flash>
          </FormGroup>
        )}
      </form>
    </>
  );
}

function SettingsProfileCompanyLogo({ companyLogo, onCrop, onDelete }) {
  const { currentUser } = useSession();
  const hasCompanyLogo = !!(companyLogo || {}).name;
  const thumbnailRef = useRef();
  const [file, setFile] = useState();
  const [fileKey, setFileKey] = useState();
  const [isDeleting, setIsDeleting] = useState();
  const [showCropper, setShowCropper] = useState();

  const handleChange = async (e) => {
    const [file] = e.target.files;
    thumbnailRef.current = URL.createObjectURL(file);
    setFile(file);
    setShowCropper(true);
    setFileKey(Date.now());
  };

  const handleDelete = () => {
    removeUserCompanyLogo(currentUser.id);
    setIsDeleting(false);
    setFileKey(Date.now());
    onDelete();
  };

  const handleCropperClose = () => {
    setShowCropper(false);
    URL.revokeObjectURL(thumbnailRef.current);
  };

  return (
    <FormGroup>
      <LabelContainer>
        <Label htmlFor="company-logo">Company Logo</Label>
        <Tip>Max 5MB in size</Tip>
      </LabelContainer>
      <InputWithButtonContainer hasCompanyLogo={hasCompanyLogo}>
        <input
          key={fileKey}
          type="file"
          id="company-logo"
          accept={[
            "image/png",
            "image/x-png",
            "image/gif",
            "image/jpg",
            "image/jpeg"
          ].join(",")}
          onChange={handleChange}
        />
        <Input as="label" htmlFor="company-logo">
          {hasCompanyLogo && companyLogo.name}
          {!hasCompanyLogo && <Placeholder aria-hidden>No Logo</Placeholder>}
        </Input>
        {hasCompanyLogo && (
          <Button app="cma" type="button" onClick={() => setIsDeleting(true)}>
            Remove
          </Button>
        )}
      </InputWithButtonContainer>
      {showCropper && (
        <CropperModal
          imageUrl={thumbnailRef.current}
          onClose={handleCropperClose}
          footer={({ getData }) => (
            <Grid>
              <Cell>
                <Button variant="secondary" onClick={handleCropperClose}>
                  Cancel
                </Button>
              </Cell>
              <Cell>
                <Button
                  onClick={() => {
                    handleCropperClose();
                    onCrop({ file, dimensions: getData() });
                  }}>
                  Confirm
                </Button>
              </Cell>
            </Grid>
          )}
        />
      )}
      {isDeleting && (
        <AlertDanger
          title="Delete Profile Image"
          confirmButton="Delete Forever"
          onConfirm={handleDelete}
          onClose={() => setIsDeleting(false)}>
          Are you sure you want to remove your company logo?
        </AlertDanger>
      )}
    </FormGroup>
  );
}

SettingsProfileCompanyLogo.propTypes = {
  companyLogo: PropTypes.object,
  onCrop: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

export default SettingsProfile;
