import React from "react";
import { formatCurrency, getDateLong } from "@wrstudios/utils";
import { useSession } from "../../Session";
import { List, Item, Label, Value } from "../common/DottedList";
import { Text } from "./styled/user-subscription";

function UserSubscription() {
  const { currentUser, subscription, config } = useSession();
  const isFree = currentUser.isSiteLicensed || !subscription.amount;

  return (
    <>
      {!currentUser.isCASUser && (
        <>
          {!isFree && currentUser.isSmallBroker && (
            <>
              <Text>
                Your {config.appName} subscription is currently part of the{" "}
                <strong>{subscription.companyName}</strong> Broker License for{" "}
                <strong>{subscription.description || subscription.name}</strong>
                .
              </Text>
              {currentUser.isAccountAdmin && <SubscriptionDetails />}
            </>
          )}
          {isFree && !currentUser.isSmallBroker && (
            <Text align="center">
              Your {config.appName} subscription is currently{" "}
              <strong>FREE</strong> courtesy of{" "}
              <strong>
                {subscription.accountName ||
                  currentUser.accountName ||
                  config.companyName}
              </strong>
              .
            </Text>
          )}
          {!isFree && !currentUser.isSmallBroker && subscription.cardNumber && (
            <>
              <Text>
                Your {config.appName} subscription is currently on the{" "}
                <strong>{subscription.description || subscription.name}</strong>
                {subscription.discountCode && (
                  <>
                    and you signed up using the promo code{" "}
                    <strong>{subscription.discountCode}</strong>
                  </>
                )}
                .
              </Text>
              {currentUser.isAccountAdmin && <SubscriptionDetails />}
            </>
          )}
        </>
      )}
      {currentUser.isCASUser && (
        <Text align="center">
          Visit{" "}
          <a
            href={`${config.casRootUrl}/app/billing`}
            target="_blank"
            rel="noreferrer">
            Cloud Agent Suite
          </a>{" "}
          to manage your {config.appName} subscription.
        </Text>
      )}
    </>
  );
}

function SubscriptionDetails() {
  const { subscription } = useSession();

  return (
    <>
      <Text>
        Your next renewal date is{" "}
        <strong>{getDateLong(subscription.renewalDate)}</strong> at which time
        you will be charged{" "}
        <strong>{formatCurrency(subscription.amount, "$0[.]00")}</strong> on the
        credit card below.
      </Text>
      <List>
        <Item>
          <Label>Credit card number:</Label>
          <Value>{subscription.cardNumber || "N/A"}</Value>
        </Item>
        <Item>
          <Label>Expiration date:</Label>
          <Value>{subscription.cardExpiration || "N/A"}</Value>
        </Item>
      </List>
    </>
  );
}

export default UserSubscription;
