import React, { useState } from "react";
import { Braintree, HostedField } from "react-braintree-fields";
import Helmet from "react-helmet";
import { Link } from "react-router-dom";
import { useSession } from "../../Session";
import { updateCreditCard } from "../../api/user";
import { useBraintreeToken } from "../braintree/useBraintreeToken";
import Button from "../common/Button";
import Flash from "../common/Flash";
import Input from "../common/Input";
import Label from "../common/Label";
import Loading from "../common/Loading";
import Radio from "../common/Radio";
import { HostedFieldWrapper } from "../common/styled/input";
import UserSubscription from "../user/UserSubscription";
import { Header, Section, Text } from "./styled/settings-billing";
import {
  Cell,
  Error,
  Grid,
  Plan,
  PlanLabel,
  Plans
} from "./styled/settings-billing-payments";

export default function SettingsBillingPayments() {
  const { currentUser, subscription, setSubscription } = useSession();
  const [planId, setPlanId] = useState(subscription.currentPlanId || "");
  const [promoCode, setPromoCode] = useState(subscription.discountCode || "");
  const [request, setRequest] = useState({
    loading: false,
    success: false,
    errors: null
  });
  const [getToken, setGetToken] = useState();
  const [error, setError] = useState();
  const { data: token } = useBraintreeToken();

  const handleSubmit = async (e) => {
    e.preventDefault();

    setError(undefined);
    setRequest({ loading: true, success: false, errors: null });

    let nonce;

    try {
      const token = await getToken();
      nonce = token.nonce;
    } catch (error) {
      setError(error);
    }

    try {
      const newSubscription = await updateCreditCard({
        plan_id: planId,
        nonce,
        discount: promoCode
      });
      setSubscription((subscription) => ({
        ...subscription,
        ...newSubscription
      }));
      setPromoCode("");
      setRequest({ loading: false, success: true, errors: null });
    } catch (error) {
      setRequest({
        loading: false,
        success: false,
        error:
          error?.response?.data?.error ||
          error?.message ||
          "An error occurred while updating your billing details"
      });
    }
  };

  const showCCForm = !subscription.isFree && currentUser.isAccountAdmin;

  return (
    <>
      <Helmet>
        <title>Billing - Edit - Account Settings - Cloud CMA</title>
      </Helmet>
      <form onSubmit={handleSubmit}>
        <Section>
          <Header>Billing - Edit</Header>
          <UserSubscription />
          <Text align="center">
            <Link to="/settings/billing">Back to billing</Link>
          </Text>
        </Section>
        {showCCForm && (
          <>
            {!currentUser.isSiteLicensed && (
              <Section>
                <Header>Plans</Header>
                <Plans>
                  <div>
                    {subscription.plans.map((plan) => (
                      <Plan key={plan.id}>
                        <Radio
                          name="plan"
                          checked={planId === plan.id}
                          onChange={() => setPlanId(plan.id)}
                        />
                        <PlanLabel>{plan.label}</PlanLabel>
                      </Plan>
                    ))}
                  </div>
                </Plans>
              </Section>
            )}
            <Section>
              <Header>Credit Card Info</Header>
              <Braintree
                authorization={token}
                getTokenRef={(getToken) => setGetToken(() => getToken)}
                onError={setError}>
                <Grid>
                  <Cell>
                    <Label htmlFor="first-name">Name on card</Label>
                    <HostedFieldWrapper>
                      <HostedField
                        type="cardholderName"
                        placeholder="John Doe"
                        id="first-name"
                      />
                    </HostedFieldWrapper>
                    {error?.details?.invalidFieldKeys?.includes(
                      "cardholderName"
                    ) && <Error>Invalid name</Error>}
                  </Cell>
                  <Cell />
                  <Cell>
                    <Label htmlFor="credit-card-number">Card number</Label>
                    <HostedFieldWrapper>
                      <HostedField
                        type="number"
                        placeholder="5555 5555 5555 5555"
                        id="credit-card-number"
                      />
                    </HostedFieldWrapper>
                    {error?.details?.invalidFieldKeys?.includes("number") && (
                      <Error>Invalid card number</Error>
                    )}
                  </Cell>
                  <Cell>
                    <Grid>
                      <Cell>
                        <Label htmlFor="credit-card-expiration">
                          Card expiration
                        </Label>
                        <HostedFieldWrapper>
                          <HostedField
                            type="expirationDate"
                            placeholder={`12 / ${new Date().getUTCFullYear()}`}
                            id="credit-card-expiration"
                          />
                        </HostedFieldWrapper>
                        {error?.details?.invalidFieldKeys?.includes(
                          "expirationDate"
                        ) && <Error>Invalid expiration</Error>}
                      </Cell>
                      <Cell>
                        <Label htmlFor="credit-card-cvv">Card CVV</Label>
                        <HostedFieldWrapper>
                          <HostedField
                            type="cvv"
                            placeholder="123"
                            id="credit-card-cvv"
                          />
                        </HostedFieldWrapper>
                        {error?.details?.invalidFieldKeys?.includes("cvv") && (
                          <Error>Invalid CVV</Error>
                        )}
                      </Cell>
                    </Grid>
                  </Cell>
                  <Cell>
                    <Label htmlFor="credit-card-postal-code">
                      Card postal code
                    </Label>
                    <HostedFieldWrapper>
                      <HostedField
                        type="postalCode"
                        placeholder="12345"
                        id="credit-card-postal-code"
                      />
                    </HostedFieldWrapper>
                    {error?.details?.invalidFieldKeys?.includes(
                      "postalCode"
                    ) && <Error>Invalid postal code</Error>}
                  </Cell>
                  <Cell>
                    <Label htmlFor="promo-code">Promo Code</Label>
                    <Input
                      id="promo-code"
                      value={promoCode}
                      onChange={(e) => setPromoCode(e.target.value)}
                    />
                  </Cell>
                  <Cell style={{ width: "100%" }}>
                    <Button app="cma" isFullWidth disabled={request.loading}>
                      {!request.loading && "Update Payment Info"}
                      {request.loading && (
                        <Loading>Updating Payment Info</Loading>
                      )}
                    </Button>
                  </Cell>
                  {(error?.message || request.error) && (
                    <Cell style={{ width: "100%" }}>
                      <Flash variant="error">
                        {error?.message || request.error}
                      </Flash>
                    </Cell>
                  )}
                  {request.success && (
                    <Cell style={{ width: "100%" }}>
                      <Flash variant="success">
                        Your info has been updated!
                      </Flash>
                    </Cell>
                  )}
                </Grid>
              </Braintree>
            </Section>
          </>
        )}
      </form>
    </>
  );
}
