import React from "react";
import PropTypes from "prop-types";
import { Container } from "./styled/youtube";

function YouTube({ videoId, width, height, autoplay, ...props }) {
  return (
    <Container {...props}>
      <iframe
        width={width}
        height={height}
        src={`https://www.youtube.com/embed/${videoId}?autoplay=${
          autoplay ? 1 : 0
        }`}
        frameBorder="0"
        allowFullScreen
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      />
    </Container>
  );
}

YouTube.defaultProps = {
  width: "100%",
  height: "100%",
  autoplay: false
};

YouTube.propTypes = {
  videoId: PropTypes.string.isRequired,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  autoplay: PropTypes.bool
};

export default YouTube;
