import { gql } from "apollo-boost";

export const GET_TOURS = gql`
  query GetTours($page: Int = 1, $limit: Int = 17, $search: String = "") {
    tours(page: $page, limit: $limit, search: $search)
      @rest(
        type: "Tours"
        path: "tours?page={args.page}&limit={args.limit}&q={args.search}"
      ) {
      meta @type(name: "ToursMeta") {
        currentPage
        perPage
        totalPages
        totalResults
      }
      results @type(name: "Tour") {
        id
        guid
        title
        type
        status
        notes
        cities: listings_cities
        addresses: listings_addresses
        thumbnail: thumbnail_url_with_timestamp
        createdWith: created_via
        pdfLink: pdf_permalink
        addressParts: report_address_parts
        updatedAt: updated_at
        eventSummary: event_summary @type(name: "ReportEventSummary") {
          viewCount: view_count
          lastViewedAt: last_view_at
        }
      }
    }
  }
`;

export const DELETE_TOUR = gql`
  mutation DeleteTour($id: ID!) {
    deleteTour(id: $id)
      @rest(type: "Tour", path: "tours/{args.id}", method: "DELETE") {
      id
    }
  }
`;
