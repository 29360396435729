import React from "react";

function IconHouse(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 12 12"
      {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.561.165a.667.667 0 01.878 0l5.333 4.667c.145.126.228.31.228.501v6a.667.667 0 01-.667.667H.667A.667.667 0 010 11.333v-6c0-.192.083-.375.228-.501L5.56.165z"
      />
    </svg>
  );
}

export default IconHouse;
