import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import IconArrowLeft from "../icons/IconArrowLeft";
import IconArrowRight from "../icons/IconArrowRight";
import { Container, Button } from "./styled/comparable-scrollbar";

const ComparableScrollbar = forwardRef(({ hasFooter, onPrev, onNext }, ref) => {
  return (
    <Container ref={ref} hasFooter={hasFooter}>
      <Button onClick={onPrev}>
        <IconArrowLeft />
      </Button>
      <Button onClick={onNext}>
        <IconArrowRight />
      </Button>
    </Container>
  );
});

ComparableScrollbar.propTypes = {
  hasFooter: PropTypes.bool,
  onPrev: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired
};

export default ComparableScrollbar;
