import React from "react";

function IllustrationComparables() {
  return (
    <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 53 40">
      <path
        d="M37.373 22.19h.812v3.912h7.022V15.416l-6.441-4.321-.349.233-6.093 4.088v10.686h5.049V22.19zm-1.16 2.744h-2.728v-8.876l5.28-3.503 5.281 3.503v8.876h-4.7v-3.912h-3.134v3.912zM12.594 22.19h.813v3.912h7.021V15.416l-6.499-4.321-.348.233-6.093 4.088v10.686h5.106V22.19zm-1.16 2.744H8.648v-8.876l5.281-3.503 5.28 3.503v8.876h-4.7v-3.912h-3.075v3.912zM28.262 36.204c-.232-1.05-.58-3.27-.87-5.138a28.379 28.379 0 01-.349-2.511c0-.117-.058-.292-.058-.409H46.02c.348 0 .58-.234.58-.584s-.232-.584-.58-.584H6.791c-.348 0-.58.234-.58.584s.232.584.58.584h18.802c0 .117-.058.233-.058.409-.116.992-.349 2.51-.58 3.97-.233 1.46-.465 2.92-.639 3.68a12.643 12.643 0 01-.696 2.218c-.116.234-.174.41-.232.468l-.058.116c-.116.175-.116.409-.058.584.116.175.29.292.522.292h5.222c.233 0 .465-.117.523-.35.116-.234.058-.467-.116-.642v-.059c-.174 0-.755-.817-1.161-2.628z"
        className="fill-primary"
      />
      <path
        d="M26.637 11.037c3.018 0 5.455-2.453 5.455-5.49C32.092 2.511 29.655 0 26.637 0c-3.017 0-5.454 2.453-5.454 5.489 0 3.037 2.437 5.548 5.454 5.548zm0-5.198c-.986-.116-1.973-.292-1.973-1.576 0-.935.755-1.402 1.625-1.46v-.584h.87v.584c.58.058 1.103.35 1.451.993l-.812.408c-.116-.292-.58-.584-1.103-.584-.464 0-.928.176-.928.643 0 .409.522.525.986.584.987.116 2.09.408 2.09 1.693 0 .934-.58 1.518-1.625 1.577v.7h-.929v-.642a2.126 2.126 0 01-1.799-1.284l.87-.468c.175.468.813.76 1.335.76.639 0 1.103-.117 1.103-.643-.058-.467-.58-.642-1.16-.7z"
        className="fill-secondary"
      />
    </svg>
  );
}

export default IllustrationComparables;
