import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Modal } from "@wrstudios/components";
import OrderForm from "../order/OrderForm";
import image from "../../images/order/tomferry-logo-darkblue-lg";
import CustomizeModalTomFerryCongratulations from "./CustomizeModalTomFerryCongratulations";
import {
  CheckoutModalContent,
  CheckoutModalClose
} from "./styled/customize-modal-kit";
import {
  Title,
  SubTitle,
  TitleImage
} from "./styled/customize-modal-tom-ferry-checkout";

function CustomizeModalTomFerryCheckout({ sku, amount, onCheckout, onClose }) {
  const [showCongrats, setShowCongrats] = useState(false);
  const [triggerCheckout, setTriggerCheckout] = useState(false);
  const isMounted = useRef();

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <Modal
      onClose={() => {
        onClose();
        if (showCongrats && triggerCheckout) onCheckout();
      }}>
      <CheckoutModalContent>
        <CheckoutModalClose />
        <Modal.Body>
          <Title>Listing Presentation Kit</Title>
          <SubTitle>
            by <TitleImage src={image} />
          </SubTitle>
          {!showCongrats && (
            <OrderForm
              sku={sku}
              amount={amount}
              title="the Tom Ferry Kit"
              onCheckout={() => {
                if (isMounted.current) {
                  setShowCongrats(true);
                  setTriggerCheckout(true);
                }
              }}
            />
          )}
          {showCongrats && (
            <CustomizeModalTomFerryCongratulations
              onClick={() => {
                setTriggerCheckout(false);
                onCheckout();
              }}
            />
          )}
        </Modal.Body>
      </CheckoutModalContent>
    </Modal>
  );
}

CustomizeModalTomFerryCheckout.propTypes = {
  sku: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onUpdate: PropTypes.func,
  onClose: PropTypes.func
};

export default CustomizeModalTomFerryCheckout;
