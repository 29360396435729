import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { clearTemplate, updateTheme } from "../../actions/customize";
import { getReportType } from "../../utils/types";

function CustomizeModalThemeSave({
  report,
  theme,
  clearTemplate,
  updateTheme,
  onClick
}) {
  return (
    <button
      className="button button-cma"
      onClick={() => {
        onClick();
        clearTemplate(report.id, getReportType(report.type), true);
        updateTheme(report.id, getReportType(report.type), theme);
      }}>
      Choose Theme
    </button>
  );
}

CustomizeModalThemeSave.propTypes = {
  report: PropTypes.shape({
    id: PropTypes.number,
    type: PropTypes.string
  }),
  theme: PropTypes.object,
  clearTemplate: PropTypes.func,
  updateTheme: PropTypes.func,
  onClick: PropTypes.func
};

const mapStateToProps = (state) => ({
  report: state.report
});

const mapDispatchToProps = (dispatch) => ({
  clearTemplate: (id, type) => dispatch(clearTemplate(id, type)),
  updateTheme: (id, type, theme) => dispatch(updateTheme(id, type, theme))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CustomizeModalThemeSave);
