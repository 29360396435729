import {
  requestState,
  rejectedState,
  resolvedState
} from "../../../utils/redux";

export function request(state) {
  return requestState({ ...state, errors: {} });
}

export function resolved(state, action) {
  const { errors } = action.payload;

  if (Object.keys(errors).length) {
    return rejectedState(state, null, errors);
  }

  return resolvedState({
    ...state,
    api: { ...state.api, ...action.payload.api }
  });
}

export function rejected(state, action) {
  return rejectedState(state, action.payload.error);
}
