import React, { Children } from "react";
import IconKeyboardArrowRight from "../icons/material/hardware/IconKeyboardArrowRight";
import { Container, Link, Arrow } from "./styled/breadcrumbs";

function Breadcrumbs({ children, ...props }) {
  return (
    <Container {...props}>
      {Children.map(children, (child, index) => {
        if (!child) return;

        return (
          <>
            {index > 0 && (
              <Arrow>
                <IconKeyboardArrowRight />
              </Arrow>
            )}
            {child}
          </>
        );
      })}
    </Container>
  );
}

function Breadcrumb(props) {
  return <Link {...props} />;
}

Breadcrumbs.Item = Breadcrumb;

export default Breadcrumbs;
