import { rejectedState } from "../../../utils/redux";

export function request(state, action) {
  const { listingId, adjustmentId } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      listing.adjustments = (listing.adjustments || []).filter(
        (adjustment) => adjustment.id !== adjustmentId
      );
      listing = {
        ...listing,
        price: listing.adjustments.reduce((price, adjustment) => {
          return price + adjustment.value;
        }, listing.price_raw)
      };
    }
    return listing;
  });
  return { ...state, all: listings };
}

export function resolved(state) {
  return state;
}

export function rejected(state, action) {
  const { listingId, adjustmentId } = action.payload;
  const listings = state.all.map((listing) => {
    if (listing.id === listingId) {
      const adjustments = listing.adjustments || [];
      listing.adjustments = adjustments.map((adjustment) => {
        if (adjustment.id === adjustmentId) {
          adjustment = rejectedState(adjustment);
        }
        return adjustment;
      });
    }
    return { ...state, all: listings };
  });
  return { ...state, all: listings };
}
